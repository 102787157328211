import '../ViewProfile/ReferCommMemberPopup.css'
import {MdFileCopy} from 'react-icons/md'
import {FaShareAltSquare} from 'react-icons/fa'
import React, { useState, useEffect,useRef} from "react";
import validator from "validator";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Countrycode } from '../JoinNow/Countrycode';
import {AiOutlineClose} from 'react-icons/ai'
import MessagePopupDirectory from './MessegePopupDirectory';
import flag from '../../images/india.png';
import subcast from '../../images/subcast.svg';
import refferal from '../../images/refferalsvg.svg';
import cups from '../../images/cups.svg';
import from from '../../images/from.svg';
import radius from '../../images/radius.svg';
import conprof from '../../images/coneect.svg';
import connectfreely from '../../images/connectfreely.png';
import chatonmessage from '../../images/chatonmessanger.png';
import viewcontactno from '../../images/viewcontactno.png';
import invitcoffee from '../../images/invitefrcoffeepng.png';
import {configData} from "../../Config"
import { RiUserSettingsLine } from "react-icons/ri";
import { IoMdCheckmark } from "react-icons/io";




import { RiCupFill, RiSendPlaneFill, RiTimeLine } from 'react-icons/ri';
import { MdOutlineArrowDropDown, MdLocationOn, MdNotifications, MdWaterDrop,MdEmail,MdCheckBox } from 'react-icons/md';
import { FaRegComments, FaUserClock, FaFilter, FaInfoCircle,FaSearch, FaRegHandshake, FaEdit, FaBookmark,FaPhoneSquareAlt,FaRegCalendar,FaUsers } from 'react-icons/fa';
import { BsBookmark } from 'react-icons/bs';
import PremiumPopup from './PremiumPopup';
 
const SubscribePremiem = props => {

  //for session
  // let history = useHistory();

  // const [session ,setSession] = useState(false);

  // const gesessiondata = async () => {
  //    debugger;
  //    axios.get('/checkSession')
      
  //    .then(result =>{
  //      if(result.data.code == 501){
  //        setSession(true)  ;
  //         }
  //     else{
  //          history.push("/Login");

  //     }
  //      debugger;
  //      console.log(result.data.code);
  //    }).catch(error =>{
  //      console.log(error);
  //    })
  //  };

  //        console.log("checksession on campaign",session);

  // useEffect(() => {
  //     gesessiondata();
  //   }, [session]);
  

     const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
     console.log("authdata11",authlocalstoragedata)
    
     const [uerAuthId, setuerAuthId] = useState(authlocalstoragedata?.userAuthenticationId);
     const [uerAuthReferralCode, setuerAuthReferalCode] = useState(authlocalstoragedata?.referralCode);


//  const [referData ,setreferData] = useState({
  
//   referralCode: uerAuthReferralCode,
//   firstName: "",
//   lastName: "",
//   countryCode: "",
//   emailId: "",
//   mobileNumber: "",
  
//  });

//  const referChange = (e) => {
//   debugger;
//   const { name, value, type, checked } = e.currentTarget;


//   setreferData(preState => ({
//     ...preState,
//     [name]: type === 'checkbox' ? checked : value
//   }));
// }
// const [contactValidEroor ,setContactValidEroor] = useState(false);
//   const [emailEroor ,setemailEroor] = useState(false);
// const [error, setError] = useState(false);
// //for email
//    const submitFormData = (e) => {
//     debugger
//     e.preventDefault();

//     // checking if value of first name and last name is empty show error else take to step 2
   
//      if (validator.isEmpty(referData.firstName)) {
//         setError(true);
//     }
//    else if (validator.isEmpty(referData.lastName)) {
//       setError(true);
//    }
//    else if (validator.isEmpty(referData.countryCode)) {
//     setError(true);
//   }
//    else if (validator.isEmpty(referData.emailId)) {
//     setError(true);
//    }
//    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(referData.emailId)) {
//     setemailEroor(true);
//   }
//    else if (validator.isEmpty(referData.mobileNumber)) {
//     setError(true);
//    }
//    else if (referData.mobileNumber.length != 10) {
//     setError(true);
  
//   }
//   else if(!/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(referData.mobileNumber)){
//     setContactValidEroor(true);
    
//   }
    
//     else {
//       debugger
//       addReferalinvite();
//     }
// };

// const addReferalinvite =()=>{
  
// const response = axios.post('/subscriber/inviteMember', referData)
//     response.then(() => {
//           toast.success("Innvite Member Successfully!");

//       })
//        response.catch((err) => {
//          toast.warn("Something went wrong")
//        });
//        console.log("response", response?.data)
//        console.log("InviteMember",referData)
      
// }

//for commDirectry list
const [communityData, setcommunityData] = useState([]);

    useEffect(() => {
      loadCommunityData();
    }, []);
  
    const loadCommunityData = async () => {
      debugger
      const result = await axios.get(configData.ApiUrl+'/subscriber/viewProfileById?hostId=' +props.uerAuthId+ '&userId=' +props.idforcommpop);
      setcommunityData(result.data.data);
      console.log("communityData",result.data.data);
    };

    //for community popup
  const [isOpenMessagePopDirect, setIsOpenMessagePopDirect] = useState(false);
  const [idforpop, setidforpop] = useState(0);

  
  const togglePopupMessageDirect = (id) => {
    setIsOpenMessagePopDirect(!isOpenMessagePopDirect);
    setidforpop(id)
  }

  const [commDirectoryconReq, setcommDirectoryconReq] = useState(false);

  const addCommDirectoryConnect = async (id) => {
    debugger
    const response = await axios.post(configData.ApiUrl+'/connection/sendConnection?senderId=' + uerAuthId + '&recieverId=' + id)
    if (response.status == 200 && response.data.code) {
      //setcommDirectoryconReq(true)
      loadCommunityData();

    }
    else {
      setcommDirectoryconReq(false)
      alert("Something Went Wrong..!")
    }
  }
  const [premiumSignup, setpremiumSignup] = useState(false);
    
  const togglePopupPremium = () => {
      setpremiumSignup(!premiumSignup);
      }

     //for close popup when click on outside
  const popupSearchRef = useRef(null);
  const handleClickOutsideSearchdiv = (event) => {
    if (popupSearchRef.current && !popupSearchRef.current.contains(event.target)) {
      props.handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideSearchdiv);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideSearchdiv);
    };
  }, []);
 
  return (
    <div className="popup-boxrefmember11">
      <div className="boxrefmember11" ref={popupSearchRef} style={{width:"42%",height:"390px",marginTop:"182px"}}>
      <span className="close-naidudi" onClick={props.handleClose}>x</span>

      <div className=" ">
                <div className="row  cardfirst-toprow11 topnewsrow" style={{backgroundColor:"#83A275",color:"#F2F2F2"}}>
                  <div className="col-lg-6 col-sm-12 col-md-4 margintop1"><RiUserSettingsLine color='#212529' size="1.3em"/> <span className="post-host-Name" style={{color:"",fontSize:"15px",marginLeft:"3px"}}>Subscribe To Premium</span></div>
                  
                </div>    
                  </div>
<div className='row mt-3 textcenter'>
    <span className='post-host-Name' style={{color:"#626262",fontSize:"14px",marginLeft:"-3px",fontWeight:"600"}}>Unlock Premium Membership and Expand Your Community Experience!</span>
</div>
{/* <div className='row mt-4'>
  <div className='col-lg-3 col-sm-12 col-md-6 textcenter'>
  <img src={connectfreely} className='' />
  <div className=''><span className='post-host-Name' style={{color:"",fontSize:"12px",marginLeft:"px",fontWeight:"400"}}>Connect Freely</span></div>
</div>
<div className='col-lg-3 col-sm-12 col-md-6 textcenter'>
  <img src={viewcontactno} className='' />
  <div className=''><span className='communtdrc-cit-text'>View Contact Numbers</span></div>
</div>
<div className='col-lg-3 col-sm-12 col-md-6 textcenter'>
  <img src={invitcoffee} className='' />
  <div className=''><span className='communtdrc-cit-text'>Invite for Coffee</span></div>
</div> 
<div className='col-lg-3 col-sm-12 col-md-6 textcenter'>
  <img src={chatonmessage} className='' />
  <div className=''><span className='communtdrc-cit-text'>Chat on Messenger</span></div>
</div>
</div> */}
<div className='row textleft margintop3'>
  <div className='col-lg-1'></div>
<div className='col-lg-5 col-sm-12 col-md-6 textleft'>
<IoMdCheckmark size="1.1em" color="#00C67E"/> <span className='post-host-Name' style={{color:"",fontSize:"12px",marginLeft:"px",fontWeight:"400"}}>Connect Freely</span>
</div>
<div className='col-lg-5 col-sm-12 col-md-6 textleft'>
<IoMdCheckmark size="1.1em" color="#00C67E"/> <span className='post-host-Name' style={{color:"",fontSize:"12px",marginLeft:"px",fontWeight:"400"}}>Invite for Coffee</span>
</div>
</div>
<div className='row textleft margintop1'>
  <div className='col-lg-1'></div>
<div className='col-lg-5 col-sm-12 col-md-6 textleft'>
<IoMdCheckmark size="1.1em" color="#00C67E"/> <span className='post-host-Name' style={{color:"",fontSize:"12px",marginLeft:"px",fontWeight:"400"}}>View Contact Numbers</span>
</div>
<div className='col-lg-5 col-sm-12 col-md-6 textleft'>
<IoMdCheckmark size="1.1em" color="#00C67E"/> <span className='post-host-Name' style={{color:"",fontSize:"12px",marginLeft:"px",fontWeight:"400"}}> Chat on Messanger</span>
</div>
</div>

      <div className='row mt-3 textcenter'>
        <div className='col-lg-12 col-sm-12 col-md-12'>
          <span className='post-host-Name' style={{color:"#626262",fontSize:"12px",marginLeft:"px",fontWeight:"500"}}> "And that's just the beginning!</span>
        </div>
        </div>  
        <div className='row textcenter'>
        <div className='col-lg-12 col-sm-12 col-md-12'>
      <span className='post-host-Name' style={{color:"#626262",fontSize:"12px",marginLeft:"px",fontWeight:"500"}}>
      Upgrade to Premium today and elevate your Naidu Directory experience.
       Don't miss out 
      </span>
      </div>
          </div> 
          <div className='row textcenter '>
        <div className='col-lg-12 col-sm-12 col-md-12'>
          <span className='post-host-Name' style={{color:"#626262",fontSize:"12px",marginLeft:"px",fontWeight:"500"}}>on this opportunity to connect, socialize, and grow within our vibrant community.</span>
        </div>
        </div>  
          <div className='row textcenter '>
        <div className='col-lg-12 col-sm-12 col-md-12'>
          <span className='post-host-Name' style={{color:"#626262",fontSize:"12px",marginLeft:"px",fontWeight:"500"}}>Subscribe now!"</span>
        </div>
        </div> 

        <div className='row mt-4'>
          <div className='col-lg-2'></div>
          <div className='col-lg-3 col-sm-12 col-md-6 mr-1'>
            <button className="secondry-btnn"  onClick={props.handleClose} style={{width:"165px"}}> I will do this later</button>
          </div>
          <div className='col-lg-4 col-sm-12 col-md-6 ml-5'>
          <button className='primary-btnn'onClick={togglePopupPremium} style={{width:"165px"}}>Go premium now</button>
          {premiumSignup && <PremiumPopup
      
      handleClose={togglePopupPremium}
    />}  
          </div>

          </div>        
      </div>
    </div>
  );
};
 
export default SubscribePremiem;