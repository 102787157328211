import { Breadcrumb,Badge, Button, Card, Col, Form, FormGroup, Dropdown ,ButtonGroup, InputGroup, Row ,Carousel,Pagination} from 'react-bootstrap';
import event1 from '../../images/event1.png'
import event2 from '../../images//event2.png'
import event3 from '../../images//event3.png'
import kycimg from '../../images//kyc.png';
import { Link } from "react-router-dom";
//import imgupload from '../../images//imgupload.svg';
import blog from '../../images//blog.png';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import React, { useState, useEffect,useRef } from "react";
import imgupload from '../../images/imageupload.svg'
import {BiLike,} from 'react-icons/bi';
import {AiOutlineDislike,AiFillLike,AiOutlineFilePdf} from 'react-icons/ai'
import blogimg from '../../images/blog.png'
import {FaRegComments,FaUserClock,FaFilter,FaInfoCircle,FaRegHandshake,FaEdit} from 'react-icons/fa';
import eventlogo from '../../images/eventlogo.svg';
import eventimg1 from '../../images/event1.png';
import eventimg2 from '../../images/event2.png';
import eventimg3 from '../../images/event3.png';
import axios from "axios";
import { useParams } from "react-router-dom";
import {MdClose} from 'react-icons/md'
import { toast } from "react-toastify";
import pdf from '../../images/Resume - 2.pdf'
import {configData} from "../../Config"
import Avatar from '@mui/material/Avatar';
import { RiSpam2Line } from "react-icons/ri";
import { MdOutlineDelete } from "react-icons/md";
import {RiCupFill,RiSendPlaneFill,RiEdit2Line} from 'react-icons/ri';
import EditEventpostPpup from './EditEventpostPopup';
import EditBlogPopup from './EditBlogPopup';
import EditKycPopup from './EditKycPopup';
import { post } from 'jquery';
import { MdOutlineEventAvailable } from "react-icons/md";
import moment, { duration } from 'moment'
import {BsPlusLg} from 'react-icons/bs'
import {MdOutlineDeleteOutline} from 'react-icons/md'
import validator from "validator";
import { FaBlog } from "react-icons/fa";
import { FaRegUser } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import { YearData } from '../UserLandingPages/YearData';
import { MdOutlineBusinessCenter } from "react-icons/md";



const handleKeypress = e => {
  //it triggers by pressing the enter key
  debugger
  if (e.key === "Enter") {
    saveComent(id);

  }
};

function valuetext(value) {
  return `${value}`;
}

const Post = () => {
  
  
   

    const [startDate, setStartDate] = useState(new Date());

    const handleChangedate= (e) => {
      debugger
     setStartDate(e)
     setEventData(preState => ({
      ...preState,
      "eventDate": (moment(e).format("DD MMM yyyy")),

    }))
    }

    const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
    console.log("authdata11",authlocalstoragedata)
    const [uerAuthId, setuerAuthId] = useState(authlocalstoragedata?.userAuthenticationId);


    const [eventdata, setEventData] = useState({
      hostId:uerAuthId,
      hostName:authlocalstoragedata?.name2,
      eventType:"",
      eventDate:"",
      eventTime:"",
      address:"",
      countryId:"",
      country:"",
      stateId:"",
      state:"",
      cityId:"",
      city:""
  
      });

      const handlechange =(event)=>{
        const {name,value,type,checked}=event.currentTarget;
        debugger
        setEventData(preState =>({
          ...preState,
          [name]:type ==='checkbox'?checked: value
        }))
        if(!CountrystateDiv){
          setEventData((preState) => ({
            ...preState,
            country: authlocalstoragedata?.currentCountry,
            state: authlocalstoragedata?.currentState,
            city: authlocalstoragedata?.currentCity
          }));
        }
    }

    const [error, setError] = useState(false);

      const submitFormData = (e) => {
        debugger
        e.preventDefault();
    
        // checking if value of first name and last name is empty show error else take to step 2
         if (validator.isEmpty(eventdata.eventType)) {
             setError(true);
         }
         else if (validator.isEmpty(eventdata.eventDate)) {
             setError(true);
         }
         else if (validator.isEmpty(eventdata.eventTime)) {
           setError(true);
         }
         else if (validator.isEmpty(eventdata.address)) {
           setError(true);
    
         }
         else if(CountrystateDiv==true) {
              if (validator.isEmpty(eventdata.country)) {
               setError(true);
             }
           }
             else if(CountrystateDiv==true) { 
             if (validator.isEmpty(eventdata.state)) {
               setError(true);
             }
           }
           else if(CountrystateDiv==true) {
                if (validator.isEmpty(eventdata.city)) {
                 setError(true);
                }
               }
       
       
        
     else {
      
      addEventDetails();      
      
       }
    };

    const convertTo24HourFormat = (time12h) => {
      if (!time12h) return ""; // Return empty if time is null/undefined
    
      const [time, modifier] = time12h.split(" ");
      let [hours, minutes] = time.split(":");
    
      if (modifier === "pm" && hours !== "12") {
        hours = String(Number(hours) + 12);
      } else if (modifier === "am" && hours === "12") {
        hours = "00";
      }
    
      return `${hours}:${minutes}`;
      
    };

    //img add
    const [profilePhoto, setprofilePhoto] = useState(null)
    
           const [photoOne, setphotoOne] = useState(null)
           const [photoTwo, setphotoTwo] = useState(null)
           const [photoThree, setphotoThree] = useState(null)
           const [photoFour, setphotoFour] = useState(null)

           //for img preview
                const [imgDataProf, setImgDataProf] = useState(null);
                const filerefProf =useRef(null);
                const [imgData11, setimgData11] = useState(null);
                const fileref =useRef(null);
                const [imgData1, setImgData1] = useState(null);
                const fileref1 =useRef(null);
                const [imgData2, setImgData2] = useState(null);
                const fileref2 =useRef(null);
                const [imgData3, setImgData3] = useState(null);
                const fileref3 =useRef(null);
                
             
                const handlephotoProfChange = (e, index) => {
                 const { name, value } = e.target;
             
                 // setphotoOne(e.target.files[0])
                 // console.log(e.target.files[0])
                 const file = e.target.files[0];
  const allowedTypes = ['image/jpeg', 'image/png', 'image/svg+xml'];
  const maxSize = 20 * 1024 * 1024; // 20 MB in bytes

  // Validate file type
  if (file && !allowedTypes.includes(file.type)) {
    toast.error('Only JPEG, PNG, and SVG files are allowed.');
    return; // Exit the function if file type is invalid
  }
  // Validate file size
  if (file && file.size > maxSize) {
    toast.error('File size must not exceed 20 MB.');
    return; // Exit the function if file size is too large
  }
           
                 if (e.target.files[0]) {
                   console.log("picture: ", e.target.files);
                   setprofilePhoto(e.target.files[0]);
                  
                   const reader = new FileReader();
                   reader.addEventListener("load", () => {
                     setImgDataProf(reader.result);
                   });
                   reader.readAsDataURL(e.target.files[0]);}
             
           
               };
                 const handlephotoOneChange = (e, index) => {
                   const { name, value } = e.target;
               
                   // setphotoOne(e.target.files[0])
                   // console.log(e.target.files[0])
                   const file = e.target.files[0];
  const allowedTypes = ['image/jpeg', 'image/png', 'image/svg+xml'];
  const maxSize = 20 * 1024 * 1024; // 20 MB in bytes

  // Validate file type
  if (file && !allowedTypes.includes(file.type)) {
    toast.error('Only JPEG, PNG, and SVG files are allowed.');
    return; // Exit the function if file type is invalid
  }
  // Validate file size
  if (file && file.size > maxSize) {
    toast.error('File size must not exceed 20 MB.');
    return; // Exit the function if file size is too large
  }
           
                   if (e.target.files[0]) {
                     console.log("picture: ", e.target.files);
                     setphotoOne(e.target.files[0]);
                    
                     const reader = new FileReader();
                     reader.addEventListener("load", () => {
                      setimgData11(reader.result);
                     });
                     reader.readAsDataURL(e.target.files[0]);}
               
             
                 };
                 const handlephotoTwoChange = (e, index) => {
                   const { name, value } = e.target;
               
                   const file = e.target.files[0];
  const allowedTypes = ['image/jpeg', 'image/png', 'image/svg+xml'];
  const maxSize = 20 * 1024 * 1024; // 20 MB in bytes

  // Validate file type
  if (file && !allowedTypes.includes(file.type)) {
    toast.error('Only JPEG, PNG, and SVG files are allowed.');
    return; // Exit the function if file type is invalid
  }
  // Validate file size
  if (file && file.size > maxSize) {
    toast.error('File size must not exceed 20 MB.');
    return; // Exit the function if file size is too large
  }
                   // setphotoTwo(e.target.files[0])
                   if (e.target.files[0]) {
                     console.log("picture2: ", e.target.files);
                     setphotoTwo(e.target.files[0]);
                    
                     const reader = new FileReader();
                     reader.addEventListener("load", () => {
                       setImgData1(reader.result);
                     });
                     reader.readAsDataURL(e.target.files[0]);}
               
             
                 };
                 const handlephotoThreeChange = (e, index) => {
                   const { name, value } = e.target;
               
                   const file = e.target.files[0];
  const allowedTypes = ['image/jpeg', 'image/png', 'image/svg+xml'];
  const maxSize = 20 * 1024 * 1024; // 20 MB in bytes

  // Validate file type
  if (file && !allowedTypes.includes(file.type)) {
    toast.error('Only JPEG, PNG, and SVG files are allowed.');
    return; // Exit the function if file type is invalid
  }
  // Validate file size
  if (file && file.size > maxSize) {
    toast.error('File size must not exceed 20 MB.');
    return; // Exit the function if file size is too large
  }
                   // setphotoThree(e.target.files[0])
                   if (e.target.files[0]) {
                     console.log("picture: ", e.target.files);
                     setphotoThree(e.target.files[0]);
                    
                     const reader = new FileReader();
                     reader.addEventListener("load", () => {
                       setImgData2(reader.result);
                     });
                     reader.readAsDataURL(e.target.files[0]);
                    }
               
             
                 };
                 const handlephotoFourChange = (e, index) => {
                   const { name, value } = e.target;
               
                   const file = e.target.files[0];
  const allowedTypes = ['image/jpeg', 'image/png', 'image/svg+xml'];
  const maxSize = 20 * 1024 * 1024; // 20 MB in bytes

  // Validate file type
  if (file && !allowedTypes.includes(file.type)) {
    toast.error('Only JPEG, PNG, and SVG files are allowed.');
    return; // Exit the function if file type is invalid
  }
  // Validate file size
  if (file && file.size > maxSize) {
    toast.error('File size must not exceed 20 MB.');
    return; // Exit the function if file size is too large
  }
                   // setphotoFour(e.target.files[0])
                   if (e.target.files[0]) {
                     console.log("picture: ", e.target.files);
                     setphotoFour(e.target.files[0]);
                    
                     const reader = new FileReader();
                     reader.addEventListener("load", () => {
                       setImgData3(reader.result);
                     });
                     reader.readAsDataURL(e.target.files[0]);}
               
             
                 };

    const [errorForImg, seterrorForImg] = useState(false);

    const addEventDetails =()=>{
debugger
      // setEventData(preState => ({
      //   ...preState,
      //   attachFiles : serviceList
      // }))
      if (!CountrystateDiv) {
        setEventData((preState) => ({
          ...preState,
          country: authlocalstoragedata?.currentCountry,
          state: authlocalstoragedata?.currentState,
          city: authlocalstoragedata?.currentCity
        }));
      }

      eventdata.eventTime = convertTo24HourFormat(eventdata.eventTime)

      // Check if at least one image is present
  const hasAttachment = 
  profilePhoto || 
  photoOne || 
  photoTwo || 
  photoThree || 
  photoFour;

  if (!hasAttachment) {
   // toast.error("Please upload at least one image.");
    seterrorForImg(true);
    return; // Stop further execution if validation fails
  }
  else{
    const formData = new FormData();

    formData.append('event',JSON.stringify(eventdata));
    
    formData.append('profilePhoto', profilePhoto);
    formData.append('photo1', photoOne);
    formData.append('photo2', photoTwo);
     formData.append('photo3', photoThree);
     formData.append('photo4', photoFour);

     const response = axios.post(configData.ApiUrl+'/CommunityPost/editEvent',formData,{
headers: {
  'content-type': 'multipart/form-data'
}
}
)
    response.then(() => {
      toast.success("Event updated Successfully!");
      //props.handleClose(togglePopup)
      
      Eventpoupopen(false);
      loadPostList();

    })
    response.catch((err) => {
      alert("Getting error in featching data")

    });
       console.log("response", response?.data)
        console.log("event",eventdata)
  }
      
     }


     const [attachment, setAttachment] = useState({
      profilePhoto: "",
      photo1: "",
      photo2: "",
      photo3: "",
      photo4: "",
      
     })

     //for country city state
const [countryData, setcountryData] = useState([]);

useEffect(() => {
  loadCountryData();
}, []);

const loadCountryData = async () => {
  
  const result = await axios.get(configData.ApiUrl+"/user/listOfCountries");
  setcountryData(result.data.data);
  console.log("country",result.data.data)
};

const handleCountryChange = (event) => {
  const [id, country] = event.target.value.split("-");
  setEventData((prevState) => ({
    ...prevState,
    countryId: id,
    country: country,
  }));
  loadStateData(id);
};
//for state city state
const [stateData, setstateData] = useState([]);

useEffect(() => {
loadStateData();
}, []);

const loadStateData = async (countryId) => {
const result = await axios.get(`${configData.ApiUrl}/user/listOfStates?countryId=${countryId}`);
setstateData(result.data.data);
console.log("state",result.data.data)
};

const handleStateChange = (event) => {
const [id, state] = event.target.value.split("-");
setEventData((prevState) => ({
...prevState,
stateId: id,
state: state,
}));
loadCityData(id);

};
//for state city state
const [cityData, setcityData] = useState([]);

useEffect(() => {
loadCityData();
}, []);

const loadCityData = async (stateId) => {
const result = await axios.get(`${configData.ApiUrl}/user/listOfCities?stateId=${stateId}`);
setcityData(result.data.data);
console.log("city",result.data.data);
};

const handleCityChange = (event) => {
const [id, city] = event.target.value.split("-");
setEventData((prevState) => ({
...prevState,
cityId: id,
city: city,
}));
};

//for countrydiv
   const [CountrystateDiv, setCountrystateDiv] = useState(false);

   const togglePopupCountry = () => {
    setCountrystateDiv(!CountrystateDiv);
   }


    
        


  const [searchDiv ,setSearchDiv] = useState(false);
  function searchpoupopen (isSearch){
    if(isSearch){
      setSearchDiv(true);
    }
    else{
      setSearchDiv(false);
    }
  }
  const [startDate1, setStartDate1] = useState(new Date());


  const handleChangeDate =(e)=>{
    
    setStartDate(e);
    
   }
   const handleChangeDate1 =(e)=>{
    
    setStartDate1(e)
    
 }
//  const [comment ,setComment] = useState(false);
//   const commentClick = () => {
//     setComment(true);
//   }

//  const [eventData, setEventData] = useState([]);

//  useEffect(() => {
//   loadEvent();
//  }, []);

//  const loadEvent = async () => {
//    const result = await axios.get("/CommunityPost/getListOfEvents");
//    setEventData(result.data.data);
//    debugger;
//    console.log(eventData);
//  };


const [loadPost, setloadPost] = useState([]);

     useEffect(() => {
      loadPostList();
     }, []);
  
    const loadPostList = async () => {
      
      debugger;
      const result = await axios.get(`${configData.ApiUrl}/CommunityPost/getListPostByHostId?id=${uerAuthId}`);
      setloadPost(result.data.data);
      console.log("postlist",result.data.data)
    };


    //for getlikesbyid
const [LikeDiv ,setLikeDiv] = useState(false);
function Likepoupopen (isLike){
  if(isLike){
    setLikeDiv(true);
  }
  else{
    setLikeDiv(false);
  }
}

//for editeventpopup

const [EventEditDiv ,setEventEditDiv] = useState(false);
function Eventpoupopen (isEvent){
  if(isEvent){
    setEventEditDiv(true);
  }
  else{
    setEventEditDiv(false);
  }
}

const { id } = useParams();

const [likeData, setlikeData] = useState([]);

useEffect(() => {
//loadlikes();
}, []);

const loadLikesInfo = async(id) => {
console.log(id)
 const result =  await axios.get(`${configData.ApiUrl}/CommunityPost/getListOfLikesById?id=${id}`);
 setlikeData(result.data.data);
//  debugger;
 console.log("likesdt",result.data.data);

 Likepoupopen(true);
};
//for editevent

//const [postData, setpostData] = useState([]);

const loadPost1 = async (id, type) =>{
  const res = await axios.get(`${configData.ApiUrl}/CommunityPost/getPostByID?id=${id}`);
  debugger;
  setEventData(res.data.data.event);
  setBlog(res.data.data.blog);
  setJob(res.data.data.job);


  // setKyc(res.data.data.kyc);


  const kycData = res.data.data.kyc;

  if (kycData) {
    setKyc({
      ...kycData,
      reference: kycData.reference?.filter(ref => typeof ref === "string") || [] // Ensure it's an array of strings
    });

    // Convert reference array into serviceList format for UI
    if (kycData.reference?.length > 0) {
      setServiceList(kycData.reference.map(ref => ({ service: ref })));
    } else {
      setServiceList([{ service: "" }]); // Default empty input if no references exist
    }

    setAttachmentkyc(kycData.bannerImagePath);
  }
  //postData.eventType = res.data.data.event.eventType;
  console.log("geteventPostsById",res.data.data.event);
  console.log("getKycPostsById",res.data.data.event);
  setAttachmentkyc(res?.data.data.kyc?.bannerImagePath);
  setAttachmentblog(res?.data.data.blog?.bannerImagePath);

  setprofilePhoto( res?.data.data.event?.profilePicturePath);
  setphotoOne( res?.data.data.event?.photoOnePath );
  setphotoTwo( res?.data.data.event?.photoTwoPath );
  setphotoThree( res?.data.data.event?.photoThreePath);
  setphotoFour( res?.data.data.event?.photoFourPath );

if(res.data.data.event?.type== type){
  Eventpoupopen(true);
}
else if(res.data.data.blog?.type== type){
  BlogEditDivpoupopen(true);
}
else if(res.data.data.kyc?.type== type){
  KycEditDivpoupopen(true);
}
else if(res.data.data.job?.type== type){
  JobEditDivpoupopen(true);
  }
  

  }

const addLikes= async (id)=>{
  
  debugger;
 const response =  await axios.post(configData.ApiUrl+'/CommunityPost/like?postId='+id+'&userId='+uerAuthId)
 if(response.status == 200 && response.data.code ){
  loadPostList();

}
else{
    alert("Something Went Wrong..!")
}
}


//for comments
const [comment ,setComment] = useState(false);
const [cmntEventId,setCmntEventId]=useState(0);
function Commentpoupopen (commentopen){
   if(commentopen){
     setComment(true);
   }
   else{
     setComment(false);
   }
}


const [commentData, setcommentData] = useState([]);
 const [cmntValue, setcmntValue] = useState({
  txtCmnt:""
 });


useEffect(() => {
//loadlikes();
}, []);
const losdUnlodCmnt = (idd)=>{
  setCmntEventId(idd);
  if(comment)
  {
   Commentpoupopen(false);
   return false;
  }
  else{
    commentInfo(idd);
  }
}
const commentInfo = async(id) => {
  setCmntEventId(id);
  
  
  console.log(id)
  debugger
   const result =  await axios.get(`${configData.ApiUrl}/CommunityPost/getListOfCommentsById?id=${id}`);
   setcommentData(result.data.data.sort((a, b) => b.id - a.id));
  //  debugger;
   console.log("commentdt",result.data.data.sort((a, b) => b.id - a.id));
   setCmntEventId(id);
   Commentpoupopen(true);
   setcmntValue(preState =>({
          ...preState,
          "txtCmnt": ""
        }))
        cmntValue.txtCmnt="";
 };

 const [commentValiderr,setcommentValiderr]=useState(false)


 const saveComent = async(id)=>{
  if(cmntValue.txtCmnt ==""){
    setcommentValiderr(true)
    return false;
  }
  else{
    setcommentValiderr(false)
  const response =  await axios.post(configData.ApiUrl+'/CommunityPost/comment?postId='+id+'&userId='+uerAuthId+'&comment='+cmntValue.txtCmnt)
  setcmntValue(preState =>({
    ...preState,
    "txtCmnt": ""
  }))
  cmntValue.txtCmnt="";
  debugger;
  loadPostList();
  commentInfo(id);
}
}
 
const handleChange = event => {

  const {name,value,type,checked}=event.currentTarget;
      debugger
      
      setcmntValue(preState =>({
        ...preState,
        [name]:type ==='checkbox'?checked: value
      }))

     
}

const deleteComment=(id)=>{
  
  debugger;
 const response = axios.delete(`${configData.ApiUrl}/CommunityPost/deleteComment?commentId=${id}`)
response.then(() => {
  toast.success("Comment deleted Successfully!");
  commentInfo(id);


})
response.catch((err) => {
  alert("Getting error in featching data")
});


}

//for search post
const handleSearchChangePost=(e)=> {
  debugger
    if (e.currentTarget.value === "") 
    {  loadPostList();
       return; }
    const filterBySearch = loadPost.filter((item) => {
      if ( item != null && item.type != null){
        if ( item?.type?.toLowerCase().includes(e.currentTarget.value)) { return item; }
      }
       if ( item != null && item.hostName != null){
         if (item?.hostName?.toLowerCase()
             .includes(e.currentTarget.value)) { return item; }
            }
            if ( item != null && item.city != null){
            if (item?.city?.toLowerCase()
                  .includes(e.currentTarget.value)) { return item; }
                }
    })
    setloadPost(filterBySearch);
}

//for event blog showall 
const handledropSearchChangePost=(e)=> {
  debugger
  const {name,value,type,checked}= e.currentTarget;

    if(value=='showAll'){
      loadPostList()
    }
    else{
      const filterBySearch = loadPost.filter((item) => {
        if ( item != null && item.type != null){
          if ( item?.type?.toLowerCase().includes(value)) { return item; }
        }
        
      })
      setloadPost(filterBySearch);
    }
    
}

const attendStatusCofeeMeet = async (id) => {
  


  debugger;
  const response = await axios.post(configData.ApiUrl+'/coffeeMeet/editGuestStatus?guestId=' + uerAuthId + '&coffeeMeetId=' + id)
  if (response.status == 200 && response.data.code) {
    toast.success("Update Status  Successfully!")
    loadPostList();

  }
  else {
    alert("Something Went Wrong..!")
  }
}



//for filter
const [spamDIv ,setspamDIv] = useState(false);
 const [ activeDiv,setactiveDiv] = useState(false);
 const [archiveDiv ,setarchiveDiv] = useState(false);
  const [allDiv ,setallDiv] = useState(false);


 const [filter, setfilter] = useState({
  filterstatus:""
});

 const handleChangeFilter = (e) => {
  debugger
  const { name, value, type, checked } = e.currentTarget;


  setfilter(preState => ({
        ...preState,
        [name]: type === 'checkbox' ? checked : value
    }));
  
    if(e.target.value=="Active"){
    //setisfilterdata( e.target.value )
    //setAadharDIv(true);
    setactiveDiv(true);
    setspamDIv(false)
    setarchiveDiv(false)
    setallDiv(false)

  }
  else if (e.target.value=="Spam") {
    //setAadharDIv(false);
    setspamDIv(true)
    setactiveDiv(false);
    setarchiveDiv(false)
    setallDiv(false)
  }
  else if (e.target.value=="Archived"){
    //setAadharDIv(false);
    setarchiveDiv(true)
    setactiveDiv(false);
    setspamDIv(false)
    setallDiv(false)
  }
  else{
    setallDiv(true)
    setactiveDiv(false);
    setspamDIv(false)
    setarchiveDiv(false)
  }


 }

 //for read more
 const [showFullDescription, setShowFullDescription] = useState(false);
  
    // Function to toggle description display
    const toggleDescription = () => {
      setShowFullDescription(!showFullDescription);
    };
//for close popup when click on outside
const popupLikeRef = useRef(null);
const handleClickOutsideLikediv = (event) => {
  if (popupLikeRef.current && !popupLikeRef.current.contains(event.target)) {
    setLikeDiv(false);
  }
};
useEffect(() => {
  document.addEventListener('mousedown', handleClickOutsideLikediv);
  return () => {
    document.removeEventListener('mousedown', handleClickOutsideLikediv);
  };
}, []);
//for randm color
   const colors = ["#ff8427", "#3498db", "#2ecc71", "#e74c3c", "#9b59b6", "#f1c40f", "#e67e22"];
   const getRandomColor = () => {
    return colors[Math.floor(Math.random() * colors.length)];
  };
  const [bgColor, setBgColor] = useState(""); // Initialize state for background color
  
    useEffect(() => {
      // Set random color only once during the initial render
      setBgColor(getRandomColor());
    }, []);

    //for edit event
    const [isOpenEditEventpop, setisOpenEditEventpop] = useState(false);
        const [idforeventEdit, setidforeventEdit] = useState(0);
      
        
        const toggleEditEventPop = (id) => {
          debugger;
          setisOpenEditEventpop(!isOpenEditEventpop);
          setidforeventEdit(id);
        }
    //for edit blog
    const [isOpenEditBlogpop, setisOpenEditBlogpop] = useState(false);
    const [idforBlogEdit, setidforBlogEdit] = useState(0);
  
    
    const toggleEditBlogPop = (id) => {
      setisOpenEditBlogpop(!isOpenEditBlogpop);
      setidforBlogEdit(id);
    }
    //for edit blog
    const [isOpenEditKycpop, setisOpenEditKycpop] = useState(false);
    const [idforKycEdit, setidforKycEdit] = useState(0);
  
    
    const toggleEditKycPop = (id) => {
      setisOpenEditKycpop(!isOpenEditKycpop);
      setidforKycEdit(id);
    }
     
    //for editblog
    const [BlogEditDiv ,setBlogEditDiv] = useState(false);
    function BlogEditDivpoupopen (isBlog){
      if(isBlog){
        setBlogEditDiv(true);
      }
      else{
        setBlogEditDiv(false);
      }
    }


    //for opening fields depends on radio cheked
      const [showhide, setShowhide]= useState("");
    
    
      const handleshow= e=>{
        const getshow= e.target.value;
        setShowhide(getshow);
      }
    
    const [blog, setBlog] = useState({
          hostId:authlocalstoragedata?.userAuthenticationId,
          authorName:authlocalstoragedata?.name2,
          blogTitle:"",
          imageSource:"",
          blogContent:"",
          publishStatus:"",
          scheduledDate:"",
          scheduledTime:"",
          
          })
          const [errorblog, setErrorblog] = useState(false);

          const submitFormDatablog = (e) => {
            debugger
            e.preventDefault();
        
            // checking if value of first name and last name is empty show error else take to step 2
             if (validator.isEmpty(blog.blogTitle)) {
              setErrorblog(true);
             }
             else if (blog.blogTitle.length > 70) {
              setErrorblog(true);
    
            }
             else if (validator.isEmpty(blog.imageSource)) {
              setErrorblog(true);
             }
             else if (blog.imageSource.length > 70) {
              setErrorblog(true);
    
            }
             else if (validator.isEmpty(blog.blogContent)) {
              setErrorblog(true);
             }
             else if (blog.blogContent.length > 7500) {
              setErrorblog(true);
    
            }
             else if (validator.isEmpty(blog.publishStatus)) {
              setErrorblog(true);
        
             }
             else if (validator.isEmpty(blog.scheduledDate) && blog.publishStatus=='schedulelater') {
              setErrorblog(true);
             }
             else if (validator.isEmpty(blog.scheduledTime) && blog.publishStatus=='schedulelater') {
              setErrorblog(true);
    
            }
           
           
         else {
          
          addBlogDetails();      
          
           }
        };
        const [imgDatablog, setImgDatablog] = useState(null);
        const [attachmentblog, setAttachmentblog] = useState(null)

              const filerefblog = useRef(null);
       
        const addBlogDetails =()=>{
          
          const formData = new FormData();
    
          if(blog.authorName!=undefined){
            setBlog(preState =>({
              ...preState,
              "authorName":authlocalstoragedata?.name2
            }))
          }
    
          if(blog.authorName!=undefined){
            setBlog(preState =>({
              ...preState,
              "hostId":authlocalstoragedata?.userAuthenticationId
            }))
          }
    debugger;
          formData.append('blog',JSON.stringify(blog));
          formData.append('bannerImage', attachmentblog);
    
          const response = axios.post(configData.ApiUrl+'/CommunityPost/createBlog', formData)
          response.then(() => {
                toast.success("Blog Added Successfully!");
    
              })
             response.catch((err) => {
               alert("Getting error in featching data")
             });
             console.log("response", response?.data)
              console.log("blog",blog)
         }
   
         const handleChangeblog = event => {
          setSelected(event.target.value);
          const {name,value,type,checked}=event.currentTarget;
              debugger
              
              setBlog(preState =>({
                ...preState,
                [name]:type ==='checkbox'?checked: value
              }))
      
              if(event.currentTarget.files != null)
              {
                  setBlog(event.target.files[0]);
              }
      
              if(blog.authorName!=undefined){
                setBlog(preState =>({
                  ...preState,
                  "authorName":authlocalstoragedata?.name2
                }))
              }
        
              if(blog.authorName!=undefined){
                setBlog(preState =>({
                  ...preState,
                  "hostId":authlocalstoragedata?.userAuthenticationId
                }))
              }
        };
        const banerImageChange = event=>{

          
              if (event.currentTarget.files[0]) {
                console.log("picture: ", event.target.files);
                setAttachmentblog(event.currentTarget.files[0]);
                const reader = new FileReader();
                reader.addEventListener("load", () => {
                  setImgDatablog(reader.result);
                });
                reader.readAsDataURL(event.currentTarget.files[0]);
              }
        }


        //for kyc

        const [KycEditDiv ,setKycEditDiv] = useState(false);
    function KycEditDivpoupopen (isKyc){
      if(isKyc){
        setKycEditDiv(true);
      }
      else{
        setKycEditDiv(false);
      }
    }

        const [serviceList, setServiceList] = useState([{ service: "" }]);
const filearr=[];
const handleServiceChange = (e, index) => {
  const { value } = e.target;
  const updatedList = [...serviceList];
  updatedList[index] = { service: value }; // Keep object format for UI updates
  setServiceList(updatedList);

  // Convert to string array before setting kyc
  setKyc(prevState => ({
    ...prevState,
    reference: updatedList.map(item => item.service) // Extract string values
  }));
};

  const handleServiceRemove = (index) => {
    const list = [...serviceList];
    list.splice(index, 1);
    setServiceList(list);
  };

  const handleServiceAdd = () => {
    setServiceList([...serviceList, { service: "" }]);
  };

    const [startDatekyc, setStartDatekyc] = useState(new Date());

    const handleChangedatekyc= (e) => {
      debugger
      setStartDatekyc(e)
     
     setKyc(preState => ({
      ...preState,
      "scheduledDate": (moment(e).format("DD MMM yyyy")),

    }))
    }
    

    const [kyc, setKyc] = useState({
      hostId:"",
      authorName:"",
      kycPerson:"",
      imageSource:"",
      kycContent:"",
      from:"",
      to:"",
      achievements:"",
      category:"",
      others:"",
      reference:[],
      publishStatus:"",
      scheduledDate:"",
      scheduledTime:"",
      
      })
      const [imgDatakyc, setImgDatakyc] = useState(null);
      const filerefkyc = useRef(null);
      

      const [attachmentkyc, setAttachmentkyc] = useState(null)

      const [errorkyc, setErrorkyc] = useState(false);
      const [MiddleNameEroor ,setMiddleNameEroor] = useState(false);
      const [referenceError, setReferenceError] = useState(false);

      const submitFormDatakyc = (e) => {
        debugger
        e.preventDefault();
        setKyc(prevState => ({
    ...prevState,
    reference: serviceList.map(item => item.service)
  }));
        let hasEmptyReference = false;

        // Check if any of the references are empty
        for (let service of serviceList) {
          if (service.service?.trim() === "") {
            hasEmptyReference = true;
            break;
          }
        }
      
        if (hasEmptyReference) {
          setReferenceError(true); // Set error if any reference is empty
        } else {
          setReferenceError(false);
        }
        // checking if value of first name and last name is empty show error else take to step 2
        if (validator.isEmpty(kyc.kycPerson)) {
            setErrorkyc(true);
        }
        else if (!/^[A-Za-z\s]*$/.test(kyc.kycPerson)) {
          setMiddleNameEroor(true);
        }
        else if (kyc.kycPerson.length > 75) {
          setErrorkyc(true);

        }
        else if (validator.isEmpty(kyc.to)) {
          setErrorkyc(true);
      }
      else if (validator.isEmpty(kyc.from)) {
        setErrorkyc(true);
      }
      else if (validator.isEmpty(kyc.achievements)) {
        setErrorkyc(true);
     }
      else if (validator.isEmpty(kyc.category)) {
       setErrorkyc(true);
      }
      else if (kyc.category === "Others" && (!kyc.others || validator.isEmpty(kyc.others))) {
    setErrorkyc(true);
}
        else if (validator.isEmpty(kyc.imageSource)) {
            setErrorkyc(true);
        }
        else if (hasEmptyReference) {
          setReferenceError(true); // This triggers the error display for the reference field
        } 
        
        else if (kyc.imageSource.length > 100) {
          setErrorkyc(true);

        }
        else if (validator.isEmpty(kyc.kycContent)) {
          setErrorkyc(true);
        }
        else if (kyc.kycContent.length > 7000) {
          setErrorkyc(true);

        }
        
        else if (validator.isEmpty(kyc.publishStatus)) {
          setErrorkyc(true);
    
        }
        else if (validator.isEmpty(kyc.scheduledDate) && kyc.publishStatus=='schedulelater') {
            setErrorkyc(true);
        }
        else if (validator.isEmpty(kyc.scheduledTime) && kyc.publishStatus=='schedulelater') {
            setErrorkyc(true);
        }
       else if (attachmentkyc == "" || attachmentkyc == null) {
        setErrorkyc(true);
       }
       
       
    else {
      
      addKycDetails();      
      
      }
    };

    const addKycDetails =()=>{
      
      const formData = new FormData();

      if(kyc.authorName!=undefined){
        setKyc(preState =>({
          ...preState,
          "authorName":authlocalstoragedata?.name2
        }))
      }

      if(kyc.authorName!=undefined){
        setKyc(preState =>({
          ...preState,
          "hostId":authlocalstoragedata?.userAuthenticationId
        }))
      }
      
      // setKyc(preState => ({
      //   ...preState,
      //   reference : serviceList
      // }))
      const formattedKyc = {
        ...kyc,
        reference: serviceList.map(item => item.service) // Convert objects to array of strings
      };
debugger;
      formData.append('kyc',JSON.stringify(kyc));
      formData.append('bannerImage', attachmentkyc);

      const response = axios.post(configData.ApiUrl+'/CommunityPost/editKycPost', formattedKyc)
      response.then(() => {
            toast.success("KYC Added Successfully!");
            setSelected("");

          })
         response.catch((err) => {
           alert("Getting error in featching data")

         });
         console.log("response", response?.data)
          console.log("kyc",kyc)
     }

     const banerImageChangekyc = event=>{

      // if(event.currentTarget.files != null)
      //    {
      //      setAttachment(event.target.files[0]);
      //    }
      if (event.currentTarget.files[0]) {
        console.log("picture: ", event.target.files);
        setAttachmentkyc(event.currentTarget.files[0]);
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setImgDatakyc(reader.result);
        });
        reader.readAsDataURL(event.currentTarget.files[0]);
      }
   }

    const [selected, setSelected] = useState('kyc');


  const handleChangekyc = event => {
    setSelected(event.target.value);
    const { name, value, type, checked } = event.target;

    setKyc(preState => ({
      ...preState,
      [name]: type === 'checkbox' ? checked : value
    }))
    if(event.currentTarget.files != null)
        {
            setKyc(event.target.files[0]);
        }
        if(kyc.authorName!=undefined){
          setKyc(preState =>({
            ...preState,
            "authorName":authlocalstoragedata?.name2
          }))
        }
  
        if(kyc.authorName!=undefined){
          setKyc(preState =>({
            ...preState,
            "hostId":authlocalstoragedata?.userAuthenticationId
          }))
        }

        setKyc(preState => ({
          ...preState,
          reference : serviceList
        }))
  };
 //for opening fields depends on radio cheked
 const [showhidekyc, setShowhidekyc]= useState("");


 const handleshowkyc= e=>{
   const getshow= e.target.value;
   setShowhidekyc(getshow);
 }

 //for job
const [JobEditDiv ,setJobEditDiv] = useState(false);
 
function JobEditDivpoupopen (isJob){
   if(isJob){
    setJobEditDiv(true);
   }
   else{
    setJobEditDiv(false);
   }
 }

 const [job, setJob] = useState({
  postedById:authlocalstoragedata?.userAuthenticationId,
  postedByName:authlocalstoragedata?.name2,
  jobOpeningFor:"",
  others:"",
  experience:"",
  availableIn:"",
  skillSets:"",
  email:authlocalstoragedata?.emailId,
  mobileNumber:authlocalstoragedata?.mobileNumber,
  
  })


  const [attachmentJob, setAttachmentJob] = useState([])

  const [errorJob, setErrorJob] = useState(false);

  const submitFormDataJob = (e) => {
    debugger
    e.preventDefault();

    // checking if value of first name and last name is empty show error else take to step 2
     if (validator.isEmpty(job.skillSets)) {
      setErrorJob(true);
     }
     else if (job.skillSets.length > 300) {
      setErrorJob(true);

    }
     else if (validator.isEmpty(job.availableIn)) {
      setErrorJob(true);
     }
     
     else if (validator.isEmpty(job.experience)) {
      setErrorJob(true);
     }
     
     else if (validator.isEmpty(job.jobOpeningFor)) {
      setErrorJob(true);

     }
     
   
   
 else {
  
  addJobDetails();      
  
   }
};

const addJobDetails =()=>{
  
  const formData = new FormData();

  if(job.authorName!=undefined){
    setJob(preState =>({
      ...preState,
      "postedByName":authlocalstoragedata?.name2
    }))
  }

  if(job.authorName!=undefined){
    setJob(preState =>({
      ...preState,
      "postedById":authlocalstoragedata?.userAuthenticationId
    }))
  }
debugger;
  formData.append('job',JSON.stringify(job));
  formData.append('resume', attachment);

  const response = axios.post(configData.ApiUrl+'/CommunityPost/createJobPost', formData)
  response.then(() => {
        toast.success("Job Post Added Successfully!");
        props.handleClose(togglePopuptopJobPop)

      })
     response.catch((err) => {
       alert("Getting error in featching data")
     });
     console.log("response", response?.data)
      console.log("job",job)
 }





const maxchar = 300;
const [charLeft, setCharLeft] = useState(maxchar);

const handleChangeJob = event => {
setSelected(event.target.value);
const {name,value,type,checked}=event.currentTarget;
    debugger
    
    setJob(preState =>({
      ...preState,
      [name]:type ==='checkbox'?checked: value
    }))

    if(event.currentTarget.files != null)
    {
        setJob(event.target.files[0]);
    }

    if(job.authorName!=undefined){
      setJob(preState =>({
        ...preState,
        "postedByName":authlocalstoragedata?.name2
      }))
    }

    if(job.authorName!=undefined){
      setJob(preState =>({
        ...preState,
        "postedById":authlocalstoragedata?.userAuthenticationId
      }))
    }

    //for dynamic placeholder
    debugger
    let input = event.target.value;
//  const maxchar = 300;
//  if(input==""|| input==null){
//   const charleft = maxchar - 0
//   setcharlenght(charleft);

//  }
//  else{
//   const charleft = maxchar - input.length
//   setcharlenght(charleft);

//  }
const remainingChars = maxchar - (input.length || 0); // Calculate remaining characters
setCharLeft(remainingChars > 0 ? remainingChars : 0); // Ensure it doesn't go below 0
};
const [selectedFilenameadharFront,setselectedFilenameadharFront]=useState(null)
const [fileSizeErr,setFileSizeErr]=useState(false)

const banerImageChangeJob = event=>{

 if(event.currentTarget.files != null)
    {
      setAttachment(event.target.files[0]);
    }
    setselectedFilenameadharFront(event.currentTarget.files[0])
    if (event.currentTarget.files[0]?.size > 3 * 1000 * 1024) {
      console.log("File with maximum size of 3MB is allowed");
      //return false;
      setFileSizeErr(true)
    }
}


//for opening fields depends on radio cheked

//for viewing   mbile
const [showMobileShow, setshowMobileShow]= useState(false);


const handleMobileshow=()=>{
setshowMobileShow(true);
}
//for viewing email 
const [showEmailShow, setshowEmailShow]= useState(false);


const handleEmailshow=()=>{
setshowEmailShow(true);
}

//for read more blog text
     const [expandedblog, setExpandedblog] = useState(false);


  
   //for read more kyc text

   const [expanded, setExpanded] = useState(false);

  return(
<div>
    {/* <!-- breadcrumb --> */}
    
    <Row className="row-sm hidescrolll"   style={{overflowY:"auto",height:"550px"}}>
      <Card.Body>
      <Col className='col-1'></Col>
      <Col className='col-11'>
      <div className="example" style={{backgroundColor:"#fff"}}>
 <div className='row'>
    <div className='col-2 textright'>
        <span className='event-sub-detail'>Posts:</span>
    </div>
    <div className='col-3'>
    <select   id="inputState" name='serchdropdown' onChange={handledropSearchChangePost}
            className="form-control" 
            style={{fontSize:"13px",height:'',marginTop:"-6px",paddingBottom:'6px'}}
            >
                <option selected value="" style={{fontSize:"13px",height:'30px'}}>Please Select</option>

            <option  value="showAll" style={{fontSize:"13px",height:'30px'}}>Show All</option>
            <option value="event"  style={{fontSize:"13px",height:'30px', marginTop:'-4px'}}>Event</option>
            <option value="blog"  style={{fontSize:"13px",height:'30px', marginTop:'-4px'}}>Blog</option>
            <option value="KycPost"  style={{fontSize:"13px",height:'30px', marginTop:'-4px'}}>KYC</option>
            <option value="coffeemeet"  style={{fontSize:"13px",height:'30px', marginTop:'-4px'}}>Coffee Meet</option>
            <option value="jobPost"  style={{fontSize:"13px",height:'30px', marginTop:'-4px'}}>Job Post</option>



            </select>
    </div>
    <div className='col-2 textright'>
    <span className='event-sub-detail'>Sort By:</span>
   </div>
    <div className='col-2'>
    <select   id="inputState" name='filterstatus' onChange={handleChangeFilter} 
            className="form-control " 
            style={{fontSize:"13px",height:'',marginTop:"-6px",paddingBottom:'6px'}}
            >
            <option selected value="Active" style={{fontSize:"13px",height:'30px'}}>Active</option>
            <option value="Archived"  style={{fontSize:"13px",height:'30px', marginTop:'-4px'}}>Archived</option>
            <option value="Spam"  style={{fontSize:"13px",height:'30px', marginTop:'-4px'}}>Spam</option>
            <option value="Scheduled"  style={{fontSize:"13px",height:'30px', marginTop:'-4px'}}>Scheduled</option>

            </select>
    </div>
    <div className='col-3'>
    <input type="text" class="form-control" placeholder="Search" onChange={handleSearchChangePost} style={{fontSize:"13px",marginTop:"-4px"}}/>

    </div>

 </div>
 {
 loadPost.map((getmenu,index)=>(

      <div>
      <div className={ activeDiv && getmenu.event?.activeStatus=='Active'?"":"hide"}>

      

          <div className={getmenu.event ? "radius-div-news margintop3" : "hide"}>
            <div className="row ">
                    <div className="col-lg-1 col-sm-12 col-md-12">
                        {getmenu.event?.profilePic == "" || getmenu.event?.profilePic == null ?
                        (<Avatar className="avtarimgsm" style={{ width: "50px",marginTop:"px", height: "49px",backgroundColor: bgColor,color:"#ffffff" }}>{getmenu.event?.hostName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.event?.profilePic} id='profpicdisablesave5' className=''style={{ width: "50px",marginTop:"px", height: "49px",borderRadius:"50%"}} />)
                      }
                    </div>
                    <div className="col-lg-5 col-sm-12 col-md-12 textleft" style={{marginTop:"4px"}}>
                      <span className="post-host-Name ">{getmenu.event?.hostName}</span>
                      <div className=""style={{marginTop:"-3px"}}><span className="event-date">{getmenu.event?.createdDate} @{getmenu.event?.createdTime}</span></div>
                      </div>
                    <div className="col-lg-2 col-sm-12" style={{ whiteSpace: "nowrap",marginTop:"px" }}>
                      </div>
                    <div className="col-lg-4 col-sm-12 col-md-12 textright"style={{marginTop:"4px"}}><span className="post-host-Name">{getmenu.event?.city}</span>
                   <div className=""style={{marginTop:"-3px"}}><span className="event-date ">Published by {getmenu.event?.hostName}</span></div> 
                   
                    </div>
                  </div>
                  <div className="row margintop1">
                    <div className="margintop1">
                   {/* <div className=""style={{marginTop:"-3px"}}><span className="event-date ">Published by {getmenu.event?.eventType}</span></div>  */}
                   <span className="post-host-Name" style={{color:"#626262",marginleft:'7px'}}>Event-</span> <span className="post-host-Name">{getmenu.event?.eventType}</span>
                  </div>
                  </div>
                 
                  <div className={getmenu.event?.imagesCount == 4 ? "" : "hide"}>
                                     <div className="row margintop3">
                                       {
                                         getmenu.event?.images?.map((gname, index) => (
                                           <div className="col-6">
                                             <img src={gname} className='' style={{ width: "326px", height: "100%" }} />
                 
                                           </div>
                                         ))
                                       }
                                       {/* <div className="col-6">
                             <img src={eventimg3}  className=''style={{  width:"333px",height:"100%"}}/>  
                 
                             </div> */}
                 
                                     </div>
                                   </div>
                                   <div className={getmenu.event?.imagesCount == 2 ? "" : "hide"}>
                                     
                                         <div className="row margintop">
                                         {
                                       getmenu.event?.images?.map((gname, index) => (
                                           <div className="col-lg-6 col-sm-12 col-md-12 margintop1">
                                             <img src={gname} className='' style={{ width: "326px", height: "100%" }} />
                 
                                           </div>
                  ))
                 }
                                           {/* <div className="col-6">
                             <img src={eventimg3}  className=''style={{  width:"333px",height:"100%"}}/>  
                 
                             </div> */}
                 
                                         </div>
                                      
                                   </div>
                                   <div className={getmenu.event?.imagesCount == 1 ? "" : "hide"}>
                                     
                                         <div className="row margintop">
                                         {
                                       getmenu.event?.images?.map((gname, index) => (
                                           <div className="col-12 col-md-12 col-sm-12 margintop1">
                                             <img src={gname} className='' style={{ width: "100%", height: "90%" }} />
                 
                                           </div>
                                            ))
                                           }
                 
                                           {/* <div className="col-6">
                             <img src={eventimg3}  className=''style={{  width:"333px",height:"100%"}}/>  
                 
                             </div> */}
                 
                                         </div>
                                      
                                   </div>
                                  <div className={getmenu.event?.imagesCount == 3 ? "" : "hide"}>
                 
                                     <div className="row margintop">
                                     {getmenu.event?.images?.map((gname, index) => (
                                     <div
                         className={`margintop1 ${index < 2 ? "col-6" : "col-12"}`}
                         key={index}
                       >
                         <img src={gname} className="" style={{ width: "100%", height: "auto" }} />
                       </div>
                 ))}
                 
                                       
                 
                                     </div>
                                     
                                   </div>
                                   <div className={getmenu.event?.imagesCount == 5 ? "" : "hide"}>
                 
                 <div className="row margintop">
                 {getmenu.event?.images?.map((gname, index) => (
                   <div
                         className={`margintop1 ${
                           index < 3 ? "col-4" : "col-6"
                         }`}
                         key={index}
                       >
                         <img src={gname} className="" style={{ width: "100%", height: "auto" }} />
                       </div>
                 ))}
                 
                   
                 
                 </div>
                 
                 </div>
                 
                  <div className="row">
                    <div className=""><span className="post-host-Name ">{getmenu.event?.hostName}</span></div>
                    <div className=""style={{marginTop:"2px"}}><span className="event-date ">{getmenu.event?.address} </span></div> 
                    <div className=""style={{marginTop:"-3px"}}><span className="event-date "> {getmenu.event?.city}, {getmenu.event?.state}</span></div>
                    <div className="margintop1"><span className="post-host-Name " style={{fontSize:'12px'}}>{getmenu.event?.eventDate}</span> <span className="post-host-Name "style={{fontSize:'12px',marginLeft:"15px"}}>{getmenu.event?.eventTime}</span></div>
                  </div>
                  <div className="row margintop3">
                    <div  className=""><div className="borderBottombtn" style={{marginLeft:"0px"}}></div></div>
                  </div>
                  <div className="row margintop3">
                                      <div className="col-lg-2 maxwd12 col-sm-4 col-md-6">
                                        {/* <BiLike className={status==='like'? 'activelike' : ''} onClick={handleClickLike}/><span className="likespn marginleft2"> {likes}</span> */}
                                        {getmenu.event?.likeStatus == true ? <AiFillLike color="#626262" size="1.3em" onClick={() => addLikes(getmenu.event?.id)} style={{ cursor: "pointer" }} /> : <BiLike onClick={() => addLikes(getmenu.event?.id)} size="1.3em" style={{ cursor: "pointer" }} color="#D9D9D9" />}<span className="post-name-btn " style={{color:"#626262",fontSize:'14px',marginLeft:"4px"}} onClick={() => loadLikesInfo(getmenu.event?.id)}> {getmenu.event?.likeCount} </span>
                                      </div>
                                      <div className="col-lg-2 maxwd12 col-md-4 col-sm-4" onClick={() => commentInfo(getmenu.event?.id)} style={{ cursor: "pointer" }}><FaRegComments size="1.3em" color="#626262" /><span className="post-name-btn " style={{color:"#626262",fontSize:'14px',marginLeft:"6px"}}>{getmenu.event?.commentCount} </span></div>
                                      <div className="col-lg-7"></div>

                                      
                                      <div className="col-lg-2 displyinline">
                                      {getmenu.event?.userSpamStatus == false ?
                                        (
                                          <div className="col-lg-1 col-sm-5 col-md-4 " onClick={() => addSpam(getmenu.event?.id)} style={{ cursor: "pointer" }}><RiSpam2Line color="#D9D9D9" size="1.3em"/> </div>
                  
                                        ) : (
                                          <div className="col-1 " onClick={() => addSpam(getmenu.event?.id)} style={{ cursor: "pointer" }}><RiSpam2Line color="#626262" size="1.3em"/> </div>
                  
                                        )
                                      }
                                      {getmenu.event?.spamCount >=3 ?
              (
              <span className='viewsub-activ-spn'style={{float:'right',whiteSpace:"nowrap",backgroundColor:"rgba(211, 15, 69, 1)",color:"#F2F2F2",marginLeft:'20px'}} >Spam</span>
              ):
              (
              <span className='viewsub-activ-spn'style={{float:'right',whiteSpace:"nowrap",marginLeft:'20px'}} >Active</span>
              )
              }
                                      </div>
                  
                                    </div>
                                    <div className='row margintop1'>
          <div className='col-4'></div>
          <div className="col-lg-2 maxwd22 col-sm-4 col-md-6 report-spm-div-delete"  style={{ cursor: "pointer" }}><MdOutlineDelete size="1.3em" color="#FF8427" style={{marginLeft:"-7px"}}/><span className="marginleft2 report-spm" style={{color:"#FF8427"}}>Delete</span> </div>
          <div className='col-1 maxwd33'></div>
          <div className="col-lg-2 maxwd22 col-sm-4 col-md-6 report-spm-div"  onClick={() => loadLikesInfo(getmenu.event?.id)} style={{ cursor: "pointer" }}><RiEdit2Line size="1.3em" color="#FFFFFF" /><span className="marginleft2 report-spm">Edit</span> </div>
          <div className='col-3'></div>

          </div>
                                    <Card className={comment && getmenu.event?.id == cmntEventId ? "margintop3" : "hide row"} >
                                      <div className="">
                                        <div className="" onClick={() => Commentpoupopen(false)} style={{float:"right",marginRight:"10px",cursor:"pointer",fontSize:'13px'}}>X</div>
                                        <Card className="overflow-hidden" style={{ height: "auto", border: "none" }}>
                                          {/* <Card className="mb-lg-0" style={{ height: "auto", border: "none" }}> */}
                  
                                            <Card.Body>
                                              <div className="mt-2">
                                                <FormGroup className="form-group " >
                                                  <Form.Control
                                                    type="text"
                                                    className="form-control borderadius25px "
                                                    id="name1"
                                                    name="txtCmnt"
                                                    placeholder="Add a Comment"
                                                    style={{ fontSize: "13px", borderRadius: "25px" }}
                                                    onChange={handleChange}
                                                    onKeyPress={handleKeypress}
                                                    value={cmntValue.txtCmnt}
                                                  />
                                                  <label className={commentValiderr ? "event-sub-detail1" : "hide"} style={{ color: "red" }}>Please Add Comment</label>
                  
                                                </FormGroup>
                  
                                                <button className="attendbtn" style={{ width: "90px", height: "28px" }} onClick={() => saveComent(getmenu.event?.id)}>
                                                  Send Reply
                                                </button>
                                                {/* <span onClick={() => Commentpoupopen(false)}>close</span> */}
                                              </div>
                                            </Card.Body>
                                          {/* </Card> */}
                                        </Card>
                                      </div>
                                      {
  commentData.map((commentmenu, index) => {
    // Extract first letter of first name and first letter of last name
    const nameParts = commentmenu.viewerName ? commentmenu.viewerName.split(" ") : [];
    const initials =
      nameParts.length > 1
        ? `${nameParts[0][0].toUpperCase()}${nameParts[nameParts.length - 1][0].toUpperCase()}`
        : nameParts[0]
        ? nameParts[0][0].toUpperCase()
        : "";

    return (
      <div key={index}>
        <Card className="" style={{ height: "auto", border: "none" }}>
          <Card.Body>
            <div className="d-sm-flex mt-0 p-3 sub-review-section border br-bl-0 br-br-0">
              <div className="media-body">
                {/* Flex container to keep avatar and name in one line */}
                <h6
                  className="mt-0 mb-1 font-12 font-weight-semibold"
                  style={{
                    fontSize: "13px",
                    display:"inline-flex",
                  }}
                >
                  {/* Profile Picture or Avatar */}
                  {commentmenu.profilePicture ? (
                    <img
                      src={commentmenu.profilePicture}
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        marginRight:"6px",
                        marginTop:"-6px"
                      }}
                    />
                  ) : (
                    <Avatar
                      style={{
                        width: "30px",
                        height: "30px",
                        fontSize: "12px",
                        backgroundColor: bgColor,
                        color: "#ffffff",
                        marginTop:"-6px",
                        marginRight:"6px"
                      }}
                    >
                      {initials}
                    </Avatar>
                  )}

                  {/* Viewer Name */}
                  {commentmenu.viewerName}
                </h6>

                {/* Comment Text */}
                <p className="font-11 mb-2 mt-2" style={{ fontSize: "12px" }}>
                  {commentmenu.comment}
                </p>

                {/* Delete Badge */}
                <Badge
                  bg=""
                  className={commentmenu.viewerId == uerAuthId ? "bg-danger" : "hide"}
                  onClick={() => deleteComment(commentmenu.id)}
                  style={{ cursor: "pointer" }}
                >
                  Delete
                </Badge>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  })
}
                  
                                    </Card>
            </div>
          </div>

          
          
          
          <div className={spamDIv && getmenu.event?.userSpamStatus==true?"":"hide"}>


   
    <div className={getmenu.event ? "radius-div-news margintop3" : "hide"}>
            <div className="row ">
                    <div className="col-lg-1 col-sm-12 col-md-12">
                        {getmenu.event?.profilePic == "" || getmenu.event?.profilePic == null ?
                        (<Avatar className="avtarimgsm" style={{ width: "50px",marginTop:"px", height: "49px",backgroundColor: bgColor,color:"#ffffff" }}>{getmenu.event?.hostName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.event?.profilePic} id='profpicdisablesave5' className=''style={{ width: "50px",marginTop:"px", height: "49px",borderRadius:"50%"}} />)
                      }
                    </div>
                    <div className="col-lg-5 col-sm-12 col-md-12 textleft" style={{marginTop:"4px"}}>
                      <span className="post-host-Name ">{getmenu.event?.hostName}</span>
                      <div className=""style={{marginTop:"-3px"}}><span className="event-date">{getmenu.event?.createdDate} @{getmenu.event?.createdTime}</span></div>
                      </div>
                    <div className="col-lg-2 col-sm-12" style={{ whiteSpace: "nowrap",marginTop:"px" }}>
                      </div>
                    <div className="col-lg-4 col-sm-12 col-md-12 textright"style={{marginTop:"4px"}}><span className="post-host-Name">{getmenu.event?.city}</span>
                   <div className=""style={{marginTop:"-3px"}}><span className="event-date ">Published by {getmenu.event?.hostName}</span></div> 
                   
                    </div>
                  </div>
                  <div className="row margintop1">
                    <div className="margintop1">
                   {/* <div className=""style={{marginTop:"-3px"}}><span className="event-date ">Published by {getmenu.event?.eventType}</span></div>  */}
                   <span className="post-host-Name" style={{color:"#626262",marginleft:'7px'}}>Event-</span> <span className="post-host-Name">{getmenu.event?.eventType}</span>
                  </div>
                  </div>
                 
                  <div className={getmenu.event?.imagesCount == 4 ? "" : "hide"}>
                                     <div className="row margintop3">
                                       {
                                         getmenu.event?.images?.map((gname, index) => (
                                           <div className="col-6">
                                             <img src={gname} className='' style={{ width: "326px", height: "100%" }} />
                 
                                           </div>
                                         ))
                                       }
                                       {/* <div className="col-6">
                             <img src={eventimg3}  className=''style={{  width:"333px",height:"100%"}}/>  
                 
                             </div> */}
                 
                                     </div>
                                   </div>
                                   <div className={getmenu.event?.imagesCount == 2 ? "" : "hide"}>
                                     
                                         <div className="row margintop">
                                         {
                                       getmenu.event?.images?.map((gname, index) => (
                                           <div className="col-lg-6 col-sm-12 col-md-12 margintop1">
                                             <img src={gname} className='' style={{ width: "326px", height: "100%" }} />
                 
                                           </div>
                  ))
                 }
                                           {/* <div className="col-6">
                             <img src={eventimg3}  className=''style={{  width:"333px",height:"100%"}}/>  
                 
                             </div> */}
                 
                                         </div>
                                      
                                   </div>
                                   <div className={getmenu.event?.imagesCount == 1 ? "" : "hide"}>
                                     
                                         <div className="row margintop">
                                         {
                                       getmenu.event?.images?.map((gname, index) => (
                                           <div className="col-12 col-md-12 col-sm-12 margintop1">
                                             <img src={gname} className='' style={{ width: "100%", height: "90%" }} />
                 
                                           </div>
                                            ))
                                           }
                 
                                           {/* <div className="col-6">
                             <img src={eventimg3}  className=''style={{  width:"333px",height:"100%"}}/>  
                 
                             </div> */}
                 
                                         </div>
                                      
                                   </div>
                                  <div className={getmenu.event?.imagesCount == 3 ? "" : "hide"}>
                 
                                     <div className="row margintop">
                                     {getmenu.event?.images?.map((gname, index) => (
                                     <div
                         className={`margintop1 ${index < 2 ? "col-6" : "col-12"}`}
                         key={index}
                       >
                         <img src={gname} className="" style={{ width: "100%", height: "auto" }} />
                       </div>
                 ))}
                 
                                       
                 
                                     </div>
                                     
                                   </div>
                                   <div className={getmenu.event?.imagesCount == 5 ? "" : "hide"}>
                 
                 <div className="row margintop">
                 {getmenu.event?.images?.map((gname, index) => (
                   <div
                         className={`margintop1 ${
                           index < 3 ? "col-4" : "col-6"
                         }`}
                         key={index}
                       >
                         <img src={gname} className="" style={{ width: "100%", height: "auto" }} />
                       </div>
                 ))}
                 
                   
                 
                 </div>
                 
                 </div>
                 
                  <div className="row">
                    <div className=""><span className="post-host-Name ">{getmenu.event?.hostName}</span></div>
                    <div className=""style={{marginTop:"2px"}}><span className="event-date ">{getmenu.event?.address} </span></div> 
                    <div className=""style={{marginTop:"-3px"}}><span className="event-date "> {getmenu.event?.city}, {getmenu.event?.state}</span></div>
                    <div className="margintop1"><span className="post-host-Name " style={{fontSize:'12px'}}>{getmenu.event?.eventDate}</span> <span className="post-host-Name "style={{fontSize:'12px',marginLeft:"15px"}}>{getmenu.event?.eventTime}</span></div>
                  </div>
                  <div className="row margintop3">
                    <div  className=""><div className="borderBottombtn" style={{marginLeft:"0px"}}></div></div>
                  </div>
                  <div className="row margintop3">
                                      <div className="col-lg-2 maxwd12 col-sm-4 col-md-6">
                                        {/* <BiLike className={status==='like'? 'activelike' : ''} onClick={handleClickLike}/><span className="likespn marginleft2"> {likes}</span> */}
                                        {getmenu.event?.likeStatus == true ? <AiFillLike color="#626262" size="1.3em" onClick={() => addLikes(getmenu.event?.id)} style={{ cursor: "pointer" }} /> : <BiLike onClick={() => addLikes(getmenu.event?.id)} size="1.3em" style={{ cursor: "pointer" }} color="#D9D9D9" />}<span className="post-name-btn " style={{color:"#626262",fontSize:'14px',marginLeft:"4px",cursor:"pointer"}} onClick={() => loadLikesInfo(getmenu.event?.id)}> {getmenu.event?.likeCount} </span>
                                      </div>
                                      <div className="col-lg-2 maxwd12 col-md-4 col-sm-4" onClick={() => commentInfo(getmenu.event?.id)} style={{ cursor: "pointer" }}><FaRegComments size="1.3em" color="#626262" /><span className="post-name-btn " style={{color:"#626262",fontSize:'14px',marginLeft:"6px"}}>{getmenu.event?.commentCount} </span></div>
                                      <div className="col-lg-7"></div>

                                      
                                      <div className="col-lg-2 displyinline">
                                      {getmenu.event?.userSpamStatus == false ?
                                        (
                                          <div className="col-lg-1 col-sm-5 col-md-4 " onClick={() => addSpam(getmenu.event?.id)} style={{ cursor: "pointer" }}><RiSpam2Line color="#D9D9D9" size="1.3em"/> </div>
                  
                                        ) : (
                                          <div className="col-1 " onClick={() => addSpam(getmenu.event?.id)} style={{ cursor: "pointer" }}><RiSpam2Line color="#626262" size="1.3em"/> </div>
                  
                                        )
                                      }
                                      {getmenu.event?.spamCount >=3 ?
        (
        <span className='viewsub-activ-spn'style={{float:'right',whiteSpace:"nowrap",backgroundColor:"rgba(211, 15, 69, 1)",color:"#F2F2F2",marginLeft:'20px'}} >Spam</span>
        ):
        (
        <span className='viewsub-activ-spn'style={{float:'right',whiteSpace:"nowrap",backgroundColor:"rgba(211, 15, 69, 1)",color:"#F2F2F2",marginLeft:'20px'}} >Spam</span>
        )
        }
                                      </div>
                  
                                    </div>
                                    <div className='row margintop1'>
          <div className='col-4'></div>
          <div className="col-lg-2 maxwd22 col-sm-4 col-md-6 report-spm-div-delete"  style={{ cursor: "pointer" }}><MdOutlineDelete size="1.3em" color="#FF8427" style={{marginLeft:"-7px"}}/><span className="marginleft2 report-spm" style={{color:"#FF8427"}}>Delete</span> </div>
          <div className='col-1 maxwd33'></div>
          <div className="col-lg-2 maxwd22 col-sm-4 col-md-6 report-spm-div"  onClick={() => loadPost1(getmenu.event?.id, getmenu.event?.type)} style={{ cursor: "pointer" }}><RiEdit2Line size="1.3em" color="#FFFFFF" /><span className="marginleft2 report-spm">Edit</span> </div>
          <div className='col-3'></div>
          </div>
                                    <Card className={comment && getmenu.event?.id == cmntEventId ? "margintop3" : "hide row"} >
                                      <div className="">
                                        <div className="" onClick={() => Commentpoupopen(false)} style={{float:"right",marginRight:"10px",cursor:"pointer",fontSize:'13px'}}>X</div>
                                        <Card className="overflow-hidden" style={{ height: "auto", border: "none" }}>
                                          {/* <Card className="mb-lg-0" style={{ height: "auto", border: "none" }}> */}
                  
                                            <Card.Body>
                                              <div className="mt-2">
                                                <FormGroup className="form-group " >
                                                  <Form.Control
                                                    type="text"
                                                    className="form-control borderadius25px "
                                                    id="name1"
                                                    name="txtCmnt"
                                                    placeholder="Add a Comment"
                                                    style={{ fontSize: "13px", borderRadius: "25px" }}
                                                    onChange={handleChange}
                                                    onKeyPress={handleKeypress}
                                                    value={cmntValue.txtCmnt}
                                                  />
                                                  <label className={commentValiderr ? "event-sub-detail1" : "hide"} style={{ color: "red" }}>Please Add Comment</label>
                  
                                                </FormGroup>
                  
                                                <button className="attendbtn" style={{ width: "90px", height: "28px" }} onClick={() => saveComent(getmenu.event?.id)}>
                                                  Send Reply
                                                </button>
                                                {/* <span onClick={() => Commentpoupopen(false)}>close</span> */}
                                              </div>
                                            </Card.Body>
                                          {/* </Card> */}
                                        </Card>
                                      </div>
                                      {
  commentData.map((commentmenu, index) => {
    // Extract first letter of first name and first letter of last name
    const nameParts = commentmenu.viewerName ? commentmenu.viewerName.split(" ") : [];
    const initials =
      nameParts.length > 1
        ? `${nameParts[0][0].toUpperCase()}${nameParts[nameParts.length - 1][0].toUpperCase()}`
        : nameParts[0]
        ? nameParts[0][0].toUpperCase()
        : "";

    return (
      <div key={index}>
        <Card className="" style={{ height: "auto", border: "none" }}>
          <Card.Body>
            <div className="d-sm-flex mt-0 p-3 sub-review-section border br-bl-0 br-br-0">
              <div className="media-body">
                {/* Flex container to keep avatar and name in one line */}
                <h6
                  className="mt-0 mb-1 font-12 font-weight-semibold"
                  style={{
                    fontSize: "13px",
                    display:"inline-flex",
                  }}
                >
                  {/* Profile Picture or Avatar */}
                  {commentmenu.profilePicture ? (
                    <img
                      src={commentmenu.profilePicture}
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        marginRight:"6px",
                        marginTop:"-6px"
                      }}
                    />
                  ) : (
                    <Avatar
                      style={{
                        width: "30px",
                        height: "30px",
                        fontSize: "12px",
                        backgroundColor: bgColor,
                        color: "#ffffff",
                        marginTop:"-6px",
                        marginRight:"6px"
                      }}
                    >
                      {initials}
                    </Avatar>
                  )}

                  {/* Viewer Name */}
                  {commentmenu.viewerName}
                </h6>

                {/* Comment Text */}
                <p className="font-11 mb-2 mt-2" style={{ fontSize: "12px" }}>
                  {commentmenu.comment}
                </p>

                {/* Delete Badge */}
                <Badge
                  bg=""
                  className={commentmenu.viewerId == uerAuthId ? "bg-danger" : "hide"}
                  onClick={() => deleteComment(commentmenu.id)}
                  style={{ cursor: "pointer" }}
                >
                  Delete
                </Badge>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  })
}
                  
                                    </Card>
            </div>
    </div> 

    <div className={ archiveDiv && getmenu.event?.activeStatus=='Archived'?"":"hide"}>
   
    <div className={getmenu.event ? "radius-div-news margintop3" : "hide"}>
            <div className="row ">
                    <div className="col-lg-1 col-sm-12 col-md-12">
                        {getmenu.event?.profilePic == "" || getmenu.event?.profilePic == null ?
                        (<Avatar className="avtarimgsm" style={{ width: "50px",marginTop:"px", height: "49px",backgroundColor: bgColor,color:"#ffffff" }}>{getmenu.event?.hostName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.event?.profilePic} id='profpicdisablesave5' className=''style={{ width: "50px",marginTop:"px", height: "49px",borderRadius:"50%"}} />)
                      }
                    </div>
                    <div className="col-lg-5 col-sm-12 col-md-12 textleft" style={{marginTop:"4px"}}>
                      <span className="post-host-Name ">{getmenu.event?.hostName}</span>
                      <div className=""style={{marginTop:"-3px"}}><span className="event-date">{getmenu.event?.createdDate} @{getmenu.event?.createdTime}</span></div>
                      </div>
                    <div className="col-lg-2 col-sm-12" style={{ whiteSpace: "nowrap",marginTop:"px" }}>
                      </div>
                    <div className="col-lg-4 col-sm-12 col-md-12 textright"style={{marginTop:"4px"}}><span className="post-host-Name">{getmenu.event?.city}</span>
                   <div className=""style={{marginTop:"-3px"}}><span className="event-date ">Published by {getmenu.event?.hostName}</span></div> 
                   
                    </div>
                  </div>
                  <div className="row margintop1">
                    <div className="margintop1">
                   {/* <div className=""style={{marginTop:"-3px"}}><span className="event-date ">Published by {getmenu.event?.eventType}</span></div>  */}
                   <span className="post-host-Name" style={{color:"#626262",marginleft:'7px'}}>Event-</span> <span className="post-host-Name">{getmenu.event?.eventType}</span>
                  </div>
                  </div>
                 
                  <div className={getmenu.event?.imagesCount == 4 ? "" : "hide"}>
                                     <div className="row margintop3">
                                       {
                                         getmenu.event?.images?.map((gname, index) => (
                                           <div className="col-6">
                                             <img src={gname} className='' style={{ width: "326px", height: "100%" }} />
                 
                                           </div>
                                         ))
                                       }
                                       {/* <div className="col-6">
                             <img src={eventimg3}  className=''style={{  width:"333px",height:"100%"}}/>  
                 
                             </div> */}
                 
                                     </div>
                                   </div>
                                   <div className={getmenu.event?.imagesCount == 2 ? "" : "hide"}>
                                     
                                         <div className="row margintop">
                                         {
                                       getmenu.event?.images?.map((gname, index) => (
                                           <div className="col-lg-6 col-sm-12 col-md-12 margintop1">
                                             <img src={gname} className='' style={{ width: "326px", height: "100%" }} />
                 
                                           </div>
                  ))
                 }
                                           {/* <div className="col-6">
                             <img src={eventimg3}  className=''style={{  width:"333px",height:"100%"}}/>  
                 
                             </div> */}
                 
                                         </div>
                                      
                                   </div>
                                   <div className={getmenu.event?.imagesCount == 1 ? "" : "hide"}>
                                     
                                         <div className="row margintop">
                                         {
                                       getmenu.event?.images?.map((gname, index) => (
                                           <div className="col-12 col-md-12 col-sm-12 margintop1">
                                             <img src={gname} className='' style={{ width: "100%", height: "90%" }} />
                 
                                           </div>
                                            ))
                                           }
                 
                                           {/* <div className="col-6">
                             <img src={eventimg3}  className=''style={{  width:"333px",height:"100%"}}/>  
                 
                             </div> */}
                 
                                         </div>
                                      
                                   </div>
                                  <div className={getmenu.event?.imagesCount == 3 ? "" : "hide"}>
                 
                                     <div className="row margintop">
                                     {getmenu.event?.images?.map((gname, index) => (
                                     <div
                         className={`margintop1 ${index < 2 ? "col-6" : "col-12"}`}
                         key={index}
                       >
                         <img src={gname} className="" style={{ width: "100%", height: "auto" }} />
                       </div>
                 ))}
                 
                                       
                 
                                     </div>
                                     
                                   </div>
                                   <div className={getmenu.event?.imagesCount == 5 ? "" : "hide"}>
                 
                 <div className="row margintop">
                 {getmenu.event?.images?.map((gname, index) => (
                   <div
                         className={`margintop1 ${
                           index < 3 ? "col-4" : "col-6"
                         }`}
                         key={index}
                       >
                         <img src={gname} className="" style={{ width: "100%", height: "auto" }} />
                       </div>
                 ))}
                 
                   
                 
                 </div>
                 
                 </div>
                 
                  <div className="row">
                    <div className=""><span className="post-host-Name ">{getmenu.event?.hostName}</span></div>
                    <div className=""style={{marginTop:"2px"}}><span className="event-date ">{getmenu.event?.address} </span></div> 
                    <div className=""style={{marginTop:"-3px"}}><span className="event-date "> {getmenu.event?.city}, {getmenu.event?.state}</span></div>
                    <div className="margintop1"><span className="post-host-Name " style={{fontSize:'12px'}}>{getmenu.event?.eventDate}</span> <span className="post-host-Name "style={{fontSize:'12px',marginLeft:"15px"}}>{getmenu.event?.eventTime}</span></div>
                  </div>
                  <div className="row margintop3">
                    <div  className=""><div className="borderBottombtn" style={{marginLeft:"0px"}}></div></div>
                  </div>
                  <div className="row margintop3">
                                      <div className="col-lg-2 maxwd12 col-sm-4 col-md-6">
                                        {/* <BiLike className={status==='like'? 'activelike' : ''} onClick={handleClickLike}/><span className="likespn marginleft2"> {likes}</span> */}
                                        {getmenu.event?.likeStatus == true ? <AiFillLike color="#626262" size="1.3em" onClick={() => addLikes(getmenu.event?.id)} style={{ cursor: "pointer" }} /> : <BiLike onClick={() => addLikes(getmenu.event?.id)} size="1.3em" style={{ cursor: "pointer" }} color="#D9D9D9" />}<span className="post-name-btn " style={{color:"#626262",fontSize:'14px',marginLeft:"4px",cursor:"pointer"}} onClick={() => loadLikesInfo(getmenu.event?.id)}> {getmenu.event?.likeCount} </span>
                                      </div>
                                      <div className="col-lg-2 maxwd12 col-md-4 col-sm-4" onClick={() => commentInfo(getmenu.event?.id)} style={{ cursor: "pointer" }}><FaRegComments size="1.3em" color="#626262" /><span className="post-name-btn " style={{color:"#626262",fontSize:'14px',marginLeft:"6px"}}>{getmenu.event?.commentCount} </span></div>
                                      <div className="col-lg-7"></div>

                                      
                                      <div className="col-lg-2 displyinline">
                                      {getmenu.event?.userSpamStatus == false ?
                                        (
                                          <div className="col-lg-1 col-sm-5 col-md-4 " onClick={() => addSpam(getmenu.event?.id)} style={{ cursor: "pointer" }}><RiSpam2Line color="#D9D9D9" size="1.3em"/> </div>
                  
                                        ) : (
                                          <div className="col-1 " onClick={() => addSpam(getmenu.event?.id)} style={{ cursor: "pointer" }}><RiSpam2Line color="#626262" size="1.3em"/> </div>
                  
                                        )
                                      }
                                      {getmenu.event?.spamCount >=3 ?
        (
        <span className='viewsub-activ-spn'style={{float:'right',whiteSpace:"nowrap",backgroundColor:"rgba(211, 15, 69, 1)",color:"#F2F2F2",marginLeft:'20px'}} >Spam</span>
        ):
        (
        <span className='viewsub-activ-spn'style={{float:'right',whiteSpace:"nowrap",backgroundColor:"orange",color:"#F2F2F2",marginLeft:'20px'}} >Archived</span>
        )
        }
                                      </div>
                  
                                    </div>
                                    <div className='row margintop1'>
          <div className='col-4'></div>
          <div className="col-lg-2 maxwd22 col-sm-4 col-md-6 report-spm-div-delete"  style={{ cursor: "pointer" }}><MdOutlineDelete size="1.3em" color="#FF8427" style={{marginLeft:"-7px"}}/><span className="marginleft2 report-spm" style={{color:"#FF8427"}}>Delete</span> </div>
          <div className='col-1 maxwd33'></div>
          <div className="col-lg-2 maxwd22 col-sm-4 col-md-6 report-spm-div"  onClick={() => loadPost1(getmenu.event?.id,getmenu.event?.type)} style={{ cursor: "pointer" }}><RiEdit2Line size="1.3em" color="#FFFFFF" /><span className="marginleft2 report-spm">Edit</span> </div>
          <div className='col-3'></div>
          </div>
                                    <Card className={comment && getmenu.event?.id == cmntEventId ? "margintop3" : "hide row"} >
                                      <div className="">
                                        <div className="" onClick={() => Commentpoupopen(false)} style={{float:"right",marginRight:"10px",cursor:"pointer",fontSize:'13px'}}>X</div>
                                        <Card className="overflow-hidden" style={{ height: "auto", border: "none" }}>
                                          {/* <Card className="mb-lg-0" style={{ height: "auto", border: "none" }}> */}
                  
                                            <Card.Body>
                                              <div className="mt-2">
                                                <FormGroup className="form-group " >
                                                  <Form.Control
                                                    type="text"
                                                    className="form-control borderadius25px "
                                                    id="name1"
                                                    name="txtCmnt"
                                                    placeholder="Add a Comment"
                                                    style={{ fontSize: "13px", borderRadius: "25px" }}
                                                    onChange={handleChange}
                                                    onKeyPress={handleKeypress}
                                                    value={cmntValue.txtCmnt}
                                                  />
                                                  <label className={commentValiderr ? "event-sub-detail1" : "hide"} style={{ color: "red" }}>Please Add Comment</label>
                  
                                                </FormGroup>
                  
                                                <button className="attendbtn" style={{ width: "90px", height: "28px" }} onClick={() => saveComent(getmenu.event?.id)}>
                                                  Send Reply
                                                </button>
                                                {/* <span onClick={() => Commentpoupopen(false)}>close</span> */}
                                              </div>
                                            </Card.Body>
                                          {/* </Card> */}
                                        </Card>
                                      </div>
                                      {
  commentData.map((commentmenu, index) => {
    // Extract first letter of first name and first letter of last name
    const nameParts = commentmenu.viewerName ? commentmenu.viewerName.split(" ") : [];
    const initials =
      nameParts.length > 1
        ? `${nameParts[0][0].toUpperCase()}${nameParts[nameParts.length - 1][0].toUpperCase()}`
        : nameParts[0]
        ? nameParts[0][0].toUpperCase()
        : "";

    return (
      <div key={index}>
        <Card className="" style={{ height: "auto", border: "none" }}>
          <Card.Body>
            <div className="d-sm-flex mt-0 p-3 sub-review-section border br-bl-0 br-br-0">
              <div className="media-body">
                {/* Flex container to keep avatar and name in one line */}
                <h6
                  className="mt-0 mb-1 font-12 font-weight-semibold"
                  style={{
                    fontSize: "13px",
                    display:"inline-flex",
                  }}
                >
                  {/* Profile Picture or Avatar */}
                  {commentmenu.profilePicture ? (
                    <img
                      src={commentmenu.profilePicture}
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        marginRight:"6px",
                        marginTop:"-6px"
                      }}
                    />
                  ) : (
                    <Avatar
                      style={{
                        width: "30px",
                        height: "30px",
                        fontSize: "12px",
                        backgroundColor: bgColor,
                        color: "#ffffff",
                        marginTop:"-6px",
                        marginRight:"6px"
                      }}
                    >
                      {initials}
                    </Avatar>
                  )}

                  {/* Viewer Name */}
                  {commentmenu.viewerName}
                </h6>

                {/* Comment Text */}
                <p className="font-11 mb-2 mt-2" style={{ fontSize: "12px" }}>
                  {commentmenu.comment}
                </p>

                {/* Delete Badge */}
                <Badge
                  bg=""
                  className={commentmenu.viewerId == uerAuthId ? "bg-danger" : "hide"}
                  onClick={() => deleteComment(commentmenu.id)}
                  style={{ cursor: "pointer" }}
                >
                  Delete
                </Badge>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  })
}
                  
                                    </Card>
            </div>
    
    </div> 
    <div className={archiveDiv==false && spamDIv==false && activeDiv==false && getmenu.event ?'example margintop3':'hide'}>
    
    <div className={getmenu.event ? "radius-div-news margintop3" : "hide"}>
            <div className="row ">
                    <div className="col-lg-1 col-sm-12 col-md-12">
                        {getmenu.event?.profilePic == "" || getmenu.event?.profilePic == null ?
                        (<Avatar className="avtarimgsm" style={{ width: "50px",marginTop:"px", height: "49px",backgroundColor: bgColor,color:"#ffffff" }}>{getmenu.event?.hostName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.event?.profilePic} id='profpicdisablesave5' className=''style={{ width: "50px",marginTop:"px", height: "49px",borderRadius:"50%"}} />)
                      }
                    </div>
                    <div className="col-lg-5 col-sm-12 col-md-12 textleft" style={{marginTop:"4px"}}>
                      <span className="post-host-Name ">{getmenu.event?.hostName}</span>
                      <div className=""style={{marginTop:"-3px"}}><span className="event-date">{getmenu.event?.createdDate} @{getmenu.event?.createdTime}</span></div>
                      </div>
                    <div className="col-lg-2 col-sm-12" style={{ whiteSpace: "nowrap",marginTop:"px" }}>
                      </div>
                    <div className="col-lg-4 col-sm-12 col-md-12 textright"style={{marginTop:"4px"}}><span className="post-host-Name">{getmenu.event?.city}</span>
                   <div className=""style={{marginTop:"-3px"}}><span className="event-date ">Published by {getmenu.event?.hostName}</span></div> 
                   
                    </div>
                  </div>
                  <div className="row margintop1">
                    <div className="margintop1">
                   {/* <div className=""style={{marginTop:"-3px"}}><span className="event-date ">Published by {getmenu.event?.eventType}</span></div>  */}
                   <span className="post-host-Name" style={{color:"#626262",marginleft:'7px'}}>Event-</span> <span className="post-host-Name">{getmenu.event?.eventType}</span>
                  </div>
                  </div>
                 
                  <div className={getmenu.event?.imagesCount == 4 ? "" : "hide"}>
                                     <div className="row margintop3">
                                       {
                                         getmenu.event?.images?.map((gname, index) => (
                                           <div className="col-6">
                                             <img src={gname} className='' style={{ width: "326px", height: "100%" }} />
                 
                                           </div>
                                         ))
                                       }
                                       {/* <div className="col-6">
                             <img src={eventimg3}  className=''style={{  width:"333px",height:"100%"}}/>  
                 
                             </div> */}
                 
                                     </div>
                                   </div>
                                   <div className={getmenu.event?.imagesCount == 2 ? "" : "hide"}>
                                     
                                         <div className="row margintop">
                                         {
                                       getmenu.event?.images?.map((gname, index) => (
                                           <div className="col-lg-6 col-sm-12 col-md-12 margintop1">
                                             <img src={gname} className='' style={{ width: "326px", height: "100%" }} />
                 
                                           </div>
                  ))
                 }
                                           {/* <div className="col-6">
                             <img src={eventimg3}  className=''style={{  width:"333px",height:"100%"}}/>  
                 
                             </div> */}
                 
                                         </div>
                                      
                                   </div>
                                   <div className={getmenu.event?.imagesCount == 1 ? "" : "hide"}>
                                     
                                         <div className="row margintop">
                                         {
                                       getmenu.event?.images?.map((gname, index) => (
                                           <div className="col-12 col-md-12 col-sm-12 margintop1">
                                             <img src={gname} className='' style={{ width: "100%", height: "90%" }} />
                 
                                           </div>
                                            ))
                                           }
                 
                                           {/* <div className="col-6">
                             <img src={eventimg3}  className=''style={{  width:"333px",height:"100%"}}/>  
                 
                             </div> */}
                 
                                         </div>
                                      
                                   </div>
                                  <div className={getmenu.event?.imagesCount == 3 ? "" : "hide"}>
                 
                                     <div className="row margintop">
                                     {getmenu.event?.images?.map((gname, index) => (
                                     <div
                         className={`margintop1 ${index < 2 ? "col-6" : "col-12"}`}
                         key={index}
                       >
                         <img src={gname} className="" style={{ width: "100%", height: "auto" }} />
                       </div>
                 ))}
                 
                                       
                 
                                     </div>
                                     
                                   </div>
                                   <div className={getmenu.event?.imagesCount == 5 ? "" : "hide"}>
                 
                 <div className="row margintop">
                 {getmenu.event?.images?.map((gname, index) => (
                   <div
                         className={`margintop1 ${
                           index < 3 ? "col-4" : "col-6"
                         }`}
                         key={index}
                       >
                         <img src={gname} className="" style={{ width: "100%", height: "auto" }} />
                       </div>
                 ))}
                 
                   
                 
                 </div>
                 
                 </div>
                 
                  <div className="row">
                    <div className=""><span className="post-host-Name ">{getmenu.event?.hostName}</span></div>
                    <div className=""style={{marginTop:"2px"}}><span className="event-date ">{getmenu.event?.address} </span></div> 
                    <div className=""style={{marginTop:"-3px"}}><span className="event-date "> {getmenu.event?.city}, {getmenu.event?.state}</span></div>
                    <div className="margintop1"><span className="post-host-Name " style={{fontSize:'12px'}}>{getmenu.event?.eventDate}</span> <span className="post-host-Name "style={{fontSize:'12px',marginLeft:"15px"}}>{getmenu.event?.eventTime}</span></div>
                  </div>
                  <div className="row margintop3">
                    <div  className=""><div className="borderBottombtn" style={{marginLeft:"0px"}}></div></div>
                  </div>
                  <div className="row margintop3">
                                      <div className="col-lg-2 maxwd12 col-sm-4 col-md-6">
                                        {/* <BiLike className={status==='like'? 'activelike' : ''} onClick={handleClickLike}/><span className="likespn marginleft2"> {likes}</span> */}
                                        {getmenu.event?.likeStatus == true ? <AiFillLike color="#626262" size="1.3em" onClick={() => addLikes(getmenu.event?.id)} style={{ cursor: "pointer" }} /> : <BiLike onClick={() => addLikes(getmenu.event?.id)} size="1.3em" style={{ cursor: "pointer" }} color="#D9D9D9" />}<span className="post-name-btn " style={{color:"#626262",fontSize:'14px',marginLeft:"4px",cursor:"pointer"}} onClick={() => loadLikesInfo(getmenu.event?.id)}> {getmenu.event?.likeCount} </span>
                                      </div>
                                      <div className="col-lg-2 maxwd12 col-md-4 col-sm-4" onClick={() => commentInfo(getmenu.event?.id)} style={{ cursor: "pointer" }}><FaRegComments size="1.3em" color="#626262" /><span className="post-name-btn " style={{color:"#626262",fontSize:'14px',marginLeft:"6px"}}>{getmenu.event?.commentCount} </span></div>
                                      <div className="col-lg-7"></div>

                                      
                                      <div className="col-lg-2 displyinline">
                                      {getmenu.event?.userSpamStatus == false ?
                                        (
                                          <div className="col-lg-1 col-sm-5 col-md-4 " onClick={() => addSpam(getmenu.event?.id)} style={{ cursor: "pointer" }}><RiSpam2Line color="#D9D9D9" size="1.3em"/> </div>
                  
                                        ) : (
                                          <div className="col-1 " onClick={() => addSpam(getmenu.event?.id)} style={{ cursor: "pointer" }}><RiSpam2Line color="#626262" size="1.3em"/> </div>
                  
                                        )
                                      }
                                      {getmenu.event?.spamCount >=3 ?
        (
        <span className='viewsub-activ-spn'style={{float:'right',whiteSpace:"nowrap",backgroundColor:"rgba(211, 15, 69, 1)",color:"#F2F2F2"}} >Spam</span>
        ):
        (
          <span>
        {/* <span className='viewsub-activ-spn'style={{float:'right',whiteSpace:"nowrap",backgroundColor:"orange",color:"#F2F2F2"}} >Spam</span> */}
        <span  className={getmenu.event?.activeStatus=='Archived'?"viewsub-activ-spn":"hide"} style={{backgroundColor:"orange",marginLeft:'20px'}}>Archived</span>
              <span style={{float:'right',whiteSpace:"nowrap",marginLeft:'20px'}}  className={getmenu.event?.activeStatus=='Active'?"viewsub-activ-spn":"hide"} >Active</span>
              <span  className={getmenu.event?.userSpamStatus==true?"viewsub-activ-spn":"hide"} style={{float:'right',whiteSpace:"nowrap",backgroundColor:"rgba(211, 15, 69, 1)",color:"#F2F2F2",marginLeft:'20px'}} >Spam</span>
        </span>
        )
        }
                                      </div>
                  
                                    </div>
                                    <div className='row margintop1'>
          <div className='col-4'></div>
          <div className="col-lg-2 maxwd22 col-sm-4 col-md-6 report-spm-div-delete"  style={{ cursor: "pointer" }}><MdOutlineDelete size="1.3em" color="#FF8427" style={{marginLeft:"-7px"}}/><span className="marginleft2 report-spm" style={{color:"#FF8427"}}>Delete</span> </div>
          <div className='col-1 maxwd33'></div>
          <div className="col-lg-2 maxwd22 col-sm-4 col-md-6 report-spm-div"  onClick={() => loadPost1(getmenu.event?.id,getmenu.event?.type)} style={{ cursor: "pointer" }}><RiEdit2Line size="1.3em" color="#FFFFFF" /><span className="marginleft2 report-spm">Edit</span> </div>
          <div className='col-3'></div>
          </div>

                                    <Card className={comment && getmenu.event?.id == cmntEventId ? "margintop3" : "hide row"} >
                                      <div className="">
                                        <div className="" onClick={() => Commentpoupopen(false)} style={{float:"right",marginRight:"10px",cursor:"pointer",fontSize:'13px'}}>X</div>
                                        <Card className="overflow-hidden" style={{ height: "auto", border: "none" }}>
                                          {/* <Card className="mb-lg-0" style={{ height: "auto", border: "none" }}> */}
                  
                                            <Card.Body>
                                              <div className="mt-2">
                                                <FormGroup className="form-group " >
                                                  <Form.Control
                                                    type="text"
                                                    className="form-control borderadius25px "
                                                    id="name1"
                                                    name="txtCmnt"
                                                    placeholder="Add a Comment"
                                                    style={{ fontSize: "13px", borderRadius: "25px" }}
                                                    onChange={handleChange}
                                                    onKeyPress={handleKeypress}
                                                    value={cmntValue.txtCmnt}
                                                  />
                                                  <label className={commentValiderr ? "event-sub-detail1" : "hide"} style={{ color: "red" }}>Please Add Comment</label>
                  
                                                </FormGroup>
                  
                                                <button className="attendbtn" style={{ width: "90px", height: "28px" }} onClick={() => saveComent(getmenu.event?.id)}>
                                                  Send Reply
                                                </button>
                                                {/* <span onClick={() => Commentpoupopen(false)}>close</span> */}
                                              </div>
                                            </Card.Body>
                                          {/* </Card> */}
                                        </Card>
                                      </div>
                                      {
  commentData.map((commentmenu, index) => {
    // Extract first letter of first name and first letter of last name
    const nameParts = commentmenu.viewerName ? commentmenu.viewerName.split(" ") : [];
    const initials =
      nameParts.length > 1
        ? `${nameParts[0][0].toUpperCase()}${nameParts[nameParts.length - 1][0].toUpperCase()}`
        : nameParts[0]
        ? nameParts[0][0].toUpperCase()
        : "";

    return (
      <div key={index}>
        <Card className="" style={{ height: "auto", border: "none" }}>
          <Card.Body>
            <div className="d-sm-flex mt-0 p-3 sub-review-section border br-bl-0 br-br-0">
              <div className="media-body">
                {/* Flex container to keep avatar and name in one line */}
                <h6
                  className="mt-0 mb-1 font-12 font-weight-semibold"
                  style={{
                    fontSize: "13px",
                    display:"inline-flex",
                  }}
                >
                  {/* Profile Picture or Avatar */}
                  {commentmenu.profilePicture ? (
                    <img
                      src={commentmenu.profilePicture}
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        marginRight:"6px",
                        marginTop:"-6px"
                      }}
                    />
                  ) : (
                    <Avatar
                      style={{
                        width: "30px",
                        height: "30px",
                        fontSize: "12px",
                        backgroundColor: bgColor,
                        color: "#ffffff",
                        marginTop:"-6px",
                        marginRight:"6px"
                      }}
                    >
                      {initials}
                    </Avatar>
                  )}

                  {/* Viewer Name */}
                  {commentmenu.viewerName}
                </h6>

                {/* Comment Text */}
                <p className="font-11 mb-2 mt-2" style={{ fontSize: "12px" }}>
                  {commentmenu.comment}
                </p>

                {/* Delete Badge */}
                <Badge
                  bg=""
                  className={commentmenu.viewerId == uerAuthId ? "bg-danger" : "hide"}
                  onClick={() => deleteComment(commentmenu.id)}
                  style={{ cursor: "pointer" }}
                >
                  Delete
                </Badge>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  })
}
                  
                                    </Card>
            </div>
</div>
         
<div className={ archiveDiv==false && spamDIv==false && activeDiv==false && getmenu.blog?"":"hide"}>

          
            <div className={getmenu.blog ? "radius-div-news margintop3" : "hide"}>
            <div className="row margintop3">
                    <div className="col-lg-1 col-sm-12 col-md-12">
                        {getmenu.blog?.profilePic == "" || getmenu.blog?.profilePic == null ?
                        (<Avatar className="avtarimgsm" style={{ width: "50px",marginTop:"px", height: "49px",backgroundColor: bgColor,color:"#ffffff" }}>{getmenu.blog?.authorName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.blog?.profilePic} id='profpicdisablesave5' className=''style={{ width: "50px",marginTop:"px", height: "49px",borderRadius:"50%"}} />)
                      }
                    </div>
                    <div className="col-lg-5 col-sm-12 col-md-12 textleft" style={{marginTop:"4px"}}>
                      <span className="post-host-Name ">{getmenu.blog?.authorName}</span>
                      <div className=""style={{marginTop:"-3px"}}><span className="event-date">{getmenu.blog?.scheduledDate} @{getmenu.blog?.scheduledTime}</span></div>
                      </div>
                    <div className="col-lg-2 col-sm-12" style={{ whiteSpace: "nowrap",marginTop:"px" }}>
                      </div>
                    <div className="col-lg-4 col-sm-12 col-md-12 textright"style={{marginTop:"4px"}}><span className="post-host-Name"></span>
                   <div className=""style={{marginTop:"-3px"}}><span className="event-date ">Published by {getmenu.blog?.authorName}</span></div> 
                   
                    </div>
                  </div>
                  <div className="row margintop1">
                    <div className="margintop1">
                   {/* <div className=""style={{marginTop:"-3px"}}><span className="event-date ">Published by {getmenu.blog?.eventType}</span></div>  */}
                   <span className="post-host-Name" style={{color:"#626262",marginleft:'7px'}}>Blog-</span> <span className="post-host-Name">{getmenu.blog?.blogTitle}</span>
                  </div>
                  </div>
                  <div className="row margintop3">
                    <img src={getmenu.blog?.bannerImagePath} className='blogimg' />
                    <div ><span className="event-date"style={{float:"left"}}>Author:{getmenu.blog?.authorName}</span><span className="event-date" style={{float:"right"}}>Image Source - {getmenu.blog?.imageSource}</span></div>

                    <div className="blog-textdiv margintop3">
                    <span className="event-date">
                    <span className={`contentblog ${expandedblog ? "expandedblog" : "collapsedblog"}`} style={{textTransform:"capitalize"}}>
        {getmenu.blog?.blogContent}
      </span>
  </span>
  
                      <div className="textright">
                      {getmenu.blog?.blogContent.length > 200 && ( // Show button only if content is long
        <div className="textright">
          <span className="read-more-blog" onClick={() => setExpandedblog(!expandedblog)} style={{cursor:"pointer"}}>
            {expandedblog ? "Read Less" : "Read More"}
          </span>
        </div>
      )}
                        </div>

                    </div>
                  </div>
                  <div className="row margintop3">
                    <div  className=""><div className="borderBottombtn" style={{marginLeft:"0px"}}></div></div>
                  </div>
                  <div className="row margintop3">
                                      <div className="col-lg-2 maxwd12 col-sm-4 col-md-6">
                                        {/* <BiLike className={status==='like'? 'activelike' : ''} onClick={handleClickLike}/><span className="likespn marginleft2"> {likes}</span> */}
                                        {getmenu.blog?.likeStatus == true ? <AiFillLike color="#626262" size="1.3em" onClick={() => addLikes(getmenu.blog?.id)} style={{ cursor: "pointer" }} /> : <BiLike onClick={() => addLikes(getmenu.blog?.id)} size="1.3em" style={{ cursor: "pointer" }} color="#D9D9D9" />}<span className="post-name-btn " style={{color:"#626262",fontSize:'14px',marginLeft:"4px",cursor:"pointer"}} onClick={() => loadLikesInfo(getmenu.blog?.id)}> {getmenu.blog?.likeCount} </span>
                                      </div>
                                      <div className="col-lg-2 maxwd12 col-md-4 col-sm-4" onClick={() => commentInfo(getmenu.blog?.id)} style={{ cursor: "pointer" }}><FaRegComments size="1.3em" color="#626262" /><span className="post-name-btn " style={{color:"#626262",fontSize:'14px',marginLeft:"6px"}}>{getmenu.blog?.commentCount} </span></div>
                                      <div className="col-lg-7"></div>

                                      
                                      <div className='col-2 displyinline'>
                                      {getmenu.blog?.userSpamStatus == false ?
                                        (
                                          <div className="col-lg-1 col-sm-5 col-md-4 " onClick={() => addSpam(getmenu.blog?.id)} style={{ cursor: "pointer" }}><RiSpam2Line color="#D9D9D9" size="1.3em"/> </div>
                  
                                        ) : (
                                          <div className="col-1 " onClick={() => addSpam(getmenu.blog?.id)} style={{ cursor: "pointer" }}><RiSpam2Line color="#626262" size="1.3em"/> </div>
                  
                                        )
                                      }
                                      {getmenu.blog?.spamCount >=3 ?
              (
              <span className='viewsub-activ-spn'style={{marginLeft:'20px',whiteSpace:"nowrap",backgroundColor:"rgba(211, 15, 69, 1)",color:"#F2F2F2"}} >Spam</span>
              ):
              (
                <span>
              {/* <span className='viewsub-activ-spn'style={{marginLeft:'120px',whiteSpace:"nowrap"}} >Active</span> */}
              <span  className={getmenu.blog?.activeStatus=='Archived'?"viewsub-activ-spn":"hide"} style={{backgroundColor:"orange",marginLeft:'20px'}}>Archived</span>
              <span style={{float:'right',whiteSpace:"nowrap",marginLeft:'20px'}}  className={getmenu.blog?.activeStatus=='Active'?"viewsub-activ-spn":"hide"} >Active</span>
              <span  className={getmenu.blog?.userSpamStatus==true?"viewsub-activ-spn":"hide"} style={{float:'right',whiteSpace:"nowrap",backgroundColor:"rgba(211, 15, 69, 1)",color:"#F2F2F2",marginLeft:'20px'}} >Spam</span>
              </span>
              )
              }
                                      </div>
                  
                                    </div>
                                    <div className='row margintop1'>
          <div className='col-4'></div>
          <div className="col-lg-2 maxwd22 col-sm-4 col-md-6 report-spm-div-delete"  style={{ cursor: "pointer" }}><MdOutlineDelete size="1.3em" color="#FF8427" style={{marginLeft:"-7px"}}/><span className="marginleft2 report-spm" style={{color:"#FF8427"}}>Delete</span> </div>
          <div className='col-1 maxwd33'></div>
          <div className="col-lg-2 maxwd22 col-sm-4 col-md-6 report-spm-div" onClick={() => loadPost1(getmenu.blog?.id, getmenu.blog?.type)} style={{ cursor: "pointer" }}><RiEdit2Line size="1.3em" color="#FFFFFF" /><span className="marginleft2 report-spm">Edit</span> </div>
          <div className='col-3'></div>

          </div>
                                    <Card className={comment && getmenu.blog?.id == cmntEventId ? "margintop3" : "hide row"} >
                                      <div className="">
                                        <div className="" onClick={() => Commentpoupopen(false)} style={{float:"right",marginRight:"10px",cursor:"pointer",fontSize:'13px'}}>X</div>
                                        <Card className="overflow-hidden" style={{ height: "auto", border: "none" }}>
                                          {/* <Card className="mb-lg-0" style={{ height: "auto", border: "none" }}> */}
                  
                                            <Card.Body>
                                              <div className="mt-2">
                                                <FormGroup className="form-group " >
                                                  <Form.Control
                                                    type="text"
                                                    className="form-control borderadius25px "
                                                    id="name1"
                                                    name="txtCmnt"
                                                    placeholder="Add a Comment"
                                                    style={{ fontSize: "13px", borderRadius: "25px" }}
                                                    onChange={handleChange}
                                                    onKeyPress={handleKeypress}
                                                    value={cmntValue.txtCmnt}
                                                  />
                                                  <label className={commentValiderr ? "event-sub-detail1" : "hide"} style={{ color: "red" }}>Please Add Comment</label>
                  
                                                </FormGroup>
                  
                                                <button className="attendbtn" style={{ width: "90px", height: "28px" }} onClick={() => saveComent(getmenu.blog?.id)}>
                                                  Send Reply
                                                </button>
                                                {/* <span onClick={() => Commentpoupopen(false)}>close</span> */}
                                              </div>
                                            </Card.Body>
                                          {/* </Card> */}
                                        </Card>
                                      </div>
                                      {
  commentData.map((commentmenu, index) => {
    // Extract first letter of first name and first letter of last name
    const nameParts = commentmenu.viewerName ? commentmenu.viewerName.split(" ") : [];
    const initials =
      nameParts.length > 1
        ? `${nameParts[0][0].toUpperCase()}${nameParts[nameParts.length - 1][0].toUpperCase()}`
        : nameParts[0]
        ? nameParts[0][0].toUpperCase()
        : "";

    return (
      <div key={index}>
        <Card className="" style={{ height: "auto", border: "none" }}>
          <Card.Body>
            <div className="d-sm-flex mt-0 p-3 sub-review-section border br-bl-0 br-br-0">
              <div className="media-body">
                {/* Flex container to keep avatar and name in one line */}
                <h6
                  className="mt-0 mb-1 font-12 font-weight-semibold"
                  style={{
                    fontSize: "13px",
                    display:"inline-flex",
                  }}
                >
                  {/* Profile Picture or Avatar */}
                  {commentmenu.profilePicture ? (
                    <img
                      src={commentmenu.profilePicture}
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        marginRight:"6px",
                        marginTop:"-6px"
                      }}
                    />
                  ) : (
                    <Avatar
                      style={{
                        width: "30px",
                        height: "30px",
                        fontSize: "12px",
                        backgroundColor: bgColor,
                        color: "#ffffff",
                        marginTop:"-6px",
                        marginRight:"6px"
                      }}
                    >
                      {initials}
                    </Avatar>
                  )}

                  {/* Viewer Name */}
                  {commentmenu.viewerName}
                </h6>

                {/* Comment Text */}
                <p className="font-11 mb-2 mt-2" style={{ fontSize: "12px" }}>
                  {commentmenu.comment}
                </p>

                {/* Delete Badge */}
                <Badge
                  bg=""
                  className={commentmenu.viewerId == uerAuthId ? "bg-danger" : "hide"}
                  onClick={() => deleteComment(commentmenu.id)}
                  style={{ cursor: "pointer" }}
                >
                  Delete
                </Badge>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  })
}
                  
                                    </Card>
                  
                 
                  
                  
                 
            </div>
            </div>
            <div className={ archiveDiv && getmenu.blog?.activeStatus=='Archived'?"":"hide"}>
            
            <div className={getmenu.blog ? "radius-div-news margintop3" : "hide"}>
            <div className="row margintop3">
                    <div className="col-lg-1 col-sm-12 col-md-12">
                        {getmenu.blog?.profilePic == "" || getmenu.blog?.profilePic == null ?
                        (<Avatar className="avtarimgsm" style={{ width: "50px",marginTop:"px", height: "49px",backgroundColor: bgColor,color:"#ffffff" }}>{getmenu.blog?.authorName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.blog?.profilePic} id='profpicdisablesave5' className=''style={{ width: "50px",marginTop:"px", height: "49px",borderRadius:"50%"}} />)
                      }
                    </div>
                    <div className="col-lg-5 col-sm-12 col-md-12 textleft" style={{marginTop:"4px"}}>
                      <span className="post-host-Name ">{getmenu.blog?.authorName}</span>
                      <div className=""style={{marginTop:"-3px"}}><span className="event-date">{getmenu.blog?.scheduledDate} @{getmenu.blog?.scheduledTime}</span></div>
                      </div>
                    <div className="col-lg-2 col-sm-12" style={{ whiteSpace: "nowrap",marginTop:"px" }}>
                      </div>
                    <div className="col-lg-4 col-sm-12 col-md-12 textright"style={{marginTop:"4px"}}><span className="post-host-Name"></span>
                   <div className=""style={{marginTop:"-3px"}}><span className="event-date ">Published by {getmenu.blog?.authorName}</span></div> 
                   
                    </div>
                  </div>
                  <div className="row margintop1">
                    <div className="margintop1">
                   {/* <div className=""style={{marginTop:"-3px"}}><span className="event-date ">Published by {getmenu.blog?.eventType}</span></div>  */}
                   <span className="post-host-Name" style={{color:"#626262",marginleft:'7px'}}>Blog-</span> <span className="post-host-Name">{getmenu.blog?.blogTitle}</span>
                  </div>
                  </div>
                  <div className="row margintop3">
                    <img src={getmenu.blog?.bannerImagePath} className='blogimg' />
                    <div ><span className="event-date"style={{float:"left"}}>Author:{getmenu.blog?.authorName}</span><span className="event-date" style={{float:"right"}}>Image Source - {getmenu.blog?.imageSource}</span></div>

                    <div className="blog-textdiv margintop3">
                    <span className="event-date">
                    <span className={`contentblog ${expandedblog ? "expandedblog" : "collapsedblog"}`} style={{textTransform:"capitalize"}}>
        {getmenu.blog?.blogContent}
      </span>
  </span>
  
                      <div className="textright">
                      {getmenu.blog?.blogContent.length > 200 && ( // Show button only if content is long
        <div className="textright">
          <span className="read-more-blog" onClick={() => setExpandedblog(!expandedblog)} style={{cursor:"pointer"}}>
            {expandedblog ? "Read Less" : "Read More"}
          </span>
        </div>
      )}
                        </div>

                    </div>
                  </div>
                  <div className="row margintop3">
                    <div  className=""><div className="borderBottombtn" style={{marginLeft:"0px"}}></div></div>
                  </div>
                  <div className="row margintop3">
                                      <div className="col-lg-2 maxwd12 col-sm-4 col-md-6">
                                        {/* <BiLike className={status==='like'? 'activelike' : ''} onClick={handleClickLike}/><span className="likespn marginleft2"> {likes}</span> */}
                                        {getmenu.blog?.likeStatus == true ? <AiFillLike color="#626262" size="1.3em" onClick={() => addLikes(getmenu.blog?.id)} style={{ cursor: "pointer" }} /> : <BiLike onClick={() => addLikes(getmenu.blog?.id)} size="1.3em" style={{ cursor: "pointer" }} color="#D9D9D9" />}<span className="post-name-btn " style={{color:"#626262",fontSize:'14px',marginLeft:"4px",cursor:"pointer"}} onClick={() => loadLikesInfo(getmenu.blog?.id)}> {getmenu.blog?.likeCount} </span>
                                      </div>
                                      <div className="col-lg-2 maxwd12 col-md-4 col-sm-4" onClick={() => commentInfo(getmenu.blog?.id)} style={{ cursor: "pointer" }}><FaRegComments size="1.3em" color="#626262" /><span className="post-name-btn " style={{color:"#626262",fontSize:'14px',marginLeft:"6px"}}>{getmenu.blog?.commentCount} </span></div>
                                      <div className="col-lg-7"></div>

                                      
                                      <div className='col-2 displyinline'>
                                      {getmenu.blog?.userSpamStatus == false ?
                                        (
                                          <div className="col-lg-1 col-sm-5 col-md-4 " onClick={() => addSpam(getmenu.blog?.id)} style={{ cursor: "pointer" }}><RiSpam2Line color="#D9D9D9" size="1.3em"/> </div>
                  
                                        ) : (
                                          <div className="col-1 " onClick={() => addSpam(getmenu.blog?.id)} style={{ cursor: "pointer" }}><RiSpam2Line color="#626262" size="1.3em"/> </div>
                  
                                        )
                                      }
                                      {getmenu.blog?.spamCount >=3 ?
              (
              <span className='viewsub-activ-spn'style={{marginLeft:'20px',whiteSpace:"nowrap",backgroundColor:"rgba(211, 15, 69, 1)",color:"#F2F2F2"}} >Spam</span>
              ):
              (
                <span>
              {/* <span className='viewsub-activ-spn'style={{marginLeft:'120px',whiteSpace:"nowrap"}} >Active</span> */}
              <span  className={getmenu.blog?.activeStatus=='Archived'?"viewsub-activ-spn":"hide"} style={{backgroundColor:"orange",marginLeft:'20px'}}>Archived</span>
              
              </span>
              )
              }
                                      </div>
                  
                                    </div>
                                    <div className='row margintop1'>
          <div className='col-4'></div>
          <div className="col-lg-2 maxwd22 col-sm-4 col-md-6 report-spm-div-delete"  style={{ cursor: "pointer" }}><MdOutlineDelete size="1.3em" color="#FF8427" style={{marginLeft:"-7px"}}/><span className="marginleft2 report-spm" style={{color:"#FF8427"}}>Delete</span> </div>
          <div className='col-1 maxwd33'></div>
          <div className="col-lg-2 maxwd22 col-sm-4 col-md-6 report-spm-div" onClick={() => loadPost1(getmenu.blog?.id, getmenu.blog?.type)} style={{ cursor: "pointer" }}><RiEdit2Line size="1.3em" color="#FFFFFF" /><span className="marginleft2 report-spm">Edit</span> </div>
          <div className='col-3'></div>

          </div>
                                    <Card className={comment && getmenu.blog?.id == cmntEventId ? "margintop3" : "hide row"} >
                                      <div className="">
                                        <div className="" onClick={() => Commentpoupopen(false)} style={{float:"right",marginRight:"10px",cursor:"pointer",fontSize:'13px'}}>X</div>
                                        <Card className="overflow-hidden" style={{ height: "auto", border: "none" }}>
                                          {/* <Card className="mb-lg-0" style={{ height: "auto", border: "none" }}> */}
                  
                                            <Card.Body>
                                              <div className="mt-2">
                                                <FormGroup className="form-group " >
                                                  <Form.Control
                                                    type="text"
                                                    className="form-control borderadius25px "
                                                    id="name1"
                                                    name="txtCmnt"
                                                    placeholder="Add a Comment"
                                                    style={{ fontSize: "13px", borderRadius: "25px" }}
                                                    onChange={handleChange}
                                                    onKeyPress={handleKeypress}
                                                    value={cmntValue.txtCmnt}
                                                  />
                                                  <label className={commentValiderr ? "event-sub-detail1" : "hide"} style={{ color: "red" }}>Please Add Comment</label>
                  
                                                </FormGroup>
                  
                                                <button className="attendbtn" style={{ width: "90px", height: "28px" }} onClick={() => saveComent(getmenu.blog?.id)}>
                                                  Send Reply
                                                </button>
                                                {/* <span onClick={() => Commentpoupopen(false)}>close</span> */}
                                              </div>
                                            </Card.Body>
                                          {/* </Card> */}
                                        </Card>
                                      </div>
                                      {
  commentData.map((commentmenu, index) => {
    // Extract first letter of first name and first letter of last name
    const nameParts = commentmenu.viewerName ? commentmenu.viewerName.split(" ") : [];
    const initials =
      nameParts.length > 1
        ? `${nameParts[0][0].toUpperCase()}${nameParts[nameParts.length - 1][0].toUpperCase()}`
        : nameParts[0]
        ? nameParts[0][0].toUpperCase()
        : "";

    return (
      <div key={index}>
        <Card className="" style={{ height: "auto", border: "none" }}>
          <Card.Body>
            <div className="d-sm-flex mt-0 p-3 sub-review-section border br-bl-0 br-br-0">
              <div className="media-body">
                {/* Flex container to keep avatar and name in one line */}
                <h6
                  className="mt-0 mb-1 font-12 font-weight-semibold"
                  style={{
                    fontSize: "13px",
                    display:"inline-flex",
                  }}
                >
                  {/* Profile Picture or Avatar */}
                  {commentmenu.profilePicture ? (
                    <img
                      src={commentmenu.profilePicture}
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        marginRight:"6px",
                        marginTop:"-6px"
                      }}
                    />
                  ) : (
                    <Avatar
                      style={{
                        width: "30px",
                        height: "30px",
                        fontSize: "12px",
                        backgroundColor: bgColor,
                        color: "#ffffff",
                        marginTop:"-6px",
                        marginRight:"6px"
                      }}
                    >
                      {initials}
                    </Avatar>
                  )}

                  {/* Viewer Name */}
                  {commentmenu.viewerName}
                </h6>

                {/* Comment Text */}
                <p className="font-11 mb-2 mt-2" style={{ fontSize: "12px" }}>
                  {commentmenu.comment}
                </p>

                {/* Delete Badge */}
                <Badge
                  bg=""
                  className={commentmenu.viewerId == uerAuthId ? "bg-danger" : "hide"}
                  onClick={() => deleteComment(commentmenu.id)}
                  style={{ cursor: "pointer" }}
                >
                  Delete
                </Badge>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  })
}
                                    </Card>
                  
                 
                  
                  
                 
            </div>
</div>          
<div className={ activeDiv && getmenu.blog?.activeStatus=='Active'?"":"hide"}>

            <div className={getmenu.blog ? "radius-div-news margintop3" : "hide"}>
            <div className="row margintop3">
                    <div className="col-lg-1 col-sm-12 col-md-12">
                        {getmenu.blog?.profilePic == "" || getmenu.blog?.profilePic == null ?
                        (<Avatar className="avtarimgsm" style={{ width: "50px",marginTop:"px", height: "49px",backgroundColor: bgColor,color:"#ffffff" }}>{getmenu.blog?.authorName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.blog?.profilePic} id='profpicdisablesave5' className=''style={{ width: "50px",marginTop:"px", height: "49px",borderRadius:"50%"}} />)
                      }
                    </div>
                    <div className="col-lg-5 col-sm-12 col-md-12 textleft" style={{marginTop:"4px"}}>
                      <span className="post-host-Name ">{getmenu.blog?.authorName}</span>
                      <div className=""style={{marginTop:"-3px"}}><span className="event-date">{getmenu.blog?.scheduledDate} @{getmenu.blog?.scheduledTime}</span></div>
                      </div>
                    <div className="col-lg-2 col-sm-12" style={{ whiteSpace: "nowrap",marginTop:"px" }}>
                      </div>
                    <div className="col-lg-4 col-sm-12 col-md-12 textright"style={{marginTop:"4px"}}><span className="post-host-Name"></span>
                   <div className=""style={{marginTop:"-3px"}}><span className="event-date ">Published by {getmenu.blog?.authorName}</span></div> 
                   
                    </div>
                  </div>
                  <div className="row margintop1">
                    <div className="margintop1">
                   {/* <div className=""style={{marginTop:"-3px"}}><span className="event-date ">Published by {getmenu.blog?.eventType}</span></div>  */}
                   <span className="post-host-Name" style={{color:"#626262",marginleft:'7px'}}>Blog-</span> <span className="post-host-Name">{getmenu.blog?.blogTitle}</span>
                  </div>
                  </div>
                  <div className="row margintop3">
                    <img src={getmenu.blog?.bannerImagePath} className='blogimg' />
                    <div ><span className="event-date"style={{float:"left"}}>Author:{getmenu.blog?.authorName}</span><span className="event-date" style={{float:"right"}}>Image Source - {getmenu.blog?.imageSource}</span></div>

                    <div className="blog-textdiv margintop3">
                    <span className="event-date">
                    <span className={`contentblog ${expandedblog ? "expandedblog" : "collapsedblog"}`} style={{textTransform:"capitalize"}}>
        {getmenu.blog?.blogContent}
      </span>
  </span>
  
                      <div className="textright">
                      {getmenu.blog?.blogContent.length > 200 && ( // Show button only if content is long
        <div className="textright">
          <span className="read-more-blog" onClick={() => setExpandedblog(!expandedblog)} style={{cursor:"pointer"}}>
            {expandedblog ? "Read Less" : "Read More"}
          </span>
        </div>
      )}
                        </div>

                    </div>
                  </div>
                  <div className="row margintop3">
                    <div  className=""><div className="borderBottombtn" style={{marginLeft:"0px"}}></div></div>
                  </div>
                  <div className="row margintop3">
                                      <div className="col-lg-2 maxwd12 col-sm-4 col-md-6">
                                        {/* <BiLike className={status==='like'? 'activelike' : ''} onClick={handleClickLike}/><span className="likespn marginleft2"> {likes}</span> */}
                                        {getmenu.blog?.likeStatus == true ? <AiFillLike color="#626262" size="1.3em" onClick={() => addLikes(getmenu.blog?.id)} style={{ cursor: "pointer" }} /> : <BiLike onClick={() => addLikes(getmenu.blog?.id)} size="1.3em" style={{ cursor: "pointer" }} color="#D9D9D9" />}<span className="post-name-btn " style={{color:"#626262",fontSize:'14px',marginLeft:"4px",cursor:"pointer"}} onClick={() => loadLikesInfo(getmenu.blog?.id)}> {getmenu.blog?.likeCount} </span>
                                      </div>
                                      <div className="col-lg-2 maxwd12 col-md-4 col-sm-4" onClick={() => commentInfo(getmenu.blog?.id)} style={{ cursor: "pointer" }}><FaRegComments size="1.3em" color="#626262" /><span className="post-name-btn " style={{color:"#626262",fontSize:'14px',marginLeft:"6px"}}>{getmenu.blog?.commentCount} </span></div>
                                      <div className="col-lg-7"></div>

                                      
                                      <div className='col-2 displyinline'>
                                      {getmenu.blog?.userSpamStatus == false ?
                                        (
                                          <div className="col-lg-1 col-sm-5 col-md-4 " onClick={() => addSpam(getmenu.blog?.id)} style={{ cursor: "pointer" }}><RiSpam2Line color="#D9D9D9" size="1.3em"/> </div>
                  
                                        ) : (
                                          <div className="col-1 " onClick={() => addSpam(getmenu.blog?.id)} style={{ cursor: "pointer" }}><RiSpam2Line color="#626262" size="1.3em"/> </div>
                  
                                        )
                                      }
                                      {getmenu.blog?.spamCount >=3 ?
              (
              <span className='viewsub-activ-spn'style={{marginLeft:'20px',whiteSpace:"nowrap",backgroundColor:"rgba(211, 15, 69, 1)",color:"#F2F2F2"}} >Spam</span>
              ):
              (
                <h6>
              {/* <span className='viewsub-activ-spn'style={{marginLeft:'120px',whiteSpace:"nowrap"}} >Active</span> */}
              <span style={{float:'right',whiteSpace:"nowrap",marginLeft:'20px'}}  className={getmenu.blog?.activeStatus=='Active'?"viewsub-activ-spn":"hide"} >Active</span>
              </h6>
              )
              }
                                      </div>
                  
                                    </div>
                                    <div className='row margintop1'>
          <div className='col-4'></div>
          <div className="col-lg-2 maxwd22 col-sm-4 col-md-6 report-spm-div-delete"  style={{ cursor: "pointer" }}><MdOutlineDelete size="1.3em" color="#FF8427" style={{marginLeft:"-7px"}}/><span className="marginleft2 report-spm" style={{color:"#FF8427"}}>Delete</span> </div>
          <div className='col-1 maxwd33'></div>
          <div className="col-lg-2 maxwd22 col-sm-4 col-md-6 report-spm-div" onClick={() => loadPost1(getmenu.blog?.id, getmenu.blog?.type)} style={{ cursor: "pointer" }}><RiEdit2Line size="1.3em" color="#FFFFFF" /><span className="marginleft2 report-spm">Edit</span> </div>
          <div className='col-3'></div>

          </div>
                                    <Card className={comment && getmenu.blog?.id == cmntEventId ? "margintop3" : "hide row"} >
                                      <div className="">
                                        <div className="" onClick={() => Commentpoupopen(false)} style={{float:"right",marginRight:"10px",cursor:"pointer",fontSize:'13px'}}>X</div>
                                        <Card className="overflow-hidden" style={{ height: "auto", border: "none" }}>
                                          {/* <Card className="mb-lg-0" style={{ height: "auto", border: "none" }}> */}
                  
                                            <Card.Body>
                                              <div className="mt-2">
                                                <FormGroup className="form-group " >
                                                  <Form.Control
                                                    type="text"
                                                    className="form-control borderadius25px "
                                                    id="name1"
                                                    name="txtCmnt"
                                                    placeholder="Add a Comment"
                                                    style={{ fontSize: "13px", borderRadius: "25px" }}
                                                    onChange={handleChange}
                                                    onKeyPress={handleKeypress}
                                                    value={cmntValue.txtCmnt}
                                                  />
                                                  <label className={commentValiderr ? "event-sub-detail1" : "hide"} style={{ color: "red" }}>Please Add Comment</label>
                  
                                                </FormGroup>
                  
                                                <button className="attendbtn" style={{ width: "90px", height: "28px" }} onClick={() => saveComent(getmenu.blog?.id)}>
                                                  Send Reply
                                                </button>
                                                {/* <span onClick={() => Commentpoupopen(false)}>close</span> */}
                                              </div>
                                            </Card.Body>
                                          {/* </Card> */}
                                        </Card>
                                      </div>
                                      {
  commentData.map((commentmenu, index) => {
    // Extract first letter of first name and first letter of last name
    const nameParts = commentmenu.viewerName ? commentmenu.viewerName.split(" ") : [];
    const initials =
      nameParts.length > 1
        ? `${nameParts[0][0].toUpperCase()}${nameParts[nameParts.length - 1][0].toUpperCase()}`
        : nameParts[0]
        ? nameParts[0][0].toUpperCase()
        : "";

    return (
      <div key={index}>
        <Card className="" style={{ height: "auto", border: "none" }}>
          <Card.Body>
            <div className="d-sm-flex mt-0 p-3 sub-review-section border br-bl-0 br-br-0">
              <div className="media-body">
                {/* Flex container to keep avatar and name in one line */}
                <h6
                  className="mt-0 mb-1 font-12 font-weight-semibold"
                  style={{
                    fontSize: "13px",
                    display:"inline-flex",
                  }}
                >
                  {/* Profile Picture or Avatar */}
                  {commentmenu.profilePicture ? (
                    <img
                      src={commentmenu.profilePicture}
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        marginRight:"6px",
                        marginTop:"-6px"
                      }}
                    />
                  ) : (
                    <Avatar
                      style={{
                        width: "30px",
                        height: "30px",
                        fontSize: "12px",
                        backgroundColor: bgColor,
                        color: "#ffffff",
                        marginTop:"-6px",
                        marginRight:"6px"
                      }}
                    >
                      {initials}
                    </Avatar>
                  )}

                  {/* Viewer Name */}
                  {commentmenu.viewerName}
                </h6>

                {/* Comment Text */}
                <p className="font-11 mb-2 mt-2" style={{ fontSize: "12px" }}>
                  {commentmenu.comment}
                </p>

                {/* Delete Badge */}
                <Badge
                  bg=""
                  className={commentmenu.viewerId == uerAuthId ? "bg-danger" : "hide"}
                  onClick={() => deleteComment(commentmenu.id)}
                  style={{ cursor: "pointer" }}
                >
                  Delete
                </Badge>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  })
}
                  
                                    </Card>
                  
                 
                  
                  
                 
            </div>
</div> 
<div className={ spamDIv && getmenu.blog?.userSpamStatus==true?"":"hide"}>

            <div className={getmenu.blog ? "radius-div-news margintop3" : "hide"}>
            <div className="row margintop3">
                    <div className="col-lg-1 col-sm-12 col-md-12">
                        {getmenu.blog?.profilePic == "" || getmenu.blog?.profilePic == null ?
                        (<Avatar className="avtarimgsm" style={{ width: "50px",marginTop:"px", height: "49px",backgroundColor: bgColor,color:"#ffffff" }}>{getmenu.blog?.authorName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.blog?.profilePic} id='profpicdisablesave5' className=''style={{ width: "50px",marginTop:"px", height: "49px",borderRadius:"50%"}} />)
                      }
                    </div>
                    <div className="col-lg-5 col-sm-12 col-md-12 textleft" style={{marginTop:"4px"}}>
                      <span className="post-host-Name ">{getmenu.blog?.hostName}</span>
                      <div className=""style={{marginTop:"-3px"}}><span className="event-date">{getmenu.blog?.scheduledDate} @{getmenu.blog?.scheduledTime}</span></div>
                      </div>
                    <div className="col-lg-2 col-sm-12" style={{ whiteSpace: "nowrap",marginTop:"px" }}>
                      </div>
                    <div className="col-lg-4 col-sm-12 col-md-12 textright"style={{marginTop:"4px"}}><span className="post-host-Name"></span>
                   <div className=""style={{marginTop:"-3px"}}><span className="event-date ">Published by {getmenu.blog?.authorName}</span></div> 
                   
                    </div>
                  </div>
                  <div className="row margintop1">
                    <div className="margintop1">
                   {/* <div className=""style={{marginTop:"-3px"}}><span className="event-date ">Published by {getmenu.blog?.eventType}</span></div>  */}
                   <span className="post-host-Name" style={{color:"#626262",marginleft:'7px'}}>Blog-</span> <span className="post-host-Name">{getmenu.blog?.blogTitle}</span>
                  </div>
                  </div>
                  <div className="row margintop3">
                    <img src={getmenu.blog?.bannerImagePath} className='blogimg' />
                    <div ><span className="event-date"style={{float:"left"}}>Author:{getmenu.blog?.authorName}</span><span className="event-date" style={{float:"right"}}>Image Source - {getmenu.blog?.imageSource}</span></div>

                    <div className="blog-textdiv margintop3">
                    <span className="event-date">
                    <span className={`contentblog ${expandedblog ? "expandedblog" : "collapsedblog"}`} style={{textTransform:"capitalize"}}>
        {getmenu.blog?.blogContent}
      </span>
  </span>
  
                      <div className="textright">
                      {getmenu.blog?.blogContent.length > 200 && ( // Show button only if content is long
        <div className="textright">
          <span className="read-more-blog" onClick={() => setExpandedblog(!expandedblog)} style={{cursor:"pointer"}}>
            {expandedblog ? "Read Less" : "Read More"}
          </span>
        </div>
      )}
                        </div>

                    </div>
                  </div>
                  <div className="row margintop3">
                    <div  className=""><div className="borderBottombtn" style={{marginLeft:"0px"}}></div></div>
                  </div>
                  <div className="row margintop3">
                                      <div className="col-lg-2 maxwd12 col-sm-4 col-md-6">
                                        {/* <BiLike className={status==='like'? 'activelike' : ''} onClick={handleClickLike}/><span className="likespn marginleft2"> {likes}</span> */}
                                        {getmenu.blog?.likeStatus == true ? <AiFillLike color="#626262" size="1.3em" onClick={() => addLikes(getmenu.blog?.id)} style={{ cursor: "pointer" }} /> : <BiLike onClick={() => addLikes(getmenu.blog?.id)} size="1.3em" style={{ cursor: "pointer" }} color="#D9D9D9" />}<span className="post-name-btn " style={{color:"#626262",fontSize:'14px',marginLeft:"4px",cursor:"pointer"}} onClick={() => loadLikesInfo(getmenu.blog?.id)}> {getmenu.blog?.likeCount} </span>
                                      </div>
                                      <div className="col-lg-2 maxwd12 col-md-4 col-sm-4" onClick={() => commentInfo(getmenu.blog?.id)} style={{ cursor: "pointer" }}><FaRegComments size="1.3em" color="#626262" /><span className="post-name-btn " style={{color:"#626262",fontSize:'14px',marginLeft:"6px"}}>{getmenu.blog?.commentCount} </span></div>
                                      <div className="col-lg-7"></div>

                                      
                                      <div className='col-2 displyinline'>
                                      {getmenu.blog?.userSpamStatus == false ?
                                        (
                                          <div className="col-lg-1 col-sm-5 col-md-4 " onClick={() => addSpam(getmenu.blog?.id)} style={{ cursor: "pointer" }}><RiSpam2Line color="#D9D9D9" size="1.3em"/> </div>
                  
                                        ) : (
                                          <div className="col-1 " onClick={() => addSpam(getmenu.blog?.id)} style={{ cursor: "pointer" }}><RiSpam2Line color="#626262" size="1.3em"/> </div>
                  
                                        )
                                      }
                                      {getmenu.blog?.spamCount >=3 ?
              (
              <span className='viewsub-activ-spn'style={{marginLeft:'20px',whiteSpace:"nowrap",backgroundColor:"rgba(211, 15, 69, 1)",color:"#F2F2F2"}} >Spam</span>
              ):
              (
                <span>
              {/* <span className='viewsub-activ-spn'style={{marginLeft:'120px',whiteSpace:"nowrap"}} >Active</span> */}
              <span  className={getmenu.blog?.userSpamStatus==true?"viewsub-activ-spn":"hide"} style={{float:'right',whiteSpace:"nowrap",backgroundColor:"rgba(211, 15, 69, 1)",color:"#F2F2F2",marginLeft:'20px'}} >Spam</span>
              </span>
              )
              }
                                      </div>
                  
                                    </div>
                                    <div className='row margintop1'>
          <div className='col-4'></div>
          <div className="col-lg-2 maxwd22 col-sm-4 col-md-6 report-spm-div-delete"  style={{ cursor: "pointer" }}><MdOutlineDelete size="1.3em" color="#FF8427" style={{marginLeft:"-7px"}}/><span className="marginleft2 report-spm" style={{color:"#FF8427"}}>Delete</span> </div>
          <div className='col-1 maxwd33'></div>
          <div className="col-lg-2 maxwd22 col-sm-4 col-md-6 report-spm-div" onClick={() => loadPost1(getmenu.blog?.id, getmenu.blog?.type)} style={{ cursor: "pointer" }}><RiEdit2Line size="1.3em" color="#FFFFFF" /><span className="marginleft2 report-spm">Edit</span> </div>
          <div className='col-3'></div>

          </div>
                                    <Card className={comment && getmenu.blog?.id == cmntEventId ? "margintop3" : "hide row"} >
                                      <div className="">
                                        <div className="" onClick={() => Commentpoupopen(false)} style={{float:"right",marginRight:"10px",cursor:"pointer",fontSize:'13px'}}>X</div>
                                        <Card className="overflow-hidden" style={{ height: "auto", border: "none" }}>
                                          {/* <Card className="mb-lg-0" style={{ height: "auto", border: "none" }}> */}
                  
                                            <Card.Body>
                                              <div className="mt-2">
                                                <FormGroup className="form-group " >
                                                  <Form.Control
                                                    type="text"
                                                    className="form-control borderadius25px "
                                                    id="name1"
                                                    name="txtCmnt"
                                                    placeholder="Add a Comment"
                                                    style={{ fontSize: "13px", borderRadius: "25px" }}
                                                    onChange={handleChange}
                                                    onKeyPress={handleKeypress}
                                                    value={cmntValue.txtCmnt}
                                                  />
                                                  <label className={commentValiderr ? "event-sub-detail1" : "hide"} style={{ color: "red" }}>Please Add Comment</label>
                  
                                                </FormGroup>
                  
                                                <button className="attendbtn" style={{ width: "90px", height: "28px" }} onClick={() => saveComent(getmenu.blog?.id)}>
                                                  Send Reply
                                                </button>
                                                {/* <span onClick={() => Commentpoupopen(false)}>close</span> */}
                                              </div>
                                            </Card.Body>
                                          {/* </Card> */}
                                        </Card>
                                      </div>
                                      {
  commentData.map((commentmenu, index) => {
    // Extract first letter of first name and first letter of last name
    const nameParts = commentmenu.viewerName ? commentmenu.viewerName.split(" ") : [];
    const initials =
      nameParts.length > 1
        ? `${nameParts[0][0].toUpperCase()}${nameParts[nameParts.length - 1][0].toUpperCase()}`
        : nameParts[0]
        ? nameParts[0][0].toUpperCase()
        : "";

    return (
      <div key={index}>
        <Card className="" style={{ height: "auto", border: "none" }}>
          <Card.Body>
            <div className="d-sm-flex mt-0 p-3 sub-review-section border br-bl-0 br-br-0">
              <div className="media-body">
                {/* Flex container to keep avatar and name in one line */}
                <h6
                  className="mt-0 mb-1 font-12 font-weight-semibold"
                  style={{
                    fontSize: "13px",
                    display:"inline-flex",
                  }}
                >
                  {/* Profile Picture or Avatar */}
                  {commentmenu.profilePicture ? (
                    <img
                      src={commentmenu.profilePicture}
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        marginRight:"6px",
                        marginTop:"-6px"
                      }}
                    />
                  ) : (
                    <Avatar
                      style={{
                        width: "30px",
                        height: "30px",
                        fontSize: "12px",
                        backgroundColor: bgColor,
                        color: "#ffffff",
                        marginTop:"-6px",
                        marginRight:"6px"
                      }}
                    >
                      {initials}
                    </Avatar>
                  )}

                  {/* Viewer Name */}
                  {commentmenu.viewerName}
                </h6>

                {/* Comment Text */}
                <p className="font-11 mb-2 mt-2" style={{ fontSize: "12px" }}>
                  {commentmenu.comment}
                </p>

                {/* Delete Badge */}
                <Badge
                  bg=""
                  className={commentmenu.viewerId == uerAuthId ? "bg-danger" : "hide"}
                  onClick={() => deleteComment(commentmenu.id)}
                  style={{ cursor: "pointer" }}
                >
                  Delete
                </Badge>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  })
}
                  
                                    </Card>
                  
                 
                  
                  
                 
            </div>
</div>   

<div className={ archiveDiv==false && spamDIv==false && activeDiv==false && getmenu.kyc?"":"hide"}>

            <div className={getmenu.kyc ? "radius-div-news margintop3" : "hide"}>
            <div className="row margintop1">
                    <div className="col-lg-1 col-sm-12 col-md-12">
                        {getmenu.kyc?.bannerImagePath == "" || getmenu.kyc?.bannerImagePath == null ?
                        (<Avatar className="avtarimgsm" style={{ width: "50px",marginTop:"px", height: "49px",backgroundColor: bgColor,color:"#ffffff" }}>{getmenu.kyc?.authorName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.kyc?.bannerImagePath} id='profpicdisablesave5' className=''style={{ width: "50px",marginTop:"px", height: "49px",borderRadius:"50%"}} />)
                      }
                    </div>
                    <div className="col-lg-5 col-sm-12 col-md-12 textleft" style={{marginTop:"4px"}}>
                      <span className="post-host-Name ">{getmenu.kyc?.authorName}</span>
                      <div className=""style={{marginTop:"-3px"}}><span className="event-date">{getmenu.kyc?.createdDate} @{getmenu.kyc?.creationTime}</span></div>
                      </div>
                    <div className="col-lg-2 col-sm-12" style={{ whiteSpace: "nowrap",marginTop:"px" }}>
                      </div>
                    <div className="col-lg-4 col-sm-12 col-md-12 textright"style={{marginTop:"4px"}}><span className="post-host-Name"></span>
                   <div className=""style={{marginTop:"-3px"}}><span className="event-date ">Published by {getmenu.kyc?.authorName}</span></div> 
                   
                    </div>
                  </div>
                  <div className="row margintop1">
                    <div className="margintop1">
                   {/* <div className=""style={{marginTop:"-3px"}}><span className="event-date ">Published by {getmenu.blog?.eventType}</span></div>  */}
                   <span className="post-host-Name" style={{color:"#626262",marginleft:'7px'}}>Know Your Community-</span>
                   <span className="post-host-Name">{getmenu.kyc?.kycPerson} </span> <span className="post-host-Name">{getmenu.kyc?.from}-{getmenu.kyc?.to} </span>
                  </div>
                  </div>
                  <div className="row margintop3">
                    <img src={getmenu.kyc?.bannerImagePath} className='blogimg' />
                    <div ><span className="event-date"style={{float:"left"}}>Author:{getmenu.kyc?.authorName}</span><span className="event-date" style={{float:"right"}}>Image Source - {getmenu.kyc?.imageSource}</span></div>

                    <div className="blog-textdiv margintop3">
                    <span className="event-date">
                    <span className={`contentkyc ${expanded ? "expanded" : "collapsed"}`} style={{textTransform:"capitalize"}}>
        {getmenu.kyc?.kycContent}
      </span>
  </span>
  
                      <div className="textright">
                      {getmenu.kyc?.kycContent.length > 100 && ( // Show button only if content is long
        <div className="textright">
          <span className="read-more-blog" onClick={() => setExpanded(!expanded)} style={{cursor:"pointer"}}>
            {expanded ? "Read Less" : "Read More"}
          </span>
        </div>
      )}
                        </div>

                    </div>
                  </div>
                  <div className="row margintop3">
                    <div  className=""><div className="borderBottombtn" style={{marginLeft:"0px"}}></div></div>
                  </div>
                  <div className="row margintop3">
                                      <div className="col-lg-2 maxwd12 col-sm-4 col-md-6">
                                        {/* <BiLike className={status==='like'? 'activelike' : ''} onClick={handleClickLike}/><span className="likespn marginleft2"> {likes}</span> */}
                                        {getmenu.kyc?.likeStatus == true ? <AiFillLike color="#626262" size="1.3em" onClick={() => addLikes(getmenu.kyc?.id)} style={{ cursor: "pointer" }} /> : <BiLike onClick={() => addLikes(getmenu.kyc?.id)} size="1.3em" style={{ cursor: "pointer" }} color="#D9D9D9" />}<span className="post-name-btn " style={{color:"#626262",fontSize:'14px',marginLeft:"4px",cursor:"pointer"}} onClick={() => loadLikesInfo(getmenu.kyc?.id)}> {getmenu.kyc?.likeCount} </span>
                                      </div>
                                      <div className="col-lg-2 maxwd12 col-md-4 col-sm-4" onClick={() => commentInfo(getmenu.kyc?.id)} style={{ cursor: "pointer" }}><FaRegComments size="1.3em" color="#626262" /><span className="post-name-btn " style={{color:"#626262",fontSize:'14px',marginLeft:"6px"}}>{getmenu.kyc?.commentCount} </span></div>
                                      <div className="col-lg-7"></div>

                                      
                                      <div className='col-2 displyinline' style={{float:"right"}}>
                                      {getmenu.kyc?.userSpamStatus == false ?
                                        (
                                          <div className="col-lg-1 col-sm-5 col-md-4 " onClick={() => addSpam(getmenu.kyc?.id)} style={{ cursor: "pointer" }}><RiSpam2Line color="#D9D9D9" size="1.3em"/> </div>
                  
                                        ) : (
                                          <div className="col-1 " onClick={() => addSpam(getmenu.kyc?.id)} style={{ cursor: "pointer" }}><RiSpam2Line color="#626262" size="1.3em"/> </div>
                  
                                        )
                                      }
                                      {getmenu.kyc?.spamCount >=3 ?
              (
              <span className='viewsub-activ-spn'style={{marginLeft:'20px',whiteSpace:"nowrap",backgroundColor:"rgba(211, 15, 69, 1)",color:"#F2F2F2"}} >Spam</span>
              ):
              (
                <span>
                {/* <span className='viewsub-activ-spn'style={{marginLeft:'120px',whiteSpace:"nowrap"}} >Active</span> */}
                <span  className={getmenu.kyc?.activeStatus=='Archived'?"viewsub-activ-spn":"hide"} style={{backgroundColor:"orange",marginLeft:'20px'}}>Archived</span>
                <span style={{float:'right',whiteSpace:"nowrap",marginLeft:'20px'}}  className={getmenu.kyc?.activeStatus=='Active'?"viewsub-activ-spn":"hide"} >Active</span>
                <span  className={getmenu.kyc?.userSpamStatus==true?"viewsub-activ-spn":"hide"} style={{float:'right',whiteSpace:"nowrap",backgroundColor:"rgba(211, 15, 69, 1)",color:"#F2F2F2",marginLeft:'20px'}} >Spam</span>
                </span>              )
              }
                                      </div>
                  
                                    </div>
                                   

          <div className='row margintop1'>
          <div className='col-4'></div>
          <div className="col-lg-2 maxwd22 col-sm-4 col-md-6 report-spm-div-delete"  style={{ cursor: "pointer" }}><MdOutlineDelete size="1.3em" color="#FF8427" style={{marginLeft:"-7px"}}/><span className="marginleft2 report-spm" style={{color:"#FF8427"}}>Delete</span> </div>
          <div className='col-1 maxwd33'></div>
          <div className="col-lg-2 maxwd22 col-sm-4 col-md-6 report-spm-div"  onClick={() => loadPost1(getmenu.kyc?.id, getmenu.kyc?.type)} style={{ cursor: "pointer" }}><RiEdit2Line size="1.3em" color="#FFFFFF" /><span className="marginleft2 report-spm">Edit</span> </div>
          <div className='col-3'></div>
          </div>
                                    <Card className={comment && getmenu.kyc?.id == cmntEventId ? "margintop3" : "hide row"} >
                                      <div className="">
                                        <div className="" onClick={() => Commentpoupopen(false)} style={{float:"right",marginRight:"10px",cursor:"pointer",fontSize:'13px'}}>X</div>
                                        <Card className="overflow-hidden" style={{ height: "auto", border: "none" }}>
                                          {/* <Card className="mb-lg-0" style={{ height: "auto", border: "none" }}> */}
                  
                                            <Card.Body>
                                              <div className="mt-2">
                                                <FormGroup className="form-group " >
                                                  <Form.Control
                                                    type="text"
                                                    className="form-control borderadius25px "
                                                    id="name1"
                                                    name="txtCmnt"
                                                    placeholder="Add a Comment"
                                                    style={{ fontSize: "13px", borderRadius: "25px" }}
                                                    onChange={handleChange}
                                                    onKeyPress={handleKeypress}
                                                    value={cmntValue.txtCmnt}
                                                  />
                                                  <label className={commentValiderr ? "event-sub-detail1" : "hide"} style={{ color: "red" }}>Please Add Comment</label>
                  
                                                </FormGroup>
                  
                                                <button className="attendbtn" style={{ width: "90px", height: "28px" }} onClick={() => saveComent(getmenu.kyc?.id)}>
                                                  Send Reply
                                                </button>
                                                {/* <span onClick={() => Commentpoupopen(false)}>close</span> */}
                                              </div>
                                            </Card.Body>
                                          {/* </Card> */}
                                        </Card>
                                      </div>
                                      {
  commentData.map((commentmenu, index) => {
    // Extract first letter of first name and first letter of last name
    const nameParts = commentmenu.viewerName ? commentmenu.viewerName.split(" ") : [];
    const initials =
      nameParts.length > 1
        ? `${nameParts[0][0].toUpperCase()}${nameParts[nameParts.length - 1][0].toUpperCase()}`
        : nameParts[0]
        ? nameParts[0][0].toUpperCase()
        : "";

    return (
      <div key={index}>
        <Card className="" style={{ height: "auto", border: "none" }}>
          <Card.Body>
            <div className="d-sm-flex mt-0 p-3 sub-review-section border br-bl-0 br-br-0">
              <div className="media-body">
                {/* Flex container to keep avatar and name in one line */}
                <h6
                  className="mt-0 mb-1 font-12 font-weight-semibold"
                  style={{
                    fontSize: "13px",
                    display:"inline-flex",
                  }}
                >
                  {/* Profile Picture or Avatar */}
                  {commentmenu.profilePicture ? (
                    <img
                      src={commentmenu.profilePicture}
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        marginRight:"6px",
                        marginTop:"-6px"
                      }}
                    />
                  ) : (
                    <Avatar
                      style={{
                        width: "30px",
                        height: "30px",
                        fontSize: "12px",
                        backgroundColor: bgColor,
                        color: "#ffffff",
                        marginTop:"-6px",
                        marginRight:"6px"
                      }}
                    >
                      {initials}
                    </Avatar>
                  )}

                  {/* Viewer Name */}
                  {commentmenu.viewerName}
                </h6>

                {/* Comment Text */}
                <p className="font-11 mb-2 mt-2" style={{ fontSize: "12px" }}>
                  {commentmenu.comment}
                </p>

                {/* Delete Badge */}
                <Badge
                  bg=""
                  className={commentmenu.viewerId == uerAuthId ? "bg-danger" : "hide"}
                  onClick={() => deleteComment(commentmenu.id)}
                  style={{ cursor: "pointer" }}
                >
                  Delete
                </Badge>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  })
}
                  
                                    </Card>
                  
                  
                 
            </div>
</div>

  <div className={ archiveDiv && getmenu.kyc?.activeStatus=='Archived'?"":"hide"}>
  
            <div className={getmenu.kyc ? "radius-div-news margintop3" : "hide"}>
            <div className="row margintop1">
                    <div className="col-lg-1 col-sm-12 col-md-12">
                        {getmenu.kyc?.bannerImagePath == "" || getmenu.kyc?.bannerImagePath == null ?
                        (<Avatar className="avtarimgsm" style={{ width: "50px",marginTop:"px", height: "49px",backgroundColor: bgColor,color:"#ffffff" }}>{getmenu.kyc?.authorName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.kyc?.bannerImagePath} id='profpicdisablesave5' className=''style={{ width: "50px",marginTop:"px", height: "49px",borderRadius:"50%"}} />)
                      }
                    </div>
                    <div className="col-lg-5 col-sm-12 col-md-12 textleft" style={{marginTop:"4px"}}>
                      <span className="post-host-Name ">{getmenu.kyc?.authorName}</span>
                      <div className=""style={{marginTop:"-3px"}}><span className="event-date">{getmenu.kyc?.createdDate} @{getmenu.kyc?.creationTime}</span></div>
                      </div>
                    <div className="col-lg-2 col-sm-12" style={{ whiteSpace: "nowrap",marginTop:"px" }}>
                      </div>
                    <div className="col-lg-4 col-sm-12 col-md-12 textright"style={{marginTop:"4px"}}><span className="post-host-Name"></span>
                   <div className=""style={{marginTop:"-3px"}}><span className="event-date ">Published by {getmenu.kyc?.authorName}</span></div> 
                   
                    </div>
                  </div>
                  <div className="row margintop1">
                    <div className="margintop1">
                   {/* <div className=""style={{marginTop:"-3px"}}><span className="event-date ">Published by {getmenu.blog?.eventType}</span></div>  */}
                   <span className="post-host-Name" style={{color:"#626262",marginleft:'7px'}}>Know Your Community-</span>
                   <span className="post-host-Name">{getmenu.kyc?.kycPerson} </span> <span className="post-host-Name">{getmenu.kyc?.from}-{getmenu.kyc?.to} </span>
                  </div>
                  </div>
                  <div className="row margintop3">
                    <img src={getmenu.kyc?.bannerImagePath} className='blogimg' />
                    <div ><span className="event-date"style={{float:"left"}}>Author:{getmenu.kyc?.authorName}</span><span className="event-date" style={{float:"right"}}>Image Source - {getmenu.kyc?.imageSource}</span></div>

                    <div className="blog-textdiv margintop3">
                    <span className="event-date">
                    <span className={`contentkyc ${expanded ? "expanded" : "collapsed"}`} style={{textTransform:"capitalize"}}>
        {getmenu.kyc?.kycContent}
      </span>
  </span>
  
                      <div className="textright">
                      {getmenu.kyc?.kycContent.length > 100 && ( // Show button only if content is long
        <div className="textright">
          <span className="read-more-blog" onClick={() => setExpanded(!expanded)} style={{cursor:"pointer"}}>
            {expanded ? "Read Less" : "Read More"}
          </span>
        </div>
      )}
                        </div>

                    </div>
                  </div>
                  <div className="row margintop3">
                    <div  className=""><div className="borderBottombtn" style={{marginLeft:"0px"}}></div></div>
                  </div>
                  <div className="row margintop3">
                                      <div className="col-lg-2 maxwd12 col-sm-4 col-md-6">
                                        {/* <BiLike className={status==='like'? 'activelike' : ''} onClick={handleClickLike}/><span className="likespn marginleft2"> {likes}</span> */}
                                        {getmenu.kyc?.likeStatus == true ? <AiFillLike color="#626262" size="1.3em" onClick={() => addLikes(getmenu.kyc?.id)} style={{ cursor: "pointer" }} /> : <BiLike onClick={() => addLikes(getmenu.kyc?.id)} size="1.3em" style={{ cursor: "pointer" }} color="#D9D9D9" />}<span className="post-name-btn " style={{color:"#626262",fontSize:'14px',marginLeft:"4px",cursor:"pointer"}} onClick={() => loadLikesInfo(getmenu.kyc?.id)}> {getmenu.kyc?.likeCount} </span>
                                      </div>
                                      <div className="col-lg-2 maxwd12 col-md-4 col-sm-4" onClick={() => commentInfo(getmenu.kyc?.id)} style={{ cursor: "pointer" }}><FaRegComments size="1.3em" color="#626262" /><span className="post-name-btn " style={{color:"#626262",fontSize:'14px',marginLeft:"6px"}}>{getmenu.kyc?.commentCount} </span></div>
                                      <div className="col-lg-7"></div>

                                      
                                      <div className='col-2 displyinline' style={{float:"right"}}>

                                      {getmenu.kyc?.spamCount >=3 ?
              (
              <span className='viewsub-activ-spn'style={{marginLeft:'20px',whiteSpace:"nowrap",backgroundColor:"rgba(211, 15, 69, 1)",color:"#F2F2F2"}} >Spam</span>
              ):
              (
                <h6>
                {/* <span className='viewsub-activ-spn'style={{marginLeft:'120px',whiteSpace:"nowrap"}} >Active</span> */}
                <span  className={getmenu.kyc?.activeStatus=='Archived'?"viewsub-activ-spn":"hide"} style={{backgroundColor:"orange",marginLeft:'20px'}}>Archived</span>
                </h6>              )
              }
                                      </div>
                  
                                    </div>
                                    <div className='row margintop1'>
          <div className='col-4'></div>
          <div className="col-lg-2 maxwd22 col-sm-4 col-md-6 report-spm-div-delete"  style={{ cursor: "pointer" }}><MdOutlineDelete size="1.3em" color="#FF8427" style={{marginLeft:"-7px"}}/><span className="marginleft2 report-spm" style={{color:"#FF8427"}}>Delete</span> </div>
          <div className='col-1 maxwd33'></div>
          <div className="col-lg-2 maxwd22 col-sm-4 col-md-6 report-spm-div"  onClick={() => loadPost1(getmenu.kyc?.id, getmenu.kyc?.type)} style={{ cursor: "pointer" }}><RiEdit2Line size="1.3em" color="#FFFFFF" /><span className="marginleft2 report-spm">Edit</span> </div>
          <div className='col-3'></div>
          </div>
                                    <Card className={comment && getmenu.kyc?.id == cmntEventId ? "margintop3" : "hide row"} >
                                      <div className="">
                                        <div className="" onClick={() => Commentpoupopen(false)} style={{float:"right",marginRight:"10px",cursor:"pointer",fontSize:'13px'}}>X</div>
                                        <Card className="overflow-hidden" style={{ height: "auto", border: "none" }}>
                                          {/* <Card className="mb-lg-0" style={{ height: "auto", border: "none" }}> */}
                  
                                            <Card.Body>
                                              <div className="mt-2">
                                                <FormGroup className="form-group " >
                                                  <Form.Control
                                                    type="text"
                                                    className="form-control borderadius25px "
                                                    id="name1"
                                                    name="txtCmnt"
                                                    placeholder="Add a Comment"
                                                    style={{ fontSize: "13px", borderRadius: "25px" }}
                                                    onChange={handleChange}
                                                    onKeyPress={handleKeypress}
                                                    value={cmntValue.txtCmnt}
                                                  />
                                                  <label className={commentValiderr ? "event-sub-detail1" : "hide"} style={{ color: "red" }}>Please Add Comment</label>
                  
                                                </FormGroup>
                  
                                                <button className="attendbtn" style={{ width: "90px", height: "28px" }} onClick={() => saveComent(getmenu.kyc?.id)}>
                                                  Send Reply
                                                </button>
                                                {/* <span onClick={() => Commentpoupopen(false)}>close</span> */}
                                              </div>
                                            </Card.Body>
                                          {/* </Card> */}
                                        </Card>
                                      </div>
                                      {
  commentData.map((commentmenu, index) => {
    // Extract first letter of first name and first letter of last name
    const nameParts = commentmenu.viewerName ? commentmenu.viewerName.split(" ") : [];
    const initials =
      nameParts.length > 1
        ? `${nameParts[0][0].toUpperCase()}${nameParts[nameParts.length - 1][0].toUpperCase()}`
        : nameParts[0]
        ? nameParts[0][0].toUpperCase()
        : "";

    return (
      <div key={index}>
        <Card className="" style={{ height: "auto", border: "none" }}>
          <Card.Body>
            <div className="d-sm-flex mt-0 p-3 sub-review-section border br-bl-0 br-br-0">
              <div className="media-body">
                {/* Flex container to keep avatar and name in one line */}
                <h6
                  className="mt-0 mb-1 font-12 font-weight-semibold"
                  style={{
                    fontSize: "13px",
                    display:"inline-flex",
                  }}
                >
                  {/* Profile Picture or Avatar */}
                  {commentmenu.profilePicture ? (
                    <img
                      src={commentmenu.profilePicture}
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        marginRight:"6px",
                        marginTop:"-6px"
                      }}
                    />
                  ) : (
                    <Avatar
                      style={{
                        width: "30px",
                        height: "30px",
                        fontSize: "12px",
                        backgroundColor: bgColor,
                        color: "#ffffff",
                        marginTop:"-6px",
                        marginRight:"6px"
                      }}
                    >
                      {initials}
                    </Avatar>
                  )}

                  {/* Viewer Name */}
                  {commentmenu.viewerName}
                </h6>

                {/* Comment Text */}
                <p className="font-11 mb-2 mt-2" style={{ fontSize: "12px" }}>
                  {commentmenu.comment}
                </p>

                {/* Delete Badge */}
                <Badge
                  bg=""
                  className={commentmenu.viewerId == uerAuthId ? "bg-danger" : "hide"}
                  onClick={() => deleteComment(commentmenu.id)}
                  style={{ cursor: "pointer" }}
                >
                  Delete
                </Badge>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  })
}
                  
                                    </Card>
                  
                  
                 
            </div>
</div>          
<div className={ activeDiv && getmenu.kyc?.activeStatus=='Active'?"":"hide"}>

            <div className={getmenu.kyc ? "radius-div-news margintop3" : "hide"}>
            <div className="row margintop1">
                    <div className="col-lg-1 col-sm-12 col-md-12">
                        {getmenu.kyc?.bannerImagePath == "" || getmenu.kyc?.bannerImagePath == null ?
                        (<Avatar className="avtarimgsm" style={{ width: "50px",marginTop:"px", height: "49px",backgroundColor: bgColor,color:"#ffffff" }}>{getmenu.kyc?.authorName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.kyc?.bannerImagePath} id='profpicdisablesave5' className=''style={{ width: "50px",marginTop:"px", height: "49px",borderRadius:"50%"}}/>)
                      }
                    </div>
                    <div className="col-lg-5 col-sm-12 col-md-12 textleft" style={{marginTop:"4px"}}>
                      <span className="post-host-Name ">{getmenu.kyc?.authorName}</span>
                      <div className=""style={{marginTop:"-3px"}}><span className="event-date">{getmenu.kyc?.createdDate} @{getmenu.kyc?.creationTime}</span></div>
                      </div>
                    <div className="col-lg-2 col-sm-12" style={{ whiteSpace: "nowrap",marginTop:"px" }}>
                      </div>
                    <div className="col-lg-4 col-sm-12 col-md-12 textright"style={{marginTop:"4px"}}><span className="post-host-Name"></span>
                   <div className=""style={{marginTop:"-3px"}}><span className="event-date ">Published by {getmenu.kyc?.authorName}</span></div> 
                   
                    </div>
                  </div>
                  <div className="row margintop1">
                    <div className="margintop1">
                   {/* <div className=""style={{marginTop:"-3px"}}><span className="event-date ">Published by {getmenu.blog?.eventType}</span></div>  */}
                   <span className="post-host-Name" style={{color:"#626262",marginleft:'7px'}}>Know Your Community-</span>
                   <span className="post-host-Name">{getmenu.kyc?.kycPerson} </span> <span className="post-host-Name">{getmenu.kyc?.from}-{getmenu.kyc?.to} </span>
                  </div>
                  </div>
                  <div className="row margintop3">
                    <img src={getmenu.kyc?.bannerImagePath} className='blogimg' />
                    <div ><span className="event-date"style={{float:"left"}}>Author:{getmenu.kyc?.authorName}</span><span className="event-date" style={{float:"right"}}>Image Source - {getmenu.kyc?.imageSource}</span></div>

                    <div className="blog-textdiv margintop3">
                    <span className="event-date">
                    <span className={`contentkyc ${expanded ? "expanded" : "collapsed"}`} style={{textTransform:"capitalize"}}>
        {getmenu.kyc?.kycContent}
      </span>
  </span>
  
                      <div className="textright">
                      {getmenu.kyc?.kycContent.length > 100 && ( // Show button only if content is long
        <div className="textright">
          <span className="read-more-blog" onClick={() => setExpanded(!expanded)} style={{cursor:"pointer"}}>
            {expanded ? "Read Less" : "Read More"}
          </span>
        </div>
      )}
                        </div>

                    </div>
                  </div>
                  <div className="row margintop3">
                    <div  className=""><div className="borderBottombtn" style={{marginLeft:"0px"}}></div></div>
                  </div>
                  <div className="row margintop3">
                                      <div className="col-lg-2 maxwd12 col-sm-4 col-md-6">
                                        {/* <BiLike className={status==='like'? 'activelike' : ''} onClick={handleClickLike}/><span className="likespn marginleft2"> {likes}</span> */}
                                        {getmenu.kyc?.likeStatus == true ? <AiFillLike color="#626262" size="1.3em" onClick={() => addLikes(getmenu.kyc?.id)} style={{ cursor: "pointer" }} /> : <BiLike onClick={() => addLikes(getmenu.kyc?.id)} size="1.3em" style={{ cursor: "pointer" }} color="#D9D9D9" />}<span className="post-name-btn " style={{color:"#626262",fontSize:'14px',marginLeft:"4px",cursor:"pointer"}} onClick={() => loadLikesInfo(getmenu.kyc?.id)}> {getmenu.kyc?.likeCount} </span>
                                      </div>
                                      <div className="col-lg-2 maxwd12 col-md-4 col-sm-4" onClick={() => commentInfo(getmenu.kyc?.id)} style={{ cursor: "pointer" }}><FaRegComments size="1.3em" color="#626262" /><span className="post-name-btn " style={{color:"#626262",fontSize:'14px',marginLeft:"6px"}}>{getmenu.kyc?.commentCount} </span></div>
                                      <div className="col-lg-7"></div>

                                      
                                      <div className='col-2 displyinline' style={{float:"right"}}>
                                      {getmenu.kyc?.userSpamStatus == false ?
                                        (
                                          <div className="col-lg-1 col-sm-5 col-md-4 " onClick={() => addSpam(getmenu.kyc?.id)} style={{ cursor: "pointer" }}><RiSpam2Line color="#D9D9D9" size="1.3em"/> </div>
                  
                                        ) : (
                                          <div className="col-1 " onClick={() => addSpam(getmenu.kyc?.id)} style={{ cursor: "pointer" }}><RiSpam2Line color="#626262" size="1.3em"/> </div>
                  
                                        )
                                      }
                                      {getmenu.kyc?.spamCount >=3 ?
              (
              <span className='viewsub-activ-spn'style={{marginLeft:'20px',whiteSpace:"nowrap",backgroundColor:"rgba(211, 15, 69, 1)",color:"#F2F2F2"}} >Spam</span>
              ):
              (
                <h6>
                {/* <span className='viewsub-activ-spn'style={{marginLeft:'120px',whiteSpace:"nowrap"}} >Active</span> */}
                <span  className={getmenu.kyc?.activeStatus=='Archived'?"viewsub-activ-spn":"hide"} style={{marginLeft:'20px'}}>Active</span>
                </h6>              )
              }
                                      </div>
                  
                                    </div>
                                    <div className='row margintop1'>
          <div className='col-4'></div>
          <div className="col-lg-2 maxwd22 col-sm-4 col-md-6 report-spm-div-delete"  style={{ cursor: "pointer" }}><MdOutlineDelete size="1.3em" color="#FF8427" style={{marginLeft:"-7px"}}/><span className="marginleft2 report-spm" style={{color:"#FF8427"}}>Delete</span> </div>
          <div className='col-1 maxwd33'></div>
          <div className="col-lg-2 maxwd22 col-sm-4 col-md-6 report-spm-div"  onClick={() => loadPost1(getmenu.kyc?.id, getmenu.kyc?.type)} style={{ cursor: "pointer" }}><RiEdit2Line size="1.3em" color="#FFFFFF" /><span className="marginleft2 report-spm">Edit</span> </div>
          <div className='col-3'></div>
          </div>
               <Card className={comment && getmenu.kyc?.id == cmntEventId ? "margintop3" : "hide row"} >
                                      <div className="">
                                        <div className="" onClick={() => Commentpoupopen(false)} style={{float:"right",marginRight:"10px",cursor:"pointer",fontSize:'13px'}}>X</div>
                                        <Card className="overflow-hidden" style={{ height: "auto", border: "none" }}>
                                          {/* <Card className="mb-lg-0" style={{ height: "auto", border: "none" }}> */}
                  
                                            <Card.Body>
                                              <div className="mt-2">
                                                <FormGroup className="form-group " >
                                                  <Form.Control
                                                    type="text"
                                                    className="form-control borderadius25px "
                                                    id="name1"
                                                    name="txtCmnt"
                                                    placeholder="Add a Comment"
                                                    style={{ fontSize: "13px", borderRadius: "25px" }}
                                                    onChange={handleChange}
                                                    onKeyPress={handleKeypress}
                                                    value={cmntValue.txtCmnt}
                                                  />
                                                  <label className={commentValiderr ? "event-sub-detail1" : "hide"} style={{ color: "red" }}>Please Add Comment</label>
                  
                                                </FormGroup>
                  
                                                <button className="attendbtn" style={{ width: "90px", height: "28px" }} onClick={() => saveComent(getmenu.kyc?.id)}>
                                                  Send Reply
                                                </button>
                                                {/* <span onClick={() => Commentpoupopen(false)}>close</span> */}
                                              </div>
                                            </Card.Body>
                                          {/* </Card> */}
                                        </Card>
                                      </div>
                                      {
  commentData.map((commentmenu, index) => {
    // Extract first letter of first name and first letter of last name
    const nameParts = commentmenu.viewerName ? commentmenu.viewerName.split(" ") : [];
    const initials =
      nameParts.length > 1
        ? `${nameParts[0][0].toUpperCase()}${nameParts[nameParts.length - 1][0].toUpperCase()}`
        : nameParts[0]
        ? nameParts[0][0].toUpperCase()
        : "";

    return (
      <div key={index}>
        <Card className="" style={{ height: "auto", border: "none" }}>
          <Card.Body>
            <div className="d-sm-flex mt-0 p-3 sub-review-section border br-bl-0 br-br-0">
              <div className="media-body">
                {/* Flex container to keep avatar and name in one line */}
                <h6
                  className="mt-0 mb-1 font-12 font-weight-semibold"
                  style={{
                    fontSize: "13px",
                    display:"inline-flex",
                  }}
                >
                  {/* Profile Picture or Avatar */}
                  {commentmenu.profilePicture ? (
                    <img
                      src={commentmenu.profilePicture}
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        marginRight:"6px",
                        marginTop:"-6px"
                      }}
                    />
                  ) : (
                    <Avatar
                      style={{
                        width: "30px",
                        height: "30px",
                        fontSize: "12px",
                        backgroundColor: bgColor,
                        color: "#ffffff",
                        marginTop:"-6px",
                        marginRight:"6px"
                      }}
                    >
                      {initials}
                    </Avatar>
                  )}

                  {/* Viewer Name */}
                  {commentmenu.viewerName}
                </h6>

                {/* Comment Text */}
                <p className="font-11 mb-2 mt-2" style={{ fontSize: "12px" }}>
                  {commentmenu.comment}
                </p>

                {/* Delete Badge */}
                <Badge
                  bg=""
                  className={commentmenu.viewerId == uerAuthId ? "bg-danger" : "hide"}
                  onClick={() => deleteComment(commentmenu.id)}
                  style={{ cursor: "pointer" }}
                >
                  Delete
                </Badge>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  })
}
                  
                                    </Card>
                  
                  
                 
            </div>
 </div>
 <div className={ spamDIv && getmenu.kyc?.userSpamStatus==true?"":"hide"}>
 
            <div className={getmenu.kyc ? "radius-div-news margintop3" : "hide"}>
            <div className="row margintop1">
                    <div className="col-lg-1 col-sm-12 col-md-12">
                        {getmenu.kyc?.bannerImagePath == "" || getmenu.kyc?.bannerImagePath == null ?
                        (<Avatar className="avtarimgsm" style={{ width: "50px",marginTop:"px", height: "49px",backgroundColor: bgColor,color:"#ffffff" }}>{getmenu.kyc?.authorName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.kyc?.bannerImagePath} id='profpicdisablesave5' className=''style={{ width: "50px",marginTop:"px", height: "49px",borderRadius:"50%"}} />)
                      }
                    </div>
                    <div className="col-lg-5 col-sm-12 col-md-12 textleft" style={{marginTop:"4px"}}>
                      <span className="post-host-Name ">{getmenu.kyc?.authorName}</span>
                      <div className=""style={{marginTop:"-3px"}}><span className="event-date">{getmenu.kyc?.createdDate} @{getmenu.kyc?.creationTime}</span></div>
                      </div>
                    <div className="col-lg-2 col-sm-12" style={{ whiteSpace: "nowrap",marginTop:"px" }}>
                      </div>
                    <div className="col-lg-4 col-sm-12 col-md-12 textright"style={{marginTop:"4px"}}><span className="post-host-Name"></span>
                   <div className=""style={{marginTop:"-3px"}}><span className="event-date ">Published by {getmenu.kyc?.authorName}</span></div> 
                   
                    </div>
                  </div>
                  <div className="row margintop1">
                    <div className="margintop1">
                   {/* <div className=""style={{marginTop:"-3px"}}><span className="event-date ">Published by {getmenu.blog?.eventType}</span></div>  */}
                   <span className="post-host-Name" style={{color:"#626262",marginleft:'7px'}}>Know Your Community-</span>
                   <span className="post-host-Name">{getmenu.kyc?.kycPerson} </span> <span className="post-host-Name">{getmenu.kyc?.from}-{getmenu.kyc?.to} </span>
                  </div>
                  </div>
                  <div className="row margintop3">
                    <img src={getmenu.kyc?.bannerImagePath} className='blogimg' />
                    <div ><span className="event-date"style={{float:"left"}}>Author:{getmenu.kyc?.authorName}</span><span className="event-date" style={{float:"right"}}>Image Source - {getmenu.kyc?.imageSource}</span></div>

                    <div className="blog-textdiv margintop3">
                    <span className="event-date">
                    <span className={`contentkyc ${expanded ? "expanded" : "collapsed"}`} style={{textTransform:"capitalize"}}>
        {getmenu.kyc?.kycContent}
      </span>
  </span>
  
                      <div className="textright">
                      {getmenu.kyc?.kycContent.length > 100 && ( // Show button only if content is long
        <div className="textright">
          <span className="read-more-blog" onClick={() => setExpanded(!expanded)} style={{cursor:"pointer"}}>
            {expanded ? "Read Less" : "Read More"}
          </span>
        </div>
      )}
                        </div>

                    </div>
                  </div>
                  <div className="row margintop3">
                    <div  className=""><div className="borderBottombtn" style={{marginLeft:"0px"}}></div></div>
                  </div>
                  <div className="row margintop3">
                                      <div className="col-lg-2 maxwd12 col-sm-4 col-md-6">
                                        {/* <BiLike className={status==='like'? 'activelike' : ''} onClick={handleClickLike}/><span className="likespn marginleft2"> {likes}</span> */}
                                        {getmenu.kyc?.likeStatus == true ? <AiFillLike color="#626262" size="1.3em" onClick={() => addLikes(getmenu.kyc?.id)} style={{ cursor: "pointer" }} /> : <BiLike onClick={() => addLikes(getmenu.kyc?.id)} size="1.3em" style={{ cursor: "pointer" }} color="#D9D9D9" />}<span className="post-name-btn " style={{color:"#626262",fontSize:'14px',marginLeft:"4px",cursor:"pointer"}} onClick={() => loadLikesInfo(getmenu.kyc?.id)}> {getmenu.kyc?.likeCount} </span>
                                      </div>
                                      <div className="col-lg-2 maxwd12 col-md-4 col-sm-4" onClick={() => commentInfo(getmenu.kyc?.id)} style={{ cursor: "pointer" }}><FaRegComments size="1.3em" color="#626262" /><span className="post-name-btn " style={{color:"#626262",fontSize:'14px',marginLeft:"6px"}}>{getmenu.kyc?.commentCount} </span></div>
                                      <div className="col-lg-7"></div>

                                      
                                      <div className='col-2 displyinline' style={{float:"right"}}>
                                      {getmenu.kyc?.userSpamStatus == false ?
                                        (
                                          <div className="col-lg-2 col-sm-5 col-md-4 " onClick={() => addSpam(getmenu.kyc?.id)} style={{ cursor: "pointer" }}><RiSpam2Line color="#D9D9D9" size="1.3em"/> </div>
                  
                                        ) : (
                                          <div className="col-1 " onClick={() => addSpam(getmenu.kyc?.id)} style={{ cursor: "pointer" }}><RiSpam2Line color="#626262" size="1.3em"/> </div>
                  
                                        )
                                      }

                                      {getmenu.kyc?.spamCount >=3 ?
              (
              <span className='viewsub-activ-spn'style={{marginLeft:'20px',whiteSpace:"nowrap",backgroundColor:"rgba(211, 15, 69, 1)",color:"#F2F2F2"}} >Spam</span>
              ):
              (
                <h6>
                {/* <span className='viewsub-activ-spn'style={{marginLeft:'120px',whiteSpace:"nowrap"}} >Active</span> */}
                <span  className={getmenu.kyc?.activeStatus=='Archived'?"viewsub-activ-spn":"hide"} style={{marginLeft:'20px',backgroundColor:"rgba(211, 15, 69, 1)"}}>Spam</span>
                </h6>              )
              }
                                      </div>
                  
                                    </div>
                                    <div className='row margintop1'>
          <div className='col-4'></div>
          <div className="col-lg-2 maxwd22 col-sm-4 col-md-6 report-spm-div-delete"  style={{ cursor: "pointer" }}><MdOutlineDelete size="1.3em" color="#FF8427" style={{marginLeft:"-7px"}}/><span className="marginleft2 report-spm" style={{color:"#FF8427"}}>Delete</span> </div>
          <div className='col-1 maxwd33'></div>
          <div className="col-lg-2 maxwd22 col-sm-4 col-md-6 report-spm-div"  onClick={() => loadPost1(getmenu.kyc?.id, getmenu.kyc?.type)} style={{ cursor: "pointer" }}><RiEdit2Line size="1.3em" color="#FFFFFF" /><span className="marginleft2 report-spm">Edit</span> </div>
          <div className='col-3'></div>
          </div>
                                    <Card className={comment && getmenu.kyc?.id == cmntEventId ? "margintop3" : "hide row"} >
                                      <div className="">
                                        <div className="" onClick={() => Commentpoupopen(false)} style={{float:"right",marginRight:"10px",cursor:"pointer",fontSize:'13px'}}>X</div>
                                        <Card className="overflow-hidden" style={{ height: "auto", border: "none" }}>
                                          {/* <Card className="mb-lg-0" style={{ height: "auto", border: "none" }}> */}
                  
                                            <Card.Body>
                                              <div className="mt-2">
                                                <FormGroup className="form-group " >
                                                  <Form.Control
                                                    type="text"
                                                    className="form-control borderadius25px "
                                                    id="name1"
                                                    name="txtCmnt"
                                                    placeholder="Add a Comment"
                                                    style={{ fontSize: "13px", borderRadius: "25px" }}
                                                    onChange={handleChange}
                                                    onKeyPress={handleKeypress}
                                                    value={cmntValue.txtCmnt}
                                                  />
                                                  <label className={commentValiderr ? "event-sub-detail1" : "hide"} style={{ color: "red" }}>Please Add Comment</label>
                  
                                                </FormGroup>
                  
                                                <button className="attendbtn" style={{ width: "90px", height: "28px" }} onClick={() => saveComent(getmenu.kyc?.id)}>
                                                  Send Reply
                                                </button>
                                                {/* <span onClick={() => Commentpoupopen(false)}>close</span> */}
                                              </div>
                                            </Card.Body>
                                          {/* </Card> */}
                                        </Card>
                                      </div>
                                      {
  commentData.map((commentmenu, index) => {
    // Extract first letter of first name and first letter of last name
    const nameParts = commentmenu.viewerName ? commentmenu.viewerName.split(" ") : [];
    const initials =
      nameParts.length > 1
        ? `${nameParts[0][0].toUpperCase()}${nameParts[nameParts.length - 1][0].toUpperCase()}`
        : nameParts[0]
        ? nameParts[0][0].toUpperCase()
        : "";

    return (
      <div key={index}>
        <Card className="" style={{ height: "auto", border: "none" }}>
          <Card.Body>
            <div className="d-sm-flex mt-0 p-3 sub-review-section border br-bl-0 br-br-0">
              <div className="media-body">
                {/* Flex container to keep avatar and name in one line */}
                <h6
                  className="mt-0 mb-1 font-12 font-weight-semibold"
                  style={{
                    fontSize: "13px",
                    display:"inline-flex",
                  }}
                >
                  {/* Profile Picture or Avatar */}
                  {commentmenu.profilePicture ? (
                    <img
                      src={commentmenu.profilePicture}
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        marginRight:"6px",
                        marginTop:"-6px"
                      }}
                    />
                  ) : (
                    <Avatar
                      style={{
                        width: "30px",
                        height: "30px",
                        fontSize: "12px",
                        backgroundColor: bgColor,
                        color: "#ffffff",
                        marginTop:"-6px",
                        marginRight:"6px"
                      }}
                    >
                      {initials}
                    </Avatar>
                  )}

                  {/* Viewer Name */}
                  {commentmenu.viewerName}
                </h6>

                {/* Comment Text */}
                <p className="font-11 mb-2 mt-2" style={{ fontSize: "12px" }}>
                  {commentmenu.comment}
                </p>

                {/* Delete Badge */}
                <Badge
                  bg=""
                  className={commentmenu.viewerId == uerAuthId ? "bg-danger" : "hide"}
                  onClick={() => deleteComment(commentmenu.id)}
                  style={{ cursor: "pointer" }}
                >
                  Delete
                </Badge>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  })
}
                  
                                    </Card>
                  
                  
                 
            </div>
</div>
            {/* <div className="cardiv-event1 border1px">
          <div className="row margintop" >
              <div className="col-1">
              <img src={imgupload}  className='eventlogoimg1'/>
              </div>
              <div className="col-5 "><span className="evntxt-birthday ">Birthday Greetings!</span></div>
            <div className="col-7 event-date-div"><span className="event-date-birthday">August 3 at 4:00 PM</span></div>
            </div>
            
            <div className="textcenter">
              <span className="birdtday-date-text">23 December 2022</span>
            </div>
            <div className="textcenter">
              <span className="birdtday-date-text">Heartiest Birthday wish from Naidu Directory</span>
            </div>
            <div className="row margintop" >
            <div className="col-1 "></div>

              <div className="col-2  ">             
               <img src={imgupload}  className='birthday-date-img marginleftmins6'/>
               <span className="birthday-date-nme1">First Name,Last Name</span>
          </div>
          <div className="col-1 "></div>

              <div className="col-2 mr-3 ">
              <img src={imgupload}  className='birthday-date-img2 '/>
              <span className="birthday-date-nme2">First Name,Last Name</span>

              </div>

              <div className="col-2 ml-3 ">
              <img src={imgupload}  className='birthday-date-img3 '/>
              <span className="birthday-date-nme3">First Name,Last Name</span>

              </div>
              <div className="col-1 "></div>

              <div className="col-2 mr-3 marginleftmins">
              <img src={imgupload}  className='birthday-date-img'/>
              <span className="birthday-date-nme">First Name,Last Name</span>

              </div>
              <div className="col-1  "></div>


            </div>
            <div className="row margintop" >
            <div className="col-1 "></div>

              <div className="col-2  ">             
               <img src={imgupload}  className='birthday-date-img marginleftmins6'/>
               <span className="birthday-date-nme1">First Name,Last Name</span>
          </div>
          <div className="col-1 "></div>

              <div className="col-2 mr-3 ">
              <img src={imgupload}  className='birthday-date-img2 '/>
              <span className="birthday-date-nme2">First Name,Last Name</span>

              </div>

              <div className="col-2 ml-3 ">
              <img src={imgupload}  className='birthday-date-img3 '/>
              <span className="birthday-date-nme3">First Name,Last Name</span>

              </div>
              <div className="col-1 "></div>

              <div className="col-2 mr-3 marginleftmins">
              <img src={imgupload}  className='birthday-date-img'/>
              <span className="birthday-date-nme">First Name,Last Name</span>

              </div>
              <div className="col-1  "></div>


            </div>



            </div> */}

            <div className=''>
            <div className={ archiveDiv==false && spamDIv==false && activeDiv==false && getmenu.coffeeMeet?"":"hide"}>

            <div className={getmenu.coffeeMeet?"  ":"hide"}>
          <div className={getmenu.coffeeMeet?.meetStatus == "upcoming" ? "radius-div-news margintop3 " : "radius-div-news margintop3  bacolorgrya"}style={{paddingBottom:"2px"}}>
                       <div className="row mt-2">
  
  <div className="col-1">

  {getmenu.coffeeMeet?.bannerImagePath == "" || getmenu.coffeeMeet?.bannerImagePath == null ?
                        (<Avatar className="" style={{ width: "54px",marginTop:"3px", height: "52px",backgroundColor: getRandomColor(),color:"#ffffff" }}>{getmenu.coffeeMeet?.hostName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.coffeeMeet?.bannerImagePath} id='profpicdisablesave1' className=''style={{ width: "50px",marginTop:"px", height: "49px",borderRadius:"50%"}} />)
                      }
    </div>
    <div className="col-6" style={{marginTop:"-2px"}}><h6 className="card-title mb-1" style={{whiteSpace:'nowrap'}}>


<span className='coffeemeet-host-name'  key={getmenu.coffeeMeet?.id}>{getmenu.coffeeMeet?.hostName} - Host</span>

             </h6>
             <div style={{marginTop:"4px"}}>
             
             <span className="coffeemeet-host-name" style={{fontSize:"12px",color:"#797979"}}>
             Creaeted On - {getmenu.coffeeMeet?.createdDate} @{getmenu.coffeeMeet?.createdTime}
</span>
<span className="marginleft20px coffeemeet-host-name" style={{fontSize:"12px",color:"#797979"}}>
City - {getmenu.coffeeMeet?.city}
</span>
             </div>
             </div>
             <div className="col-2"></div>
             
             


</div>
<div className="row margintop3 ">
                          <div className="col-11 host-coffee-datetime-div" style={{marginLeft:"13px"}}>
                            <RiCupFill size="1.7em" color="#964B00"/>
                            <span className="coffeemeet-host-name" style={{fontSize:"12px",color:"#222227",fontWeight:"400",marginLeft:"5px"}}>Hosting Coffee Meet Up on <span style={{fontSize:"12px",color:"#222227",fontWeight:"600",marginLeft:"1px"}}>{getmenu.coffeeMeet?.date}</span> at <span style={{fontSize:"12px",color:"#222227",fontWeight:"600",marginLeft:"1px"}}>{getmenu.coffeeMeet?.venue} ,</span> starting at <span style={{fontSize:"12px",color:"#222227",fontWeight:"600",marginLeft:"1px"}}>{getmenu.coffeeMeet?.time}</span> </span>
                            {/* <span className="coffeemeet-host-name" style={{fontSize:"12px",color:"#797979"}}>{getmenu.date} </span> <span className="event-sub-detail"> At {getmenu.time} </span><span className="event-sub-detail" style={{ marginLeft: '12px',textTransform:"capitalize" }}>{getmenu.city}</span> */}
                          </div>
                          
                        </div>   
                       
                       
                        

                        
                       
                        <div className="row margintop3">
                        <div className="col-6">
                        {
                        getmenu.coffeeMeet?.cancelStatus != "Cancelled"?
                          (<div className="">
                            <span className="coffeemeet-host-name" style={{color:"#797979",fontSize:"11px",fontWeight:"500"}}>{getmenu.coffeeMeet?.attendeesCount}/{getmenu.coffeeMeet?.memberCount} Members are attending this Coffee Meet Up</span>
                          </div>)
                          :(<div><span className="coffeemeet-host-name" style={{ margin: "5px", fontSize: '13px', fontStyle: "italic", color: "#E75480" }}> This meet up has been cancelled</span></div>)
                        }
                        </div>
                        <div className="col-1"></div>
                        <div className="col-4 textright" style={{float:"right"}}>
                            <a href={`/${getmenu.coffeeMeet?.googleMapsLink}`} target='_blank'><span className="coffeemeet-host-name cpointer" style={{color:"#626262",fontSize:"12px",fontWeight:"400"}}>Check on Google Maps</span></a>
                        </div>
                        </div>
                      
                        <div className="row margintop">
                        <div className="col-1 maxwd12">
                          {getmenu.coffeeMeet?.bannerImagePath == "" || getmenu.coffeeMeet?.bannerImagePath == null ?
                        (<Avatar className="borderstatshost" style={{ width: "70px",marginTop:"3px", height: "70px",backgroundColor: getRandomColor(),color:"#ffffff" }}>{getmenu.coffeeMeet?.hostName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.coffeeMeet?.bannerImagePath} id='profpicdisablesave1' className='prof-img-right topprofsmimg eventlogoimg1 borderstatshost' />)
                      }
                      <div className="tectcenter margintop1" style={{ height: '30px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}><span className="coffeemeet-host-name" style={{color:"#797979",fontSize:"11px",fontWeight:"400",textAlign:"center",marginTop:"3px"}}>{getmenu.coffeeMeet?.hostName}</span></div>
                      <div className=""style={{ textAlign: 'center', minHeight: '20px' }}><span className="coffeemeet-host-name"style={{color:"#964B00",fontSize:"11px",fontWeight:"500"}}>Host</span></div>

                          </div>
                        {
                            getmenu.coffeeMeet?.guests?.map((gname, index) => (
                              // <div className="col-2">
                              //   <img src={gname.profilePicture} id="mycoffeeprofid" className=' ' style={{ width: "100px", height: "100px", borderRadius: "0%", borderColor: "gray" }} />
                              //   <div><span className="event-sub-detail1" style={{ fontSize: '10px', marginLeft: '2px',textTransform:"capitalize" }}>{gname.name}</span></div>
                              // </div>
                              <div className="col-1 maxwd12">
                                                             
                                         <span className={gname.status == 'Reject'?"":"hide"}><img src={gname.profilePicture} id="othercoffeeprofid" className='borderstatusspnrej' style={{ width: "75px", height: "73px", borderRadius: "50%",marginBottom: '5px' }} /></span>
                                         <span className={gname.status == 'Attend'?"":"hide"}><img src={gname.profilePicture} id="othercoffeeprofid" className='borderstatusspnatt' style={{ width: "75px", height: "73px", borderRadius: "50%",marginBottom: '5px'  }} /></span>
                                         <span className={gname.status == 'Pending'?"":"hide"}><img src={gname.profilePicture} id="othercoffeeprofid" className='borderstatusspnpen' style={{ width: "75px", height: "73px", borderRadius: "50%",marginBottom: '5px'  }} /></span>



                                {/* <span className="borderstatusspn"><img src={gname.profilePicture} id="othercoffeeprofid" className=' ' style={{ width: "100px", height: "100px", borderRadius: "50%" }} /></span> */}
                                {/* <div className="textcenter"><span className="event-sub-detail1" style={{ fontSize: '10px', marginLeft: '0px' }}>{gname.name}</span></div> */}
                                <div className="textcenter" style={{ height: '30px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>

    <span className="coffeemeet-host-name" style={{ fontSize: '12px', maxWidth: '100px', textAlign: 'center', whiteSpace: 'normal', lineHeight: '1.2', overflow: 'hidden',fontWeight:'400',color:'#797979' }}>{gname.name}</span>
    </div>
    <div className="textcenter" style={{ textAlign: 'center', minHeight: '20px' }}>
    <span className={gname.status === 'Reject' ? "coffeemeet-host-name" : "hide"} style={{ fontSize: '12px', color: "#E75480", whiteSpace: 'nowrap',marginLeft:"-1px" }}>{gname.status}</span>
    <span className={gname.status === 'Attend' ? "coffeemeet-host-name" : "hide"} style={{ fontSize: '12px', color: "#4CAF50", whiteSpace: 'nowrap',marginLeft:"-1px" }}>{gname.status}</span>
    <span className={gname.status === 'Pending' ? "coffeemeet-host-name" : "hide"} style={{ fontSize: '12px', color: "#FFC107", whiteSpace: 'nowrap',marginLeft:"-1px" }}>{gname.status}</span>
  </div>
                                  {/* <span className={gname.status == 'Reject'?"event-sub-detail":"hide"} style={{ fontSize: '10px', marginLeft: '0px',color:"#E75480" }}>{gname.status}</span>
                                  <span className={gname.status == 'Attend'?"event-sub-detail":"hide"} style={{ fontSize: '10px', marginLeft: '0px',color:"#4CAF50" }}>{gname.status}</span>
                                  <span className={gname.status == 'Pending'?"event-sub-detail":"hide"} style={{ fontSize: '10px', marginLeft: '0px',color:"#FFC107" }}>{gname.status}</span> */}

                              </div>
                            ))
                          }
                        </div>
                        
                        <div className="row margintop3"></div>
                      </div>
                      </div>

</div>
<div className={ archiveDiv && getmenu.coffeeMeet?.activeStatus=='Archived'?"":"hide"}>
<div className={getmenu.coffeeMeet?" ":"hide"}>
          <div className={getmenu.coffeeMeet?.meetStatus == "upcoming" ? "radius-div-news margintop3 " : "radius-div-news margintop3  bacolorgrya"}style={{paddingBottom:"2px"}}>
                       <div className="row mt-2">
  
  <div className="col-1">

  {getmenu.coffeeMeet?.bannerImagePath == "" || getmenu.coffeeMeet?.bannerImagePath == null ?
                        (<Avatar className="" style={{ width: "54px",marginTop:"3px", height: "52px",backgroundColor: getRandomColor(),color:"#ffffff" }}>{getmenu.coffeeMeet?.hostName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.coffeeMeet?.bannerImagePath} id='profpicdisablesave1' className=''style={{ width: "50px",marginTop:"px", height: "49px",borderRadius:"50%"}} />)
                      }
    </div>
    <div className="col-6" style={{marginTop:"-2px"}}><h6 className="card-title mb-1" style={{whiteSpace:'nowrap'}}>


<span className='coffeemeet-host-name'  key={getmenu.coffeeMeet?.id}>{getmenu.coffeeMeet?.hostName} - Host</span>

             </h6>
             <div style={{marginTop:"4px"}}>
             
             <span className="coffeemeet-host-name" style={{fontSize:"12px",color:"#797979"}}>
             Creaeted On - {getmenu.coffeeMeet?.createdDate} @{getmenu.coffeeMeet?.createdTime}
</span>
<span className="marginleft20px coffeemeet-host-name" style={{fontSize:"12px",color:"#797979"}}>
City - {getmenu.coffeeMeet?.city}
</span>
             </div>
             </div>
             <div className="col-2"></div>
             
             


</div>
<div className="row margintop3 ">
                          <div className="col-11 host-coffee-datetime-div" style={{marginLeft:"13px"}}>
                            <RiCupFill size="1.7em" color="#964B00"/>
                            <span className="coffeemeet-host-name" style={{fontSize:"12px",color:"#222227",fontWeight:"400",marginLeft:"5px"}}>Hosting Coffee Meet Up on <span style={{fontSize:"12px",color:"#222227",fontWeight:"600",marginLeft:"1px"}}>{getmenu.coffeeMeet?.date}</span> at <span style={{fontSize:"12px",color:"#222227",fontWeight:"600",marginLeft:"1px"}}>{getmenu.coffeeMeet?.venue} ,</span> starting at <span style={{fontSize:"12px",color:"#222227",fontWeight:"600",marginLeft:"1px"}}>{getmenu.coffeeMeet?.time}</span> </span>
                            {/* <span className="coffeemeet-host-name" style={{fontSize:"12px",color:"#797979"}}>{getmenu.date} </span> <span className="event-sub-detail"> At {getmenu.time} </span><span className="event-sub-detail" style={{ marginLeft: '12px',textTransform:"capitalize" }}>{getmenu.city}</span> */}
                          </div>
                          
                        </div>   
                       
                       
                        

                        
                       
                        <div className="row margintop3">
                        <div className="col-6">
                        {
                        getmenu.coffeeMeet?.cancelStatus != "Cancelled"?
                          (<div className="">
                            <span className="coffeemeet-host-name" style={{color:"#797979",fontSize:"11px",fontWeight:"500"}}>{getmenu.coffeeMeet?.attendeesCount}/{getmenu.coffeeMeet?.memberCount} Members are attending this Coffee Meet Up</span>
                          </div>)
                          :(<div><span className="coffeemeet-host-name" style={{ margin: "5px", fontSize: '13px', fontStyle: "italic", color: "#E75480" }}> This meet up has been cancelled</span></div>)
                        }
                        </div>
                        <div className="col-1"></div>
                        <div className="col-4 textright" style={{float:"right"}}>
                            <a href={`/${getmenu.coffeeMeet?.googleMapsLink}`} target='_blank'><span className="coffeemeet-host-name cpointer" style={{color:"#626262",fontSize:"12px",fontWeight:"400"}}>Check on Google Maps</span></a>
                        </div>
                        </div>
                      
                        <div className="row margintop">
                        <div className="col-1 maxwd12">
                          {getmenu.coffeeMeet?.bannerImagePath == "" || getmenu.coffeeMeet?.bannerImagePath == null ?
                        (<Avatar className="borderstatshost" style={{ width: "70px",marginTop:"3px", height: "70px",backgroundColor: getRandomColor(),color:"#ffffff" }}>{getmenu.coffeeMeet?.hostName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.coffeeMeet?.bannerImagePath} id='profpicdisablesave1' className='prof-img-right topprofsmimg eventlogoimg1 borderstatshost' />)
                      }
                      <div className="tectcenter margintop1" style={{ height: '30px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}><span className="coffeemeet-host-name" style={{color:"#797979",fontSize:"11px",fontWeight:"400",textAlign:"center",marginTop:"3px"}}>{getmenu.coffeeMeet?.hostName}</span></div>
                      <div className=""style={{ textAlign: 'center', minHeight: '20px' }}><span className="coffeemeet-host-name"style={{color:"#964B00",fontSize:"11px",fontWeight:"500"}}>Host</span></div>

                          </div>
                        {
                            getmenu.guests?.map((gname, index) => (
                              // <div className="col-2">
                              //   <img src={gname.profilePicture} id="mycoffeeprofid" className=' ' style={{ width: "100px", height: "100px", borderRadius: "0%", borderColor: "gray" }} />
                              //   <div><span className="event-sub-detail1" style={{ fontSize: '10px', marginLeft: '2px',textTransform:"capitalize" }}>{gname.name}</span></div>
                              // </div>
                              <div className="col-1 maxwd12">
                                                             
                                         <span className={gname.status == 'Reject'?"":"hide"}><img src={gname.profilePicture} id="othercoffeeprofid" className='borderstatusspnrej' style={{ width: "75px", height: "73px", borderRadius: "50%",marginBottom: '5px' }} /></span>
                                         <span className={gname.status == 'Attend'?"":"hide"}><img src={gname.profilePicture} id="othercoffeeprofid" className='borderstatusspnatt' style={{ width: "75px", height: "73px", borderRadius: "50%",marginBottom: '5px'  }} /></span>
                                         <span className={gname.status == 'Pending'?"":"hide"}><img src={gname.profilePicture} id="othercoffeeprofid" className='borderstatusspnpen' style={{ width: "75px", height: "73px", borderRadius: "50%",marginBottom: '5px'  }} /></span>



                                {/* <span className="borderstatusspn"><img src={gname.profilePicture} id="othercoffeeprofid" className=' ' style={{ width: "100px", height: "100px", borderRadius: "50%" }} /></span> */}
                                {/* <div className="textcenter"><span className="event-sub-detail1" style={{ fontSize: '10px', marginLeft: '0px' }}>{gname.name}</span></div> */}
                                <div className="textcenter" style={{ height: '30px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>

    <span className="coffeemeet-host-name" style={{ fontSize: '12px', maxWidth: '100px', textAlign: 'center', whiteSpace: 'normal', lineHeight: '1.2', overflow: 'hidden',fontWeight:'400',color:'#797979' }}>{gname.name}</span>
    </div>
    <div className="textcenter" style={{ textAlign: 'center', minHeight: '20px' }}>
    <span className={gname.status === 'Reject' ? "coffeemeet-host-name" : "hide"} style={{ fontSize: '12px', color: "#E75480", whiteSpace: 'nowrap',marginLeft:"-1px" }}>{gname.status}</span>
    <span className={gname.status === 'Attend' ? "coffeemeet-host-name" : "hide"} style={{ fontSize: '12px', color: "#4CAF50", whiteSpace: 'nowrap',marginLeft:"-1px" }}>{gname.status}</span>
    <span className={gname.status === 'Pending' ? "coffeemeet-host-name" : "hide"} style={{ fontSize: '12px', color: "#FFC107", whiteSpace: 'nowrap',marginLeft:"-1px" }}>{gname.status}</span>
  </div>
                                  {/* <span className={gname.status == 'Reject'?"event-sub-detail":"hide"} style={{ fontSize: '10px', marginLeft: '0px',color:"#E75480" }}>{gname.status}</span>
                                  <span className={gname.status == 'Attend'?"event-sub-detail":"hide"} style={{ fontSize: '10px', marginLeft: '0px',color:"#4CAF50" }}>{gname.status}</span>
                                  <span className={gname.status == 'Pending'?"event-sub-detail":"hide"} style={{ fontSize: '10px', marginLeft: '0px',color:"#FFC107" }}>{gname.status}</span> */}

                              </div>
                            ))
                          }
                        </div>
                        
                        <div className="row margintop3"></div>
                      </div>
                      </div>
</div>
<div className={ activeDiv && getmenu.coffeeMeet?.activeStatus=='Active'?"":"hide"}>

          <div className={getmenu.coffeeMeet?" ":"hide"}>
          <div className={getmenu.coffeeMeet?.meetStatus == "upcoming" ? "radius-div-news margintop3 " : "radius-div-news margintop3  bacolorgrya"}style={{paddingBottom:"2px"}}>
                       <div className="row mt-2">
  
  <div className="col-1">

  {getmenu.coffeeMeet?.bannerImagePath == "" || getmenu.coffeeMeet?.bannerImagePath == null ?
                        (<Avatar className="" style={{ width: "54px",marginTop:"3px", height: "52px",backgroundColor: getRandomColor(),color:"#ffffff" }}>{getmenu.coffeeMeet?.hostName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.coffeeMeet?.bannerImagePath} id='profpicdisablesave1' className=''style={{ width: "50px",marginTop:"px", height: "49px",borderRadius:"50%"}} />)
                      }
    </div>
    <div className="col-6" style={{marginTop:"-2px"}}><h6 className="card-title mb-1" style={{whiteSpace:'nowrap'}}>


<span className='coffeemeet-host-name'  key={getmenu.coffeeMeet?.id}>{getmenu.coffeeMeet?.hostName} - Host</span>

             </h6>
             <div style={{marginTop:"4px"}}>
             
             <span className="coffeemeet-host-name" style={{fontSize:"12px",color:"#797979"}}>
             Creaeted On - {getmenu.coffeeMeet?.createdDate} @{getmenu.coffeeMeet?.createdTime}
</span>
<span className="marginleft20px coffeemeet-host-name" style={{fontSize:"12px",color:"#797979"}}>
City - {getmenu.coffeeMeet?.city}
</span>
             </div>
             </div>
             <div className="col-2"></div>
             
             


</div>
<div className="row margintop3 ">
                          <div className="col-11 host-coffee-datetime-div" style={{marginLeft:"13px"}}>
                            <RiCupFill size="1.7em" color="#964B00"/>
                            <span className="coffeemeet-host-name" style={{fontSize:"12px",color:"#222227",fontWeight:"400",marginLeft:"5px"}}>Hosting Coffee Meet Up on <span style={{fontSize:"12px",color:"#222227",fontWeight:"600",marginLeft:"1px"}}>{getmenu.coffeeMeet?.date}</span> at <span style={{fontSize:"12px",color:"#222227",fontWeight:"600",marginLeft:"1px"}}>{getmenu.coffeeMeet?.venue} ,</span> starting at <span style={{fontSize:"12px",color:"#222227",fontWeight:"600",marginLeft:"1px"}}>{getmenu.coffeeMeet?.time}</span> </span>
                            {/* <span className="coffeemeet-host-name" style={{fontSize:"12px",color:"#797979"}}>{getmenu.date} </span> <span className="event-sub-detail"> At {getmenu.time} </span><span className="event-sub-detail" style={{ marginLeft: '12px',textTransform:"capitalize" }}>{getmenu.city}</span> */}
                          </div>
                          
                        </div>   
                       
                       
                        

                        
                       
                        <div className="row margintop3">
                        <div className="col-6">
                        {
                        getmenu.coffeeMeet?.cancelStatus != "Cancelled"?
                          (<div className="">
                            <span className="coffeemeet-host-name" style={{color:"#797979",fontSize:"11px",fontWeight:"500"}}>{getmenu.coffeeMeet?.attendeesCount}/{getmenu.coffeeMeet?.memberCount} Members are attending this Coffee Meet Up</span>
                          </div>)
                          :(<div><span className="coffeemeet-host-name" style={{ margin: "5px", fontSize: '13px', fontStyle: "italic", color: "#E75480" }}> This meet up has been cancelled</span></div>)
                        }
                        </div>
                        <div className="col-1"></div>
                        <div className="col-4 textright" style={{float:"right"}}>
                            <a href={`/${getmenu.coffeeMeet?.googleMapsLink}`} target='_blank'><span className="coffeemeet-host-name cpointer" style={{color:"#626262",fontSize:"12px",fontWeight:"400"}}>Check on Google Maps</span></a>
                        </div>
                        </div>
                      
                        <div className="row margintop">
                        <div className="col-1 maxwd12">
                          {getmenu.coffeeMeet?.bannerImagePath == "" || getmenu.coffeeMeet?.bannerImagePath == null ?
                        (<Avatar className="borderstatshost" style={{ width: "70px",marginTop:"3px", height: "70px",backgroundColor: getRandomColor(),color:"#ffffff" }}>{getmenu.coffeeMeet?.hostName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.coffeeMeet?.bannerImagePath} id='profpicdisablesave1' className='prof-img-right topprofsmimg eventlogoimg1 borderstatshost' />)
                      }
                      <div className="tectcenter margintop1" style={{ height: '30px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}><span className="coffeemeet-host-name" style={{color:"#797979",fontSize:"11px",fontWeight:"400",textAlign:"center",marginTop:"3px"}}>{getmenu.coffeeMeet?.hostName}</span></div>
                      <div className=""style={{ textAlign: 'center', minHeight: '20px' }}><span className="coffeemeet-host-name"style={{color:"#964B00",fontSize:"11px",fontWeight:"500"}}>Host</span></div>

                          </div>
                        {
                            getmenu.guests?.map((gname, index) => (
                              // <div className="col-2">
                              //   <img src={gname.profilePicture} id="mycoffeeprofid" className=' ' style={{ width: "100px", height: "100px", borderRadius: "0%", borderColor: "gray" }} />
                              //   <div><span className="event-sub-detail1" style={{ fontSize: '10px', marginLeft: '2px',textTransform:"capitalize" }}>{gname.name}</span></div>
                              // </div>
                              <div className="col-1 maxwd12">
                                                             
                                         <span className={gname.status == 'Reject'?"":"hide"}><img src={gname.profilePicture} id="othercoffeeprofid" className='borderstatusspnrej' style={{ width: "75px", height: "73px", borderRadius: "50%",marginBottom: '5px' }} /></span>
                                         <span className={gname.status == 'Attend'?"":"hide"}><img src={gname.profilePicture} id="othercoffeeprofid" className='borderstatusspnatt' style={{ width: "75px", height: "73px", borderRadius: "50%",marginBottom: '5px'  }} /></span>
                                         <span className={gname.status == 'Pending'?"":"hide"}><img src={gname.profilePicture} id="othercoffeeprofid" className='borderstatusspnpen' style={{ width: "75px", height: "73px", borderRadius: "50%",marginBottom: '5px'  }} /></span>



                                {/* <span className="borderstatusspn"><img src={gname.profilePicture} id="othercoffeeprofid" className=' ' style={{ width: "100px", height: "100px", borderRadius: "50%" }} /></span> */}
                                {/* <div className="textcenter"><span className="event-sub-detail1" style={{ fontSize: '10px', marginLeft: '0px' }}>{gname.name}</span></div> */}
                                <div className="textcenter" style={{ height: '30px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>

    <span className="coffeemeet-host-name" style={{ fontSize: '12px', maxWidth: '100px', textAlign: 'center', whiteSpace: 'normal', lineHeight: '1.2', overflow: 'hidden',fontWeight:'400',color:'#797979' }}>{gname.name}</span>
    </div>
    <div className="textcenter" style={{ textAlign: 'center', minHeight: '20px' }}>
    <span className={gname.status === 'Reject' ? "coffeemeet-host-name" : "hide"} style={{ fontSize: '12px', color: "#E75480", whiteSpace: 'nowrap',marginLeft:"-1px" }}>{gname.status}</span>
    <span className={gname.status === 'Attend' ? "coffeemeet-host-name" : "hide"} style={{ fontSize: '12px', color: "#4CAF50", whiteSpace: 'nowrap',marginLeft:"-1px" }}>{gname.status}</span>
    <span className={gname.status === 'Pending' ? "coffeemeet-host-name" : "hide"} style={{ fontSize: '12px', color: "#FFC107", whiteSpace: 'nowrap',marginLeft:"-1px" }}>{gname.status}</span>
  </div>
                                  {/* <span className={gname.status == 'Reject'?"event-sub-detail":"hide"} style={{ fontSize: '10px', marginLeft: '0px',color:"#E75480" }}>{gname.status}</span>
                                  <span className={gname.status == 'Attend'?"event-sub-detail":"hide"} style={{ fontSize: '10px', marginLeft: '0px',color:"#4CAF50" }}>{gname.status}</span>
                                  <span className={gname.status == 'Pending'?"event-sub-detail":"hide"} style={{ fontSize: '10px', marginLeft: '0px',color:"#FFC107" }}>{gname.status}</span> */}

                              </div>
                            ))
                          }
                        </div>
                        
                        <div className="row margintop3"></div>
                      </div>
                      </div>
</div>

<div className={ activeDiv && getmenu.job?.activeStatus=='Active'?"":"hide"}>p
<div className={getmenu.job ? "radius-div-news margintop " : "hide"}>
  <div className="" style={{paddingTop:"18px",paddingBottom:"16px"}}>
  <div className="row">
    <div className="col-1">
      {/* <img src={getmenu.job?.profilePic} id='profpicdisablesave5' className='eventlogoimg' /> */}
      {getmenu.job?.profilePic == "" || getmenu.job?.profilePic == null ?
                        (<Avatar className="avtarimgSm" style={{ width: "44px", height: "42px",backgroundColor: getRandomColor(),color:"#ffffff" }}>{getmenu.job?.postedByName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.job?.profilePic} id='profpicdisablesave5' className=''style={{ width: "50px",marginTop:"px", height: "49px",borderRadius:"50%"}} />)
                      }
      </div>
    <div className="col-11">
      <div className=""><span className="evntxt ">Open for Opportunities</span>
      <span className=" event-type-text marginleft2" key={getmenu.job?.id}>{getmenu.job?.jobOpeningFor}</span>
      <span className="marginleft2 event-location">{getmenu.job?.currentLocation}</span>
      
       </div>
       <div className="">
       <span className="event-date">{getmenu.job?.createdDate} @{getmenu.job?.createdTime}</span>
       <span className="event-publisher ">Published by {getmenu.job?.postedByName}</span>
       </div>
      
    </div>

  </div>
  <div className="row mt-1">
    <div className="col-6">
      <div className="row mt-2">
      <div className="col-5"><span className="post-name-btn textleft" style={{marginLeft:'0px',color:"#555555"}}>Community Member</span></div>
      <div className="col-7"><span className=" whitenowrap post-host-Name textleft" style={{fontSize:"12px", marginTop:"px"}}>{getmenu.job?.postedByName} </span></div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="post-name-btn textleft" style={{marginLeft:'0px',color:"#555555"}}>Job Title</span></div>
      <div className="col-7"><span className=" whitenowrap post-host-Name textleft" style={{fontSize:"12px", marginTop:"px"}}>{getmenu.job?.jobOpeningFor}</span></div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="post-name-btn textleft" style={{marginLeft:'0px',color:"#555555"}}>Total Experience</span></div>
      <div className="col-7"><span className=" whitenowrap post-host-Name textleft" style={{fontSize:"12px", marginTop:"px"}}>{getmenu.job?.experience}</span></div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="post-name-btn textleft" style={{marginLeft:'0px',color:"#555555"}}>Current Location</span></div>
      <div className="col-7"><span className=" whitenowrap post-host-Name textleft" style={{fontSize:"12px", marginTop:"px"}}>{getmenu.job?.currentLocation}</span></div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="post-name-btn textleft" style={{marginLeft:'0px',color:"#555555"}}>Home Town</span></div>
      <div className="col-7"><span className=" whitenowrap post-host-Name textleft" style={{fontSize:"12px", marginTop:"px"}}>{getmenu.job?.homeLocation}</span></div>
</div>
    </div>
    
    <div className="col-6">
    <div className="row mt-2">
      <div className="col-5"><span className="post-name-btn textleft">Resume</span></div>
      <div className="col-7">{
        getmenu.job?.resumePath=="" || getmenu.job?.resumePath==null?
        (<span className='post-name-btn textleft' style={{color:"#8B0000",marginLeft:"0px"}}>Not Uploaded</span>):(  <a href={`//${getmenu.job?.resumePath}`} target='_blank'><AiOutlineFilePdf size='1.9em' color='#8B0000'/></a>
        )
      }
        </div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="post-name-btn textleft" style={{marginLeft:'0px',color:"#555555"}}>Available for job in</span></div>
      <div className="col-7"><span className=" whitenowrap post-host-Name textleft" style={{fontSize:"12px", marginTop:"px"}}>{getmenu.job?.availableIn}</span></div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="post-name-btn textleft" style={{marginLeft:'0px',color:"#555555"}}>Email Id</span></div>
      <div className="col-7"><span className=" whitenowrap post-host-Name textleft" style={{fontSize:"12px", marginTop:"px"}}>{getmenu.job?.emailId}</span></div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="post-name-btn textleft" style={{marginLeft:'0px',color:"#555555"}}>Mobile No.</span></div>
      <div className="col-7"><span className=" whitenowrap post-host-Name textleft" style={{fontSize:"12px", marginTop:"px"}}>{getmenu.job?.mobileNumber}</span></div>
</div>

    </div>

  </div>

  <div className="row margintop3">
                    <div  className=""><div className="borderBottombtn" style={{marginLeft:"0px"}}></div></div>
                  </div>
  
          <div className="row margintop3">
                                      <div className="col-lg-2 maxwd12 col-sm-4 col-md-6">
                                        {/* <BiLike className={status==='like'? 'activelike' : ''} onClick={handleClickLike}/><span className="likespn marginleft2"> {likes}</span> */}
                                        {getmenu.job?.likeStatus == true ? <AiFillLike color="#626262" size="1.3em" onClick={() => addLikes(getmenu.job?.id)} style={{ cursor: "pointer" }} /> : <BiLike onClick={() => addLikes(getmenu.job?.id)} size="1.3em" style={{ cursor: "pointer" }} color="#D9D9D9" />}<span className="post-name-btn " style={{color:"#626262",fontSize:'14px',marginLeft:"4px",cursor:"pointer"}} onClick={() => loadLikesInfo(getmenu.job?.id)}> {getmenu.job?.likeCount} </span>
                                      </div>
                                      <div className="col-lg-2 maxwd12 col-md-4 col-sm-4" onClick={() => commentInfo(getmenu.job?.id)} style={{ cursor: "pointer" }}><FaRegComments size="1.3em" color="#626262" /><span className="post-name-btn " style={{color:"#626262",fontSize:'14px',marginLeft:"6px"}}>{getmenu.job?.commentCount} </span></div>
                                      <div className="col-lg-7"></div>

                                      
                                      <div className='col-2 displyinline' style={{float:"right"}}>
                                      {getmenu.job?.userSpamStatus == false ?
                                        (
                                          <div className="col-lg-2 col-sm-5 col-md-4 " onClick={() => addSpam(getmenu.job?.id)} style={{ cursor: "pointer" }}><RiSpam2Line color="#D9D9D9" size="1.3em"/> </div>
                  
                                        ) : (
                                          <div className="col-1 " onClick={() => addSpam(getmenu.job?.id)} style={{ cursor: "pointer" }}><RiSpam2Line color="#626262" size="1.3em"/> </div>
                  
                                        )
                                      }
{getmenu.job?.spamCount >=3 ?
              (
              <span className='viewsub-activ-spn'style={{float:'right',whiteSpace:"nowrap",backgroundColor:"rgba(211, 15, 69, 1)",color:"#F2F2F2"}} >Spam</span>
              ):
              (
              <span className='viewsub-activ-spn'style={{float:'right',whiteSpace:"nowrap"}} >Active</span>
              )
              }
              
                                      </div>
                  
                                    </div>
                                    <div className='row margintop1'>
          <div className='col-4'></div>
          <div className="col-lg-2 maxwd22 col-sm-4 col-md-6 report-spm-div-delete"  style={{ cursor: "pointer" }}><MdOutlineDelete size="1.3em" color="#FF8427" style={{marginLeft:"-7px"}}/><span className="marginleft2 report-spm" style={{color:"#FF8427"}}>Delete</span> </div>
          <div className='col-1 maxwd33'></div>
          <div className="col-lg-2 maxwd22 col-sm-4 col-md-6 report-spm-div" onClick={() => loadPost1(getmenu.job?.id, getmenu.job?.type)} style={{ cursor: "pointer" }}><RiEdit2Line size="1.3em" color="#FFFFFF" /><span className="marginleft2 report-spm">Edit</span> </div>
          <div className='col-3'></div>

          </div>
          <Card className={comment && getmenu.job?.id == cmntEventId ? "margintop3":"hide row"}>
          <div className="">
          <div className="" onClick={() => Commentpoupopen(false)} style={{float:"right",marginRight:"10px",cursor:"pointer",fontSize:'13px'}}>X</div>

            <Card className="overflow-hidden" style={{height:"auto",border:"none"}}>
              {/* <Card className="mb-lg-0" style={{height:"auto",border:"none"}}> */}
                
                <Card.Body>
                  <div className="mt-2">
                    <FormGroup className="form-group " >
                      <Form.Control
                        type="text"
                        className="form-control borderadius25px "
                        id="name1"
                        name="txtCmnt"
                        placeholder="Add a Comment"
                        style={{fontSize:"13px",borderRadius:"25px",textTransform:"capitalize"}}
                        onChange={handleChange}
                        value={cmntValue.txtCmnt}
                      />
                    <label className={commentValiderr?"event-sub-detail1":"hide"} style={{color:"red"}}>Please Add Comment</label>

                    </FormGroup>
                    <button  className="attendbtn" style={{width:"90px",height:"28px"}} onClick={()=>saveComent(getmenu.job?.id)}>
                      Send Reply
                    </button>
                  </div>
                </Card.Body>
              {/* </Card> */}
            </Card>
          </div>
          {
  commentData.map((commentmenu, index) => {
    // Extract first letter of first name and first letter of last name
    const nameParts = commentmenu.viewerName ? commentmenu.viewerName.split(" ") : [];
    const initials =
      nameParts.length > 1
        ? `${nameParts[0][0].toUpperCase()}${nameParts[nameParts.length - 1][0].toUpperCase()}`
        : nameParts[0]
        ? nameParts[0][0].toUpperCase()
        : "";

    return (
      <div key={index}>
        <Card className="" style={{ height: "auto", border: "none" }}>
          <Card.Body>
            <div className="d-sm-flex mt-0 p-3 sub-review-section border br-bl-0 br-br-0">
              <div className="media-body">
                {/* Flex container to keep avatar and name in one line */}
                <h6
                  className="mt-0 mb-1 font-12 font-weight-semibold"
                  style={{
                    fontSize: "13px",
                    display:"inline-flex",
                  }}
                >
                  {/* Profile Picture or Avatar */}
                  {commentmenu.profilePicture ? (
                    <img
                      src={commentmenu.profilePicture}
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        marginRight:"6px",
                        marginTop:"-6px"
                      }}
                    />
                  ) : (
                    <Avatar
                      style={{
                        width: "30px",
                        height: "30px",
                        fontSize: "12px",
                        backgroundColor: bgColor,
                        color: "#ffffff",
                        marginTop:"-6px",
                        marginRight:"6px"
                      }}
                    >
                      {initials}
                    </Avatar>
                  )}

                  {/* Viewer Name */}
                  {commentmenu.viewerName}
                </h6>

                {/* Comment Text */}
                <p className="font-11 mb-2 mt-2" style={{ fontSize: "12px" }}>
                  {commentmenu.comment}
                </p>

                {/* Delete Badge */}
                <Badge
                  bg=""
                  className={commentmenu.viewerId == uerAuthId ? "bg-danger" : "hide"}
                  onClick={() => deleteComment(commentmenu.id)}
                  style={{ cursor: "pointer" }}
                >
                  Delete
                </Badge>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  })
}

    </Card>
</div>
</div>

</div>
<div className={spamDIv && getmenu.job?.userSpamStatus==true?"":"hide"}>

<div className={getmenu.job ? "radius-div-news margintop3" : "hide"}>
            <div className="row margintop1">
                    <div className="col-lg-1 col-sm-12 col-md-12">
                        {getmenu.job?.profilePic == "" || getmenu.job?.profilePic == null ?
                        (<Avatar className="avtarimgsm" style={{ width: "50px",marginTop:"px", height: "49px",backgroundColor: bgColor,color:"#ffffff" }}>{getmenu.job?.postedByName.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.job?.profilePic} id='profpicdisablesave5' className=''style={{ width: "50px",marginTop:"px", height: "49px",borderRadius:"50%"}} />)
                      }
                    </div>
                    <div className="col-lg-5 col-sm-12 col-md-12 textleft" style={{marginTop:"4px"}}>
                      <span className="post-host-Name ">{getmenu.job?.postedByName}</span>
                      <div className=""style={{marginTop:"-3px"}}><span className="event-date">{getmenu.job?.createdDate} @{getmenu.job?.createdTime}</span></div>
                      </div>
                    <div className="col-lg-2 col-sm-12" style={{ whiteSpace: "nowrap",marginTop:"px" }}>
                      </div>
                    <div className="col-lg-4 col-sm-12 col-md-12 textright"style={{marginTop:"4px"}}><span className="post-host-Name"></span>
                   <div className=""style={{marginTop:"-3px"}}><span className="event-date ">Published by {getmenu.job?.postedByName}</span></div> 
                   
                    </div>
                  </div>
                  <div className="row margintop1">
                    <div className="margintop1">
                   {/* <div className=""style={{marginTop:"-3px"}}><span className="event-date ">Published by {getmenu.blog?.eventType}</span></div>  */}
                   <span className="post-host-Name" style={{color:"#626262",marginleft:'7px'}}>Open for Opportunities-</span>
                  </div>
                  </div>
                  <div className="row margintop3">

                    <div className="row mt-1">
    <div className="col-6">
      <div className="row mt-2">
      <div className="col-5"><span className="post-name-btn textleft" style={{marginLeft:'0px',color:"#555555"}}>Community Member</span></div>
      <div className="col-7"><span className=" whitenowrap post-host-Name textleft" style={{fontSize:"12px", marginTop:"px"}}>{getmenu.job?.postedByName} </span></div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="post-name-btn textleft" style={{marginLeft:'0px',color:"#555555"}}>Job Title</span></div>
      <div className="col-7"><span className=" whitenowrap post-host-Name textleft" style={{fontSize:"12px", marginTop:"px"}}>{getmenu.job?.jobOpeningFor}</span></div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="post-name-btn textleft" style={{marginLeft:'0px',color:"#555555"}}>Total Experience</span></div>
      <div className="col-7"><span className=" whitenowrap post-host-Name textleft" style={{fontSize:"12px", marginTop:"px"}}>{getmenu.job?.experience}</span></div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="post-name-btn textleft" style={{marginLeft:'0px',color:"#555555"}}>Current Location</span></div>
      <div className="col-7"><span className=" whitenowrap post-host-Name textleft" style={{fontSize:"12px", marginTop:"px"}}>{getmenu.job?.currentLocation}</span></div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="post-name-btn textleft" style={{marginLeft:'0px',color:"#555555"}}>Home Town</span></div>
      <div className="col-7"><span className=" whitenowrap post-host-Name textleft" style={{fontSize:"12px", marginTop:"px"}}>{getmenu.job?.homeLocation}</span></div>
</div>
    </div>
    
    <div className="col-6">
    <div className="row mt-2">
      <div className="col-5"><span className="post-name-btn textleft">Resume</span></div>
      <div className="col-7">{
        getmenu.job?.resumePath=="" || getmenu.job?.resumePath==null?
        (<span className='post-name-btn textleft' style={{color:"#8B0000",marginLeft:"0px"}}>Not Uploaded</span>):(  <a href={`//${getmenu.job?.resumePath}`} target='_blank'><AiOutlineFilePdf size='1.9em' color='#8B0000'/></a>
        )
      }
        </div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="post-name-btn textleft" style={{marginLeft:'0px',color:"#555555"}}>Available for job in</span></div>
      <div className="col-7"><span className=" whitenowrap post-host-Name textleft" style={{fontSize:"12px", marginTop:"px"}}>{getmenu.job?.availableIn}</span></div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="post-name-btn textleft" style={{marginLeft:'0px',color:"#555555"}}>Email Id</span></div>
      <div className="col-7"><span className=" whitenowrap post-host-Name textleft" style={{fontSize:"12px", marginTop:"px"}}>{getmenu.job?.emailId}</span></div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="post-name-btn textleft" style={{marginLeft:'0px',color:"#555555"}}>Mobile No.</span></div>
      <div className="col-7"><span className=" whitenowrap post-host-Name textleft" style={{fontSize:"12px", marginTop:"px"}}>{getmenu.job?.mobileNumber}</span></div>
</div>

    </div>

  </div>

  


                  </div>
                  <div className="row margintop3">
                    <div  className=""><div className="borderBottombtn" style={{marginLeft:"0px"}}></div></div>
                  </div>
                  <div className="row margintop3">
                                      <div className="col-lg-2 maxwd12 col-sm-4 col-md-6">
                                        {/* <BiLike className={status==='like'? 'activelike' : ''} onClick={handleClickLike}/><span className="likespn marginleft2"> {likes}</span> */}
                                        {getmenu.job?.likeStatus == true ? <AiFillLike color="#626262" size="1.3em" onClick={() => addLikes(getmenu.job?.id)} style={{ cursor: "pointer" }} /> : <BiLike onClick={() => addLikes(getmenu.job?.id)} size="1.3em" style={{ cursor: "pointer" }} color="#D9D9D9" />}<span className="post-name-btn " style={{color:"#626262",fontSize:'14px',marginLeft:"4px",cursor:"pointer"}} onClick={() => loadLikesInfo(getmenu.job?.id)}> {getmenu.job?.likeCount} </span>
                                      </div>
                                      <div className="col-lg-2 maxwd12 col-md-4 col-sm-4" onClick={() => commentInfo(getmenu.job?.id)} style={{ cursor: "pointer" }}><FaRegComments size="1.3em" color="#626262" /><span className="post-name-btn " style={{color:"#626262",fontSize:'14px',marginLeft:"6px"}}>{getmenu.job?.commentCount} </span></div>
                                      <div className="col-lg-7"></div>

                                      
                                      <div className='col-2 displyinline' style={{float:"right"}}>
                                      {getmenu.job?.userSpamStatus == false ?
                                        (
                                          <div className="col-lg-2 col-sm-5 col-md-4 " onClick={() => addSpam(getmenu.job?.id)} style={{ cursor: "pointer" }}><RiSpam2Line color="#D9D9D9" size="1.3em"/> </div>
                  
                                        ) : (
                                          <div className="col-1 " onClick={() => addSpam(getmenu.job?.id)} style={{ cursor: "pointer" }}><RiSpam2Line color="#626262" size="1.3em"/> </div>
                  
                                        )
                                      }
{getmenu.job?.spamCount >=3 ?
        (
        <span className='viewsub-activ-spn'style={{float:'right',whiteSpace:"nowrap",backgroundColor:"rgba(211, 15, 69, 1)",color:"#F2F2F2"}} >Spam</span>
        ):
        (
        <span className='viewsub-activ-spn'style={{float:'right',whiteSpace:"nowrap",backgroundColor:"rgba(211, 15, 69, 1)",color:"#F2F2F2"}} >Spam</span>
        )
        }
              
                                      </div>
                  
                                    </div>
                                    <div className='row margintop1'>
          <div className='col-4'></div>
          <div className="col-lg-2 maxwd22 col-sm-4 col-md-6 report-spm-div-delete"  style={{ cursor: "pointer" }}><MdOutlineDelete size="1.3em" color="#FF8427" style={{marginLeft:"-7px"}}/><span className="marginleft2 report-spm" style={{color:"#FF8427"}}>Delete</span> </div>
          <div className='col-1 maxwd33'></div>
          <div className="col-lg-2 maxwd22 col-sm-4 col-md-6 report-spm-div" onClick={() => loadPost1(getmenu.job?.id, getmenu.job?.type)} style={{ cursor: "pointer" }}><RiEdit2Line size="1.3em" color="#FFFFFF" /><span className="marginleft2 report-spm">Edit</span> </div>
          <div className='col-3'></div>

          </div>
                                    <Card className={comment && getmenu.job?.id == cmntEventId ? "margintop3" : "hide row"} >
                                      <div className="">
                                        <div className="" onClick={() => Commentpoupopen(false)} style={{float:"right",marginRight:"10px",cursor:"pointer",fontSize:'13px'}}>X</div>
                                        <Card className="overflow-hidden" style={{ height: "auto", border: "none" }}>
                                          {/* <Card className="mb-lg-0" style={{ height: "auto", border: "none" }}> */}
                  
                                            <Card.Body>
                                              <div className="mt-2">
                                                <FormGroup className="form-group " >
                                                  <Form.Control
                                                    type="text"
                                                    className="form-control borderadius25px "
                                                    id="name1"
                                                    name="txtCmnt"
                                                    placeholder="Add a Comment"
                                                    style={{ fontSize: "13px", borderRadius: "25px" }}
                                                    onChange={handleChange}
                                                    onKeyPress={handleKeypress}
                                                    value={cmntValue.txtCmnt}
                                                  />
                                                  <label className={commentValiderr ? "event-sub-detail1" : "hide"} style={{ color: "red" }}>Please Add Comment</label>
                  
                                                </FormGroup>
                  
                                                <button className="attendbtn" style={{ width: "90px", height: "28px" }} onClick={() => saveComent(getmenu.kyc?.id)}>
                                                  Send Reply
                                                </button>
                                                {/* <span onClick={() => Commentpoupopen(false)}>close</span> */}
                                              </div>
                                            </Card.Body>
                                          {/* </Card> */}
                                        </Card>
                                      </div>
                                      {
  commentData.map((commentmenu, index) => {
    // Extract first letter of first name and first letter of last name
    const nameParts = commentmenu.viewerName ? commentmenu.viewerName.split(" ") : [];
    const initials =
      nameParts.length > 1
        ? `${nameParts[0][0].toUpperCase()}${nameParts[nameParts.length - 1][0].toUpperCase()}`
        : nameParts[0]
        ? nameParts[0][0].toUpperCase()
        : "";

    return (
      <div key={index}>
        <Card className="" style={{ height: "auto", border: "none" }}>
          <Card.Body>
            <div className="d-sm-flex mt-0 p-3 sub-review-section border br-bl-0 br-br-0">
              <div className="media-body">
                {/* Flex container to keep avatar and name in one line */}
                <h6
                  className="mt-0 mb-1 font-12 font-weight-semibold"
                  style={{
                    fontSize: "13px",
                    display:"inline-flex",
                  }}
                >
                  {/* Profile Picture or Avatar */}
                  {commentmenu.profilePicture ? (
                    <img
                      src={commentmenu.profilePicture}
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        marginRight:"6px",
                        marginTop:"-6px"
                      }}
                    />
                  ) : (
                    <Avatar
                      style={{
                        width: "30px",
                        height: "30px",
                        fontSize: "12px",
                        backgroundColor: bgColor,
                        color: "#ffffff",
                        marginTop:"-6px",
                        marginRight:"6px"
                      }}
                    >
                      {initials}
                    </Avatar>
                  )}

                  {/* Viewer Name */}
                  {commentmenu.viewerName}
                </h6>

                {/* Comment Text */}
                <p className="font-11 mb-2 mt-2" style={{ fontSize: "12px" }}>
                  {commentmenu.comment}
                </p>

                {/* Delete Badge */}
                <Badge
                  bg=""
                  className={commentmenu.viewerId == uerAuthId ? "bg-danger" : "hide"}
                  onClick={() => deleteComment(commentmenu.id)}
                  style={{ cursor: "pointer" }}
                >
                  Delete
                </Badge>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  })
}
                  
                                    </Card>
                  
                  
                 
            </div>
</div>
<div className={ archiveDiv && getmenu.job?.activeStatus=='Archived'?"":"hide"}>



<div className={getmenu.job ? "radius-div-news margintop3" : "hide"}>
            <div className="row margintop1">
                    <div className="col-lg-1 col-sm-12 col-md-12">
                        {getmenu.job?.profilePic == "" || getmenu.job?.profilePic == null ?
                        (<Avatar className="avtarimgsm" style={{ width: "50px",marginTop:"px", height: "49px",backgroundColor: bgColor,color:"#ffffff" }}>{getmenu.job?.postedByName.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.job?.profilePic} id='profpicdisablesave5' className=''style={{ width: "50px",marginTop:"px", height: "49px",borderRadius:"50%"}} />)
                      }
                    </div>
                    <div className="col-lg-5 col-sm-12 col-md-12 textleft" style={{marginTop:"4px"}}>
                      <span className="post-host-Name ">{getmenu.job?.postedByName}</span>
                      <div className=""style={{marginTop:"-3px"}}><span className="event-date">{getmenu.job?.createdDate} @{getmenu.job?.createdTime}</span></div>
                      </div>
                    <div className="col-lg-2 col-sm-12" style={{ whiteSpace: "nowrap",marginTop:"px" }}>
                      </div>
                    <div className="col-lg-4 col-sm-12 col-md-12 textright"style={{marginTop:"4px"}}><span className="post-host-Name"></span>
                   <div className=""style={{marginTop:"-3px"}}><span className="event-date ">Published by {getmenu.job?.postedByName}</span></div> 
                   
                    </div>
                  </div>
                  <div className="row margintop1">
                    <div className="margintop1">
                   {/* <div className=""style={{marginTop:"-3px"}}><span className="event-date ">Published by {getmenu.blog?.eventType}</span></div>  */}
                   <span className="post-host-Name" style={{color:"#626262",marginleft:'7px'}}>Open for Opportunities-</span>
                  </div>
                  </div>
                  <div className="row margintop3">

                  <div className="row mt-1">
    <div className="col-6">
      <div className="row mt-2">
      <div className="col-5"><span className="post-name-btn textleft" style={{marginLeft:'0px',color:"#555555"}}>Community Member</span></div>
      <div className="col-7"><span className=" whitenowrap post-host-Name textleft" style={{fontSize:"12px", marginTop:"px"}}>{getmenu.job?.postedByName} </span></div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="post-name-btn textleft" style={{marginLeft:'0px',color:"#555555"}}>Job Title</span></div>
      <div className="col-7"><span className=" whitenowrap post-host-Name textleft" style={{fontSize:"12px", marginTop:"px"}}>{getmenu.job?.jobOpeningFor}</span></div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="post-name-btn textleft" style={{marginLeft:'0px',color:"#555555"}}>Total Experience</span></div>
      <div className="col-7"><span className=" whitenowrap post-host-Name textleft" style={{fontSize:"12px", marginTop:"px"}}>{getmenu.job?.experience}</span></div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="post-name-btn textleft" style={{marginLeft:'0px',color:"#555555"}}>Current Location</span></div>
      <div className="col-7"><span className=" whitenowrap post-host-Name textleft" style={{fontSize:"12px", marginTop:"px"}}>{getmenu.job?.currentLocation}</span></div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="post-name-btn textleft" style={{marginLeft:'0px',color:"#555555"}}>Home Town</span></div>
      <div className="col-7"><span className=" whitenowrap post-host-Name textleft" style={{fontSize:"12px", marginTop:"px"}}>{getmenu.job?.homeLocation}</span></div>
</div>
    </div>
    
    <div className="col-6">
    <div className="row mt-2">
    <div className="col-5"><span className="post-name-btn textleft"style={{marginLeft:'0px',color:"#555555"}}>Resume</span></div>
    <div className="col-7">{
        getmenu.job?.resumePath=="" || getmenu.job?.resumePath==null?
        (<span className='post-name-btn textleft' style={{color:"#8B0000",marginLeft:"0px"}}>Not Uploaded</span>):(  <a href={`//${getmenu.job?.resumePath}`} target='_blank'><AiOutlineFilePdf size='1.9em' color='#8B0000'/></a>
        )
      }
        </div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="post-name-btn textleft" style={{marginLeft:'0px',color:"#555555"}}>Available for job in</span></div>
      <div className="col-7"><span className=" whitenowrap post-host-Name textleft" style={{fontSize:"12px", marginTop:"px"}}>{getmenu.job?.availableIn}</span></div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="post-name-btn textleft" style={{marginLeft:'0px',color:"#555555"}}>Email Id</span></div>
      <div className="col-7"><span className=" whitenowrap post-host-Name textleft" style={{fontSize:"12px", marginTop:"px"}}>{getmenu.job?.emailId}</span></div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="post-name-btn textleft" style={{marginLeft:'0px',color:"#555555"}}>Mobile No.</span></div>
      <div className="col-7"><span className=" whitenowrap post-host-Name textleft" style={{fontSize:"12px", marginTop:"px"}}>{getmenu.job?.mobileNumber}</span></div>
</div>

    </div>

  </div>


  


                  </div>
                  <div className="row margintop3">
                    <div  className=""><div className="borderBottombtn" style={{marginLeft:"0px"}}></div></div>
                  </div>
                  <div className="row margintop3">
                                      <div className="col-lg-2 maxwd12 col-sm-4 col-md-6">
                                        {/* <BiLike className={status==='like'? 'activelike' : ''} onClick={handleClickLike}/><span className="likespn marginleft2"> {likes}</span> */}
                                        {getmenu.job?.likeStatus == true ? <AiFillLike color="#626262" size="1.3em" onClick={() => addLikes(getmenu.job?.id)} style={{ cursor: "pointer" }} /> : <BiLike onClick={() => addLikes(getmenu.job?.id)} size="1.3em" style={{ cursor: "pointer" }} color="#D9D9D9" />}<span className="post-name-btn " style={{color:"#626262",fontSize:'14px',marginLeft:"4px",cursor:"pointer"}} onClick={() => loadLikesInfo(getmenu.job?.id)}> {getmenu.job?.likeCount} </span>
                                      </div>
                                      <div className="col-lg-2 maxwd12 col-md-4 col-sm-4" onClick={() => commentInfo(getmenu.job?.id)} style={{ cursor: "pointer" }}><FaRegComments size="1.3em" color="#626262" /><span className="post-name-btn " style={{color:"#626262",fontSize:'14px',marginLeft:"6px"}}>{getmenu.job?.commentCount} </span></div>
                                      <div className="col-lg-7"></div>

                                      
                                      <div className='col-2 displyinline' style={{float:"right"}}>
                                      {getmenu.job?.userSpamStatus == false ?
                                        (
                                          <div className="col-lg-2 col-sm-5 col-md-4 " onClick={() => addSpam(getmenu.job?.id)} style={{ cursor: "pointer" }}><RiSpam2Line color="#D9D9D9" size="1.3em"/> </div>
                  
                                        ) : (
                                          <div className="col-1 " onClick={() => addSpam(getmenu.job?.id)} style={{ cursor: "pointer" }}><RiSpam2Line color="#626262" size="1.3em"/> </div>
                  
                                        )
                                      }
{getmenu.job?.spamCount >=3 ?
        (
        <span className='viewsub-activ-spn'style={{float:'right',whiteSpace:"nowrap",backgroundColor:"rgba(211, 15, 69, 1)",color:"#F2F2F2"}} >Spam</span>
        ):
        (
        <span className='viewsub-activ-spn'style={{float:'right',whiteSpace:"nowrap",backgroundColor:"orange",color:"#F2F2F2"}} >Archived</span>
        )
        }
              
                                      </div>
                  
                                    </div>
                                    <div className='row margintop1'>
          <div className='col-4'></div>
          <div className="col-lg-2 maxwd22 col-sm-4 col-md-6 report-spm-div-delete"  style={{ cursor: "pointer" }}><MdOutlineDelete size="1.3em" color="#FF8427" style={{marginLeft:"-7px"}}/><span className="marginleft2 report-spm" style={{color:"#FF8427"}}>Delete</span> </div>
          <div className='col-1 maxwd33'></div>
          <div className="col-lg-2 maxwd22 col-sm-4 col-md-6 report-spm-div" onClick={() => loadPost1(getmenu.job?.id, getmenu.job?.type)} style={{ cursor: "pointer" }}><RiEdit2Line size="1.3em" color="#FFFFFF" /><span className="marginleft2 report-spm">Edit</span> </div>
          <div className='col-3'></div>

          </div>
                                    <Card className={comment && getmenu.job?.id == cmntEventId ? "margintop3" : "hide row"} >
                                      <div className="">
                                        <div className="" onClick={() => Commentpoupopen(false)} style={{float:"right",marginRight:"10px",cursor:"pointer",fontSize:'13px'}}>X</div>
                                        <Card className="overflow-hidden" style={{ height: "auto", border: "none" }}>
                                          {/* <Card className="mb-lg-0" style={{ height: "auto", border: "none" }}> */}
                  
                                            <Card.Body>
                                              <div className="mt-2">
                                                <FormGroup className="form-group " >
                                                  <Form.Control
                                                    type="text"
                                                    className="form-control borderadius25px "
                                                    id="name1"
                                                    name="txtCmnt"
                                                    placeholder="Add a Comment"
                                                    style={{ fontSize: "13px", borderRadius: "25px" }}
                                                    onChange={handleChange}
                                                    onKeyPress={handleKeypress}
                                                    value={cmntValue.txtCmnt}
                                                  />
                                                  <label className={commentValiderr ? "event-sub-detail1" : "hide"} style={{ color: "red" }}>Please Add Comment</label>
                  
                                                </FormGroup>
                  
                                                <button className="attendbtn" style={{ width: "90px", height: "28px" }} onClick={() => saveComent(getmenu.kyc?.id)}>
                                                  Send Reply
                                                </button>
                                                {/* <span onClick={() => Commentpoupopen(false)}>close</span> */}
                                              </div>
                                            </Card.Body>
                                          {/* </Card> */}
                                        </Card>
                                      </div>
                                      {
  commentData.map((commentmenu, index) => {
    // Extract first letter of first name and first letter of last name
    const nameParts = commentmenu.viewerName ? commentmenu.viewerName.split(" ") : [];
    const initials =
      nameParts.length > 1
        ? `${nameParts[0][0].toUpperCase()}${nameParts[nameParts.length - 1][0].toUpperCase()}`
        : nameParts[0]
        ? nameParts[0][0].toUpperCase()
        : "";

    return (
      <div key={index}>
        <Card className="" style={{ height: "auto", border: "none" }}>
          <Card.Body>
            <div className="d-sm-flex mt-0 p-3 sub-review-section border br-bl-0 br-br-0">
              <div className="media-body">
                {/* Flex container to keep avatar and name in one line */}
                <h6
                  className="mt-0 mb-1 font-12 font-weight-semibold"
                  style={{
                    fontSize: "13px",
                    display:"inline-flex",
                  }}
                >
                  {/* Profile Picture or Avatar */}
                  {commentmenu.profilePicture ? (
                    <img
                      src={commentmenu.profilePicture}
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        marginRight:"6px",
                        marginTop:"-6px"
                      }}
                    />
                  ) : (
                    <Avatar
                      style={{
                        width: "30px",
                        height: "30px",
                        fontSize: "12px",
                        backgroundColor: bgColor,
                        color: "#ffffff",
                        marginTop:"-6px",
                        marginRight:"6px"
                      }}
                    >
                      {initials}
                    </Avatar>
                  )}

                  {/* Viewer Name */}
                  {commentmenu.viewerName}
                </h6>

                {/* Comment Text */}
                <p className="font-11 mb-2 mt-2" style={{ fontSize: "12px" }}>
                  {commentmenu.comment}
                </p>

                {/* Delete Badge */}
                <Badge
                  bg=""
                  className={commentmenu.viewerId == uerAuthId ? "bg-danger" : "hide"}
                  onClick={() => deleteComment(commentmenu.id)}
                  style={{ cursor: "pointer" }}
                >
                  Delete
                </Badge>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  })
}
                  
                                    </Card>
                  
                  
                 
            </div>
</div>
<div className={archiveDiv==false && spamDIv==false && activeDiv==false && getmenu.job ?'example margintop3':'hide'}>


<div className={getmenu.job ? "radius-div-news margintop3" : "hide"}>
            <div className="row margintop1">
                    <div className="col-lg-1 col-sm-12 col-md-12">
                        {getmenu.job?.profilePic == "" || getmenu.kyc?.bannerImagePath == null ?
                        (<Avatar className="avtarimgsm" style={{ width: "50px",marginTop:"px", height: "49px",backgroundColor: bgColor,color:"#ffffff" }}>{getmenu.job?.postedByName.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.job?.profilePic} id='profpicdisablesave5' className=''style={{ width: "50px",marginTop:"px", height: "49px",borderRadius:"50%"}} />)
                      }
                    </div>
                    <div className="col-lg-5 col-sm-12 col-md-12 textleft" style={{marginTop:"4px"}}>
                      <span className="post-host-Name ">{getmenu.job?.postedByName}</span>
                      <div className=""style={{marginTop:"-3px"}}><span className="event-date">{getmenu.job?.createdDate} @{getmenu.job?.createdTime}</span></div>
                      </div>
                    <div className="col-lg-2 col-sm-12" style={{ whiteSpace: "nowrap",marginTop:"px" }}>
                      </div>
                    <div className="col-lg-4 col-sm-12 col-md-12 textright"style={{marginTop:"4px"}}><span className="post-host-Name"></span>
                   <div className=""style={{marginTop:"-3px"}}><span className="event-date ">Published by {getmenu.job?.postedByName}</span></div> 
                   
                    </div>
                  </div>
                  <div className="row margintop1">
                    <div className="margintop1">
                   {/* <div className=""style={{marginTop:"-3px"}}><span className="event-date ">Published by {getmenu.blog?.eventType}</span></div>  */}
                   <span className="post-host-Name" style={{color:"#626262",marginleft:'7px'}}>Open for Opportunities-</span>
                  </div>
                  </div>
                  <div className="row margintop3">

                  <div className="row mt-1">
    <div className="col-6">
      <div className="row mt-2">
      <div className="col-5"><span className="post-name-btn textleft" style={{marginLeft:'0px',color:"#555555"}}>Community Member</span></div>
      <div className="col-7"><span className=" whitenowrap post-host-Name textleft" style={{fontSize:"12px", marginTop:"px"}}>{getmenu.job?.postedByName} </span></div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="post-name-btn textleft" style={{marginLeft:'0px',color:"#555555"}}>Job Title</span></div>
      <div className="col-7"><span className=" whitenowrap post-host-Name textleft" style={{fontSize:"12px", marginTop:"px"}}>{getmenu.job?.jobOpeningFor}</span></div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="post-name-btn textleft" style={{marginLeft:'0px',color:"#555555"}}>Total Experience</span></div>
      <div className="col-7"><span className=" whitenowrap post-host-Name textleft" style={{fontSize:"12px", marginTop:"px"}}>{getmenu.job?.experience}</span></div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="post-name-btn textleft" style={{marginLeft:'0px',color:"#555555"}}>Current Location</span></div>
      <div className="col-7"><span className=" whitenowrap post-host-Name textleft" style={{fontSize:"12px", marginTop:"px"}}>{getmenu.job?.currentLocation}</span></div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="post-name-btn textleft" style={{marginLeft:'0px',color:"#555555"}}>Home Town</span></div>
      <div className="col-7"><span className=" whitenowrap post-host-Name textleft" style={{fontSize:"12px", marginTop:"px"}}>{getmenu.job?.homeLocation}</span></div>
</div>
    </div>
    
    <div className="col-6">
    <div className="row mt-2">
    <div className="col-5"><span className="post-name-btn textleft"style={{marginLeft:'0px',color:"#555555"}}>Resume</span></div>
    <div className="col-7 ">{
        getmenu.job?.resumePath=="" || getmenu.job?.resumePath==null?
        (<span className='post-name-btn textleft' style={{color:"#8B0000",marginLeft:"0px"}}>Not Uploaded</span>):(  <a href={`//${getmenu.job?.resumePath}`} target='_blank'><AiOutlineFilePdf size='1.9em' color='#8B0000'/></a>
        )
      }
        </div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="post-name-btn textleft" style={{marginLeft:'0px',color:"#555555"}}>Available for job in</span></div>
      <div className="col-7"><span className=" whitenowrap post-host-Name textleft" style={{fontSize:"12px", marginTop:"px"}}>{getmenu.job?.availableIn}</span></div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="post-name-btn textleft" style={{marginLeft:'0px',color:"#555555"}}>Email Id</span></div>
      <div className="col-7"><span className=" whitenowrap post-host-Name textleft" style={{fontSize:"12px", marginTop:"px"}}>{getmenu.job?.emailId}</span></div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="post-name-btn textleft" style={{marginLeft:'0px',color:"#555555"}}>Mobile No.</span></div>
      <div className="col-7"><span className=" whitenowrap post-host-Name textleft" style={{fontSize:"12px", marginTop:"px"}}>{getmenu.job?.mobileNumber}</span></div>
</div>

    </div>

  </div>

  


                  </div>
                  <div className="row margintop3">
                    <div  className=""><div className="borderBottombtn" style={{marginLeft:"0px"}}></div></div>
                  </div>
                  <div className="row margintop3">
                                      <div className="col-lg-2 maxwd12 col-sm-4 col-md-6">
                                        {/* <BiLike className={status==='like'? 'activelike' : ''} onClick={handleClickLike}/><span className="likespn marginleft2"> {likes}</span> */}
                                        {getmenu.job?.likeStatus == true ? <AiFillLike color="#626262" size="1.3em" onClick={() => addLikes(getmenu.job?.id)} style={{ cursor: "pointer" }} /> : <BiLike onClick={() => addLikes(getmenu.job?.id)} size="1.3em" style={{ cursor: "pointer" }} color="#D9D9D9" />}<span className="post-name-btn " style={{color:"#626262",fontSize:'14px',marginLeft:"4px",cursor:"pointer"}} onClick={() => loadLikesInfo(getmenu.job?.id)}> {getmenu.job?.likeCount} </span>
                                      </div>
                                      <div className="col-lg-2 maxwd12 col-md-4 col-sm-4" onClick={() => commentInfo(getmenu.job?.id)} style={{ cursor: "pointer" }}><FaRegComments size="1.3em" color="#626262" /><span className="post-name-btn " style={{color:"#626262",fontSize:'14px',marginLeft:"6px"}}>{getmenu.job?.commentCount} </span></div>
                                      <div className="col-lg-7"></div>

                                      
                                      <div className='col-2 displyinline' style={{float:"right"}}>
                                      {getmenu.job?.userSpamStatus == false ?
                                        (
                                          <div className="col-lg-2 col-sm-5 col-md-4 " onClick={() => addSpam(getmenu.job?.id)} style={{ cursor: "pointer" }}><RiSpam2Line color="#D9D9D9" size="1.3em"/> </div>
                  
                                        ) : (
                                          <div className="col-1 " onClick={() => addSpam(getmenu.job?.id)} style={{ cursor: "pointer" }}><RiSpam2Line color="#626262" size="1.3em"/> </div>
                  
                                        )
                                      }
{getmenu.job?.spamCount >=3 ?
        (
        <span className='viewsub-activ-spn'style={{float:'right',whiteSpace:"nowrap",backgroundColor:"rgba(211, 15, 69, 1)",color:"#F2F2F2"}} >Spam</span>
        ):
        (
          <span>
        {/* <span className='viewsub-activ-spn'style={{float:'right',whiteSpace:"nowrap",backgroundColor:"orange",color:"#F2F2F2"}} >Spam</span> */}
        <span  className={getmenu.job?.activeStatus=='Archived'?"viewsub-activ-spn":"hide"} style={{backgroundColor:"orange"}}>Archived</span>
              <span style={{float:'right',whiteSpace:"nowrap"}}  className={getmenu.job?.activeStatus=='Active'?"viewsub-activ-spn":"hide"} >Active</span>
              <span  className={getmenu.job?.userSpamStatus==true?"viewsub-activ-spn":"hide"} style={{float:'right',whiteSpace:"nowrap",backgroundColor:"rgba(211, 15, 69, 1)",color:"#F2F2F2"}} >Spam</span>
        </span>
        )
        }
              
                                      </div>
                  
                                    </div>
                                    <div className='row margintop1'>
          <div className='col-4'></div>
          <div className="col-lg-2 maxwd22 col-sm-4 col-md-6 report-spm-div-delete"  style={{ cursor: "pointer" }}><MdOutlineDelete size="1.3em" color="#FF8427" style={{marginLeft:"-7px"}}/><span className="marginleft2 report-spm" style={{color:"#FF8427"}}>Delete</span> </div>
          <div className='col-1 maxwd33'></div>
          <div className="col-lg-2 maxwd22 col-sm-4 col-md-6 report-spm-div" onClick={() => loadPost1(getmenu.job?.id, getmenu.job?.type)} style={{ cursor: "pointer" }}><RiEdit2Line size="1.3em" color="#FFFFFF" /><span className="marginleft2 report-spm">Edit</span> </div>
          <div className='col-3'></div>

          </div>
                                    <Card className={comment && getmenu.job?.id == cmntEventId ? "margintop3" : "hide row"} >
                                      <div className="">
                                        <div className="" onClick={() => Commentpoupopen(false)} style={{float:"right",marginRight:"10px",cursor:"pointer",fontSize:'13px'}}>X</div>
                                        <Card className="overflow-hidden" style={{ height: "auto", border: "none" }}>
                                          {/* <Card className="mb-lg-0" style={{ height: "auto", border: "none" }}> */}
                  
                                            <Card.Body>
                                              <div className="mt-2">
                                                <FormGroup className="form-group " >
                                                  <Form.Control
                                                    type="text"
                                                    className="form-control borderadius25px "
                                                    id="name1"
                                                    name="txtCmnt"
                                                    placeholder="Add a Comment"
                                                    style={{ fontSize: "13px", borderRadius: "25px" }}
                                                    onChange={handleChange}
                                                    onKeyPress={handleKeypress}
                                                    value={cmntValue.txtCmnt}
                                                  />
                                                  <label className={commentValiderr ? "event-sub-detail1" : "hide"} style={{ color: "red" }}>Please Add Comment</label>
                  
                                                </FormGroup>
                  
                                                <button className="attendbtn" style={{ width: "90px", height: "28px" }} onClick={() => saveComent(getmenu.kyc?.id)}>
                                                  Send Reply
                                                </button>
                                                {/* <span onClick={() => Commentpoupopen(false)}>close</span> */}
                                              </div>
                                            </Card.Body>
                                          {/* </Card> */}
                                        </Card>
                                      </div>
                                      {
  commentData.map((commentmenu, index) => {
    // Extract first letter of first name and first letter of last name
    const nameParts = commentmenu.viewerName ? commentmenu.viewerName.split(" ") : [];
    const initials =
      nameParts.length > 1
        ? `${nameParts[0][0].toUpperCase()}${nameParts[nameParts.length - 1][0].toUpperCase()}`
        : nameParts[0]
        ? nameParts[0][0].toUpperCase()
        : "";

    return (
      <div key={index}>
        <Card className="" style={{ height: "auto", border: "none" }}>
          <Card.Body>
            <div className="d-sm-flex mt-0 p-3 sub-review-section border br-bl-0 br-br-0">
              <div className="media-body">
                {/* Flex container to keep avatar and name in one line */}
                <h6
                  className="mt-0 mb-1 font-12 font-weight-semibold"
                  style={{
                    fontSize: "13px",
                    display:"inline-flex",
                  }}
                >
                  {/* Profile Picture or Avatar */}
                  {commentmenu.profilePicture ? (
                    <img
                      src={commentmenu.profilePicture}
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        marginRight:"6px",
                        marginTop:"-6px"
                      }}
                    />
                  ) : (
                    <Avatar
                      style={{
                        width: "30px",
                        height: "30px",
                        fontSize: "12px",
                        backgroundColor: bgColor,
                        color: "#ffffff",
                        marginTop:"-6px",
                        marginRight:"6px"
                      }}
                    >
                      {initials}
                    </Avatar>
                  )}

                  {/* Viewer Name */}
                  {commentmenu.viewerName}
                </h6>

                {/* Comment Text */}
                <p className="font-11 mb-2 mt-2" style={{ fontSize: "12px" }}>
                  {commentmenu.comment}
                </p>

                {/* Delete Badge */}
                <Badge
                  bg=""
                  className={commentmenu.viewerId == uerAuthId ? "bg-danger" : "hide"}
                  onClick={() => deleteComment(commentmenu.id)}
                  style={{ cursor: "pointer" }}
                >
                  Delete
                </Badge>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  })
}
                  
                                    </Card>
                  
                  
                 
            </div>
</div>
         
</div>

            </div>
            ))

        }
{
 loadPost  && loadPost.length== 0 &&(
<div className='row margintop'>
  <div className='col-3'></div>
  <div className='col-5 margintop'> <span className='event-sub-detail'>Posts Are not Available</span>
</div>

</div>
 )
}

<br/><br/>





{/* <div className="example" style={{float:"right"}}>
      <Pagination className=" mb-0">
        <Pagination.Item className="page-item" style={{color:"black", fontSize:"16px"}}>
Previous       
 </Pagination.Item>
        <Pagination.Item className="page-item active">
          1
        </Pagination.Item>
        <Pagination.Item className="page-item">
          2
        </Pagination.Item>
        <Pagination.Item className="page-item">
          3
        </Pagination.Item>
        <Pagination.Item className="page-item">
Next        </Pagination.Item>
      </Pagination>
    </div> */}
</div>
      </Col>

                       
                          </Card.Body>
    </Row>
    
    
    <div className={LikeDiv ? "row":"hide row"}>
  <div className="popup-boxdate">
       <div className="boxdate" style={{width:'30%',marginLeft:"620px",marginTop:"100px"}}>
 <div className='bordernonemessg' ref={popupLikeRef} style={{marginLeft:"-16px",marginRight:"-16px"}}>
 <div className=" row" style={{borderBottom:"1px",borderColor:"lightgray"}} >
   <div className='col-1'></div>
     <div className=" col-9" style={{textAlign:"left",fontSize:"16px"}}><span className="event-sub-detail">Reactions</span></div>
     <div className='col-1'>
     <span className="" onClick={()=>Likepoupopen(false)}><MdClose size='1.5em'/></span>
     </div>
     <br/>
     </div>
     <div className='row' style={{marginTop:"20px"}}></div>
 </div>
 
 <div style={{overflowY:"scroll",height:"350px",overflowX:"hidden"}}>
 
 {
  likeData.map((likemenu, index) => {
    // Extract first letter of first name and first letter of last name
    const nameParts = likemenu.viewerName ? likemenu.viewerName.split(" ") : [];
    const initials =
      nameParts.length > 1
        ? `${nameParts[0][0].toUpperCase()}${nameParts[nameParts.length - 1][0].toUpperCase()}`
        : nameParts[0]
        ? nameParts[0][0].toUpperCase()
        : "";

    return (
      <div key={index} className="radius-fourth-card1 bordernonemessg1 margintop3" style={{ marginTop: "20px" }}>
        <div className="row margintop1">
          <div className="col-9">
            <div>
              {/* Profile Picture or Avatar */}
              {likemenu.profilePicture ? (
                <img
                  src={likemenu.profilePicture}
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    marginRight: "6px",
                    marginTop: "-6px",
                  }}
                />
              ) : (
                <Avatar
                  style={{
                    width: "30px",
                    height: "30px",
                    fontSize: "12px",
                    backgroundColor: "#007bff", // You can choose a different color
                    color: "#ffffff",
                    marginTop: "-6px",
                    marginRight: "6px",
                  }}
                >
                  {initials}
                </Avatar>
              )}

              {/* Viewer Name */}
              <span className="event-sub-detail" style={{ fontSize: "14px", color: "black" }}>
                {likemenu.viewerName}
              </span>
            </div>
            <div>
              
            </div>
          </div>
        </div>
        <div className="row" style={{ marginTop: "10px" }}></div>
      </div>
    );
  })
}

             
             
 </div>
     
             
       </div>
     </div>
 
   
     
 
     </div>

     <div className={EventEditDiv ? "row":"hide row"}>

     <div className="popup-boxnews">
      <div className="boxnews" >
                  <div className="topnewsrow"style={{paddingTop:"11px",paddingBottom:"7px",backgroundColor:"#FF84270D",borderColor:"#FF84270D"}} >
                  <div className="row " id="sharediv"> 

                    <span className='post-host-Name' style={{fontSize:"15px",textAlign:"left"}}><MdOutlineEventAvailable size="1.3em"/> Edit an Event</span>
                    </div>
                  </div>
                  {/* <form onSubmit={submitFormData}> */}

                  <div className='row'>
                    <div className='col-12'>
                    
                    <div className="row margintop3">
                    <div className="col-5 textleft whitenowrap"><span className="post-host-Name "style={{color:"#626262"}}>Host Name</span></div>
                    <div className=" textleft "><span className="post-host-Name "style={{color:"#212529"}}>{eventdata?.hostName} </span></div>
                </div>
                <div className="row margintop2">
                    <div className="col-5 textleft margintop1"><span className="post-host-Name whitenowrap " style={{color:"#626262",fontSize:"13px"}}>Event Type</span>
                    <div className=" textleft">
                    <select   id="inputState" name='eventType' 
            className="form-control " onChange={handlechange} style={{fontSize:"13px"}} value={eventdata?.eventType || ""}>
            <option  value=""> Select Event Type</option>
            <option value="House Warming Ceremony">House Warming Ceremony</option>
            <option value="Birthday">Birthday</option>
            <option value="Marrige Anniversary">Marrige Anniversary</option>
            <option value="Death Anniversary"> Death Anniversary</option>
            <option value="Others">Others</option>
       </select>
                    </div>
                </div>
                 <div className='row '>
               <div className="col-7">
                                    {error && eventdata.eventType == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Type  is is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>     
  </div>            
                <div className="row margintop2">
                    <div className="col-6 textleft margintop1"><span className="post-host-Name "style={{color:"#626262"}}>Date</span>
                    <div className=" textleft">
                    <DatePicker
          className="adreservdate width299px "
          name='eventDate'
            selected={startDate}
             onChange={date => handleChangedate(date)}

            selectsStart // tells this DatePicker that it is part of a range*
            minDate={moment().toDate()}
            value={eventdata?.eventDate}
            startDate={startDate}
            dateFormat="dd  MMM yyyy"
          />     
           <div className='row '>
               <div className="col-12">
                                    {error && eventdata.eventDate == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Date  is is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>                          
           </div>
                    </div>
                    <div className="col-5 textleft margintop1">
                    <span className=" post-host-Name "style={{color:"#626262"}}>Time</span>
                    <input type="time" name='eventTime'  value={convertTo24HourFormat(eventdata?.eventTime)} class="  form-control" onChange={handlechange} style={{fontSize:"13px"}}/>
                    <div className='row '>
                  
               <div className="col-12">
                                    {error && eventdata.eventTime == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Time  is is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>        
                    </div>

                   
                </div>
                
                
                 
                <div className="row margintop2">
                    <div className="col-5 textleft margintop1"><span className="post-host-Name "style={{color:"#626262"}}>Venue</span>
                    <div className=" textleft form-group">
                    <textarea class="form-control " name='address' value={eventdata?.address} id="exampleFormControlTextarea1" rows="2" placeholder="Enter Address"
                     onChange={handlechange} style={{fontSize:"13px"}}/>
                        </div>
                        <div className="col-7">
                                    {error && eventdata.address == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Address  is is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                </div>
                                  
  </div>        
                 <div className="row ">
                    <div className="col-5 textleft "><span className="post-host-Name "style={{color:"#626262"}}>Country</span>
                    <div className=" textleft form-group">
                   
    <select   id="countryy"  
                className=" form-control "
                name='country'
                onChange={handleCountryChange}
                >
                <option selected value="">{eventdata?.country}</option>
                {
                  
                  countryData.map((result) =>(
                  <option  key={result.id}  value={`${result.id}-${result.country}`}>{result.country}</option>
                  ) )

                }
                </select>   
     </div>
     <div className="col-7">
                                    {error && eventdata.country == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Country  is is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                
                
  </div>
  </div>      
                
                 <div className="row ">
                    <div className="col-5 textleft "><span className="post-host-Name "style={{color:"#626262"}}>State</span>
                    <div className=" textleft form-group">
                    
      <select   id="state"  
                className=" form-control "
                name='state'
//value={postData?.state}
                onChange={handleStateChange}>
                <option selected value="">{eventdata?.state}</option>
                {
                  
                  stateData.map((result) =>(
                  <option  key={result.id}  value={`${result.id}-${result.state}`}>{result.state}</option>
                  ) )

                }
                </select> 
             </div>
             <div className="col-7">
                                    {error && eventdata.state == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            State is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                </div>
                 
  </div>      
                
                 <div className="row ">
                    <div className="col-5 textleft "><span className="post-host-Name "style={{color:"#626262"}}>City</span>
                    <div className=" textleft form-group">
                    
      <select   id="city"  
                className=" form-control "
                name='city'
                //value={postData?.city}
                onChange={handleCityChange}>
                <option selected value="">{eventdata?.city}</option>
                {
                  
                  cityData.map((result) =>(
                  <option  key={result.id}  value={`${result.id}-${result.city}`}>{result.city}</option>
                  ) )

                }
                </select>
       </div>
       <div className="col-7">
                                    {error && eventdata.city == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            City  is is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                </div>
                
  </div>     
  {/* <div className="row  textleft">
                  <div className="col-8">
                  <div className="  "><span className="post-host-Name " style={{color:"#797979",fontSize:"12px"}}>This event is being host in </span> <span className="event-sub-detail">{authlocalstoragedata?.currentCountry}, {authlocalstoragedata?.currentState}, {authlocalstoragedata?.currentCity}</span></div>
                  
                  <div className=" margintop1">
                  <div className="  mt-1"><span className="post-host-Name " style={{color:"#797979",fontSize:"12px"}}>If you want to host somewhere else please <span className="bluetextforlink" style={{ fontSize: "10px", marginLeft: "0px" }} onClick={togglePopupCountry}>Click here</span></span></div>
                  </div>
                </div> 
                </div>  */}

      <div className="col-12 textleft margintop2"><span className="post-host-Name "style={{color:"#626262"}}>Choose File</span>
      <div className=''><span className='fontroboto40012px' style={{color:"#626262",fontSize:"11px",fontStyle:"italic"}}>A maximum of 5 files can be added to this event. (Your file should be in jpg., jpeg.,  png., svg. with less than 20 MB size)</span></div>
      </div>
            
  {/* {serviceList.map((singleService, index) => (
      <div className='row margintop1' key={index}  style={{whiteSpace:'nowrap'}}>

      <div  className='margintop2 col-lg-5 col-sm-9 col-md-9'>
      <input type="file" 
      accept="image/jpeg, image/png, image/svg+xml"
      class="form-control  textboxback" name='attachFiles'
     id="attachFiles" placeholder="Upload" 
     value={singleService?.service}
                onChange={(e) => handleServiceChange(e, index)}
     />
      </div>
      <div className='margintop3 col-lg-1 col-sm-1 col-md-1'>
      {serviceList.length - 1 === index && serviceList.length < 5 && (
        <BsPlusLg onClick={handleServiceAdd} size='1.2em'/>
                
              )}
      </div>
      <div className='margintop3 col-lg-1 col-sm-1 col-md-1'>
              {serviceList.length !== 1 && (
                <MdOutlineDeleteOutline size='1.7em' onClick={() => handleServiceRemove(index)}/>
                
              )}
      </div>
      </div>

        ))} */}
        




                                        <div className="row margintop3">
                                        <div className="col-lg-2 col-md-3 col-sm-12" >
<label class=" btn-primary1" for="selectFileprof" name="profilePhoto" role="button" tabindex="0" style={{fontSize:"12px"}}>

{
imgDataProf !=null ?(
<img id='image' className="playerProfilePic_home_tile2"
src={imgDataProf} 

/>
):
(                       
<img src={profilePhoto}  className='imagePreview11' style={{height:"108px"}} onChange={handlephotoProfChange}/>

)
}

</label>
<input type="file" class="hide" ref={filerefProf}  id="selectFileprof" aria-invalid="false" onChange={handlephotoProfChange}/>
<div className=""><span className="comudrct-digitxt">Profile</span></div>

</div>
<div className="col-lg-2 col-md-3 col-sm-12" >
<label class=" btn-primary1" for="selectFile11" name="photoOne" role="button" tabindex="0" style={{fontSize:"12px"}}>

{
imgData11 !=null ?(
<img id='image' className="playerProfilePic_home_tile2"
src={imgData11} 

/>
):
(                       
<img src={photoOne}  className='imagePreview11' style={{height:"108px"}} onChange={handlephotoOneChange}/>

)
}

</label>
<input type="file" class="hide" ref={fileref}  id="selectFile11" aria-invalid="false" onChange={handlephotoOneChange}/>
{/* <div style={{textAlign:"center"}} className={photoOne!=""||photoOne!=null?"":"hide"}><Tippy content="Delete"><span style={{cursor:"pointer"}}><MdDeleteOutline onClick={deleteFirstimg} size="1.5em"/></span></Tippy></div> */}

</div>
<div className="col-lg-2 col-md-3 col-sm-12" >
<label class=" btn-primary1" name="photoTwo" for="selectFile1" role="button" tabindex="0" style={{fontSize:"12px"}}>

{
imgData1 !=null ?(
<img id='image' className="playerProfilePic_home_tile2"
src={imgData1} 

/>
):
( 
<img src={photoTwo}  className='imagePreview11' style={{height:"108px"}} onChange={handlephotoTwoChange}/>

)
}
</label>
<input type="file" class="hide" ref={fileref1}  id="selectFile1" aria-invalid="false" onChange={handlephotoTwoChange}/>
{/* <div style={{textAlign:"center"}} className={photoTwo!=""||photoTwo!=null?"":"hide"}><Tippy content="Delete"><span style={{cursor:"pointer"}}><MdDeleteOutline onClick={deleteSecondimg} size="1.5em"/></span></Tippy></div> */}

</div>
<div className="col-lg-2 col-md-3 col-sm-12" >
<label class=" btn-primary1" for="selectFile2" name="photoThree" role="button" tabindex="0" style={{fontSize:"12px"}}>
{
imgData2 !=null ?(
<img id='image' className="playerProfilePic_home_tile2"
src={imgData2} 

/>
):
( 
<img src={photoThree}  className='imagePreview11' style={{height:"108px"}} onChange={handlephotoThreeChange}/>

)
} 
</label>
<input type="file" class="hide"  ref={fileref2}  id="selectFile2" aria-invalid="false" onChange={handlephotoThreeChange}/>
{/* <div style={{textAlign:"center"}} className={photoThree!=""||photoThree!=null?"":"hide"}><Tippy content="Delete"><span style={{cursor:"pointer"}}><MdDeleteOutline onClick={deleteThirdimg} size="1.5em"/></span></Tippy></div> */}

</div>
<div className="col-lg-2 col-md-3 col-sm-12" >
<label class=" btn-primary1" for="selectFile3" name="photoFour" role="button" tabindex="0" style={{fontSize:"12px",justifySelf:"center"}}>
{
imgData3 !=null ?(
<img id='image' className="playerProfilePic_home_tile2"
src={imgData3} 

/>
):
( 
<img src={photoFour}  className='imagePreview11' style={{height:"108px"}}  onChange={handlephotoFourChange}/>

)
}   
</label>
<input type="file" class="hide" ref={fileref3}  id="selectFile3" aria-invalid="false" onChange={handlephotoFourChange}/>
{/* <div style={{textAlign:"center"}} className={photoFour!=""||photoFour!=null?"":"hide"}><Tippy content="Delete"><span style={{cursor:"pointer"}}><MdDeleteOutline onClick={deleteFourthimg} size="1.5em"/></span></Tippy></div> */}

</div>
</div>
<div className={errorForImg?'row':"hide"}><span style={{ color: "red",fontSize:"12px" }}>
        Please upload at least one image.
                                        </span></div>

        
                    <div className='row margintop'>
                    <div className='col-2'>
                        <button className='secondry-btnn'  onClick={()=>setEventEditDiv(false)} style={{height:"40px",borderRadius:"20px"}}>Cancel</button>
                    </div>
                    <div className='col-2'>
                    <button className='primary-btnn' onClick={submitFormData} style={{height:"40px",fontWeight:"500",marginLeft:"17px"}}>Create Event</button>
                  </div>
                 

                  </div>
                    </div>
                    
                    
                     
                  </div>
                  
                  {/* </form> */}

                 

      </div>
      
    </div>
     </div>
     <div className={BlogEditDiv ? "row":"hide row"}>

     <div className="popup-boxnews">
      <div className="boxnews" style={{height:"90vh", marginTop: "10px"}}>
                        <div className="topnewsrow"style={{paddingTop:"11px",paddingBottom:"7px",backgroundColor:"#FF84270D",borderColor:"#FF84270D"}} >
                        <div className="row " id="sharediv"> 
      
                          <span className='post-host-Name ' style={{fontSize:"15px"}}><FaBlog size="1.3em"/> Edit an Blog</span>
                          </div>
                        </div>
                  {/* <form onSubmit={submitFormData}> */}

                  <div className='row'style={{}}>
                    <div className='col-6'>
                    <div className="row margintop1">
                    <div className="col-11 textleft whitenowrap"><span className="post-host-Name "style={{color:"#626262",fontSize:"13px"}}>Author Name</span>
                    <div className=" textleft "><span className="post-host-Name "style={{color:"#626262",fontSize:"13px"}} >{authlocalstoragedata?.name2}</span></div>
                    </div>
                </div>
                <div className="row margintop3">
                    <div className="col-11 mt-1 textleft"><span className="whitenowrap post-host-Name "style={{color:"#626262",fontSize:"13px"}}>Blog Title</span>
                    <div className=" textleft form-group">
                 <input type="text" class=" form-control" placeholder='Add Blog Title' value={blog?.blogTitle} onChange={handleChangeblog} name='blogTitle' style={{fontSize:"12px"}}/>
                 </div>
                  </div>
                </div>
               <div className='row textleft'>
               <div className="col-8">
                                    {errorblog && blog.blogTitle == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Blog Title is required .
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    {errorblog &&  blog.blogTitle?.length >70 ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                        Blog Title  cannot exceed more than 70 characters.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
                                
  </div> 
                <div className="row ">
                    <div className="col-11 mt-1 textleft"><span className="post-host-Name "style={{color:"#626262",fontSize:"13px"}} >Image Source</span>
                    <div className=" textleft form-group">
                    <input type="text" class=" form-control" placeholder='Add Image Source' value={blog?.imageSource} onChange={handleChangeblog} name='imageSource' style={{fontSize:"12px"}} />
                    </div>
                   </div>
                </div>
                 <div className='row textleft'>
               <div className="col-8">
                                    {errorblog && blog.imageSource == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Image Source is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    {errorblog &&  blog.imageSource?.length >70 ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                        Image Source  cannot exceed more than 70 characters.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div> 
  </div>
                <div className='col-6'>
                <div className="row margintop1">
                    <div className="col-11 textleft"><span className="post-host-Name "style={{color:"#626262",fontSize:"13px"}} >Banner Image</span>
                    <div className=' margintop1'>
                            <label for="selectFileblog" name="bannerImage" className="" style={{ backgroudColor: "white" }}   >
                            {
                  imgDatablog !=null ?(
                    <img id='image' className="playerProfilePic_home_tileblog"
                    src={imgDatablog} 
                   
                />
                  ):
                       ( <img src={attachmentblog}  className='imagePreview' style={{height:"160px",width:"200px"}} onChange={banerImageChange}/>)
                  }
                           </label>
                     <input id="selectFileblog" type="file" ref={filerefblog}  className="fileupload hide" 
                       name="bannerImage"  onChange={banerImageChange}
                        />
                        <div className={imgDatablog ==null?'margintpmins1':"hide"} style={{marginLeft:'50px'}}><span className='photoupload-lbl textcenter'>Add Banner Image to your blog</span></div>
                              </div>
                              </div>
                </div>
                </div>
               
                <div className="row margintop3">
                    <div className="col-10 textleft"><span className=" post-host-Name" style={{color:"#626262",fontSize:"13px"}}>Add Blog Content</span>
                    <div className=" textleft form-group">
                    <textarea class="form-control" name='blogContent' value={blog?.blogContent} onChange={handleChangeblog} id="exampleFormControlTextarea1" rows="5" placeholder="Blog Content" style={{fontSize:"12px"}}/>
                        </div>
                        </div>
                </div>
                
                 <div className='row textleft'>
               <div className="col-8">
                                    {errorblog && blog.blogContent == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Blog Content is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    {errorblog && blog.blogContent.length >7500  ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Blog Content  cannot exceed more than 7500 characters..
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div> 
                <div className="row ">
                <div className="col-3  textleft">
                    {/* <input type="radio" id="radio1" className='radiobtnnews'  
                    name="addblogradio" value="publishnow" onClick={ handleshow }
                    />  
                     <label for="defaultRadio1" className='marginleft2 radiobtn-textblog'>Publish Now</label> */}
                     <label class="radiodivcust radiobtn-text1 post-host-Name" style={{color:"#626262",fontSize:"13px"}}>Publish Now
                     <input type="radio" id="radio1" className=''  
                    name="publishStatus"  value="publishnow" onClick={ handleshow } onChange={handleChangeblog}
                    />
                      <span class="checkmark"></span>
           </label>
                    </div>
                    <div className="col-4  textleft">
                    {/* <input type="radio" id="radio1" className='radiobtnnews' onClick={ handleshow }
                    name="addblogradio" value="schedulelater"
                    />  
                     <label for="defaultRadio1" className='marginleft2 radiobtn-textblog'>Schedule for later</label> */}
                     <label class="radiodivcust radiobtn-text1 post-host-Name" style={{color:"#626262",fontSize:"13px"}}>Schedule for later
                     <input type="radio" id="radio1" className='radiobtnnews' onChange={handleChangeblog} onClick={ handleshow }
                    name="publishStatus" value="schedulelater"
                    /> 
                      <span class="checkmark"></span>
           </label>
                    </div>

                 </div>
                
                  <div className='row '>
               <div className="col-6">
                                    {errorblog && blog.publishStatus == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            This is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>   
                    
                    
                    
                  {
                    showhide==='schedulelater' && (
                    <div className='row'>
                    <div className='col-5  textleft'>
                     <label  className="form-label schedulforlbl post-host-Name "style={{color:"#626262",fontSize:"13px"}}> Schedule Date </label> 
                     <div className=' textleft'>
                     <DatePicker
                    className="adreservdate  width299px"
                    name='scheduledDate'
                    selected={startDate}
                    onChange={date => handleChangedate(date)}
                    selectsStart // tells this DatePicker that it is part of a range*
                    startDate={startDate}
                    dateFormat="dd  MMM yyyy"
                    minDate={moment().toDate()}
                    value={blog?.scheduledDate}
                />    
                 <div className='row '>
                  <div className=''></div>
               <div className="col-12">
                                    {errorblog && blog.scheduledDate == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Date is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                </div>
      
  </div>        
                     </div>
                     <div className="col-4 textleft"> 
                     <label  className="form-label schedulforlbl post-host-Name "style={{color:"#626262",fontSize:"13px"}}> Schedule Time </label> 
                    <div> <input type="time" name='scheduledTime'  value={blog?.scheduledTime} onChange={handleChangeblog} className=' form-control' style={{fontSize:"12px"}}/> 
                      
                      </div>
                      <div className='row '>
                  <div className=''></div>
               <div className="col-12">
                                    {errorblog && blog.scheduledTime == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Time  is is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>                                                        
                    </div>
                    </div>

                    )

                    } 

                  </div>

                  <div className='row margintop2 '>
                    <div className='col-2'>
                        <button className='cancelbtn post-host-Name' onClick={()=>setBlogEditDiv(false)} style={{height:"40px",width:"140px",borderColor:"#FF8427",color:"#FF8427",borderRadius:"20px"}}>Cancel</button>
                    </div>
                    <div className='col-2'>

                    {
                    showhide==='publishnow' && (
                     <button className='createeventbtn post-host-Name' onClick={submitFormDatablog} style={{height:"40px",width:"140px",backgroundColor:"#FF8427",borderColor:"#FF8427",color:"#ffffff",borderRadius:"20px",fontWeight:"500",marginLeft:"17px"}}>Publish Blog</button> 
                  )

                }

               {
                    showhide==='schedulelater' && (
                      <div>
                     <button className='createeventbtn post-host-Name' onClick={submitFormDatablog} style={{height:"40px",width:"140px",backgroundColor:"#FF8427",borderColor:"#FF8427",color:"#ffffff",borderRadius:"20px",fontWeight:"500",marginLeft:"17px"}}>Schedule Blog</button> 
                      </div>
                      )

                }
                                  </div>


                  </div>
{/* </form> */}
         
      </div>
    </div>
     </div>
     <div className={KycEditDiv ? "row":"hide row"}>

     <div className="popup-boxnews">
          <div className="boxnews" style={{height:"100vh", marginTop: "10px"}}>
                            <div className="topnewsrow"style={{paddingTop:"11px",paddingBottom:"7px",backgroundColor:"#FF84270D",borderColor:"#FF84270D"}} >
                            <div className="row " id="sharediv"> 
          
                              <span className='post-host-Name ' style={{fontSize:"15px",textAlign:"left"}}><FaRegUser size="1.3em"/> Share an KYC(Know Your Community)</span>
                              </div>
                            </div>
                  {/* <form onSubmit={submitFormData}> */}
                  <div className='row'>
                    <div className='col-6'>
                    <div className="row margintop3">
                    <div className="col-10 textleft whitenowrap"><span className="lbl ">Author Name</span>
                    <div className=" textleft "><span className="post-host-Name "style={{color:"#212529",fontSize:"13px"}}>{kyc?.authorName}</span></div>
                    </div>
                </div>
                <div className="row margintop1">
                    <div className="col-10 textleft mt-1"><span className="lbl whitenowrap post-host-Name "style={{color:"#626262",fontSize:"13px"}}> KYC Person</span>
                    <div className=" textleft form-group">
                 <input type="text" class=" form-control" placeholder='First Name Middle Name Last Name' value={kyc?.kycPerson} name='kycPerson' onChange={handleChangekyc} style={{fontSize:"13px"}}/>
                  </div>
                  </div>
                  <div className='col-1 margintop'><FaInfoCircle color='#B5924C' size='1.2em'data-tip data-for="registerTip" className='kycinfo' style={{marginTop:"7px"}}/></div>
                  <ReactTooltip id="registerTip" place="right" backgroundColor='#B5924C' class='tooltip' effect="solid">
                    Name of person who you are writing for
                 </ReactTooltip>
                </div>
                <div className='row '>
               <div className="col-7">
               <span style={{ color: "red",fontSize:"12px" }} className={MiddleNameEroor?"":'hide'}>  Contains only charecters</span>

                                    {errorkyc && kyc.kycPerson == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                           Kyc Person  is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    {errorkyc &&  kyc.kycPerson.length >75 ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                        Kyc Person  cannot exceed more than 75 characters.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div> 
  <div className="row ">
                    <div className="col-5 textleft "><span className=" whitenowrap post-host-Name "style={{color:"#626262",fontSize:"13px"}}> Era</span>
                    <div className=" textleft form-group">
                 <select   id="inputState" name='from'   
           onChange={handleChangekyc}
           value={kyc?.from}
           className="form-control textboxselect" style={{fontSize:'12px',textTransform:"capitalize"}}>
                          <option selected value="">Year(From)</option>
                {
                  
                  YearData.map((result) =>(
                  <option value={result.year}>{result.year}</option>
                  ) )

                }
               
            </select>
                 
                  </div>
                  </div>
                  <div className="col-5 margintop textleft form-group">
                  <select   id="inputState" name='to'   
           onChange={handleChangekyc}
           value={kyc?.to}
           className="form-control textboxselect" style={{fontSize:'12px',textTransform:"capitalize"}}>
                          <option selected value="">Year(To)</option>
                          <option  value="Present">Present</option>

                {
                  
                  YearData.map((result) =>(
                  <option value={result.year}>{result.year}</option>
                  ) )

                }
               
            </select>                 
                  </div>
                  
                </div>
                <div className='row '>
               <div className="col-9">

                                    {errorkyc && kyc.from == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                           Year From is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    {errorkyc && kyc.to == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                           Year To is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    
                                </div>
                                
                                
  </div> 
  <div className="row ">
                    <div className="col-10 textleft "><span className=" post-host-Name "style={{color:"#626262",fontSize:"13px"}}> Achivements</span>
                    <div className=" textleft form-group">
                
                    <textarea class="form-control" name='achievements' value={kyc?.achievements}  id="exampleFormControlTextarea1" rows="3" placeholder="Enter Achivements or Contribution" onChange={handleChangekyc} style={{fontSize:"12px"}}/>

                 
                  </div>
                  
                  </div>
                </div>
                <div className='row '>
               <div className="col-8">

                                    {errorkyc && kyc.achievements == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                           Achivements  is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    
                                </div>
                                
                                
  </div> 
                <div className="row ">
                    <div className="col-10 textleft "><span className=" whitenowrap post-host-Name "style={{color:"#626262",fontSize:"13px"}}> Category</span>
                    <div className=" textleft form-group">
                 <select   id="inputState" name='category'  
                 value={kyc?.category} 
           onChange={handleChangekyc}
           className="form-control textboxselect" style={{fontSize:'12px',textTransform:"capitalize"}}>
                          <option selected value="">Please Select</option>
                          <option  value="Community Service">Community Service</option>
                          <option  value="Personal Achivement">Personal Achivement</option>
                          <option  value="Social Contribution">Social Contribution</option>
                          <option  value="Entrepreneurship">Entrepreneurship</option>
                          <option  value="Education">Education</option>
                          <option  value="Medicine">Medicine</option>
                          <option  value="Art & Culture">Art & Culture</option>
                          <option  value="Sports">Sports</option>
                          <option  value="Other">Other</option>
                
               
            </select>
            <div className={kyc.category=="Other"?'margintop':"hide"}>
              {
    kyc.category=="others" && (
      <input type="text" className=" form-control" id="fst"
         name="others"
         placeholder="Category"
         onChange={handleChangekyc}
         value={kyc.others}
         style={{fontSize:'12px',textTransform:"capitalize"}}    />
    )
    }
            </div>
                 
                  </div>
                  </div>
                  
                  
                </div>
                <div className='row '>
               <div className="col-8">

                                    {errorkyc && kyc.category == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                           Category  is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    {errorkyc  && kyc.category=="" && kyc.category=='others' ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                        Other Category is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div> 
  <div className="row margintop1">
                    <div className="col-10  textleft"><span className=" post-host-Name "style={{color:"#626262",fontSize:"13px"}}>Image Source</span>
                    <div className=" textleft form-group">
                    <input type="text" class=" form-control" placeholder='Add Image Source' value={kyc?.imageSource} onChange={handleChangekyc} name='imageSource' style={{fontSize:"12px"}}/>
                   </div>
                   </div>

                   <div className='col-1 margintop'>
                    <FaInfoCircle color='#B5924C' data-tip data-for="registerTip1" size='1.2em' className='kycinfo'/></div>
                   <ReactTooltip id="registerTip1" place="right" backgroundColor='#B5924C' class='tooltip width' effect="solid">
                 References website where image is taken from
                 </ReactTooltip>
                </div>
                <div className='row '>
               <div className="col-9">
                                    {errorkyc && kyc.imageSource == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                           Image Source  is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    {errorkyc &&  kyc.imageSource.length >100 ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                        ImageSource  cannot exceed more than 100 characters.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                            </div> 
  </div>
  <div className='col-6'>

  <div className="row margintop1">
                    <div className="col-10 textleft"><span className=" post-host-Name "style={{color:"#626262",fontSize:"13px"}}>Banner Image</span>
                    <div className=' margintop1'>
                            <label for="selectFile" name="bannerImage" className="" style={{ backgroudColor: "white" }}   >
                            {
                  imgDatakyc !=null ?(
                    <img id='image' className="playerProfilePic_home_tileblog"
                    src={imgDatakyc} 
               
                />
                  ):
                       ( <img src={attachmentkyc}  className='imagePreview' style={{height:"190px",width:"200px"}} onChange={banerImageChangekyc}/>)
                  }
                           </label>
                     <input id="selectFile" type="file" ref={filerefkyc} onChange={banerImageChangekyc} className="fileupload hide" 
                       name="bannerImage"
                        />
                              </div>
                              <div className={imgDatakyc ==null?'margintpmins1 kycimg-div':"hide"} style={{width:"100px"}}><span className='photoupload-lbl textleft'>Add Banner Image to your kyc</span></div>

                              </div>
                </div>
                
                <div className='row '>
               <div className="col-9">
               {errorkyc && attachmentkyc == null ? (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    Banner Image is required.
                  </span>
                ) : (
                  ""
                )}
                                    
                </div>
                  </div> 
                  <div className="row margintop1" style={{whiteSpace:'nowrap'}}>
                    <div className="col-10 textleft " ><span className=" post-host-Name "style={{color:"#626262",fontSize:"13px"}}>Add References</span></div>
                    {/* <div className="col-5 textleft form-group">
                    <input type="text" class="form-control" placeholder='Add Content Source' name='reference' style={{fontSize:"13px"}}/>
                    </div> */}

                    {/* <div className='col-1'><MdAddBox color='#0000FF' className='kycinfo'  size='1.7em'/>
                    <FaInfoCircle color='#B5924C' size='1.2em' data-tip data-for="registerTip2" className='marginleft2'/></div> */}
                    
                    
                </div>
                <div className='row'>
                <div className='col-12' style={{marginLeft:"-12px"}}>
                    {serviceList.map((singleService, index) => (
      <div className='row' key={index}  style={{whiteSpace:'nowrap',marginTop:"0px"}}>
<div className=' '></div>
      <div  className=' col-8 margintop1'>
      <input type="text" class="form-control" placeholder='Add Content Source' name='reference' style={{fontSize:"13px"}}
        value={singleService.service} // Bind the input field
              onChange={(e) => handleServiceChange(e, index)}
     />
     <div className='row '>
               <div className="col-">
               {referenceError && singleService.service === "" ? (
          <span style={{ color: "red", fontSize: "12px" }}>Reference is required.</span>
        ) : null}
                </div>
                  </div>
     
      </div>
      <div className='col-1 margintop2'>
      <FaInfoCircle color='#B5924C' size='1.1em' data-tip data-for="registerTip2" className=''/>

      <ReactTooltip id="registerTip2" place="right" backgroundColor='#B5924C' class='tooltip width margintop1' effect="solid">
           References website where Content is taken from
           </ReactTooltip>
      </div>
      <div className=' col-1  margintop2'>
      {serviceList.length - 1 === index && serviceList.length < 5 && (
        <BsPlusLg onClick={handleServiceAdd} size='1.1em'/>
                
              )}
      </div>
      <div className=' col-1 margintop2'>
              {serviceList.length !== 1 && (
                <MdOutlineDeleteOutline size='1.5em' onClick={() => handleServiceRemove(index)}/>
                
              )}
      </div>
      
      </div>

        ))}
        </div>
                </div>
  </div>

               
                
               
                
                
                <div className="row margintop1">
                    <div className="col-11 textleft"><span className=" post-host-Name "style={{color:"#626262",fontSize:"13px"}}>Add KYC Content</span>
                    <div className=" textleft form-group">
                    <textarea class="form-control " id="exampleFormControlTextarea1" value={kyc?.kycContent} onChange={handleChangekyc} rows="5" name='kycContent' placeholder="Kyc Content" style={{fontSize:"13px"}}/>
                        </div>
                        </div>
                </div>
                <div className='row '>
               <div className="col-8">
                                    {errorkyc && kyc.kycContent == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                           kyc Content  is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    {errorkyc &&  kyc.kycContent.length >7000 ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                        kyc Content  cannot exceed more than 7000 characters.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                            </div> 

                
                
                 
                <div className="row margintop3">
                <div className="col-3  textleft">
                    {/* <input type="radio" id="radio1" className='radiobtnnews' 
                    name="addblogradio" value="publishnow" onClick={ handleshow }
                    />  
                     <label for="defaultRadio1" className='marginleft2 radiobtn-textblog'>Publish Now</label> */}
                     <label class="radiodivcust radiobtn-text1 post-host-Name "style={{color:"#626262",fontSize:"13px"}}>Publish Now
                     <input type="radio" id="radio1" className='radiobtnnews' 
                    name="publishStatus" value="publishnow" onClick={ handleshowkyc } onChange={handleChangekyc}
                    /> 
                      <span class="checkmark"></span>
           </label>
                    </div>
                    <div className="col-4 margintop1 textleft">
                    {/* <input type="radio" id="radio1" className='radiobtnnews' 
                    onClick={ handleshow }
                    name="addblogradio" value="schedulelater"
                    />  
                     <label for="defaultRadio1" className='marginleft2 radiobtn-textblog'>Schedule for later</label> */}
                     <label class="radiodivcust radiobtn-text1 post-host-Name "style={{color:"#626262",fontSize:"13px"}}>Schedule for later
                     <input type="radio" id="radio1" className='radiobtnnews' 
                    onClick={ handleshowkyc } onChange={handleChangekyc}
                    name="publishStatus" value="schedulelater"
                    />  
                      <span class="checkmark"></span>
           </label>
                    </div>

                 </div>
                 <div className='row '>
               <div className="col-6">
                                    {errorkyc && kyc.publishStatus == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            This  is is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>   
                    
                    
                    
                 {
                    showhidekyc==='schedulelater' && (
                    <div className='row'>
                    <div className='col-5  textleft'>
                     <label  className="form-label schedulforlbl post-host-Name "style={{color:"#626262",fontSize:"13px"}}> Schedule Date </label> 
                     <div className='  textright'>
                     <DatePicker
                    className="adreservdate width299px inputwidth"
                    name='scheduledDate'
                    selected={startDatekyc}
                    onChange={date => handleChangedatekyc(date)}
                    selectsStart // tells this DatePicker that it is part of a range*
                    startDate={startDatekyc}
                    dateFormat="dd  MMM yyyy" 
                    minDate={moment().toDate()}

                />   
                 <div className='row '>
                  <div className=''></div>
               <div className="col-12">
                                    {errorkyc && kyc.scheduledDate == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Date is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                </div>
                                
  </div>              
                     
                     </div>
                    
                     <div className="col-4 textleft"> 
                     <label  className="form-label schedulforlbl post-host-Name "style={{color:"#626262",fontSize:"13px"}}> Schedule Time </label>
                     <div className=''>
                     <input type="time" name='scheduledTime' onChange={handleChangekyc} className=' form-control'/>                                                 
                    </div>
                     <div className='row '>
                  <div className=''></div>
               <div className="col-12">
                                    {errorkyc && kyc.scheduledTime == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Time  is is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>                             
                    </div>
                    </div>

                    )

                    }  
                   

                  </div>

                  <div className='row margintop2'>
                    <div className='col-2'>
                        <button className='cancelbtn post-host-Name' onClick={()=>setKycEditDiv(false)}  style={{height:"40px",width:"140px",borderColor:"#FF8427",color:"#FF8427",borderRadius:"20px"}}>Cancel</button>
                    </div>
                    <div className='col-2'>
                    {
                    showhidekyc==='publishnow' && (
                     <button className='createeventbtn post-host-Name' onClick={submitFormDatakyc}  style={{height:"40px",width:"140px",backgroundColor:"#FF8427",borderColor:"#FF8427",color:"#ffffff",borderRadius:"20px",fontWeight:"500",marginLeft:"17px"}}>Publish KYC</button> 
                  )

                }    
                {
                    showhidekyc==='schedulelater' && (
                      <div>
                     <button className='createeventbtn post-host-Name' onClick={submitFormDatakyc}  style={{height:"40px",width:"140px",backgroundColor:"#FF8427",borderColor:"#FF8427",color:"#ffffff",borderRadius:"20px",fontWeight:"500",marginLeft:"17px"}}>Schedule KYC</button> 
                      </div>
                      )

                }             
                
                 </div>

                  </div>
                {/* </form>   */}
      </div>
    </div>
     </div>
     <div className={JobEditDiv ? "row":"hide row"}>

<div className="popup-boxnews">
              <div className="boxnews" style={{height:"96vh", marginTop: "10px"}}>
                                <div className="topnewsrow"style={{paddingTop:"11px",paddingBottom:"7px",backgroundColor:"#FF84270D",borderColor:"#FF84270D"}} >
                                <div className="row " id="sharediv"> 
              
                                  <span className='post-host-Name ' style={{fontSize:"15px",textAlign:"left"}}><MdOutlineBusinessCenter size="1.3em"/> Share an Looking For Job Post</span>
                                  </div>
                                </div>
                  {/* <form onSubmit={submitFormData}> */}

                  <div className='row'>
                    
                    <div className="row margintop1">
                    <div className="col-5 textleft "><span className="post-host-Name "style={{color:"#626262",fontSize:"13px"}}>Posted by</span>
                    <div className=" textleft "><span className="post-host-Name "style={{color:"#212529",fontSize:"13px"}}>{authlocalstoragedata?.name2}</span></div>
                    </div>
                </div>
                <div className="row margintop1">
                    <div className="col-5 textleft "><span className="post-host-Name "style={{color:"#626262",fontSize:"13px"}}>Hometown</span>
                    <div className=" textleft "><span className="post-host-Name "style={{color:"#212529",fontSize:"13px"}}>{authlocalstoragedata?.homeCity} {authlocalstoragedata?.state}</span></div>
                    </div>
                </div>
                <div className="row margintop1">
                    <div className="col-5 textleft"><span className="post-host-Name "style={{color:"#626262",fontSize:"13px"}}>Current Location</span>
                    <div className=" textleft "><span className="post-host-Name "style={{color:"#212529",fontSize:"13px"}}>{authlocalstoragedata?.currentCity} {authlocalstoragedata?.state}</span></div>
                    </div>
                </div>
              
                
                
                
                <div className="row margintop2">
                    <div className="col-5 textleft" style={{whiteSpace:"wrap"}}><span className="post-host-Name "style={{color:"#626262",fontSize:"13px"}}>Looking for a job opening for</span>
                    <div className=" textleft form-group">
                    <select   id="inputState"  
                className=" form-control "
                name='jobOpeningFor'
                value={job?.jobOpeningFor}
                onChange={handleChangeJob} >
                <option selected value="">Select Category</option>
                <option  value="Analytics Manager">Analytics Manager</option>
                <option  value="Financial Analyst">Financial Analyst</option>
                <option  value="Data Analyst">Data Analyst</option>
                <option  value="Business Analyst">Business Analyst</option>
                <option  value="Charted Accountant">Charted Accountant</option>
                <option  value="Account Executive">Account Executive </option>
                <option  value="Software Developer">Software Developer</option>
                <option  value="Testing Engineer">Testing Engineer</option>
                <option  value="Networking Engineer">Networking Engineer</option>
                <option  value="Java Developer">Java Developer</option>
                <option  value="Frontend  Developer">Frontend  Developer</option>
                <option  value="UI Designer">UI Designer</option>
                <option  value="Graphic Designer">Graphic Designer</option>
                <option  value="Solution Architect">Solution Architect</option>
                <option  value="Data Security Analyst">Data Security Analyst</option>
                <option  value="Machin Learning Engineer">Machin Learning Engineer</option>
                <option  value="Electrical Engineer">Electrical Engineer</option>
                <option  value="Mechanical Engineer">Mechanical Engineer</option>
                <option  value="Technician">Technician</option>
                <option  value="Logistics manager/supply chain manage">Logistics manager/supply chain manager</option>
                <option  value="Marketing manager">Marketing manager</option>
                <option  value="SEO manager">SEO manager</option>
                <option  value="Content writer">Content writer</option>
                <option  value="Social media marketer">Social media marketer</option>
                <option  value="Product manager">Product manager</option>
                <option  value="Sales manager">Sales manager</option>
                <option  value="business developer">business developer</option>
                <option  value="Customer service executive">Customer service executive</option>
                <option  value="Teacher">Teacher</option>
                <option  value="Librarian">Librarian</option>
                <option  value="Counsellor">Counsellor</option>
                <option  value="HR manager">HR manager</option>
                <option  value="legal officer">legal officer</option>
                <option  value="Garment designer/textile designer">Garment designer/textile designer</option>
                <option  value="Jewellery designer">Jewellery designer</option>
                <option  value="Architect/interior designer">Architect/interior designer</option>
                <option  value="Travel agent">Travel agent</option>
                <option  value="Branch head/branch manager">Branch head/branch manager</option>
                <option  value="Healthcare professional/Doctor">Healthcare professional/Doctor</option>
                <option  value="Operations manager">Operations manager</option>
                <option  value="Insurance advisor">Insurance advisor</option>Food runner
                <option  value="Food runner">Food runner</option>
                <option  value=" Dishwasher"> Dishwasher</option>
                <option  value="Grill cook">Grill cook</option>
                <option  value="Prep cook">Prep cook</option>
                <option  value="Pantry cook"> Pantry cook</option>         
                <option  value="Baker"> Baker</option>
                <option  value="Food and beverage manager">Food and beverage manager</option>
                <option  value="Executive chef">Executive chef</option>
                <option  value="Homemaker"> Homemaker</option>
                <option  value="Others"> Others</option>

                <div className={job?.jobOpeningFor=="Others"?'margintop':"hide"}>
              {
    job?.jobOpeningFor=="Others" && (
      <input type="text" className=" form-control" id="fst"
         name="others"
         placeholder="Looking for job opening for"
         onChange={handleChangeJob}
         value={job?.others}
         style={{fontSize:'12px',textTransform:"capitalize"}}    />
    )
    }
            </div>

                </select>
                   </div>
                   </div>
                </div>
                 <div className='row '>
               <div className="col-6">
                                    {errorJob && job.jobOpeningFor == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Looking for job Opening is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    {errorJob && jobOpeningFor.others=="" && job.jobOpeningFor=='Others' ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Other  is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    
                                </div>
                                
                                
  </div> 
  <div className="row margintop1">
                    <div className="col-5  textleft"><span className=" post-host-Name "style={{color:"#626262",fontSize:"13px"}}>Total Experience</span>
                    <div className=" textleft form-group">
                    <select   id="inputState"  
                className=" form-control "
                name='experience'
                value={job?.experience}
                onChange={handleChangeJob} >
                <option selected value=""> Please Select</option>
                <option  value="0-1 Years">0-1 Years</option>
                <option  value="1-2 Years">1-2 Years</option>
                <option  value="2-3 Years">2-3 Years</option>
                <option  value="3-4 Years">3-4 Years</option>
                <option  value="4-5 Years">4-5 Years</option>
                <option  value="5-6 Years">5-6 Years</option>
                <option  value="6-7 Years">6-7 Years</option>
                <option  value="7-8 Years">7-8 Years</option>
                <option  value="8-9 Years">8-9 Years</option>
                <option  value="9-10 Years">9-10 Years</option>
                <option  value="More than Years">More than Years</option>

                </select>
                   </div>
                </div>
                </div>
                 <div className='row '>
               <div className="col-6">
                                    {errorJob && job.experience == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Experience is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    
                                </div>
                                
                                
  </div> 
                
  <div className="row margintop1">
                    <div className="col-5  textleft"><span className=" post-host-Name "style={{color:"#626262",fontSize:"13px"}}>Available for job in</span>
                    <div className=" textleft form-group">
                    <select   id="inputState"  
                className=" form-control "
                name='availableIn'
                value={job?.availableIn}
                onChange={handleChangeJob} >
                <option selected value="">Select Category</option>
                <option  value="Immediately Available">Immediately Available</option>
                <option  value="In a week">In a week</option> 
                <option  value="In 30 Day">In 30 Day</option>
                <option  value="In 60 Day">In 60 Day</option>

                </select>
                   </div>
                </div>
                </div>
                 <div className='row '>
               <div className="col-6">
                                    {errorJob && job.availableIn == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Available for job in is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    
                                </div>
                                
                                </div>           
               
  <div className="row margintop1">
                    <div className="col-5 textleft "><span className="post-host-Name "style={{color:"#626262",fontSize:"13px"}}>Write in brief about your skill sets(Optional)</span>
                    <div className=" textleft form-group">
                    <textarea class="form-control" name='skillSets' value={job?.skillSets} maxLength={maxchar} disabled={charLeft === 0} onChange={handleChangeJob} id="exampleFormControlTextarea1" rows="4"  placeholder={`${charLeft} characters left`} style={{fontSize:"13px"}}/>
                    <div style={{float:"right"}}><span className="post-host-Name " style={{color:"#797979",fontSize:"12px"}}>{`${charLeft} characters left`}</span></div>
                        </div>
                        </div>
                </div>
                
                 <div className='row '>
               <div className="col-6">
                                    {errorJob && job.skillSets == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            SkillSets is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    {errorJob && job.skillSets?.length >300  ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Skillsets cannot exceed more than 300 characters..
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div> 
               
  <div className="row margintop1">
                    <div className="col-5 textleft"><span className="post-host-Name "style={{color:"#626262",fontSize:"13px"}}>Resume (Optional)</span>
                    <div className=' '>
                            <label for="selectFile" name="fileupload" className="bluetextforlink" style={{ backgroudColor: "white" }}   >
                      Upload Resume
                           </label>
                     <input id="selectFile" type="file"  className="fileupload hide" 
                       name="bannerImage"  accept='.doc,.docx,application/pdf'  onChange={banerImageChangeJob}
                        />
                              </div>
                              </div>
                              <div className='col-4'>
                              <span className='event-sub-detail textShowondotBuss'style={{width:"230px",marginTop:"5px"}}>{selectedFilenameadharFront?.name}</span> 

                              </div>
                </div>
                
                
                <div className='row '>
               <div className="col-6">
                                    
                  <span className={fileSizeErr?"":"hide"} style={{ color: "red",fontSize:"12px" }}>
                       File with maximum size of 3MB is allowed                                        </span>
                                    
                                    
                                </div>
                                
                                
  </div> 
               
                <div className="row margintop1">
                    <div className="col-5 textleft "><span className="post-host-Name "style={{color:"#626262",fontSize:"13px"}}>Email Id</span>
                    <div className=" textleft ">
                        {/* {showMobileShow==true ?
                        ( */}
                        <span className="post-host-Name "style={{color:"#212529",fontSize:"13px"}}>{authlocalstoragedata?.emailId}</span>
                        {/* ):
                        (<span className="bluetextforlink" style={{fontSize:"12px"}} onClick={handleMobileshow}>View</span>)} */}
                        </div>
                        </div>
                </div>
                <div className="row margintop1">
                    <div className="col-5 textleft "><span className="post-host-Name "style={{color:"#626262",fontSize:"13px"}}>Mobile No.</span>
                    <div className=" textleft ">
                    {/* {showEmailShow==true ? */}
                    {/* ( */}
                      <span className="post-host-Name "style={{color:"#212529",fontSize:"13px"}}>{authlocalstoragedata?.mobileNumber}</span>
                      {/* ):
                        (<span className="bluetextforlink" style={{fontSize:"12px"}} onClick={handleEmailshow}>View</span>
                        )}                        */}
                         </div>
                         </div>
                </div>
                
                  </div>
                <div className='row  '>
                    <div className='col-2'>
                        <button className='cancelbtn'  style={{height:"40px",width:"140px",borderColor:"#FF8427",color:"#FF8427",borderRadius:"20px"}}>Cancel</button>
                    </div>
                    <div className='col-5'>

                    
                     <button className='createeventbtn' onClick={submitFormDataJob} style={{height:"40px",width:"195px",backgroundColor:"#FF8427",borderColor:"#FF8427",color:"#ffffff",borderRadius:"20px",fontWeight:"500",marginLeft:"17px"}}>Post Availbility for Job</button> 
                
                     
                                  </div>


                 
           </div>  
{/* </form> */}
         
      </div>
    </div>
</div>

  </div>
  )

}
  

export default Post
