import '../JoinNow/JoinNow.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import React, { useState, useEffect } from "react";
import validator from "validator";
import axios from "axios";
import { useHistory } from "react-router-dom";
import moment, { duration } from 'moment'
import {configData} from "../../Config"
import { AiFillDelete } from 'react-icons/ai';

 
const BasicDetail = ({additional,setadditional,handleBack,handleNext}) => {
  const [startDate, setStartDate] = useState(new Date());

  const handleChangeDate = (e) => {
    setStartDate(e);
    // setStartDate(preState => ({
    //     ...preState,
    //     "birthday": (moment(e).format("dd-MMM-yyyy")),

    //   }))

    setadditional(preState => ({
        ...preState,
        "birthDate": (moment(e).format("DD MMM yyyy")),

    }))

    console.log(additional.birthday);
}

  const [error, setError] = useState(false);

      const submitFormData = (e) => {
        debugger
        e.preventDefault();
    
        // checking if value of first name and last name is empty show error else take to step 2
        if (additional.gender==null) {
            setError(true);
        }
        else if (additional.birthDate ==""||additional.birthDate==null ) {
            setError(true);
        }
        else if (additional.bloodGroup==""||additional.bloodGroup==null) {
          setError(true);
        }
        else if (additional.maritalStatus==null) {
          setError(true);
    
        }
        else if (additional.familyCount==""||additional.familyCount==null) {
            setError(true);
    
        }
        else if (additional.profession==""||additional.profession==null) {
          setError(true);
  
        }
        else if (additional.natureOfBuisness==""||additional.natureOfBuisness==null) {
        setError(true);

       }
       else if (additional.bio==""||additional.bio==null) {
        setError(true);
      }
      else if (additional.bio.length > 300) {
        setError(true);

      }
       
        
    else {
          debugger
          handleNext()
        }
    };


    const handleChange = (e) => {
      debugger;
      const { name, value, type, checked } = e.currentTarget;
     setadditional(preState => ({
          ...preState,
          [name]: type === 'checkbox' ? checked : value
      }));
  }
debugger
const [loaclId, setloaclId] = useState("");
const [localusername, setLocalUsername] = useState("");

useEffect(() => {  debugger
  setLocalUsername(localStorage.getItem("localusername"))
  setloaclId(localStorage.getItem("loaclId"))
    
  
})
debugger
// const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
// console.log("authdata11",authlocalstoragedata)

// if(authlocalstoragedata !=null && authlocalstoragedata?.userAuthenticationId !=null 
//   && authlocalstoragedata?.name1 != null){
//   localStorage.id = authlocalstoragedata?.userAuthenticationId;
//   localStorage.name = authlocalstoragedata?.name1;

// }else{
//   localStorage.name = localStorage.name;

//   localStorage.id = localStorage.id;
// }
 
const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
const JoinNowData = JSON.parse(localStorage.getItem('JoinNowData'));
console.log("authdata11",authlocalstoragedata)

if(authlocalstoragedata !=null && authlocalstoragedata?.userAuthenticationId !=null 
  && authlocalstoragedata?.name1 != null){
  localStorage.id = authlocalstoragedata?.userAuthenticationId;
  localStorage.name = authlocalstoragedata?.name1;
  localStorage.emailId = authlocalstoragedata?.emailId;
  localStorage.mobileNumber = authlocalstoragedata?.mobileNumber;
  localStorage.countryCode = authlocalstoragedata?.countryCode;
  localStorage.status = authlocalstoragedata?.status;




}else{
  localStorage.name = JoinNowData.name;

  localStorage.id = JoinNowData.id;
  localStorage.emailId = JoinNowData.emailId;
  localStorage.mobileNumber = JoinNowData.phoneNo;
  localStorage.countryCode = JoinNowData.countryCode;
  localStorage.status = JoinNowData.status;


}


  //for natur of business
  const [naturOfBusiness, setnaturOfBusiness] = useState([]);

  useEffect(() => {
    loadNaturOfBusiness();
  }, []);

  const loadNaturOfBusiness = async () => {
    const result = await axios.get(configData.ApiUrl+"/user/natureOfBuisness");
    setnaturOfBusiness(result.data.data);
    console.log("busnessgrp",result.data.data)

  };
  //for blood group
  const [bloodGroupData, setbloodGroup] = useState([]);

  useEffect(() => {
    loadBloodGroup();
  }, []);

  const loadBloodGroup = async () => {
    debugger
    const result = await axios.get(configData.ApiUrl+"/user/bloodGroups");
    setbloodGroup(result.data.data);
    console.log("bldgrp",result.data.data)
  };

  return (
    <div className="container ">
      <div className='row'>
        <div className='col-lg-1 '></div>
        <div className='col-lg-10 col-sm-12 col-md-12'>
        <form onSubmit={submitFormData}>
       <div className='row '>
        <div className='col-lg-6 col-sm-12 col-md-6'>
        <div className=" textleft txtleftformobres">
  <span className="matchestxetxt"style={{fontSize:'12px',color:"#797979"}} >Name</span>
  <div className='textleft'>
  <span className="matchestxetxt"style={{fontSize:'14px',color:"#222227",textTransform:"capitalize"}}>{localStorage.name}</span>

  </div>

</div>
        </div>
        <div className='col-lg-6 col-sm-12 col-md-6'>
        <div className=" textleft txtleftformobres">
  <span className="matchestxetxt"style={{fontSize:'12px',color:"#797979"}} >Gender</span>
  <div className='row textleft'>
    <div className='col-lg-3 col-sm-12 col-md-6'>
    <label class="radiodivcust radiobtn-text1 matchestxetxt" style={{fontSize:'14px',color:"#222227",textTransform:"capitalize",marginLeft:"-12px"}} >Male
<input type="radio" id="defaultRadio5" className='radiobtn' checked={additional.gender=="Male"?true:false} name="gender" onChange={handleChange} value="Male"/>  

                <span class="checkmark"></span>
           </label>
    </div>
    <div className='col-lg-4 col-sm-12 col-md-6'>
    <label class="radiodivcust radiobtn-text1 matchestxetxt" style={{fontSize:'14px',color:"#222227",textTransform:"capitalize",marginLeft:"-5px"}}>Female
<input type="radio" id="defaultRadio6" className='radiobtn' checked={additional.gender=="Female"?true:false} name="gender" onChange={handleChange} value="Female"/>  

                <span class="checkmark"></span>
           </label>
    </div>

  </div>
  {error && additional.gender == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Gender is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}

</div>
        </div>
       </div>

       <div className='row '>
        <div className='col-lg-6 col-sm-12 col-md-6'>
        <div className=" textleft txtleftformobres">
  <span className="matchestxetxt"style={{fontSize:'12px',color:"#797979"}} >Date of Birth</span>
  <div className='textleft'>
  <DatePicker
                                    className="adreservdate datwidth datforreswidth matchestxetxt textboxbackcolorshadow"
                                     style={{fontSize:'14px',color:"#222227",textTransform:"capitalize"}}
                                    name='birthDate'
                                    //required
                                    //selected={startDate}
                                    onChange={date=>handleChangeDate(date)}
                                    //onChange={date => setStartDate(date)}

                                    selectsStart // tells this DatePicker that it is part of a range*
                                    placeholderText="Date of Birth"
                                    selected={startDate}

                                    //startDate={startDate}
                                    dateFormat="MMMM  d, yyyy"
                                    //dateFormat="MMMM-d-yyyy"
                                    maxDate={moment().toDate()}
                                    value={additional.birthDate}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                     dropdownMode="select"



                                />
  </div>
  <div>
  {error && additional.birthDate == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Birth Date is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
  </div>

</div>
        </div>
        <div className='col-lg-6 col-sm-12 col-md-6'>
        <div className=" textleft txtleftformobres">
  <span className="matchestxetxt"style={{fontSize:'12px',color:"#797979"}} >Blood Group</span>
 <div>
 <select   id="inputState"  
                className=" form-control matchestxetxt textboxbackcolorshadow"
               style={{fontSize:'14px',color:"#222227",textTransform:"capitalize"}}
                name='bloodGroup'
                onChange={handleChange} 
                value={additional.bloodGroup}
                >
                                   
                                   
                 <option selected value=""> Select </option>

                {
                  bloodGroupData.map((result) =>(
                    <option value={result}>{result}</option>
                    ) )

                }

                
                
                </select>
 </div>
 {error && additional.bloodGroup == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Blood Group is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}

</div>
        </div>
       </div>

       <div className='row margintop3'>
        
        <div className='col-lg-6 col-sm-12 col-md-6'>
        <div className=" textleft txtleftformobres">
  <span className="matchestxetxt"style={{fontSize:'12px',color:"#797979"}} >Marital Status</span>
  <div className='row textleft'>
    <div className='col-lg-3 col-sm-12 col-md-6'>
    <label class="radiodivcust radiobtn-text1 matchestxetxt" style={{fontSize:'14px',color:"#222227",textTransform:"capitalize",marginLeft:"-12px"}}>Single
<input type="radio" id="defaultRadio3"  className='radiobtn' checked={additional.maritalStatus=="Single"?true:false} name="maritalStatus"onChange={handleChange} value="Single"/>  

                <span class="checkmark"></span>
           </label>
    </div>
    <div className='col-lg-3 col-sm-12 col-md-6'>
    <label class="radiodivcust radiobtn-text1 matchestxetxt" style={{fontSize:'14px',color:"#222227",textTransform:"capitalize",marginLeft:"-6px"}} >Married
<input type="radio" id="defaultRadio4" className='radiobtn' checked={additional.maritalStatus=="Married"?true:false} name="maritalStatus" onChange={handleChange} value="Married"/>  

                <span class="checkmark"></span>
           </label>
    </div>

  </div>
  {error && additional.maritalStatus == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                             Marital Status is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}

</div>
        </div>
        <div className='col-lg-6 col-sm-12 col-md-6'>
        <div className=" textleft txtleftformobres">
  <span className="matchestxetxt"style={{fontSize:'12px',color:"#797979"}} >Family Members</span>
  <div className='textleft'>
  <select   id="inputState"  
                className=" form-control matchestxetxt textboxbackcolorshadow" 
                style={{fontSize:'14px',color:"#222227",textTransform:"capitalize"}}
                name='familyCount'
                onChange={handleChange}
                value={additional.familyCount}
                 >
                
                <option selected value=""> Select </option>
               <option value="01">1</option>
                <option value="02">2</option>
                <option value="03">3</option>
                <option value="04">4</option>
                <option value="05">5</option>
                <option value="06">6</option>
                <option value="07">7</option>
                <option value="08">8</option>
                <option value="09">9</option>
                <option value="10">10</option>
                <option value="10+">10+</option>



                </select>
  </div>

</div>
        </div>
       </div>
       
       <div className='row margintop2'>
        
        <div className='col-lg-6 col-sm-12 col-md-6'>
        <div className=" textleft txtleftformobres">
  <span className="matchestxetxt"style={{fontSize:'12px',color:"#797979"}} >Profession</span>
  <div className='row textleft'>
    <div className='col-lg-4 col-sm-12 col-md-6'>
    <label class="radiodivcust radiobtn-text1 matchestxetxt" style={{fontSize:'14px',color:"#222227",textTransform:"capitalize",marginLeft:"-12px"}} >Employed
<input type="radio" id="defaultRadio1" className=' radiobtn' 
checked={additional.profession=="Employed"?true:false} name="profession" onChange={handleChange} value="Employed"/>  

                <span class="checkmark"></span>
           </label>
    </div>
    <div className='col-lg-7 col-sm-12 col-md-6'>
    <label class="radiodivcust radiobtn-text1 matchestxetxt" style={{fontSize:'14px',color:"#222227",textTransform:"capitalize",marginLeft:"-12px"}}>Self Employed/Business
<input type="radio" id="defaultRadio2" className=' radiobtn' 
checked={additional.profession=="Self Employed/Business"?true:false} name="profession" onChange={handleChange} value="Self Employed/Business"/>  

                <span class="checkmark"></span>
           </label>
    </div>
   

  </div>
  <div className='row'>
  <div className='col-lg-4 col-sm-12 col-md-6'>
    <label class="radiodivcust radiobtn-text1 matchestxetxt" style={{fontSize:'14px',color:"#222227",textTransform:"capitalize",marginLeft:"-12px"}}>Others
<input type="radio" id="defaultRadio3" className=' radiobtn' 
checked={additional.profession=="Others"?true:false} name="profession" onChange={handleChange} value="Others"/>  

                <span class="checkmark"></span>
           </label>
    </div>
  </div>
  {error && additional.profession == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                             Profession  is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}

</div>
        </div>
        <div className='col-lg-6 col-sm-12 col-md-6'>
        <div className=" textleft txtleftformobres">
  <span className="matchestxetxt"style={{fontSize:'12px',color:"#797979"}} >Nature Of Business</span>
  <div className='textleft'>
  {
    additional.profession=="Employed"?(
<select   id="inputState"  
                className=" form-control matchestxetxt textboxbackcolorshadow" style={{fontSize:'14px',color:"#222227",textTransform:"capitalize"}}
                name='natureOfBuisness' onChange={handleChange}
                value={additional.natureOfBuisness}
                >
                
            <option selected value="">Please select</option>:
           
            {
                  naturOfBusiness.map((result) =>(
                    <option value={result}>{result}</option>
                    ) )

                }
                
                
               </select>
    ):(
<select   id="inputState"  
                className=" form-control matchestxetxt textboxbackcolorshadow" style={{fontSize:'14px',color:"#222227",textTransform:"capitalize"}}
                name='natureOfBuisness' 
                value={additional.natureOfBuisness}

                onChange={handleChange}>

            <option selected value="">Please select</option>:
            <option value='Property and real estate managers'>Property and real estate managers</option>
                <option value='Farmers and ranchers'>Farmers and ranchers</option>
                <option value='Brickmasons and blockmasons'>Brickmasons and blockmasons</option>
                <option value='Food-service managers'>Food-service managers</option>
              
                 <option value='Painters (construction and maintenance)'>Painters (construction and maintenance)</option>
                <option value='Carpenters'>Carpenters</option>
                <option value='Lodging managers'>Lodging managers</option>
                <option value='Tile and marble setters'>Tile and marble setters</option>
                <option value='Artists and related workers'>Artists and related workers</option>
                <option value='Construction laborers'>Construction laborers</option>
                <option value='Real estate brokers'>Real estate brokers</option>
                <option value='writers'>writers</option>
                <option value='lawyers'>lawyers</option>
                <option value='freelancers'>freelancers</option>
                <option value='insurance agents'>insurance agents</option>
                <option value='salespersons'>salespersons</option>

    </select>
    )
  }
  </div>

</div>
{error && additional.natureOfBuisness == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                             Nature Of Buisness is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}

        </div>
       </div>
       <div className='row margintop1'>
        <div className='col-lg-12 col-sm-12 col-md-12'>
        <span className="matchestxetxt"style={{fontSize:'12px',color:"#797979"}} >Bio</span>
        <div>
        <textarea class="form-control matchestxetxt textboxbackcolorshadow" name='bio' onChange={handleChange} id="exampleFormControlTextarea1" rows="1" placeholder="300 charecters Remaining for Bio"     
              value={additional.bio}
              style={{fontSize:'14px',color:"#222227",textTransform:"capitalize",height:"39px"}}/>
          </div>
          {error && additional.bio == ""||additional.bio == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Bio Content is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    {error && additional.bio?.length >300 ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Bio Content  cannot exceed more than 300 characters..
                                        </span>
                                    ) : (
                                        ""
                                    )}
         </div>

        </div>

        
        
        
        
           
          
          
          <div className="margintop3 row">
            <div className="col-lg-2 col-sm-12 "></div>
            <div className="col-lg-3 col-sm-12 col-md-12 margintop3 textright">
            <button
               className="displynoneprev"
              
              onClick={handleBack}
            >
              Previous
            </button>
            </div>
          <div className="col-lg-2 col-sm-12 col-md-12 margintop3">
          <button
            className="primary-btnn "
              variant="contained"
              color="primary"
              type='submit'
              style={{width:"200px"}}
            >
               Next
            </button>
            
            
          </div>

            
          </div>  

</form>
        </div>
      </div>

    </div>
  );
};
 
export default BasicDetail;