import '../ViewProfile/ReferCommMemberPopup.css'
import {MdFileCopy} from 'react-icons/md'
import {FaShareAltSquare} from 'react-icons/fa'
import React, { useState, useEffect,useRef} from "react";
import validator from "validator";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Countrycode } from '../JoinNow/Countrycode';
import {AiOutlineClose} from 'react-icons/ai'
import MessagePopupDirectory from './MessegePopupDirectory';
import flag from '../../images/india.png';
import subcast from '../../images/subcast.svg';
import refferal from '../../images/refferalsvg.svg';
import cups from '../../images/cups.svg';
import from from '../../images/from.svg';
import radius from '../../images/radius.svg';
import conprof from '../../images/coneect.svg';
import { RiCupFill, RiSendPlaneFill, RiTimeLine } from 'react-icons/ri';
import { MdOutlineArrowDropDown, MdLocationOn, MdNotifications, MdWaterDrop,MdEmail,MdCheckBox } from 'react-icons/md';
import { FaRegComments, FaUserClock, FaFilter, FaInfoCircle,FaSearch, FaRegHandshake, FaEdit, FaBookmark,FaPhoneSquareAlt,FaRegCalendar,FaUsers } from 'react-icons/fa';
import { BsBookmark } from 'react-icons/bs';
import {configData} from "../../Config";
import Avatar from '@mui/material/Avatar';
import { MdMessage } from "react-icons/md";
import { RiUserStarLine } from "react-icons/ri";
import { MdOutlineContacts } from "react-icons/md";
import contactinfo from '../../images/contactinfo.png';
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";




const ViewProfCommunityDirectPop = props => {

  //for session
  // let history = useHistory();

  // const [session ,setSession] = useState(false);

  // const gesessiondata = async () => {
  //    debugger;
  //    axios.get('/checkSession')
      
  //    .then(result =>{
  //      if(result.data.code == 501){
  //        setSession(true)  ;
  //         }
  //     else{
  //          history.push("/Login");

  //     }
  //      debugger;
  //      console.log(result.data.code);
  //    }).catch(error =>{
  //      console.log(error);
  //    })
  //  };

  //        console.log("checksession on campaign",session);

  // useEffect(() => {
  //     gesessiondata();
  //   }, [session]);
  
//for randm color
   const colors = ["#ff8427", "#3498db", "#2ecc71", "#e74c3c", "#9b59b6", "#f1c40f", "#e67e22"];
   const getRandomColor = () => {
    return colors[Math.floor(Math.random() * colors.length)];
  };
  const [bgColor, setBgColor] = useState(""); // Initialize state for background color
  
    useEffect(() => {
      // Set random color only once during the initial render
      setBgColor(getRandomColor());
    }, []);

     const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
     console.log("authdata11",authlocalstoragedata)
    
     const [uerAuthId, setuerAuthId] = useState(authlocalstoragedata?.userAuthenticationId);
     const [uerAuthReferralCode, setuerAuthReferalCode] = useState(authlocalstoragedata?.referralCode);

     let history = useHistory();

     //for new session for userlogin
  

//  const [referData ,setreferData] = useState({
  
//   referralCode: uerAuthReferralCode,
//   firstName: "",
//   lastName: "",
//   countryCode: "",
//   emailId: "",
//   mobileNumber: "",
  
//  });

//  const referChange = (e) => {
//   debugger;
//   const { name, value, type, checked } = e.currentTarget;


//   setreferData(preState => ({
//     ...preState,
//     [name]: type === 'checkbox' ? checked : value
//   }));
// }
// const [contactValidEroor ,setContactValidEroor] = useState(false);
//   const [emailEroor ,setemailEroor] = useState(false);
// const [error, setError] = useState(false);
// //for email
//    const submitFormData = (e) => {
//     debugger
//     e.preventDefault();

//     // checking if value of first name and last name is empty show error else take to step 2
   
//      if (validator.isEmpty(referData.firstName)) {
//         setError(true);
//     }
//    else if (validator.isEmpty(referData.lastName)) {
//       setError(true);
//    }
//    else if (validator.isEmpty(referData.countryCode)) {
//     setError(true);
//   }
//    else if (validator.isEmpty(referData.emailId)) {
//     setError(true);
//    }
//    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(referData.emailId)) {
//     setemailEroor(true);
//   }
//    else if (validator.isEmpty(referData.mobileNumber)) {
//     setError(true);
//    }
//    else if (referData.mobileNumber.length != 10) {
//     setError(true);
  
//   }
//   else if(!/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(referData.mobileNumber)){
//     setContactValidEroor(true);
    
//   }
    
//     else {
//       debugger
//       addReferalinvite();
//     }
// };

// const addReferalinvite =()=>{
  
// const response = axios.post('/subscriber/inviteMember', referData)
//     response.then(() => {
//           toast.success("Innvite Member Successfully!");

//       })
//        response.catch((err) => {
//          toast.warn("Something went wrong")
//        });
//        console.log("response", response?.data)
//        console.log("InviteMember",referData)
      
// }

//for commDirectry list
const [communityData, setcommunityData] = useState([]);

    useEffect(() => {
      loadCommunityData();
    }, []);
  
    const loadCommunityData = async () => {
      debugger
      const result = await axios.get(configData.ApiUrl+'/subscriber/viewProfileById?hostId=' +props.uerAuthId+ '&userId=' +props.idforcommpop);
      setcommunityData(result.data.data);
      console.log("communityData",result.data.data);
    };

    const avtarProf = communityData?.firstName?.substr(0, 2)

if(communityData.mobileVisibility==0){
 var mobileNumberformat = communityData.mobileNumber.substr(0, 2) + 'xxxxxx'
          + communityData.mobileNumber.substr(8, 9);
}
else{
 var mobileNumberformat=communityData.mobileNumber
}

    //for community popup
  const [isOpenMessagePopDirect, setIsOpenMessagePopDirect] = useState(false);
  const [idforpop, setidforpop] = useState(0);
  const [styleForMessgBlockstatusCommView, setstyleForMessgBlockstatusCommView] = useState(null);

  
  const togglePopupMessageDirect = (id,blockStatus) => {
    setIsOpenMessagePopDirect(!isOpenMessagePopDirect);
    setidforpop(id);
    setstyleForMessgBlockstatusCommView(blockStatus)

  }

  const [commDirectoryconReq, setcommDirectoryconReq] = useState(false);

  const addCommDirectoryConnect = async (id) => {
    debugger
    const response = await axios.post(configData.ApiUrl+'/connection/sendConnection?senderId=' + uerAuthId + '&recieverId=' + id)
    if (response.status == 200 && response.data.code) {
      //setcommDirectoryconReq(true)
      loadCommunityData();

    }
    else {
      setcommDirectoryconReq(false)
      alert("Something Went Wrong..!")
    }
  }
  
  const addCommDirectoryBookmark = async (id) => {

    const response = await axios.post(configData.ApiUrl+'/subscriber/bookMark?hostId=' + uerAuthId + '&userId=' + id)
    if (response.status == 200 && response.data.code) {
      //toast.success("Bookmarked Successfully!")
      loadCommunityData();

    }
    else {
      alert("Something Went Wrong..!")
    }
  }
const [userlist, setuserlist] = useState([]);

     useEffect(() => {
       loadUserList();
     }, []);

    // useEffect(() => {
    //   const intervalId = setInterval(() => {
    //     loadUserList();
    //   }, 5000); // Poll every 5 seconds

    //   return () => clearInterval(intervalId);
    // }, []);


    const loadUserList = async () => {
      const result = await axios.get(`${configData.ApiUrl}/messenger/getUsersListMessenger?hostId=${uerAuthId}`);

      setuserlist(result.data.data);
      console.log("getuserlist", result.data.data);

    };

  //for close popup when click on outside
const popupMessagdiv1Ref = useRef(null);
const handleClickOutsideMessagediv1 = (event) => {
  if (popupMessagdiv1Ref.current && !popupMessagdiv1Ref.current.contains(event.target)) {
    props.handleClose();
  }
};
useEffect(() => {
  document.addEventListener('mousedown', handleClickOutsideMessagediv1);
  return () => {
    document.removeEventListener('mousedown', handleClickOutsideMessagediv1);
  };
}, []);
  return (
    <div className="popup-boxrefmember1" >
      <div className="boxviewprof" style={{}} >
      <span className="close-commdirect" onClick={props.handleClose}>x</span>

      <div className=" ">
       <div className='radiussub-div ' style={{backgroundColor:"#ffffff",marginLeft:"-12px",marginRight:"-12px"}}>

       <div className='row'>
        <div className='row margintop2'>
<div className='col-5'>
  <div className='row'>
    <div className='col-4 margintop1'>
    {communityData.profilePicturePath == "" || communityData.profilePicturePath == null ?
                        (<Avatar className="" style={{marginTop:"3px", width: "62px", height: "59px",fontSize:"15px",backgroundColor:bgColor,color:"#ffffff",marginLeft:"-6px" }}>{avtarProf}</Avatar>)
                        : (<img src={communityData.profilePicturePath} id='profpicdisablesave1' className='prof-img-right topprofMdimg' style={{marginLeft:"-8px"}} />)
                      }
    </div>
    <div className='col-6 margintop2 textleft'><span className='matchestxetxt' style={{color:"#212529",fontSize:"14px"}}>{communityData.firstName} {communityData.lastName}</span>
    <div><span className='matchestxetxt' style={{color:"#212529",fontSize:"14px"}}>{communityData.bio} </span></div>
    </div>
  </div>
</div>
<div className='col-4'></div>
<div className='col-3 margintop3'>
  <span className='iconboxshaow'>{communityData.bookmarkStatus == true ? <FaBookmark color='#626262' size='1.2em'  onClick={() => addCommDirectoryBookmark(communityData.id)}   style={{ cursor: "pointer" }} /> : <BsBookmark  style={{ cursor: "pointer" }} color="#555555"  onClick={() => addCommDirectoryBookmark(communityData.id)}  size='1.3em'/>}</span>
  <span className='iconboxshaow' style={{marginLeft:'10px'}}><MdMessage color='#FF8427' size='1.3em'  onClick={() => togglePopupMessageDirect(communityData.id,communityData.blockStatus)}/></span>
  {isOpenMessagePopDirect && <MessagePopupDirectory

handleClose={togglePopupMessageDirect} setuserlist={setuserlist} uerAuthId={uerAuthId} idforpop={idforpop} styleForMessgBlockstatusCommView={styleForMessgBlockstatusCommView} 
/>}
</div>

</div>
      </div>
      </div>

    <div className='radiussub-div boxshadowclass margintop3 ' style={{backgroundColor:"#ffffff",marginLeft:"-12px",marginRight:"-12px",paddingBottom:"15px"}}>
 <div className='row'>
 <div className='row ' >
  <div className='col-10 textleft' style={{marginLeft:"10px"}}><RiUserStarLine color='#626262' size="1.1em"/> <span className='matchestxetxt' style={{color:"#212529",fontSize:"14px",marginLeft:"5px"}}>Personal Information</span></div>
 </div>
 <div className='row textleft'>
   <div className="col-lg-4 margintop2">
                      <div className="row">
                        <div className="col-10">
                        <span className="post-name-btn fontsize-12px" style={{marginLeft:"0px",fontSize:"12px",color:"#626262"}}>CID</span>
                        <div className="" style={{marginTop:"-5px"}}><span className="post-name-btn fontsize-12px" style={{color:"#212529",marginLeft:"0px",fontSize:"12px"}}>{communityData.id}</span></div>
                        </div>
                      </div>
                      <div className="row margintop2">
                        <div className="col-8">
                        <span className="post-name-btn fontsize-12px" style={{marginLeft:"0px",fontSize:"12px",color:"#626262"}}>Sub Caste</span>
                        <div className="" style={{marginTop:"-5px"}}><span className="post-name-btn fontsize-12px" style={{color:"#212529",marginLeft:"0px",fontSize:"12px"}}>{communityData.subCaste}</span></div>
                        </div>
                      </div>
  
                      
  
                </div>
                
               
                <div className="col-lg-4 margintop2">
                      <div className="row">
                        <div className="col-10">
                        <span className="post-name-btn fontsize-12px" style={{marginLeft:"0px",fontSize:"12px",color:"#626262"}}>DOB</span>
                        <div className="" style={{marginTop:"-5px"}}><span className="post-name-btn fontsize-12px" style={{color:"#212529",marginLeft:"0px",fontSize:"12px"}}>{communityData.birthDate}</span></div>
                        </div>
                      </div>
                      <div className="row margintop1">
                        <div className="col-8">
                        <span className="post-name-btn fontsize-12px" style={{marginLeft:"0px",fontSize:"12px",color:"#626262"}}>Works At</span>
                        <div className="" style={{marginTop:"-5px"}}><span className="post-name-btn fontsize-12px" style={{color:"#212529",marginLeft:"0px",fontSize:"12px"}}>{communityData.profession}</span></div>
                        </div>
                      </div>
  
                      
  
                </div>   
                <div className="col-lg-4 margintop3">
                      <div className="row">
                        <div className="col-10">
                        <span className="post-name-btn fontsize-12px" style={{marginLeft:"0px",fontSize:"12px",color:"#626262"}}>Blood Group</span>
                        <div className="" style={{marginTop:"-5px"}}><span className="post-name-btn fontsize-12px" style={{color:"#212529",marginLeft:"0px",fontSize:"12px"}}>{communityData.bloodGroup}</span></div>
                        </div>
                      </div>
                      
  
                      
  
                </div>   
 </div>
 </div>
               </div>
               <div className='radiussub-div boxshadowclass margintop3 ' style={{backgroundColor:"#ffffff",marginLeft:"-12px",marginRight:"-12px",paddingBottom:"15px"}}>
 <div className='row'>
 <div className='row ' >
  <div className='col-10 textleft' style={{marginLeft:"10px"}}><FaPhoneAlt color='#626262' size='1.0em'/> <sup><FaInfoCircle color='#626262' size='0.9em' style={{marginLeft:"-13px",marginTop:"-2px"}}/></sup> <span className='matchestxetxt' style={{color:"#212529",fontSize:"14px",marginLeft:"5px"}}>Contact Information</span></div>
 </div>
 <div className='row textleft'>
   <div className="col-lg-4 margintop2">
                      <div className="row">
                        <div className="col-10">
                        <span className="post-name-btn fontsize-12px" style={{marginLeft:"0px",fontSize:"12px",color:"#626262"}}>Contact Number</span>
                        <div className="" style={{marginTop:"-5px"}}><span className="post-name-btn fontsize-12px" style={{color:"#212529",marginLeft:"0px",fontSize:"12px"}}>{mobileNumberformat}, {communityData.secondaryMobileNumber}</span></div>
                        </div>
                      </div>
                     
  
                      
  
                </div>
                
               
                <div className="col-lg-4 margintop2">
                      <div className="row">
                        <div className="col-10">
                        <span className="post-name-btn fontsize-12px" style={{marginLeft:"0px",fontSize:"12px",color:"#626262"}}>Email</span>
                        <div className="" style={{marginTop:"-5px"}}><span className="post-name-btn fontsize-12px" style={{color:"#212529",marginLeft:"0px",fontSize:"12px"}}>{communityData.emailId}</span></div>
                        </div>
                      </div>
                     
  
                      
  
                </div>   
                 
 </div>
 </div>
               </div>
               <div className='radiussub-div boxshadowclass margintop3 ' style={{backgroundColor:"#ffffff",marginLeft:"-12px",marginRight:"-12px",paddingBottom:"15px"}}>
 <div className='row'>
 <div className='row ' >
  <div className='col-10 textleft' style={{marginLeft:"10px"}}><FaMapMarkerAlt size="1.2em" style={{color:"#626262"}}/>  <span className='matchestxetxt' style={{color:"#212529",fontSize:"14px",marginLeft:"5px"}}>Location Information</span></div>
 </div>
 <div className='row textleft'>
   <div className="col-lg-4 margintop2">
                      <div className="row">
                        <div className="col-10">
                        <span className="post-name-btn fontsize-12px" style={{marginLeft:"0px",fontSize:"12px",color:"#626262"}}>Country</span>
                        <div className="" style={{marginTop:"-5px"}}><span className="post-name-btn fontsize-12px" style={{color:"#212529",marginLeft:"0px",fontSize:"12px"}}>{communityData.homeCountry}</span></div>
                        </div>
                      </div>
                     </div>
                
               <div className="col-lg-4 margintop2">
                      <div className="row">
                        <div className="col-10">
                        <span className="post-name-btn fontsize-12px" style={{marginLeft:"0px",fontSize:"12px",color:"#626262"}}>From</span>
                        <div className="" style={{marginTop:"-5px"}}><span className="post-name-btn fontsize-12px" style={{color:"#212529",marginLeft:"0px",fontSize:"12px"}}>{communityData.homeCity} {communityData.homeState}</span></div>
                        </div>
                      </div>
                     </div>
                     <div className="col-lg-4 margintop2">
                      <div className="row">
                        <div className="col-10">
                        <span className="post-name-btn fontsize-12px" style={{marginLeft:"0px",fontSize:"12px",color:"#626262"}}>Lives In</span>
                        <div className="" style={{marginTop:"-5px"}}><span className="post-name-btn fontsize-12px" style={{color:"#212529",marginLeft:"0px",fontSize:"12px"}}>{communityData.currentCity},{communityData.currentState}</span></div>
                        </div>
                      </div>
                     </div>   
                 
 </div>
 </div>
               </div>
               <div className='radiussub-div boxshadowclass margintop3 ' style={{backgroundColor:"#ffffff",marginLeft:"-12px",marginRight:"-12px",paddingBottom:"15px"}}>
 <div className='row'>
 <div className='row ' >
  <div className='col-10 textleft' style={{marginLeft:"10px"}}><MdOutlineContacts size="1.3em" style={{color:"#626262"}}/>  <span className='matchestxetxt' style={{color:"#212529",fontSize:"14px",marginLeft:"5px"}}>Membership Details</span></div>
 </div>
 <div className='row textleft'>
   <div className="col-lg-4 margintop3">
                      <div className="row">
                        <div className="col-10">
                        <span className="post-name-btn fontsize-12px" style={{marginLeft:"0px",fontSize:"12px",color:"#626262"}}>Member Since</span>
                        <div className="" style={{marginTop:"-5px"}}><span className="post-name-btn fontsize-12px" style={{color:"#212529",marginLeft:"0px",fontSize:"12px"}}>{communityData.joinDate}</span></div>
                        </div>
                      </div>
                     </div>
                
               <div className="col-lg-4 margintop2">
                      <div className="row">
                        <div className="col-10">
                        <span className="post-name-btn fontsize-12px" style={{marginLeft:"0px",fontSize:"12px",color:"#626262"}}>Referrals</span>
                        <div className="" style={{marginTop:"-5px"}}><span className="post-name-btn fontsize-12px" style={{color:"#212529",marginLeft:"0px",fontSize:"12px"}}>{communityData.referralCount}</span></div>
                        </div>
                      </div>
                     </div>
                     <div className="col-lg-4 margintop2">
                      <div className="row">
                        <div className="col-10">
                        <span className="post-name-btn fontsize-12px" style={{marginLeft:"0px",fontSize:"12px",color:"#626262"}}>Cups of Coffee</span>
                        <div className="" style={{marginTop:"-5px"}}><span className="post-name-btn fontsize-12px" style={{color:"#212529",marginLeft:"0px",fontSize:"12px"}}>{communityData.coffeeMeetsAttended}</span></div>
                        </div>
                      </div>
                     </div>   
                 
 </div>
 </div>
               </div>
               <div className="row margintop3 textcenter">
                             <div className="col-6 ">
                             <button className="secondry-btnn"  onClick={props.handleClose} style={{width:"265px"}}> Close</button>
                             
                             </div>
               
                             <div className="col-6" style={{marginLeft:"-20px"}}> 
                               <button className={communityData.connectionStatus == false && communityData.blockStatus == 0 ? "primary-btnn" : "hide"}  onClick={() => addCommDirectoryConnect(communityData.id)} style={{width:"265px"}}>Connect</button>
                               <span className={communityData.blockStatus == 1 ? "communtdrc-cit-text" : "hide"} style={{ marginLeft: "10px", fontSize: "13px", color: "#555555" }} >Blocked</span>
                               <span className={communityData.connectionStatus == true && communityData.connection=="Sent" ? "community-direct-nametxt11" : "hide"} style={{marginLeft:"7px"}}>Connection Request Sent</span>
                               <span className={communityData.connectionStatus == true && communityData.connection=="Recieved" ? "community-direct-nametxt11" : "hide"} style={{marginLeft:"7px"}}>Connection Request Recieved</span>
                               </div>
                               

               
                          
                           
                           </div>
                
                
                
                
                  
                </div>
              </div>
    
                  

                  
     
    </div>
  );
};
 
export default ViewProfCommunityDirectPop;