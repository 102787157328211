import '../UserLandingPages/AddNewsPopup.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import React, { useState, useEffect,useRef } from "react";
import {FaInfoCircle} from 'react-icons/fa';
import {MdAddBox} from 'react-icons/md';
import ReactTooltip from "react-tooltip";
import validator from "validator";
import imgupload from '../../images/imageupload.svg'

import axios from "axios";
import { toast } from "react-toastify";
import moment, { duration } from 'moment'
import {BsPlusLg} from 'react-icons/bs'
import {MdOutlineDeleteOutline} from 'react-icons/md'
import {configData} from "../../Config"
import {AiOutlineClose} from 'react-icons/ai'
import { useHistory } from "react-router-dom";


 
const EditMyprofBasicDetail =( props,subscriber,setSubscriber) => {

  const [serviceList, setServiceList] = useState([{ service: "" }]);
const filearr=[];
  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...serviceList];
    list[index] = e.target.value;
    setServiceList(list);
debugger;


setKyc(preState => ({
       ...preState,
       reference : serviceList
     }))

    // setAppointment(preState => ({
    //   ...preState,
    //   "attachFiles" : e.target.files[0]
    // }))
    filearr.push(e.target.value);
  };

  const handleServiceRemove = (index) => {
    const list = [...serviceList];
    list.splice(index, 1);
    setServiceList(list);
  };

  const handleServiceAdd = () => {
    setServiceList([...serviceList, { service: "" }]);
  };

    const [startDate, setStartDate] = useState(new Date());

    const handleChangedate= (e) => {
      debugger
     setStartDate(e)
     
     seteditprof(preState => ({
      ...preState,
      "birthDate": (moment(e).format("DD MMM yyyy")),

    }))
    }
    const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
console.log("authdata11",authlocalstoragedata)


const [uerAuthId, setuerAuthId] = useState(authlocalstoragedata?.userAuthenticationId)

    const [editprof, seteditprof] = useState({
      id:authlocalstoragedata?.userAuthenticationId,
      firstName: "",
      middleName: "",
      lastName: "",
      religion: "",
      subCaste: "",
      emailId: "",
      joinDate: "",
      gender: "",
      birthDate: "",
      bloodGroup: "",
      maritalStatus: "",
      familyCount: "",
      profession: "",
      natureOfBuisness: "",
      })

      useEffect(()=>{
        loadsubScriber()
    },[])
  
  const loadsubScriber = async () =>{
    const res = await axios.get(`${configData.ApiUrl}/subscriber/getSubscribersById?id=${uerAuthId}`);
    debugger;
    seteditprof(res.data.data);
  props.setSubscriber(res.data.data);

    console.log("getSubscribersById",res.data.data)
  }

      const [imgData, setImgData] = useState(null);
      const fileref = useRef(null);
      

      const [attachment, setAttachment] = useState(null)

      const [error, setError] = useState(false);
      const [MiddleNameEroor ,setMiddleNameEroor] = useState(false);
      const [referenceError, setReferenceError] = useState(false);

      let history = useHistory();

     
      const submitFormData = (e) => {
        debugger
        e.preventDefault();
    
        // checking if value of first name and last name is empty show error else take to step 2
        if (editprof.gender==null) {
            setError(true);
        }
        else if (editprof.birthDate ==""||editprof.birthDate==null ) {
            setError(true);
        }
        else if (editprof.bloodGroup==""||editprof.bloodGroup==null) {
          setError(true);
        }
        else if (editprof.maritalStatus==null) {
          setError(true);
    
        }
        else if (editprof.familyCount==""||editprof.familyCount==null) {
            setError(true);
    
        }
        else if (editprof.profession==""||editprof.profession==null) {
          setError(true);
  
        }
        else if (editprof.natureOfBuisness==""||editprof.natureOfBuisness==null) {
        setError(true);

       }
       else if (editprof.subCaste==""||editprof.subCaste==null) {
        setError(true);

       }
       
       
        
    else {
          debugger
          onSave()
        }
    };

    const [updatedData, setupdatedData] = useState();

    const loadUpdatedData = async () => {
      debugger;
      const result = await axios.get(`${configData.ApiUrl}/getUpdatedData?userId=${authlocalstoragedata.userAuthenticationId}`);
      setupdatedData(result.data.data);
     // var storageUpdate = JSON.parse(localStorage.getItem("authdata"));
      localStorage.setItem("authdata",JSON.stringify(result.data.data));
   
      
   console.log("updatedadata",updatedData)
    }; 
   

    const onSave = (e) => {
      debugger
      const response = axios.post(configData.ApiUrl+'/user/editUserProfile', editprof)
      debugger;
      response.then(() => {
        // alert("user updeted")
        loadsubScriber();
        loadUpdatedData();
        toast.success("Basic Details Updated Successfully");
        props.handleClose();
      })
      response.catch((err) => {
        // alert("Getting error in featching data")
      });
      redirectViewprof();
  
    }
    const redirectViewprof = () => {
      history.push("/ViewProfile");

    }

    const [selected, setSelected] = useState('kyc');

const [isOpen, setIsOpen] = useState(false);
  const togglePopup = () => {
    setIsOpen(!isOpen);
  }

  const [isOpenBlog, setIsOpenBlog] = useState(false);
  const togglePopupBlog = () => {
    setIsOpenBlog(!isOpenBlog);
  }
  const [isOpenkyc, setIsOpenkyc] = useState(false);
  const togglePopupKyc = () => {
    setIsOpenkyc(!isOpenkyc);
  }
  const handleChange = event => {
    setSelected(event.target.value);
    const { name, value, type, checked } = event.target;

    seteditprof(preState => ({
      ...preState,
      [name]: type === 'checkbox' ? checked : value
    }))
    if(event.currentTarget.files != null)
        {
            seteditprof(event.target.files[0]);
        }
        if(editprof.authorName!=undefined){
          seteditprof(preState =>({
            ...preState,
            "authorName":authlocalstoragedata?.name2
          }))
        }
  
        if(editprof.authorName!=undefined){
          seteditprof(preState =>({
            ...preState,
            "hostId":authlocalstoragedata?.userAuthenticationId
          }))
        }

        seteditprof(preState => ({
          ...preState,
          reference : serviceList
        }))
  };
 //for opening fields depends on radio cheked
 const [showhide, setShowhide]= useState("");


 const handleshow= e=>{
   const getshow= e.target.value;
   setShowhide(getshow);
 }

 const [isOpentopjobPop, setisOpentopjobPop] = useState(false);
  const togglePopuptopJobPop = () => {
    setisOpentopjobPop(!isOpentopjobPop);
  }
//for close popup when click on outside
const popupBlogdivRef = useRef(null);
const handleClickOutsideBlogtdiv = (event) => {
  if (popupBlogdivRef.current && !popupBlogdivRef.current.contains(event.target)) {
    props.handleClose();
    setSelected("");
  }
};
useEffect(() => {
  document.addEventListener('mousedown', handleClickOutsideBlogtdiv);
  return () => {
    document.removeEventListener('mousedown', handleClickOutsideBlogtdiv);
  };
}, []);
const [subCastData, setsubCastData] = useState([]);

    useEffect(() => {
      loadsubCast();
    }, []);
  
    const loadsubCast = async () => {
      const result = await axios.get(configData.ApiUrl+"/user/listOfSubCastes");
      setsubCastData(result.data.data);
      console.log("subCast",result.data.data)
    };
    //for blood group
  const [bloodGroupData, setbloodGroup] = useState([]);

  useEffect(() => {
    loadBloodGroup();
  }, []);

  const loadBloodGroup = async () => {
    debugger
    const result = await axios.get(configData.ApiUrl+"/user/bloodGroups");
    setbloodGroup(result.data.data);
    console.log("bldgrp",result.data.data)
  };

  //for natur of business
  const [naturOfBusiness, setnaturOfBusiness] = useState([]);

  useEffect(() => {
    loadNaturOfBusiness();
  }, []);

  const loadNaturOfBusiness = async () => {
    const result = await axios.get(configData.ApiUrl+"/user/natureOfBuisness");
    setnaturOfBusiness(result.data.data);
    console.log("busnessgrp",result.data.data)

  };

  return (
    <div className="popup-boxnews">
      <div className="boxnews" ref={popupBlogdivRef}>
                  <div className="topnewsrow"style={{paddingTop:"9px",paddingBottom:"6px",backgroundColor:"#FF84270D",border:"none"}}>
                  <div className="row">
                <div className="col-4"></div>
                <div className="col-7">
                <span className=" blood-top-text ">Basic Details</span>
                 {/* <MdFileCopy color='#fff' className="marginleft2"style={{color:"#555555"}}/>
                 <FaShareAltSquare color='#fff' className="marginleft5"style={{color:"#555555"}}/> */}
                 </div>
                 <div className='col-1'>
                 <span className="lastpop-top-text" onClick={props.handleClose} style={{cursor:"pointer"}}><AiOutlineClose size="1.3em"/></span>

                 </div>
            </div>
                  </div>
                  <form onSubmit={submitFormData}>
                  <div className='row'>
                    
                    <div className="row margintop3">
                    <div className="col-6 textleft whitenowrap"><span className="matchestxetxt"style={{fontSize:'12px',color:"#797979"}}> Name</span>
                    <div className=" textleft ">
                        <div className=" textleft "><span className="post-host-Name "style={{color:"#212529"}}>{editprof?.firstName} {editprof?.middleName} {editprof?.lastName}</span></div>

                        </div>
                        </div>
                        <div className="col-6 textleft whitenowrap"><span className="matchestxetxt"style={{fontSize:'12px',color:"#797979"}}> CID</span>
                    <div className=" textleft ">
                        <span className="post-host-Name "style={{color:"#212529"}}>{editprof?.id}</span>
                        </div>
                        </div>
                </div>
               
                <div className="row margintop3">
                    <div className="col-6 textleft whitenowrap"><span className="matchestxetxt"style={{fontSize:'12px',color:"#797979"}}> Date of Activation</span>
                    <div className=" textleft ">
                        <span className="post-host-Name "style={{color:"#212529"}}>{editprof.joinDate}</span>
                        </div>
                        </div>
                        <div className="col-6 textleft whitenowrap"><span className="matchestxetxt"style={{fontSize:'12px',color:"#797979"}}> Religion</span>
                    <div className=" textleft ">
                        <span className="post-host-Name "style={{color:"#212529"}}>Hindu</span>
                        </div>
                        </div>
                </div>
                
                
                
                <div className="row margintop3">
                    <div className="col-6 textleft mt-1"><span className="matchestxetxt"style={{fontSize:'12px',color:"#797979"}}>Subcaste</span>
                    <div className=" textleft form-group">
                    <select   id="inputState" name='subCaste' 
                    onChange={handleChange} 
                    // value={editprof?.subCaste}
                    value={subCastData.includes(editprof?.subCaste) ? editprof?.subCaste : "Others"}
            className="form-control textboxselect post-host-Name "style={{color:"#212529",fontSize:"14px"}} 
             >
           
           <option selected value=""> Select </option>

               {
                  
                  subCastData.map((result) =>(
                  <option value={result}>{result}</option>
                  ) )

                }
                <option value="Others">Others</option>
            </select>
            {editprof?.subCaste === "Others" || !subCastData.includes(editprof?.subCaste) ?(
            <div className='margintop3'>
              
      <input type="text" className=" form-control post-host-Name "style={{color:"#212529",fontSize:"14px"}} id="fst"
         name="subCaste"
         placeholder="Sub Caste"
         onChange={handleChange}
         value={editprof?.subCaste}
            />
    
    </div> ) : null }  
                          </div>
                 
                          <div className='row margintpmins1'>
    <div className="col-lg-6 col-sm-12 col-md-12 textcenter">
                                    {error && editprof?.subCaste == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Sub Caste  is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    
                                </div>
                                
                                
  </div>
                </div>
                <div className="col-lg-6  col-sm-12 col-md-12 textleft txtleftformobres" style={{marginTop:"5px"}}>
  <span className="matchestxetxt"style={{fontSize:'12px',color:"#797979"}}>Date of Birth</span>
<div className=" textleft">
<DatePicker
                                    className="adreservdate width336 datforreswidth post-host-Name "style={{color:"#212529",fontSize:"14px"}}
                                    name='birthDate'
                                    //required
                                    //selected={startDate}
                                    onChange={date=>handleChangedate(date)}
                                    //onChange={date => setStartDate(date)}

                                    selectsStart // tells this DatePicker that it is part of a range*
                                    placeholderText="Date of Birth"
                                    selected={startDate}

                                    //startDate={startDate}
                                    dateFormat="MMMM  d, yyyy"
                                    //dateFormat="MMMM-d-yyyy"
                                    maxDate={moment().toDate()}
                                    value={editprof?.birthDate}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                     dropdownMode="select"



                                />
          </div>
          <div className='row '>
    <div className=" col-lg-10 col-sm-12 col-md-12 ">
                                    {error && editprof?.birthDate == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Birth Date is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div> 
          </div>

                </div>
               

  
  
         
  
  <div className="row margintop3">
<div className="col-lg-6 col-md-12 col-sm-12 textleft txtleftformobres">
  <span className="matchestxetxt"style={{fontSize:'12px',color:"#797979"}}>Gender</span>
{/* <div className="col-1 textright">
<input type="radio" id="defaultRadio5" className='radiobtn' name="gender" onChange={handleChange} value="Male"/>  
</div> */}
<div className='row'>
<div className="col-lg-3 col-md-12 col-sm-12 margintop1 textleft">
{/* <label for="defaultRadio5" className='gendertxt popinsfont'>Male</label>   */}
<label class="radiodivcust radiobtn-text1 matchestxetxt" style={{fontSize:'14px',color:"#222227",textTransform:"capitalize",marginLeft:"-12px"}} >Male
<input type="radio" id="defaultRadio5" className='radiobtn' checked={editprof?.gender=="Male"?true:false} name="gender" onChange={handleChange} value="Male"/>  

                <span class="checkmark"></span>
           </label>
</div>
{/* <div className="col-1 marginleftmins1 textright">
<input type="radio" id="defaultRadio6" className='radiobtn' name="gender" onChange={handleChange} value="Female"/>  
</div> */}
<div className="col-lg-3 col-sm-12 col-md-12 margintop1 textleft">
{/* <label for="defaultRadio6" className='gendertxt popinsfont'>Female</label>   */}
<label class="radiodivcust radiobtn-text1 matchestxetxt" style={{fontSize:'14px',color:"#222227",textTransform:"capitalize",marginLeft:"-12px"}}>Female
<input type="radio" id="defaultRadio6" className='radiobtn' checked={editprof?.gender=="Female"?true:false} name="gender" onChange={handleChange} value="Female"/>  

                <span class="checkmark"></span>
           </label>
</div>
</div>
<div className='row '>
    <div className=" col-lg-10 col-sm-12 col-md-12 ">
                                    {error && editprof.gender == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Gender is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>
</div>
<div className="col-lg-6 col-sm-12 col-md-12 textleft txtleftformobres" style={{marginTop:"px"}}>
  <span className="matchestxetxt"style={{fontSize:'12px',color:"#797979"}}>Blood Group</span>
<div className="textleft">
<select   id="inputState"  
                className=" form-control post-host-Name" style={{color:"#212529",fontSize:"14px"}}
                name='bloodGroup'
                onChange={handleChange} 
                value={editprof?.bloodGroup}
                >
                                   
                                   
                 <option selected value=""> Select </option>

                {
                  bloodGroupData.map((result) =>(
                    <option value={result}>{result}</option>
                    ) )

                }

                
                
                </select>
</div>
<div className='row '>
    <div className=" col-lg-8 col-sm-12 col-md-12 ">
                                    {error && editprof.bloodGroup == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Blood Group is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>
  
</div>
  
         </div>
        
  
  
        
  <div className="row margintop3">
<div className="col-lg-6 col-sm-12 col-md-12 textleft txtleftformobres">
  <span className="matchestxetxt"style={{fontSize:'12px',color:"#797979"}}>Marital Status</span>

{/* <div className="col-1 textright">
<input type="radio" id="defaultRadio3"  className='radiobtn' name="maritalStatus"onChange={handleChange} value="Single"/>  
</div> */}
<div className='row'>
<div className="col-lg-3 col-sm-12 col-md-12 margintop1 textleft">
{/* <label for="defaultRadio3" className='gendertxt popinsfont'> Single </label>   */}
<label class="radiodivcust radiobtn-text1 matchestxetxt" style={{fontSize:'14px',color:"#222227",textTransform:"capitalize",marginLeft:"-12px"}} >Single
<input type="radio" id="defaultRadio3"  className='radiobtn' checked={editprof.maritalStatus=="Single"?true:false} name="maritalStatus"onChange={handleChange} value="Single"/>  

                <span class="checkmark"></span>
           </label>
</div>
{/* <div className="col-1 marginleftmins1 textright">
<input type="radio" id="defaultRadio4" className='radiobtn' name="maritalStatus" onChange={handleChange} value="Married"/>  
</div> */}
<div className="col-lg-3 col-sm-12 col-md-12 margintop1 textleft">
{/* <label for="defaultRadio4" className='gendertxt popinsfont'> Married </label>   */}
<label class="radiodivcust radiobtn-text1 matchestxetxt" style={{fontSize:'14px',color:"#222227",textTransform:"capitalize",marginLeft:"-12px"}} >Married
<input type="radio" id="defaultRadio4" className='radiobtn' checked={editprof.maritalStatus=="Married"?true:false} name="maritalStatus" onChange={handleChange} value="Married"/>  

                <span class="checkmark"></span>
           </label>
</div>
</div>
<div className='row '>
    <div className=" col-lg-8 col-sm-12 col-md-12 ">
                                    {error && editprof.maritalStatus == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                             Marital Status is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>   
</div>
<div className="col-lg-6 textleft txtleftformobres" style={{marginTop:"px"}}>
  <span className="matchestxetxt"style={{fontSize:'12px',color:"#797979"}}>Family Members</span>
<div className=" textleft">
<select   id="inputState"  
                className=" form-control post-host-Name "style={{color:"#212529",fontSize:"14px"}}
                name='familyCount'
                onChange={handleChange}
                value={editprof?.familyCount}
                 >
                
                <option selected value=""> Select </option>
               <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="10+">10+</option>



                </select>
</div>  
<div className='row '>
    <div className=" col-lg-8 col-sm-12 col-md-12 ">
                                    {error && editprof.familyCount == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                             Family Count is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div> 
</div>

         </div>

        
  
  
  
  
  <div className="row whitespacenowrap  margintop1 txtleftformobres " style={{marginLeft:"px"}}>
<div className="col-lg-12 col-sm-12 col-md-12 textleft txtleftformobres">
  <span className="lbl matchestxetxt"style={{fontSize:'12px',color:"#797979"}}>Profession</span>
<div className='row'>
<div className="col-lg-2 col-sm-12 col-md-12 margintop1 textleft">
{/* <input type="radio" id="defaultRadio1" className=' radiobtn' name="profession" onChange={handleChange} value="Employed"/>  

<label for="defaultRadio1" className='marginleft2 gendertxt popinsfont'> Employed </label>   */}
<label class="radiodivcust radiobtn-text1  matchestxetxt" style={{fontSize:'14px',color:"#222227",textTransform:"capitalize",marginLeft:"-12px"}} >Employed
<input type="radio" id="defaultRadio1" className=' radiobtn' 
checked={editprof.profession=="Employed"?true:false} name="profession" onChange={handleChange} value="Employed"/>  

                <span class="checkmark"></span>
           </label>
</div>
<div className="col-lg-3 col-sm-12 col-md-12 margintop1 ">
{/* <input type="radio" id="defaultRadio2" className='marginleftmins radiobtn' name="profession" onChange={handleChange} value="Self Employed/Business"/>   */}

{/* <label for="defaultRadio2" className='marginleft2 gendertxt popinsfont'> Self Employed/Business </label>   */}
<label class="radiodivcust radiobtn-text1 matchestxetxt" style={{fontSize:'14px',color:"#222227",textTransform:"capitalize",marginLeft:"-12px"}}>Self Employed/Business
<input type="radio" id="defaultRadio2" className=' radiobtn' 
checked={editprof.profession=="Self Employed/Business"?true:false} name="profession" onChange={handleChange} value="Self Employed/Business"/>  

                <span class="checkmark"></span>
           </label>
</div>
<div className="col-lg-3 col-sm-12 col-md-12 margintop1 textleft">
{/* <input type="radio" id="defaultRadio3" className='marginleftmins1 radiobtn' name="profession" onChange={handleChange} value="Retired"/>   */}

{/* <label for="defaultRadio3" className='marginleft2 gendertxt popinsfont'> Retired </label>   */}
<label class="radiodivcust radiobtn-text1 matchestxetxt" style={{fontSize:'14px',color:"#222227",textTransform:"capitalize",marginLeft:"5px"}}>Others
<input type="radio" id="defaultRadio3" className=' radiobtn' 
checked={editprof.profession=="Others"?true:false} name="profession" onChange={handleChange} value="Others"/>  

                <span class="checkmark"></span>
           </label>
</div>
</div>
</div>
<div className='row '>
    <div className=" col-lg-8 col-sm-12 col-md-12 ">
                                    {error && editprof.profession == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                             Profession  is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>   
         </div>
         
  
  <div className="row margintop1">
<div className="col-lg-6 col-sm-12 col-md-12 textleft txtleftformobres" style={{marginTop:"px"}}>
  <span className="matchestxetxt"style={{fontSize:'12px',color:"#797979"}}>Nature Of Business</span>
<div className=" textleft">
  {
    editprof.profession=="Employed"?(
<select   id="inputState"  
                className=" form-control post-host-Name "style={{color:"#212529",fontSize:"14px"}}
                name='natureOfBuisness' onChange={handleChange}
                value={editprof?.natureOfBuisness}
                >
                
            <option selected value="">Please select</option>:
           
            {
                  naturOfBusiness.map((result) =>(
                    <option value={result}>{result}</option>
                    ) )

                }
                
                
               </select>
    ):(
<select   id="inputState"  
                className=" form-control post-host-Name "style={{color:"#212529",fontSize:"14px"}}
                name='natureOfBuisness' 
                value={editprof?.natureOfBuisness}

                onChange={handleChange}>

            <option selected value="">Please select</option>:
            <option value='Property and real estate managers'>Property and real estate managers</option>
                <option value='Farmers and ranchers'>Farmers and ranchers</option>
                <option value='Brickmasons and blockmasons'>Brickmasons and blockmasons</option>
                <option value='Food-service managers'>Food-service managers</option>
              
                 <option value='Painters (construction and maintenance)'>Painters (construction and maintenance)</option>
                <option value='Carpenters'>Carpenters</option>
                <option value='Lodging managers'>Lodging managers</option>
                <option value='Tile and marble setters'>Tile and marble setters</option>
                <option value='Artists and related workers'>Artists and related workers</option>
                <option value='Construction laborers'>Construction laborers</option>
                <option value='Real estate brokers'>Real estate brokers</option>
                <option value='writers'>writers</option>
                <option value='lawyers'>lawyers</option>
                <option value='freelancers'>freelancers</option>
                <option value='insurance agents'>insurance agents</option>
                <option value='salespersons'>salespersons</option>

    </select>
    )
  }
          
</div>
 <div className='row '>
    <div className=" col-lg-8 col-sm-12 col-md-12 ">
                                    {error && editprof.natureOfBuisness == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                             Nature Of Buisness is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div> 
</div>

         </div>
        
  
  
  
                   

                  </div>

                  <div className='row margintop'style={{}}>
                    <div className='col-4'></div>
                    <div className='col-2'>
                        <button className='secondry-btnn'  onClick={props.handleClose} style={{height:"40px",width:"px"}}>Cancel</button>
                    </div>
                    <div className='col-2'>
                    
                
                      <div>
                     <button className='primary-btnn' type='submit' style={{height:"40px",width:"px"}}>Update</button> 
                      </div>
                            
                
                 </div>

                  </div>
                </form>  
      </div>
    </div>
  );
};
 
export default EditMyprofBasicDetail;