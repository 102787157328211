import './ReferCommMemberPopup.css'
import {MdFileCopy} from 'react-icons/md'
import {FaShareAltSquare} from 'react-icons/fa'
import React, { useState, useEffect,useRef} from "react";
import validator from "validator";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Countrycode } from '../JoinNow/Countrycode';
import {AiOutlineClose} from 'react-icons/ai'
import {configData} from "../../Config";
import referimg from "../../images/c7f2a2300c973f33ae413cdcdcf63c1e.gif";
import { BsDot } from "react-icons/bs";
import ReferInviteCommMemberPopup from './ReferInviteCommMemberPopup';

 
const ReferInviteDetailContentPop = props => {

  //for session
  // let history = useHistory();

  // const [session ,setSession] = useState(false);

  // const gesessiondata = async () => {
  //    debugger;
  //    axios.get('/checkSession')
      
  //    .then(result =>{
  //      if(result.data.code == 501){
  //        setSession(true)  ;
  //         }
  //     else{
  //          history.push("/Login");

  //     }
  //      debugger;
  //      console.log(result.data.code);
  //    }).catch(error =>{
  //      console.log(error);
  //    })
  //  };

  //        console.log("checksession on campaign",session);

  // useEffect(() => {
  //     gesessiondata();
  //   }, [session]);
  

    const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
    console.log("authdata11",authlocalstoragedata)
    
    const [uerAuthId, setuerAuthId] = useState(authlocalstoragedata?.userAuthenticationId);
    const [uerAuthReferralCode, setuerAuthReferalCode] = useState(authlocalstoragedata?.referralCode);


 





    //for close popup when click on outside
  const popupSearchRef = useRef(null);
  const handleClickOutsideSearchdiv = (event) => {
    if (popupSearchRef.current && !popupSearchRef.current.contains(event.target)) {
      props.handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideSearchdiv);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideSearchdiv);
    };
  }, []);

  const [isOpenMessagePop, setIsOpenMessagePop] = useState(false);
      const togglePopupMessage = () => {
        setIsOpenMessagePop(!isOpenMessagePop);
      }

  return (
    <div className="popup-boxrefmember11">
          <div className="boxrefmember111" ref={popupSearchRef} style={{width:"42%",height:"80vh",maxHeight:"90vh",marginTop:"102px"}}>
            <div className="topnewsrow " style={{backgroundColor:"#FF84270D",border:"none"}}>
                <div className="row">
                    <div className="col-4"></div>
                    <div className="col-7">
                    <span className="post-host-Name" style={{color:"",fontSize:"15px",marginLeft:"px"}}>Refer a Member</span>
                <span className="post-host-Name "style={{color:"#00C67E",marginLeft:"5px",fontSize:"12px"}}>(Referral ID - {authlocalstoragedata?.referralCode})</span>
                     {/* <MdFileCopy color='#fff' className="marginleft2"style={{color:"#555555"}}/>
                     <FaShareAltSquare color='#fff' className="marginleft5"style={{color:"#555555"}}/> */}
                     </div>
                     <div className='col-1'>
                     <span className="lastpop-top-text" onClick={props.handleClose} style={{cursor:"pointer"}}><AiOutlineClose size="1.3em"/></span>
    
                     </div>
                </div>
               
            
            </div>
    
            <div className='row margintop3'>
                <div className='col-12'>
                    <span className="post-host-Name "style={{color:"#626262",marginLeft:"px",fontSize:"14px"}}>Invite a Valued Community Member and Grow Our Network!</span>
                <div className=''><span className="post-host-Name "style={{color:"#797979",marginLeft:"px",fontSize:"14px",fontWeight:"400",fontStyle:"italic"}}>Know someone who would love to be a part of our Naidu Community? Refer them today! </span></div>
                <img src={referimg} style={{width:"160px",marginTop:"-17px"}}/>
                <div className='' style={{marginTop:"-9px"}}><span className="post-host-Name "style={{color:"#626262",marginLeft:"px",fontSize:"12px",fontWeight:"500",marginTop:"-7px "}}>Why Refer? </span></div>
                <div className=''><BsDot/><span className="post-host-Name "style={{color:"#626262",marginLeft:"px",fontSize:"12px",fontWeight:"400",fontStyle:""}}>

Help build a stronger community with more connections.
</span></div>
<div className=''><BsDot/><span className="post-host-Name "style={{color:"#626262",marginLeft:"px",fontSize:"12px",fontWeight:"400",fontStyle:""}}>

Share access to exclusive events, resources, and networking opportunities.
</span></div>
<div className=''><BsDot/><span className="post-host-Name "style={{color:"#626262",marginLeft:"px",fontSize:"12px",fontWeight:"400",fontStyle:""}}>

For every successful referral, you contribute to making our platform richer and more vibrant! </span></div>
<div className=''><span className="post-host-Name "style={{color:"#626262",marginLeft:"px",fontSize:"12px",fontWeight:"500",marginTop:"-5px "}}>How it Works: </span></div>
<div className=''><BsDot/><span className="post-host-Name "style={{color:"#626262",marginLeft:"px",fontSize:"12px",fontWeight:"400",fontStyle:""}}>
Send your friend a personalized invite link.
</span></div>
<div className=''><BsDot/><span className="post-host-Name "style={{color:"#626262",marginLeft:"px",fontSize:"12px",fontWeight:"400",fontStyle:""}}>
They sign up and complete their profile.
</span></div>
<div className=''><BsDot/><span className="post-host-Name "style={{color:"#626262",marginLeft:"px",fontSize:"12px",fontWeight:"400",fontStyle:""}}>

You both enjoy the benefits of a thriving community together!
</span></div>
<div className=''><BsDot/><span className="post-host-Name "style={{color:"#626262",marginLeft:"px",fontSize:"12px",fontWeight:"400",fontStyle:""}}>

Let’s make our community even bigger and better!</span></div>
                </div>
            </div>
            
        
              <div className='row margintop3'>
                    <div className='col-3'></div>
                    <div className='col-3'>
                        <button className='secondry-btnn'  onClick={props.handleClose} style={{width:'130px',height:"40px",fontSize:"13px"}}>Cancel</button>
                    </div>
                    <div className='col-3'>
                    <button className='primary-btnn' style={{width:'130px',height:"40px",fontSize:"13px",marginLeft:"15px"}} onClick={togglePopupMessage}>Refer</button>
                  </div>
                  {isOpenMessagePop && <ReferInviteCommMemberPopup
                        
                                           handleClose={togglePopupMessage}
                                        />}    
    
                  </div>
                  </div>
        </div>
  );
};
 
export default ReferInviteDetailContentPop;