import React, { useState, useEffect,useCallback } from "react";
import logo from '../../images/u128.svg';
import './SignUpMatrimony.css';
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Typography,
  TextField,
  Button,
  Stepper,
  Step,
  StepLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import axios from "axios";
import { useParams } from "react-router-dom"
import BasicDetail from "./BasicDetail";
import MatrimonyPlan from "./MatrimonyPlan";
import Subscribe from "./SubScribe";
import {configData} from "../../Config"


const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

function getSteps() {
  return [
    "Basic details", "Select Matrimony Plan", "Subscribe & Community Pledge"
  ];
}



const SignUpMatrimony = (props) => {

  //for session
  // const [session ,setSession] = useState(false);

  // const gesessiondata = async () => {
  //    debugger;
  //    axios.get('/checkSession')
      
  //    .then(result =>{
  //      if(result.data.code == 501){
  //        setSession(true)  ;
  //         }
  //     else{
  //          history.push("/Login");

  //     }
  //      debugger;
  //      console.log(result.data.code);
  //    }).catch(error =>{
  //      console.log(error);
  //    })
  //  };

  //        console.log("checksession on campaign",session);

  // useEffect(() => {
  //     gesessiondata();
  //   }, [session]);
  


  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [activeStep1, setActiveStep1] = useState(0);

  var newStepValue=0;
  if(activeStep1 < 0)
  {
    newStepValue = activeStep1+2;
}
else if(activeStep1>0){
  newStepValue=activeStep1;
}

 
//   else{
//   const [activeStep, setActiveStep] = useState(0);
// }
  const steps = getSteps();
//debugger
  const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
console.log("authdata11",authlocalstoragedata)



  const getStepContent = (value) => {
    //debugger;
    // if(newStepValue==2){
    //   //setActiveStep(activeStep==2)
    //   return <SubscribeCommunity handleNext={handleNext} basicInfo={basicInfo} setBasicInfo={setBasicInfo} handleBack={handleBack}/>;
    // }
     if (activeStep === 0) {
      return <BasicDetail handleChange1={handleChange} handleNext={handleNext} matrimonySign={matrimonySign} setmatrimonySign={setmatrimonySign}  handleBack1={handleBack} confirmPass={confirmPass} setConfirmPass={setConfirmPass} confirmPassErr={confirmPassErr} setconfirmPassErr={setconfirmPassErr} setOtpNew={setOtpNew} otpNew={otpNew} />;
    } else if (activeStep === 1) {
      return <MatrimonyPlan handleNext1={handleNext1} matrimonySign={matrimonySign} setmatrimonySign={setmatrimonySign}   handleVerifyOptp={handleVerifyOptp} handleBack1={handleBack1}  />;
    } else {
      debugger;
      return <Subscribe handleNext1={handleNext1} matrimonySign={matrimonySign} setmatrimonySign={setmatrimonySign} joinNowRes={joinNowRes} setjoinNowRes={setjoinNowRes} handleBack1={handleBack1} Paymentreq={Paymentreq} setPaymentreq={setPaymentreq}/>;
    }
  };

  
//for otp verify basicinfo

const handleChange=(e)=>{
  const {name,value,type,checked}=e.currentTarget;
 
 
  debugger;
 
 setmatrimonySign(preState =>({
    ...preState,
    [name]:type ==='checkbox'?checked: value
  }))

}


const [otpNew, setOtpNew] = useState('');


 const [confirmPass,setConfirmPass]= useState({
  confirmPassword:"",
  

 })



 const handleVerifyOptp =async()=>{
  debugger;
  if(authlocalstoragedata?.countryCode=="+91"||authlocalstoragedata?.countryCode=="91"||authlocalstoragedata?.countryCode==" 91"){
  const result = await axios.get(configData.ApiUrl+'/matrimony/validateMobileOtp?mobileNo='+
  authlocalstoragedata?.mobileNumber+ '&otp='+otpNew);
console.log("sendotp",result.data.data)
  if(result.data.code==200){
    toast.success("Otp Verified!");
    addBasicInfo();
    newStepValue=1;

    //newStepValue =newStepValue+1;

  }
  else{
    alert("fail")
    newStepValue=0;


  }
}
else{

  const result = await axios.get(configData.ApiUrl+'/matrimony/validateEmailOtp?emailId='+
  authlocalstoragedata?.emailId+ '&otp='+otpNew);
console.log("sendotp",result.data.data)
  if(result.data.code==200){
    toast.success("Otp Verified!");
    addBasicInfo();
    //newStepValue =newStepValue+1;
    newStepValue=1;

    
  }
  else{
    alert("fail")
    newStepValue=0;

  }
}

}

const handleVerifyOptpOther =async()=>{
  debugger;
  console.log("latest matrimonySign = ",matrimonySign)
  //matrimonySign.candidate.
  if(matrimonySign.candidate.countryCode=="+91"||matrimonySign.candidate.countryCode=="91"||matrimonySign.candidate.countryCode==" 91"){

  const result = await axios.get(configData.ApiUrl+'/matrimony/validateMobileOtp?mobileNo='+
  matrimonySign.candidate.mobileNumber+ '&otp='+otpNew);
console.log("verifyotp",result.data.data)
  if(result.data.code==200){
    toast.success("Otp Verified!");
    addBasicInfo();
    newStepValue=1;

    //newStepValue =newStepValue+1;

  }
  else{
    alert("fail")
    newStepValue=0;


  }
}
else{

  const result = await axios.get(configData.ApiUrl+'/matrimony/validateEmailOtp?emailId='+
  matrimonySign.candidate.emailId+ '&otp='+otpNew);
console.log("sendotp",result.data.data)
  if(result.data.code==200){
    toast.success("Otp Verified!");
    addBasicInfo();
    //newStepValue =newStepValue+1;
    newStepValue=1;

    
  }
  else{
    alert("fail")
    newStepValue=0;

  }
}

}
const { id } = useParams();

 //add basic info
 const [matrimonySign,setmatrimonySign]= useState({
  userId:authlocalstoragedata?.userAuthenticationId,
  userName:authlocalstoragedata?.name2,
  creatingFor:"",
  relation:"",
  candidate:{
      id:null,
      name:null,
      countryCode:null,
      emailId:null,
      gender:null,
      mobileNumber:null,
      birthdate1:null,
      birthdate2:null
  }

 })
    const [confirmPassErr,setconfirmPassErr]= useState(false)

    let history = useHistory();

    const [joinNowRes,setjoinNowRes]= useState()


    const [Paymentreq, setPaymentreq]= useState({
      nameId:{joinNowRes},
      amount:"",
      productType:'Community',
      currency:'INR',
      code:"",

      });

    //setPaymentreq(joinNowRes);

//payment req
const paymentRequest =()=>{
  debugger

  
    const response = axios.post(configData.ApiUrl+'/user/payment-request', Paymentreq)
    response.then((result) => {
      console.log("Paymentres response",result.data.data);
      debugger
      if (result.data.data != null) {
          toast.success(" Successfully!");

         // localStorage.setItem('id',result.data.data.id);
          //setjoinNowRes(result.data.data)
          debugger;
          //localStorage.setItem('name',result.data.data.name);
         // history.push("/CompleteProfile")

  
        }
          //toast.success("Last Name  has been added Successfully!");
   })
       response.catch((err) => {
         alert("Getting error in featching data")
       });
       console.log("response", response?.data)

      
}




// useEffect(() => {
//   loadUpdatedData();
//  }, []);
const [updatedData, setupdatedData] = useState(false);

 const loadUpdatedData = async () => {
   debugger;
   const result = await axios.get(`${configData.ApiUrl}/getUpdatedData?userId=${authlocalstoragedata.userAuthenticationId}`);
   setupdatedData(result.data.data);

   
console.log("updatedadatasignupmat",updatedData)
 }; 


const addBasicInfo =()=>{
  debugger

  
    const response = axios.post(configData.ApiUrl+'/matrimony/addMatrimonyUser', matrimonySign)
    response.then((result) => {

      console.log("matrimony response",result.data.data);
      debugger
       if (result.data.data != null && result.data.code ==200) {
      //     toast.success("Basic Detail Added Successfully!");
      toast.success("Basic Detail Added Successfully!");


           //localStorage.setItem('id',result.data.data.matrimonyId);
           // Store the matrimonyId as a string (localStorage only supports strings)
localStorage.setItem('id', result.data.data.matrimonyId.toString());

      //     //setjoinNowRes(result.data.data)
           debugger;
           localStorage.setItem('name',result.data.data.name);
           var storageUpdate = JSON.parse(localStorage.getItem("authdata"));
           if(storageUpdate !=null)
           {
            storageUpdate.matrimonyId = result.data.data.matrimonyId;
           }
           
           localStorage.setItem("authdata",JSON.stringify(storageUpdate));


           loadUpdatedData()

           history.push("/MatrimonyCompleteProfile");

  
         }
         else{
          toast.warn("User Profile already exist!");
         }
        setActiveStep(activeStep + 1);
          newStepValue =newStepValue+1;
          //toast.success("Last Name  has been added Successfully!");
   })
       response.catch((err) => {
         alert("Getting error in featching data")
       });
       console.log("response", response?.data)

      
}



   const isStepOptional = (step) => {
     return step === 4 || step === 5;
   };

  // const isStepSkipped = (step) => {
  //   return skippedSteps.includes(step);
  // };

  const handleNext = async() => {
    if (newStepValue === 0) {
      if(matrimonySign.creatingFor=='Self'){

      
      debugger
      handleVerifyOptp()}
      else{
        handleVerifyOptpOther()
      }

        

  }
  if (newStepValue === 1) {
    debugger
    //handleVerifyOptp();
  }

  if (newStepValue === 2) {
    debugger
    paymentRequest();
  }
  if(newStepValue!=0){
    setActiveStep(activeStep + 1);
    newStepValue =newStepValue+1;}
    // setSkippedSteps(skippedSteps.filter((skipItem) => skipItem !== activeStep));
  };
  const handleNext1 = async()=>{
    debugger
    setActiveStep(activeStep + 1);

  }
  const handleBack1 = async()=>{
    debugger
    setActiveStep(activeStep - 1);

  }


  const handleBack = () => {
    //
    debugger;
    newStepValue =newStepValue-1;
    //getStepContent(newStepValue);
    setActiveStep(activeStep - 1);
  };

  // const handleSkip = () => {
  //   if (!isStepSkipped(activeStep)) {
  //     setSkippedSteps([...skippedSteps, activeStep]);
  //   }
  //   setActiveStep(activeStep + 1);
  // };

  // const handleBackData = () => {
  //   setActiveStep(activeStep +2);
  // };
  if(authlocalstoragedata != null && authlocalstoragedata?.directory==false && activeStep === 0)
  {
    debugger;
    newStepValue=2;

  }
  //for new session for userlogin
  // const redirecToLandingdata =  () => {
 
  //   if(authlocalstoragedata?.kycStatus ==null &&  authlocalstoragedata?.kycStatus ==''){
  //     history.push("/");

  //   }
  // }
     
  //    useEffect(() => {
  //     redirecToLandingdata();
  //  });

  return (
    <div className="popup-boxSign">
      <div className="boxSign">
      <span className="close-iconSign" onClick={props.handleClose}>x</span>

    <div className=" "style={{}}>

          <div className="margintop1  row">
            
            {/* <div className="col-1"></div> */}
            <div className="col-12">
<div className="demo1">    
<Stepper alternativeLabel activeStep={activeStep} style={{width:"50px !important"}}>
        {steps.map((step, index) => {
          const labelProps = {};
          const stepProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography
                variant="caption"
                align="center"
                style={{ display: "block" }}
              >
                optional
              </Typography>
            );
          }
          // if (isStepSkipped(index)) {
          //   stepProps.completed = false;
          // }
          return (
            <Step {...stepProps} key={index}>
              <StepLabel {...labelProps}>{step}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {activeStep === steps.length ? (
        <Typography variant="h3" align="center">
             {/* {joinpoupopen(true)}         */}
             </Typography>
      ) : (
        <>
        <div className="row marglrftrightminusrow">
          <div className={activeStep==0?"col-11":"col-12"}>
          <div className="">{getStepContent(activeStep)}</div> 
         
         {/* { authlocalstoragedata?.directory==false? (
          <div className="">{getStepContent(newStepValue)}</div>

         ):(
          <div className="">{getStepContent(newStepValue)}</div> 

         )


          

          
} */}

          
          {/* <div className="margintop1 row ">
            <div className="col-3 marginleftmins4"></div>
            <div className="col-3 margintop textright">
            <button
               className={activeStep === 0?"displynoneprev":"prevbtn sourcesansfont"}
              
              onClick={handleBack}
            >
              Previous
            </button>
            </div>
          <div className="col-2 margintop ">
          <button
            className={activeStep === 0?"nextbtn sourcesansfont":activeStep === 2?"nextbtn2 sourcesansfont ":"nextbtn1 sourcesansfont"}
              variant="contained"
              color="primary"
               onClick={handleNext}
              type="submit"
            >
              {activeStep === steps.length - 1 ? "Submit And Proceed to Pay" : "Next"}
            </button>
            
            
          </div>
          <div className="margintop1  row"></div>

          <div className={activeStep === 1?"displynoneprev":activeStep === 2?"displynoneprev":"margintop textcenter row"}>
            <div className="col-4"></div>
            <div className="margintop col-4">
            <span className="alreadyspn marginleftmins1">Already a registered member ?</span>
            <span className="signin-stepper marginleft5" onClick={redirectoLogin} style={{cursor:"pointer"}}>Sign In</span>
            </div>
           
            </div>

            <div className={activeStep === 2?"displynoneprev":activeStep === 0?"displynoneprev":" textcenter row"}>
            <div className="col-3"></div>
            <div className="margintop col-6">
            <span className="alreadyspn1 ">Mobile verified badge is shown on your profile once you verify your mobile </span>
            </div>
           
            </div>

            <div className={activeStep === 1?"displynoneprev":activeStep === 0?"displynoneprev":" textcenter row "}>
            <div className="col-3"></div>
            <div className="margintop col-6">
            <span className="alreadyspn1 ">You will be redirect to Payment Gateway once you click on Submit button </span>
            </div>
           
            </div>
            
          </div> */}
         
          </div>
          <br/>
        </div>
        </>
      )}
</div>

</div>
          </div>
      
          

    </div>
    </div>
    </div>
  );
  
};

export default SignUpMatrimony;