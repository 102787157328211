import './ViewJobpop.css'
import conprof from '../../images/coneect.svg';
import {FiMaximize2} from 'react-icons/fi'
import {AiOutlineCloseSquare} from 'react-icons/ai'
import React, { useState, useEffect ,useRef} from "react";
import {over} from 'stompjs';
import SockJS from 'sockjs-client';
import axios from "axios";
import { toast } from "react-toastify";
import { BiBorderRadius } from 'react-icons/bi';
import {configData} from "../../Config"
import ReactTooltip from "react-tooltip";


const ViewJobPopup = (props ,styleForMessgid,styleForMessgBlockstatus,uerAuthId,userlist,setuserlist)=> {

 

 return (
   <div className="popup-boxjob">
     {/* {listMesseges?.map((messglistdata,index)=>( */}
       <div className="boxjob" >
       <div className="border1pxformessge marginrightminusformefssg " style={{paddingBottom:"5px",paddingTop:"2px"}}>

         <div className="row">
           <div className="col-lg-3 col-md-5 col-sm-12 margintop1">
           <img src={conprof}  className='' style={{width:"45px",height:"38px",paddingBottom:"2px"}}/>
           </div>
           <div className="col-lg-7 col-md-6 col-sm-12">
             <div className=""><span className="fontroboto40012px" style={{fontWeight:"600",fontSize:"14px"}}>Ayesha Naidu</span>       
             <div>
             <span className="fontroboto40012px"style={{fontWeight:"500",fontSize:"12px",color:"#626262"}}>
                       Dec 22,2024 @4.00 PM               </span>
               </div>
               
    </div>
   

           </div>
           <div className='col-lg-1 col-sm-12 col-md-12'>
    <AiOutlineCloseSquare color='rgba(238, 118, 0, 1)' size='1.5em' className='closemegpopicon' onClick={props.handleClose} style={{marginLeft:'0px', marginTop:'10px',cursor:"pointer"}}/>

    </div>
           
           </div>
         
           </div>
           <div>
                      <div className="row margintop3">
        <div className="col-7"><span className="radius-secobd-text" style={{textAlign:"right",color:"#797979"}}>Job Id</span></div>
        <div className="col-5 textleft"><span className="slider-name  " style={{marginLeft:"-22px"}}> 222111</span></div>

        </div>
        <div className="row margintop2">
        <div className="col-7"><span className="radius-secobd-text" style={{textAlign:"right",color:"#797979"}}> Role/Position</span></div>
        <div className="col-5 textleft"><span className="slider-name  " style={{marginLeft:"-22px"}}> Business Manager</span></div>

        </div>
        <div className="row margintop2">
        <div className="col-7"><span className="radius-secobd-text" style={{textAlign:"right",color:"#797979"}}> Date Posted</span></div>
        <div className="col-5 textleft"><span className="slider-name  " style={{marginLeft:"-22px"}}> 22 nov,2024</span></div>

        </div>
        <div className="row margintop2">
        <div className="col-7"><span className="radius-secobd-text" style={{textAlign:"right",color:"#797979"}}>Location</span></div>
        <div className="col-5 textleft"><span className="slider-name  " style={{marginLeft:"-22px"}}> Pune</span></div>

        </div>
        <div className="row margintop2">
        <div className="col-7"><span className="radius-secobd-text" style={{textAlign:"right",color:"#797979"}}>Educational Qualification:</span></div>
        <div className="col-5 textleft"><span className="slider-name  " style={{marginLeft:"-22px"}}> MBA</span></div>

        </div>
                     
                     
                      <div className="row margintop2">
                        <span className="radius-fourth-card1-text" style={{fontSize:"12px"}}>
                          {/* {job.jobDescription} */}Lorem Ipsum sit amet Lorem Ipsum sit amet Lorem Ipsum sit amet Lorem Ipsum sit amet Lorem Ipsum sit amet Lorem Ipsum sit amet Lorem Ipsum sit amet
                        </span>
                      </div>
           </div>

     </div>
     {/* ))} */}
   </div>
 );
};

export default ViewJobPopup;