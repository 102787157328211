import React, { useState, useEffect ,useRef} from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { columns ,data, heading, Rows } from "./Data1";
import { Link } from "react-router-dom";
import {AiOutlineFilePdf} from 'react-icons/ai'
import axios from "axios";
import { Breadcrumb, Button, Card, Col, Form, FormGroup, Dropdown, ButtonGroup, InputGroup, Row, Carousel, Pagination } from 'react-bootstrap';
import ReceiptPopup from "./ReceiptPopup";
import './ReferCommMemberPopup.css'
import { MdOutlineFileDownload } from "react-icons/md";
import logo from '../../images/Naidus directory.png';
import { MdCall } from "react-icons/md";
import { MdOutlineEmail } from "react-icons/md";
import {AiOutlineClose} from 'react-icons/ai'
import {configData} from "../../Config"

 
// const Payment=()=>{
  
//    const columns = [
  
    
//       {
//         name: "Date.",
//        selector: "date",
//         sortable: true,
        

        
//     },
//     {
//       name: "Order Id",
//      selector: "orderid",
//       sortable: true,
       
      
//   },
//   {
//       name: "Subscription/Item",
//      selector: "subscription",
//       sortable: true,
//      className:"texttransformcapitalize"
      
//   },
//   {
//       name: " Payment Id",
//      selector: "paymentid",
//       sortable: true,

      
//   },
//   {
//       name: "Amount",
//      selector: "amount",
//       sortable: true,
//       className:"texttransformcapitalize"

      
//   },
//   {
//       name: "Status",
//      selector: "status",
//       sortable: true,
//       className:"texttransformcapitalize",
//       cell: (d) => [
     
//         d.status=="Success"? <span className="" style={{color:'#04DE71'}}>Success</span> : <span className="" style={{color:'#FF3B30'}}>Fail</span>  
        
//       ]
      
//   }
//   ,{
//       name: " Receipt",
//      selector: "receipt",
//       sortable: true,
//       cell: (d) => (
//         <div className="dlink">
//             <AiOutlineFilePdf size='1.9em' color='#8B0000'/>
//          </div>
//        ),

//   }
    
//     ];
   

//     const data = [
//         {
//           date: "10 Nov 2022",
//           orderid: "10001",
//           subscription: "Paid Directory",
//           paymentid: "1234",
//           amount: "Rs.500",
//           status: "Success",
//           receipt: "Active",


            
            
//           },
//           {
//             date: "10 Nov 2022",
//             orderid: "10001",
//             subscription: "Matrimony",
//             paymentid: "1234",
//             amount: "Rs.500",
//             status: "Success",
//             receipt: "Active",
  
  
              
              
//             },
//             {
//                 date: "10 Nov 2022",
//                 orderid: "10001",
//                 subscription: "Business Directory",
//                 paymentid: "1234",
//                 amount: "Rs.500",
//                 status: "Success",
//                 receipt: "Active",
      
      
                  
                  
//                 },
//                 {
//                     date: "10 Nov 2022",
//                     orderid: "10001",
//                     subscription: "Sponsered Ads",
//                     paymentid: "1234",
//                     amount: "Rs.500",
//                     status: "Fail",
//                     receipt: "Active",
          
          
                      
                      
//                     },
//       ]
    


   
    


    

//     const tableData = {
//       columns,
//       data,
      
//     };

//     // useEffect(() => {
//     //   loadUsers();
//     // },[]);
  
//     // const loadUsers = async () => {
//     //   const result = await axios.get("/patient/getAllPatients");

//     //   setData(result.data.data);
//     //   console.log("patientlist",result.data.data)


//     // };

    
  
  
  
   

//   return (
//     <div >
// <DataTableExtensions {...tableData}>
//         <DataTable
//           columns={columns}
//           data={data}
//           noDataComponent= "Data is Not Available"          striped
//           noHeader
//           defaultSortField="id"
//           defaultSortAsc={true}
//           pagination
//           highlightOnHover
//           print={false}
//           export={true}
        
//         />
//       </DataTableExtensions>
//     </div>

//            );
// }
 
const Payment =()=>{
  
  const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
    console.log("authdata11",authlocalstoragedata)
    
    const [uerAuthId, setuerAuthId] = useState(authlocalstoragedata?.userAuthenticationId);
  
  const [data, setData] = useState([]);
  useEffect(() => {
    loadConnects();
   },[]);

   const loadConnects = async () => {
    
     const result = await axios.get(`${configData.ApiUrl}/user/listOfPayments?userId=${uerAuthId}`);

    setData(result.data.data);
     console.log("allpayments",result.data.data);
    
   

   };

   const [receiptData, setreceiptData] = useState({
    id:"",
    amount: "",
    productType: "",
    transactionId: "",
    status: "",
    orderId: "",
    code: "",
    paymentId: "",
    paymentMode: "",
    paymentDateTime:""
  });

  useEffect(() => {
    loadPaymentDetails();
   },[]);

   const loadPaymentDetails = async (id) => {
    

     const response  =  await axios.get(`${configData.ApiUrl}/user/getRecieptDetailsById?id=${id}`)
            console.log(response);
            
            setreceiptData({ ...response?.data.data });
           
     console.log("paymentrecieptdetails",response.data.data);
    
   };

   //for popup
   
    const [isOpenMessagePop, setIsOpenMessagePop] = useState(false);

   const togglePopupMessage = (isOpend,id) => {
    debugger;
    if(isOpend)
    {
      setIsOpenMessagePop(true);
      loadPaymentDetails(id);
    }
    else{
      setIsOpenMessagePop(false);
      setreceiptData("")
      
    }
    
  }

const loadUsers = async () => {
    // const response = await axios.get('/employeeDetails')
    //  .catch((err) => {
    //      alert("Getting error in featching data")
    //  });
    //  console.log("response",response?.data)
    //  setData(response?.data)
 }


 const [showRecord, setshowRecord] = useState(10);

 const  handleChanegeShowRec = (e) => {
   debugger;
   const { name, value, type, checked } = e.currentTarget;

   setshowRecord( e.currentTarget.value);
  }
 //for table records per page
 const [recordCount, setrecordCount] = useState(10);


 //for pagination

 const [pageData, setPageData] = useState([]);
const [page, setPage] = useState(1);
const [pageCount, setPageCount] = useState(10);


// handle next
const handleNext = () => {
   if (page === pageCount) return page;
   setPage(page + 1)
}

// handle previous
const handlePrevios = () => {
   if (page === 1) return page;
   setPage(page - 1)
}

useEffect(() => {
 loadUsers()
}, [page])

useEffect(() => {
   const pagedatacount = Math.ceil(data.length / showRecord);
   //setPageCount(pagedatacount);

   if (page) {
       const LIMIT = showRecord;
       const skip = LIMIT * page // 5 *2 = 10
       const dataskip = data.slice(page === 1 ? 0 : skip - LIMIT, skip);
       setPageData(dataskip)
   }
}, [data])

const [isReceiptPop, setisReceiptPop] = useState(false);
const togglePopupReceipt = () => {
  setisReceiptPop(!isReceiptPop);
}

  return(
    <div className="hidescrolll" style={{overflowY:"auto",height:"398px"}}>
<div className='row ' >
            <div className='col-2' style={{float:'left'}}>
        {/* <select  id="inputState" name='showrecc' onChange={handleChanegeShowRec}
                  className="selectdrpdn form-control" style={{height:"36px",marginTop:"4px"}}>
                  <option  value="10">Show 10</option>
                  <option value="25">Show 25</option>
                  <option value="50">Show 50</option>
                  <option value="100">Show 100</option>

                  </select> */}
                  </div>
                  <div className='col-8'></div>
                  <div className='form-group col-2'  style={{float:'right'}}>
                  <input
className="form-control"
placeholder="Search..."
type="search"
style={{height:"36px",marginTop:"4px",fontSize:"12px"}}
/>
                  </div>

        </div>
<table class="table  table-hove">
  <thead>
    <tr>
      <th scope="col" className="tableroww">Date</th>
      <th scope="col" className="tableroww">Order Id</th>
      <th scope="col" className="tableroww">Subscription/Item</th>
      <th scope="col" className="tableroww">Payment Id</th>
      <th scope="col" className="tableroww">Amount</th>
      <th scope="col" className="tableroww">Status</th>
      <th scope="col" className="tableroww">Receipt</th>


    </tr>
  </thead>
  <tbody>
    
     {
        data.map((data,index)=>( 
            <tr>
            <td className="tableroww1">{data.paymentDateTime}</td>
            <td className="tableroww1">{data.orderId}</td>
            <td className="tableroww1">{data.productType}</td>
            <td className="tableroww1">{data.paymentId}</td>
            <td className="tableroww1">{data.amount}</td>

            <td className="tableroww1">
               {data.status=="Credit"? <span className="" style={{color:'#04DE71'}}>Credit</span> : <span className="" style={{color:'#FF3B30'}}>Failed</span> }  

              </td>
            <td><div className="dlink"><AiOutlineFilePdf size='1.9em' color='#8B0000' onClick={()=>togglePopupMessage(true,data.id)} /></div></td>
{isReceiptPop && <ReceiptPopup
      
      handleClose={togglePopupReceipt}
   />}
            {/* <td>
                <Link className='btn btn-primary mr-2'to= {`/View/${data.id}`}>View</Link>
                <Link className='btn btn--outline-primary mr-2' to= {`/EditUser/${data.id}`}>Edit</Link>
                <Link className='btn btn-outline-danger' onClick={() =>deleteUser(data.id)}>Delete</Link>
            </td> */}
            </tr>


         ))
    } 
  </tbody>
</table>
{/* <div className=""style={{float:"right"}}>
       <Pagination>

<Pagination.Prev onClick={()=>handlePrevios()} disabled={page === 1} />
{
    Array(pageCount).fill(null).map((ele, index) => {
        return (
            <>
                <Pagination.Item active={page === index + 1 ? true : false} onClick={() => setPage(index + 1)}>{index + 1}</Pagination.Item>
            </>
        )
    })
}
<Pagination.Next onClick={()=>handleNext()} disabled={page === pageCount} />
</Pagination>
               </div> */}
               <div className={isOpenMessagePop?"":"hide"}>
               <div className="popup-boxrefmember" >
    <div className="boxrefmember"style={{width:"46%",marginTop:"100px",maxHeight:"83vh"}} >
      <div className="topnewsrow " style={{backgroundColor:"#1B3D0B",color:"#ffffff"}}>
      <div className="row">
        <div className="col-4"></div>
                <div className="col-7">                 
                    <img src={logo} style={{width:"185px", height:"70px"}}/>
                </div>
                <div className="col-1"style={{float:"right"}}>
                <span className="lastpop-top-text" onClick={()=>togglePopupMessage(false,0)} style={{cursor:"pointer",color:"#ffffff",marginLeft:"px"}}><AiOutlineClose size="1.3em"/></span>

                  </div>
                </div>
      <div className="row">
                <div className="col-3"></div>
                <div className="col-7 textcenter" style={{marginLeft:"-5px"}}>
                <span className=" blood-top-text" style={{color:"#ffffff",fontSize:"20px",fontWeight:"500"}}>Customer Receipt</span>
                {/* <div> <span className=" blood-top-text"style={{color:"#ffffff",fontSize:"16px",fontWeight:"500",marginTop:"7px"}}>Thank you for your payment!</span>
                </div> */}
                 {/* <MdFileCopy color='#fff' className="marginleft2"style={{color:"#555555"}}/>
                 <FaShareAltSquare color='#fff' className="marginleft5"style={{color:"#555555"}}/> */}
                 </div>
                 
                 <div className='col-1' style={{float:"",}}>
                 
                 <MdOutlineFileDownload className="downloadicon"size="1.6em" color="#ffffff" backgroundColor="#FF8427"style={{marginTop:"0px"}}/>
                 
                 </div>
            </div>
        </div>
        <div style={{}}>

        <div className="margintop row">
  <div className="col-12">
  <span for="inputState" className="lbl " style={{fontSize:"15px",fontWeight:"500",color:"#555555"}}>Your Subscription to <span style={{fontWeight:"bold"}}>Community Matrimony</span>  has been Successfully processed. Below are the details of your transaction</span>
  </div>
   </div>
   
   <div className="margintop row">
  <div className="col-6 textleft ">
  <span for="inputState" className="lbl " style={{fontSize:"14px",fontWeight:"500",color:"gray"}}>Order Id</span>
  </div>
  <div className="col-6  textright form-group">
  <span for="inputState" className="lbl " style={{fontSize:"14px",fontWeight:"500",color:"gray"}}>{receiptData.orderId}</span>

  </div>
  
  </div>
  <div className="row"style={{marginTop:"-7px"}}>
  <div className="col-6 textleft ">
  <span for="inputState" className="lbl " style={{fontSize:"14px",fontWeight:"500",color:"gray"}}>Transaction Id</span>
  </div>
  <div className="col-6  textright form-group">
  <span for="inputState" className="lbl " style={{fontSize:"14px",fontWeight:"500",color:"gray"}}>{receiptData.transactionId}</span>

  </div>
  
  </div>
  <div className="row"style={{marginTop:"-7px"}}>
  <div className="col-6 textleft ">
  <span for="inputState" className="lbl " style={{fontSize:"14px",fontWeight:"500",color:"gray"}}>Date & Time</span>
  </div>
  <div className="col-6  textright form-group">
  <span for="inputState" className="lbl " style={{fontSize:"14px",fontWeight:"500",color:"#555555"}}>{receiptData.paymentDateTime}</span>

  </div>
  
  </div>
  <div className="row"style={{marginTop:"6px"}}>
  <div className="col-6 textleft ">
  <span for="inputState" className="lbl " style={{fontSize:"14px",fontWeight:"500",color:"gray"}}>Amount Paid</span>
  </div>
  <div className="col-6  textright form-group">
  <span for="inputState" className="lbl " style={{fontSize:"14px",fontWeight:"500",color:"#555555"}}>{receiptData.amount}</span>

  </div>
  
  </div>
   

    
    
    
    
    
                  </div>
                  <div className='popupbtn-div ' style={{marginLeft:"0px",marginRight:'0px',borderLeft:"none",borderRight:"none",paddingBottom:"2px"}}>
<div className="row textright">
<div className="col-6 textleft ">
  <div className=""><span for="inputState" className="lbl " style={{fontSize:"14px",fontWeight:"600",color:"#555555"}}> Subscription Details</span></div>
  </div>
</div>
                  <div className="row">
  <div className="col-6 textleft ">
  <div className=""><span for="inputState" className="lbl " style={{fontSize:"14px",fontWeight:"500",color:"gray"}}> Service</span></div>
  </div>
  <div className="col-6  textright form-group">
  <span for="inputState" className="lbl " style={{fontSize:"14px",fontWeight:"500",color:"#555555"}}>Matrimony</span>

  </div>
  
  </div>
  <div className="row" style={{marginTop:"-7px"}}>
  <div className="col-6 textleft ">
  <div className=""><span for="inputState" className="lbl " style={{fontSize:"14px",fontWeight:"500",color:"gray"}}> Plan</span></div>
  </div>
  <div className="col-6  textright form-group">
  <span for="inputState" className="lbl " style={{fontSize:"14px",fontWeight:"500",color:"#555555"}}>Gold Plan</span>

  </div>
  
  </div>

    </div>
    <div className='popupbtn-div 'style={{marginLeft:"0px",marginRight:'0px',borderLeft:"none",borderRight:"none",borderTop:"none",marginTop:"4px",paddingBottom:"2px"}}>

                  <div className="row"style={{marginTop:"-7px"}}>
  <div className="col-6 textleft ">
  <div className=""><span for="inputState" className="lbl " style={{fontSize:"14px",fontWeight:"500",color:"gray"}}> Sub Total</span></div>
  </div>
  <div className="col-6  textright form-group">
  <span for="inputState" className="lbl " style={{fontSize:"14px",fontWeight:"500",color:"black"}}>499:00 rs</span>

  </div>
  
  </div>
  <div className="row"style={{marginTop:"-7px"}}>
  <div className="col-6 textleft ">
  <div className=""><span for="inputState" className="lbl " style={{fontSize:"14px",fontWeight:"500",color:"gray"}}> Discount</span></div>
  </div>
  <div className="col-6  textright form-group">
  <span for="inputState" className="lbl " style={{fontSize:"14px",fontWeight:"500",color:"black"}}> -99 rs</span>

  </div>
  
  </div>

    </div>
    <div className='popupbtn-div 'style={{marginLeft:"0px",marginRight:'0px',borderLeft:"none",borderRight:"none",borderTop:"none",marginTop:"4px",paddingBottom:"2px"}}>

<div className="row"style={{marginTop:"-7px"}}>
<div className="col-6 textleft ">
<div className=""><span for="inputState" className="lbl " style={{fontSize:"14px",fontWeight:"500",color:"gray"}}>  Total</span></div>
</div>
<div className="col-6  textright form-group">
<span for="inputState" className="lbl " style={{fontSize:"18px",fontWeight:"600",color:"gray"}}>419:00 rs</span>

</div>

</div>
<div className="row" style={{marginTop:"7px"}}>
  <div className="col-8 textleft ">
  <div className=""><span for="inputState" className="lbl " style={{fontSize:"10px",fontWeight:"500",color:"gray"}}> If You need assistance with your payment, please contact</span></div>
  </div>
  
  
  </div>
  <div className="row" style={{marginTop:"-7px"}}>
  <div className="col-6 textleft ">
  <div className=""><span for="inputState" className="lbl " style={{fontSize:"10px",fontWeight:"500",color:"gray"}}> <MdCall/> 9100000000</span></div>
  </div>
  
  
  </div>
  <div className="row" style={{marginTop:"-7px"}}>
  <div className="col-6 textleft ">
  <div className=""><span for="inputState" className="lbl " style={{fontSize:"10px",fontWeight:"500",color:"gray"}}> <MdOutlineEmail/> support@gmail.com</span></div>
  </div>
  
  
  </div>

</div>

        </div>

        
    </div>
    </div>
    </div>

  )
}
export default Payment;