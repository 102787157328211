import '../JoinNow/JoinNow.css';
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
// import ReCAPTCHA from "react-google-recaptcha";
import {MdBookOnline} from 'react-icons/md'
 import cloudimg from '../../images/cloud 1.png';
 import uplodimg from '../../images/upload 1.png';

 import u115 from '../../images/u115.png';
 import axios from "axios";
 import imgupload from '../../images/imageupload.svg'
 import {configData} from "../../Config"
 import {AiOutlineClose} from 'react-icons/ai'

 import {FaInfoCircle} from 'react-icons/fa';


 
const VerifiIdentity = ({additional,setadditional,setAttachment,attachment, handleBack,handleNext}) => {

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  const handleChangeKyc = (e) => {
    debugger;
    const { name, value, type, checked } = e.currentTarget;


    setadditional(preState => ({
        ...preState,
        [name]: type === 'checkbox' ? checked : value
    }));
}
  
  const [isOpendiv, setIsOpendiv] = useState(true);
  const [isformdiv, setIsformdiv] = useState(false);
  const [isOfflinekyc, setIsOfflinekyc] = useState(false);

  const onNext = (isback) => {
    setadditional(preState => ({
      ...preState,
      kycStatus: "online"
  }));
    if(isback){
      setIsOpendiv(true);
      setIsformdiv(false);
      setIsOfflinekyc(false);
    }
    else{
      setIsOpendiv(false);
      setIsformdiv(true);
      setIsOfflinekyc(false);
    }
       
    

  }
  const onNext1 = (isback1) => {
   debugger
   setadditional(preState => ({
    ...preState,
    kycStatus: "offline"
}));
    if(isback1){
      setIsOpendiv(true);
      setIsformdiv(true);
      setIsOfflinekyc(false);
    }
    else{
      setIsOpendiv(false);
      setIsformdiv(false);
      setIsOfflinekyc(true);
    }
       
    
  }

//  function onChange(value) {
//     console.log("Captcha value:", value);
//   }

const [user, setUser] = useState({
  usercaptcha:""
});
const characters ='abc123';
  function generateString(length) 
  {
      let result = '';
      const charactersLength = characters.length;
      for ( let i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
     return result;
   }
   const captcha = generateString(6) // Function called here and save in captcha variable
   let handleChange = (e) => {
     let name = e.target.name;
     let value = e.target.value;
     user[name] = value;
     setUser(user);
  }
  const onSubmit = e => {
    var element =  document.getElementById("succesBTN");
    var inputData = document.getElementById("inputType");
     element.style.cursor = "wait";
     element.innerHTML  = "Checking...";
     inputData.disabled = true;
     element.disabled = true;
      var myFunctions = function(){
          if(captcha == user.usercaptcha)
          {
            element.style.backgroundColor   = "green";
            element.innerHTML  = "Captcha Verified";
            element.disabled = true;
            element.style.cursor = "not-allowed";
            inputData.style.display = "none";
            
          }
          else
          {
            element.style.backgroundColor   = "red";
            element.style.cursor = "not-allowed";
            element.innerHTML  = "Not Matched";
            element.disabled = true;
            //  element.disabled = true;
            var myFunction = function(){
              element.style.backgroundColor   = "#007bff";
              element.style.cursor = "pointer";
              element.innerHTML  = "Verify Captcha";
              element.disabled = false;
              inputData.disabled = false;
              inputData.value ='';
            };
            setTimeout(myFunction,5000);
          }
        }   
        setTimeout(myFunctions,5000); 
  };

  const [aadharDIv ,setAadharDIv] = useState(false);
  const [ passportDiv,setpassportDiv] = useState(false);
  const [pancardDiv ,setpancardDiv] = useState(false);


  const handleChangeforkycoffline = (e) => {
    debugger
    const { name, value, type, checked } = e.currentTarget;


      setadditional(preState => ({
          ...preState,
          [name]: type === 'checkbox' ? checked : value
      }));
    
      if(e.target.value=="AadharCard"){
      //setisfilterdata( e.target.value )
      setAadharDIv(true);
      setpassportDiv(false);
      setpancardDiv(false)
    }
    else if (e.target.value=="Passport") {
      setAadharDIv(false);
      setpassportDiv(true);
      setpancardDiv(false)
    }
    else{
      setAadharDIv(false);
      setpassportDiv(false);
      setpancardDiv(true);

    }


   }


   const handleChangeforidentificationNo = (e) => {
    debugger
    const { name, value, type, checked } = e.currentTarget;


      setadditional(preState => ({
          ...preState,
          [name]: type === 'checkbox' ? checked : value
      }));
    }
//for display img name
const [selectedFilenameadharFront,setselectedFilenameadharFront]=useState(null)
const [selectedFilenameadharBack,setselectedFilenameadharBack]=useState(null)

const removeFrontFile =()=>{
  setAttachment(preState => ({
    ...preState,
    "frontDocPhoto" : null,

  }))
  setselectedFilenameadharFront(null)
}
const removeBackFile =()=>{
  setAttachment(preState => ({
    ...preState,
    "backDocPhoto" : null,

  }))
  setselectedFilenameadharBack(null)
}

   //for file upload
   const handleFrontPicChange = (e, index) => {
    const { name, value } = e.target;

  setAttachment(preState => ({
       ...preState,
       "frontDocPhoto" : e.target.files[0],

     }))
     setselectedFilenameadharFront(e.target.files[0])
console.log("frontpic",selectedFilenameadharFront?.name)

//      if(e.currentTarget.files != null)
//  {
//      setadditional(e.target.files[0]);
//  }

  };
  const handleBackPicChange = (e, index) => {
    const { name, value } = e.target;

  setAttachment(preState => ({
       ...preState,
       "backDocPhoto" : e.target.files[0]
     }))
     setselectedFilenameadharBack(e.target.files[0])
//      if(e.currentTarget.files != null)
//  {
//   setadditional(e.target.files[0]);
//  }

  };
  const [error, setError] = useState(false);
  const [adharValidError,setAdharValidError] =useState(false);
  const [panValidError,setPanValidError] =useState(false);
  const [drivingValidError,setDrivingValidError] =useState(false);
  const [passportValidError,setPassportValidError] =useState(false);

  function validateAadhaar(){ 
    var regexp = /^[2-9]{1}[0-9]{3}\s[0-9]{4}\s[0-9]{4}$/; 
    var ano = document.getElementById("aadhharno").value; 
    
    if(regexp.test(ano)) { 
    console.log("Valid Aadhaar Number"); 
    return true; 
    }else{ 
    console.log("Invalid Aadhaar Number"); 
     return false; 
    } } 

  const submitformData3 =(e)=> {
    debugger

    e.preventDefault();
    //RegExp(/^[2-9]{1}[0-9]{3}\s[0-9]{4}\s[0-9]{4}$/) adhar card
    //(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/) pancard
    //^[A-Z](?:\d[- ]*){14}$ driving licence
    ///^[A-PR-WY][1-9]\d\s?\d{4}[1-9]$/ passport
    var regexp = /^[2-9]{1}[0-9]{3}\s[0-9]{4}\s[0-9]{4}$/; 
      var ano = document.getElementById("aadhharno")?.value; 
      var regexp1 = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/; 
      var ano1 = document.getElementById("panno")?.value; 
      var regexp2 = /^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/; 
      var ano2 = document.getElementById("drivingno")?.value; 

        // checking if value of first name and last name is empty show error else take to step 2
        if (additional.documentType ==""||additional.documentType==null) {
            setError(true);
        }
        else if (additional.kycStatus ==""||additional.kycStatus==null ) {
            setError(true);
        }
        else if (attachment.frontDocPhoto ==""||attachment.frontDocPhoto==null ) {
          setError(true);
       }
         else if (attachment.backDocPhoto ==""||attachment.backDocPhoto==null ) {
        setError(true);
      }
      else if (additional.identificationNo1 ==""||additional.identificationNo1==null ) {
        setError(true);
      }

      else if(!regexp.test(ano)&&(additional.documentType=="AadharCard")) { 
        setAdharValidError(true); 
      }
      else if(!regexp1.test(ano1)&&(additional.documentType=="PAN Card Driving License")) { 
        setPanValidError(true); 
      }
      else if(!regexp2.test(ano2)&&(additional.documentType=="PAN Card Driving License")) { 
        setDrivingValidError(true); 
      }

      // else if ((aadharDIv) && !/^[2-9]{1}[0-9]{3}\\s[0-9]{4}\\s[0-9]{4}$/.test(additional.identificationNo1)) {
      //   setAdharValidError(true);
      // }
      // else if ((additional.documentType=="PAN Card Driving License") && !/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(additional.identificationNo1)) {
      //   setPanValidError(true);
      // }
      // else if ((additional.documentType=="PAN Card Driving License") && !/^[A-Z](?:\d[- ]*){14}$/.test(additional.identificationNo2)) {
      //   setDrivingValidError(true);
      // }
      else if ((additional.documentType=="Passport") && !/^[A-PR-WY][1-9]\d\s?\d{4}[1-9]$/.test(additional.identificationNo1)) {
        setPassportValidError(true);
      }
      
      else if ((pancardDiv) && (additional.identificationNo2 ==""||additional.identificationNo2==null)) {
        setError(true);
      }
      
        else{
          handleNext();

        }

  }

  //for validateidentification no
  const [IdEntNoValidEroor ,setIdEntNoValidEroor] = useState(false);
  const [identificationError ,setidentificationError] = useState("");


  const identificationNoValidation =(e)=> {

    if (attachment.identificationNo1 ==""||attachment.identificationNo1==null ) {
      //setidentificationError("Please Enter Identification No");
      setIdEntNoValidEroor(true);
  
    }else{
      // axios.get(`/UserManagement/validateUserForEmail?emailId=${user.emailId}`)
       axios.get(`${configData.ApiUrl}/user/validateIdentificationNumber?identificationNumber=${attachment.identificationNo1}`)
  
    .then(result =>{
      console.log(result.data);
       if(result.data.code==200){
        setIdEntNoValidEroor(false);
        
       }
       else{
       
        setIdEntNoValidEroor(true);
        setidentificationError("User Already Exist")
       }
    })
    .catch(error =>{
      console.log(error)
    })
    }
    
  }
//for identifiation no 2
const identificationNoValidation2 =(e)=> {

  if (pancardDiv && attachment.identificationNo2 ==""||attachment.identificationNo2==null ) {
    //setidentificationError("Please Enter Driving license No");
    setIdEntNoValidEroor(true);

  }else{
    // axios.get(`/UserManagement/validateUserForEmail?emailId=${user.emailId}`)
     axios.get(`${configData.ApiUrl}/user/validateIdentificationNumber?identificationNumber=${attachment.identificationNo2}`)

  .then(result =>{
    console.log(result.data);
     if(result.data.code==200){
      setIdEntNoValidEroor(false);
      
     }
     else{
     
      setIdEntNoValidEroor(true);
      setidentificationError("User Already Exist")
     }
  })
  .catch(error =>{
    console.log(error)
  })
  }
  
}
  
  return (
    <div className="container">
      <form onSubmit={submitformData3}>

      <div className={isOpendiv?"":"hide"}>
      <div className="row margintop">
                  <div className="col-lg-3 col-sm-12 col-md-12 textcenter">
                    <span className="contactmain-labl">Verify Identity:</span>
                  </div>
           </div>
           <div className="row margintop">
            <div className="col-lg-2"></div>
             <div className="col-lg-4 col-sm-12 col-md-12 margintop1 textcenter ">
     <div className="margintop1">
      <img src={cloudimg}  className='' style={{}}/>

      </div>
          <div className="margintop3 textcenter"><span className='event-date'style={{fontSize:'14px',color:"#222227"}}>Verify Aadhar Online</span></div>

      <div className="margintop2 textcenter">
        <span className='event-date' style={{fontSize:'14px',color:"#222227"}}>Instant verification in few seconds</span>
      </div>
      <div className="margintop3 textcenter">
        <span className='event-date' style={{fontSize:'14px',color:"#4682DB",fontStyle:'italic'}} onClick={handleSubmit(() => onNext(false))}>Pay ₹10 to proceed with this option.</span><FaInfoCircle color='#626262' size='1.2em'/>
      </div>
            </div> 
             <div className="col-lg-4 col-sm-12 col-md-12 margintop1 textcenter ">
     <div className="margintop1">
      <img src={uplodimg}  className='' style={{}}/>

      </div>
          <div className="margintop3 textcenter"><span className='event-date'style={{fontSize:'14px',color:"#222227"}}>Offline verification in 1 to </span></div>

      <div className="margintop2 textcenter">
        <span className='event-date' style={{fontSize:'14px',color:"#222227"}}>2 working days</span>
      </div>
      <div className="margintop3">
        <span className='event-date' style={{fontSize:'14px',color:"#FF8427",textDecoration:'underline'}} onClick={handleSubmit(() => onNext1(false))}>Offline KYC Upload</span>
      </div>
            </div>
            {/* <div className="col-lg-4 col-sm-12 col-md-12 margintop1   ">
     <div className="margintop1">
      <img src={u115}  className='' style={{width:"28px",height:"28px"}}/>

      </div>
      <div className="margintop textcenter">
        <span className="instantveritext">Usually takes 2 to 4 working days </span>
      </div>
      <div className="margintop textcenter">
        <button className="margintop1 kycbtn" onClick={handleSubmit(() => onNext1(false))}>Offline KYC Upload Method </button>
      </div>
            </div> */}
            </div>

            <div className="row  margintop">
              <div className="col-lg-3"></div>
              <div className="col-lg-1 col-sm-12 col-md-12 margintop textright"style={{marginLeft:"-25px"}}>              
                <span className='event-date' style={{fontSize:'14px',color:"#222227",}}>IMPORTANT:</span>
               </div>
              <div className="col-lg-7 col-sm-12 col-md-12  margintop textleft">
                <span className='event-date' style={{fontSize:'14px',color:"#797979"}}>Indian Residents currently staying abord or NRIs can skip the step 1 of Online Aadhar Verification.
                They still will have to complete the step 2 of Offline KYC Upload Method.
                </span>
              </div>
            </div>
      </div>

      
      
      <div className={isformdiv?"":"hide"}>
      <div className="row margintop">
                  <div className="col-lg-3 col-sm-12 col-md-12 textcenter">
                    <span className="contactmain-labl">Verify Identity:</span>
                  </div>
           </div>
           <div className='row margintop'>
            <div className='col-lg-3 col-sm-12 col-md-12 textright'>
              <span className='lbl 'style={{fontSize:'12px'}}>Verify Aadhar Online</span>
            </div>
            <div className='col-lg-7 col-md-12 col-sm-12'>
              <span className='important-text' style={{fontSize:'13px'}}>We do not store any of your Aadhar data and it is completely secure</span>
            </div>
            </div>
            <div className='row margintop'>
            <div className='col-lg-3 col-sm-12 col-md-12 textright'>
              <span className='lbl ' style={{fontSize:'12px'}}> Aadhar Number</span>
            </div>
            <div className='col-lg-6 col-sm-12 col-md-12 form-group'>
            <input type="text" className=" form-control" id="fst"name="firstname"
              placeholder="Enter Email Id "/>            
          </div>
         </div>

         <div className='row margintop1'>
            <div className='col-lg-3 col-sm-12 col-md-12 textright'>
              <span className='lbl ' style={{fontSize:'12px'}}>Email Id</span>
            </div>
            <div className='col-lg-6 col-sm-12 col-md-12 form-group'>
            <input type="text" className=" form-control" id="fst"name="firstname"
              placeholder="Enter Email Id "/>            
          </div>
         </div>

         <div className='row margintop1'>
            <div className='col-lg-3 col-sm-12 col-md-12 textright'>
              <span className='lbl ' style={{fontSize:'12px'}}> Mobile No</span>
            </div>
            <div className='col-lg-6 col-sm-12 col-md-12 form-group'>
            <input type="text" className=" form-control" id="fst"name="firstname"
              placeholder="Aadhar Linked Mobile No "/>            
          </div>
         </div>

         <div className='row margintop1'>
            <div className='col-lg-3 col-sm-12 col-md-12 textright'>
              <span className='lbl popinsfont' style={{fontSize:'13px'}}>Enter Security Code</span>
            </div>
            <div className='col-lg-4 col-sm-12 col-md-12 form-group'>
            <input type="text" className=" form-control" name="usercaptcha" id="inputType" onChange={handleChange}
              placeholder="Enter Displayed Number"/>            
          </div>
          <div className='col-lg-2 col-sm-12 col-md-12 margintop1'>
            <span className='securitycode'>{captcha}</span>
            
            </div>
            <div className='col-lg-2 col-sm-12 col-md-12 margintop1'>
            <button className='textcenter verifycaptcha'  id="succesBTN" onClick={onSubmit}>Verify Captcha</button>

            </div>
         </div>
        

      </div>

      <div className={isOfflinekyc?"":"hide"}>
      <div className="row margintop">
                  <div className="col-lg-3 col-sm-12 col-md-12 textcenter">
                    <span className="contactmain-labl" style={{fontSize:'15px'}}>Verify Identity:</span>
                  </div>
           </div>
           <div className='row margintop1'>
            <div className='col-lg-3 col-sm-12 col-md-12 textright'>
              <span className='lbl ' style={{fontSize:'12px'}}>KYC Offline</span>
            </div>
            <div className='col-lg-7 col-sm-12 col-md-12'>
              <span className='event-date' style={{fontSize:'14px',color:"#797979"}}>We do not share any of your KYC data with 3rd Party and is encrypted

to prevent any misuse
</span>
            </div>
            </div>

           <div className='row margintop3'>
            <div className='col-lg-4 col-sm-12 col-md-12 textright'>
              <span className="matchestxetxt"style={{fontSize:'12px',color:"#797979"}}>KYC Type</span>
            </div>

            <div class="form-group col-lg-6 col-sm-12 col-md-12">
  <select class="form-control matchestxetxt textboxbackcolorshadow" style={{fontSize:'14px',color:"#222227",textTransform:"capitalize",height:"39px"}} id="sel1" value={additional.documentType} onChange={handleChangeforkycoffline} required name='documentType'>
    <option value=''>Please select</option>
    <option value='AadharCard'>Aadhar Card</option>
    <option value='Passport'>Passport</option>
    <option value='PAN Card Driving License' className=''>PAN Card Driving License</option>
  </select>
</div>
            
            </div>
            <div className='row '>
    <div className='col-lg-4'></div>
    <div className=" col-lg-4 col-sm-12 col-md-12 ">
                                    {error && additional.documentType == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            This is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>

            {
              aadharDIv==true && (
                <div>
<div className='row margintop '>
            <div className='col-lg-4 col-sm-12 col-md-12 textright margintop1'>
              <span className="matchestxetxt"style={{fontSize:'12px',color:"#797979"}}>KYC Document</span>
            </div>

            <div class="col-lg-3 col-sm-12 col-md-12 margintop">
            {/* <label for="selectFile" name="frontDocPhoto" className="uplod" style={{ backgroudColor: "white",width: '160px',height: '100px' }}   >
          
          </label>
    <input id="selectFile" type="file"  className="fileupload hide" onChange={handleFrontPicChange}
      name="frontDocPhoto"
       /> */}

<label for="selectFile" name="frontDocPhoto" className="uplod" style={{ backgroudColor: "white",width: '160px',height: '100px',cursor:"pointer" }}   >
            <img src={imgupload}  className='imagePreview' style={{height:"210px"}}/>
            </label>
            <input id="selectFile" type="file" value={additional?.frontDocPhoto} className="fileupload hide" onChange={handleFrontPicChange}
      name="frontDocPhoto"
       />   
   <label className='' style={{marginTop:"-74px",fontSize:"11px",display:"block",marginLeft:"55px",position:"absolute",cursor:"pointer"}}>Front Side</label>
   <div className='textcenter'style={{marginLeft:"-15px"}}><span className='event-sub-detail textShowondotBuss'>{selectedFilenameadharFront?.name}</span> <AiOutlineClose color=''className={selectedFilenameadharFront?.name==null|| selectedFilenameadharFront?.name==""?"hide":""} size="1.1em" style={{cursor:"pointer"}} onClick={removeFrontFile}/></div>

      <div className='textleft' style={{marginLeft:"55px"}}>
    <span className='bluetextforlink'>Upload</span>
      </div> 
      {/* <div className='textcenter'>
      {error && attachment.frontDocPhoto == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            This is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
      </div> */}
      </div>


      <div class="col-lg-3 col-sm-12 col-md-12 margintop">
            <label for="selectFile1" name="backDocPhoto" className="uplod" style={{ backgroudColor: "white",width: '160px',height: '100px',cursor:"pointer" }}   >
            <img src={imgupload}  className='imagePreview' style={{height:"210px"}}/>

          </label>
    <input id="selectFile1" type="file" value={additional.backDocPhoto} className="fileupload hide" onChange={handleBackPicChange}
      name="backDocPhoto" placeholder='' 
       />
       <label className='popinsfont' style={{marginTop:"-74px",fontSize:"11px",display:"block",marginLeft:"55px",position:"absolute",cursor:"pointer"}}>Back Side</label>
       <div className='textcenter'style={{marginLeft:"-15px"}}><span className="event-sub-detail textShowondotBuss">{selectedFilenameadharBack?.name}</span><AiOutlineClose color='' className={selectedFilenameadharBack?.name==null||selectedFilenameadharBack?.name==""?"hide":""} size="1.1em" onClick={removeBackFile} style={{cursor:"pointer"}}/> </div>

      <div className='textleft'style={{marginLeft:"55px"}}><span className='bluetextforlink'>Upload</span></div> 
      {/* <div className='textcenter'>
      {error && attachment.backDocPhoto == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            This is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
      </div> */}
      </div>
            
            </div>  
            <div className='row margintop '>
            <div className='col-lg-4 col-sm-12 col-md-12 textright '>
              <span className="matchestxetxt"style={{fontSize:'12px',color:"#797979"}}>Aadhar No</span>
            </div>

            <div class="col-lg-6 col-sm-12 col-md-12  form-group">
            <input type="text" className="form-control matchestxetxt textboxbackcolorshadow" id="aadhharno" name="identificationNo1" onChange={handleChangeforidentificationNo}
              placeholder="Aadhar No" style={{fontSize:'14px',color:"#222227",textTransform:"capitalize",height:"39px"}} value={additional.identificationNo1}   onBlur={identificationNoValidation}
              /> 
              {/* <div className='textcenter'>
      {error && additional.identificationNo1 == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            This is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
      </div> */}
      </div>
      
            
            </div>
                
                </div>      
             )

            }
            {
              passportDiv == true && (
                <div>
<div className='row margintop'>
            <div className='col-lg-4 col-sm-12 col-md-12 textright margintop1'>
              <span className="matchestxetxt"style={{fontSize:'12px',color:"#797979"}}>KYC Document</span>
            </div>

            <div class="col-lg-3 col-sm-12 col-md-12 margintop">
            <label for="selectFile" name="frontDocPhoto" className="uplod" style={{ backgroudColor: "white",width: '160px',height: '100px' }}   >
            <img src={imgupload}  className='imagePreview' style={{height:"210px"}}/>

          </label>
    <input id="selectFile" type="file" value={additional.frontDocPhoto}  className="fileupload hide" onChange={handleFrontPicChange}
      name="frontDocPhoto"
       />
              <label className='' style={{marginTop:"-74px",fontSize:"11px",display:"block",marginLeft:"55px",position:"absolute"}}>Front Side</label>
              <div className='textcenter'style={{marginLeft:"-15px"}}><span className='event-sub-detail textShowondotBuss'>{selectedFilenameadharFront?.name}</span><AiOutlineClose color='' className={selectedFilenameadharFront?.name==null||selectedFilenameadharFront?.name==""?"hide":""} size="1.1em"/> </div>

              <div className='textleft'style={{marginLeft:"55px"}}><span className='bluetextforlink'>Upload</span></div> 
              {/* <div className='textcenter'>
      {error && attachment.frontDocPhoto == null && attachment.frontDocPhoto == ""? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            This is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
      </div> */}
      </div>
      <div class="col-lg-3 col-sm-12 col-md-12 margintop">
            <label for="selectFile1" name="backDocPhoto" className="uplod" style={{ backgroudColor: "white",width: '160px',height: '100px' }}   >
            <img src={imgupload}  className='imagePreview' style={{height:"210px"}}/>

          </label>
    <input id="selectFile1" type="file" value={additional.backDocPhoto} className="fileupload hide" onChange={handleBackPicChange}
      name="backDocPhoto" placeholder=''
       />
       <label className='' style={{marginTop:"-74px",fontSize:"11px",display:"block",marginLeft:"55px",position:"absolute"}}>Back Side</label>
       <div className='textcenter'style={{marginLeft:"-15px"}}><span className='event-sub-detail textShowondotBuss'>{selectedFilenameadharBack?.name}</span> <AiOutlineClose color='' className={selectedFilenameadharBack?.name==null || selectedFilenameadharBack?.name==""?"hide":""} size="1.1em"/></div>

       <div className='textleft'style={{marginLeft:"55px"}}><span className='bluetextforlink'>Upload</span></div> 
        {/* <div className='textcenter'>
      {error && attachment.backDocPhoto == null && attachment.backDocPhoto == ""? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            This is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
      </div>  */}
      </div>
            
    </div>  
    
    <div className='row margintop '>
            <div className='col-lg-4 col-sm-12 col-md-12 textright '>
              <span className="matchestxetxt"style={{fontSize:'12px',color:"#797979"}}>Passport No</span>
            </div>

            <div class="col-lg-6 col-sm-12 col-md-12  form-group">
            <input type="text" className="form-control matchestxetxt textboxbackcolorshadow" id="fst" name="identificationNo1"
              placeholder="Passport No" style={{fontSize:'14px',color:"#222227",textTransform:"capitalize",height:"39px"}} value={additional.identificationNo1} onChange={handleChangeforidentificationNo}  onBlur={identificationNoValidation}/> 
             {/* <div className='textcenter'>
      {error && additional.identificationNo1 == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            This is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
      </div> */}
            </div>
      
            
            </div>
            </div>
              )
              

            }
             {
              pancardDiv ==true && (
<div className='row margintop '>
            <div className='col-lg-4 col-sm-12 col-md-12 textright margintop1'>
              <span className="matchestxetxt"style={{fontSize:'12px',color:"#797979"}}>KYC Document</span>
            </div>

            <div class="col-lg-3 col-sm-12 col-md-12 margintop">
            <label for="selectFile" name="frontDocPhoto" className="uplod" style={{ backgroudColor: "white",width: '160px',height: '100px' }}   >
          
          </label>
    <input id="selectFile" type="file" value={additional.frontDocPhoto} className="fileupload hide" onChange={handleFrontPicChange}
      name="frontDocPhoto"
       />
              <label className='' style={{marginTop:"-74px",fontSize:"11px",display:"block",marginLeft:"55px",position:"absolute"}}>PAN Card</label>
              <div className='textcenter'style={{marginLeft:"-15px"}}><span className='event-sub-detail textShowondotBuss'>{selectedFilenameadharFront?.name}</span> <AiOutlineClose className={selectedFilenameadharFront?.name==null||selectedFilenameadharFront?.name==""?"hide":""} color='' size="1.1em"/></div>

              <div className='textleft'style={{marginLeft:"55px"}}><span className='bluetextforlink'>Upload</span></div> 


      <div class="form-group margintop1">
            <input type="text" className="form-control matchestxetxt textboxbackcolorshadow" id="panno" name="identificationNo1" onChange={handleChangeforidentificationNo}
              placeholder="PAN Card No." value={additional.identificationNo1} style={{fontSize:'14px',color:"#222227",textTransform:"capitalize",height:"39px"}}  onBlur={identificationNoValidation}/> 
                  
            </div>
             
      
      </div>
      <div class="col-lg-3 col-sm-12 col-l-md-12 margintop">
            <label for="selectFile1" name="backDocPhoto" className="uplod" style={{ backgroudColor: "white",width: '160px',height: '100px' }}   >
          
          </label>
    <input id="selectFile1" type="file" value={additional.backDocPhoto} className="fileupload hide" onChange={handleBackPicChange}
      name="backDocPhoto" placeholder=''
       />
       <label className='' style={{marginTop:"-74px",fontSize:"11px",display:"block",marginLeft:"55px",position:"absolute"}}> Driving License</label>
       <div className='textcenter'style={{marginLeft:"-15px"}}><span className='event-sub-detail textShowondotBuss'>{selectedFilenameadharBack?.name}</span> <AiOutlineClose className={selectedFilenameadharBack?.name==null||selectedFilenameadharBack?.name==""?"hide":""} color='' size="1.1em"/></div>

       <div className='textleft'style={{marginLeft:"55px"}}><span className='bluetextforlink'>Upload</span></div> 
      <div class="form-group margintop1">
            <input type="text" className="form-control matchestxetxt textboxbackcolorshadow" id="drivingno" name="identificationNo2"
              placeholder="Driving License No." style={{fontSize:'14px',color:"#222227",textTransform:"capitalize",height:"39px"}} value={additional.identificationNo2} onChange={handleChangeforidentificationNo} onBlur={identificationNoValidation2}/> 
                  
            </div>
            {/* <div className='textcenter'>
      {error && attachment.backDocPhoto == null && attachment.backDocPhoto == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            This is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
      </div> */}
      
      </div>
      <div className='row'>
    <div className='col-lg-4'></div>
    <div className=" col-lg-4 col-sm-12 col-md-12 ">
                                    {error && additional.identificationNo2 == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                             Driving License is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                  <div><lable className={IdEntNoValidEroor ? "show lablered1":"hide"} style={{ color: "red",fontSize:"12px" }}>{identificationError}</lable></div>
                                  <lable className={drivingValidError ? "show lablered1":"hide"} style={{ color: "red",fontSize:"12px" }}>Please Enter valid driving licence no.</lable>

                                  
                                </div>
                                
                                
  </div>
            </div>           
              )
              

            }
            

             <div className='row '>
    <div className='col-lg-4'></div>
    <div className=" col-lg-4 col-sm-12 col-md-12 ">
                                    {error && attachment.frontDocPhoto == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Frontside Picture is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>
  <div className='row '>
    <div className='col-lg-4'></div>
    <div className=" col-lg-4 col-sm-12 col-md-12 ">
                                    {error && attachment.backDocPhoto == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Backside Picture is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>
  <div className='row '>
    <div className='col-lg-4'></div>
    <div className=" col-lg-4 col-sm-12 col-md-12 ">
                                    {error && aadharDIv && additional.identificationNo1 == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Aadhar No is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    {error && pancardDiv&& additional.identificationNo1 == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Pancard No is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    {error && passportDiv && additional.identificationNo1 == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Passport No is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                  <div><lable className={IdEntNoValidEroor ? "show lablered1":"hide"} style={{ color: "red",fontSize:"12px"}}>{identificationError}</lable></div>
                                  <lable className={adharValidError ? "show lablered1":"hide"} style={{ color: "red",fontSize:"12px" }}>Please Enter valid Aadhar No.It contains space after four digits </lable>
                                  <lable className={panValidError ? "show lablered1":"hide"} style={{ color: "red",fontSize:"12px" }}>Please Enter valid Pancard No. </lable>
                                  <lable className={passportValidError ? "show lablered1":"hide"} style={{ color: "red",fontSize:"12px" }}>Please Enter valid Passport No. </lable>

                                  
                                  
                                </div>
                                
                                
  </div> 
  

  {/* <div className='row '>
    <div className='col-lg-4'></div>
    <div className=" col-lg-4 col-sm-12 col-md-12 ">
                                    {error && additional.identificationNo2 == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Identification No2. is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div> */}

            <div className='row margintop3'>
            <div className='col-lg-2 textright'>
            </div>
            <div className='col-lg-8 col-sm-12 col-md-12'>
              <span className='event-date' style={{fontSize:'14px',color:"#797979"}}>
              For Aadhar Card & Passport subscriber needs to upload front and back side both. If you don't have both then upload PAN Card and Driving License together.
            </span>
            </div>
            </div>
         

         
         

      </div>

        
         <div className="margintop row">
            <div className="col-lg-3 col-sm-12 col-md-12"></div>
            <div className="col-lg-3 col-sm-12 col-md-12 margintop3 textright">
            <button
               className="secondry-btnn "
              type='submit'
              onClick={handleBack}
            >
              Previous
            </button>
            </div>
          <div className="col-lg-5 col-sm-12 col-md-12  margintop3">
          <button
            className="primary-btnn  "
              variant="contained"
              color="primary"
              type='submit'
              style={{paddingLeft:"10px",paddingRight:"10px",width:"auto"}}
            >
            Submit and Start Socializing
            </button>
            
            
          </div>
          <div className="margintop2  row"></div>

        

            
            
          </div>
          <br/>
          </form>
 
    </div>
  );
};
 
export default VerifiIdentity;