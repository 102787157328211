import React, { useState, useEffect ,Suspense} from "react";
import './App.css';



import axios from "axios";
import { useHistory } from "react-router-dom";

import { HashRouter as Router, Switch, Route,Routes, Link, Redirect } from 'react-router-dom'
 import LandingPage from "./Component/LandingPage";
 import JoinNow from "./Component/JoinNow/JoinNow";
 import Case1 from "./Component/JoinNow/PaymentSuccess";
 import PaymentFail from "./Component/JoinNow/PaymentFail";
 import PaymentSuccess from "./Component/JoinNow/PaymentSuccess";
 import CompleteProfile from "./Component/CompletePfofile/CompleteProfile";
 import UserLanding from "./Component/UserLandingPages/UserLanding";
 import ViewProfile from "./Component/ViewProfile/ViewProfile";
//  import ViewProfileNew from "./Component/ViewProfile/ViewProfileNew";

 import Payment from "./Component/ViewProfile/Payment";
 import Contact from "./Component/ViewProfile/Contact";
 import ContactAll from "./Component/ViewProfile/ContactAll";
 import ContactInvitationsent from "./Component/ViewProfile/ContactInvitationsent";
 import ContactInvitationRecive from "./Component/ViewProfile/ContactInvitationRecive";
 import ContactBlock from "./Component/ViewProfile/ContactBlock";
 import Refferal from "./Component/ViewProfile/Refferal";
 import ReferralInvitaionSent from "./Component/ViewProfile/ReferralInvitaionSent";
 import Post from "./Component/ViewProfile/Post";
 import CoffeeMeets from "./Component/ViewProfile/CoffeeMeets";
 import BloodRequirement from "./Component/ViewProfile/BloodRequirement";
 import BloodReqCommunityReq from "./Component/ViewProfile/BloodReqCommunityReq";
 import AccountSetting from "./Component/ViewProfile/AccountSetting";
 import Login from "./Component/Login";
 import BloodReqMyReq from "./Component/ViewProfile/BloodReqMyReq";
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
 import LandingTest from "./Component/LandingTest";
 import FooterTest from "./Component/FooterTest";
 import UserLandingTest from "./Component/UserLandingPages/UserLandingTest";
 import BasicDetail from "./Component/CompletePfofile/BasicDetail";
 import ContactPrefrence from "./Component/CompletePfofile/ContactPreference";
 import VerifiIdentity from "./Component/CompletePfofile/VeryfiIdentity";
 import BasicInfo from "./Component/JoinNow/BasicInfo";
 import SubscribeCommunity from "./Component/JoinNow/SubscribeCommunity";
 import ForgotPassword from "./Component/ForgotPassword";
 import ForgotEntercode from "./Component/ForgotEntercode";
 import ForgotResetPassword from "./Component/ForgotResetPassword";
 import SignUpMatrimony from "./Component/SignUpMatrimony/SignUpMatrimony";
 import MatrimonyPlan from "./Component/SignUpMatrimony/MatrimonyPlan";
 import Subscribe from "./Component/SignUpMatrimony/SubScribe";
 import MatrimonyCompleteProfile from "./Component/MatrimonyCompleteProfile/MatrimonyCompleteProfile";
 import MatrimonyEducation from "./Component/MatrimonyCompleteProfile/MatrimonyEducation";
 import MatrimonyFeatureAndHealth from "./Component/MatrimonyCompleteProfile/MatrimonyFeatureAndHealth";
 import MatrimonyHoroscope from "./Component/MatrimonyCompleteProfile/MatrimonyHoroscop";
 import MatrimonyAlbum from "./Component/MatrimonyCompleteProfile/MatrimonyAlbum";
 import BusinessDetail from "./Component/BusinessCompleteProf/Businessdetail";
 import BusinessTiming from "./Component/BusinessCompleteProf/BusinessTiming";
 import BusinessCompletePfifile from "./Component/BusinessCompleteProf/BusinessCompleteProfile";
 import BusinessAboutPhoto from "./Component/BusinessCompleteProf/BusinessAboutPhotos";
 import BusinessAboutBusiness from "./Component/BusinessCompleteProf/BusinessAboutBusiness";
 import BusinessAddress from "./Component/BusinessCompleteProf/BusinessAddress";
 import AddJobPosting from "./Component/UserLandingPages/AddJobPosting";
 import ViewJobPosting from "./Component/UserLandingPages/ViewJobPosting";
 import MyJobPosting from "./Component/UserLandingPages/MyJobPosting";
 import MyJobPostingMain from "./Component/UserLandingPages/MyJobPostingMain";
 import RefferalMain from "./Component/ViewProfile/RefferalMain";
 import ContactMain from "./Component/ViewProfile/ContactMain";
 import MyBussProfileMain from "./Component/UserLandingPages/MyBussProfileMain";
 import MatrimonypartnerPrefrence from "./Component/MatrimonyCompleteProfile/MatrimonyPartnerPrefrence";
 import EditBasicDetail from "./Component/UserLandingPages/EditBasicDetail";
 import EditEducationalDetail from "./Component/UserLandingPages/EditEducationalDetail";
 import EditHealthDetail from "./Component/UserLandingPages/EditHealthDetail";
 import EditFamilybackground from "./Component/UserLandingPages/EditFamilybackground";
 import EditHoroscopeDetail from "./Component/UserLandingPages/EditHoroscopeDetail";
 import EditLifeStyleDetail from "./Component/UserLandingPages/EditLifeStyleDetail";
 import EditPreferenceDetail from "./Component/UserLandingPages/EditPreferenceDetail";
 import EditAboutYourself from "./Component/UserLandingPages/EditAboutYourself";
 import EditAlbum from "./Component/UserLandingPages/EditAlbum";
 import BusinessContactDetail from "./Component/SignUpBusiness/BusinessContactDetail";
 import RegisterAndCommunity from "./Component/SignUpBusiness/RegisterAndCommunity";
 import SignUpBusiness from "./Component/SignUpBusiness/SignUpBusiness";
 import JoinNowPrivacy from "./Component/JoinNow/JoinNowPrivacy";
 import NaiduSanghamCommunityPledge from "./Component/JoinNow/NaiduSanghamCommunityPledge";
 import TermsConditionsNaiduSangham from "./Component/JoinNow/TermsConditionsNaiduSangham";
 import MatrimonyTermsConsitions from "./Component/SignUpMatrimony/MatrimonyTermsConsitions";
 import BusinessTermsConditions from "./Component/SignUpBusiness/BusinessTermsConditions";
 import ViewContactRecive from "./Component/ViewProfile/ViewContactRecive";
 import ViewCoffeeMeetInvitatin from "./Component/ViewProfile/ViewCoffeeMeetInvitatin";
 import MatrimonyViewProf from "./Component/UserLandingPages/MatrimonyViewProf";
 import AccountMain from "./Component/ViewProfile/AccountMain";
 import BloodReqMain from "./Component/ViewProfile/BloodReqMain";
 import SliderPageNew from "./Component/UserLandingPages/SliderPageNew";
 import SponserBusiness from "./Component/UserLandingPages/SponserBusiness";
 import EditBusinessProfile from "./Component/UserLandingPages/EditBusinessProfile";
 import BusinessViewProfile from "./Component/UserLandingPages/BusinessViewProfile";
 import JobListingPrivacy from "./Component/JoinNow/JobListingPrivacy";
 import {configData} from "./Config"
import MatrimonyMyProf from "./Component/UserLandingPages/MatrimonyMyProf";
import BusinessListingMain from "./Component/UserLandingPages/BusinessListingMain";
import Maintanance from "./Component/Maintainance";
import AccountClosureMatrimony from "./Component/UserLandingPages/AccountClosureMatrimony";
import AccountSettingOld from "./Component/ViewProfile/AccountSettingOld";
// import UserLandingNew from "./Component/UserLandingPages/UserLandingNew";
import CommunityDirectoryMain from "./Component/UserLandingPages/CommunityDirectoryMain";
import MatrimonyAllProfMain from "./Component/UserLandingPages/MatrimonyAllProfMain";

// const LandingPage = React.lazy(() => import('./Component/LandingPage'));
// const JoinNow = React.lazy(() => import('./Component/JoinNow/JoinNow'));
// const Case1 = React.lazy(() => import('./Component/JoinNow/PaymentSuccess'));
// const PaymentFail = React.lazy(() => import('./Component/JoinNow/PaymentFail'));
// const PaymentSuccess = React.lazy(() => import('./Component/JoinNow/PaymentSuccess'));
// const CompleteProfile = React.lazy(() => import('./Component/CompletePfofile/CompleteProfile'));
// const UserLanding = React.lazy(() => import('./Component/UserLandingPages/UserLanding'));
// const ViewProfile = React.lazy(() => import('./Component/ViewProfile/ViewProfile'));
// const Payment = React.lazy(() => import('./Component/ViewProfile/Payment'));
// const Contact = React.lazy(() => import('./Component/ViewProfile/Contact'));
// const ContactAll = React.lazy(() => import('./Component/ViewProfile/ContactAll'));
// const ContactInvitationsent = React.lazy(() => import('./Component/ViewProfile/ContactInvitationsent'));
// const ContactInvitationRecive = React.lazy(() => import('./Component/ViewProfile/ContactInvitationRecive'));
// const ContactBlock = React.lazy(() => import('./Component/ViewProfile/ContactBlock'));
// const Refferal = React.lazy(() => import('./Component/ViewProfile/Refferal'));
// const ReferralInvitaionSent = React.lazy(() => import('./Component/ViewProfile/ReferralInvitaionSent'));
// const Post = React.lazy(() => import('./Component/ViewProfile/Post'));
// const CoffeeMeets = React.lazy(() => import('./Component/ViewProfile/CoffeeMeets'));
// const BloodRequirement = React.lazy(() => import('./Component/ViewProfile/BloodRequirement'));
// const BloodReqCommunityReq = React.lazy(() => import('./Component/ViewProfile/BloodReqCommunityReq'));
// const AccountSetting = React.lazy(() => import('./Component/ViewProfile/AccountSetting'));
// const Login = React.lazy(() => import('./Component/Login'));
// const BloodReqMyReq = React.lazy(() => import('./Component/ViewProfile/BloodReqMyReq'));
// const LandingTest = React.lazy(() => import('./Component/LandingTest'));
// const FooterTest = React.lazy(() => import('./Component/FooterTest'));
// const UserLandingTest = React.lazy(() => import('./Component/UserLandingPages/UserLandingTest'));
// const BasicDetail = React.lazy(() => import('./Component/CompletePfofile/BasicDetail'));
// const ContactPrefrence = React.lazy(() => import('./Component/CompletePfofile/ContactPreference'));
// const VerifiIdentity = React.lazy(() => import('./Component/CompletePfofile/VeryfiIdentity'));
// const BasicInfo = React.lazy(() => import('./Component/JoinNow/BasicInfo'));
// const SubscribeCommunity = React.lazy(() => import('./Component/JoinNow/SubscribeComunity'));
// const ForgotPassword = React.lazy(() => import('./Component/ForgotPassword'));
// const ForgotEntercode = React.lazy(() => import('./Component/ForgotEntercode'));
// const ForgotResetPassword = React.lazy(() => import('./Component/ForgotResetPassword'));
// const SignUpMatrimony = React.lazy(() => import('./Component/SignUpMatrimony/SignUpMatrimony'));
// const MatrimonyPlan = React.lazy(() => import('./Component/SignUpMatrimony/MatrimonyPlan'));
// const Subscribe = React.lazy(() => import('./Component/SignUpMatrimony/SubScribe'));
// const MatrimonyCompleteProfile = React.lazy(() => import('./Component/MatrimonyCompleteProfile/MatrimonyCompleteProfile'));
// const MatrimonyEducation = React.lazy(() => import('./Component/MatrimonyCompleteProfile/MatrimonyEducation'));
// const MatrimonyFeatureAndHealth = React.lazy(() => import('./Component/MatrimonyCompleteProfile/MatrimonyFeatureAndHealth'));
// const MatrimonyHoroscope = React.lazy(() => import('./Component/MatrimonyCompleteProfile/MatrimonyHoroscop'));
// const MatrimonyAlbum = React.lazy(() => import('./Component/MatrimonyCompleteProfile/MatrimonyAlbum'));
// const BusinessDetail = React.lazy(() => import('./Component/BusinessCompleteProf/Businessdetail'));
// const BusinessTiming = React.lazy(() => import('./Component/BusinessCompleteProf/BusinessTiming'));
// const BusinessCompletePfifile = React.lazy(() => import('./Component/BusinessCompleteProf/BusinessCompleteProfile'));
// const BusinessAboutPhoto = React.lazy(() => import('./Component/BusinessCompleteProf/BusinessAboutPhotos'));
// const BusinessAboutBusiness = React.lazy(() => import('./Component/BusinessCompleteProf/BusinessAboutBusiness'));
// const BusinessAddress = React.lazy(() => import('./Component/BusinessCompleteProf/BusinessAddress'));
// const AddJobPosting = React.lazy(() => import('./Component/UserLandingPages/AddJobPosting'));
// const ViewJobPosting = React.lazy(() => import('./Component/UserLandingPages/ViewJobPosting'));
// const MyJobPosting = React.lazy(() => import('./Component/UserLandingPages/MyJobPosting'));
// const MyJobPostingMain = React.lazy(() => import('./Component/UserLandingPages/MyJobPostingMain'));
// const RefferalMain = React.lazy(() => import('./Component/ViewProfile/RefferalMain'));
// const ContactMain = React.lazy(() => import('./Component/ViewProfile/ContactMain'));
// const MyBussProfileMain = React.lazy(() => import('./Component/UserLandingPages/MyBussProfileMain'));
// const MatrimonypartnerPrefrence = React.lazy(() => import('./Component/MatrimonyCompleteProfile/MatrimonyPartnerPrefrence'));
// const EditBasicDetail = React.lazy(() => import('./Component/UserLandingPages/EditBasicDetail'));
// const EditEducationalDetail = React.lazy(() => import('./Component/UserLandingPages/EditEducationalDetail'));
// const EditHealthDetail = React.lazy(() => import('./Component/UserLandingPages/EditHealthDetail'));
// const EditFamilybackground = React.lazy(() => import('./Component/UserLandingPages/EditFamilybackground'));
// const EditHoroscopeDetail = React.lazy(() => import('./Component/UserLandingPages/EditHoroscopeDetail'));
// const EditLifeStyleDetail = React.lazy(() => import('./Component/UserLandingPages/EditLifeStyleDetail'));
// const EditPreferenceDetail = React.lazy(() => import('./Component/UserLandingPages/EditPreferenceDetail'));
// const EditAboutYourself = React.lazy(() => import('./Component/UserLandingPages/EditAboutYourself'));
// const EditAlbum = React.lazy(() => import('./Component/UserLandingPages/EditAlbum'));
// const BusinessContactDetail = React.lazy(() => import('./Component/SignUpBusiness/BusinessContactDetail'));
// const RegisterAndCommunity = React.lazy(() => import('./Component/SignUpBusiness/RegisterAndCommunity'));
// const SignUpBusiness = React.lazy(() => import('./Component/SignUpBusiness/SignUpBusiness'));
// const JoinNowPrivacy = React.lazy(() => import('./Component/JoinNow/JoinNowPrivacy'));
// const NaiduSanghamCommunityPledge = React.lazy(() => import('./Component/JoinNow/NaiduSanghamCommunityPledge'));
// const NaiduSanghamTermsConditions = React.lazy(() => import('./Component/JoinNow/NaiduSanghamTermsConditions'));
// const TermsConsitionsMatrimony = React.lazy(() => import('./Component/SignUpMatrimony/TermsConditionsMatrimony'));
// const TermsConditionsBusiness = React.lazy(() => import('./Component/SignUpBusiness/TermsConditionsBusiness'));
// const ViewContactRecive = React.lazy(() => import('./Component/ViewProfile/ViewContactRecive'));
// const ViewCoffeeMeetInvitatin = React.lazy(() => import('./Component/ViewProfile/ViewCoffeeMeetInvitatin'));
// const MatrimonyViewProf = React.lazy(() => import('./Component/UserLandingPages/MatrimonyViewProf'));
// const AccountMain = React.lazy(() => import('./Component/ViewProfile/AccountMain'));
// const BloodReqMain = React.lazy(() => import('./Component/ViewProfile/BloodReqMain'));
// const SliderPageNew = React.lazy(() => import('./Component/UserLandingPages/SliderPageNew'));
// const SponserBusiness = React.lazy(() => import('./Component/UserLandingPages/SponserBusiness'));
// const EditBusinessProfile = React.lazy(() => import('./Component/UserLandingPages/EditBusinessProfile'));
// const BusinessViewProfile = React.lazy(() => import('./Component/UserLandingPages/BusinessViewProfile'));
// const JobListingPrivacy = React.lazy(() => import('./Component/JoinNow/JobListingPrivacy'));






function App() {

  //for pingmonk
  const [monitors, setMonitors] = useState([]);

    useEffect(() => {
        async function fetchMonitors() {
            try {
                const response = await axios.get('https://api.pingmonk.com/v1/monitors', {
                    headers: {
                        'Authorization': 'Bearer YOUR_API_KEY'
                    }
                });
                setMonitors(response.data);
            } catch (error) {
                console.error('Error fetching monitors:', error);
            }
        }

        fetchMonitors();
    }, []);

 
  //"proxy": "https://naidudirectory.org"
  //for session
  //const history = useHistory();

//   const [session, setSession] = useState(false);


//   useEffect(() => {
//     loadSession();
//   }, []);

//   const loadSession = async () => {
//     debugger;
//     const result = await axios.get("/checkSession");
//     if(result.data.code==501){
//       setSession(true)
//     }
//     else{
//       setSession(false);
//       //history.push("/Login");

//     }
// console.log("checksession",session)
//   };

const [updatedData, setupdatedData] = useState(false);


  useEffect(() => {
    loadUpdatedData();
   }, []);

   const loadUpdatedData = async () => {
     debugger;
     const result = await axios.get(`${configData.ApiUrl}/getUpdatedData?emailId=${authlocalstoragedata?.emailId}`);
     setupdatedData(result.data.data);

     
 console.log("updatedadata",updatedData)
   };
debugger

 const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
//   console.log("authdataappjs", authlocalstoragedata)


  //for loader
  const [loading, setLoading] = useState(true);
  const spinner = document.getElementById("spinner");
  if (spinner) {
    setTimeout(() => {
      spinner.style.display = "none";
      setLoading(false);
    }, 500);
  }

  
  return (
    <div className="App">
    { !loading && (
      <Router>
       
       <Switch>
      
       {/* {session == false?  (
          <Route  path='/Login' component={Login}/>

        ):( */}
        {/* {
          authlocalstoragedata?.kycStatus ==null &&  authlocalstoragedata?.kycStatus ==''?( */}
             {/* <Route exact path='/' component={LandingPage}/>  */}
             
            {/* <Route exact path="/">
  {authlocalstoragedata==null || authlocalstoragedata?.kycStatus ==null &&  authlocalstoragedata?.kycStatus ==''? <Redirect to="/LandingPage" /> : <Redirect to='/UserLanding' />}
</Route> */}

<Route exact path="/">
  {authlocalstoragedata ==null || authlocalstoragedata?.kycStatus ==null ||  authlocalstoragedata?.kycStatus ==''?
   (<LandingPage/>): (<UserLanding/>)}
</Route>
{/* <Suspense fallback={<div>Loading...</div>}> */}
          
          <div>
          <Route  path='/Login' component={Login}/>
          <Route exact path='/LandingPage' component={LandingPage}/>
          <Route exact path='/Maintanance' component={Maintanance}/>


     <Route exact path='/JoinNow/:null' component={JoinNow}/>
     <Route exact path='/BasicInfo' component={BasicInfo}/>
     <Route exact path='/AccountClosureMatrimony' component={AccountClosureMatrimony}/>

  
     <Route exact path='/SubscribeCommunity' component={SubscribeCommunity}/>

     <Route exact path='/JoinNowPrivacy' component={JoinNowPrivacy}/>
     <Route exact path='/NaiduSanghamCommunityPledge' component={NaiduSanghamCommunityPledge}/>
     <Route exact path='/TermsConditionsNaiduSangham' component={TermsConditionsNaiduSangham}/>
     <Route exact path='/MatrimonyTermsConsitions' component={MatrimonyTermsConsitions}/>
     <Route exact path='/BusinessTermsConditions' component={BusinessTermsConditions}/>
     <Route exact path='/JobListingPrivacy' component={JobListingPrivacy}/>


     <Route exact path='/CompleteProfile' component={CompleteProfile}/>
     <Route exact path='/BasicDetail' component={BasicDetail}/>
     <Route exact path='/ContactPreference' component={ContactPrefrence}/>
     <Route exact path='/VeryfiIdentity' component={VerifiIdentity}/>

     <Route exact path='/PaymentSuccess' component={PaymentSuccess}/> 
     <Route exact path='/PaymentFail' component={PaymentFail}/> 
     
     <Route exact path='/UserLanding' component={UserLanding}/>
     {/* <Route exact path='/UserLandingNew' component={UserLandingNew}/> */}
     <Route exact path='/CommunityDirectoryMain' component={CommunityDirectoryMain}/>
     <Route exact path='/MatrimonyAllProfMain' component={MatrimonyAllProfMain}/>


     
     <Route exact path='/viewProfile' component={ViewProfile}/>

      <Route exact path='/Payment' component={Payment}/>
     <Route exact path='/Contact' component={Contact}/>
     <Route exact path='/ContactAll' component={ContactAll}/>
     <Route exact path='/ContactInvitationsent' component={ContactInvitationsent}/>
     <Route exact path='/ContactInvitationRecive' component={ContactInvitationRecive}/>
     <Route exact path='/ContactBlock' component={ContactBlock}/>
     <Route exact path='/Refferal' component={Refferal}/>
     <Route exact path='/RefrralInvitaionSent' component={ReferralInvitaionSent}/>
     <Route exact path='/Post' component={Post}/>
     <Route exact path='/CoffeeMeets' component={CoffeeMeets}/>
     <Route exact path='/BloodRequirement' component={BloodRequirement}/>
     <Route exact path='/BloodReqCommunityReq' component={BloodReqCommunityReq}/>
     <Route exact path='/AccountSetting' component={AccountSetting}/>
     <Route exact path='/AccountSettingOld' component={AccountSettingOld}/>

     <Route exact path='/BloodReqMyReq' component={BloodReqMyReq}/>
     <Route exact path='/LandingTest' component={LandingTest}/>
     <Route exact path='/FooterTest' component={FooterTest}/>
     <Route exact path='/UserLandingTest' component={UserLandingTest}/>
     <Route exact path='/ForgotPassword' component={ForgotPassword}/>
     <Route exact path='/ForgotEntercode' component={ForgotEntercode}/>
     <Route exact path='/ForgotResetPassword' component={ForgotResetPassword}/>
     <Route exact path='/SignUpMatrimony' component={SignUpMatrimony}/>
     <Route exact path='/MatrimonyPlan' component={MatrimonyPlan}/>
     <Route exact path='/Subscribe' component={Subscribe}/>
     <Route exact path='/SliderPageNew' component={SliderPageNew}/>
     <Route exact path='/SponserBusiness' component={SponserBusiness}/>



     <Route exact path='/BusinessDetail' component={BusinessDetail}/>
     <Route exact path='/BusinessContactDetail' component={BusinessContactDetail}/>
     <Route exact path='/RegisterAndCommunity' component={RegisterAndCommunity}/>
     <Route exact path='/SignUpBusiness' component={SignUpBusiness}/>


    <Route exact path='/MatrimonyCompleteProfile' component={MatrimonyCompleteProfile}/>
     <Route exact path='/MatrimonyBasicDetail' component={MatrimonyPlan}/>
     <Route exact path='/MatrimonyEducation' component={MatrimonyEducation}/>
     <Route exact path='/MatrimonyFeatureAndHealth' component={MatrimonyFeatureAndHealth}/>
     <Route exact path='/MatrimonyHoroscope' component={MatrimonyHoroscope}/>
     <Route exact path='/MatrimonyAlbum' component={MatrimonyAlbum}/>
     <Route exact path='/MatrimonypartnerPrefrence' component={MatrimonypartnerPrefrence}/>
     <Route exact path='/MatrimonyViewProf/:id' component={MatrimonyViewProf}/>
     <Route exact path='/MatrimonyMyProf' component={MatrimonyMyProf}/>


     
     <Route exact path='/BusinessDetail' component={BusinessDetail}/>
     <Route exact path='/BusinessAddress' component={BusinessAddress}/>
     <Route exact path='/BusinessTiming' component={BusinessTiming}/>
     <Route exact path='/BusinessCompleteProfile' component={BusinessCompletePfifile}/>
     <Route exact path='/BusinessAboutPhoto' component={BusinessAboutPhoto}/>
     <Route exact path='/BusinessAboutBusiness' component={BusinessAboutBusiness}/>
     <Route exact path='/BusinessViewProfile/:id' component={BusinessViewProfile}/>
     <Route exact path='/BusinessListingMain' component={BusinessListingMain}/>


     <Route exact path='/AddJobPosting' component={AddJobPosting}/>
     <Route exact path='/ViewJobPosting/:id' component={ViewJobPosting}/>
     <Route exact path='/MyJobPosting' component={MyJobPosting}/>
     <Route exact path='/MyJobPostingMain' component={MyJobPostingMain}/>
     <Route exact path='/RefferalMain' component={RefferalMain}/>
     <Route exact path='/ContactMain' component={ContactMain}/>
     <Route exact path='/ViewContactRecive' component={ViewContactRecive}/>
     <Route exact path='/ViewCoffeeMeetInvitatin' component={ViewCoffeeMeetInvitatin}/>
     <Route exact path='/AccountMain' component={AccountMain}/>
     <Route exact path='/BloodReqMain' component={BloodReqMain}/>




     <Route exact path='/MyBussProfileMain' component={MyBussProfileMain}/>
     <Route exact path='/EditBasicDetail/:id' component={EditBasicDetail}/>
     <Route exact path='/EditEducationDetail/:id' component={EditEducationalDetail}/>
     <Route exact path='/EditHealthDetail/:id' component={EditHealthDetail}/>

     <Route exact path='/EditFamilybackground/:id' component={EditFamilybackground}/>
     <Route exact path='/EditHoroscopeDetail/:id' component={EditHoroscopeDetail}/>
     <Route exact path='/EditLifeStyleDetail/:id' component={EditLifeStyleDetail}/>
     <Route exact path='/EditPreferenceDetail/:id' component={EditPreferenceDetail}/>
     <Route exact path='/EditAboutYourself/:id' component={EditAboutYourself}/>
     <Route exact path='/EditAlbum/:id' component={EditAlbum}/>

     <Route exact path='/EditBusinessProfile/:id' component={EditBusinessProfile}/> 


</div>


{/* </Suspense> */}

</Switch>
     <ToastContainer autoClose={2500}  />

 
 
     </Router> 
       ) 
        } 
        <ul>
                {monitors.map(monitor => (
                    <li key={monitor.id}>{monitor.name}</li>
                ))}
            </ul> 
 </div>
  );
}

export default App;
