import React, { useState, useEffect } from "react";
import logo from '../../images/Naidus directory.png';
import '../JoinNow/JoinNow.css';
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
import {configData} from "../../Config"

import {
  Typography,
  TextField,
  Button,
  Stepper,
  Step,
  StepLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import axios from "axios";
import MatrimonyBasicDetail from "./MatrimonyBasicDetail";
import MatrimonyEducation from "./MatrimonyEducation";
import MatrimonyFeatureAndHealth from "./MatrimonyFeatureAndHealth";
import MatrimonyHoroscope from "./MatrimonyHoroscop";
import MatrimonyAlbum from "./MatrimonyAlbum";
import MatrimonypartnerPrefrence from "./MatrimonyPartnerPrefrence";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

function getSteps() {
  return [
    "Basic details", "Education ", "Feature & Health","Horoscope","Partner Preference","Album"
  ];
}



const MatrimonyCompleteProfile = () => {

  //for session

  const [session ,setSession] = useState(false);

  // const gesessiondata = async () => {
  //    debugger;
  //    axios.get('/checkSession')
      
  //    .then(result =>{
  //      if(result.data.code == 501){
  //        setSession(true)  ;
  //         }
  //     else{
  //          history.push("/Login");

  //     }
  //      debugger;
  //      console.log(result.data.code);
  //    }).catch(error =>{
  //      console.log(error);
  //    })
  //  };

  //        console.log("checksession on campaign",session);

  // useEffect(() => {
  //     gesessiondata();
  //   }, [session]);
  

  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  var newStepValue=activeStep+2;


  

//for new session for userlogin
// const redirecToLandingdata =  () => {
 
//   if(authlocalstoragedata?.kycStatus ==null &&  authlocalstoragedata?.kycStatus ==''){
//     history.push("/");

//   }
// }
   
//    useEffect(() => {
//     redirecToLandingdata();
//  });

  const getStepContent = () => {
    debugger;
    if (newStepValue === 0) {
      return <MatrimonyBasicDetail basicDetails={basicDetails} setbasicDetails={setbasicDetails} handleBack={handleBack} handleNext={handleNext} attachmentProf={attachmentProf} setAttachmentProf={setAttachmentProf}/>;
    } else if (newStepValue === 1) {
      return <MatrimonyEducation educationDetails={educationDetails} seteducationDetails={seteducationDetails} handleBack={handleBack} handleNext={handleNext}/>;
    } else if(newStepValue === 2){
      return <MatrimonyFeatureAndHealth healthDetails={healthDetails} sethealthDetails={sethealthDetails}  handleBack={handleBack} handleNext={handleNext}/>;
    }
    else if (newStepValue === 3){
        return <MatrimonyHoroscope horoscopeDetails={horoscopeDetails} sethoroscopeDetails={sethoroscopeDetails}  handleBack={handleBack} handleNext={handleNext}/>;
      }
      else if (newStepValue === 4){
        return <MatrimonypartnerPrefrence preferenceDetails={preferenceDetails} setpreferenceDetails={setpreferenceDetails} handleBack={handleBack} handleNext={handleNext}/>;

      }
      else {
        return <MatrimonyAlbum imageDetails={imageDetails} setimageDetails={setimageDetails} attachmentphotoOne={attachmentphotoOne} setattachmentphotoOne={setattachmentphotoOne} attachmentphotoTwo={attachmentphotoTwo} setattachmentphotoTwo={setattachmentphotoTwo} attachmentphotoThree={attachmentphotoThree} setattachmentphotoThree={setattachmentphotoThree} attachmentphotoFour={attachmentphotoFour} setattachmentphotoFour={setattachmentphotoFour} handleBack={handleBack} handleNext={handleNext}/>;

      }
  };



  const [updatedData, setupdatedData] = useState(false);


  useEffect(() => {
    loadUpdatedData();
   }, []);

   const loadUpdatedData = async () => {
     debugger;
     const result = await axios.get(`${configData.ApiUrl}/getUpdatedData?userId=${authlocalstoragedata.userAuthenticationId}`);
     setupdatedData(result.data.data);

     
 console.log("updatedadata",updatedData)
   };

   const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
console.log("authdata11",authlocalstoragedata)
  
  const [loaclId, setloaclId] = useState("");
const [localusername, setLocalUsername] = useState("");

useEffect(() => {  
  setLocalUsername(localStorage.getItem("localusername"))
  setloaclId(localStorage.getItem("loaclId"))
    
  
})
  const username = localStorage.getItem('name');
  //const matrimonyId = localStorage.getItem('id');
// Retrieve and convert it back to an integer
const matrimonyId = parseInt(localStorage.getItem('id'), 10);  // Base 10
  //for updating authdata


  //const userid = localStorage.id;
  debugger;
  if(authlocalstoragedata !=null && authlocalstoragedata?.matrimonyId !=null 
    && authlocalstoragedata?.name1 != null && authlocalstoragedata?.matrimonyId !=0){
    localStorage.id = authlocalstoragedata?.matrimonyId;
    localStorage.name = authlocalstoragedata?.name1;

  }else{
    localStorage.name = localStorage.name;

    localStorage.id = matrimonyId;
  }
  //for 1st step
  const [basicDetails, setbasicDetails] = useState({
    matrimonyId:localStorage.id,
    name:authlocalstoragedata?.name1,
    gender:null,
    dateOfBirth:authlocalstoragedata?.birthDate,
    bloodGroup:null,
    maritalStatus:null,
    primaryMobileNumber:authlocalstoragedata?.mobileNumber,
    secondaryMobileNumber:null,
    emailId:authlocalstoragedata?.emailId,
  })

  //for second step
  const [educationDetails, seteducationDetails] = useState({
    matrimonyId:localStorage.id,
    education:null,
    occupation:null,
    employmentType:null,
    annualIncome:null,
    familyValues:null,
    diet:null,
    drinks:null,
    smokes:null,
  })
  //for third step
  const [healthDetails, sethealthDetails] = useState({
    matrimonyId:localStorage.id,
    heightFeet:null,
    heightInches:null,
    weight:null,
    skinTone:null,
    bodyType:null,
    healthInformation:null,
    disability:null,
    glasses:null,
  })
  //for fourth step
  const [horoscopeDetails, sethoroscopeDetails] = useState({
    matrimonyId:localStorage.id,
    dob:authlocalstoragedata?.birthDate,
    time:null,
    placeOfBirth:null,
    raashi:null,
    goatra:null,
    nakshatra:null,
    manglik:null,
  })
  
  //for fifth step
  const [preferenceDetails, setpreferenceDetails] = useState({
    matrimonyId:localStorage.id,
    education:null,
    complexion:null,
    caste:null,
    ageFrom:null,
    ageTo:null,
    heightFeetFrom:null,
    heightInchesFrom:null,
    heightFeetTo:null,
    heightInchesTo:null,
    otherExpectations:null,

  })
 
  //for sixth step
  const [imageDetails, setimageDetails] = useState({
    matrimonyId:localStorage.id,
    
    aboutYourself:null,
    familyBackground:null,
  })

  
  

  // const [attachment, setAttachment] = useState({
    
  //   photoOne: null,
  //   photoTwo: null,
  //   photoThree: null,
  //   photoFour: null,

  //   })
    const [attachmentphotoOne, setattachmentphotoOne] = useState(null)
    const [attachmentphotoTwo, setattachmentphotoTwo] = useState(null)
    const [attachmentphotoThree, setattachmentphotoThree] = useState(null)
    const [attachmentphotoFour, setattachmentphotoFour] = useState(null)



    const [attachmentProf, setAttachmentProf] = useState(null)
    

    let history = useHistory();


   const isStepOptional = (step) => {
     return step === 6 || step === 7;
   };

  // const isStepSkipped = (step) => {
  //   return skippedSteps.includes(step);
  // };

  const handleNext = () => {

    // setAppointment(preState => ({
    //   ...preState,
    //   attachFiles : serviceList
    // }))
    if (newStepValue === 0) {
       const formData = new FormData();
     debugger
    
      formData.append('profile',JSON.stringify({basicDetails}));
      
        formData.append('profilePhoto', attachmentProf);
      // formData.append('photo1', attachment.photo1);
      

 const response = axios.post(configData.ApiUrl+'/matrimony/completeMatrimonyProfile',formData,{
   headers: {
     'content-type': 'multipart/form-data'
 }
 }

 )
      response.then(() => {
         toast.success("Data Added Successfully!");
         newStepValue=1;

       })
       response.catch((err) => {
        alert("Getting error in featching data");
        newStepValue=0;

       });
    }

   if (newStepValue === 1) {
    debugger
     const formData = new FormData();


    
      
    formData.append('profile',JSON.stringify({educationDetails}));
      
//       // formData.append('profilePhoto', attachment.profilePhoto);
    

 const response = axios.post(configData.ApiUrl+'/matrimony/completeMatrimonyProfile',formData,{
   headers: {
     'content-type': 'multipart/form-data'
 }
 }

 )
      response.then(() => {
         toast.success("Data Added Successfully!");
         newStepValue=2;

      })
       response.catch((err) => {
         alert("Getting error in featching data");
         newStepValue=1;

       });

    }
  if (newStepValue === 2) {
      debugger
//       setadditional(preState => ({
//         ...preState,
//         "frontDocPhoto" : attachment.frontDocPhoto
//       }))
//       setadditional(preState => ({
//         ...preState,
//         "backDocPhoto" : attachment.backDocPhoto
//       }))

       const formData = new FormData();
  
  
//       additional.gender = null;
//       additional.birthDate = null;
//       additional.bloodGroup = null;
//       additional.maritalStatus = null;
//       additional.familyCount = null;
//       additional.profession = null;
//       additional.natureOfBuisness = null;
//       additional.mobileVisibility=null;
//       additional.homeCountryCode=null;
//       additional.homeCountry=null;
//     additional.homeStateCode=null;
//     additional.homeState=null;
//     additional.homeCityCode=null;
//     additional.homeCity=null;
//     additional.homePinCode=null;
//     additional.homeAddress1=null;
//     additional.homeAddress2=null;
//     additional.homeLandmark=null;
//     additional.currentCountryCode=null;
//     additional.currentCountry=null;
//     additional.currentStateCode=null;
//     additional.currentState=null;
//     additional.currentCityCode=null;
//     additional.currentCity=null;
//     additional.currentPinCode=null;
//     additional.currentAddress1=null;
//     additional.currentAddress2=null;
//     additional.currentLandmark=null;
        
//       debugger

        
      formData.append('profile',JSON.stringify({healthDetails}));
        
          //formData.append('frontDocPhoto', attachment.frontDocPhoto);
          // formData.append('backDocPhoto', attachment.backDocPhoto);

      
  
           const response = axios.post(configData.ApiUrl+'/matrimony/completeMatrimonyProfile',formData,{
            headers: {
              'content-type': 'multipart/form-data'
          }
          }
  
  )
         response.then(() => {
           toast.success("Data Added Successfully!");
           newStepValue=3;

         })
        response.catch((err) => {
           alert("Getting error in featching data");
           newStepValue=2;

         });
//   history.push("/UserLanding")
      }
   if (newStepValue === 3) {
        debugger
         const formData = new FormData();
    
    
        
          
        formData.append('profile',JSON.stringify({horoscopeDetails}));
          
    //       // formData.append('profilePhoto', attachment.profilePhoto);
        
    
     const response = axios.post(configData.ApiUrl+'/matrimony/completeMatrimonyProfile',formData,{
       headers: {
         'content-type': 'multipart/form-data'
     }
     }
    
     )
          response.then(() => {
             toast.success("Data Added Successfully!");
             newStepValue=4;

          })
           response.catch((err) => {
             alert("Getting error in featching data");
             newStepValue=3;

           });
    
        }
        if (newStepValue === 4) {
          debugger
           const formData = new FormData();
      
      
          
            
          formData.append('profile',JSON.stringify({preferenceDetails}));
            
      //       // formData.append('profilePhoto', attachment.profilePhoto);
          
      
       const response = axios.post(configData.ApiUrl+'/matrimony/completeMatrimonyProfile',formData,{
         headers: {
           'content-type': 'multipart/form-data'
       }
       }
      
       )
            response.then(() => {
               toast.success("Data Added Successfully!");
               newStepValue=5;

      
            })
             response.catch((err) => {
               alert("Getting error in featching data");
               newStepValue=4;

             });
      
          }
          if (newStepValue === 5) {
            debugger
            
            // setAttachment(preState => ({
            //   ...preState,
            //   "photoOne" : attachment.photoOne
            // }))
            
            // setAttachment(preState => ({
            //   ...preState,
            //   "photoTwo" : attachment.photoTwo
            // }))
            
            // setAttachment(preState => ({
            //   ...preState,
            //   "photoThree" : attachment.photoThree
            // }))

            // setAttachment(preState => ({
            //   ...preState,
            //   "photoFour" : attachment.photoFour
            // }))
            setattachmentphotoOne(attachmentphotoOne);
            setattachmentphotoTwo(attachmentphotoTwo);
            setattachmentphotoThree(attachmentphotoThree);
            setattachmentphotoFour(attachmentphotoFour);


       
             const formData = new FormData();
           
             formData.append('profile',JSON.stringify({imageDetails}));
           formData.append('photoOne',attachmentphotoOne);
          formData.append('photoTwo', attachmentphotoTwo);
          formData.append('photoThree', attachmentphotoThree);
          formData.append('photoFour', attachmentphotoFour);

              
        //       // formData.append('profilePhoto', attachment.profilePhoto);
            
        
         const response = axios.post(configData.ApiUrl+'/matrimony/completeMatrimonyProfile',formData,{
           headers: {
             'content-type': 'multipart/form-data'
         }
         }
        
         )
              response.then(() => {
                 toast.success("Data Added Successfully!");
             history.push("/Userlanding")
        
              })
               response.catch((err) => {
                 alert("Getting error in featching data")
               });
        
            }
    setActiveStep(activeStep+1);
    newStepValue =newStepValue+1;

    // setSkippedSteps(skippedSteps.filter((skipItem) => skipItem !== activeStep));
  };

  
  
  const handleBack = () => {
    newStepValue =newStepValue-1;

    setActiveStep(activeStep - 1);
  };

  // const handleSkip = () => {
  //   if (!isStepSkipped(activeStep)) {
  //     setSkippedSteps([...skippedSteps, activeStep]);
  //   }
  //   setActiveStep(activeStep + 1);
  // };
  newStepValue = activeStep;
//   if(activeStep !=0)
//   {
//     newStepValue = activeStep+0;
// }
//   if(activeStep !=0)
//   {
//     newStepValue = activeStep+1;
// }
// if(activeStep !=0)
//   {
//     newStepValue = activeStep+2;
// }
// debugger

//   if(authlocalstoragedata != null && authlocalstoragedata?.birthDate==null && activeStep === 0)
//   {
//     debugger;
//     newStepValue=0;

//   }
//   else if(authlocalstoragedata != null && authlocalstoragedata?.currentCountry==null && activeStep === 0)
//   {
//     debugger;
//     newStepValue=1;

//   }
//  else if(authlocalstoragedata != null && authlocalstoragedata?.kycStatus==null && activeStep === 0)
//   {
//     debugger;
//     newStepValue=2;

//   }
//   else {
//     debugger;
//     //history.push("/UserLanding")

//   }
  
  return (
    <div className=" container-fluid">

          <div className="margintop row">
            <div className="col-1">                 </div>
            <div className="textleft col-1">
            {/* <img src={logo}  style={{width:"104px", height:"77px"}}/> */}
            </div>
            {/* <div className="col-1"></div> */}
            <div className="col-9">
<span className='registerspn merriweatherfont'>Complete your profile to connect with the community ...</span>
<div className="margintop3">
<div className="row marglrftrightminusrow">
          <div className={activeStep === 1?"col-11 form-container2":"col-11 form-container1"}>
<Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((step, index) => {
          const labelProps = {};
          const stepProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography
                variant="caption"
                align="center"
                style={{ display: "block" }}
              >
                optional
              </Typography>
            );
          }
          // if (isStepSkipped(index)) {
          //   stepProps.completed = false;
          // }
          return (
            <Step {...stepProps} key={index}>
              <StepLabel {...labelProps}>{step}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {activeStep === steps.length ? (
        <Typography variant="h5" align="center">
          Now Your Profile is Completed!
             </Typography>
      ) : (
        <>
        
          <div className="">{getStepContent(activeStep)}</div>
          
          
          
        </>
      )}
</div>
</div>
</div>
</div>
          </div>
      
          

    </div>
  );
};

export default MatrimonyCompleteProfile;