import React, { useState, useEffect, useCallback,useRef,useReducer } from "react";

import './UserLandingTest.css';
import clogo from '../../images/Naidus directory_Symbol.svg';
import profile from '../../images/sagarsir.svg';
import profileshital from '../../images/shitalnaidu.svg';

import { MdOutlineArrowDropDown, MdLocationOn, MdNotifications, MdWaterDrop } from 'react-icons/md';
import { Link } from "react-router-dom";
import { CgProfile } from 'react-icons/cg'
import { Box } from '@material-ui/core';
// import { Slider } from '@material-ui/core';
import AddNewsPopup from "./AddNewsPopup.js";
import eventlogo from '../../images/eventlogo.svg';
import eventimg1 from '../../images/event1.png';
import eventimg2 from '../../images/event2.png';
import eventimg3 from '../../images/event3.png';
import eventimg4 from '../../images/eventimg4jpg.jpg';
import { BiLike, } from 'react-icons/bi';
import { FaRegComments, FaUserClock, FaFilter,FaThumbsDown, FaInfoCircle,FaSearch,FaArrowLeft, FaRegHandshake, FaEdit, FaBookmark } from 'react-icons/fa';

import { AiOutlineDislike, AiFillLike, AiOutlineHeart,AiOutlineFilePdf ,AiFillHeart,AiOutlineClose,AiFillDislike} from 'react-icons/ai'
import { HiOutlineLogout } from 'react-icons/hi'
import { BsBookmark } from 'react-icons/bs'
import { RiCupFill, RiSendPlaneFill, RiTimeLine ,} from 'react-icons/ri';
import { RiSpam2Line } from "react-icons/ri";
import { MdMessage } from "react-icons/md";

import imgupload from '../../images/imageupload.svg'
import blogimg from '../../images/blog.png'
import kycimg from '../../images/kyc.png'
import AddBlogPopup from "./AddBlogPopup.js";
import AddKycPopup from "./AddKycPopup.js";
import flag from '../../images/india.png';
import subcast from '../../images/subcast.svg';
import refferal from '../../images/refferalsvg.svg';
import cups from '../../images/cups.svg';
import from from '../../images/from.svg';
import radius from '../../images/radius.svg';
import conprof from '../../images/coneect.svg';
import hotelpic from '../../images/u1165.png'
import bussslider from '../../images/bussslider.png'
import bussslider1 from '../../images/bussslider1.png'

import contactinfo from '../../images/contactinfo.png';
import connect from '../../images/connect.png';
import ContactInfoPopup from "./ContactInfoPopup.js";
import NotificationPopup from "./NotificationPopup.js";
import { MdAddBox, MdWarning,MdOutlineClose } from 'react-icons/md';
import SliderPage from "./SliderPage.js";
import BloodRequirePopup from "./BloodRequirePopup.js";
import MessagePopup from "./MessagePopup.js";
import { Badge, Button, Tab, Nav, Card, Col, Form, FormGroup, Row } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { MdClose } from 'react-icons/md'
import { event } from "jquery";
import { ImCross, ImMobile } from 'react-icons/im'
import { AiOutlineCalendar } from 'react-icons/ai'
import { TbCheckbox } from 'react-icons/tb'
import { FaWhatsapp, FaArrowRight, FaMapPin } from 'react-icons/fa'
import { MdEmail } from 'react-icons/md'
import ReferInviteCommMemberPopup from "../ViewProfile/ReferInviteCommMemberPopup.js";
import Gallery from "react-photo-gallery";
import Carousel1, { Modal, ModalGateway } from "react-images";
import { photos } from "./photos.js";
// import Carousel1 from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";
import Carousel from 'react-grid-carousel'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import SignUpMatrimony from "../SignUpMatrimony/SignUpMatrimony.js";
import { BsThreeDots } from 'react-icons/bs';
import './Blockpopup.css';
import { MdArrowDropDown } from 'react-icons/md'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment, { duration } from 'moment'
import csc from "country-state-city";
import Select from "react-select";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import MySubscription from "./DataBussnes.js";
import SimpleImageSlider from "react-simple-image-slider";
import MyJobPosting from "./MyJobPosting.js";
import MyJobPostingMain from "./MyJobPostingMain.js";
import MySubscriptionMatrimony from "./MySubscriptionMatrimony.js";
import SignUpBusiness from "../SignUpBusiness/SignUpBusiness.js";
import JobAllSeePop from "./JobAllSeePop.js";
import ReactPaginate from 'react-paginate';
import Avatar from '@mui/material/Avatar';
import SliderPageNew from "./SliderPageNew.js";
import MessagePopupDirectory from "./MessegePopupDirectory.js";
import SponserBusiness from "./SponserBusiness.js";
import ViewProfCommunityDirectPop from "./ViewProfCommunityDirectPop.js.js";
import SubscribePreMiemPop from "./SubscribePremiemPop.js";
import SubscribePremiem from "./SubscribePremiemPop.js";
import BusinessFirstPage from "../SignUpBusiness/BusinessFirstPage.js";
import AddJobPop from "./AddJobPop.js";
import ContactViewPop from "./ContactViewPop.js";
import {configData} from "../../Config.js"
import ReactCountryFlag from "react-country-flag"
import { CounryflagJson, CountryflagJson } from './CountryflagJson.js';
import logo from '../../images/naidudismall.png';
import { IoPinSharp } from "react-icons/io5";
import { IoCloseOutline } from "react-icons/io5";
import { IoMdCheckmark } from "react-icons/io";
import {FiMaximize2} from 'react-icons/fi'
import MessageExpandPopup from "./MessageExpandPopup.js";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MembersInMyLocationSeAllPopup from "./MembersInMyLocationSeALLPopup.js";
import { FaRegAddressCard } from "react-icons/fa";
import { MdBusinessCenter } from "react-icons/md";
import { IoMdMan } from "react-icons/io";
import { FaMapMarkerAlt } from "react-icons/fa";
import { CgShapeHalfCircle } from "react-icons/cg";
import { GiCoffeeCup } from "react-icons/gi";
import { FaPhoneAlt } from "react-icons/fa";
import mylocationimg from "../../images/gis_location-man-alt.png";
import myplatform from "../../images/Vector (1).png";
import { FiUsers } from "react-icons/fi";
import bloddreq from "../../images/hugeicons_blood-pressure.png";
import jobopeningg from "../../images/hugeicons_job-search.png";
import SockJS from "sockjs-client";
import { Client } from "@stomp/stompjs";




const UserLanding = () => {
    const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
    //console.log("authdata11", authlocalstoragedata)
    const authlocalstoragedata1 = JSON.parse(localStorage.getItem('authdata'));
    const authlocalstoragedata2 = JSON.parse(localStorage.getItem('authdata'));
    const authlocalstoragedata3 = JSON.parse(localStorage.getItem('authdata'));


    const [uerAuthId, setuerAuthId] = useState(authlocalstoragedata?.userAuthenticationId);
    const [uerAuthId1, setuerAuthId1] = useState(authlocalstoragedata1?.userAuthenticationId);
    const [uerAuthId2, setuerAuthId2] = useState(authlocalstoragedata2?.userAuthenticationId);
    const [uerAuthId3, setuerAuthId3] = useState(authlocalstoragedata3?.userAuthenticationId);



    const [uerCurrentCity, setuerCurrentCity] = useState(authlocalstoragedata?.currentCity);

    //for session
    // const [session ,setSession] = useState(false);

    // const gesessiondata = async () => {
    //    debugger;
    //    axios.get('/checkSession')

    //    .then(result =>{
    //      if(result.data.code == 501){
    //        setSession(true)  ;
    //         }
    //     else{
    //          history.push("/Login");

    //     }
    //      debugger;
    //      console.log(result.data.code);
    //    }).catch(error =>{
    //      console.log(error);
    //    })
    //  };

    //        console.log("checksession on campaign",session);

    // useEffect(() => {
    //     gesessiondata();
    //   }, [session]);

  //for new session for userlogin
   //for updaating authdata value

   const [updatedData, setupdatedData] = useState({});


    useEffect(() => {
      loadUpdatedData();
     }, []);

     const loadUpdatedData = async () => {
       debugger;
       const result = await axios.get(`${configData.ApiUrl}/getUpdatedData?userId=${uerAuthId1}`);
       setupdatedData(result.data.data);

       console.log("updatedadataforuserlanding",result.data.data)

   //console.log("updatedadataforuserlanding+++++++",updatedData)
     };
     const [updatedDataMatrimonyid, setupdatedDataMatrimonyid] = useState(updatedData.matrimonyId);
     console.log("updatedadataforuserlanding+++++++",updatedData  )


    const [loadProfile, setloadProfile] = useState([]);

    useEffect(() => {
      loadProfilePic();
    }, [loadProfile]);

    const loadProfilePic = async () => {

      debugger;
      const result = await axios.get(`${configData.ApiUrl}/user/getProfilePicture?id=${uerAuthId2}`);
      setloadProfile(result.data.data);
      console.log("profpic", result.data.data);


    };

    const avtarProf = authlocalstoragedata?.name2.substr(0, 2)

    //for paginatin
    //for pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const itemsPerPage = 5


    //for gallary
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = useCallback((event, { photo, index }) => {
      debugger
      setCurrentImage(index);
      setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
      setCurrentImage(0);
      setViewerIsOpen(false);
    };


    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
        slidesToSlide: 4 // optional, default to 1.
      },
      tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 3,
        slidesToSlide: 3 // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 767, min: 464 },
        items: 2,
        slidesToSlide: 1 // optional, default to 1.
      }
    };

    const sliderImageUrl = [
      //First image url

      {
        url:
          "https://picsum.photos/800/600?random=1"
      },
      {
        url:
          "https://picsum.photos/800/600?random=2"
      },
      {
        url:
          "https://source.unsplash.com/Dm-qxdynoEc/800x799"
      },

      {
        url:
          "https://picsum.photos/800/600?random=2"
      },
      {
        url:
          "https://picsum.photos/800/600?random=2"
      },
      //Second image url

    ];

    const images = [
       { url: bussslider1 },
       { url: bussslider },
      // { url: bussslider1 },
      // { url: bussslider1 },
      // { url: bussslider },
      // { url: bussslider1 },
      // { url: bussslider },
    ];

    const [profdivhide, setprofdivhide] = useState(false);


    //for like functionality
    const appReducer = (state, action) => {
      switch (action.type) {
        case 'HANDLE_LIKE':
          return {
            ...state,
            likes: state.likes + action.payload
          }
        case 'HANDLE_DISLIKE':
          return {
            ...state,
            dislikes: state.dislikes + action.payload
          }
        default:
          return state
      }
    }




    const [status, setStatus] = useState(null)


    const [isOpenMessagePop, setIsOpenMessagePop] = useState(false);
    const togglePopupMessage = (userId) => {
      debugger;
      if (!styleForMessgblock || !blockPop) {
        setIsOpenMessagePop(!isOpenMessagePop);

      }
      setstyleForMessgid(userId)
      //loadMesseges(userId)

    }

    //for community popup
    const [isOpenMessagePopDirect, setIsOpenMessagePopDirect] = useState(false);
    const [idforpop, setidforpop] = useState(0);
    const [styleForMessgBlockstatusComm, setstyleForMessgBlockstatusComm] = useState(null);

    const togglePopupMessageDirect = (id,blockStatus) => {
      setIsOpenMessagePopDirect(!isOpenMessagePopDirect);
      setidforpop(id);
      setstyleForMessgBlockstatusComm(blockStatus);
    }
    const [isOpenMessagePopViewDirect, setisOpenMessagePopViewDirect] = useState(false);
    const [idforcommpop, setidforcommpop] = useState(0);
    const [styleForMessgBlockstatusCommView, setstyleForMessgBlockstatusCommView] = useState(null);


    const toggleviewProfDiPop = (id,blockStatus) => {
      setisOpenMessagePopViewDirect(!isOpenMessagePopViewDirect);
      setidforcommpop(id);
      setstyleForMessgBlockstatusCommView(blockStatus)
    }



    const [isOpenNotification, setIsOpenNotification] = useState(false);
    const togglePopupNotify = () => {
      setIsOpenNotification(!isOpenNotification);
    }

    const [isOpenContactPopup, setisOpenContactPopup] = useState(false);
    const togglePopupContact = () => {
      setisOpenContactPopup(!isOpenContactPopup);
    }

    const [selected, setSelected] = useState('');

    const [isOpen, setIsOpen] = useState(false);
    const togglePopup = () => {

      setIsOpen(!isOpen);


    }

    const [isOpenBlog, setIsOpenBlog] = useState(false);
    const togglePopupBlog = () => {
      setIsOpenBlog(!isOpenBlog);
    }
    const [isOpenkyc, setIsOpenkyc] = useState(false);
    const togglePopupKyc = () => {
      setIsOpenkyc(!isOpenkyc);
    }
    const [isOpentopjobPop, setisOpentopjobPop] = useState(false);
    const togglePopuptopJobPop = () => {
      setisOpentopjobPop(!isOpentopjobPop);
    }


    const [connectedStatus, setConnectedStatus] = useState(0);

    const handleChange = event => {
      debugger;
      setSelected(event.target.value);

      const { name, value, type, checked } = event.currentTarget;


      setcmntValue(preState => ({
        ...preState,
        [name]: type === 'checkbox' ? checked : value
      }))

      // setConnectedStatus(preState =>({
      //   ...preState,
      //   [name]:type ==='checkbox'?checked: value
      // }))

      if (type == "checkbox") {
        //loadMembers(event.currentTarget.checked);
        //setConnectedStatus(1);
        loadMembers(checked);

      }



    }

    const [sliderval, setSliderVal] = useState()

    const getValue = (e, val) => {
      setSliderVal(val);
    }

    const mark = [
      {
        value: 25,
        label: "25 Km",
      },
      {
        value: 50,
        label: "50 Km"
      },
      {
        value: 75,
        label: "75 Km"
      },
      {
        value: 100,
        label: "100 Km"
      }


    ]



    const [news, setNews] = useState(true);
    const [communityDirect, setcommunityDirect] = useState(false);
    const [matrimony, setMatrimony] = useState(false);
    const [communityMeet, setcommunityMeet] = useState(false);
    const [businessDirect, setbusinessDirect] = useState(false);

    const showNews = () => {
      setNews(true);
      setcommunityDirect(false);
      setMatrimony(false);
      setbusinessDirect(false);
      setcommunityMeet(false);



    }

    const showCommunityDirect = () => {
      setNews(false);
      setcommunityDirect(true);
      setMatrimony(false);
      setbusinessDirect(false);
      setcommunityMeet(false);

    }
    const showMatrimony = () => {
      setNews(false);
      setcommunityDirect(false);
      setMatrimony(true);
      setbusinessDirect(false);
      setcommunityMeet(false);

    }
    const showBusinessDirect = () => {
      setNews(false);
      setcommunityDirect(false);
      setMatrimony(false);
      setbusinessDirect(true);
      setcommunityMeet(false);
    }
    const showCommunityMeet = () => {
      setNews(false);
      setcommunityDirect(false);
      setMatrimony(false);
      setbusinessDirect(false);
      setcommunityMeet(true);
    }


    const [isOpenBloodPopup, setisOpenBloodPopup] = useState(false);
    const togglePopupBlood = () => {
      setisOpenBloodPopup(!isOpenBloodPopup);
    }


    // const [comment ,setComment] = useState(false);
    // const commentClick = () => {
    //   setComment(true);
    // }
    //logout




    let history = useHistory();

    const logoutclick = async() => {
      debugger
      const result = await axios.get(configData.ApiUrl+'/logout');
      localStorage.clear()

      history.push("/Login");
    }

    //for disable backbtn
    window.history.pushState(null, null, window.location.href);
  window.onpopstate = function () {
      window.history.go(1);
  };

    //for bloodreq slider



    // const [bloodReqSlider, setbloodReqSlider] = useState([]);

    // useEffect(() => {
    //   loadBloodReqSlider();
    // }, []);

    // const loadBloodReqSlider = async () => {

    //   const result = await axios.get(`/bloodReq/getCommunityBloodRequirementsForMain?id=${uerAuthId}`);
    //   setbloodReqSlider(result.data.data);
    //   console.log("bldreqslider", result.data.data)
    // };

    //for community directory

    const [commDirectory, setcommDirectory] = useState([]);

       useEffect(() => {
        loadCommDirectory();
     }, []);
    
    
    // useEffect(() => {
    //   loadCommDirectory(); // ✅ Initial fetch
  
    //   const intervalId = setInterval(() => {
    //     console.log("Polling API...");
    //     loadCommDirectory();
    //   }, 5000); // ✅ Poll every 5 seconds
  
    //   return () => clearInterval(intervalId); // Cleanup when unmounting
    // }, []); //

    
    const loadCommDirectory = async () => {
      const result = await axios.get(`${configData.ApiUrl}/subscriber/getAllSubscribers?id=${uerAuthId3}`);
      if (JSON.stringify(result.data.data) !== JSON.stringify(commDirectory)) {
        setcommDirectory(result.data.data);
      }
      //setcommDirectory(result.data.data);
      console.log("comdirectory",result.data.data)

    };


    const [loadPost, setloadPost] = useState([]);
    const [filterpost, setFilterpost] = useState(''); // Stores the current filter
    const [allPosts, setAllPosts] = useState([]); // Stores all fetched posts
    const stompClientRef = useRef(null); // Store WebSocket client reference

    //  useEffect(() => {
    //    loadPostList();
    //     }, [loadPost]);
        
/////for websocket...................
    
  useEffect(() => {
    
    loadPostList(); // Initial fetch
    // loadCommDirectory();
    // loadCoffeeMeets();
    // loadConnection();
    // loadLikesInfo();
    // loadUsers();
    // loadMembers();
    // loadUserList();

    // Create WebSocket connection
    const socket = new SockJS("https://naidudirectory.org/ws");
    stompClientRef.current = new Client({
      webSocketFactory: () => socket,
      reconnectDelay: 5000, // Auto-reconnect if disconnected
      onConnect: () => {
        console.log("Connected to WebSocket");

        // Subscribe to refresh-feed when a new post is added
        stompClientRef.current.subscribe("/topic/refresh-feed", () => {
          console.log("Received WebSocket update, refreshing posts...");
          loadPostList(); // Silent refresh of posts
        });
// //for loadCommDirectory
//         stompClientRef.current.subscribe("/topic/comments", () => {
//           console.log("New comment detected!");
//           loadCommDirectory(); // Refresh 
//         });

//         //for loadCoffeeMeets
//         stompClientRef.current.subscribe("/topic/comments", () => {
//           console.log("New comment detected!");
//           loadCoffeeMeets(); // Refresh 
//         });

//         //for loadConnection
//         stompClientRef.current.subscribe("/topic/comments", () => {
//           console.log("New comment detected!");
//           loadConnection(); // Refresh 
//         });

//         //for loadLikesInfo
//         stompClientRef.current.subscribe("/topic/comments", () => {
//           console.log("New comment detected!");
//           loadLikesInfo(); // Refresh 
//         });
        
//         //for loadUsers
//         stompClientRef.current.subscribe("/topic/comments", () => {
//           console.log("New comment detected!");
//           loadUsers(); // Refresh 
//         });
        
//         //for loadMembers
//         stompClientRef.current.subscribe("/topic/comments", () => {
//           console.log("New comment detected!");
//           loadMembers(); // Refresh 
//         });
        
//         //for loadUserList
//         stompClientRef.current.subscribe("/topic/comments", () => {
//           console.log("New comment detected!");
//           loadUserList(); // Refresh 
//         });
         
        

      },
      onDisconnect: () => console.log("WebSocket Disconnected"),
    });

    stompClientRef.current.activate();

    return () => {
      if (stompClientRef.current) {
        stompClientRef.current.deactivate();
      }
    };
  }, []);

    // const loadPostList = async () => {
    //   const result = await axios.get(`${configData.ApiUrl}/CommunityPost/getListOfPosts?id=${uerAuthId}`);

    //   //setloadPost(result.data.data);
    //   const posts = result.data.data;

    //   setAllPosts(posts);
    //   if (filterpost) {
    //     setloadPost(posts.filter((post) => post?.type?.toLowerCase().includes(filterpost.toLowerCase())));
    //   } else {
    //     setloadPost(posts);
    //   }
    //   console.log("postlist", result.data.data)

    // };

    ////////for websocket implementation
// Fetch all posts from backend (GET API)
  const loadPostList = async () => {
    try {
      const result = await axios.get(
        `${configData.ApiUrl}/CommunityPost/getListOfPosts?id=${uerAuthId}`
      );
      const posts = result.data.data;

      setAllPosts(posts);
      if (filterpost) {
        setloadPost(posts.filter((post) => post?.type?.toLowerCase().includes(filterpost.toLowerCase())));
      } else {
        setloadPost(posts);
      }

      console.log("Post list updated", posts);
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  };

    
    
    // const loadPostList = useCallback(async ()  => {


    //   const result = await axios.get(`${configData.ApiUrl}/CommunityPost/getListOfPosts?id=${uerAuthId}`);

    //   //setloadPost(result.data.data);
    //   const posts = result.data.data;

    //   setAllPosts(posts);
    //   if (filterpost) {
    //     setloadPost(posts.filter((post) => post?.type?.toLowerCase().includes(filterpost.toLowerCase())));
    //   } else {
    //     setloadPost(posts);
    //   }
    //   console.log("postlist", result.data.data)


    // },[]);
    // Fetch posts on mount and start polling
// useEffect(() => {
//   loadPostList(); // Initial fetch
//   loadCommDirectory();


//   if (!intervalRef.current) { // Ensure only one interval is set
//     intervalRef.current = setInterval(() => {
//       loadPostList(); // Poll for new posts every 5 seconds
//       loadCommDirectory();

//     }, 5000);
//   }

//   return () => clearInterval(intervalRef.current); // Cleanup interval when unmounting
// }, [loadPostList,loadCommDirectory]);

// Stop polling when component unmounts
// useEffect(() => {
//   return () => {
//     if (intervalRef.current) {
//       clearInterval(intervalRef.current);
//       intervalRef.current = null;
//     }
//   };
// }, []);

    
    //for pagination
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const subset = loadPost.slice(startIndex, endIndex);

    const handlePageChange = (selectedPage) => {
      setCurrentPage(selectedPage.selected);
    };

    //for addlikes
    const [postid, setPostid] = useState();

    // for(var i=0;i<loadPost.length;i++)
    // {
    //   debugger
    //   loadPost[i].postId=postid ;
    //   console.log(postid)
    // }

    const addLikes = async (id) => {

      const response = await axios.post(configData.ApiUrl+'/CommunityPost/like?postId=' + id + '&userId=' + uerAuthId)
      if (response.status == 200 && response.data.code) {
        loadPostList();

      }
      else {
        alert("Something Went Wrong..!")
      }



    }


    //for getlikesbyid
    const [LikeDiv, setLikeDiv] = useState(false);
    function Likepoupopen(isLike) {
      if (isLike) {
        setLikeDiv(true);
      }
      else {
        setLikeDiv(false);
      }
    }



    const { id } = useParams();

    const [likeData, setlikeData] = useState([]);

    useEffect(() => {
      //loadlikes();
    }, []);

    const loadLikesInfo = async (id) => {
      console.log(id)
      const result = await axios.get(`${configData.ApiUrl}/CommunityPost/getListOfLikesById?id=${id}`);
      setlikeData(result.data.data);
      //  debugger;
      console.log("likesdt", result.data.data);

      Likepoupopen(true);
    };

    //for contactinfopopup
    const [contactInfoDiv, setcontactInfoDiv] = useState(false);
    const [commanView, setcommanView] = useState({
      fullName: "",
      emailId: "",
      countryCode: "",
      mobileNumber: "",
      connectionStatus: "",
      connection: ""
    })
    var info = "";
    const contactInfopoupopen = (id, isContInfo) => {
      if (id > 0) {
        info = commDirectory.filter((obj) =>
          id == obj.id)[0];

        //setcommanView({...commanView :info} );
        commanView.fullName = info.fullName;


        commanView.emailId = info.emailId;
        commanView.connection = info.connection;
        if (commanView.connection != "Accepted") {
          commanView.mobileNumber = info.mobileNumber.substr(0, 2) + 'xxxxxx'
            + info.mobileNumber.substr(8, 9);
           
            let emailParts = info.emailId.split("@");
            if (emailParts.length === 2) {
            commanView.emailId = emailParts[0].substr(0, 2) + "xxxxxx" + emailParts[0].substr(-2) + "@" + emailParts[1];
      }
        }
        else {
          commanView.mobileNumber = info.mobileNumber;
          commanView.emailId = info.emailId;
        }
        commanView.connectionStatus = info.connectionStatus
      }

      console.log("conm View =", commanView);

      if (isContInfo) {
        setcontactInfoDiv(true);
      }
      else {
        setcontactInfoDiv(false);
      }
    }

    //for cofeediv
    const [coffeediv, setcoffeediv] = useState(false);
    const [coffeeView, setcoffeeView] = useState({
      hostId: "",
      hostName: "",
      date: "",
      city: "",
      venue: "",
      time: "",
      guests: [],
      attendeesCount: "",
      googleMapsLink: "",
      attendStatus: "",
      id: "",
      profilePicturePath:""
    })
    var info = "";
    const coffeeInfopoupopen = (id, isCoffeeInfo,attendStatus) => {
      if (id > 0) {
        info = coffeeMeets.filter((obj) =>
          id == obj.id)[0];

        //setcommanView({...commanView :info} );
        coffeeView.hostName = info.hostName;
        coffeeView.date = info.date;
        coffeeView.city = info.city;
        coffeeView.venue = info.venue;
        coffeeView.time = info.time;
        coffeeView.guests = info.guests;
        coffeeView.attendeesCount = info.attendeesCount;
        coffeeView.googleMapsLink = info.googleMapsLink;
        coffeeView.attendStatus = info.attendStatus;
        coffeeView.id = info.id;
  coffeeView.profilePicturePath = info.profilePicturePath
     }
      console.log("conm View =", coffeeView);

      if (isCoffeeInfo) {
        setcoffeediv(true);
      }
      else {
        setcoffeediv(false);
      }
    }

    //for comments
    const [comment, setComment] = useState(false);
    const [cmntEventId, setCmntEventId] = useState(0);

    function Commentpoupopen(commentopen) {
      if (commentopen) {
        setComment(true);
      }
      else {
        setComment(false);
      }
    }
    //for close popup when click on outside
  const popupcommentRef = useRef(null);
  const handleClickOutsideCommentdiv = (event) => {
    if (popupcommentRef.current && !popupcommentRef.current.contains(event.target)) {
      setComment(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideCommentdiv);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideCommentdiv);
    };
  }, []);

    const [commentData, setcommentData] = useState([]);
    const [cmntValue, setcmntValue] = useState({
      txtCmnt: ""
    });




    useEffect(() => {
      //loadlikes();
    }, []);

    const commentInfo = async (id) => {
      console.log(id)
      const result = await axios.get(`${configData.ApiUrl}/CommunityPost/getListOfCommentsById?id=${id}`);
      setcommentData(result.data.data.sort((a, b) => b.id - a.id));
      //  debugger;
      console.log("commentdt", result.data.data.sort((a, b) => b.id - a.id));
      setCmntEventId(id);

      Commentpoupopen(true);
      setcmntValue(preState => ({
        ...preState,
        "txtCmnt": ""
      }))
    };
    const [commentValiderr, setcommentValiderr] = useState(false)

    const handleKeypress = e => {
      //it triggers by pressing the enter key
      debugger
      if (e.key === "Enter") {
        saveComent(id);

      }
    };

    const saveComent = async (id) => {
      if (cmntValue.txtCmnt == "") {
        setcommentValiderr(true)
        return false;
      }
      else {
        setcommentValiderr(false)
        const response = await axios.post(configData.ApiUrl+'/CommunityPost/comment?postId=' + id + '&userId=' + uerAuthId + '&comment=' + cmntValue.txtCmnt)
        setcmntValue(preState => ({
          ...preState,
          "txtCmnt": ""
        }))
        debugger;
        loadPostList();
        commentInfo(id);


      }


    }

    const deleteComment = (id) => {

      const response = axios.delete(`${configData.ApiUrl}/CommunityPost/deleteComment?commentId=${id}`)
      response.then(() => {
        toast.success("Comment deleted Successfully!");
        loadPostList();

        commentInfo(id);


      })
      response.catch((err) => {
        alert("Getting error in featching data")
      });


    }

    
    function handleContextMenuimgdisable(e) {
      debugger
      e.preventDefault(); // prevents the default right-click menu from appearing
    }

    //bookmark
    //for community directory


    const addCommDirectoryBookmark = async (id) => {

      const response = await axios.post(configData.ApiUrl+'/subscriber/bookMark?hostId=' + uerAuthId + '&userId=' + id)
      if (response.status == 200 && response.data.code) {
        //toast.success("Bookmarked Successfully!")
        loadCommDirectory();

      }
      else {
        alert("Something Went Wrong..!")
      }
    }
    //forconnection reqsent in commDirectory
    const [commDirectoryconReq, setcommDirectoryconReq] = useState(false);

    const addCommDirectoryConnect = async (id) => {
      debugger
      const response = await axios.post(configData.ApiUrl+'/connection/sendConnection?senderId=' + uerAuthId + '&recieverId=' + id)
      if (response.status == 200 && response.data.code) {
        //setcommDirectoryconReq(true)
        loadCommDirectory();

      }
      else {
        setcommDirectoryconReq(false)
        alert("Something Went Wrong..!")
      }
    }

    //for  getall connection of
    const [connection, setconnection] = useState([]);

     useEffect(() => {
       loadConnection();
     }, []);
    

    const loadConnection = async () => {

      const result = await axios.get(`${configData.ApiUrl}/connection/getListRecievedConnections?id=${uerAuthId}`);
      setconnection(result.data.data);
      console.log("connections", result.data.data)

    };
    //for accept connection in my connection
    const acceptConnection = async (senderId) => {
      debugger
      const response = await axios.post(configData.ApiUrl+'/connection/acceptConnection?senderId=' + senderId + '&recieverId=' + uerAuthId)
      if (response.status == 200 && response.data.code) {
        toast.success("Successfully Accepted Connection Request")

        loadConnection();

      }
      else {
        alert("Something Went Wrong..!")
      }
    }
    //for Reject connection in my connection
    const rejectConnection = async (senderId) => {
      debugger
      const response = await axios.post(configData.ApiUrl+'/connection/rejectConnection?senderId=' + senderId + '&recieverId=' + uerAuthId)
      if (response.status == 200 && response.data.code) {
        toast.success("Successfully Rejected Connection Request")
        loadConnection();

      }
      else {
        alert("Something Went Wrong..!")
      }
    }


    //for load list of coffee meets
    const [coffeeMeets, setcoffeeMeets] = useState([]);

     useEffect(() => {
       loadCoffeeMeets();
     }, []);

    // useEffect(() => {
    //   const intervalId = setInterval(() => {
    //     loadCoffeeMeets();
    //   }, 5000); // Poll every 5 seconds

    //   return () => clearInterval(intervalId);
    // }, []);

    const loadCoffeeMeets = async (senderId) => {

      const result = await axios.get(`${configData.ApiUrl}/coffeeMeet/getListOfUpcomingCoffeeMeets?hostId=${uerAuthId}`);
      setcoffeeMeets(result.data.data);
      console.log("coffeemeet", result.data.data);

      if(coffeediv && senderId>0){
        var info = "";
          if (senderId > 0) {
            info = result.data.data.filter((obj) =>
            senderId == obj.id)[0];

            //setcommanView({...commanView :info} );
            coffeeView.hostName = info.hostName;
            coffeeView.date = info.date;
            coffeeView.city = info.city;
            coffeeView.venue = info.venue;
            coffeeView.time = info.time;
            coffeeView.guests = info.guests;
            coffeeView.attendeesCount = info.attendeesCount;
            coffeeView.googleMapsLink = info.googleMapsLink;
            coffeeView.attendStatus = info.attendStatus;
            coffeeView.id = info.id;
      coffeeView.profilePicturePath = info.profilePicturePath
         }

      }
    };

    //for my coffee meet search functionality
    //const [searchVal, setSearchVal] = useState("");
    const handleSearchChange = (e) => {
      debugger
      if (e.currentTarget.value === "") {
        loadCoffeeMeets();
        return;
      }
      const filterBySearch = coffeeMeets.filter((item) => {
        if (item != null && item.hostName != null) {
          if (item?.hostName?.toLowerCase().includes(e.currentTarget.value)) { return item; }
        }
        // if ( item != null && item.profession != null){
        //   if (item?.profession?.toLowerCase()
        //       .includes(e.currentTarget.value)) { return item; }}
      })
      setcoffeeMeets(filterBySearch);
    }

    //second filter
    //for filter
    const [pendingDIv, setpendingDIv] = useState(false);
    const [acceptedDiv, setacceptedDiv] = useState(false);
    const [allDiv, setallDiv] = useState(false);


    const [filter, setfilter] = useState({
      filterstatus: ""
    });

    const handleChangeFilter = (e) => {
      debugger
      const { name, value, type, checked } = e.currentTarget;


      setfilter(preState => ({
        ...preState,
        [name]: type === 'checkbox' ? checked : value
      }));

      if (e.target.value == "Pending") {
        //setisfilterdata( e.target.value )
        //setAadharDIv(true);
        setpendingDIv(true);
        setacceptedDiv(false);
        setallDiv(false);

      }
      else if (e.target.value == "accepted") {
        //setAadharDIv(false);
        setacceptedDiv(true);
        setpendingDIv(false);
        setallDiv(false);
      }

      else {
        setallDiv(true);
        setpendingDIv(false);
        setacceptedDiv(false)
      }


    }


    const [countmenu, setData] = useState([]);

     useEffect(() => {
       loadUsers();
     }, []);

    


    const loadUsers = async () => {
      const result = await axios.get(`${configData.ApiUrl}/subscriber/getCountForInvitations?id=${uerAuthId}`);

      setData(result.data.data);
      console.log("getCountForInvitations", result.data.data)

    };


    const [isOpenMessagePop1, setIsOpenMessagePop1] = useState(false);
    const togglePopupMessage1 = () => {
      setIsOpenMessagePop1(!isOpenMessagePop1);
    }

    //for spampost
    const addSpam = async (id) => {

      const response = await axios.post(configData.ApiUrl+'/CommunityPost/spam?postId=' + id + '&userId=' + uerAuthId)
      if (response.status == 200 && response.data.code) {
        loadPostList();

      }
      else {
        alert("Something Went Wrong..!")
      }

    }

    const redirectTorefferal = () => {

      history.push("/ViewProfile");

    }


    //for members in my location

    const [members, setMembers] = useState([]);

    useEffect(() => {
      loadMembers();
    }, []);


    const loadMembersold = async (checkedval) => {
      console.log("checkedval", checkedval)
      if (checkedval == true) {

        const result = await axios.get(configData.ApiUrl+'/user/getUsersNearMe?id=' + uerAuthId + '&connectedStatus=' + 1 + '&currentCity=' + uerCurrentCity);

        setMembers(result.data.data);
        console.log("getmembersinlocation", result.data.data)

      }
      else {

        const result = await axios.get(configData.ApiUrl+'/user/getUsersNearMe?id=' + uerAuthId + '&connectedStatus=' + 0 + '&currentCity=' + uerCurrentCity);

        setMembers(result.data.data);
        console.log("getmembersinlocation", result.data.data);

      }



    };
    const loadMembers = async () => {

      const result = await axios.get(configData.ApiUrl+'/user/getUsersNearMe?filterStatus=' + "All" + '&id=' + uerAuthId + '&currentCity=' + uerCurrentCity);

        setMembers(result.data.data);
        console.log("getmembersinlocation", result.data.data);

    }

    const [isOpenSignMatriPopup, setisOpenSignMatriPopup] = useState(false);
    const togglePopupSignUpmatri = () => {
      setisOpenSignMatriPopup(!isOpenSignMatriPopup);
    }

    const [isOpenSignBusinessPopup, setisOpenSignBusinessPopup] = useState(false);
    const togglePopupSignUpBusiness = () => {
      setisOpenSignBusinessPopup(!isOpenSignBusinessPopup);
    }


    ///for mesenger
    const [userlist, setuserlist] = useState([]);

     useEffect(() => {
       loadUserList();
     }, []);

    // useEffect(() => {
    //   const intervalId = setInterval(() => {
    //     loadUserList();
    //   }, 5000); // Poll every 5 seconds

    //   return () => clearInterval(intervalId);
    // }, []);


    const loadUserList = async () => {
      const result = await axios.get(`${configData.ApiUrl}/messenger/getUsersListMessenger?hostId=${uerAuthId}`);

      setuserlist(result.data.data);
      console.log("getuserlist", result.data.data);

    };

    //for search messg
    const handleSearchChangeMessage = (e) => {
      debugger
      if (e.currentTarget.value === "") {
        loadUserList();
        return;
      }
      const filterBySearch = userlist.filter((item) => {
        if (item != null && item.userName != null) {
          if (item?.userName?.toLowerCase().includes(e.currentTarget.value)) { return item; }
        }
         if ( item != null && item.lastMessage != null){
           if (item?.lastMessage?.toLowerCase()
               .includes(e.currentTarget.value)) { return item; }}
      })
      setuserlist(filterBySearch);
    }


    const [styleForMessg, setstyleForMessg] = useState(true);
    const [styleForMessgid, setstyleForMessgid] = useState(0);
    const [styleForMessgBlockstatus, setstyleForMessgBlockstatus] = useState("");

    const omMouseHover = (userId,blockStatus) => {

      setstyleForMessg(true);
      setstyleForMessgid(userId);
      setstyleForMessgBlockstatus(blockStatus)

    }
    const omMouseLeve = (userId) => {

      setstyleForMessg(false);

    }

    //for three dot for bpck unblock
    const [styleForMessgblock, setstyleForMessgblock] = useState(true);
    const [style, setStyle] = useState({ display: 'none' });

    const omMouseHoverblock = (userId) => {

      setIsOpenMessagePop(false)
      setstyleForMessgid(userId)

      //setStyle({display: 'block',border: '1px solid gray',  padding: 6, borderRadius:50});
      setstyleForMessgblock(true)
    }
    const omMouseLeveBlock = (userId) => {

      setstyleForMessgblock(false)

    }


    const addblockUnblock = async (userId) => {
  debugger
      const response = await axios.post(configData.ApiUrl+'/messenger/block-unblock?hostId=' + uerAuthId + '&userId=' + userId)
      if (response.status == 200 && response.data.code) {
        toast.success("Updated Block Status Successfully!");
        loadUserList();
        blockunblockpoupopen(false)
        setblockPop(false)
      }
      else {
        alert("Something Went Wrong..!")
      }
    }

    //for block popup
    const [blockPop, setblockPop] = useState(false);

    function blockunblockpoupopen(blockpopopen) {
      if (blockpopopen) {
        setblockPop(true);
      }
      else {
        setblockPop(false);
      }
    }

    const attendStatusCofeeMeet = async (id) => {



      debugger;
      const response = await axios.post(configData.ApiUrl+'/coffeeMeet/editGuestStatus?guestId=' + uerAuthId + '&coffeeMeetId=' + id)
      if (response.status == 200 && response.data.code) {
        toast.success("Update Status  Successfully!")
        loadCoffeeMeets(id);

      }
      else {
        alert("Something Went Wrong..!")
      }
    }

    //for matrimony
    const [matrimonyDiv, setmatrimonyDiv] = useState(true);
    const [matrimonyEditDiv, setmatrimonyEditDiv] = useState(false);



    const onNext = (isback) => {
      debugger;
      if (isback) {
        setmatrimonyDiv(true);
        setmatrimonyEditDiv(false)
      }
      else {
        setmatrimonyDiv(false);
        setmatrimonyEditDiv(true)
      }
    }

    const [startDate, setStartDate] = useState(new Date());

    const handleChangedate = (e) => {
      setStartDate(e)
      //  setBlog(preState => ({
      //   ...preState,
      //   "scheduledDate": (moment(e).format("DD MMM yyyy")),

      // }))
    }
    const [formData, setFormData] = useState({

      reqCountryId: "",
      reqCountry: "",
      reqStateId: "",
      reqState: "",
      reqCityId: "",
      reqCity: "",


    });

    const countries = csc.getAllCountries();
    ////console.log(csc.getAllCountries())

    const updatedCountries = countries.map((country) => ({
      label: country.name,
      value: country.id,
      ...country
    }));

    const updatedStates = (countryId) => csc.getStatesOfCountry(countryId)
      .map((state) => ({ label: state.name, value: state.name, ...state }));

    const updatedCities = (stateId) =>
      csc
        .getCitiesOfState(stateId)
        .map((city) => ({ label: city.name, value: city.name, ...city }));




    //for business directory

    const columns = [
      {
        name: "Job Id",
        selector: "id",
        sortable: true,
        width: "100px",
        cell: (d) => (
          <Link to={`/ViewJobPosting/${d.id}`} className="dlink">
            {d.id}
          </Link>
        ),

      },

      {
        name: "Date",
        selector: "createdDate",
        sortable: true,

      },
      {
        name: "Position",
        selector: "role",
        sortable: true,
        width: "100px"
      },

      {
        name: "Location",
        selector: "city",
        sortable: true,
        className: "texttransformcapitalize"

      },
      {
        name: "Status",
        selector: "status",
        sortable: true,
        cell: (d) => [
          d.status == "Open" ? <span className="" style={{ color: '#70B603' }}>Open</span>
            : <span className="" style={{ color: '#FA114F' }}>Closed</span>

        ]

      },


    ];

    //  const data = [
    //     {
    //         jobId:"1002",
    //         date:"1 May 2023",
    //         position: "Sales Executive",
    //         location: "Pune",
    //          status: "Open",


    //       },
    //       {
    //         jobId:"1003",
    //         date:"1 May 2023",
    //         position: "Sales Executive",
    //         location: "Pune",
    //          status: "Closed",

    //         },

    //             ]



    const [data, setDataJob] = useState([]);

    const tableData = {
      columns,
      data,

    };

    useEffect(() => {
      loadJobList();
    }, []);

    const loadJobList = async () => {
      debugger
      const result = await axios.get(`${configData.ApiUrl}/buisness/getListJobPostings?buisnessId=${authlocalstoragedata?.buisnessId}`);
      setDataJob(result.data.data);
      console.log("getalljobpost", result.data.data)


    };


    const redirecttoViewProf = () => {
      history.push("/ViewProfile");
    }
    const redirectToReferal = () => {
      history.push("/RefferalMain");
    }
    const redirectToContact = () => {
      history.push("/ContactMain");
    }
    const redirectToContactRecive = () => {
      history.push("/ViewContactRecive");
    }
    const redirectToCoffeemeetInvite = () => {
      history.push("/ViewCoffeeMeetinvitatin");
    }
    const redirectMybussProfilemain = () => {
      history.push("/MybussProfileMain");

    }
    const redirectToAccSetting = () => {
      history.push("/AccountMain");
    }
    const redirectToBloodReq = () => {
      history.push("/BloodReqMain");
    }

    ///for matrimony all profile
    const [matrimonyAllProf, setmatrimonyAllProf] = useState([]);

    useEffect(() => {
      loadMatAllProf();
    }, []);


    const loadMatAllProf = async () => {
      const result = await axios.get(`${configData.ApiUrl}/matrimony/getMatrimonyProfiles?matrimonyId=${authlocalstoragedata?.matrimonyId}`);

      setmatrimonyAllProf(result.data.data);
      console.log("getAllMatProflist", result.data.data)

    };

    ///for shortlisted  profile
    const [matrimonyShortProf, setmatrimonyShortProf] = useState([]);

    useEffect(() => {
      loadMatShortProf();
    }, []);


    const loadMatShortProf = async () => {
      const result = await axios.get(`${configData.ApiUrl}/matrimony/getMatrimonyShortlistedProfiles?matrimonyId=${authlocalstoragedata?.matrimonyId}`);

      setmatrimonyShortProf(result.data.data);
      console.log("getShortMatProflist", result.data.data);

    };
    ///for Myprofile  profile
    const [matrimonyMyProf, setmatrimonyMyProf] = useState([]);

    useEffect(() => {
      loadMatMyProf();
    }, []);


    const loadMatMyProf = async () => {
      const result = await axios.get(`${configData.ApiUrl}/matrimony/getMatrimonyMyProfile?matrimonyId=${authlocalstoragedata?.matrimonyId}`);

      setmatrimonyMyProf(result.data.data);
      console.log("getMyMatProflist", result.data.data);

    };

     ///for viewprofile  profile
     const [matrimonyViewProf, setmatrimonyViewProf] = useState([]);

     useEffect(() => {
      loadMatViewProf();
     }, []);


     const loadMatViewProf = async () => {
       const result = await axios.get(`${configData.ApiUrl}/matrimony/getMatrimonyMyProfile?matrimonyId=${authlocalstoragedata?.matrimonyId}`);

       setmatrimonyViewProf(result.data.data);
       console.log("getViewMatProflist", result.data.data)

     };

    //for Matrimony directory


    const addShortList = async (id) => {

      const response = await axios.post(configData.ApiUrl+'/matrimony/shortlist?hostMatrimonyId=' + authlocalstoragedata?.matrimonyId + '&userMatrimonyId=' + id)
      if (response.status == 200 && response.data.code) {
        //toast.success("Bookmarked Successfully!")
        //loadMatAllProf();
        const result = await axios.get(`${configData.ApiUrl}/matrimony/getMatrimonyProfiles?matrimonyId=${authlocalstoragedata?.matrimonyId}`);

      setmatrimonyAllProf(result.data.data);
      console.log("getAllMatProflist", result.data.data)

      }
      else {
        alert("Something Went Wrong..!")
      }
    }


    ///for business listing  profile
    const [allbusinessList, setallbusinessList] = useState([]);

    useEffect(() => {
      loadBusinessList();
    }, []);


    const loadBusinessList = async () => {
      const result = await axios.get(`${configData.ApiUrl}/buisness/getBuisnessProfiles?buisnessId=${authlocalstoragedata?.buisnessId}`);

      setallbusinessList(result.data.data);
      console.log("getallbusiness", result.data.data)

    };

    ///for business myprofile
    const [myProfilebusinessList, setmyProfilebusinessList] = useState([]);

    useEffect(() => {
      loadBusinessMyProfile();
    }, []);


    const loadBusinessMyProfile = async () => {
      const result = await axios.get(`${configData.ApiUrl}/buisness/getBuisnessProfilesById?buisnessId=${authlocalstoragedata?.buisnessId}`);

      setmyProfilebusinessList(result.data.data);
      console.log("getmyprofbussness", result.data.data)

    };
  console.log("mybussprofbyid",myProfilebusinessList)
    //for job psting fr userlanding pg
    const [jobPostingList, setjobPostingList] = useState([]);

    useEffect(() => {
      loadJobPostingList();
    }, []);


    const loadJobPostingList = async () => {
      const result = await axios.get(`${configData.ApiUrl}/buisness/getListJobOpenings?buisnessId=${authlocalstoragedata?.buisnessId}`);

      setjobPostingList(result.data.data);
      console.log("jobPostingList", result.data.data)

    };

    //for opening job popup
    const [isOpenJobPop, setisOpenJobPop] = useState(false);
    const togglePopupJob = () => {
      setisOpenJobPop(!isOpenJobPop);
    }

    //for directory filter

    const [bloodGroupDIv, setbloodGroupDIv] = useState(false);
    const [countryDiv, setcountryDiv] = useState(false);
    const [stateDiv, setstateDiv] = useState(false);
    const [cityDiv, setcityDiv] = useState(false);
    const [professionDiv, setprofessionDiv] = useState(false);


    const [filterDirect, setfilterDirect] = useState({
      name: ""
    });

    const [filterdiData, setfilterdiData] = useState({
      city: "",
      country: "",
      state: "",
      bloodGroup: "",
      profession: ""
    })

    const handleChangeDirectFilter = (e) => {
      debugger
      const { name, value, type, checked } = e.currentTarget;


      setfilterdiData(preState => ({
        ...preState,
        [name]: type === 'checkbox' ? checked : value
      }));

      setfilterDirect(preState => ({
        ...preState,
        "name" : value
      }));


      if (e.target.value == "country") {

        setcountryDiv(true);
        setstateDiv(false)
        setcityDiv(false);
        setbloodGroupDIv(false);
        setprofessionDiv(false);
        //loadCommDirectory(e.target.value)

      }
      else if (e.target.value == "state") {
        //setAadharDIv(false);
        setcountryDiv(false);
        setstateDiv(true)
        setcityDiv(false);
        setbloodGroupDIv(false);
        setprofessionDiv(false);
        //loadCommDirectory(e.target.value)


      }
      else if (e.target.value == "city") {
        //setAadharDIv(false);
        setcountryDiv(false);
        setstateDiv(false)
        setcityDiv(true);
        setbloodGroupDIv(false);
        setprofessionDiv(false);
        //loadCommDirectory(e.target.value)


      }
      else if (e.target.value == "blood group") {
        //setAadharDIv(false);
        setcountryDiv(false);
        setstateDiv(false)
        setcityDiv(false);
        setbloodGroupDIv(true);
        setprofessionDiv(false);
        //loadCommDirectory(e.target.value)

      }
      else if (e.target.value == "profession") {
        //setAadharDIv(false);
        setcountryDiv(false);
        setstateDiv(false)
        setcityDiv(false);
        setbloodGroupDIv(false);
        setprofessionDiv(true);
        //loadCommDirectory(e.target.value)

      }

      else {
        setcountryDiv(false);
        setstateDiv(false)
        setcityDiv(false);
        setbloodGroupDIv(false);
        setprofessionDiv(false);
        //loadCommDirectory1
        if (e.target.value == "All") {
          loadCommDirectory1(e.target.value)

        }
        else if (e.target.value == "bookmark profile") {
          loadCommDirectory1(e.target.value)


        }

      }


    }


    const [filterCountryName, setfilterCountryName] = useState("")

    const handleChangeforFilterDirect = (e) => {
      debugger
      const { name, value, type, checked } = e.currentTarget;


      setfilterCountryName(e.target.value)

    }

    const filterCountry = (e) => {
      loadCommDirectory1('country')
    }

    const [filterStateName, setfilterStateName] = useState("")

    const handleChangeforFilterState = (e) => {
      debugger
      const { name, value, type, checked } = e.currentTarget;

      setfilterStateName(e.target.value)

    }

    const filterState = (e) => {
      loadCommDirectory1('state')
    }

    const [filterCityName, setfilterCityName] = useState("")

    const handleChangeforFilterCity = (e) => {
      debugger
      const { name, value, type, checked } = e.currentTarget;

      setfilterCityName(e.target.value);

    }

    const filterCity = (e) => {
      loadCommDirectory1('city')
    }

    const [filterBlooodName, setfilterBloodName] = useState("")

    const handleChangeforFilterBlood = (e) => {
      debugger
      const { name, value, type, checked } = e.currentTarget;

      setfilterBloodName(e.target.value);

    }

    const filterBlood = (e) => {
      loadCommDirectory1('blood group')
    }
    const [filterProfessionName, setfilterProfessionName] = useState("")

    const handleChangeforFilterProfession = (e) => {
      debugger
      const { name, value, type, checked } = e.currentTarget;

      setfilterProfessionName(e.target.value);

    }

    const filterProfession = (e) => {
      loadCommDirectory1('profession')
    }


    const loadCommDirectory1 = async (directFilter) => {
      debugger;

      if (directFilter == 'All') {
        debugger
        const result =  await axios.get(`${configData.ApiUrl}/subscriber/getAllSubscribers?id=${uerAuthId}`);
        setcommDirectory(result.data.data);
        console.log("comdirectory", result.data.data)

      }
      else if(directFilter=="bookmark profile"){
        const result = await axios.get(configData.ApiUrl+'/subscriber/getAllSubscribers?id=' + uerAuthId + '&filter=' + directFilter);
        setcommDirectory(result.data.data);
        console.log("comdirectory", result.data.data)
        const rootElement8 = document.getElementById('profpicdisablesave8');
    const rootElement9 = document.getElementById('profpicdisablesave9');



      }
      else if (directFilter == 'country') {
        debugger

        const result = await axios.get(configData.ApiUrl+'/subscriber/getAllSubscribers?id=' + uerAuthId + '&filter=' + filterDirect.name + '&country=' + filterCountryName);
        setcommDirectory(result.data.data);
        console.log("comdirectory", result.data.data)

      }
      else if (filterdiData.filterDirect == 'state') {
        debugger

        const result = await axios.get(configData.ApiUrl+'/subscriber/getAllSubscribers?id=' + uerAuthId + '&filter=' + filterDirect.name + '&state=' + filterStateName);
        setcommDirectory(result.data.data);
        console.log("comdirectory", result.data.data)
        const rootElement8 = document.getElementById('profpicdisablesave8');
    const rootElement9 = document.getElementById('profpicdisablesave9');



      }
      else if (filterdiData.filterDirect == 'city') {
        debugger

        const result = await axios.get(configData.ApiUrl+'/subscriber/getAllSubscribers?id=' + uerAuthId + '&filter=' + filterDirect.name + '&city=' + filterCityName);
        setcommDirectory(result.data.data);
        console.log("comdirectory", result.data.data)

      }
      else if (filterdiData.filterDirect == 'blood group') {
        debugger
        const result = await axios.get(configData.ApiUrl+'/subscriber/getAllSubscribers?id=' + uerAuthId + '&filter=' + filterDirect.name + '&bloodGroup=' + filterBlooodName);
        setcommDirectory(result.data.data);
        console.log("comdirectory", result.data.data)

      }
      else if (filterdiData.filterDirect == 'profession') {
        debugger
        const result = await axios.get(configData.ApiUrl+'/subscriber/getAllSubscribers?id=' + uerAuthId + '&filter=' + filterDirect.name + '&profession=' + filterProfessionName);
        setcommDirectory(result.data.data);
        console.log("comdirectory", result.data.data)

      }
      else {
        debugger
        const result = await axios.get(configData.ApiUrl+'/subscriber/getAllSubscribers?id=' + uerAuthId + '&filter=' + filterDirect.name);
        setcommDirectory(result.data.data);
        console.log("comdirectory", result.data.data)


      }

    };

    //   const loadCommDirectory = async (abc) => {
    //    debugger
    //    const result = await axios.get('/subscriber/getAllSubscribers?id='+uerAuthId+'&filter='+abc);
    //    setcommDirectory(result.data.data);
    //    console.log("comdirectory",result.data.data)
    //  };

    const handleSearchForDirectChange = (e) => {
      debugger
      if (e.currentTarget.value === "") {
        loadCommDirectory();
        return;
      }
      const filterBySearch = commDirectory.filter((item) => {
        if (item != null && item.fullName != null) {
          if (item?.fullName?.toLowerCase().includes(e.currentTarget.value)) { return item; }
        }
         if ( item != null && item.profession != null){
           if (item?.profession?.toLowerCase()
               .includes(e.currentTarget.value)) { return item; }
              }

          if ( item != null && item.subCaste != null){
             if (item?.subCaste?.toLowerCase()
                .includes(e.currentTarget.value)) { return item; }
                }
            if ( item != null && item.currentCity != null){
              if (item?.currentCity?.toLowerCase()
                 .includes(e.currentTarget.value)) { return item; }
                }
      })
      setcommDirectory(filterBySearch);
    }
  //disable download and rightclick on image
  // const noRightClick = document.getElementById("profpicdisablesave");
  // if(noRightClick){
  //   noRightClick.addEventListener("contextmenu", (e) => {e.preventDefault()});

  // }
  //disable download and rightclick on image
  // useEffect(() => {
  //   function handleContextMenu(e) {
  //     e.preventDefault(); // prevents the default right-click menu from appearing
  //   }
  //   // add the event listener to the component's root element
  //   const rootElement = document.getElementById('profpicdisablesave');
  //   rootElement.addEventListener('contextmenu', handleContextMenu);
  //   // remove the event listener when the component is unmounted

  //   return () => {
  //     rootElement.removeEventListener('contextmenu', handleContextMenu);
  //   };
  // }, []);


    //for nidudirectory
    const [isOpenNaiduDiPopup, setisOpenNaiduDiPopup] = useState(false);

    const togglePopupNaiduDi = () => {
      setisOpenNaiduDiPopup(!isOpenNaiduDiPopup);
    }
    //for businessdirectory
    const [isOpenbusinessDiPopup, setisOpenbusinessDiPopup] = useState(false);

    const togglePopupbussDi = () => {
      setisOpenbusinessDiPopup(!isOpenbusinessDiPopup);
    }

    const [isOpenJobPostContactPopup, setisOpenJobPostContactPopup] = useState(false);
    const toggleJobPostPopupContact = () => {
      setisOpenJobPostContactPopup(!isOpenJobPostContactPopup);
    }

    const [viewContactJobpostDiv, setviewContactJobpost] = useState(false);
    function ContactJobPostpoupopen(iscontactJobPost) {
      if (iscontactJobPost) {
        setviewContactJobpost(true);
      }
      else {
        setviewContactJobpost(false);
      }
    }

    const viewContactJobPost = async (id) => {

      debugger;
      const response = await axios.post(configData.ApiUrl+'/CommunityPost/viewJobContactDetails?userId=' + uerAuthId + '&postId=' + id)
      if (response.status == 200 && response.data.code) {
        // toast.success("Update Status  Successfully!")
        setviewContactJobpost(true);
      loadPostList();
      }
      else {
        alert("Something Went Wrong..!")
      }
    }

    const viewContactJobPostPdf = async (id) => {

      debugger;
      const response = await axios.post(configData.ApiUrl+'/CommunityPost/viewJobContactDetails?userId=' + uerAuthId + '&postId=' + id)
      if (response.status == 200 && response.data.code) {
        // toast.success("Update Status  Successfully!")
       // setviewContactJobpost(true);
      loadPostList();
      }
      else {
        alert("Something Went Wrong..!")
      }
    }

    //for bussness filter


    const handleSearchChangeBusiness = (e) => {
      debugger
      if (e.currentTarget.value === "") {
        loadBusinessList();
        return;
      }
      const filterBySearch = allbusinessList.filter((item) => {
        if (item != null && item.buisnessName != null) {
          if (item?.buisnessName?.toLowerCase().includes(e.currentTarget.value)) { return item; }
        }
         if ( item != null && item.city != null){
           if (item?.city?.toLowerCase()
               .includes(e.currentTarget.value)) { return item; }}
      })
      setallbusinessList(filterBySearch);
    }





  const filterLoadBusiness = async (value)=>{
    debugger
    const result = await axios.get(configData.ApiUrl+'/buisness/getBuisnessProfiles?buisnessId=' + authlocalstoragedata.buisnessId + '&businessCategory=' + value );
    setmyProfilebusinessList(result.data.data);
      console.log("bussdirectfilter", result.data.data)
  }


    const handleChangeBussFilter = (e) => {
      debugger
      const { name, value, type, checked } = e.currentTarget;

      filterLoadBusiness( e.target.value)


    }


    //for matrimony shortlist search
    const handleSearchForShortMatrimony = (e) => {
      debugger
      if (e.currentTarget.value === "") {
        loadMatShortProf();
        return;
      }
      const filterBySearch = matrimonyShortProf.filter((item) => {
        if (item != null && item.caste != null) {
          if (item?.caste?.toLowerCase().includes(e.currentTarget.value)) { return item; }
        }
         if ( item != null && item.livesIn != null){
           if (item?.livesIn?.toLowerCase()
               .includes(e.currentTarget.value)) { return item; }
              }

          if ( item != null && item.from != null){
             if (item?.from?.toLowerCase()
                .includes(e.currentTarget.value)) { return item; }
                }
            if ( item != null && item.motherTongue != null){
              if (item?.motherTongue?.toLowerCase()
                 .includes(e.currentTarget.value)) { return item; }
                }
            if ( item != null && item.eduQualification != null){
                  if (item?.eduQualification?.toLowerCase()
                     .includes(e.currentTarget.value)) { return item; }
                    }
            if ( item != null && item.working != null){
                      if (item?.working?.toLowerCase()
                         .includes(e.currentTarget.value)) { return item; }
                        }
              if ( item != null && item.name != null){
                          if (item?.name?.toLowerCase()
                             .includes(e.currentTarget.value)) { return item; }
                            }
      })
      setmatrimonyShortProf(filterBySearch);
    }

     //for read more blog text
     const [expandedblog, setExpandedblog] = useState(false);


  
   //for read more kyc text

   const [expanded, setExpanded] = useState(false);

      //for close popup when click on outside
  const popupLikeRef = useRef(null);
  const handleClickOutsideLikediv = (event) => {
    if (popupLikeRef.current && !popupLikeRef.current.contains(event.target)) {
      setLikeDiv(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideLikediv);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideLikediv);
    };
  }, []);


  //for randm color
   const colors = ["#ff8427", "#3498db", "#2ecc71", "#e74c3c", "#9b59b6", "#f1c40f", "#e67e22"];
   const getRandomColor = () => {
    return colors[Math.floor(Math.random() * colors.length)];
  };
  const [bgColor, setBgColor] = useState(""); // Initialize state for background color

    useEffect(() => {
      // Set random color only once during the initial render
      setBgColor(getRandomColor());
    }, []);
    //for opening expand message popup
    const [isOpenExpandMessage, setisOpenExpandMessage] = useState(false);
    const togglePopupExpand = () => {
      setisOpenExpandMessage(!isOpenExpandMessage);
    }
    //for coffeemeetup search hide show
    const [showSearchBox, setShowSearchBox] = useState(false);

  const handleSearchClick = () => {
    setShowSearchBox(true);
  };
  const handleResetClick = () => {
    setShowSearchBox(false); // Reset view to "Sort By" and search icon
  };


  //for opening expand message popup
  const [isOpenMembersPopup, setisOpenMembersPopup] = useState(false);
  const togglePopupMembers = () => {
    setisOpenMembersPopup(!isOpenMembersPopup);
  }


  const [activeEventButton, setactiveEventButton] = useState(''); // Tracks the active button

  // const handleEventButtonClick = (buttonName) => {
  //   setactiveEventButton(buttonName); // Sets the clicked button as active
  // };

  const handleEventButtonClick = (buttonName) => {
    setactiveEventButton(buttonName);

    switch (buttonName) {
      case 'Event':
        togglePopup(); // connectedStatus = 1 for My Contacts
        break;
       case 'Blog':
        togglePopupBlog(); // connectedStatus = 0 for Not in Contact
         break;
         case 'KYC':
          togglePopupKyc(); // connectedStatus = 0 for Not in Contact
         break;
         case 'Job Posting':
          togglePopuptopJobPop(); // connectedStatus = 0 for Not in Contact
         break;
      default:
        loadMembers(0); // connectedStatus = null for All
    }
  };
  const handleClosePopup = (closePopup) => {
    closePopup(); // Close the popup
    setactiveEventButton(''); // Remove button activeness
  };
  //for sort by all
  //for event blog showall
const handledropSearchChangePost=(e)=> {
  debugger
  const { value } = e.target;

  if (value === 'showAll') {
    setFilterpost(''); // Clear the filter
    setloadPost(allPosts); // Show all posts
  } else {
    setFilterpost(value); // Set the filter
    setloadPost(
      allPosts.filter((post) =>
        post?.type?.toLowerCase().includes(value.toLowerCase())
      )
    ); // Apply the filter
  }

}

return(
<div className="container-fluid containerfluidpadding0">
      <div className="container-fluid  userlandtop-div">
      <div className="row">
          <div className="col-lg-1 col-sm-12 col-md-3">
            <img src={logo} className='' id='profpicdisablesave' style={{paddingTop:"14px"}}/>

          </div>
          <div className="col-lg-1 "></div>

          <div className="col-lg-8 col-sm-12 col-md-12" style={{display:'inline-flex',paddingTop:"31px",justifyContent: "center", gap: "20px"}}>
          {/* <div className="row " style={{paddingTop:"31px",justifyContent: "center", gap: "10px" }}> */}

          <div className=" ">
            <span className={news ? "textdecorunder menu-name" : "menu-name"} onClick={showNews} name="news">News & Updates</span>
          </div>
          <div className=" ">
            <span className={communityDirect ? "textdecorunder menu-name" : "menu-name"} onClick={showCommunityDirect} name="cdirect">Community Directory</span>
          </div>
          <div className=" ">
            <span className={matrimony ? "textdecorunder menu-name" : "menu-name"} onClick={showMatrimony} name="matrimony">Matrimony</span>
          </div>
          <div className=" ">
            <span className={businessDirect ? "textdecorunder menu-name" : "menu-name"} onClick={showBusinessDirect} name="bdirect">Business Directory</span>
          </div>
          {/* <div className="col-lg-2 col-sm-12 col-md-4 textcenter">
            <span className={communityMeet ? "textdecorunder menu-name" : "menu-name"} onClick={showCommunityMeet} name="communitym">Community Meetups</span>
          </div> */}

        {/* </div> */}

          </div>
          {/* <div className="col-lg-1 "></div> */}

          <div className="col-1 displyinline  ">
            <MdNotifications size='1.8em' className="notifiicon" onClick={togglePopupNotify} />
            {isOpenNotification && <NotificationPopup
              handleClose={togglePopupNotify}
            />
            }
            <p className="notifi-text">12</p>
            {/* <RiCupFill className="cupicon-top" size='1.7em' />
            <p className="notifi-text1">2</p> */}

          </div>
          <div className="col-lg-1 col-sm-12 col-md-12 textcenter">

            <div className=" margintop1 textright">


                  <div className="row  textcenter">
                    <div className="col-lg-1 col-md-2 col-sm-12 margintop1">
                      {loadProfile == "" || loadProfile == null ?
                        (<Avatar className="" style={{marginTop:"16px", width: "40px", height: "37px",fontSize:"15px",backgroundColor:bgColor,color:"#ffffff" }}>{avtarProf}</Avatar>)
                        : (<img src={loadProfile} id='profpicdisablesave1' className='prof-img-right topprofsmimg' style={{marginTop:"16px"}} />)
                      }

                    </div>
                    {/* <div className="col-lg-8 col-sm-12 col-md-9 margintop1 textcenter" style={{paddingTop:"4px"}}>
                      <span className="proftop-name textShowondottopprofname capitalizetxxxt">{authlocalstoragedata?.name2}</span>
                    </div> */}
                     <div className="col-lg-1 col-md-1 col-sm-12 margintop1">
                     <div class="dropdown drpdiv" style={{marginLeft:"10px",marginTop:"20px"}}>
                     <button type="button" class="drpbtn-menu dropdown-toggle" data-toggle="dropdown">
                      {/* <MdArrowDropDown size='1.9em' style={{marginTop:"6px"}}/> */}
                      </button>
                      <div class="dropdown-menu drpdn-meni-item" style={{ cursor: "pointer" }}>
                  <a class="dropdown-item drp-item" onClick={redirecttoViewProf} style={{ cursor: "pointer" }}>View Profile</a>
                  <a class="dropdown-item drp-item" onClick={redirectToContact}>View Contacts</a>
                  <a class="dropdown-item drp-item" onClick={redirectToBloodReq}>Blood Requirement Request</a>
                  <a class="dropdown-item drp-item" >View Subscriptions</a>
                  <a class="dropdown-item drp-item" onClick={redirectToContactRecive}>View Connect requests</a>
                  <a class="dropdown-item drp-item" onClick={redirectToReferal}>Referrals</a>
                  <a class="dropdown-item drp-item" onClick={redirectToAccSetting}>Change Password</a>
                  <a class="dropdown-item drp-item" onClick={redirectToAccSetting}>My Account</a>
                  <a class="dropdown-item drp-item" onClick={logoutclick}>Sign Out <HiOutlineLogout size='1.4em' className="marginleft2" /></a>
                </div>
                      </div>

                    </div>
                  </div>


            </div>


          </div>


        </div>

            </div>
            <div className="row ">
        <div className="col-lg-3 col-sm-12 col-md-12 leftdivuser heightforAll3Div" id="radiusdivchek">
        <div className="row margintop3">

<div className="borderbottom-radius">


  <div className="margintop1 "></div>
  <div className="radius-div" style={{ marginLeft: "-10px" }} >
    <div className="textleft radiussub-div"><img src={mylocationimg} style={{color:"#626262"}}/>    <span className="radius-heding" style={{marginLeft:"5px"}}> Members In My Location({members && members.length})</span><span className="seealltxt cpointer" onClick={togglePopupMembers}>See All</span></div>
    {isOpenMembersPopup && <MembersInMyLocationSeAllPopup

handleClose={togglePopupMembers}
/>}
    {/* <div className="row">
      <div className="col-lg-4"></div>
      <div className="col-lg-2 col-sm-4 col-md-4">
        <span className="radius-heding">{members && members.length}</span></div>

    </div> */}
    <div className="row margintop3"></div>


    <div style={{ marginLeft: "17px" }}>

      <Carousel cols={4} rows={members?.length > 4 ? 2 : 1}  gap={10} loop autoplay={1200} >


{members.map((image, index) => (
          // <Tippy content={imageUrl.name} style={{fontSize:"11px"}}>

<Carousel.Item onClick={openLightbox}>
          {image.profilePhotoPath == "" || image.profilePhotoPath == null ?
            (<Avatar className="" style={{ width: "53px", height: "52px",backgroundColor: bgColor,color:"#ffffff",textTransform:"capitalize" }}>{image.name?.substring(0, 2)}</Avatar>)
            : (<img src={image.profilePhotoPath}  id='profpicdisablesave2' title={image.name} className='prof-img-right topprofsmimg eventlogoimg1' style={{ cursor: "pointer",borderRadius:"50%",width: "57px", height: "56px" }} />)
          }
            {/* <img width="100%" src={image.profilePhotoPath} id='profpicdisablesave2' title={image.name} style={{ cursor: "pointer" }} /> */}
          </Carousel.Item>
          // </Tippy>
        ))
        }
        {
          members && members.length == 0 && (
            <Carousel.Item >
              <div style={{ width: "100%", height: "20px", color: "gray", marginLeft: "90px", paddingTop: '0px' }}>0 Members</div>
            </Carousel.Item>
          )
        }
        <ModalGateway >
          {viewerIsOpen ? (

            <Modal onClose={closeLightbox}>
              <Carousel1
                currentIndex={currentImage}
                views={
                  //members.map(x => ({
                  //   ...x,
                  //   srcset: x.srcSet,
                  //   caption: x.title
                  // }))
                  sliderImageUrl
                }
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </Carousel>

    </div>


    {/* <div className="margintop3" style={{ marginLeft: "5px", marginTop: "10px" }}>

      <label class="chechboxxcustom" style={{fontSize:"12px",marginTop:"5px",fontWeight:"500"}}>Show Connected Members Only
        <input type="checkbox" class=" form-check-input " name='connectedStatus' onChange={handleChange} value="" />
        <span class="checkmark1"></span>
      </label>
    </div> */}
  </div>
</div>
<div className="borderbottom-radius margintop3">


  <div className="margintop1 "></div>
  <div className="radius-div" style={{ marginLeft: "-10px",paddingBottom:"0px" }} >
    <div className="textleft radiussub-div"><FiUsers color="#626262" size="1.1em"/> <span className="radius-heding" style={{marginLeft:"5px"}}> My Community Networks</span>
    </div>
    <div className="row margintop3">
                  <div className="col-lg-7 col-sm-12 col-md-6">
                    <span className="radius-secobd-text " style={{marginLeft:"-10px"}}>Members Joined <span style={{color:"#212529"}}>({countmenu.count2})</span></span>
                  </div>


                </div>
                <div className="row margintop1"></div>
                <div className="textcenter margintop3 radiussub-btn-div"><span className="radius-heding-invite cpointer" style={{marginLeft:"0px",fontSize:"14px",fontWeight:"500",color:"#ffffff"}} onClick={togglePopupMessage1}> Invite New Members</span></div>

                {isOpenMessagePop1 && <ReferInviteCommMemberPopup

       handleClose={togglePopupMessage1}
      />}


  </div>
</div>

<div className="borderbottom-radius margintop3">


  <div className="margintop1 "></div>
  <div className="radius-div" style={{ marginLeft: "-10px" ,paddingBottom:'0px'}} >
    <div className="textleft radiussub-div"><img src={myplatform} style={{color:"#626262",width:"16px",height:"16px"}}/>   <span className="radius-heding" style={{marginLeft:"5px"}}>  My Community Platforms</span>
    </div>

    <div className="margintop">
                  {/* <label class="form-check-label ">
    <input type="checkbox" class=" form-check-input chek" checked value=""/>
    <span className="radius-secobd-text">Community Listing & Directory </span>
    </label> */}
                  {/* <label class="chechboxxcustom">Community Listing & Directory
                    <input type="checkbox" checked={authlocalstoragedata?.directory == true ? true : false} class=" form-check-input " value="" />
                    <span class="checkmark1"></span>
                  </label> */}
                   <label class="chechboxxcustom" style={{ cursor: "pointer",fontSize:"12px",fontWeight:"500" }}>Naidu Directory -Free
                    <input type="checkbox" checked={authlocalstoragedata?.directory == true ? true : false} class=" form-check-input " value="" />
                    <span class="checkmark1"></span>
                  </label>
                </div>
                <div className=" margintop2" style={{ whiteSpace: "nowrap" }}>
                  {/* <label class="form-check-label ">
    <input type="checkbox" class=" form-check-input chek" checked value=""/>
    <span className="radius-secobd-text">Community Meetups</span>
    </label> */}
                  {/* <label class="chechboxxcustom">Community Meetups
                    <input type="checkbox" class="form-check-input" checked={authlocalstoragedata?.directory == true ? true : false} value="" />
                    <span class="checkmark1"></span>
                  </label> */}
                  <div className="row ">
                    <div className="col-lg-8 col-sm-12 col-md-12 displyinline " style={{ marginLeft: "-15px" }}>
                      <label class="chechboxxcustom" style={{ cursor: "pointer",fontSize:"12px",fontWeight:"500" }}>Naidu Directory -Premium
                        <input type="checkbox" class=" form-check-input " value="" />
                        <span class="checkmark1"></span>
                      </label>
                      <span className="radius-second-linktext cpointer" onClick={togglePopupNaiduDi} style={{cursor:"pointer",marginLeft:'5px',color:"#FF8427",fontStyle:"italic",fontSize:"12px",fontWeight:"500"}}>Sign Up</span>
                      {isOpenNaiduDiPopup && <SubscribePremiem
                      handleClose={togglePopupNaiduDi}
                    />
                    }
                    </div>

                  </div>
                </div>
                <div className=" ">
                  {/* <label class="form-check-label ">
    <input type="checkbox" class=" form-check-input chek" checked value=""/>
    <span className="radius-secobd-text">Business Directory</span>
    </label> */}
                  <label class="chechboxxcustom" style={{ cursor: "pointer",fontSize:"12px",fontWeight:"500" }}>Business Directory
                    <input type="checkbox" class=" form-check-input " checked value="" />
                    <span class="checkmark1"></span>
                  </label>
                </div>
                <div className=" margintop2" style={{ whiteSpace: "nowrap" }}>
                  {/* <label class="form-check-label ">
    <input type="checkbox" class=" form-check-input chek" value=""/>
    <span className="radius-secobd-text">Community Matrimony</span>  <span className="radius-second-linktext">Sign Up</span>
    </label> */}
                  <div className="row ">
                    <div className="col-lg-7 col-sm-12 col-md-12 displyinline " style={{ marginLeft: "-15px" }}>
                      <label class="chechboxxcustom" style={{ cursor: "pointer" ,fontSize:"12px",fontWeight:"500"}}>Community Matrimony
                        <input type="checkbox" class=" form-check-input " value="" checked={authlocalstoragedata?.matrimony == true ? true : false}/>
                        <span class="checkmark1"></span>
                      </label>
                      <span className={authlocalstoragedata.matrimony ==false?"radius-second-linktext cpointer":"hide"} style={{ marginLeft: "5px" ,cursor:"pointer",color:"#FF8427",fontStyle:"italic",fontSize:"12px",fontWeight:"500"}} onClick={togglePopupSignUpmatri}>Sign Up</span>
                      {isOpenSignMatriPopup && <SignUpMatrimony
                    handleClose={togglePopupSignUpmatri}
                  />
                  }
                    </div>



                  </div>


                </div>
                <div className=" " style={{ whiteSpace: "nowrap" }}>
                  {/* <label class="form-check-label ">
    <input type="checkbox" class=" form-check-input chek" value=""/>
    <span className="radius-secobd-text">Business Listing</span> <FaInfoCircle color='#214B0D' size='0.9em'/> <span className="radius-second-linktext">Sign Up</span>
    </label> */}
                  <div className="row">
                    <div className="col-lg-6 col-sm-12 col-md-12 displyinline" style={{ marginLeft: "-15px" }}>
                      <label class="chechboxxcustom" style={{ cursor: "pointer",fontSize:"12px",fontWeight:"500" }}>Business Listing
                        <input type="checkbox" class=" form-check-input " value="" checked={authlocalstoragedata?.buisnessDirectory == true ? true : false} />
                        <span class="checkmark1"></span>
                      </label>
                      <span className={authlocalstoragedata.buisnessDirectory ==false?"radius-second-linktext cpointer":"hide"} onClick={togglePopupbussDi} style={{cursor:"pointer",marginLeft:"5px",color:"#FF8427",fontStyle:"italic",fontSize:"12px",fontWeight:"500"}}>Sign Up</span>
                      {isOpenbusinessDiPopup && <BusinessFirstPage
                    handleClose={togglePopupbussDi}
                  />
                  }
                    </div>

                  </div>



                </div>


  </div>
</div>

<div className="borderbottom-radius margintop3">


  <div className="margintop1 "></div>
  <div className="radius-div" style={{ marginLeft: "-10px" ,paddingBottom:'20px'}} >
    <div className="textleft radiussub-div"><GiCoffeeCup size="1.3em" color="#626262" style={{marginTop:"-4px"}}/>    <span className="radius-heding" style={{marginLeft:"5px"}}>  My Coffee Meet Up </span><span className="seealltxt cpointer">See All</span>
    </div>
    <div className="row margintop3"></div>
    {
                  coffeeMeets && coffeeMeets.length > 0 ? (
                    <span className="radius-fourth-subspn" style={{fontSize:"12px",fontWeight:"500",fontStyle:"italic",color:"#964B00",marginLeft:'2px'}}>You have {coffeeMeets.length} Upcoming Cofee Meet Ups</span>
                  ) : (
                    <span className="radius-fourth-subspn" style={{fontSize:"12px",fontWeight:"500",fontStyle:"italic",color:"#964B00",marginLeft:'2px'}}>You have 0 Upcoming Cofee Meet Ups</span>

                  )
                }
                <div>

                {!showSearchBox && (
 <div className="row margintop2 ">

 {/* <div className="col-lg-3 col-sm-12 col-md-12 margintop1 "></div> */}
  <div className="col-lg-10 col-sm-12 col-md-12 margintop1 displyinline minusleftmarg">
  <span className="sortbytxt" style={{whiteSpace:"nowrap",marginTop:"6px"}}>Sort By:</span>
    <select id="inputState" name='filterstatus' onChange={handleChangeFilter}
      className=" form-control sortbytxt" style={{ height: "28px", fontSize: "11px",borderColor:"#D9D9D9" ,marginLeft:"5px"}}>
      <option selected value="">Date</option>
      <option value="Pending">Pending Response</option>
      <option value="accepted">Accepted Invitations</option>
    </select>

  </div>
   <div className=" col-lg-1 col-md-6 col-sm-6 has-search margintop1">
   <FaSearch onClick={handleSearchClick} color="#626262" style={{ cursor: 'pointer', margintop: '5px' }}/>

  </div>

</div>)}
{showSearchBox && (
  <div className="row margintop2">
                <div class="form-group col-lg-11 col-md-6 col-sm-6 has-search margintop1" style={{ display: 'flex', alignItems: 'center' }}>
                <FaArrowLeft
                color="#626262"
                onClick={handleResetClick}
                style={{ cursor: 'pointer', marginRight: '8px' }}
              />
                    <span class="fa fa-search form-control-feedback"></span>
                    <input type="text" class="form-control" placeholder="Search by Organizer" onChange={handleSearchChange} style={{ fontSize: '11px',borderRadius:'15px' }} />

                  </div>
                </div>
                )}
                </div>




    <div>

    <Carousel cols={1} rows={1} gap={10} loop autoplay={4000} hideArrow={true} showDots={true}>
    {
                  coffeeMeets.map((getmenu, index) => (
                    pendingDIv && getmenu.attendStatus == 'Pending' ? (
    <Carousel.Item >
      <div className="">
   <div className="row margintop2" >
     <div className="col-lg-12 minusleftmarg8 margintop2" >
       <div className="row" style={{borderWidth:"1px",borderStyle:"solid",borderColor:"#964B00",borderRadius:"5px",marginRight:"px", paddingBottom:'5px',paddingTop:"5px"}}>
       <span className="fontroboto40012px">Hosting Coffee Meet Up on <span style={{fontWeight:"600"}}>{getmenu.date} </span> at <span style={{fontWeight:"600"}}>{getmenu.venue}</span>, Starting at <span style={{fontWeight:"600"}}>{getmenu.time}.</span></span>
     </div>
     </div>
   </div>
   <div className="row margintop3">
     <span className="fontroboto40012px" style={{fontSize:"11px"}}>{getmenu.guests?.length}/10 Members are attending this Coffee Meet Up</span>
   </div>
   <div className="minusleftmarg margintop3">
   <Carousel cols={1} rows={1} gap={1} loop autoplay={4000} hideArrow={true} showDots={false} style={{width:"200px"}}>


 <Carousel.Item>
   <div className="row" >
    <div className="col-3 ">
                       {getmenu.bannerImagePath == "" || getmenu.bannerImagePath == null ?
                     (<Avatar className="borderstatshost" style={{ width: "53px",marginTop:"3px", height: "50px",backgroundColor: "#ff8427",color:"#ffffff" }}>{getmenu.hostName?.substring(0, 2)}</Avatar>)
                     : (<img src={getmenu.bannerImagePath} id='profpicdisablesave1' className='prof-img-right topprofsmimg eventlogoimg1 borderstatshost' />)
                   }
                   <div className="tectcenter margintop1" style={{ height: '30px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}><span className="coffeemeet-host-name" style={{color:"#797979",fontSize:"11px",fontWeight:"400",textAlign:"center",marginTop:"3px"}}>{getmenu.hostName}</span></div>
                   <div className=""style={{ textAlign: 'center', minHeight: '20px' }}><span className="coffeemeet-host-name"style={{color:"#964B00",fontSize:"11px",fontWeight:"500"}}>Host</span></div>

                       </div>
                       {
                             getmenu.guests?.map((gname, index) => (

   <div className="col-3">

                                      <span className={gname.status == 'Reject'?"":"hide"}><img src={gname.profilePicture} id="othercoffeeprofid" className='borderstatusspnrej' style={{ width: "55px", height: "53px", borderRadius: "50%",marginBottom: '5px' }} /></span>
                                      <span className={gname.status == 'Attend'?"":"hide"}><img src={gname.profilePicture} id="othercoffeeprofid" className='borderstatusspnatt' style={{ width: "55px", height: "53px", borderRadius: "50%",marginBottom: '5px'  }} /></span>
                                      <span className={gname.status == 'Pending'?"":"hide"}><img src={gname.profilePicture} id="othercoffeeprofid" className='borderstatusspnpen' style={{ width: "55px", height: "53px", borderRadius: "50%",marginBottom: '5px'  }} /></span>



                             {/* <span className="borderstatusspn"><img src={gname.profilePicture} id="othercoffeeprofid" className=' ' style={{ width: "100px", height: "100px", borderRadius: "50%" }} /></span> */}
                             <div className="textcenter" style={{ height: '30px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>

 <span className="coffeemeet-host-name" style={{ fontSize: '12px', maxWidth: '100px', textAlign: 'center', whiteSpace: 'normal', lineHeight: '1.2', overflow: 'hidden',fontWeight:'400',color:'#797979' }}>{gname.name}</span>
 </div>
 <div className="textcenter" style={{ textAlign: 'center', minHeight: '20px' }}>
 <span className={gname.status === 'Reject' ? "coffeemeet-host-name" : "hide"} style={{ fontSize: '12px', color: "#E75480", whiteSpace: 'nowrap',marginLeft:"-1px" }}>{gname.status}</span>
 <span className={gname.status === 'Attend' ? "coffeemeet-host-name" : "hide"} style={{ fontSize: '12px', color: "#4CAF50", whiteSpace: 'nowrap',marginLeft:"-1px" }}>{gname.status}</span>
 <span className={gname.status === 'Pending' ? "coffeemeet-host-name" : "hide"} style={{ fontSize: '12px', color: "#FFC107", whiteSpace: 'nowrap',marginLeft:"-1px" }}>{gname.status}</span>
</div>

</div>
))
}
                           </div>
  </Carousel.Item>







                 </Carousel>

   </div>

   <div className="row margintop3 " style={{justifyItems:"center", marginLeft:"15px" }}>
     <div className="col-2"></div>
     <div className="col-5">
     <button className={getmenu.attendStatus == 'Pending' ? "attendbtn cpointer" : "hide"} style={{ marginTop: '6px', position: "absolute", width: "180px" }} onClick={() => attendStatusCofeeMeet(getmenu.id)}>Attend</button>
                 {/* <button className={getmenu.attendStatus=='Pending'?"hide ":"attendbtn"} style={{marginTop:'6px',position:"absolute"}} onClick={()=>attendStatusCofeeMeet(getmenu.id)}>Attend</button> */}
                 <div class={getmenu.attendStatus == 'Pending' ? "hide" : "dropdown"}>
                   <button class="btn  dropdown-toggle attendbtn cpointer" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ width: "180px" }} onClick={() => attendStatusCofeeMeet(getmenu.id)}>
                     You are Attending
                   </button>
                   <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                     <a class="dropdown-item" style={{ fontSize: '14px', }}>Withdraw Attendance</a>
                   </div>
                 </div>
     </div>
     <div className="col-3"></div>
     </div>



               <div className="row margintop3 "></div>
               </div>
               </Carousel.Item>):
               acceptedDiv && getmenu.attendStatus == 'Attending' ?
               (
                <Carousel.Item >
                <div className="">
                <div className="row margintop2" >
                  <div className="col-lg-12 minusleftmarg8 margintop2" >
                    <div className="row" style={{borderWidth:"1px",borderStyle:"solid",borderColor:"#964B00",borderRadius:"5px",marginRight:"px", paddingBottom:'5px',paddingTop:"5px"}}>
                    <span className="fontroboto40012px">Hosting Coffee Meet Up on <span style={{fontWeight:"600"}}>{getmenu.date} </span> at <span style={{fontWeight:"600"}}>{getmenu.venue}</span>, Starting at <span style={{fontWeight:"600"}}>{getmenu.time}.</span></span>
                  </div>
                  </div>
                </div>
                <div className="row margintop3">
                  <span className="fontroboto40012px" style={{fontSize:"11px"}}>{getmenu.guests?.length}/10 Members are attending this Coffee Meet Up</span>
                </div>
                <div className="minusleftmarg margintop3">
                <Carousel cols={1} rows={1} gap={1} loop autoplay={4000} hideArrow={true} showDots={false} style={{width:"200px"}}>


              <Carousel.Item>
                <div className="row" >
                 <div className="col-3 ">
                                    {getmenu.bannerImagePath == "" || getmenu.bannerImagePath == null ?
                                  (<Avatar className="borderstatshost" style={{ width: "53px",marginTop:"3px", height: "50px",backgroundColor: "#ff8427",color:"#ffffff" }}>{getmenu.hostName?.substring(0, 2)}</Avatar>)
                                  : (<img src={getmenu.bannerImagePath} id='profpicdisablesave1' className='prof-img-right topprofsmimg eventlogoimg1 borderstatshost' />)
                                }
                                <div className="tectcenter margintop1" style={{ height: '30px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}><span className="coffeemeet-host-name" style={{color:"#797979",fontSize:"11px",fontWeight:"400",textAlign:"center",marginTop:"3px"}}>{getmenu.hostName}</span></div>
                                <div className=""style={{ textAlign: 'center', minHeight: '20px' }}><span className="coffeemeet-host-name"style={{color:"#964B00",fontSize:"11px",fontWeight:"500"}}>Host</span></div>

                                    </div>
                                    {
                                          getmenu.guests?.map((gname, index) => (

                <div className="col-3">

                                                   <span className={gname.status == 'Reject'?"":"hide"}><img src={gname.profilePicture} id="othercoffeeprofid" className='borderstatusspnrej' style={{ width: "55px", height: "53px", borderRadius: "50%",marginBottom: '5px' }} /></span>
                                                   <span className={gname.status == 'Attend'?"":"hide"}><img src={gname.profilePicture} id="othercoffeeprofid" className='borderstatusspnatt' style={{ width: "55px", height: "53px", borderRadius: "50%",marginBottom: '5px'  }} /></span>
                                                   <span className={gname.status == 'Pending'?"":"hide"}><img src={gname.profilePicture} id="othercoffeeprofid" className='borderstatusspnpen' style={{ width: "55px", height: "53px", borderRadius: "50%",marginBottom: '5px'  }} /></span>



                                          {/* <span className="borderstatusspn"><img src={gname.profilePicture} id="othercoffeeprofid" className=' ' style={{ width: "100px", height: "100px", borderRadius: "50%" }} /></span> */}
                                          <div className="textcenter" style={{ height: '30px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>

              <span className="coffeemeet-host-name" style={{ fontSize: '12px', maxWidth: '100px', textAlign: 'center', whiteSpace: 'normal', lineHeight: '1.2', overflow: 'hidden',fontWeight:'400',color:'#797979' }}>{gname.name}</span>
              </div>
              <div className="textcenter" style={{ textAlign: 'center', minHeight: '20px' }}>
              <span className={gname.status === 'Reject' ? "coffeemeet-host-name" : "hide"} style={{ fontSize: '12px', color: "#E75480", whiteSpace: 'nowrap',marginLeft:"-1px" }}>{gname.status}</span>
              <span className={gname.status === 'Attend' ? "coffeemeet-host-name" : "hide"} style={{ fontSize: '12px', color: "#4CAF50", whiteSpace: 'nowrap',marginLeft:"-1px" }}>{gname.status}</span>
              <span className={gname.status === 'Pending' ? "coffeemeet-host-name" : "hide"} style={{ fontSize: '12px', color: "#FFC107", whiteSpace: 'nowrap',marginLeft:"-1px" }}>{gname.status}</span>
            </div>

          </div>
           ))
          }
                                        </div>
               </Carousel.Item>







                              </Carousel>

                </div>

                <div className="row margintop3 " style={{justifyItems:"center", marginLeft:"15px" }}>
                  <div className="col-2"></div>
                  <div className="col-5">
                  <button className={getmenu.attendStatus == 'Pending' ? "attendbtn cpointer" : "hide"} style={{ marginTop: '6px', position: "absolute", width: "180px" }} onClick={() => attendStatusCofeeMeet(getmenu.id)}>Attend</button>
                              {/* <button className={getmenu.attendStatus=='Pending'?"hide ":"attendbtn"} style={{marginTop:'6px',position:"absolute"}} onClick={()=>attendStatusCofeeMeet(getmenu.id)}>Attend</button> */}
                              <div class={getmenu.attendStatus == 'Pending' ? "hide" : "dropdown"}>
                                <button class="btn  dropdown-toggle attendbtn cpointer" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ width: "180px" }} onClick={() => attendStatusCofeeMeet(getmenu.id)}>
                                  You are Attending
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                  <a class="dropdown-item" style={{ fontSize: '14px', }}>Withdraw Attendance</a>
                                </div>
                              </div>
                  </div>
                  <div className="col-3"></div>
                  </div>



                            <div className="row margintop3 "></div>
                            </div>
                </Carousel.Item>
               ):
               pendingDIv == false && acceptedDiv == false ?(
                <Carousel.Item >
               <div className="">
      <div className="row margintop2" >
        <div className="col-lg-12 minusleftmarg8 margintop2" >
          <div className="row" style={{borderWidth:"1px",borderStyle:"solid",borderColor:"#964B00",borderRadius:"5px",marginRight:"px", paddingBottom:'5px',paddingTop:"5px"}}>
          <span className="fontroboto40012px">Hosting Coffee Meet Up on <span style={{fontWeight:"600"}}>{getmenu.date} </span> at <span style={{fontWeight:"600"}}>{getmenu.venue}</span>, Starting at <span style={{fontWeight:"600"}}>{getmenu.time}.</span></span>
        </div>
        </div>
      </div>
      <div className="row margintop3">
        <span className="fontroboto40012px" style={{fontSize:"11px"}}>{getmenu.guests?.length}/10 Members are attending this Coffee Meet Up</span>
      </div>
      <div className="minusleftmarg margintop3">
      <Carousel cols={1} rows={1} gap={1} loop autoplay={4000} hideArrow={true} showDots={false} style={{width:"200px"}}>


    <Carousel.Item>
      <div className="row" >
       <div className="col-3 ">
                          {getmenu.bannerImagePath == "" || getmenu.bannerImagePath == null ?
                        (<Avatar className="borderstatshost" style={{ width: "53px",marginTop:"3px", height: "50px",backgroundColor: "#ff8427",color:"#ffffff" }}>{getmenu.hostName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.bannerImagePath} id='profpicdisablesave1' className='prof-img-right topprofsmimg eventlogoimg1 borderstatshost' />)
                      }
                      <div className="tectcenter margintop1" style={{ height: '30px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}><span className="coffeemeet-host-name" style={{color:"#797979",fontSize:"11px",fontWeight:"400",textAlign:"center",marginTop:"3px"}}>{getmenu.hostName}</span></div>
                      <div className=""style={{ textAlign: 'center', minHeight: '20px' }}><span className="coffeemeet-host-name"style={{color:"#964B00",fontSize:"11px",fontWeight:"500"}}>Host</span></div>

                          </div>
                          {
                                getmenu.guests?.map((gname, index) => (

      <div className="col-3">

                                         <span className={gname.status == 'Reject'?"":"hide"}><img src={gname.profilePicture} id="othercoffeeprofid" className='borderstatusspnrej' style={{ width: "55px", height: "53px", borderRadius: "50%",marginBottom: '5px' }} /></span>
                                         <span className={gname.status == 'Attend'?"":"hide"}><img src={gname.profilePicture} id="othercoffeeprofid" className='borderstatusspnatt' style={{ width: "55px", height: "53px", borderRadius: "50%",marginBottom: '5px'  }} /></span>
                                         <span className={gname.status == 'Pending'?"":"hide"}><img src={gname.profilePicture} id="othercoffeeprofid" className='borderstatusspnpen' style={{ width: "55px", height: "53px", borderRadius: "50%",marginBottom: '5px'  }} /></span>



                                {/* <span className="borderstatusspn"><img src={gname.profilePicture} id="othercoffeeprofid" className=' ' style={{ width: "100px", height: "100px", borderRadius: "50%" }} /></span> */}
                                <div className="textcenter" style={{ height: '30px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>

    <span className="coffeemeet-host-name" style={{ fontSize: '12px', maxWidth: '100px', textAlign: 'center', whiteSpace: 'normal', lineHeight: '1.2', overflow: 'hidden',fontWeight:'400',color:'#797979' }}>{gname.name}</span>
    </div>
    <div className="textcenter" style={{ textAlign: 'center', minHeight: '20px' }}>
    <span className={gname.status === 'Reject' ? "coffeemeet-host-name" : "hide"} style={{ fontSize: '12px', color: "#E75480", whiteSpace: 'nowrap',marginLeft:"-1px" }}>{gname.status}</span>
    <span className={gname.status === 'Attend' ? "coffeemeet-host-name" : "hide"} style={{ fontSize: '12px', color: "#4CAF50", whiteSpace: 'nowrap',marginLeft:"-1px" }}>{gname.status}</span>
    <span className={gname.status === 'Pending' ? "coffeemeet-host-name" : "hide"} style={{ fontSize: '12px', color: "#FFC107", whiteSpace: 'nowrap',marginLeft:"-1px" }}>{gname.status}</span>
  </div>

</div>
 ))
}
                              </div>
     </Carousel.Item>







                    </Carousel>

      </div>

      <div className="row margintop3 " style={{justifyItems:"center", marginLeft:"15px" }}>
        <div className="col-2"></div>
        <div className="col-5">
        <button className={getmenu.attendStatus == 'Pending' ? "attendbtn cpointer" : "hide"} style={{ marginTop: '6px', position: "absolute", width: "180px" }} onClick={() => attendStatusCofeeMeet(getmenu.id)}>Attend</button>
                    {/* <button className={getmenu.attendStatus=='Pending'?"hide ":"attendbtn"} style={{marginTop:'6px',position:"absolute"}} onClick={()=>attendStatusCofeeMeet(getmenu.id)}>Attend</button> */}
                    <div class={getmenu.attendStatus == 'Pending' ? "hide" : "dropdown"}>
                      <button class="btn  dropdown-toggle attendbtn cpointer" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ width: "180px" }} onClick={() => attendStatusCofeeMeet(getmenu.id)}>
                        You are Attending
                      </button>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item" style={{ fontSize: '14px', }}>Withdraw Attendance</a>
                      </div>
                    </div>
        </div>
        <div className="col-3"></div>
        </div>



                  <div className="row margintop3 "></div>
                  </div>
                </Carousel.Item>
               ):null







      ))
    }
      </Carousel>




       </div>

  </div>
</div>
<div className="borderbottom-radius margintop3">


  <div className="margintop1 "></div>
  <div className="radius-div" style={{ marginLeft: "-10px",paddingLeft:'5px' }} >
    <div className="textleft radiussub-div"><FaRegHandshake size="1.3em" style={{marginLeft:"15px"}}/>    <span className="radius-heding" style={{marginLeft:"5px"}}> My Connection Requests</span><span className="seealltxt cpointer" onClick={redirectToContactRecive}>See All</span></div>
    {/* <div className="row">
      <div className="col-lg-4"></div>
      <div className="col-lg-2 col-sm-4 col-md-4">
        <span className="radius-heding">{members && members.length}</span></div>

    </div> */}
    <div className="row margintop3"></div>


    <div style={{ marginLeft: "14px" }}>

    {
                  connection && connection.length > 0 ? (
                    <span className="radius-fourth-subspn" style={{fontSize:"12px",fontWeight:"500",fontStyle:"italic",color:"#626262"}}>You have {connection.length} new connection requests</span>
                  )
                    :
                    (
                      <span className="radius-fourth-subspn" style={{fontSize:"12px",fontWeight:"500",fontStyle:"italic",color:"#626262"}}>You have 0 new connection requests</span>
                    )

                }
                                {
                  connection.map((getmenu, index) => (
                    <div className=" margintop2">
                      <div className="row minusleftmarg">
                        <div className="col-lg-2 col-sm-12 col-md-12 margintop1">
                          <img src={getmenu.profilePicturePath} id='profpicdisablesave3' className='topprofsmimg margintop1' />
                          {/* <div className=""><span className="radius-fourth-linktext">View Profile</span></div> */}
                        </div>
                        <div className="col-lg-8 col-sm-12 col-md-12" >
                          <div className=""><span className="radius-fourth-card1-lefttext ">{getmenu.senderName}</span> </div>

                          <div className=""><span className="radius-fourth-card1-text">{getmenu.currentCity}, {getmenu.currentState}, {getmenu.currentCountry}</span> </div>

                        </div>
                      </div>
                      <div className="row margintop2 marginleftrightminus" style={{marginLeft:'-15px',marginRight:'-15px'}}>
                      <div className="col-lg-6 col-sm-12 col-md-12  border-btn1 margintop1" style={{marginLeft:'px', cursor: "pointer"}} onClick={() => rejectConnection(getmenu.senderId)}>
                      <IoCloseOutline size="1.3em" color="#D9001B"/> <span className="radius-fourth-subspn" style={{ color: "#626262", marginLeft: "9px", cursor: "pointer" }} >Reject</span>

                      </div>

                        <div className="col-lg-6 col-sm-12 col-md-12 border-btn1 margintop1" onClick={() => acceptConnection(getmenu.senderId)} style={{marginRight:"px", cursor: "pointer"}}>
                        <IoMdCheckmark size="1.1em" color="#4CAF50"/> <span className="radius-fourth-subspn" style={{ color: "#626262", marginLeft: "9px", cursor: "pointer" }} >Accept</span>

                       </div>
                      </div>
                      <div className=" margintop3 borderBottombtn"></div>



                    </div>
                  ))
                }

    </div>



  </div>
</div>

</div>

        </div>
        <div className={news ? "col-lg-6 col-sm-12 col-md-12   heightforAll3Div" : "hide"}>
      <div className="row margintop2">
       <div className=" textleft margintop2">
         <span className="User-Prof-name ">Hi {authlocalstoragedata?.name2}</span>
          </div>
          <div className=" margintop3">
          <div className="radius-div-news" style={{paddingBottom:"px", }} >
          <div className="textcenter radiussub-div" style={{backgroundColor:"#ffffff",borderBottomColor:"#D9D9D9",borderBottomWidth:"1px",borderBottomStyle:"solid"}}>
            <div className="row">
              <div className="col-lg-1"></div>
              <div className="col-lg-2 col-sm-12 col-md-12">
              <span
              className={`post-name-btn eevent cpointer${activeEventButton === 'Event' ? 'active' : ''}`}
              onClick={() => handleEventButtonClick('Event')}

              >Event</span>

              </div>
              {isOpen && <AddNewsPopup

handleClose={() => handleClosePopup(togglePopup)}
/>}

              <div className="col-lg-2 col-sm-12 col-md-12">
              <span
              className={`post-name-btn eevent cpointer${activeEventButton === 'Blog' ? 'active' : ''}`}
              onClick={() => handleEventButtonClick('Blog')}
              >Blog</span>

              </div>
              {isOpenBlog && <AddBlogPopup

handleClose={() => handleClosePopup(togglePopupBlog)}
/>}
              <div className="col-lg-2 col-sm-12 col-md-12">
              <span
              className={`post-name-btn eevent cpointer${activeEventButton === 'KYC' ? 'active' : ''}`}
              onClick={() => handleEventButtonClick('KYC')}
              >KYC</span>
              </div>
              {isOpenkyc && <AddKycPopup

handleClose={() => handleClosePopup(togglePopupKyc)}
/>}
              <div className="col-lg-3 col-sm-12 col-md-12">
              <span
              className={`post-name-btn eevent cpointer${activeEventButton === 'Job Posting' ? 'active' : ''}`}
              onClick={() => handleEventButtonClick('Job Posting')}
              >Job Posting</span>
              </div>
              {isOpentopjobPop && <AddJobPop

handleClose={() => handleClosePopup(togglePopuptopJobPop)}
/>}
            </div>

          </div>
          <div className="row margintop3" style={{backgroundColor:"#F7F5F5",height:"37px",paddingTop:"1px",borderRadius:"5px"}}>
            <div className="col-lg-1 textright">
            {loadProfile == "" || loadProfile == null ?
                        (<Avatar className="" style={{marginTop:"3px", width: "30px", height: "29px",fontSize:"15px",backgroundColor:bgColor,color:"#ffffff",marginLeft:"15px" }}>{avtarProf}</Avatar>)
                        : (<img src={loadProfile} id='profpicdisablesave1' className='prof-img-right topprofsmimg' style={{marginTop:"px"}} />)
                      }
            </div>
            <div className="col-lg-11 " style={{marginTop:"7px"}}>
            <span className="share-an-event-txt">
                      Share an update (Your post will be approved shortly. All the content needs to be community oriented.)
                      </span>
            </div>


          </div>

            </div>
          </div>

          <div className=" displyinline" style={{ display: "flex", alignItems: "center",margin:"5px" }}>
             {/* <span style={{borderBottomWidth:'1px',borderBottomStyle:"solid",borderBottomColor:"#D9D9D9"}}>hii</span> */}
             <hr style={{ flex: 1, border: "1px solid #D9D9D9", marginRight: "2px" }} />

            <span className="post-name-btn" style={{margin:"0px",padding:"0px",marginleft:"7px"}}>Sort by</span>

            <select id="inputState"
          name='serchdropdown' onChange={handledropSearchChangePost}
           className="form-control textboxselect height20px User-Prof-name" style={{fontSize:'14px',color:"#212529",textTransform:"capitalize",width:"150px",height:"20px",float:'right',margin: "0 0 0 0", padding: 0}}>
              <option selected value="showAll"> All</option>
              <option value="event"> Events</option>
              <option value="blog"> Blog</option>
              <option value="kyc"> Know Your Community</option>
              <option value="jobPost"> Job Posting</option>
              <option value="coffeemeet"> Coffee Meetups</option>
              </select>
            </div>

            {
            loadPost.map((getmenu, index) => (
              <div>
            <div className={getmenu.event ? "radius-div-news margintop3" : "hide"}>
            <div className="row ">
                    <div className="col-lg-1 col-sm-12 col-md-12">
                        {getmenu.event?.profilePic == "" || getmenu.event?.profilePic == null ?
                        (<Avatar className="avtarimgsm" style={{ width: "50px",marginTop:"px", height: "49px",backgroundColor: bgColor,color:"#ffffff" }}>{getmenu.event?.hostName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.event?.profilePic} id='profpicdisablesave5' className='prof-img-right postprofsmimg eventlogoimg1' />)
                      }
                    </div>
                    <div className="col-lg-5 col-sm-12 col-md-12 textleft" style={{marginTop:"7px"}}>
                      <span className="post-host-Name ">{getmenu.event?.hostName}</span>
                      <div className=""style={{marginTop:"px"}}><span className="event-date">{getmenu.event?.createdDate} @{getmenu.event?.createdTime}</span></div>
                      </div>
                    {/* <div className="col-lg-2 col-sm-12" style={{ whiteSpace: "nowrap",marginTop:"px" }}> */}
                      {/* </div> */}
                    <div className="col-lg-6 col-sm-12 col-md-12 textright"style={{marginTop:"7px"}}><span className="post-host-Name">{getmenu.event?.city}</span>
                   <div className=""style={{marginTop:"px"}}><span className="event-date ">Published by {getmenu.event?.hostName}</span></div>

                    </div>
                  </div>
                  <div className="row margintop1">
                    <div className="margintop1">
                   {/* <div className=""style={{marginTop:"-3px"}}><span className="event-date ">Published by {getmenu.event?.eventType}</span></div>  */}
                   <span className="post-host-Name" style={{color:"#626262",marginleft:'7px'}}>Event-</span> <span className="post-host-Name">{getmenu.event?.eventType}</span>
                  </div>
                  </div>

                  <div className={getmenu.event?.imagesCount == 4 ? "" : "hide"}>
                                     <div className="row margintop3">
                                       {
                                         getmenu.event?.images?.map((gname, index) => (
                                           <div className="col-6">
                                             <img src={gname} className='' style={{ width: "326px", height: "100%" }} />

                                           </div>
                                         ))
                                       }
                                       {/* <div className="col-6">
                             <img src={eventimg3}  className=''style={{  width:"333px",height:"100%"}}/>

                             </div> */}

                                     </div>
                                   </div>
                                   <div className={getmenu.event?.imagesCount == 2 ? "" : "hide"}>

                                         <div className="row margintop">
                                         {
                                       getmenu.event?.images?.map((gname, index) => (
                                           <div className="col-lg-6 col-sm-12 col-md-12 margintop1">
                                             <img src={gname} className='' style={{ width: "326px", height: "100%" }} />

                                           </div>
                  ))
                 }
                                           {/* <div className="col-6">
                             <img src={eventimg3}  className=''style={{  width:"333px",height:"100%"}}/>

                             </div> */}

                                         </div>

                                   </div>
                                   <div className={getmenu.event?.imagesCount == 1 ? "" : "hide"}>

                                         <div className="row margintop">
                                         {
                                       getmenu.event?.images?.map((gname, index) => (
                                           <div className="col-12 col-md-12 col-sm-12 margintop1">
                                             <img src={gname} className='' style={{ width: "100%", height: "90%" }} />

                                           </div>
                                            ))
                                           }

                                           {/* <div className="col-6">
                             <img src={eventimg3}  className=''style={{  width:"333px",height:"100%"}}/>

                             </div> */}

                                         </div>

                                   </div>
                                  <div className={getmenu.event?.imagesCount == 3 ? "" : "hide"}>

                                     <div className="row margintop">
                                     {getmenu.event?.images?.map((gname, index) => (
                                     <div
                         className={`margintop1 ${index < 2 ? "col-6" : "col-12"}`}
                         key={index}
                       >
                         <img src={gname} className="" style={{ width: "100%", height: "100%" }} />
                       </div>
                 ))}



                                     </div>

                                   </div>
                                   <div className={getmenu.event?.imagesCount == 5 ? "" : "hide"}>

                 <div className="row margintop">
                 {getmenu.event?.images?.map((gname, index) => (
                   <div
                         className={`margintop1 ${
                           index < 3 ? "col-4" : "col-6"
                         }`}
                         key={index}
                       >
                         <img src={gname} className="" style={{ width: "100%", height: "auto" }} />
                       </div>
                 ))}



                 </div>

                 </div>

                  <div className="row">
                    <div className=""><span className="post-host-Name ">{getmenu.event?.hostName}</span></div>
                    <div className=""style={{marginTop:"2px"}}><span className="event-date ">{getmenu.event?.address} </span></div>
                    <div className=""style={{marginTop:"-3px"}}><span className="event-date "> {getmenu.event?.city}, {getmenu.event?.state}</span></div>
                    <div className="margintop1"><span className="post-host-Name " style={{fontSize:'12px'}}>{getmenu.event?.eventDate}</span> <span className="post-host-Name "style={{fontSize:'12px',marginLeft:"15px"}}>{getmenu.event?.eventTime}</span></div>
                  </div>
                  <div className="row margintop3">
                    <div  className=""><div className="borderBottombtn" style={{marginLeft:"0px"}}></div></div>
                  </div>
                  <div className="row margintop3">
                                      <div className="col-lg-2 col-sm-4 col-md-6">
                                        {/* <BiLike className={status==='like'? 'activelike' : ''} onClick={handleClickLike}/><span className="likespn marginleft2"> {likes}</span> */}
                                        {getmenu.event?.likeStatus == true ? <AiFillLike color="#626262" size="1.3em" onClick={() => addLikes(getmenu.event?.id)} style={{ cursor: "pointer" }} /> : <BiLike onClick={() => addLikes(getmenu.event?.id)} size="1.3em" style={{ cursor: "pointer" }} color="#D9D9D9" />}<span className="post-name-btn " style={{color:"#626262",fontSize:'14px',marginLeft:"4px",cursor:"pointer"}} onClick={() => loadLikesInfo(getmenu.event?.id)}> {getmenu.event?.likeCount} </span>
                                      </div>
                                      <div className="col-lg-2 col-md-4 col-sm-4" onClick={() => commentInfo(getmenu.event?.id)} style={{ cursor: "pointer" }}><FaRegComments size="1.3em" color="#626262" /><span className="post-name-btn " style={{color:"#626262",fontSize:'14px',marginLeft:"6px"}}>{getmenu.event?.commentCount} </span></div>
                                      <div className="col-lg-7"></div>

                                      {getmenu.event?.userSpamStatus == false ?
                                        (
                                          <div className="col-lg-1 col-sm-5 col-md-4 " onClick={() => addSpam(getmenu.event?.id)} style={{ cursor: "pointer" }}><RiSpam2Line color="#D9D9D9" size="1.3em"/> </div>

                                        ) : (
                                          <div className="col-1 " onClick={() => addSpam(getmenu.event?.id)} style={{ cursor: "pointer" }}><RiSpam2Line color="#626262" size="1.3em"/> </div>

                                        )
                                      }

                                    </div>
                                    <Card className={comment && getmenu.event?.id == cmntEventId ? "margintop3" : "hide row"} >
                                      <div className="">
                                        <div className="" onClick={() => Commentpoupopen(false)} style={{float:"right",marginRight:"10px",cursor:"pointer",fontSize:'13px'}}>X</div>
                                        <Card className="overflow-hidden" style={{ height: "auto", border: "none" }}>
                                          {/* <Card className="mb-lg-0" style={{ height: "auto", border: "none" }}> */}

                                            <Card.Body>
                                              <div className="mt-2">
                                                <FormGroup className="form-group " >
                                                  <Form.Control
                                                    type="text"
                                                    className="form-control borderadius25px "
                                                    id="name1"
                                                    name="txtCmnt"
                                                    placeholder="Add a Comment"
                                                    style={{ fontSize: "13px", borderRadius: "25px" }}
                                                    onChange={handleChange}
                                                    onKeyPress={handleKeypress}
                                                    value={cmntValue.txtCmnt}
                                                  />
                                                  <label className={commentValiderr ? "event-sub-detail1" : "hide"} style={{ color: "red" }}>Please Add Comment</label>

                                                </FormGroup>

                                                <button className="attendbtn" style={{ width: "90px", height: "28px" }} onClick={() => saveComent(getmenu.event?.id)}>
                                                  Send Reply
                                                </button>
                                                {/* <span onClick={() => Commentpoupopen(false)}>close</span> */}
                                              </div>
                                            </Card.Body>
                                          {/* </Card> */}
                                        </Card>
                                      </div>
                                      {
  commentData.map((commentmenu, index) => {
    // Extract first letter of first name and first letter of last name
    const nameParts = commentmenu.viewerName ? commentmenu.viewerName.split(" ") : [];
    const initials =
      nameParts.length > 1
        ? `${nameParts[0][0].toUpperCase()}${nameParts[nameParts.length - 1][0].toUpperCase()}`
        : nameParts[0]
        ? nameParts[0][0].toUpperCase()
        : "";

    return (
      <div key={index}>
        <Card className="" style={{ height: "auto", border: "none" }}>
          <Card.Body>
            <div className="d-sm-flex mt-0 p-3 sub-review-section border br-bl-0 br-br-0">
              <div className="media-body">
                {/* Flex container to keep avatar and name in one line */}
                <h6
                  className="mt-0 mb-1 font-12 font-weight-semibold"
                  style={{
                    fontSize: "13px",
                    display:"inline-flex",
                  }}
                >
                  {/* Profile Picture or Avatar */}
                  {commentmenu.profilePicture ? (
                    <img
                      src={commentmenu.profilePicture}
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        marginRight:"6px",
                        marginTop:"-6px"
                      }}
                    />
                  ) : (
                    <Avatar
                      style={{
                        width: "30px",
                        height: "30px",
                        fontSize: "12px",
                        backgroundColor: bgColor,
                        color: "#ffffff",
                        marginTop:"-6px",
                        marginRight:"6px"
                      }}
                    >
                      {initials}
                    </Avatar>
                  )}

                  {/* Viewer Name */}
                  {commentmenu.viewerName}
                </h6>

                {/* Comment Text */}
                <p className="font-11 mb-2 mt-2" style={{ fontSize: "12px" }}>
                  {commentmenu.comment}
                </p>

                {/* Delete Badge */}
                <Badge
                  bg=""
                  className={commentmenu.viewerId == uerAuthId ? "bg-danger" : "hide"}
                  onClick={() => deleteComment(commentmenu.id)}
                  style={{ cursor: "pointer" }}
                >
                  Delete
                </Badge>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  })
}

                                    </Card>
            </div>
            <div className={getmenu.blog ? "radius-div-news margintop3" : "hide"}>
            <div className="row margintop3">
                    <div className="col-lg-1 col-sm-12 col-md-12">
                        {getmenu.blog?.profilePic == "" || getmenu.blog?.profilePic == null ?
                        (<Avatar className="avtarimgsm" style={{ width: "50px",marginTop:"px", height: "49px",backgroundColor: bgColor,color:"#ffffff" }}>{getmenu.blog?.hostName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.blog?.profilePic} id='profpicdisablesave5' className='prof-img-right postprofsmimg eventlogoimg1' />)
                      }
                    </div>
                    <div className="col-lg-5 col-sm-12 col-md-12 textleft" style={{marginTop:"7px"}}>
                      <span className="post-host-Name ">{getmenu.blog?.authorName}</span>
                      <div className=""style={{marginTop:"px"}}><span className="event-date">{getmenu.blog?.scheduledDate} @{getmenu.blog?.scheduledTime}</span></div>
                      </div>
                    {/* <div className="col-lg-2 col-sm-12" style={{ whiteSpace: "nowrap",marginTop:"px" }}>
                      </div> */}
                    <div className="col-lg-6 col-sm-12 col-md-12 textright"style={{marginTop:"7px"}}><span className="post-host-Name"></span>
                   <div className=""style={{marginTop:"px"}}><span className="event-date ">Published by {getmenu.blog?.authorName}</span></div>

                    </div>
                  </div>
                  <div className="row margintop1">
                    <div className="margintop1">
                   {/* <div className=""style={{marginTop:"-3px"}}><span className="event-date ">Published by {getmenu.blog?.eventType}</span></div>  */}
                   <span className="post-host-Name" style={{color:"#626262",marginleft:'7px'}}>Blog-</span> <span className="post-host-Name">{getmenu.blog?.blogTitle}</span>
                  </div>
                  </div>
                  <div className="row margintop3">
                    <img src={getmenu.blog?.bannerImagePath} className='blogimg' />
                    <div ><span className="event-date"style={{float:"left"}}>Author:{getmenu.blog?.authorName}</span><span className="event-date" style={{float:"right"}}>Image Source - {getmenu.blog?.imageSource}</span></div>

                    
                    <div className="blog-textdiv margintop3">
                    <span className="event-date">
                    <span className={`contentblog ${expandedblog ? "expandedblog" : "collapsedblog"}`} style={{textTransform:"capitalize"}}>
        {getmenu.blog?.blogContent}
      </span>
  </span>
  
                      <div className="textright">
                      {getmenu.blog?.blogContent.length > 200 && ( // Show button only if content is long
        <div className="textright">
          <span className="read-more-blog" onClick={() => setExpandedblog(!expandedblog)} style={{cursor:"pointer"}}>
            {expandedblog ? "Read Less" : "Read More"}
          </span>
        </div>
      )}
                        </div>

                    </div>
                  </div>
                  <div className="row margintop3">
                    <div  className=""><div className="borderBottombtn" style={{marginLeft:"0px"}}></div></div>
                  </div>
                  <div className="row margintop3">
                                      <div className="col-lg-2 col-sm-4 col-md-6">
                                        {/* <BiLike className={status==='like'? 'activelike' : ''} onClick={handleClickLike}/><span className="likespn marginleft2"> {likes}</span> */}
                                        {getmenu.blog?.likeStatus == true ? <AiFillLike color="#626262" size="1.3em" onClick={() => addLikes(getmenu.blog?.id)} style={{ cursor: "pointer" }} /> : <BiLike onClick={() => addLikes(getmenu.blog?.id)} size="1.3em" style={{ cursor: "pointer" }} color="#D9D9D9" />}<span className="post-name-btn " style={{color:"#626262",fontSize:'14px',marginLeft:"4px",cursor:"pointer"}} onClick={() => loadLikesInfo(getmenu.blog?.id)}> {getmenu.blog?.likeCount} </span>
                                      </div>
                                      <div className="col-lg-2 col-md-4 col-sm-4" onClick={() => commentInfo(getmenu.blog?.id)} style={{ cursor: "pointer" }}><FaRegComments size="1.3em" color="#626262" /><span className="post-name-btn " style={{color:"#626262",fontSize:'14px',marginLeft:"6px"}}>{getmenu.blog?.commentCount} </span></div>
                                      <div className="col-lg-7"></div>

                                      {getmenu.blog?.userSpamStatus == false ?
                                        (
                                          <div className="col-lg-1 col-sm-5 col-md-4 " onClick={() => addSpam(getmenu.blog?.id)} style={{ cursor: "pointer" }}><RiSpam2Line color="#D9D9D9" size="1.3em"/> </div>

                                        ) : (
                                          <div className="col-1 " onClick={() => addSpam(getmenu.blog?.id)} style={{ cursor: "pointer" }}><RiSpam2Line color="#626262" size="1.3em"/> </div>

                                        )
                                      }

                                    </div>
                                    <Card className={comment && getmenu.blog?.id == cmntEventId ? "margintop3" : "hide row"} >
                                      <div className="">
                                        <div className="" onClick={() => Commentpoupopen(false)} style={{float:"right",marginRight:"10px",cursor:"pointer",fontSize:'13px'}}>X</div>
                                        <Card className="overflow-hidden" style={{ height: "auto", border: "none" }}>
                                          {/* <Card className="mb-lg-0" style={{ height: "auto", border: "none" }}> */}

                                            <Card.Body>
                                              <div className="mt-2">
                                                <FormGroup className="form-group " >
                                                  <Form.Control
                                                    type="text"
                                                    className="form-control borderadius25px "
                                                    id="name1"
                                                    name="txtCmnt"
                                                    placeholder="Add a Comment"
                                                    style={{ fontSize: "13px", borderRadius: "25px" }}
                                                    onChange={handleChange}
                                                    onKeyPress={handleKeypress}
                                                    value={cmntValue.txtCmnt}
                                                  />
                                                  <label className={commentValiderr ? "event-sub-detail1" : "hide"} style={{ color: "red" }}>Please Add Comment</label>

                                                </FormGroup>

                                                <button className="attendbtn" style={{ width: "90px", height: "28px" }} onClick={() => saveComent(getmenu.blog?.id)}>
                                                  Send Reply
                                                </button>
                                                {/* <span onClick={() => Commentpoupopen(false)}>close</span> */}
                                              </div>
                                            </Card.Body>
                                          {/* </Card> */}
                                        </Card>
                                      </div>
                                      {
  commentData.map((commentmenu, index) => {
    // Extract first letter of first name and first letter of last name
    const nameParts = commentmenu.viewerName ? commentmenu.viewerName.split(" ") : [];
    const initials =
      nameParts.length > 1
        ? `${nameParts[0][0].toUpperCase()}${nameParts[nameParts.length - 1][0].toUpperCase()}`
        : nameParts[0]
        ? nameParts[0][0].toUpperCase()
        : "";

    return (
      <div key={index}>
        <Card className="" style={{ height: "auto", border: "none" }}>
          <Card.Body>
            <div className="d-sm-flex mt-0 p-3 sub-review-section border br-bl-0 br-br-0">
              <div className="media-body">
                {/* Flex container to keep avatar and name in one line */}
                <h6
                  className="mt-0 mb-1 font-12 font-weight-semibold"
                  style={{
                    fontSize: "13px",
                    display:"inline-flex",
                  }}
                >
                  {/* Profile Picture or Avatar */}
                  {commentmenu.profilePicture ? (
                    <img
                      src={commentmenu.profilePicture}
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        marginRight:"6px",
                        marginTop:"-6px"
                      }}
                    />
                  ) : (
                    <Avatar
                      style={{
                        width: "30px",
                        height: "30px",
                        fontSize: "12px",
                        backgroundColor: bgColor,
                        color: "#ffffff",
                        marginTop:"-6px",
                        marginRight:"6px"
                      }}
                    >
                      {initials}
                    </Avatar>
                  )}

                  {/* Viewer Name */}
                  {commentmenu.viewerName}
                </h6>

                {/* Comment Text */}
                <p className="font-11 mb-2 mt-2" style={{ fontSize: "12px" }}>
                  {commentmenu.comment}
                </p>

                {/* Delete Badge */}
                <Badge
                  bg=""
                  className={commentmenu.viewerId == uerAuthId ? "bg-danger" : "hide"}
                  onClick={() => deleteComment(commentmenu.id)}
                  style={{ cursor: "pointer" }}
                >
                  Delete
                </Badge>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  })
}


                                    </Card>





            </div>

            <div className={getmenu.kyc ? "radius-div-news margintop3" : "hide"}>
            <div className="row margintop1">
                    <div className="col-lg-1 col-sm-12 col-md-12">
                        {getmenu.kyc?.bannerImagePath == "" || getmenu.kyc?.bannerImagePath == null ?
                        (<Avatar className="avtarimgsm" style={{ width: "50px",marginTop:"px", height: "49px",backgroundColor: bgColor,color:"#ffffff" }}>{getmenu.kyc?.authorName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.kyc?.bannerImagePath} id='profpicdisablesave5' className='prof-img-right postprofsmimg eventlogoimg1' />)
                      }
                    </div>
                    <div className="col-lg-5 col-sm-12 col-md-12 textleft" style={{marginTop:"7px"}}>
                      <span className="post-host-Name ">{getmenu.kyc?.authorName}</span>
                      <div className=""style={{marginTop:"px"}}><span className="event-date">{getmenu.kyc?.createdDate} @{getmenu.kyc?.creationTime}</span></div>
                      </div>
                    {/* <div className="col-lg-2 col-sm-12" style={{ whiteSpace: "nowrap",marginTop:"px" }}>
                      </div> */}
                    <div className="col-lg-6 col-sm-12 col-md-12 textright"style={{marginTop:"7px"}}><span className="post-host-Name"></span>
                   <div className=""style={{marginTop:"px"}}><span className="event-date ">Published by {getmenu.kyc?.authorName}</span></div>

                    </div>
                  </div>
                  <div className="row margintop1">
                    <div className="margintop1">
                   {/* <div className=""style={{marginTop:"-3px"}}><span className="event-date ">Published by {getmenu.blog?.eventType}</span></div>  */}
                   <span className="post-host-Name" style={{color:"#626262",marginleft:'7px'}}>Know Your Community-</span>
                   <span className="post-host-Name">{getmenu.kyc?.kycPerson} </span> <span className="post-host-Name">{getmenu.kyc?.from}-{getmenu.kyc?.to} </span>
                  </div>
                  </div>
                  <div className="row margintop3">
                    <img src={getmenu.kyc?.bannerImagePath} className='blogimg' />
                    <div ><span className="event-date"style={{float:"left"}}>Author:{getmenu.kyc?.authorName}</span><span className="event-date" style={{float:"right"}}>Image Source - {getmenu.kyc?.imageSource}</span></div>

                    <div className="blog-textdiv margintop3">
                    <span className="event-date">
                    <span className={`contentkyc ${expanded ? "expanded" : "collapsed"}`} style={{textTransform:"capitalize"}}> 
        {getmenu.kyc?.kycContent}
      </span>
  </span>
  
                      <div className="textright">
                      {getmenu.kyc?.kycContent.length > 100 && ( // Show button only if content is long
        <div className="textright">
          <span className="read-more-blog" onClick={() => setExpanded(!expanded)} style={{cursor:"pointer"}}>
            {expanded ? "Read Less" : "Read More"}
          </span>
        </div>
      )}
                        </div>

                    </div>
                  </div>
                  <div className="row margintop3">
                    <div  className=""><div className="borderBottombtn" style={{marginLeft:"0px"}}></div></div>
                  </div>
                  <div className="row margintop3">
                                      <div className="col-lg-2 col-sm-4 col-md-6">
                                        {/* <BiLike className={status==='like'? 'activelike' : ''} onClick={handleClickLike}/><span className="likespn marginleft2"> {likes}</span> */}
                                        {getmenu.kyc?.likeStatus == true ? <AiFillLike color="#626262" size="1.3em" onClick={() => addLikes(getmenu.kyc?.id)} style={{ cursor: "pointer" }} /> : <BiLike onClick={() => addLikes(getmenu.kyc?.id)} size="1.3em" style={{ cursor: "pointer" }} color="#D9D9D9" />}<span className="post-name-btn " style={{color:"#626262",fontSize:'14px',marginLeft:"4px",cursor:"pointer"}} onClick={() => loadLikesInfo(getmenu.kyc?.id)}> {getmenu.kyc?.likeCount} </span>
                                      </div>
                                      <div className="col-lg-2 col-md-4 col-sm-4" onClick={() => commentInfo(getmenu.kyc?.id)} style={{ cursor: "pointer" }}><FaRegComments size="1.3em" color="#626262" /><span className="post-name-btn " style={{color:"#626262",fontSize:'14px',marginLeft:"6px"}}>{getmenu.kyc?.commentCount} </span></div>
                                      <div className="col-lg-7"></div>

                                      {getmenu.kyc?.userSpamStatus == false ?
                                        (
                                          <div className="col-lg-1 col-sm-5 col-md-4 " onClick={() => addSpam(getmenu.kyc?.id)} style={{ cursor: "pointer" }}><RiSpam2Line color="#D9D9D9" size="1.3em"/> </div>

                                        ) : (
                                          <div className="col-1 " onClick={() => addSpam(getmenu.kyc?.id)} style={{ cursor: "pointer" }}><RiSpam2Line color="#626262" size="1.3em"/> </div>

                                        )
                                      }

                                    </div>
                                    <Card className={comment && getmenu.kyc?.id == cmntEventId ? "margintop3" : "hide row"} >
                                      <div className="">
                                        <div className="" onClick={() => Commentpoupopen(false)} style={{float:"right",marginRight:"10px",cursor:"pointer",fontSize:'13px'}}>X</div>
                                        <Card className="overflow-hidden" style={{ height: "auto", border: "none" }}>
                                          {/* <Card className="mb-lg-0" style={{ height: "auto", border: "none" }}> */}

                                            <Card.Body>
                                              <div className="mt-2">
                                                <FormGroup className="form-group " >
                                                  <Form.Control
                                                    type="text"
                                                    className="form-control borderadius25px "
                                                    id="name1"
                                                    name="txtCmnt"
                                                    placeholder="Add a Comment"
                                                    style={{ fontSize: "13px", borderRadius: "25px" }}
                                                    onChange={handleChange}
                                                    onKeyPress={handleKeypress}
                                                    value={cmntValue.txtCmnt}
                                                  />
                                                  <label className={commentValiderr ? "event-sub-detail1" : "hide"} style={{ color: "red" }}>Please Add Comment</label>

                                                </FormGroup>

                                                <button className="attendbtn" style={{ width: "90px", height: "28px" }} onClick={() => saveComent(getmenu.kyc?.id)}>
                                                  Send Reply
                                                </button>
                                                {/* <span onClick={() => Commentpoupopen(false)}>close</span> */}
                                              </div>
                                            </Card.Body>
                                          {/* </Card> */}
                                        </Card>
                                      </div>
                                      {
  commentData.map((commentmenu, index) => {
    // Extract first letter of first name and first letter of last name
    const nameParts = commentmenu.viewerName ? commentmenu.viewerName.split(" ") : [];
    const initials =
      nameParts.length > 1
        ? `${nameParts[0][0].toUpperCase()}${nameParts[nameParts.length - 1][0].toUpperCase()}`
        : nameParts[0]
        ? nameParts[0][0].toUpperCase()
        : "";

    return (
      <div key={index}>
        <Card className="" style={{ height: "auto", border: "none" }}>
          <Card.Body>
            <div className="d-sm-flex mt-0 p-3 sub-review-section border br-bl-0 br-br-0">
              <div className="media-body">
                {/* Flex container to keep avatar and name in one line */}
                <h6
                  className="mt-0 mb-1 font-12 font-weight-semibold"
                  style={{
                    fontSize: "13px",
                    display:"inline-flex",
                  }}
                >
                  {/* Profile Picture or Avatar */}
                  {commentmenu.profilePicture ? (
                    <img
                      src={commentmenu.profilePicture}
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        marginRight:"6px",
                        marginTop:"-6px"
                      }}
                    />
                  ) : (
                    <Avatar
                      style={{
                        width: "30px",
                        height: "30px",
                        fontSize: "12px",
                        backgroundColor: bgColor,
                        color: "#ffffff",
                        marginTop:"-6px",
                        marginRight:"6px"
                      }}
                    >
                      {initials}
                    </Avatar>
                  )}

                  {/* Viewer Name */}
                  {commentmenu.viewerName}
                </h6>

                {/* Comment Text */}
                <p className="font-11 mb-2 mt-2" style={{ fontSize: "12px" }}>
                  {commentmenu.comment}
                </p>

                {/* Delete Badge */}
                <Badge
                  bg=""
                  className={commentmenu.viewerId == uerAuthId ? "bg-danger" : "hide"}
                  onClick={() => deleteComment(commentmenu.id)}
                  style={{ cursor: "pointer" }}
                >
                  Delete
                </Badge>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  })
}

                                    </Card>



            </div>
            <div className={getmenu.job ? "radius-div-news margintop " : "hide"}>
  <div className="" style={{paddingTop:"18px",paddingBottom:"16px"}}>
  <div className="row">
    <div className="col-1">
      {/* <img src={getmenu.job?.profilePic} id='profpicdisablesave5' className='eventlogoimg' /> */}
      {getmenu.job?.profilePic == "" || getmenu.job?.profilePic == null ?
                        (<Avatar className="avtarimgSm" style={{ width: "44px", height: "42px",backgroundColor: bgColor,color:"#ffffff" }}>{getmenu.job?.postedByName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.job?.profilePic} id='profpicdisablesave5' className='prof-img-right postprofsmimg eventlogoimg1' />)
                      }
      </div>
    <div className="col-11">
      <div className=""><span className="evntxt ">Open for Opportunities</span>
      <span className=" event-type-text marginleft2" key={getmenu.job?.id}>{getmenu.job?.jobOpeningFor}</span>
      <span className="marginleft2 event-location">{getmenu.job?.currentLocation}</span>

       </div>
       <div className="">
       <span className="event-date">{getmenu.job?.createdDate} at {getmenu.job?.createdTime}</span>
       <span className="event-publisher ">Published by {getmenu.job?.postedByName}</span>
       </div>

    </div>

  </div>
  <div className="row mt-1">
    <div className="col-6">
      <div className="row mt-2">
      <div className="col-7"><span className="post-name-btn textleft" style={{marginLeft:'0px',color:"#555555"}}>Community Member</span></div>
      <div className="col-5"><span className=" whitenowrap post-host-Name textleft" style={{fontSize:"12px", marginTop:"px"}}>{getmenu.job?.postedByName} </span></div>
</div>
<div className="row mt-2">
      <div className="col-7"><span className="post-name-btn textleft" style={{marginLeft:'0px',color:"#555555"}}>Job Title</span></div>
      <div className="col-5"><span className=" whitenowrap post-host-Name textleft" style={{fontSize:"12px", marginTop:"px"}}>{getmenu.job?.jobOpeningFor}</span></div>
</div>
<div className="row mt-2">
      <div className="col-7"><span className="post-name-btn textleft" style={{marginLeft:'0px',color:"#555555"}}>Total Experience</span></div>
      <div className="col-5"><span className=" whitenowrap post-host-Name textleft" style={{fontSize:"12px", marginTop:"px"}}>{getmenu.job?.experience}</span></div>
</div>
<div className="row mt-2">
      <div className="col-7"><span className="post-name-btn textleft" style={{marginLeft:'0px',color:"#555555"}}>Current Location</span></div>
      <div className="col-5"><span className=" whitenowrap post-host-Name textleft" style={{fontSize:"12px", marginTop:"px"}}>{getmenu.job?.currentLocation}</span></div>
</div>
<div className="row mt-2">
      <div className="col-7"><span className="post-name-btn textleft" style={{marginLeft:'0px',color:"#555555"}}>Home Town</span></div>
      <div className="col-5"><span className=" whitenowrap post-host-Name textleft" style={{fontSize:"12px", marginTop:"px"}}>{getmenu.job?.homeLocation}</span></div>
</div>
    </div>
    <div className="col-6">
    <div className="row mt-2">
      <div className="col-6"><span className="post-name-btn textleft"style={{marginLeft:"0px"}}>Resume</span></div>
      <div className="col-6">{
        getmenu.job?.resumePath=="" || getmenu.job?.resumePath==null?
        (<span className='post-name-btn textleft' style={{color:"#8B0000",marginLeft:"0px"}}>Not Uploaded</span>):(  <a href={`//${getmenu.job?.resumePath}`} target='_blank'><AiOutlineFilePdf size='1.9em' color='#8B0000'/></a>
        )
      }
        </div>
</div>
<div className="row mt-2">
      <div className="col-6"><span className="post-name-btn textleft" style={{marginLeft:'0px',color:"#555555"}}>Available for job in</span></div>
      <div className="col-6"><span className=" whitenowrap post-host-Name textleft" style={{fontSize:"12px", marginTop:"px"}}>{getmenu.job?.availableIn}</span></div>
</div>
<div className="row mt-2">
      <div className="col-6"><span className="post-name-btn textleft" style={{marginLeft:'0px',color:"#555555"}}>Email Id</span></div>
      <div className="col-6"><span className=" whitenowrap post-host-Name textleft" style={{fontSize:"12px", marginTop:"px"}}>{getmenu.job?.emailId}</span></div>
</div>
<div className="row mt-2">
      <div className="col-6"><span className="post-name-btn textleft" style={{marginLeft:'0px',color:"#555555"}}>Mobile No.</span></div>
      <div className="col-6"><span className=" whitenowrap post-host-Name textleft" style={{fontSize:"12px", marginTop:"px"}}>{getmenu.job?.mobileNumber}</span></div>
</div>

    </div>
      </div>




  <div className="row margintop3">
                    <div  className=""><div className="borderBottombtn" style={{marginLeft:"0px"}}></div></div>
                  </div>
  <div className="row margintop3">
                                      <div className="col-lg-2 col-sm-4 col-md-6">
                                        {/* <BiLike className={status==='like'? 'activelike' : ''} onClick={handleClickLike}/><span className="likespn marginleft2"> {likes}</span> */}
                                        {getmenu.job?.likeStatus == true ? <AiFillLike color="#626262" size="1.3em" onClick={() => addLikes(getmenu.job?.id)} style={{ cursor: "pointer" }} /> : <BiLike onClick={() => addLikes(getmenu.job?.id)} size="1.3em" style={{ cursor: "pointer" }} color="#D9D9D9" />}<span className="post-name-btn " style={{color:"#626262",fontSize:'14px',marginLeft:"4px",cursor:"pointer"}} onClick={() => loadLikesInfo(getmenu.kyc?.id)}> {getmenu.job?.likeCount} </span>
                                      </div>
                                      <div className="col-lg-2 col-md-4 col-sm-4" onClick={() => commentInfo(getmenu.job?.id)} style={{ cursor: "pointer" }}><FaRegComments size="1.3em" color="#626262" /><span className="post-name-btn " style={{color:"#626262",fontSize:'14px',marginLeft:"6px"}}>{getmenu.job?.commentCount} </span></div>
                                      <div className="col-lg-7"></div>

                                      {getmenu.kyc?.userSpamStatus == false ?
                                        (
                                          <div className="col-lg-1 col-sm-5 col-md-4 " onClick={() => addSpam(getmenu.job?.id)} style={{ cursor: "pointer" }}><RiSpam2Line color="#D9D9D9" size="1.3em"/> </div>

                                        ) : (
                                          <div className="col-1 " onClick={() => addSpam(getmenu.job?.id)} style={{ cursor: "pointer" }}><RiSpam2Line color="#626262" size="1.3em"/> </div>

                                        )
                                      }

                                    </div>
          <Card className={comment && getmenu.job?.id == cmntEventId ? "margintop3":"hide row"}>
          <div className="">
          <div className="" onClick={() => Commentpoupopen(false)} style={{float:"right",marginRight:"10px",cursor:"pointer",fontSize:'13px'}}>X</div>

            <Card className="overflow-hidden" style={{height:"auto",border:"none"}}>
              {/* <Card className="mb-lg-0" style={{height:"auto",border:"none"}}> */}

                <Card.Body>
                  <div className="mt-2">
                    <FormGroup className="form-group " >
                      <Form.Control
                        type="text"
                        className="form-control borderadius25px "
                        id="name1"
                        name="txtCmnt"
                        placeholder="Add a Comment"
                        style={{fontSize:"13px",borderRadius:"25px",textTransform:"capitalize"}}
                        onChange={handleChange}
                        value={cmntValue.txtCmnt}
                      />
                    <label className={commentValiderr?"event-sub-detail1":"hide"} style={{color:"red"}}>Please Add Comment</label>

                    </FormGroup>
                    <button  className="attendbtn" style={{width:"90px",height:"28px"}} onClick={()=>saveComent(getmenu.job?.id)}>
                      Send Reply
                    </button>
                  </div>
                </Card.Body>
              {/* </Card> */}
            </Card>
          </div>
          {
 commentData.map((commentmenu,index)=>(
<div className="">
            <Card className="" style={{height:"auto",border:"none"}}>
              {/* <Card style={{height:"auto"}}> */}

                <Card.Body>

                  <div className="d-sm-flex mt-0 p-3 sub-review-section border br-bl-0 br-br-0">

                    <div className="media-body">
                        <h6 className="mt-0 mb-1 font-12 font-weight-semibold" style={{fontSize:"13px"}}>
                          {commentmenu.viewerName}

                        </h6>
                      <p className="font-11  mb-2 mt-2" style={{fontSize:"12px"}}>
                      {commentmenu.comment}

                      </p>

                        <Badge bg="" className={commentmenu.viewerId==uerAuthId?" bg-danger":"hide"} onClick={()=>deleteComment(commentmenu.id)}>
                          Delete
                        </Badge>

                    </div>
                  </div>


                </Card.Body>
              {/* </Card> */}
            </Card>
          </div>
  ))

}

    </Card>
</div>
</div>
<div className={getmenu.coffeeMeet?" ":"hide"}>
          <div className={getmenu.coffeeMeet?.meetStatus == "upcoming" ? "radius-div-news margintop3 " : "radius-div-news margintop3  bacolorgrya"}style={{paddingBottom:"2px"}}>
                       <div className="row mt-2">

  <div className="col-1">

  {getmenu.coffeeMeet?.bannerImagePath == "" || getmenu.coffeeMeet?.bannerImagePath == null ?
                        (<Avatar className="" style={{ width: "54px",marginTop:"3px", height: "52px",backgroundColor: bgColor,color:"#ffffff" }}>{getmenu.coffeeMeet?.hostName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.coffeeMeet?.bannerImagePath} id='profpicdisablesave1' className='prof-img-right postprofsmimg eventlogoimg1' />)
                      }
    </div>
    <div className="col-6" style={{marginTop:"-2px"}}><h6 className="card-title mb-1" style={{whiteSpace:'nowrap'}}>


<span className='coffeemeet-host-name'  key={getmenu.coffeeMeet?.id}>{getmenu.coffeeMeet?.hostName} - Host</span>

             </h6>
             <div style={{marginTop:"4px"}}>

             <span className="coffeemeet-host-name" style={{fontSize:"12px",color:"#797979"}}>
             Creaeted On - {getmenu.coffeeMeet?.createdDate} @{getmenu.coffeeMeet?.createdTime}
</span>
<span className="marginleft20px coffeemeet-host-name" style={{fontSize:"12px",color:"#797979"}}>
City - {getmenu.coffeeMeet?.city}
</span>
             </div>
             </div>
             <div className="col-2"></div>




</div>
<div className="row margintop3 ">
                          <div className="col-11 host-coffee-datetime-div" style={{marginLeft:"13px"}}>
                            <RiCupFill size="1.7em" color="#964B00"/>
                            <span className="coffeemeet-host-name" style={{fontSize:"12px",color:"#222227",fontWeight:"400",marginLeft:"5px"}}>Hosting Coffee Meet Up on <span style={{fontSize:"12px",color:"#222227",fontWeight:"600",marginLeft:"1px"}}>{getmenu.coffeeMeet?.date}</span> at <span style={{fontSize:"12px",color:"#222227",fontWeight:"600",marginLeft:"1px"}}>{getmenu.coffeeMeet?.venue} ,</span> starting at <span style={{fontSize:"12px",color:"#222227",fontWeight:"600",marginLeft:"1px"}}>{getmenu.coffeeMeet?.time}</span> </span>
                            {/* <span className="coffeemeet-host-name" style={{fontSize:"12px",color:"#797979"}}>{getmenu.date} </span> <span className="event-sub-detail"> At {getmenu.time} </span><span className="event-sub-detail" style={{ marginLeft: '12px',textTransform:"capitalize" }}>{getmenu.city}</span> */}
                          </div>

                        </div>






                        <div className="row margintop3">
                        <div className="col-6">
                        {
                        getmenu.coffeeMeet?.cancelStatus != "Cancelled"?
                          (<div className="">
                            <span className="coffeemeet-host-name" style={{color:"#797979",fontSize:"11px",fontWeight:"500"}}>{getmenu.coffeeMeet?.attendeesCount}/{getmenu.coffeeMeet?.memberCount} Members are attending this Coffee Meet Up</span>
                          </div>)
                          :(<div><span className="coffeemeet-host-name" style={{ margin: "5px", fontSize: '13px', fontStyle: "italic", color: "#E75480" }}> This meet up has been cancelled</span></div>)
                        }
                        </div>
                        <div className="col-1"></div>
                        <div className="col-4 textright" style={{float:"right"}}>
                            <a href={`/${getmenu.coffeeMeet?.googleMapsLink}`} target='_blank'><span className="coffeemeet-host-name cpointer" style={{color:"#626262",fontSize:"12px",fontWeight:"400"}}>Check on Google Maps</span></a>
                        </div>
                        </div>

                        <div className="row margintop">
                        <div className="col-1 maxwd12 textcenter">
                          {getmenu.coffeeMeet?.bannerImagePath == "" || getmenu.coffeeMeet?.bannerImagePath == null ?
                        (<Avatar className="borderstatshost" style={{ width: "65px",marginTop:"6px", height: "65px",backgroundColor: getRandomColor(),color:"#ffffff" }}>{getmenu.coffeeMeet?.hostName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.coffeeMeet?.bannerImagePath} id='profpicdisablesave1' className='prof-img-right topprofsmimg eventlogoimg1 borderstatshost' />)
                      }
                      <div className="tectcenter margintop1" style={{ height: '30px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}><span className="coffeemeet-host-name" style={{color:"#797979",fontSize:"11px",fontWeight:"400",textAlign:"center",marginTop:"3px"}}>{getmenu.coffeeMeet?.hostName}</span></div>
                      <div className=""style={{ textAlign: 'center', minHeight: '20px',marginTop:"12px" }}><span className="coffeemeet-host-name"style={{color:"#964B00",fontSize:"11px",fontWeight:"500"}}>Host</span></div>

                          </div>
                        {
                            getmenu.guests?.map((gname, index) => (
                              // <div className="col-2">
                              //   <img src={gname.profilePicture} id="mycoffeeprofid" className=' ' style={{ width: "100px", height: "100px", borderRadius: "0%", borderColor: "gray" }} />
                              //   <div><span className="event-sub-detail1" style={{ fontSize: '10px', marginLeft: '2px',textTransform:"capitalize" }}>{gname.name}</span></div>
                              // </div>
                              <div className="col-1 maxwd12">

                                         <span className={gname.status == 'Reject'?"":"hide"}><img src={gname.profilePicture} id="othercoffeeprofid" className='borderstatusspnrej' style={{ width: "75px", height: "73px", borderRadius: "50%",marginBottom: '5px' }} /></span>
                                         <span className={gname.status == 'Attend'?"":"hide"}><img src={gname.profilePicture} id="othercoffeeprofid" className='borderstatusspnatt' style={{ width: "75px", height: "73px", borderRadius: "50%",marginBottom: '5px'  }} /></span>
                                         <span className={gname.status == 'Pending'?"":"hide"}><img src={gname.profilePicture} id="othercoffeeprofid" className='borderstatusspnpen' style={{ width: "75px", height: "73px", borderRadius: "50%",marginBottom: '5px'  }} /></span>



                                {/* <span className="borderstatusspn"><img src={gname.profilePicture} id="othercoffeeprofid" className=' ' style={{ width: "100px", height: "100px", borderRadius: "50%" }} /></span> */}
                                {/* <div className="textcenter"><span className="event-sub-detail1" style={{ fontSize: '10px', marginLeft: '0px' }}>{gname.name}</span></div> */}
                                <div className="textcenter" style={{ height: '30px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>

    <span className="coffeemeet-host-name" style={{ fontSize: '12px', maxWidth: '100px', textAlign: 'center', whiteSpace: 'normal', lineHeight: '1.2', overflow: 'hidden',fontWeight:'400',color:'#797979' }}>{gname.name}</span>
    </div>
    <div className="textcenter" style={{ textAlign: 'center', minHeight: '20px' }}>
    <span className={gname.status === 'Reject' ? "coffeemeet-host-name" : "hide"} style={{ fontSize: '12px', color: "#E75480", whiteSpace: 'nowrap',marginLeft:"-1px" }}>{gname.status}</span>
    <span className={gname.status === 'Attend' ? "coffeemeet-host-name" : "hide"} style={{ fontSize: '12px', color: "#4CAF50", whiteSpace: 'nowrap',marginLeft:"-1px" }}>{gname.status}</span>
    <span className={gname.status === 'Pending' ? "coffeemeet-host-name" : "hide"} style={{ fontSize: '12px', color: "#FFC107", whiteSpace: 'nowrap',marginLeft:"-1px" }}>{gname.status}</span>
  </div>
                                  {/* <span className={gname.status == 'Reject'?"event-sub-detail":"hide"} style={{ fontSize: '10px', marginLeft: '0px',color:"#E75480" }}>{gname.status}</span>
                                  <span className={gname.status == 'Attend'?"event-sub-detail":"hide"} style={{ fontSize: '10px', marginLeft: '0px',color:"#4CAF50" }}>{gname.status}</span>
                                  <span className={gname.status == 'Pending'?"event-sub-detail":"hide"} style={{ fontSize: '10px', marginLeft: '0px',color:"#FFC107" }}>{gname.status}</span> */}

                              </div>
                            ))
                          }
                        </div>

                        <div className="row margintop3"></div>
                      </div>
                      </div>

              </div>

          ))

        }
        {
            loadPost && loadPost.length == 0 && (
              <div className='row margintop'>
                <div className='col-3'></div>
                <div className='col-5 margintop'> <span className='event-sub-detail'>Posts Are not Available</span>
                </div>

              </div>
            )
          }
        </div>
        </div>
        <div className={communityDirect ? "col-lg-6 col-sm-12 col-md-12 heightforAll3Div communitydiv" : "hide"}>
        <div className="row margintop2">
       <div className=" textleft margintop2">
         <span className="User-Prof-name ">Hi Sagar Naidu</span>
          </div>
          <div className="row minusleftmarg" style={{marginLeft:"px"}}>
          <div className="col-6 form-group has-search" style={{ marginLeft: '0px', backgroundColor: '#ffffff' }}>
          <span className="fa fa-search form-control-feedback"></span>
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            onChange={handleSearchForDirectChange}
            style={{
              fontSize: '12px',
              backgroundColor: '#ffffff',
              height: '30px',
            }}
          />
        </div>
        <div className="col-3 ">
                      <select id="inputState" name='filterDirect'
                        onChange={handleChangeDirectFilter}

                        className="form-control drpdowncmdirect" style={{backgroundColor:"#ffffff",height:"30px"}}>
                        <option selected value="All"> All</option>
                        <option value="country">Country</option>
                        <option value="state">State</option>
                        <option value="city">City</option>
                        <option value="profession">Profession</option>
                        <option value="blood group">Blood Group</option>
                        <option value="bookmark profile">Bookmark Profile</option>

                      </select>
                    </div>
                    <div className="col-3" style={{whiteSpace:"nowrap",display:"inline-flex"}}>
                      {
                        countryDiv == true && (
                          <div style={{whiteSpace:"nowrap",display:"inline-flex"}}>
                            <input type="text" className=" form-control" id="fst"
                            name="country"
                            placeholder="Enter Country"
                            style={{ fontSize: "12px",height:"32px" }}
                            onChange={handleChangeforFilterDirect}

                          />

                            <FaSearch onClick={filterCountry} size="1.3em" style={{marginLeft:"6px",marginTop:"6px",cursor:"pointer"}}/>
                          </div>
                        )
                      }
                      {
                        stateDiv == true && (
                          <div style={{whiteSpace:"nowrap",display:"inline-flex"}}>
                          <input type="text" className=" form-control" id="fst"
                            name="state"
                            placeholder="Enter State"
                            style={{ fontSize: "12px",height:"32px" }}
                            onChange={handleChangeforFilterState}

                          />
                         <FaSearch onClick={filterState} size="1.3em" style={{marginLeft:"6px",marginTop:"6px",cursor:"pointer"}}/>

                          </div>
                        )
                      }
                      {
                        cityDiv == true && (
                          <div style={{whiteSpace:"nowrap",display:"inline-flex"}}>
                          <input type="text" className=" form-control" id="fst"
                            name="city"
                            placeholder="Enter City"
                            style={{ fontSize: "12px",height:"32px" }}
                            onChange={handleChangeforFilterCity}

                          />
                          <FaSearch onClick={filterCity} size="1.3em" style={{marginLeft:"6px",marginTop:"6px",cursor:"pointer"}}/>

                          </div>
                        )
                      }
                      {
                        bloodGroupDIv == true && (
                          <div style={{whiteSpace:"nowrap",display:"inline-flex"}}>
                          <input type="text" className=" form-control" id="fst"
                            name="bloodGroup"
                            placeholder="Enter Blood Group"
                            style={{ fontSize: "12px",height:"32px" }}
                            onChange={handleChangeforFilterBlood}

                          />
                          <FaSearch onClick={filterBlood} size="1.3em" style={{marginLeft:"6px",marginTop:"6px",cursor:"pointer"}}/>
                      </div>
                        )
                      }
                      {
                        professionDiv == true && (
                          <div style={{whiteSpace:"nowrap",display:"inline-flex"}}>
                          <input type="text" className=" form-control" id="fst"
                            name="profession"
                            placeholder="Enter Profession"
                            style={{ fontSize: "12px",height:"32px" }}
                            onChange={handleChangeforFilterProfession}
                          />
                          <FaSearch onClick={filterProfession} size="1.3em" style={{marginLeft:"6px",marginTop:"6px",cursor:"pointer"}}/>

                          </div>
                        )
                      }

                    </div>
          </div>
          <div className="row">
          {
            commDirectory.map((getmenu, index) => (
          <div className= "radius-div-news margintop3" >
            <div className="row margintop2">
              <div className="col-lg-4 textleft" style={{borderRightColor:"#DEDEDE",borderRightStyle:'solid',borderRightWidth:'1px'}}>
              {loadProfile == "" || loadProfile == null ?
                        (<Avatar className="" style={{marginTop:"3px", width: "65px", height: "61px",fontSize:"15px",backgroundColor:bgColor,color:"#ffffff",marginLeft:"px" }}>{avtarProf}</Avatar>)
                        : (<img src={loadProfile} id='profpicdisablesave1' className='prof-img-right topprofMdimg' style={{marginTop:"16px"}} />)
                      }
                      <div className="margintop2"><span className="post-host-Name">{getmenu.fullName}</span></div>
                      <div className="margintop2"></div>
                    <img src={contactinfo} className=' communitydrect-img' />
                    <span className="marginleft4 fontroboto40012px cpointer" onClick={() => contactInfopoupopen( getmenu.id,true)} style={{color:"#4682DB",fontWeight:"600",fontStyle:'italic'}}>Contact Info </span>  <span className="comudrct-digitxt"></span>
<div className="margintop1"></div>
<div className="margintop">
  <span style={{backgroundColor:"#FF84270D",padding:"8px",paddingLeft:"14px",paddingRight:"14px"}}>{getmenu.bookmarkStatus == true ?
            <FaBookmark color='#626262' size="1.1em" onClick={() => addCommDirectoryBookmark(getmenu.id)} style={{ cursor: "pointer" }} /> : <BsBookmark size="1.1em" onClick={() => addCommDirectoryBookmark(getmenu.id)} style={{ cursor: "pointer" }} color="#555555" />}</span>
            <span style={{backgroundColor:"#FF84270D",padding:"8px",marginLeft:'16px',paddingLeft:"14px",paddingRight:"12px"}}><MdMessage color='#626262' size="1.4em" onClick={() => togglePopupMessageDirect(getmenu.id,getmenu.blockStatus)}/></span>
            {isOpenMessagePopDirect && <MessagePopupDirectory

handleClose={togglePopupMessageDirect} uerAuthId={uerAuthId} idforpop={idforpop}
userlist ={userlist} setuserlist={setuserlist} styleForMessgBlockstatusComm={styleForMessgBlockstatusComm}
/>}
            </div>
              </div>
              <div className="col-lg-4 margintop3">
                    <div className="row">
                      <div className="col-2" style={{marginTop:"8px"}}><FaRegAddressCard color="#626262" size="1.1em"/></div>
                      <div className="col-8">
                      <span className="post-name-btn fontsize-12px" style={{marginLeft:"0px",fontSize:"12px",color:"#626262"}}>CID</span>
                      <div className="" style={{marginTop:"-5px"}}><span className="post-name-btn fontsize-12px" style={{color:"#212529",marginLeft:"0px",fontSize:"12px"}}>{getmenu.id}</span></div>
                      </div>
                    </div>

                    <div className="row margintop2">
                      <div className="col-2" style={{marginTop:"8px"}}><MdBusinessCenter color="#626262" size="1.2em"/></div>
                      <div className="col-8">
                      <span className="post-name-btn fontsize-12px" style={{marginLeft:"0px",fontSize:"12px",color:"#626262"}}>Works at</span>
                      <div className="" style={{marginTop:"-5px"}}><span className="post-name-btn fontsize-12px" style={{color:"#212529",marginLeft:"0px",fontSize:"12px"}}>{getmenu.profession}</span></div>
                      </div>
                    </div>

                    <div className="row margintop2">
                      <div className="col-2" style={{marginTop:"8px"}}><MdWaterDrop color="#626262" size="1.2em"/></div>
                      <div className="col-8">
                      <span className="post-name-btn fontsize-12px" style={{marginLeft:"0px",fontSize:"12px",color:"#626262"}}>Blood Group</span>
                      <div className="" style={{marginTop:"-5px"}}><span className="post-name-btn fontsize-12px" style={{color:"#212529",marginLeft:"0px",fontSize:"12px"}}>{getmenu.bloodGroup}</span></div>
                      </div>
                    </div>

                    <div className="row margintop2">
                      <div className="col-2" style={{marginTop:"8px"}}><IoMdMan color="#626262" size="1.3em"/></div>
                      <div className="col-8">
                      <span className="post-name-btn fontsize-12px" style={{marginLeft:"0px",fontSize:"12px",color:"#626262"}}>Sub Caste</span>
                      <div className="" style={{marginTop:"-5px"}}><span className="post-name-btn fontsize-12px" style={{color:"#212529",marginLeft:"0px",fontSize:"12px"}}>{getmenu.subCaste}</span></div>
                      </div>
                    </div>

              </div>
              <div className="col-lg-4 margintop3">
              <div className="row">
                      <div className="col-2" style={{marginTop:"8px"}}><FaMapMarkerAlt color="#626262" size="1.1em" style={{borderLeftWidth:"2px",borderLeftStyle:"solid",borderLeftColor:"#626262", borderRadius:"20px",borderRightWidth:"2px",borderRightStyle:"dashed",borderRightColor:"#626262",padding:"3px"}}/></div>
                      <div className="col-8">
                      <span className="post-name-btn fontsize-12px" style={{marginLeft:"0px",fontSize:"12px",color:"#626262"}}>From</span>
                      <div className="" style={{marginTop:"-5px"}}><span className="post-name-btn fontsize-12px" style={{color:"#212529",marginLeft:"0px",fontSize:"12px"}}>{getmenu.homeCity}</span></div>
                      </div>
                    </div>

                    <div className="row margintop2">
                      <div className="col-2" style={{marginTop:"8px"}}><FaMapMarkerAlt color="#626262" size="1.2em"/></div>
                      <div className="col-8">
                      <span className="post-name-btn fontsize-12px" style={{marginLeft:"0px",fontSize:"12px",color:"#626262"}}>Lives in</span>
                      <div className="" style={{marginTop:"-5px"}}><span className="post-name-btn fontsize-12px" style={{color:"#212529",marginLeft:"0px",fontSize:"12px"}}>{getmenu.currentCity}</span></div>
                      </div>
                    </div>

                    <div className="row margintop2">
                      <div className="col-2" style={{marginTop:"8px"}}><IoMdMan color="#626262" size="1.2em" /><div className="" style={{ marginTop:"-15px" }}><CgShapeHalfCircle color="#626262" size="1.2em" style={{transform: "rotate(270deg)"}}/></div> </div>
                      <div className="col-8">
                      <span className="post-name-btn fontsize-12px" style={{marginLeft:"0px",fontSize:"12px",color:"#626262",whiteSpace:"nowrap"}}>Disatance Radius</span>
                      <div className="" style={{marginTop:"-5px"}}><span className="post-name-btn fontsize-12px" style={{color:"#212529",marginLeft:"0px",fontSize:"12px"}}>{getmenu.distance}</span></div>
                      </div>
                    </div>

                    <div className="row margintop2">
                      <div className="col-2" style={{marginTop:"8px"}}><GiCoffeeCup color="#626262" size="1.3em"/></div>
                      <div className="col-8">
                      <span className="post-name-btn fontsize-12px" style={{marginLeft:"0px",fontSize:"12px",color:"#626262",whiteSpace:"nowrap"}}>Cups of Coffee</span>
                      <div className="" style={{marginTop:"-5px"}}><span className="post-name-btn fontsize-12px" style={{color:"#212529",marginLeft:"0px",fontSize:"12px"}}>{getmenu.coffeeMeetsAttended}</span></div>
                      </div>
                    </div>

              </div>
            </div>
            <div className="row margintop3 textcenter">
              <div className="col-3"></div>
              <div className="col-3 ">
              <button className="secondry-btnn cpointer" onClick={()=>toggleviewProfDiPop(getmenu.id,getmenu.blockStatus)}>View Profile</button>
              {isOpenMessagePopViewDirect && <ViewProfCommunityDirectPop

handleClose={toggleviewProfDiPop} uerAuthId={uerAuthId} idforcommpop={idforcommpop}
userlist ={userlist} setuserlist={setuserlist} styleForMessgBlockstatusCommView={styleForMessgBlockstatusCommView}
/>}
              </div>

              <div className="col-6" style={{marginLeft:"-20px",marginTop:"6px"}}>
                <button className={getmenu.connectionStatus == false && getmenu.blockStatus == 0 ? "primary-btnn cpointer" : "hide"}  onClick={() => addCommDirectoryConnect(getmenu.id)}>Connect</button>
                <span className={getmenu.blockStatus == 1 ? "communtdrc-cit-text" : "hide"} style={{ marginLeft: "10px", fontSize: "13px", color: "#555555" }} >Blocked</span>
                <span className={getmenu.connectionStatus == true && getmenu.connection=="Sent" ? "community-direct-nametxt11" : "hide"} style={{marginLeft:"9px"}}>Connection Request Sent</span>
                <span className={getmenu.connectionStatus == true && getmenu.connection=="Recieved" ? "community-direct-nametxt11" : "hide"} style={{marginLeft:"9px"}}>Connection Request Recieved</span>
                </div>

              {/* <div className="col-2"></div> */}



            </div>

          </div>
          ))
        }
        {
          commDirectory && commDirectory.length == 0 && (
            <div className='row margintop'>
              <div className='col-3'></div>
              <div className='col-5 margintop'> <span className='event-sub-detail'> Community Members Are not Available</span>
              </div>

            </div>
          )
        }
        </div>
          </div>
          </div>
<div className={matrimony ? "col-lg-6 col-sm-12 col-md-12 matrimon-div communitydiv heightforAll3Div" : "hide"}>
          <div className="row Matrimony-Top-div textcenter">
            <p style={{color:"#ffffff",fontWeight:"normal"}}>Community Matrimony Service</p>
          </div>

          <div className="row">
            <div className="text-wrap">
              <div className="border" style={{ marginRight: "-11px", marginLeft: "-11px" }}>
                <Tab.Container id="left-tabs-example" defaultActiveKey="link-29">
                  <div className=" bg-gray-100 nav-bg horizontal-tabmenu ">

                    <Nav className="nav nav-tabs link-29 ">

                      <Nav.Item>
                        <Nav.Link eventKey="link-29" className="navlinkforverticaltab">
                          All Profiles
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link eventKey="link-30" className="navlinkforverticaltab">
                          My Profile
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link eventKey="link-31" className="navlinkforverticaltab">
                          Shortlist
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="link-32" className="navlinkforverticaltab">
                          My Subscription
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>

                  </div>

                  <Tab.Content className="card-body ">
                    <Tab.Pane eventKey="link-29">
                      <div className="">

                        <Tab.Container id="left-tabs-example" defaultActiveKey="link-29">
                          <div className='row'>
                            <div className="col-3">
                              <select id="inputState" name=''
                                className=" form-control" style={{ height: "34px", fontSize: "11px" }}>
                                <option selected value="">All 1502 profile</option>
                                <option value="pending response">Brides 750</option>
                                <option value="accepted invitations">Grooms 752</option>
                              </select>
                            </div>
                          </div>
                          <div className="row" style={{ marginTop: "20px" }}>
                            
                            {
                              matrimonyAllProf.map((getmenu, index) => (
                                <div className="myproffile-mtr-div">
                                <Card className={ authlocalstoragedata.matrimonyId==0?"blurcardmat":"margintop3 "} style={{ borderColor: "#B5924C", }} key={getmenu.matrimonyId}>
                                  <div className="row textcenter">
                                    <div className="col-3 margintop3">
                                      <img src={getmenu.profilePhotoPath} id='profpicdisablesave10' className='margintop3 blurcardmatprof' style={{width:"140px",borderRadius:"48%"}}/>
                                      <div className="" style={{ marginLeft: '7px' }}>
                                       {getmenu.bookmarkStatus==1?
                                       (<AiFillHeart size="1.4em" onClick={() => addShortList(getmenu.matrimonyId)} style={{cursor:"pointer"}}/>):
                                       (<AiOutlineHeart size="1.4em" onClick={() => addShortList(getmenu.matrimonyId)} style={{cursor:"pointer"}} />)

                                      } <span style={{ fontSize: "14px", color: "#333333", fontWeight: "700", marginLeft: "7px" }}>{getmenu.name}</span>
                                        </div>
                                      <div className="" style={{ marginLeft: '0px' }}>
                                      </div>

                                    </div>

                                    <div className="col-4 ">
                                      <div className="row margintop3">
                                        <div className="col-6 textleft"><span className="communtdrc-cit-text">Caste</span></div>
                                        <div className="col-6 textleft" style={{ whiteSpace: "nowrap" }}><span className="comudrct-digitxt">{getmenu.caste}</span></div>
                                      </div>
                                      <div className="row margintop5">
                                        <div className="col-6 textleft"><span className="communtdrc-cit-text">Lives In</span></div>
                                        <div className="col-6 textleft" style={{ whiteSpace: "nowrap" }}><span className="comudrct-digitxt">{getmenu.livesIn}</span></div>
                                      </div>
                                      <div className="row margintop5">
                                        <div className="col-6 textleft"><span className="communtdrc-cit-text">From</span></div>
                                        <div className="col-6 textleft" style={{ whiteSpace: "nowrap" }}><span className="comudrct-digitxt">{getmenu.from}</span></div>
                                      </div>
                                      <div className="row margintop5">
                                        <div className="col-6 textleft"><span className="communtdrc-cit-text">Height</span></div>
                                        <div className="col-6 textleft"><span className="comudrct-digitxt">{getmenu.height}</span></div>
                                      </div>
                                      <div className="row margintop5">
                                        <div className="col-6 textleft"><span className="communtdrc-cit-text">Weight</span></div>
                                        <div className="col-6 textleft"><span className="comudrct-digitxt">{getmenu.weight}</span></div>
                                      </div>


                                    </div>
                                    <div className="col-5">
                                      <div className="row margintop3">
                                        <div className="col-6 textleft"><span className="communtdrc-cit-text" style={{ whiteSpace: "nowrap" }}>Mother Tongue</span></div>
                                        <div className="col-6 textleft" style={{ whiteSpace: "nowrap" }}><span className="comudrct-digitxt">{getmenu.motherTongue}</span></div>
                                      </div>

                                      <div className="row margintop5">
                                        <div className="col-6 textleft"><span className="communtdrc-cit-text">DOB</span></div>
                                        <div className="col-6 textleft" style={{ whiteSpace: "nowrap" }}><span className="comudrct-digitxt">{getmenu.dob}</span></div>
                                      </div>
                                      <div className="row margintop5">
                                        <div className="col-6 textleft"><span className="communtdrc-cit-text" style={{ whiteSpace: "nowrap", marginLeft: "" }}>Edu. Qualification</span></div>
                                        <div className="col-6 textleft"><span className="comudrct-digitxt" style={{ whiteSpace: "nowrap" }}>{getmenu.eduQualification}</span></div>
                                      </div>
                                      {/* <div className="row margintop5">
                                        <div className="col-6 textleft"><span className="communtdrc-cit-text">Working</span></div>
                                        <div className="col-6 textleft"><span className="comudrct-digitxt">{getmenu.working}</span></div>
                                      </div> */}
                                      <div className="row margintop5">
                                        <div className="col-6 textleft"><span className="communtdrc-cit-text" style={{ whiteSpace: "nowrap" }}>Specially Abled</span></div>
                                        <div className="col-6 textleft"><span className="comudrct-digitxt">{getmenu.speciallyAbled}</span></div>
                                      </div>
                                    </div>

                                  </div>
                                  <div className="row textright">
                                    <div style={{left:"97%"}}>
                                    <Link to={`/MatrimonyViewProf/${getmenu.matrimonyId}`} ><p className="bluetextforlink" style={{ marginTop: "1px" }} >View Profile</p></Link>

                                    </div>
                                  </div>
                                </Card>
                                </div>

                              )
                              )}
                              
                            {/* <Card className="margintop3" style={{borderColor:"#B5924C",}}>
                        <div className="row textcenter">
                          <div className="col-3 margintop3">
                          <img src={profileshital}  className='margintop3'/>
                          <div className=""style={{marginLeft:'7px'}}><AiOutlineHeart size="1.2em"/><span style={{fontSize:"14px",color:"#333333",fontWeight:"700",marginLeft:"7px"}}>Shital Naidu</span></div>
                          <div className=""style={{marginLeft:'0px'}}><span style={{fontSize:"10px",color:"#333333",fontWeight:"400",marginLeft:"5px"}}>Never Married Age 32</span></div>

                          </div>
                          
                          <div className="col-4 ">
                            <div className="row margintop3">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text">Caste</span></div>
                              <div className="col-6 textleft"style={{whiteSpace:"nowrap"}}><span className="comudrct-digitxt">Balija Naidu</span></div>
                              </div>
                              <div className="row margintop5">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text">Lives In</span></div>
                              <div className="col-6 textleft"style={{whiteSpace:"nowrap"}}><span className="comudrct-digitxt">Vijaywada</span></div>
                              </div>
                              <div className="row margintop5">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text">From</span></div>
                              <div className="col-6 textleft" style={{whiteSpace:"nowrap"}}><span className="comudrct-digitxt">Vijaywada</span></div>
                              </div>
                              <div className="row margintop5">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text">Height</span></div>
                              <div className="col-6 textleft"><span className="comudrct-digitxt">5'6"</span></div>
                              </div>
                              <div className="row margintop5">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text">Weight</span></div>
                              <div className="col-6 textleft"><span className="comudrct-digitxt">55</span></div>
                              </div>
                              
                          
                          </div>
                          <div className="col-5">
                          <div className="row margintop3">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text"style={{whiteSpace:"nowrap"}}>Mother Tongue</span></div>
                              <div className="col-6 textleft"style={{whiteSpace:"nowrap"}}><span className="comudrct-digitxt">Telugu</span></div>
                              </div>
                              
                              <div className="row margintop5">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text">DOB</span></div>
                              <div className="col-6 textleft" style={{whiteSpace:"nowrap"}}><span className="comudrct-digitxt">19 October 2000</span></div>
                              </div>
                              <div className="row margintop5">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text"style={{whiteSpace:"nowrap",marginLeft:""}}>Edu. Qualification</span></div>
                              <div className="col-6 textleft"><span className="comudrct-digitxt"style={{whiteSpace:"nowrap"}}>BE Architecture</span></div>
                              </div>
                              <div className="row margintop5">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text">Working</span></div>
                              <div className="col-6 textleft"><span className="comudrct-digitxt">No</span></div>
                              </div>
                              <div className="row margintop5">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text"style={{whiteSpace:"nowrap"}}>Specially Abled</span></div>
                              <div className="col-6 textleft"><span className="comudrct-digitxt">No</span></div>
                              </div>
                          </div>

                        </div>
                        <div className="row textright"><p className="bluetextforlink" style={{marginTop:"-11px"}}>View Profile</p></div>
                      </Card>
                      <Card className="margintop3" style={{borderColor:"#B5924C",}}>
                        <div className="row textcenter">
                          <div className="col-3 margintop3">
                          <img src={profileshital}  className='margintop3'/>
                          <div className=""style={{marginLeft:'7px'}}><AiOutlineHeart size="1.2em"/><span style={{fontSize:"14px",color:"#333333",fontWeight:"700",marginLeft:"7px"}}>Shital Naidu</span></div>
                          <div className=""style={{marginLeft:'0px'}}><span style={{fontSize:"10px",color:"#333333",fontWeight:"400",marginLeft:"5px"}}>Never Married Age 32</span></div>

                          </div>
                          
                          <div className="col-4 ">
                            <div className="row margintop3">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text">Caste</span></div>
                              <div className="col-6 textleft"style={{whiteSpace:"nowrap"}}><span className="comudrct-digitxt">Balija Naidu</span></div>
                              </div>
                              <div className="row margintop5">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text">Lives In</span></div>
                              <div className="col-6 textleft"style={{whiteSpace:"nowrap"}}><span className="comudrct-digitxt">Vijaywada</span></div>
                              </div>
                              <div className="row margintop5">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text">From</span></div>
                              <div className="col-6 textleft" style={{whiteSpace:"nowrap"}}><span className="comudrct-digitxt">Vijaywada</span></div>
                              </div>
                              <div className="row margintop5">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text">Height</span></div>
                              <div className="col-6 textleft"><span className="comudrct-digitxt">5'6"</span></div>
                              </div>
                              <div className="row margintop5">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text">Weight</span></div>
                              <div className="col-6 textleft"><span className="comudrct-digitxt">55</span></div>
                              </div>
                              
                          
                          </div>
                          <div className="col-5">
                          <div className="row margintop3">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text"style={{whiteSpace:"nowrap"}}>Mother Tongue</span></div>
                              <div className="col-6 textleft"style={{whiteSpace:"nowrap"}}><span className="comudrct-digitxt">Telugu</span></div>
                              </div>
                              
                              <div className="row margintop5">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text">DOB</span></div>
                              <div className="col-6 textleft" style={{whiteSpace:"nowrap"}}><span className="comudrct-digitxt">19 October 2000</span></div>
                              </div>
                              <div className="row margintop5">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text"style={{whiteSpace:"nowrap",marginLeft:""}}>Edu. Qualification</span></div>
                              <div className="col-6 textleft"><span className="comudrct-digitxt"style={{whiteSpace:"nowrap"}}>BE Architecture</span></div>
                              </div>
                              <div className="row margintop5">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text">Working</span></div>
                              <div className="col-6 textleft"><span className="comudrct-digitxt">No</span></div>
                              </div>
                              <div className="row margintop5">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text"style={{whiteSpace:"nowrap"}}>Specially Abled</span></div>
                              <div className="col-6 textleft"><span className="comudrct-digitxt">No</span></div>
                              </div>
                          </div>

                        </div>
                        <div className="row textright"><p className="bluetextforlink" style={{marginTop:"-11px"}}>View Profile</p></div>
                      </Card>
                      <Card className="margintop3" style={{borderColor:"#B5924C",}}>
                        <div className="row textcenter">
                          <div className="col-3 margintop3">
                          <img src={profileshital}  className='margintop3'/>
                          <div className=""style={{marginLeft:'7px'}}><AiOutlineHeart size="1.2em"/><span style={{fontSize:"14px",color:"#333333",fontWeight:"700",marginLeft:"7px"}}>Shital Naidu</span></div>
                          <div className=""style={{marginLeft:'0px'}}><span style={{fontSize:"10px",color:"#333333",fontWeight:"400",marginLeft:"5px"}}>Never Married Age 32</span></div>

                          </div>
                          
                          <div className="col-4 ">
                            <div className="row margintop3">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text">Caste</span></div>
                              <div className="col-6 textleft"style={{whiteSpace:"nowrap"}}><span className="comudrct-digitxt">Balija Naidu</span></div>
                              </div>
                              <div className="row margintop5">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text">Lives In</span></div>
                              <div className="col-6 textleft"style={{whiteSpace:"nowrap"}}><span className="comudrct-digitxt">Vijaywada</span></div>
                              </div>
                              <div className="row margintop5">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text">From</span></div>
                              <div className="col-6 textleft" style={{whiteSpace:"nowrap"}}><span className="comudrct-digitxt">Vijaywada</span></div>
                              </div>
                              <div className="row margintop5">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text">Height</span></div>
                              <div className="col-6 textleft"><span className="comudrct-digitxt">5'6"</span></div>
                              </div>
                              <div className="row margintop5">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text">Weight</span></div>
                              <div className="col-6 textleft"><span className="comudrct-digitxt">55</span></div>
                              </div>
                              
                          
                          </div>
                          <div className="col-5">
                          <div className="row margintop3">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text"style={{whiteSpace:"nowrap"}}>Mother Tongue</span></div>
                              <div className="col-6 textleft"style={{whiteSpace:"nowrap"}}><span className="comudrct-digitxt">Telugu</span></div>
                              </div>
                              
                              <div className="row margintop5">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text">DOB</span></div>
                              <div className="col-6 textleft" style={{whiteSpace:"nowrap"}}><span className="comudrct-digitxt">19 October 2000</span></div>
                              </div>
                              <div className="row margintop5">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text"style={{whiteSpace:"nowrap",marginLeft:""}}>Edu. Qualification</span></div>
                              <div className="col-6 textleft"><span className="comudrct-digitxt"style={{whiteSpace:"nowrap"}}>BE Architecture</span></div>
                              </div>
                              <div className="row margintop5">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text">Working</span></div>
                              <div className="col-6 textleft"><span className="comudrct-digitxt">No</span></div>
                              </div>
                              <div className="row margintop5">
                              <div className="col-6 textleft"><span className="communtdrc-cit-text"style={{whiteSpace:"nowrap"}}>Specially Abled</span></div>
                              <div className="col-6 textleft"><span className="comudrct-digitxt">No</span></div>
                              </div>
                          </div>

                        </div>
                        <div className="row textright"><p className="bluetextforlink" style={{marginTop:"-11px"}}>View Profile</p></div>
                      </Card> */}
                          </div>


                        </Tab.Container>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="link-30">
                      
                      {
                        matrimonyMyProf.map((getmenu, index) => (
                          <div className="myproffile-mtr-div">
                            <div className="row myproffile-mtr-div" style={{ marginTop: "20px" }}>
                              
                              <div className="col-12">
                              <div className={getmenu.profileDetails ? "backgroundcolor-card-matrimony" : "hide"} style={{ borderColor: "", borderBottomRightRadius: "0px", borderBottomLeftRadius: "0px" ,backgroundColor:"#FFFFFF",paddingBottom:"14px",marginTop:"15px"}}>
                                <div className="row margintop1">
                                  <div className="col-3"></div>
                                  <div className="col-8">
                                    <span style={{ fontSize: "14px", color: "#222227", fontWeight: "700", marginLeft: "7px" }}>{getmenu.profileDetails?.name}</span>
                                    <span style={{ fontSize: "10px", color: "#222227", fontWeight: "400", marginLeft: "9px" }}>{getmenu.profileDetails?.maritalStatus} Age {getmenu.profileDetails?.age}</span>
                                  </div>



                                </div>
                                <div className="row textcenter">
                                  <div className="col-3 margintop3">
                                    <img src={getmenu.profilePhotoPath} id='profpicdisablesave11' className='margintop3' />

                                  </div>

                                  <div className="col-4 ">
                                    <div className="row margintop">
                                      <div className="col-6 textleft"><span className="communtdrc-cit-text" style={{color:"#797979"}}>Caste</span></div>
                                      <div className="col-6 textleft" style={{ whiteSpace: "nowrap" }}><span className="comudrct-digitxt" style={{color:"#797979"}}>{getmenu.profileDetails?.caste}</span></div>
                                    </div>
                                    <div className="row margintop5">
                                      <div className="col-6 textleft"><span className="communtdrc-cit-text" style={{color:"#797979"}}>Religion</span></div>
                                      <div className="col-6 textleft" style={{ whiteSpace: "nowrap",color:"#797979" }} ><span className="comudrct-digitxt">Hindu</span></div>
                                    </div>
                                    <div className="row margintop5">
                                      <div className="col-6 textleft"><span className="communtdrc-cit-text" style={{color:"#797979"}}>Lives In</span></div>
                                      <div className="col-6 textleft" style={{ whiteSpace: "nowrap",color:"#797979" }} ><span className="comudrct-digitxt">{getmenu.profileDetails?.livesIn}</span></div>
                                    </div>
                                    <div className="row margintop5">
                                      <div className="col-6 textleft"><span className="communtdrc-cit-text" style={{color:"#797979"}}>From</span></div>
                                      <div className="col-6 textleft" style={{ whiteSpace: "nowrap" }}><span className="comudrct-digitxt" style={{color:"#797979"}}>{getmenu.profileDetails?.from}</span></div>
                                    </div>
                                    <div className="row margintop5">
                                      <div className="col-6 textleft"><span className="communtdrc-cit-text"style={{color:"#797979"}}>Height</span></div>
                                      <div className="col-6 textleft"><span className="comudrct-digitxt" style={{color:"#797979"}}>{getmenu.profileDetails?.height}</span></div>
                                    </div>
                                    <div className={getmenu.healthDetails?"row margintop5":"hide"}>
                                      <div className="col-6 textleft"><span className="communtdrc-cit-text" style={{color:"#797979"}}>Weight</span></div>
                                      <div className="col-6 textleft"><span className="comudrct-digitxt" style={{color:"#797979"}}>{getmenu.healthDetails?.weight}</span></div>
                                    </div>


                                  </div>
                                  <div className="col-5">
                                    <div className={getmenu.basicDetails?"row margintop":"hide"}>
                                      <div className="col-6 textleft"><span className="communtdrc-cit-text" style={{ whiteSpace: "nowrap",color:"#797979" }} >Mother Tongue</span></div>
                                      <div className="col-6 textleft" style={{ whiteSpace: "nowrap" }}><span className="comudrct-digitxt" style={{color:"#797979"}}>{getmenu.basicDetails?.motherTongue}</span></div>
                                    </div>

                                    <div className="row margintop5">
                                      <div className="col-6 textleft"><span className="communtdrc-cit-text">DOB</span></div>
                                      <div className="col-6 textleft" style={{ whiteSpace: "nowrap" }}><span className="comudrct-digitxt" >{getmenu.profileDetails?.dob}</span></div>
                                    </div>
                                    <div className="row margintop5">
                                      <div className="col-6 textleft"><span className="communtdrc-cit-text" style={{ whiteSpace: "nowrap", marginLeft: "",color:"#797979" }} >Edu. Qualification</span></div>
                                      <div className="col-6 textleft"><span className="comudrct-digitxt" style={{ whiteSpace: "nowrap",color:"#797979" }} >{getmenu.profileDetails?.eduQualification}</span></div>
                                    </div>
                                    {/* <div className="row margintop5">
                                      <div className="col-6 textleft"><span className="communtdrc-cit-text">Working</span></div>
                                      <div className="col-6 textleft"><span className="comudrct-digitxt">No</span></div>
                                    </div> */}
                                    <div className="row margintop5">
                                      <div className="col-6 textleft"><span className="communtdrc-cit-text" style={{ whiteSpace: "nowrap" ,color:"#797979"}}>Specially Abled</span></div>
                                      <div className="col-6 textleft"><span className="comudrct-digitxt" style={{color:"#797979"}}>{getmenu.profileDetails?.speciallyAbled}</span></div>
                                    </div>
                                  </div>

                                </div>
                                {/* <div className="row textright"><p className="bluetextforlink" style={{marginTop:"-11px"}}>View Profile</p></div> */}
                              </div>
                              </div>
                              
                            </div>
                            <div className="row margintop3">
                              <div className="col-lg-6 col-sm-12 col-md-12">
                                <div className=""><span style={{ fontSize: "14px", color: "#222227", fontWeight: "700", marginLeft: "0px" }}>Basic Information <Link to={`/EditBasicDetail/${getmenu.basicDetails.matrimonyId}`}><FaEdit size='1.2em' color="#222227" style={{ marginLeft: '10px' }} /></Link></span></div>
                                <div className={getmenu.basicDetails ? "backgroundcolor-card-matrimony margintop1" : "hide"} style={{ borderColor: "", paddingRight: "",backgroundColor:"#ffffff",paddingBottom:"14px" }}>
                                  <div className="row margintop1">
                                    <div className="col-lg-5 col-sm-6 col-md-6 textleft"><span className="communtdrc-cit-text" style={{color:"#797979"}}>Profile Created By</span></div>
                                    <div className="col-lg-7  col-sm-6 col-md-6 textleft" style={{ marginLeft: "0px" }}><span className="comudrct-digitxt" style={{ marginLeft: "-1px" }}>{getmenu.basicDetails?.name}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text" style={{color:"#797979"}}>Email ID</span></div>
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft" style={{ marginLeft: "0px" }}><span className="comudrct-digitxt" style={{ marginLeft: "-1" }}>{getmenu.basicDetails?.emailId}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text" style={{color:"#797979"}}>Gender</span></div>
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft" style={{ marginLeft: "0px" }}><span className="comudrct-digitxt">{getmenu.basicDetails?.gender}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text" style={{color:"#797979"}}>Blood Grp:</span></div>
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft" style={{ marginLeft: "0px" }}><span className="comudrct-digitxt" style={{ marginLeft: "-1px" }}>{getmenu.basicDetails?.bloodGroup}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text" style={{color:"#797979"}}>Mother Tongue</span></div>
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt" style={{ marginLeft: "0px" }}>{getmenu.basicDetails?.motherTongue}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text" style={{color:"#797979"}}>Marital Status</span></div>
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft" style={{ marginLeft: "0" }}><span className="comudrct-digitxt">{getmenu.basicDetails?.maritalStatus} </span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text" style={{color:"#797979"}}>Primary Mobile No:</span></div>
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft" style={{ marginLeft: "0px" }}><span className="comudrct-digitxt" style={{ marginLeft: "-1px" }}>{getmenu.basicDetails?.primaryMobileNumber} <FaWhatsapp /></span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text" style={{color:"#797979"}}>Secondary Mobile No:</span></div>
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft" style={{ marginLeft: "0px" }}><span className="comudrct-digitxt" style={{ marginLeft: "-1px" ,color:"#797979"}}>{getmenu.basicDetails?.secondaryMobileNumber}</span></div>
                                  </div>

                                </div>
                              </div>

                              <div className="col-lg-6 col-sm-12 col-md-12">
                                <div className=""><span style={{ fontSize: "14px", color: "#F2F2F2", fontWeight: "700", marginLeft: "0px", color:"#222227"}}>Educations Details <Link to={`/EditEducationDetail/${getmenu.educationDetails.matrimonyId}`}><FaEdit size='1.2em' color="#222227" style={{ marginLeft: '10px' }} /></Link> </span></div>

                                <div className={getmenu.educationDetails ? "backgroundcolor-card-matrimony margintop1" : "hide"} style={{ borderColor: "",backgroundColor:"#ffffff",paddingBottom:"14px" }}>
                                  <div className="row margintop1">
                                    <div className="col-lg-7 col-sm-6 col-md-6 textleft"><span className="communtdrc-cit-text" style={{color:"#797979"}}>Education</span></div>
                                    <div className="col-lg-5  col-sm-6 col-md-6 textleft" style={{ whiteSpace: "nowrap" }}><span className="comudrct-digitxt" style={{color:"#797979"}}>{getmenu.educationDetails?.education}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text" style={{color:"#797979"}}>Education in detail</span></div>
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft" style={{ whiteSpace: "" }}><span className="comudrct-digitxt" style={{color:"#797979"}}>{getmenu.educationDetails?.education}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text" style={{color:"#797979"}}>Occupation</span></div>
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft" style={{ whiteSpace: "" }}><span className="comudrct-digitxt" style={{color:"#797979"}}>{getmenu.educationDetails?.occupation} </span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text" style={{color:"#797979"}}>Employment Type</span></div>
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt" style={{color:"#797979"}}>{getmenu.educationDetails?.employmentType}</span></div>
                                  </div>

                                  <div className="row margintop5">
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text" style={{color:"#797979"}}> Annual Income</span></div>
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt"style={{color:"#797979"}}>{getmenu.educationDetails?.annualIncome}</span></div>
                                  </div>
                                  <div className="row margintop">
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text"> </span></div>
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt"></span></div>
                                  </div>
                                  <div className="row margintop">
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text"> </span></div>
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt"></span></div>
                                  </div>
                                  <div className="row margintop">
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text"> </span></div>
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt"></span></div>
                                  </div>
                                  <div className="row margintop">
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text"> </span></div>
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt"></span></div>
                                  </div>
                                  <div className="row margintop">
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text"> </span></div>
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt"></span></div>
                                  </div>
                                  <div className="row margintop">
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text"> </span></div>
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt"></span></div>
                                  </div>
                                  <div className="row margintop3"></div>


                                </div>
                              </div>
                            </div>

                            <div className="row margintop3">
                              <div className="col-lg-6 col-sm-12 col-md-12">
                                <div className=""><span style={{ fontSize: "14px", color: "#222227", fontWeight: "700", marginLeft: "0px" }}>Features and Health <Link to={`/EditHealthDetail/${getmenu.healthDetails.matrimonyId}`}><FaEdit size='1.2em' color="#222227" style={{ marginLeft: '10px' }} /></Link></span></div>
                                <div className={getmenu.healthDetails ? "backgroundcolor-card-matrimony margintop1" : "hide"} style={{ borderColor: "",backgroundColor:"#ffffff" ,paddingBottom:"14px"}}>
                                  <div className="row margintop1">
                                    <div className="col-lg-6 col-sm-6 col-md-6 textleft"><span className="communtdrc-cit-text">Height</span></div>
                                    <div className="col-lg-6  col-sm-6 col-md-6 textleft" style={{}}><span className="comudrct-digitxt">{getmenu.healthDetails?.heightFeet} ft {getmenu.healthDetails?.heightInches} inch</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Weight</span></div>
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft" style={{}}><span className="comudrct-digitxt">{getmenu.healthDetails?.weight} Kg</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Skin Tone</span></div>
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt">{getmenu.healthDetails?.skinTone}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Body Type</span></div>
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft" style={{}}><span className="comudrct-digitxt">{getmenu.healthDetails?.bodyType} </span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text"> Health Information</span></div>
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt">{getmenu.healthDetails?.healthInformation}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Disability</span></div>
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt">{getmenu.healthDetails?.disability}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Glasses</span></div>
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt">{getmenu.healthDetails?.glasses}</span></div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 col-sm-12 col-md-12">
                                <div className=""><span style={{ fontSize: "14px", color: "#222227", fontWeight: "700", marginLeft: "0px" }}>Horoscope Details <Link to={`/EditHoroscopeDetail/${getmenu.horoscopeDetails.matrimonyId}`}><FaEdit size='1.2em' color="#222227" style={{ marginLeft: '10px' }} /></Link></span></div>

                                <div className={getmenu.horoscopeDetails ? "backgroundcolor-card-matrimony margintop1" : "hide"} style={{ borderColor: "" ,backgroundColor:"#ffffff",paddingBottom:"14px"}}>
                                  <div className="row margintop1">
                                    <div className="col-lg-6 col-sm-6 col-md-6 textleft"><span className="communtdrc-cit-text">DOB</span></div>
                                    <div className="col-lg-6  col-sm-6 col-md-6 textleft" style={{ whiteSpace: "norap" }}><span className="comudrct-digitxt">{getmenu.horoscopeDetails?.dob}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Time</span></div>
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft" style={{ whiteSpace: "" }}><span className="comudrct-digitxt">{getmenu.horoscopeDetails?.time}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Place of Birth</span></div>
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft" style={{ whiteSpace: "" }}><span className="comudrct-digitxt">{getmenu.horoscopeDetails?.placeOfBirth} </span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Rashi</span></div>
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt">{getmenu.horoscopeDetails?.raashi}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Goatra</span></div>
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt">{getmenu.horoscopeDetails?.goatra}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Nakshatra</span></div>
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt">{getmenu.horoscopeDetails?.nakshatra}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Manglik</span></div>
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt">{getmenu.horoscopeDetails?.manglik}</span></div>
                                  </div>
                                  <div className="row margintop">
                                  </div>

                                </div>
                              </div>

                            </div>
                            <div className="row margintop3">
                              <div className="col-lg-6 col-sm-12 col-md-12">
                                <div className=""><span style={{ fontSize: "14px", color: "#222227", fontWeight: "700", marginLeft: "0px" }}>Lifestyle<Link to={`/EditLifeStyleDetail/${getmenu.lifestyleDetails.matrimonyId}`}><FaEdit size='1.2em' color="#222227" style={{ marginLeft: '10px' }} /></Link></span></div>
                                <div className={getmenu.lifestyleDetails ? "backgroundcolor-card-matrimony margintop1" : "hide"} style={{ borderColor: "",backgroundColor:"#ffffff",paddingBottom:"14px" }}>
                                  <div className="row margintop1">
                                    <div className="col-lg-6 col-sm-6 col-md-6 textleft"><span className="communtdrc-cit-text">Family Values</span></div>
                                    <div className="col-lg-6  col-sm-6 col-md-6 textleft" style={{}}><span className="comudrct-digitxt">{getmenu.lifestyleDetails?.familyValues}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Diet</span></div>
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft" style={{}}><span className="comudrct-digitxt">{getmenu.lifestyleDetails?.diet}</span></div>
                                  </div>
                                  {/* <div className="row margintop5">
                              <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Skin Tone</span></div>
                              <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt">{getmenu.lifestyleDetails?.diet}</span></div>
                              </div> */}
                                  <div className="row margintop5">
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Smoke</span></div>
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft" style={{}}><span className="comudrct-digitxt">{getmenu.lifestyleDetails?.smokes} </span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Drinks</span></div>
                                    <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt">{getmenu.lifestyleDetails?.drinks}</span></div>
                                  </div>
                                  <div className="row margintop3">

                                  </div>
                                  <div className="row margintop">

                                  </div>
                                  <div className="row margintop">
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text"> </span></div>
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt"></span></div>
                                  </div><div className="row margintop">
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text"> </span></div>
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt"></span></div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 col-sm-12 col-md-12">
                                <div className=""><span style={{ fontSize: "14px", color: "#222227", fontWeight: "700", marginLeft: "0px" }}>About Yourself <Link to={`/EditAboutYourself/${authlocalstoragedata?.matrimonyId}`}><FaEdit size='1.2em' color="#222227" style={{ marginLeft: '10px' }} /></Link></span></div>

                                <div className="backgroundcolor-card-matrimony margintop1" style={{ borderColor: "",backgroundColor:"#ffffff" ,paddingBottom:"14px"}}>
                                  <div className="row margintop1">
                                    <div className="col-lg-7 col-sm-6 col-md-6 textleft"><span className="communtdrc-cit-text">About Yourself</span></div>
                                    <div className="col-lg-5  col-sm-6 col-md-6 textleft" style={{ whiteSpace: "norap" }}><span className="comudrct-digitxt">{getmenu.aboutYourself}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    {/* <div className="col-lg-7 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Time</span></div>
                              <div className="col-lg-5 col-sm-6 col-md-6  textleft"style={{whiteSpace:""}}><span className="comudrct-digitxt">12:40 PM</span></div> */}
                                  </div>
                                  <div className="row margintop5">
                                    {/* <div className="col-lg-7 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Place of Birth</span></div>
                              <div className="col-lg-5 col-sm-6 col-md-6  textleft" style={{whiteSpace:""}}><span className="comudrct-digitxt">Pune </span></div> */}
                                  </div>
                                  <div className="row margintop5">
                                    {/* <div className="col-lg-7 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Rashi</span></div>
                              <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt">Vrish</span></div> */}
                                  </div>
                                  <div className="row margintop5">
                                    {/* <div className="col-lg-7 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Goatra</span></div>
                              <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt">Atri</span></div> */}
                                  </div>
                                  <div className="row margintop5">
                                    {/* <div className="col-lg-7 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Nakshatra</span></div>
                              <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt">Ashwini</span></div> */}
                                  </div>
                                  <div className="row margintop5">
                                    {/* <div className="col-lg-7 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Manglik</span></div>
                              <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt">No</span></div> */}
                                  </div>
                                  <div className="row margintop">
                                  </div>
                                  <div className="row margintop">
                                  </div>
                                  <div className="row margintop">
                                  </div>
                                  <div className="row margintop">
                                  </div>

                                  <div className="row margintop3">
                                  </div>

                                </div>
                              </div>

                            </div>
                            <div className="row margintop3">
                              <div className="col-lg-6 col-sm-12 col-md-12">
                                <div className=""><span style={{ fontSize: "14px", color: "#222227", fontWeight: "700", marginLeft: "0px" }}>Family Background<Link to={`/EditFamilybackground/${authlocalstoragedata?.matrimonyId}`}><FaEdit size='1.2em' color="#222227" style={{ marginLeft: '10px' }} /></Link></span></div>
                                <div className="backgroundcolor-card-matrimony margintop1" style={{ borderColor: "",backgroundColor:"#ffffff",paddingBottom:"14px" }}>
                                  <div className="row margintop1">
                                    <div className="col-lg-6 col-sm-7 col-md-6 textleft"><span className="communtdrc-cit-text">Family Background</span></div>
                                    <div className="col-lg-6  col-sm-5 col-md-6 textleft" style={{}}><span className="comudrct-digitxt">{getmenu.familyBackground}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    {/* <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Diet</span></div>
                              <div className="col-lg-6 col-sm-6 col-md-6  textleft"style={{}}><span className="comudrct-digitxt">Vegetarian</span></div> */}
                                  </div>
                                  <div className="row margintop5">
                                    {/* <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Skin Tone</span></div>
                              <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt">Fair</span></div> */}
                                  </div>
                                  <div className="row margintop5">
                                    {/* <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Smoke</span></div>
                              <div className="col-lg-6 col-sm-6 col-md-6  textleft" style={{}}><span className="comudrct-digitxt">No </span></div> */}
                                  </div>
                                  <div className="row margintop5">
                                    {/* <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Drinks</span></div>
                              <div className="col-lg-6 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt">No</span></div> */}
                                  </div>
                                  <div className="row margintop5">

                                  </div>
                                  <div className="row margintop1">

                                  </div>
                                  <div className="row margintop"></div>
                                  <div className="row margintop"></div>
                                  <div className="row margintop"></div>
                                  <div className="row margintop"></div>
                                  <div className="row margintop"></div>
                                  <div className="row margintop"></div>
                                  <div className="row margintop"></div>

                                  <div className="row margintop"></div>


                                </div>
                              </div>
                              <div className="col-lg-6 col-sm-12 col-md-12">
                                <div className=""><span style={{ fontSize: "14px", color: "#222227", fontWeight: "700", marginLeft: "0px" }}>Partner Preference<Link to={`/EditPreferenceDetail/${getmenu.preferenceDetails.matrimonyId}`}><FaEdit size='1.2em' color="#222227" style={{ marginLeft: '10px' }} /></Link></span></div>

                                <div className={getmenu.preferenceDetails ? "backgroundcolor-card-matrimony margintop1" : "hide"} style={{ borderColor: "",backgroundColor:"#ffffff",paddingBottom:"14px" }}>
                                  <div className="row margintop1">
                                    <div className="col-lg-5 col-sm-6 col-md-6 textleft"><span className="communtdrc-cit-text">Education</span></div>
                                    <div className="col-lg-7  col-sm-6 col-md-6 textleft" style={{ whiteSpace: "norap" }}><span className="comudrct-digitxt">{getmenu.preferenceDetails?.education}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Complexion</span></div>
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft" style={{ whiteSpace: "" }}><span className="comudrct-digitxt">{getmenu.preferenceDetails?.complexion}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Caste</span></div>
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft" style={{ whiteSpace: "" }}><span className="comudrct-digitxt">{getmenu.preferenceDetails?.caste}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Age From</span></div>
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft" style={{ whiteSpace: "nowrap" }}>
                                      <div className="row" >
                                        <div className="col-3" style={{marginLeft:"-15px"}}>
                                          <span className="comudrct-digitxt">{getmenu.preferenceDetails?.ageFrom}</span>
                                        </div>
                                        <div className="col-2"> <span className="comudrct-digitxt">To</span></div>
                                        <div className="col-3">
                                          <span className="comudrct-digitxt">{getmenu.preferenceDetails?.ageTo}</span>

                                        </div>
                                        <div className="col-2"><span className="comudrct-digitxt">Yrs</span></div>

                                      </div>



                                    </div>
                                  </div>

                                  <div className="row margintop5">
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Height From</span></div>
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft" style={{ whiteSpace: "nowrap" }}>
                                      <div className="row">
                                        <div className="col-4" style={{marginLeft:"-15px"}}>
                                          <span className="comudrct-digitxt">{getmenu.preferenceDetails?.heightFeetFrom} ft</span>
                                        </div>
                                        <div className="col-4">
                                          <span className="comudrct-digitxt">{getmenu.preferenceDetails?.heightInchesFrom} inch</span>

                                        </div>

                                      </div>



                                    </div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Height to</span></div>
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft" style={{ whiteSpace: "nowrap" }}>
                                      <div className="row">
                                        <div className="col-4" style={{marginLeft:"-15px"}}>
                                          <span className="comudrct-digitxt">{getmenu.preferenceDetails?.heightFeetTo} ft</span>
                                        </div>
                                        <div className="col-4">
                                          <span className="comudrct-digitxt">{getmenu.preferenceDetails?.heightInchesTo} inch</span>

                                        </div>

                                      </div>



                                    </div>
                                  </div>

                                  <div className="row margintop5">
                                    <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Other Expectations</span></div>
                                    <div className="col-lg-7 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt">{getmenu.preferenceDetails?.otherExpectations}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    {/* <div className="col-lg-7 col-sm-6 col-md-6  textleft"><span className="communtdrc-cit-text">Manglik</span></div>
                              <div className="col-lg-5 col-sm-6 col-md-6  textleft"><span className="comudrct-digitxt">No</span></div> */}
                                  </div>
                                  <div className="row margintop"></div>

                                  <div className="row margintop3">
                                  </div>

                                </div>
                              </div>

                            </div>
                            <div className="row margintop3">
                            <div className=""><span style={{ fontSize: "14px", color: "#222227", fontWeight: "700", marginLeft: "0px" }}>Album<Link to={`/EditAlbum/${authlocalstoragedata?.matrimonyId}`}><FaEdit size='1.2em' color="#222227" style={{ marginLeft: '10px' }} /></Link></span></div>
                            <div className={getmenu.profilePhoto !=null ||getmenu.profilePhoto !=""?"col-lg-4 col-sm-12 col-md-12":"hide"}>
                          
                          <div className="backgroundcolor-card-matrimony margintop1" style={{borderColor:"",border:"none",backgroundColor:"#ffffff",paddingBottom:"0px",height:"200px"}}>
                         
                                <div className="row margintop1">
                                <img src={getmenu.profilePhoto} id='profpicdisablesavematmyprof1' className='' style={{width:"235px"}}/>
                  <div className="comudrct-digitxt">Profile Photo</div>
                                </div>
                                
                                
                                
                              
  
        </div>
                          </div>
                        <div className={getmenu.photoOnePath !=null ||getmenu.photoOnePath !=""?"col-lg-4 col-sm-12 col-md-12":"hide"}>
                          
                        <div className="backgroundcolor-card-matrimony margintop1" style={{borderColor:"",border:"none",backgroundColor:"#ffffff" ,paddingBottom:"0px",height:"200px"}}>
                       
                              <div className="row margintop1">
                              <img src={getmenu.photoOnePath} id='profpicdisablesavematmyprof1' className='' style={{width:"235px"}}/>

                              </div>
                              
                              
                              
                            

      </div>
                        </div>
                        <div className={getmenu.photoTwoPath !=null ||getmenu.photoTwoPath !="" ?"col-lg-4 col-sm-12 col-md-12":"hide"}>
                        <div className=""><span style={{fontSize:"14px",color:"#F2F2F2",fontWeight:"700",marginLeft:"0px"}}></span></div>

                        <div className="backgroundcolor-card-matrimony margintop1" style={{borderColor:"",border:"none",backgroundColor:"#ffffff",paddingBottom:"0px",height:"200px"}}>
                        
                              
                              <div className="row margintop1">
                              <img src={getmenu.photoTwoPath} id='profpicdisablesavematmyprof2'  className='margintop1' style={{width:"235px"}}/>

                              </div>
                          
                                

                          </div>
                        </div>
                        
                        
                      </div>
                      <div className="row margintop1">
                      <div className={getmenu.photoThreePath !=null ||getmenu.photoThreePath !="" ?"col-lg-4 col-sm-12 col-md-12":"hide"}>
                          <div className=""><span style={{fontSize:"14px",color:"#F2F2F2",fontWeight:"700",marginLeft:"0px"}}></span></div>
                        <div className="backgroundcolor-card-matrimony margintop1" style={{borderColor:"",border:"none",backgroundColor:"#ffffff",paddingBottom:"0px",height:"200px"}}>
                       
                              <div className="row margintop1">
                              <img src={getmenu.photoThreePath} id='profpicdisablesavematmyprof3' className=''style={{width:"235px"}}/>

                              </div>
                              
                              
                    </div>
                        </div>
                        <div className={getmenu.photoFourPath !=null|| getmenu.photoFourPath !=""?"col-lg-4 col-sm-12 col-md-12":"hide"}>
                        <div className=""><span style={{fontSize:"14px",color:"#F2F2F2",fontWeight:"700",marginLeft:"0px"}}></span></div>

                        <div className="backgroundcolor-card-matrimony margintop1" style={{borderColor:"",border:"none",backgroundColor:"#ffffff",paddingBottom:"0px",height:"200px"}}>
                        
                              
                              <div className="row margintop1">
                              <img src={getmenu.photoFourPath} id="profpicdisablesavematmyprof4" className='margintop1'/>

                              </div>
                          
                                

                          </div>
                        </div>
                        
                        
                      </div>
                          </div>
                        )
                        )}

                    </Tab.Pane>
                    <Tab.Pane eventKey="link-31">
                      <div className='row margintop3' style={{ whiteSpace: "" }}>
                        <div className="col-1 textright">
                          <span className="communtdrc-cit-text">Showing</span>
                        </div>
                        <div className="col-2 ">
                          <select id="inputState" name=''
                            className=" form-control" style={{ height: "32px", fontSize: "11px", marginLeft: "5px" }}>
                            <option selected value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>

                          </select>
                        </div>
                        <div className="col-4 textleft" style={{ marginLeft: "-11px" }}>
                          <span className="communtdrc-cit-text">0f 34 shortlisted profiles</span>
                        </div>
                        <div className="col-2"></div>
                        <div className="col-3">
                          <div class="form-group has-search ">
                            <span class="fa fa-search form-control-feedback"></span>
                            <input type="text" class="form-control" placeholder="Search" onChange={handleSearchForShortMatrimony} style={{ fontSize: '12px' }} />
                          </div>
                        </div>

                      </div>
                      <div className="row" style={{ marginTop: "10px" }}>

                        {
                          matrimonyShortProf.map((getmenu, index) => (
                            <Card className="margintop3" style={{ borderColor: "#B5924C", }} key={getmenu.matrimonyId}>
                              <div className="row textcenter">
                                <div className="col-3 margintop3">
                                  <img src={getmenu.profilePhotoPath} id='profpicdisablesave16' oncontextmenu="return false" className='margintop3' style={{width:"140px",borderRadius:"48%"}} />
                                  <div className="" style={{ marginLeft: '7px' }}> <AiFillHeart size="1.4em" /><span style={{ fontSize: "14px", color: "#333333", fontWeight: "700", marginLeft: "7px" }}>{getmenu.name}</span></div>
                                  <div className="" style={{ marginLeft: '0px' }}><span style={{ fontSize: "10px", color: "#333333", fontWeight: "400", marginLeft: "5px" }}>{getmenu.maritalStatus} Age {getmenu.age}</span></div>

                                </div>

                                <div className="col-4 ">
                                  <div className="row margintop3">
                                    <div className="col-6 textleft"><span className="communtdrc-cit-text">Caste</span></div>
                                    <div className="col-6 textleft" style={{ whiteSpace: "nowrap" }}><span className="comudrct-digitxt">{getmenu.caste}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-6 textleft"><span className="communtdrc-cit-text">Lives In</span></div>
                                    <div className="col-6 textleft" style={{ whiteSpace: "nowrap" }}><span className="comudrct-digitxt">{getmenu.livesIn}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-6 textleft"><span className="communtdrc-cit-text">From</span></div>
                                    <div className="col-6 textleft" style={{ whiteSpace: "nowrap" }}><span className="comudrct-digitxt">{getmenu.from}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-6 textleft"><span className="communtdrc-cit-text">Height</span></div>
                                    <div className="col-6 textleft"><span className="comudrct-digitxt">{getmenu.height}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-6 textleft"><span className="communtdrc-cit-text">Weight</span></div>
                                    <div className="col-6 textleft"><span className="comudrct-digitxt">{getmenu.weight}</span></div>
                                  </div>


                                </div>
                                <div className="col-5">
                                  <div className="row margintop3">
                                    <div className="col-6 textleft"><span className="communtdrc-cit-text" style={{ whiteSpace: "nowrap" }}>Mother Tongue</span></div>
                                    <div className="col-6 textleft" style={{ whiteSpace: "nowrap" }}><span className="comudrct-digitxt">{getmenu.motherTongue}</span></div>
                                  </div>

                                  <div className="row margintop5">
                                    <div className="col-6 textleft"><span className="communtdrc-cit-text">DOB</span></div>
                                    <div className="col-6 textleft" style={{ whiteSpace: "nowrap" }}><span className="comudrct-digitxt">{getmenu.dob}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-6 textleft"><span className="communtdrc-cit-text" style={{ whiteSpace: "nowrap", marginLeft: "" }}>Edu. Qualification</span></div>
                                    <div className="col-6 textleft"><span className="comudrct-digitxt" style={{ whiteSpace: "nowrap" }}>{getmenu.eduQualification}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-6 textleft"><span className="communtdrc-cit-text">Working</span></div>
                                    <div className="col-6 textleft"><span className="comudrct-digitxt">{getmenu.working}</span></div>
                                  </div>
                                  <div className="row margintop5">
                                    <div className="col-6 textleft"><span className="communtdrc-cit-text" style={{ whiteSpace: "nowrap" }}>Specially Abled</span></div>
                                    <div className="col-6 textleft"><span className="comudrct-digitxt">{getmenu.speciallyAbled}</span></div>
                                  </div>
                                </div>

                              </div>
                              <div className="row textright">
                                    <div style={{left:"97%"}}>
                                    <Link to={`/MatrimonyViewProf/${getmenu.matrimonyId}`} ><p className="bluetextforlink" style={{ marginTop: "1px" }} >View Profile</p></Link>

                                    </div>
                                  </div>                            </Card>
                          )
                          )}
                        
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="link-32">
                      <div className="row tblclassmatsubscrip" style={{ marginTop: "20px" }}>
                        <Card className="margintop3" style={{ borderColor: "#B5924C", }}>
                          <div className="margintop3" style={{ marginLeft: '7px' }}><span style={{ fontSize: "14px", color: "#333333", fontWeight: "700", marginLeft: "7px" }}>Matrimony Subscription</span></div>

                          <div className="table-responsive fileexport pos-relative">
                            <MySubscriptionMatrimony />
                          </div>
                        </Card>


                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>

          </div>
        </div>
        <div className={businessDirect ? "col-6 matrimon-div heightforAll3Div communitydiv" : "hide"}>
          <div className="row Matrimony-Top-div textcenter">
            <p>Community Business Directory</p>
          </div>

          <div className="row">
            <div className="text-wrap">
              <div className="border" style={{ marginRight: "-11px", marginLeft: "-11px" }}>
                <Tab.Container id="left-tabs-example" defaultActiveKey="link-29">
                  <div className=" bg-gray-100 nav-bg horizontal-tabmenu ">

                    <Nav className="nav nav-tabs link-29 ">

                      <Nav.Item>
                        <Nav.Link eventKey="link-29" className="navlinkforverticaltab">
                          All Business Listing
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link eventKey="link-30" className="navlinkforverticaltab">
                          My Subscription
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link eventKey="link-31" className="navlinkforverticaltab">
                          My Business Profile
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link eventKey="link-32" className="navlinkforverticaltab">
                          Sponsor Business
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link eventKey="link-33" className="navlinkforverticaltab">
                          Job Postings
                        </Nav.Link>
                      </Nav.Item>

                    </Nav>

                  </div>

                  <Tab.Content className="card-body ">
                    <Tab.Pane eventKey="link-29">
                      <div className="">

                        <Tab.Container id="left-tabs-example" defaultActiveKey="link-29">
                          <div className='row'>
                            <div class="form-group has-search col-5">
                              <span class="fa fa-search form-control-feedback"></span>
                              <input type="text" class="form-control" placeholder="Search Business by Name or City" onChange={handleSearchChangeBusiness} style={{ fontSize: '12px' }} />
                            </div>
                            <div className="col-3">
                              <select id="inputState" name='filterBusiness'
                              onChange={handleChangeBussFilter}
                                className=" form-control" style={{ height: "34px", fontSize: "11px" }}>
                                <option selected value="All Category">All Category</option>
                                <option value="Contractor">Contractor</option>
                                <option value="Education">Education</option>
                                <option value="Event Organizer">Event Organizer</option>
                                <option value="Hotels">Hotels</option>
                                <option value="Home & Decor">Home & Decor</option>
                                <option value="Hospitals">Hospitals</option>
                                <option value="Hostels">Hostels</option>
                                <option value="HealthCare">HealthCare</option>
                                <option value="Food">Food</option>
                                <option value="Real Estate">Real Estate</option>
                                <option value="Transport">Transport</option>
                                <option value="Travel">Travel</option>
                                <option value="Women Enterprenueurs">Women Enterprenueurs</option>


                              </select>
                            </div>
                            <div className="col-3" style={{whiteSpace:"nowrap",display:"inline-flex"}}>
              
              
              
              
            </div>
                          </div>
                          <div className="row  busnessdrectory" style={{ marginTop: "20px" }}>
                            {
                              allbusinessList.map((getmenu, index) => (
                                <div className="col-lg-6 col-sm-6 col-md-6">

                                  <Card className="margintop3 boxshadow" style={{ borderColor: "#B5924C", width: "327px",height:"313px" ,paddingBottom:"5px"}} >
                                    <div className="row margintop3" style={{ paddingLeft: "0px" }}>
                                      <div className="col-lg-4 col-sm-12-col-md-12">
                                        <SimpleImageSlider
                                          width={100}
                                          height={90}
                                          images={getmenu.images}
                                          showBullets={false}
                                          showNavs={false}
                                          autoPlay={true}
                                          loop={true}
                                          startIndex={0}
                                          useGPURender={true}
                                          style={{ borderRadius: "4px", marginLeft: "-5px" }}
                                          className='rsis-image'
                                        />

                                      </div>
                                      <div className="col-lg-8 col-sm-12 col-md-12">
                                        <div><span style={{ fontSize: "14px", color: "#555555", fontWeight: "600", marginLeft: "0px" }}>{getmenu.buisnessName}</span></div>
                                        <div><span className="message-text">{getmenu.buisnessType}</span>
                                        </div>
                                        <div><span className="message-text" style={{ fontStyle: "normal" }}>{getmenu.addressLine1} </span></div>
                                        <div><span className="message-text" style={{ fontStyle: "normal" }}> {getmenu.addressLine2}</span></div>
                                        <div><span className="message-text" style={{ fontStyle: "normal" }}> {getmenu.landmark} </span></div>
                                        <div><span className="message-text" style={{ fontStyle: "normal" }}>  {getmenu.city},{getmenu.country}</span></div>


                                      </div>

                                    </div>
                                    <div className="row bussbodertop margintop1" >
                                      <div className="col-lg-6 col-sm-12 col-md-12"> <span className="communtdrc-cit-text" style={{ fontSize: "11px" }}>Owner</span></div>
                                      <div className="col-lg-6 col-sm-12 col-md-12" > <span className="comudrct-digitxt" style={{ fontSize: "11px" }}>{getmenu.userName}</span></div>
                                      

                                    </div>
                                    <div className="row  margintop1" >
                                      
                                      <div className="col-lg-6 col-sm-12 col-md-12"> <span className="communtdrc-cit-text" style={{ fontSize: "11px" }}>Business Contact</span></div>
                                      <div className="col-lg-6 col-sm-12 col-md-12"> <span className="comudrct-digitxt" style={{ fontSize: "11px" }}>{getmenu.primaryBuisnessCountryCode}/{getmenu.primaryBuisnessMobileNumber}</span></div>

                                    </div>

                                    <div className="row  margintop1" >
                                      <span className="communtdrc-cit-text " style={{ fontSize: "13px" }}>Deals in</span>

                                    </div>
                                    <div className="row  margintop1" >
                                      <span className="comudrct-digitxt textShowondotBuss" style={{ fontSize: "11px", fontStyle: "italic" }}>{getmenu.dealsIn}
                                      </span>

                                    </div>
                                    <div className="row" >
                                    <Link to={`/BusinessViewProfile/${getmenu.buisnessId}`}><span className="comudrct-digitxt" style={{ color: "blue", float: "right", fontSize: "11px",marginTop:"5px" ,right:"0",bottom:"2",display:"flex",alignItems:"flex-end"}} >know More</span></Link> 

                                    </div>

                                  </Card>
                                </div>
                              )
                              )}
                             {/* <div className="col-lg-6 col-sm-6 col-md-6">
                      <Card className="margintop3 boxshadow" style={{borderColor:"#B5924C",width:"327px"}} >
                       <div className="row margintop3" style={{paddingLeft:"0px"}}>
                        <div className="col-lg-4 col-sm-12-col-md-12">
                        <SimpleImageSlider
        width={100}
        height={90}
        images={images}
        showBullets={false}
        showNavs={false}
        autoPlay={true}
        loop={true}
        startIndex={0}
        useGPURender={true}
        style={{borderRadius:"4px",marginLeft:"-5px"}}
        className='rsis-image'
      />

                        </div>
                        <div className="col-lg-8 col-sm-12 col-md-12">
                       <div><span style={{fontSize:"14px",color:"#555555",fontWeight:"600",marginLeft:"0px"}}>Radha Krushna Pure Veg</span></div> 
                       <div style={{whiteSpace:"nowrap"}}><span className="message-text">Restaurant</span>                       
                       <span className="complet-name" style={{backgroundColor:"#214B0D",marginLeft:"5px",fontSize:"10px"}}>Women Enterpreneur</span>
</div>
                       <div><span className="message-text" style={{fontStyle:"normal"}}>Sinhgad Road Manik Baug Pune,India</span></div>

                        
                        </div>

                       </div>
                       <div className="row bussbodertop margintop1" >
                       <div className="col-lg-3 col-sm-12 col-md-12"> <span className="communtdrc-cit-text" style={{fontSize:"11px"}}>Owner</span></div>
                       <div className="col-lg-3 col-sm-12 col-md-12" > <span className="comudrct-digitxt" style={{fontSize:"11px"}}>Sagar Naidu</span></div>
                       <div className="col-lg-3 col-sm-12 col-md-12"> <span className="communtdrc-cit-text" style={{fontSize:"11px"}}>Business Contact</span></div>
                       <div className="col-lg-3 col-sm-12 col-md-12"> <span className="comudrct-digitxt" style={{fontSize:"11px"}}>+91/9923474723</span></div>

                       </div>
                       <div className="row  margintop1" >
                        <span className="communtdrc-cit-text" style={{fontSize:"13px"}}>Deals in</span>

             </div>
             <div className="row  margintop1" >
                        <span className="comudrct-digitxt" style={{fontSize:"11px",fontStyle:"italic"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                        Aenean euismod bibendum laoreet.</span><span className="comudrct-digitxt" style={{color:"blue",float:"right",fontSize:"11px"}} onClick={redirectMybussProfilemain}>Read More</span>

             </div>
                       
                    </Card>

                      </div>  */}

                          </div>
                          {/* <div className="row margintop1 busnessdrectory" style={{ marginTop: "20px" }}>
                            <div className="col-lg-6 col-sm-6 col-md-6">
                              <Card className=" boxshadow" style={{ borderColor: "#B5924C", width: "327px" }} >
                                <div className="row margintop3" style={{ paddingLeft: "0px" }}>
                                  <div className="col-lg-4 col-sm-12-col-md-12">
                                    <SimpleImageSlider
                                      width={100}
                                      height={90}
                                      images={images}
                                      showBullets={false}
                                      showNavs={false}
                                      autoPlay={true}
                                      startIndex={1}
                                      style={{ borderRadius: "4px", marginLeft: "-5px" }}
                                      className='rsis-image'
                                    />

                                  </div>
                                  <div className="col-lg-8 col-sm-12 col-md-12">
                                    <div><span style={{ fontSize: "14px", color: "#555555", fontWeight: "600", marginLeft: "0px" }}>Radha Krushna Pure Veg</span></div>
                                    <div><span className="message-text">Restaurant</span></div>
                                    <div><span className="message-text" style={{ fontStyle: "normal" }}>Sinhgad Road Manik Baug Pune,India</span></div>


                                  </div>

                                </div>
                                <div className="row bussbodertop margintop1" >
                                  <div className="col-lg-3 col-sm-12 col-md-12"> <span className="communtdrc-cit-text" style={{ fontSize: "11px" }}>Owner</span></div>
                                  <div className="col-lg-3 col-sm-12 col-md-12" > <span className="comudrct-digitxt" style={{ fontSize: "11px" }}>Sagar Naidu</span></div>
                                  <div className="col-lg-3 col-sm-12 col-md-12"> <span className="communtdrc-cit-text" style={{ fontSize: "11px" }}>Business Contact</span></div>
                                  <div className="col-lg-3 col-sm-12 col-md-12"> <span className="comudrct-digitxt" style={{ fontSize: "11px" }}>+91/9923474723</span></div>

                                </div>
                                <div className="row  margintop1" >
                                  <span className="communtdrc-cit-text" style={{ fontSize: "13px" }}>Deals in</span>

                                </div>
                                <div className="row  margintop1" >
                                  <span className="comudrct-digitxt" style={{ fontSize: "11px", fontStyle: "italic" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    Aenean euismod bibendum laoreet.</span><span className="comudrct-digitxt" style={{ color: "blue", float: "right", fontSize: "11px" }} onClick={redirectMybussProfilemain}>Read More</span>

                                </div>

                              </Card>

                            </div>
                            <div className="col-lg-6 col-sm-6 col-md-6">
                              <Card className=" boxshadow" style={{ borderColor: "#B5924C", width: "327px" }} >
                                <div className="row margintop3" style={{ paddingLeft: "0px" }}>
                                  <div className="col-lg-4 col-sm-12-col-md-12">
                                    <SimpleImageSlider
                                      width={100}
                                      height={90}
                                      images={images}
                                      showBullets={false}
                                      showNavs={false}
                                      autoPlay={true}
                                      startIndex={1}
                                      style={{ borderRadius: "4px", marginLeft: "-5px" }}
                                      className='rsis-image'
                                    />

                                  </div>
                                  <div className="col-lg-8 col-sm-12 col-md-12">
                                    <div><span style={{ fontSize: "14px", color: "#555555", fontWeight: "600", marginLeft: "0px" }}>Radha Krushna Pure Veg</span></div>
                                    <div><span className="message-text">Restaurant</span></div>
                                    <div><span className="message-text" style={{ fontStyle: "normal" }}>Sinhgad Road Manik Baug Pune,India</span></div>


                                  </div>

                                </div>
                                <div className="row bussbodertop margintop1" >
                                  <div className="col-lg-3 col-sm-12 col-md-12"> <span className="communtdrc-cit-text" style={{ fontSize: "11px" }}>Owner</span></div>
                                  <div className="col-lg-3 col-sm-12 col-md-12" > <span className="comudrct-digitxt" style={{ fontSize: "11px" }}>Sagar Naidu</span></div>
                                  <div className="col-lg-3 col-sm-12 col-md-12"> <span className="communtdrc-cit-text" style={{ fontSize: "11px" }}>Business Contact</span></div>
                                  <div className="col-lg-3 col-sm-12 col-md-12"> <span className="comudrct-digitxt" style={{ fontSize: "11px" }}>+91/9923474723</span></div>

                                </div>
                                <div className="row  margintop1" >
                                  <span className="communtdrc-cit-text" style={{ fontSize: "13px" }}>Deals in</span>

                                </div>
                                <div className="row  margintop1" >
                                  <span className="comudrct-digitxt" style={{ fontSize: "11px", fontStyle: "italic" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    Aenean euismod bibendum laoreet.</span><span className="comudrct-digitxt" style={{ color: "blue", float: "right", fontSize: "11px" }} onClick={redirectMybussProfilemain}>Read More</span>

                                </div>

                              </Card>

                            </div>

                          </div>
                          <div className="row margintop1 busnessdrectory" style={{ marginTop: "20px" }}>
                            <div className="col-lg-6 col-sm-6 col-md-6">
                              <Card className=" boxshadow" style={{ borderColor: "#B5924C", width: "327px" }} >
                                <div className="row margintop3" style={{ paddingLeft: "0px" }}>
                                  <div className="col-lg-4 col-sm-12-col-md-12">
                                    <SimpleImageSlider
                                      width={100}
                                      height={90}
                                      images={images}
                                      showBullets={false}
                                      showNavs={false}
                                      autoPlay={true}
                                      startIndex={1}
                                      style={{ borderRadius: "4px", marginLeft: "-5px" }}
                                      className='rsis-image'
                                    />

                                  </div>
                                  <div className="col-lg-8 col-sm-12 col-md-12">
                                    <div><span style={{ fontSize: "14px", color: "#555555", fontWeight: "600", marginLeft: "0px" }}>Radha Krushna Pure Veg</span></div>
                                    <div><span className="message-text">Restaurant</span></div>
                                    <div><span className="message-text" style={{ fontStyle: "normal" }}>Sinhgad Road Manik Baug Pune,India</span></div>


                                  </div>

                                </div>
                                <div className="row bussbodertop margintop1" >
                                  <div className="col-lg-3 col-sm-12 col-md-12"> <span className="communtdrc-cit-text" style={{ fontSize: "11px" }}>Owner</span></div>
                                  <div className="col-lg-3 col-sm-12 col-md-12" > <span className="comudrct-digitxt" style={{ fontSize: "11px" }}>Sagar Naidu</span></div>
                                  <div className="col-lg-3 col-sm-12 col-md-12"> <span className="communtdrc-cit-text" style={{ fontSize: "11px" }}>Business Contact</span></div>
                                  <div className="col-lg-3 col-sm-12 col-md-12"> <span className="comudrct-digitxt" style={{ fontSize: "11px" }}>+91/9923474723</span></div>

                                </div>
                                <div className="row  margintop1" >
                                  <span className="communtdrc-cit-text" style={{ fontSize: "13px" }}>Deals in</span>

                                </div>
                                <div className="row  margintop1" >
                                  <span className="comudrct-digitxt" style={{ fontSize: "11px", fontStyle: "italic" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    Aenean euismod bibendum laoreet.</span><span className="comudrct-digitxt" style={{ color: "blue", float: "right", fontSize: "11px" }} onClick={redirectMybussProfilemain}>Read More</span>

                                </div>

                              </Card>

                            </div>
                            <div className="col-lg-6 col-sm-6 col-md-6">
                              <Card className=" boxshadow" style={{ borderColor: "#B5924C", width: "327px" }} >
                                <div className="row margintop3" style={{ paddingLeft: "0px" }}>
                                  <div className="col-lg-4 col-sm-12-col-md-12">
                                    <SimpleImageSlider
                                      width={100}
                                      height={90}
                                      images={images}
                                      showBullets={false}
                                      showNavs={false}
                                      autoPlay={true}
                                      startIndex={1}
                                      style={{ borderRadius: "4px", marginLeft: "-5px" }}
                                      className='rsis-image'
                                    />

                                  </div>
                                  <div className="col-lg-8 col-sm-12 col-md-12">
                                    <div><span style={{ fontSize: "14px", color: "#555555", fontWeight: "600", marginLeft: "0px" }}>Radha Krushna Pure Veg</span></div>
                                    <div><span className="message-text">Restaurant</span></div>
                                    <div><span className="message-text" style={{ fontStyle: "normal" }}>Sinhgad Road Manik Baug Pune,India</span></div>


                                  </div>

                                </div>
                                <div className="row bussbodertop margintop1" >
                                  <div className="col-lg-3 col-sm-12 col-md-12"> <span className="communtdrc-cit-text" style={{ fontSize: "11px" }}>Owner</span></div>
                                  <div className="col-lg-3 col-sm-12 col-md-12" > <span className="comudrct-digitxt" style={{ fontSize: "11px" }}>Sagar Naidu</span></div>
                                  <div className="col-lg-3 col-sm-12 col-md-12"> <span className="communtdrc-cit-text" style={{ fontSize: "11px" }}>Business Contact</span></div>
                                  <div className="col-lg-3 col-sm-12 col-md-12"> <span className="comudrct-digitxt" style={{ fontSize: "11px" }}>+91/9923474723</span></div>

                                </div>
                                <div className="row  margintop1" >
                                  <span className="communtdrc-cit-text" style={{ fontSize: "13px" }}>Deals in</span>

                                </div>
                                <div className="row  margintop1" >
                                  <span className="comudrct-digitxt" style={{ fontSize: "11px", fontStyle: "italic" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    Aenean euismod bibendum laoreet.</span><span className="comudrct-digitxt" style={{ color: "blue", float: "right", fontSize: "11px" }} onClick={redirectMybussProfilemain}>Read More</span>

                                </div>

                              </Card>

                            </div>

                          </div>
                          <div className="row margintop1 busnessdrectory" style={{ marginTop: "20px" }}>
                            <div className="col-lg-6 col-sm-6 col-md-6">
                              <Card className=" boxshadow" style={{ borderColor: "#B5924C", width: "327px" }} >
                                <div className="row margintop3" style={{ paddingLeft: "0px" }}>
                                  <div className="col-lg-4 col-sm-12-col-md-12">
                                    <SimpleImageSlider
                                      width={100}
                                      height={90}
                                      images={images}
                                      showBullets={false}
                                      showNavs={false}
                                      autoPlay={true}
                                      startIndex={1}
                                      style={{ borderRadius: "4px", marginLeft: "-5px" }}
                                      className='rsis-image'
                                    />

                                  </div>
                                  <div className="col-lg-8 col-sm-12 col-md-12">
                                    <div><span style={{ fontSize: "14px", color: "#555555", fontWeight: "600", marginLeft: "0px" }}>Radha Krushna Pure Veg</span></div>
                                    <div><span className="message-text">Restaurant</span></div>
                                    <div><span className="message-text" style={{ fontStyle: "normal" }}>Sinhgad Road Manik Baug Pune,India</span></div>


                                  </div>

                                </div>
                                <div className="row bussbodertop margintop1" >
                                  <div className="col-lg-3 col-sm-12 col-md-12"> <span className="communtdrc-cit-text" style={{ fontSize: "11px" }}>Owner</span></div>
                                  <div className="col-lg-3 col-sm-12 col-md-12" > <span className="comudrct-digitxt" style={{ fontSize: "11px" }}>Sagar Naidu</span></div>
                                  <div className="col-lg-3 col-sm-12 col-md-12"> <span className="communtdrc-cit-text" style={{ fontSize: "11px" }}>Business Contact</span></div>
                                  <div className="col-lg-3 col-sm-12 col-md-12"> <span className="comudrct-digitxt" style={{ fontSize: "11px" }}>+91/9923474723</span></div>

                                </div>
                                <div className="row  margintop1" >
                                  <span className="communtdrc-cit-text" style={{ fontSize: "13px" }}>Deals in</span>

                                </div>
                                <div className="row  margintop1" >
                                  <span className="comudrct-digitxt" style={{ fontSize: "11px", fontStyle: "italic" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    Aenean euismod bibendum laoreet.</span><span className="comudrct-digitxt" style={{ color: "blue", float: "right", fontSize: "11px" }} onClick={redirectMybussProfilemain}>Read More</span>

                                </div>

                              </Card>

                            </div>
                            <div className="col-lg-6 col-sm-6 col-md-6">
                              <Card className=" boxshadow" style={{ borderColor: "#B5924C", width: "327px" }} >
                                <div className="row margintop3" style={{ paddingLeft: "0px" }}>
                                  <div className="col-lg-4 col-sm-12-col-md-12">
                                    <SimpleImageSlider
                                      width={100}
                                      height={90}
                                      images={images}
                                      showBullets={false}
                                      showNavs={false}
                                      autoPlay={true}
                                      startIndex={1}
                                      style={{ borderRadius: "4px", marginLeft: "-5px" }}
                                      className='rsis-image'
                                    />

                                  </div>
                                  <div className="col-lg-8 col-sm-12 col-md-12">
                                    <div><span style={{ fontSize: "14px", color: "#555555", fontWeight: "600", marginLeft: "0px" }}>Radha Krushna Pure Veg</span></div>
                                    <div><span className="message-text">Restaurant</span></div>
                                    <div><span className="message-text" style={{ fontStyle: "normal" }}>Sinhgad Road Manik Baug Pune,India</span></div>


                                  </div>

                                </div>
                                <div className="row bussbodertop margintop1" >
                                  <div className="col-lg-3 col-sm-12 col-md-12"> <span className="communtdrc-cit-text" style={{ fontSize: "11px" }}>Owner</span></div>
                                  <div className="col-lg-3 col-sm-12 col-md-12" > <span className="comudrct-digitxt" style={{ fontSize: "11px" }}>Sagar Naidu</span></div>
                                  <div className="col-lg-3 col-sm-12 col-md-12"> <span className="communtdrc-cit-text" style={{ fontSize: "11px" }}>Business Contact</span></div>
                                  <div className="col-lg-3 col-sm-12 col-md-12"> <span className="comudrct-digitxt" style={{ fontSize: "11px" }}>+91/9923474723</span></div>

                                </div>
                                <div className="row  margintop1" >
                                  <span className="communtdrc-cit-text" style={{ fontSize: "13px" }}>Deals in</span>

                                </div>
                                <div className="row  margintop1" >
                                  <span className="comudrct-digitxt" style={{ fontSize: "11px", fontStyle: "italic" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    Aenean euismod bibendum laoreet.</span><span className="comudrct-digitxt" style={{ color: "blue", float: "right", fontSize: "11px" }} onClick={redirectMybussProfilemain}>Read More</span>

                                </div>

                              </Card>

                            </div>

                          </div> */}


                        </Tab.Container>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="link-30">
                      <div className="row tblclassmatsubscrip" style={{ marginTop: "20px" }}>
                        <Card className="margintop3" style={{ borderColor: "#B5924C", }}>
                          <div className="margintop3" style={{ marginLeft: '7px' }}><span style={{ fontSize: "14px", color: "#333333", fontWeight: "700", marginLeft: "7px" }}>My Business Subscription</span></div>

                          <div className="table-responsive fileexport pos-relative">

                            <MySubscription />

                          </div>
                        </Card>


                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="link-31">
                      <div className="row " style={{ marginTop: "20px" }}>
                        <Card className="margintop3" style={{ borderColor: "#B5924C", }}>
                          <div className="margintop3" style={{ marginLeft: '0px' }}><span style={{ fontSize: "13px", color: "#333333", fontWeight: "700", marginLeft: "7px" }}>My Business Profile</span>
                            <Link to={`/EditBusinessProfile/${myProfilebusinessList.buisnessId}`}><span className="comudrct-digitxt" style={{ color: "blue", marginLeft: "20px" }}>Edit Business</span></Link>
                          </div>

                          <div className="row margintop3">
                            <div className="col-lg-4 col-sm-12 col-md-4"><span className="communtdrc-cit-text">Business Name</span></div>
                            <div className="col-lg-8 col-sm-12 col-md-4"><span className="comudrct-digitxt">{myProfilebusinessList.buisnessName}</span></div>
                          </div>
                          <div className="row margintop3">
                            <div className="col-lg-4 col-sm-12 col-md-4"><span className="communtdrc-cit-text">Business Category</span></div>
                            <div className="col-lg-8 col-sm-12 col-md-4"><span className="comudrct-digitxt">{myProfilebusinessList.buisnessType}</span></div>
                          </div>
                          <div className="row margintop3">
                            <div className="col-lg-4 col-sm-12 col-md-4"><span className="communtdrc-cit-text"> Business Address</span></div>
                            <div className="col-lg-8 col-sm-12 col-md-4">
                              <span className="comudrct-digitxt">{myProfilebusinessList.addressLine1} {myProfilebusinessList.addressLine2}</span>
                              <div className=""><span className="comudrct-digitxt">{myProfilebusinessList.landmark}</span></div>
                              <div className=""><span className="comudrct-digitxt">{myProfilebusinessList.city} {myProfilebusinessList.pinCode}</span></div>

                            </div>
                          </div>
                          <div className="row margintop3">
                            <div className="col-lg-4 col-sm-12 col-md-4"><span className="communtdrc-cit-text">Business Owner Name</span></div>
                            <div className="col-lg-8 col-sm-12 col-md-4"><span className="comudrct-digitxt">{myProfilebusinessList.userName}</span></div>
                          </div>
                          <div className="row margintop3">
                            <div className="col-lg-4 col-sm-12 col-md-4"><span className="communtdrc-cit-text">Business Contact Number</span></div>
                            <div className="col-lg-8 col-sm-12 col-md-4"><span className="comudrct-digitxt">{myProfilebusinessList.primaryBuisnessCountryCode}/{myProfilebusinessList.primaryBuisnessMobileNumber} {myProfilebusinessList.secondaryBuisnessMobileNumber}</span></div>
                          </div>
                          <div className="row margintop3">
                            <div className="col-lg-4 col-sm-12 col-md-4"><span className="communtdrc-cit-text">Deals In</span></div>

                          </div>
                          <div className="row margintop1">
                            <div className="col-lg-11 col-sm-12 col-md-4"><span className="comudrct-digitxt">
                              {myProfilebusinessList.dealsIn}
                            </span></div>
                          </div>
                          <div className="row margintop3">
                            <div className="col-lg-4 col-sm-12 col-md-4"><span className="communtdrc-cit-text">Business Establishment Date</span></div>
                            <div className="col-lg-8 col-sm-12 col-md-4"><span className="comudrct-digitxt">{myProfilebusinessList.registerationDate}</span></div>
                          </div>
                          <div className="row margintop3">
                            <div className="col-lg-4 col-sm-12 col-md-4"><span className="communtdrc-cit-text">Type of Establishment</span></div>
                            <div className="col-lg-8 col-sm-12 col-md-4"><span className="comudrct-digitxt">{myProfilebusinessList.buisnessCategory}</span></div>
                          </div>
                          <div className="row margintop3">
                            <div className="col-lg-4 col-sm-12 col-md-4"><span className="communtdrc-cit-text">GST Registered</span></div>
                            <div className="col-lg-8 col-sm-12 col-md-4"><span className="comudrct-digitxt">{myProfilebusinessList.registeredGst}</span></div>
                          </div>
                          <div className="row margintop3">
                            <div className="col-lg-4 col-sm-12 col-md-4"><span className="communtdrc-cit-text">GST Number</span></div>
                            <div className="col-lg-8 col-sm-12 col-md-4"><span className="comudrct-digitxt">{myProfilebusinessList.gstNumber}</span>
                              {/* <span className="complet-name" style={{fontStyle:"italic",marginLeft:"20px",fontSize:"13px"}}>Verified</span> */}
                            </div>
                          </div>
                          <div className="row margintop3">
                            <div className="col-lg-4 col-sm-12 col-md-4"><span className="communtdrc-cit-text">No. of Employees</span></div>
                            <div className="col-lg-8 col-sm-12 col-md-4"><span className="comudrct-digitxt">{myProfilebusinessList.numberOfEmployees}</span></div>
                          </div>
                          <div className="row margintop3">
                            <div className="col-lg-4 col-sm-12 col-md-4"><span className="communtdrc-cit-text">Business Brief</span></div>

                          </div>
                          <div className="row margintop1">
                            <div className="col-lg-11 col-sm-12 col-md-4"><span className="comudrct-digitxt">
                              {myProfilebusinessList.aboutBuisness}
                            </span>

                            </div>
                          </div>
                          <div className="row margintop3">
                            <div className="col-lg-4 col-sm-12 col-md-4"><span className="communtdrc-cit-text">Business Timings</span></div>

                          </div>
                          <div className="row margintop3">
                            <div className="col-lg-1"></div>
                            <div className="col-lg-11 col-sm-12 col-md-12">
                              <Card style={{ borderColor: "#333333" }}>
                                {
                                  myProfilebusinessList?.buisnessTime?.map((getmenu, index) =>  (
                                    <div className=" margintop1">
                                      {
                                        getmenu.dayOff == false ? (
                                          <div className="row">
                                            <div className="col-lg-2 col-sm-12 col-md-12"><span className="communtdrc-cit-text"style={{whiteSpace:"nowrap"}}>{getmenu.day}</span></div>
                                            <div className="col-lg-5 col-sm-12 col-md-12"style={{marginLeft:"19px"}}>
                                             {getmenu.startTime1==""||getmenu.startTime1==null?(<span className="comudrct-digitxt">Not mentioned </span>):(<span className="comudrct-digitxt">{getmenu.startTime1} </span>)} 
                                              <span className="communtdrc-cit-text" style={{ marginLeft: "5px" }}>To</span>
                                              {getmenu.endTime1==""||getmenu.endTime1==null?(<span className="comudrct-digitxt" style={{ marginLeft: "5px" }}>Not mentioned </span>):(<span className="comudrct-digitxt"style={{ marginLeft: "5px" }}>{getmenu.endTime1} </span>)} 

                                            </div>
                                            <div className="col-lg-5 col-sm-12 col-md-12" style={{marginLeft:"-19px"}}>
                                            {getmenu.startTime2==""||getmenu.startTime2==null?(<span className="comudrct-digitxt">Not mentioned </span>):(<span className="comudrct-digitxt">{getmenu.startTime2} </span>)} 

                                              <span className="communtdrc-cit-text" style={{ marginLeft: "5px" }}>To</span>
                                              {getmenu.endTime2==""||getmenu.endTime2==null?(<span className="comudrct-digitxt" style={{ marginLeft: "5px" }}>Not mentioned </span>):(<span className="comudrct-digitxt"style={{ marginLeft: "5px" }}>{getmenu.endTime2} </span>)} 

                                            </div>
                                          </div>
                                        ) : (
                                          <div className="row">
                                            <div className="col-lg-2 col-sm-12 col-md-12"><span className="communtdrc-cit-text">{getmenu.day}</span></div>
                                            <div className="col-lg-5 col-sm-12 col-md-12">
                                              <span className="comudrct-digitxt"style={{marginLeft:"19px"}}>Closed </span>

                                            </div>
                                          </div>
                                        )
                                      }

                                    </div>
                                  )
                                  )}

                                {/* <div className="row">
<div className="col-lg-2 col-sm-12 col-md-12"><span className="communtdrc-cit-text">Tue</span></div>
<div className="col-lg-5 col-sm-12 col-md-12">
  <span className="comudrct-digitxt">9:00 AM </span>
  <span className="communtdrc-cit-text"style={{marginLeft:"5px"}}>To</span>
  <span className="comudrct-digitxt"style={{marginLeft:"5px"}}>2:00 PM </span>
</div>
<div className="col-lg-5 col-sm-12 col-md-12">
  <span className="comudrct-digitxt">4:00 PM </span>
  <span className="communtdrc-cit-text"style={{marginLeft:"5px"}}>To</span>
  <span className="comudrct-digitxt" style={{marginLeft:"5px"}}>9:00 PM </span>
</div>
</div>
 <div className="row">
<div className="col-lg-2 col-sm-12 col-md-12"><span className="communtdrc-cit-text">Wed</span></div>
<div className="col-lg-5 col-sm-12 col-md-12">
  <span className="comudrct-digitxt">9:00 AM </span>
  <span className="communtdrc-cit-text"style={{marginLeft:"5px"}}>To</span>
  <span className="comudrct-digitxt"style={{marginLeft:"5px"}}>2:00 PM </span>
</div>
<div className="col-lg-5 col-sm-12 col-md-12">
  <span className="comudrct-digitxt">4:00 PM </span>
  <span className="communtdrc-cit-text"style={{marginLeft:"5px"}}>To</span>
  <span className="comudrct-digitxt" style={{marginLeft:"5px"}}>9:00 PM </span>
</div>
</div>
<div className="row">
<div className="col-lg-2 col-sm-12 col-md-12"><span className="communtdrc-cit-text">Thir</span></div>
<div className="col-lg-5 col-sm-12 col-md-12">
  <span className="comudrct-digitxt">9:00 AM </span>
  <span className="communtdrc-cit-text"style={{marginLeft:"5px"}}>To</span>
  <span className="comudrct-digitxt"style={{marginLeft:"5px"}}>2:00 PM </span>
</div>
<div className="col-lg-5 col-sm-12 col-md-12">
  <span className="comudrct-digitxt">4:00 PM </span>
  <span className="communtdrc-cit-text"style={{marginLeft:"5px"}}>To</span>
  <span className="comudrct-digitxt" style={{marginLeft:"5px"}}>9:00 PM </span>
</div>
</div>
<div className="row">
<div className="col-lg-2 col-sm-12 col-md-12"><span className="communtdrc-cit-text">Fri</span></div>
<div className="col-lg-5 col-sm-12 col-md-12">
  <span className="comudrct-digitxt">9:00 AM </span>
  <span className="communtdrc-cit-text"style={{marginLeft:"5px"}}>To</span>
  <span className="comudrct-digitxt"style={{marginLeft:"5px"}}>2:00 PM </span>
</div>
<div className="col-lg-5 col-sm-12 col-md-12">
  <span className="comudrct-digitxt">4:00 PM </span>
  <span className="communtdrc-cit-text"style={{marginLeft:"5px"}}>To</span>
  <span className="comudrct-digitxt" style={{marginLeft:"5px"}}>9:00 PM </span>
</div>
</div>
<div className="row">
<div className="col-lg-2 col-sm-12 col-md-12"><span className="communtdrc-cit-text">Sat</span></div>
<div className="col-lg-5 col-sm-12 col-md-12">
  <span className="comudrct-digitxt">9:00 AM </span>
  <span className="communtdrc-cit-text"style={{marginLeft:"5px"}}>To</span>
  <span className="comudrct-digitxt"style={{marginLeft:"5px"}}>2:00 PM </span>
</div>
<div className="col-lg-5 col-sm-12 col-md-12">
  <span className="comudrct-digitxt">4:00 PM </span>
  <span className="communtdrc-cit-text"style={{marginLeft:"5px"}}>To</span>
  <span className="comudrct-digitxt" style={{marginLeft:"5px"}}>9:00 PM </span>
</div>
</div>
<div className="row">
<div className="col-lg-2 col-sm-12 col-md-12"><span className="communtdrc-cit-text">Sun</span></div>
<div className="col-lg-3 col-sm-12 col-md-12">
  <span className="comudrct-digitxt">Closed </span>
  
</div>
</div> */}
                                <div className="row margintop1"></div>
                              </Card>
                            </div>
                            <div className="col-lg-1"></div>

                          </div>
                          <div className="row margintop1">
                            <div className=""><span className="communtdrc-cit-text">Business Photos</span></div>

                          </div>
                          {/* <div className="row margintop1">
                            {
                              myProfilebusinessList?.images?.map((getmenu, index) => (
                                <div className="col-lg-6 col-sm-12 col-md-12"><img src={getmenu} width='322px' /></div>
                              )
                              )}
                          </div> */}
<div className="row margintop3">

<div className={myProfilebusinessList.profilePhotoPath !=null ||myProfilebusinessList.profilePhotoPath !=""?"col-lg-4 col-sm-12 col-md-12":"hide"}>
  
<div className=" margintop1" style={{borderColor:"",border:"none"}}>

      <div className="row margintop1">
      <img src={myProfilebusinessList.profilePhotoPath} id='profpicdisablesavematmyprof1' className='' style={{width:"235px"}}/>

      </div>
      
      
      
    

</div>
</div>

</div>
                <div className="row margintop3">

                        <div className={myProfilebusinessList.photoOnePath !=null ||myProfilebusinessList.photoOnePath !=""?"col-lg-4 col-sm-12 col-md-12":"hide"}>
                          
                        <div className=" margintop1" style={{borderColor:"",border:"none"}}>
                       
                              <div className="row margintop1">
                              <img src={myProfilebusinessList.photoOnePath} id='profpicdisablesavematmyprof1' className='' style={{width:"235px"}}/>

                              </div>
                              
                              
                              
                            

      </div>
                        </div>
                        <div className={myProfilebusinessList.photoTwoPath !=null ||myProfilebusinessList.photoTwoPath !="" ?"col-lg-4 col-sm-12 col-md-12":"hide"}>
                        <div className=""><span style={{fontSize:"14px",color:"#F2F2F2",fontWeight:"700",marginLeft:"0px"}}></span></div>

                        <div className=" margintop1" style={{borderColor:"",border:"none"}}>
                        
                              
                              <div className="row margintop1">
                              <img src={myProfilebusinessList.photoTwoPath} id='profpicdisablesavematmyprof2'  className='margintop1' style={{width:"235px"}}/>

                              </div>
                          
                                

                          </div>
                        </div>
                        <div className={myProfilebusinessList.photoThreePath !=null ||myProfilebusinessList.photoThreePath !="" ?"col-lg-4 col-sm-12 col-md-12":"hide"}>
                          <div className=""><span style={{fontSize:"14px",color:"#F2F2F2",fontWeight:"700",marginLeft:"0px"}}></span></div>
                        <div className=" margintop1" style={{borderColor:"",border:"none"}}>
                       
                              <div className="row margintop1">
                              <img src={myProfilebusinessList.photoThreePath} id='profpicdisablesavematmyprof3' className=''style={{width:"235px"}}/>

                              </div>
                              
                              
                    </div>
                        </div>
                        
                      </div>
                      <div className="row margintop1">
                        
                        <div className={myProfilebusinessList.photoFourPath !=null|| myProfilebusinessList.photoFourPath !=""?"col-lg-4 col-sm-12 col-md-12":"hide"}>
                        <div className=""><span style={{fontSize:"14px",color:"#F2F2F2",fontWeight:"700",marginLeft:"0px"}}></span></div>

                        <div className=" margintop1" style={{borderColor:"",border:"none"}}>
                        
                              
                              <div className="row margintop1">
                              <img src={myProfilebusinessList.photoFourPath} id="profpicdisablesavematmyprof4" className='margintop1'/>

                              </div>
                          
                                

                          </div>
                        </div>
                        <div className={myProfilebusinessList.photoFivePath !=null|| myProfilebusinessList.photoFivePath !=""?"col-lg-4 col-sm-12 col-md-12":"hide"}>
                        <div className=""><span style={{fontSize:"14px",color:"#F2F2F2",fontWeight:"700",marginLeft:"0px"}}></span></div>

                        <div className=" margintop1" style={{borderColor:"",border:"none"}}>
                        
                              
                              <div className="row margintop1">
                              <img src={myProfilebusinessList.photoFivePath} id="profpicdisablesavematmyprof4" className='margintop1'/>

                              </div>
                          
                                

                          </div>
                        </div>
                        <div className={myProfilebusinessList.photoSixPath !=null|| myProfilebusinessList.photoSixPath !=""?"col-lg-4 col-sm-12 col-md-12":"hide"}>
                        <div className=""><span style={{fontSize:"14px",color:"#F2F2F2",fontWeight:"700",marginLeft:"0px"}}></span></div>

                        <div className=" margintop1" style={{borderColor:"",border:"none"}}>
                        
                              
                              <div className="row margintop1">
                              <img src={myProfilebusinessList.photoFourPath} id="profpicdisablesavematmyprof4" className='margintop1'/>

                              </div>
                          
                                

                          </div>
                        </div>
                        
                      </div>
                        </Card>


                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="link-32">
                      <div className="row " style={{ marginTop: "20px" }}>
                        <Card className="margintop3" style={{ borderColor: "#B5924C", }}>
                          <div className="margintop3" style={{ marginLeft: '0px' }}>
                            <span style={{ fontSize: "13px", color: "#333333", fontWeight: "700", marginLeft: "7px" }}>Sponsored Business

                            </span>
                          </div>
                          <div className="row">
                            <div className="col-lg-11 col-sm-12 col-md-4">
                              <span className="comudrct-digitxt">
                                Sponsor your business now with a minimal amount for a greater visibility of your business within the community. Sponsored businesses are displayed on homepage for maximum reach.
                              </span>
                            </div>

                          </div>
                          <div className="margintop3" style={{ marginLeft: '0px' }}>
                            <span style={{ fontSize: "13px", color: "#333333", fontWeight: "700", marginLeft: "7px" }}>Contact Details</span>
                          </div>
                          <div className="row">
                            <div className="col-lg-11 col-sm-12 col-md-4">
                              <span className="comudrct-digitxt">
                                To sponsor your business contact:-

                              </span>
                            </div>

                          </div>

                          <div className="row margintop3">
                            <div className="col-lg-4 col-sm-12 col-md-4"><span className="communtdrc-cit-text">Email:-</span></div>
                            <div className="col-lg-8 col-sm-12 col-md-4"><span className="comudrct-digitxt">business@naidusangham.com</span></div>
                          </div>
                          <div className="row margintop3">
                            <div className="col-lg-4 col-sm-12 col-md-4"><span className="communtdrc-cit-text">Mobile:-</span></div>
                            <div className="col-lg-8 col-sm-12 col-md-4"><span className="comudrct-digitxt">9823423424</span></div>
                          </div>



                        </Card>


                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="link-33">
                      <div className="row tblclassmatsubscrip" style={{ marginTop: "20px" }}>
                        <Card className="margintop3" style={{ borderColor: "#B5924C", }}>

                          <div className="margintop3" style={{ marginLeft: '0px' }}>
                            <Link to="/MyJobPostingMain" ><span className="comudrct-digitxt" style={{ fontSize: "13px", fontWeight: "700", marginLeft: "0px" }}>My Job Postings</span></Link>
                           {authlocalstoragedata.buisnessId ==0?(
                           <span className="comudrct-digitxt" style={{ fontSize: "13px", color: "gray", marginLeft: "10px", cursor:"not-allowed"}}>Add New Job Posting</span>):
                           (<Link to='/AddJobPosting'><span className="comudrct-digitxt" style={{ fontSize: "13px", color: "#0000ff", marginLeft: "10px" }}>Add New Job Posting</span></Link>
                           )
                           }
                          </div>

                          <div className="table-responsive fileexport pos-relative">

                            <DataTableExtensions {...tableData}>
                              <DataTable
                                columns={columns}
                                data={data}
                                noDataComponent="Data is Not Available" //or your component
                                striped
                                noHeader
                                defaultSortField="id"
                                defaultSortAsc={true}
                                pagination
                                highlightOnHover
                                print={false}
                                export={true}

                              />
                            </DataTableExtensions>

                          </div>
                        </Card>


                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>

          </div>
        </div>

        <div className="col-lg-3 col-sm-4 col-md-4 bloodreq-head-div heightforAll3Div right-div" style={{backgroundColor:"#ffffff"}}>
        <div className="row margintop1">
        <div className="borderbottom-radius margintop3">


  <div className="margintop1 "></div>
  <div className="radius-div" style={{ marginLeft: "-10px",paddingBottom:"0px" }} >
    <div className="textleft radiussub-div"><img src={bloddreq} style={{color:"#626262",height:"20px",width:"20px"}}/>  <span className="radius-heding" style={{marginLeft:"5px"}}> Urgent Blood Requirement</span>
    <span className="seealltxt" style={{marginLeft:"15px",cursor:"pointer"}} onClick={redirectToBloodReq}>See All</span>
    </div>
    <div className="row margintop3">
    <SliderPageNew />


                </div>
                <div className="row margintop1"></div>
                <div className="textcenter margintop3 radiussub-btn-div"><span className="radius-heding-invite" style={{marginLeft:"0px",fontSize:"14px",fontWeight:"500",color:"#ffffff",cursor:"pointer"}} onClick={togglePopupBlood}> Add New Requirement</span></div>

                {isOpenBloodPopup && <BloodRequirePopup

                   handleClose={togglePopupBlood}
             />}


  </div>
</div>

<div className="borderbottom-radius margintop3">


  <div className="margintop1 "></div>
  <div className="radius-div" style={{ marginLeft: "-10px",paddingBottom:"0px" }} >
    <div className="textleft radiussub-div" style={{paddingBottom:'2px'}}><MdBusinessCenter size="1.3em" color="#626262"/>    <span className="radius-heding" style={{marginLeft:"5px"}}> My Community Platforms</span><br/><div style={{fontStyle:"italic",marginLeft:"30px",marginTop:"-5px"}}><span className="slider-name" style={{fontStyle:"italic",marginLeft:"0px",marginTop:"-5px"}}>Sponsored</span></div>


    </div>

    <div className="row margintop3  ">
    <SponserBusiness />


                </div>
                <div className="row margintop1"></div>



  </div>
</div>
<div className="borderbottom-radius margintop3">


  <div className="margintop1 "></div>
  <div className="radius-div" style={{ marginLeft: "-10px" }} >
    <div className="textleft radiussub-div"><img src={jobopeningg} style={{width:"19px",height:"19px"}}/> <span className="radius-heding" style={{marginLeft:"5px"}}> Job Openings({jobPostingList && jobPostingList.length})</span><span className="seealltxt cpointer" style={{marginLeft:"15px"}} onClick={togglePopupJob}>See All</span></div>
    {isOpenJobPop && <JobAllSeePop

handleClose={togglePopupJob}
/>}
    {/* <div className="row">
      <div className="col-lg-4"></div>
      <div className="col-lg-2 col-sm-4 col-md-4">
        <span className="radius-heding">{members && members.length}</span></div>

    </div> */}
    <div className="row margintop3"></div>
    {/* {
                  jobPostingList.map((job, index) => ( */}
                    <div>
                      <div className="row minusleftmarg">
        <div className="col-7"><span className="radius-secobd-text" style={{textAlign:"right",color:"#797979"}}>Job Id</span></div>
        <div className="col-5 textleft"><span className="slider-name  " style={{marginLeft:"-22px"}}> 222111</span></div>

        </div>
        <div className="row minusleftmarg">
        <div className="col-7"><span className="radius-secobd-text" style={{textAlign:"right",color:"#797979"}}> Role/Position</span></div>
        <div className="col-5 textleft"><span className="slider-name  " style={{marginLeft:"-22px"}}> Business Manager</span></div>

        </div>
        <div className="row minusleftmarg">
        <div className="col-7"><span className="radius-secobd-text" style={{textAlign:"right",color:"#797979"}}> Date Posted</span></div>
        <div className="col-5 textleft"><span className="slider-name  " style={{marginLeft:"-22px"}}> 22 nov,2024</span></div>

        </div>
        <div className="row minusleftmarg">
        <div className="col-7"><span className="radius-secobd-text" style={{textAlign:"right",color:"#797979"}}>Location</span></div>
        <div className="col-5 textleft"><span className="slider-name  " style={{marginLeft:"-22px"}}> Pune</span></div>

        </div>
        <div className="row minusleftmarg">
        <div className="col-7"><span className="radius-secobd-text" style={{textAlign:"right",color:"#797979"}}>Educational Qualification:</span></div>
        <div className="col-5 textleft"><span className="slider-name  " style={{marginLeft:"-22px"}}> MBA</span></div>

        </div>


                      <div className="row margintop2">
                        <span className="radius-fourth-card1-text" style={{fontSize:"12px"}}>
                          {/* {job.jobDescription} */}Lorem Ipsum sit amet
                        </span>
                      </div>
                    </div>
                  {/* )
                  )} */}
{
     jobPostingList  && jobPostingList.length== 0 &&(
      <div className="row">
      <span className="slider-name " style={{paddingBottom:"5px"}}>No Job Requirements Currently</span>
      </div>
     )
}



  </div>
</div>
<div className="borderbottom-radius margintop3">


  <div className="margintop1 "></div>
  <div className="radius-div" style={{ marginLeft: "-10px" }} >
    <div className="textleft radiussub-div"><MdMessage size="1.3em" color="#626262"/>    <span className="radius-heding" style={{marginLeft:"5px"}}> My Messanger({userlist && userlist.length})</span><span style={{float:"right",marginRight:"10px"}}><FaEdit style={{ marginLeft: '0px',color:'#FF8427' }} /><FiMaximize2  style={{cursor:'pointer',marginLeft:"10px"}} onClick={togglePopupExpand}/></span></div>
    {isOpenExpandMessage && <MessageExpandPopup
              handleClose={togglePopupExpand}
            />
            }

    <div className="row margintop3"></div>
    <div class="form-group has-search margintop3">
                  <span class="fa fa-search form-control-feedback"></span>
                  <input type="text" class="form-control" placeholder="Search Messages" onChange={handleSearchChangeMessage} style={{ fontSize: '12px' }} />
                </div>
                {
                  userlist.map((user, index) => (
                    <Card className={styleForMessg && user.userId == styleForMessgid ? "hoverformesgcard margintop1" : " margintop1"} style={{ paddingBottom: "8px", paddingTop: "5px", lineHeight: "1.6", cursor: "pointer" ,borderTop:"none",borderLeft:"none",borderRight:"none",borderBottomWidth:"1px",borderRadius:"0px"}}

                      onMouseEnter={() => omMouseHover(user.userId,user.blockStatus)}
                      onMouseLeave={() => omMouseLeve(user.userId)}
                    >
                      <div className="row margintop1" key={user.userId}>
                        <div className="col-2 margintop1" onClick={() => togglePopupMessage(user.userId)}>
                          <img src={user.profilePicturePath} id='profpicdisablesave17' className=' messengerprofile' />

                        </div>
                        <div className="col-10">
                          <div className="row">
                            <div className="col-9" onClick={() => togglePopupMessage(user.userId)}>
                              <span className="radius-fourth-card1-lefttext" style={{ marginLeft: "-9px" }} >{user.userName}</span>
                            </div>
                            <div className="col-3 textright">
                              {
                                styleForMessg && user.userId == styleForMessgid ?
                                  (<span className={styleForMessgblock && user.userId == styleForMessgid ? "threedotblockunclock" : ""}
                                    onMouseEnter={() => omMouseHoverblock(user.userId)}
                                    onMouseLeave={() => omMouseLeveBlock(user.userId)}
                                    onClick={() => blockunblockpoupopen(true)}>
                                    <BsThreeDots style={{ cursor: "pointer" }} />
                                  </span>)
                                  :
                                  (<span className="radius-fourth-card1-text" style={{ marginLeft: '-16px' }}>{user.time}</span>)
                              }
                            </div>

                          </div>
                          <div onClick={() => togglePopupMessage(user.userId)}>
                            <span className="textShowondot message-text" style={{ lineHeight: '80%' }}>
                              {user.lastMessage}

                            </span>
                          </div>

                        </div>
                        <div className={blockPop && user.userId == styleForMessgid ? "" : "hide"}>
                          <div className="" style={{ backgroundColor: "fff", boxShadow: "0px 5px 5px 0px rgba(242, 242, 242, 1)", borderRadius: "3px", width: "150px", fontSize: "12px", float: "right", marginTop: "-62px", marginLeft: "-20px" }}>
                            <div className="">
                              <ul class="list-group ">
                                {
                                  user.blockStatus == false ?
                                    (<li class="list-group-item"  style={{ cursor: "pointer" }}> <span onClick={() => addblockUnblock(user.userId)}>Block</span> <MdOutlineClose  onClick={() => blockunblockpoupopen(false)} style={{marginLeft:"0px",marginTop:"-7px",float:"right",marginRight:"-14px"}}/></li>) :
                                    (<li class="list-group-item"  style={{ cursor: "pointer" }}><span onClick={() => addblockUnblock(user.userId)}>Unblock</span> <MdOutlineClose  onClick={() => blockunblockpoupopen(false)} style={{marginLeft:"0px",marginTop:"-7px",float:"right",marginRight:"-14px"}}/></li>)

                                }
                              </ul>
                            </div>
                          </div>

                        </div>
                      </div>


                    </Card>
                  ))

                }

                {isOpenMessagePop && <MessagePopup

                  handleClose={togglePopupMessage} uerAuthId={uerAuthId} styleForMessgid={styleForMessgid}
                  userlist ={userlist} setuserlist={setuserlist} styleForMessgBlockstatus={styleForMessgBlockstatus}
                />}


  </div>
</div>

        </div>
        </div>
       <div className={LikeDiv ? "row":"hide row"}>
         <div className="popup-boxdate">
              <div className="boxdate" style={{width:'30%',marginLeft:"620px",marginTop:"100px"}}>
        <div className='bordernonemessg' ref={popupLikeRef} style={{marginLeft:"-16px",marginRight:"-16px"}}>
        <div className=" row" style={{borderBottom:"1px",borderColor:"lightgray"}} >
          <div className='col-1'></div>
            <div className=" col-9" style={{textAlign:"left",fontSize:"16px"}}><span className="event-sub-detail">Reactions</span></div>
            <div className='col-1'>
            <span className="" onClick={()=>Likepoupopen(false)}><MdClose size='1.5em'/></span>
            </div>
            <br/>
            </div>
            <div className='row' style={{marginTop:"20px"}}></div>
        </div>
        
        <div style={{overflowY:"scroll",height:"350px",overflowX:"hidden"}}>
        
        {
         likeData.map((likemenu, index) => {
           // Extract first letter of first name and first letter of last name
           const nameParts = likemenu.viewerName ? likemenu.viewerName.split(" ") : [];
           const initials =
             nameParts.length > 1
               ? `${nameParts[0][0].toUpperCase()}${nameParts[nameParts.length - 1][0].toUpperCase()}`
               : nameParts[0]
               ? nameParts[0][0].toUpperCase()
               : "";
       
           return (
             <div key={index} className="radius-fourth-card1 bordernonemessg1 margintop3" style={{ marginTop: "20px" }}>
               <div className="row margintop1">
                 <div className="col-9">
                   <div>
                     {/* Profile Picture or Avatar */}
                     {likemenu.profilePicture ? (
                       <img
                         src={likemenu.profilePicture}
                         style={{
                           width: "30px",
                           height: "30px",
                           borderRadius: "50%",
                           marginRight: "6px",
                           marginTop: "-6px",
                         }}
                       />
                     ) : (
                       <Avatar
                         style={{
                           width: "30px",
                           height: "30px",
                           fontSize: "12px",
                           backgroundColor: "#007bff", // You can choose a different color
                           color: "#ffffff",
                           marginTop: "-6px",
                           marginRight: "6px",
                         }}
                       >
                         {initials}
                       </Avatar>
                     )}
       
                     {/* Viewer Name */}
                     <span className="event-sub-detail" style={{ fontSize: "14px", color: "black" }}>
                       {likemenu.viewerName}
                     </span>
                   </div>
                   <div>
                     
                   </div>
                 </div>
               </div>
               <div className="row" style={{ marginTop: "10px" }}></div>
             </div>
           );
         })
       }
       
                    
                    
        </div>
            
                    
              </div>
            </div>
        
          
            
        
            </div>
        <div className={contactInfoDiv ? "row" : "hide row"}>
                <div className="popup-boxcontact">
                  <div className="boxcontact">
                  <div className="textleft radiussub-div" style={{marginLeft:"-12px",marginTop:"-14px",marginRight:"-12px"}}>
                    <FaPhoneAlt color='#626262' size='1.0em' style={{marginLeft:'13px'}}/> <sup><FaInfoCircle color='#626262' size='0.9em' style={{marginLeft:"-13px",marginTop:"-2px"}}/></sup> <span className='event-date' style={{color:"#212529",fontSize:"14px",marginLeft:"5px"}}>Contact Information</span><MdOutlineClose size="1.3em"  style={{cursor:'pointer',marginLeft:"10px",float:"right"}} onClick={() => contactInfopoupopen(0, false)}/></div>
                    <div className="row margintop3">
                      <div className="col-5 iconboxshaow" style={{borderRadius:"10px",marginLeft:'10px'}}>
                        <MdEmail color='#626262' size='1.1em'/>
                        <div className=""><span className="event-date" style={{color:"#626262"}}>Email</span></div>
                        <span className="event-date" style={{fontWeight:"400"}}>{commanView.emailId}</span>
                      </div>
                      <div className="col-1"></div>
                      <div className="col-5 iconboxshaow" style={{borderRadius:"10px",marginLeft:'0px'}}>
                        <FaPhoneAlt color='#626262' size='1.1em'/>
                        <div className=""><span className="event-date" style={{color:"#626262"}}>Mobile</span></div>
                        <span className="event-date" style={{fontWeight:"400"}}>{commanView.mobileNumber}</span>
                      </div>
                    </div>
                    </div>
                    </div>
              </div>
        </div>


</div>
)

}
export default UserLanding;