import React, { useState, useEffect ,useRef} from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { columns ,data, heading, Rows } from "./Data1";
import { Link } from "react-router-dom";
import {Tab,Tabs,Row,Col,Nav,NavItem,Card} from 'react-bootstrap'
import ContactAll from "./ContactAll";
import ContactInvitationsent from "./ContactInvitationsent";
import ContactInvitationRecive from "./ContactInvitationRecive";
import ContactBlock from "./ContactBlock";
import ReferralAll from "./ReferralAll";
import ReferralInvitaionSent from "./ReferralInvitaionSent";
import ReferInviteCommMemberPopup from "./ReferInviteCommMemberPopup";
import { useHistory } from "react-router-dom";
import axios from "axios";
import {configData} from "../../Config"
import ReferInviteDetailContentPop from "./ReferInviteDetailContentPop";

 
const Refferal=()=>{
  
  //for session
  // let history = useHistory();

  // const [session ,setSession] = useState(false);

  // const gesessiondata = async () => {
  //    debugger;
  //    axios.get('/checkSession')
      
  //    .then(result =>{
  //      if(result.data.code == 501){
  //        setSession(true)  ;
  //         }
  //     else{
  //          history.push("/Login");

  //     }
  //      debugger;
  //      console.log(result.data.code);
  //    }).catch(error =>{
  //      console.log(error);
  //    })
  //  };

  //        console.log("checksession on campaign",session);

  // useEffect(() => {
  //     gesessiondata();
  //   }, [session]);
  

 
    const [isOpenMessagePop, setIsOpenMessagePop] = useState(false);
    const togglePopupMessage = () => {
      setIsOpenMessagePop(!isOpenMessagePop);
    }
  
    const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
    console.log("authdata11",authlocalstoragedata)
    
    const [uerAuthId, setuerAuthId] = useState(authlocalstoragedata?.userAuthenticationId);
    const [uerAuthReferralCode, setuerAuthReferalCode] = useState(authlocalstoragedata?.referralCode);

    const [countmenu, setData] = useState([]);

    useEffect(() => {
      loadUsers();
    },[countmenu]);
 
    
    const loadUsers = async () => {
      const result = await axios.get(`${configData.ApiUrl}/subscriber/getCountForInvitations?id=${uerAuthId}`);

      setData(result.data.data);
      console.log("getCountForInvitations",result.data.data)

     };

    
//for referalall
const [allreferal, setallreferal] = useState([]);


useEffect(() => {
  loadAllRefConnects();
 },[]);

 const loadAllRefConnects = async () => {
   
  // const result = await axios.get(`/subscriber/getAllReferrals?referralCode=${uerAuthReferralCode}`);
  const result = await axios.get(configData.ApiUrl+'/subscriber/getAllReferrals?pageNo='+1+'&pageSize='+10+'&referralCode='+uerAuthReferralCode)

  setallreferal(result.data.data);
   console.log("getListOfAllReferrals",result.data.data)


 };  
//for sent referal invite
const [sentReferal, setsentReferal] = useState([]);

useEffect(() => {
  loadSentReferal();
 },[]);

 const loadSentReferal = async () => {
  //  const result = await axios.get(`/subscriber/getAllInvitationSent?referralCode=${uerAuthReferralCode}`);
   const result = await axios.get(configData.ApiUrl+'/subscriber/getAllInvitationSent?pageNo='+1+'&pageSize='+10+'&referralCode='+uerAuthReferralCode)

   setsentReferal(result.data.data);
   console.log("getListOfAllInvitationSent",result.data.data)


 };

  return (
    <div >
<Tab.Container className="custom-tab-containerr" id="left-tabs-example" defaultActiveKey="link-29">
                      <div className=" bg-gray-100 nav-bg" id="referaldiv" style={{backgroundColor:"#4682DB",paddingBottom:"5px",paddingTop:"5px",borderRadius:"17px"}}>

                        <Nav className="nav nav-tabs  ">
                        
                          <Nav.Item>
                            <Nav.Link eventKey="link-29" className="referalnvlink ">
                              Referrals-({countmenu.count4})
                            </Nav.Link>
                          </Nav.Item>
                         
                          <Nav.Item>
                            <Nav.Link eventKey="link-30" className="referalnvlink " >
                             Invitation Sent-({countmenu.count2})
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="link-31" className="referalnvlink  " onClick={togglePopupMessage}>
                            Invite Community Member
                            </Nav.Link>
                            
                          </Nav.Item>
                          
                          
                        </Nav>

                      </div>

                      <Tab.Content className=" ">
                        <Tab.Pane eventKey="link-29">
                        <div className="">
                          <ReferralAll allreferal={allreferal} setallreferal={setallreferal}/>
                        </div>                  
                       </Tab.Pane>
                        <Tab.Pane eventKey="link-30">
                          <div>
                            <ReferralInvitaionSent sentReferal={sentReferal} setsentReferal={setsentReferal}/>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="link-31">
                          <div>
                          {isOpenMessagePop && <ReferInviteDetailContentPop
      
                         handleClose={togglePopupMessage}
                      />}                
                                </div>
                        </Tab.Pane>
                        
                      </Tab.Content>
                    </Tab.Container>
    </div>

           );
}
 
export default Refferal;