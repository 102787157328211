import React, { useState,useEffect } from "react";
import logo from '../../images/naidudismall.png';
import './JoinNow.css';
import './CaseOneTwo.css';
import { useHistory } from "react-router-dom";
import axios from "axios";

import { toast } from "react-toastify";
import BasicInfo from "./BasicInfo";
import ContactVerify from "./ContactVerify";
import SubscribeCommunity from "./SubscribeCommunity";
import {
  Typography,
  TextField,
  Button,
  Stepper,
  Step,
  StepLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom"
import {configData} from "../../Config"


const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

function getSteps() {
  return [
    "Basic & contact details", "Contact verification Info", "Subscribe & community pledge"
  ];
}



const JoinNow = () => {

  //for session
  // const [session ,setSession] = useState(false);

  // const gesessiondata = async () => {
  //    debugger;
  //    axios.get('/checkSession')
      
  //    .then(result =>{
  //      if(result.data.code == 501){
  //        setSession(true)  ;
  //         }
  //     else{
  //          history.push("/Login");

  //     }
  //      debugger;
  //      console.log(result.data.code);
  //    }).catch(error =>{
  //      console.log(error);
  //    })
  //  };

  //        console.log("checksession on campaign",session);

  // useEffect(() => {
  //     gesessiondata();
  //   }, [session]);
  


  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  var newStepValue=0;
  if(activeStep < 0)
  {
    newStepValue = activeStep+2;
}
else if(activeStep>0){
  newStepValue=activeStep;
}

 
//   else{
//   const [activeStep, setActiveStep] = useState(0);
// }
  const steps = getSteps();
debugger
  const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
console.log("authdata11",authlocalstoragedata)



  const getStepContent = (value) => {
    debugger;
    // if(newStepValue==2){
    //   //setActiveStep(activeStep==2)
    //   return <SubscribeCommunity handleNext={handleNext} basicInfo={basicInfo} setBasicInfo={setBasicInfo} handleBack={handleBack}/>;
    // }
     if (newStepValue === 0) {
      return <BasicInfo handleNext={handleNext} basicInfo={basicInfo} setBasicInfo={setBasicInfo} handleBack={handleBack} confirmPass={confirmPass} setConfirmPass={setConfirmPass} confirmPassErr={confirmPassErr} setconfirmPassErr={setconfirmPassErr} basicSaveInfo={basicSaveInfo}
      selectedVal={selectedVal} setSelectedVal={setSelectedVal}/>;
    } else if (newStepValue === 1) {
      return <ContactVerify handleNext={handleNext} basicInfo={basicInfo} setBasicInfo={setBasicInfo} otp={otp} setOtp={setOtp} resendOtp={resendOtp} handleVerifyOptp={handleVerifyOptp} handleBack={handleBack} setOtpNew={setOtpNew} otpNew={otpNew}/>;
    } 
     else {
       debugger;
       return <SubscribeCommunity handleNext={handleNext} basicInfo={basicInfo} setBasicInfo={setBasicInfo} joinNowRes={joinNowRes} setjoinNowRes={setjoinNowRes} handleBack={handleBack} Paymentreq={Paymentreq} setPaymentreq={setPaymentreq} paymentRequest={paymentRequest} amouttotal={amouttotal} setamouttotal={setamouttotal} borderbox={borderbox} setBorderbox={setBorderbox} borderboxfree={borderboxfree} setBorderboxfree={setBorderboxfree}/>;
     }
  };

  
//for otp verify basicinfo
const [otp,setOtp]= useState({
  otpNum1:"",
  otpNum2:"",
  otpNum3:"",
  otpNum4:""

 })

const [otpNew, setOtpNew] = useState('');


 const [confirmPass,setConfirmPass]= useState({
  confirmPassword:"",
  

 })

 const handleVerifyOptp =async()=>{
  debugger;
  if(basicInfo.countryCode=="+91"){

  const result = await axios.get(configData.ApiUrl+'/user/validateMobileOtp?mobileNo='+
  basicInfo.mobileNumber+ '&otp='+otpNew);
console.log("sendotp",result.data.data)
  if(result.data.code==200){
    toast.success("Otp Verified!");
    addBasicInfo();
    newStepValue =newStepValue+1;

  }
  else{
    alert("fail")

  }
}
else{

  const result =await  axios.get(configData.ApiUrl+'/user/validateEmailOtp?emailId='+
  basicInfo.emailId+ '&otp='+otpNew);
console.log("sendotp",result.data.data)
  if(result.data.code==200){
    toast.success("Otp Verified!");
    addBasicInfo();
    newStepValue =newStepValue+1;

    
  }
  else{
    alert("fail")

  }
}

}
const { id } = useParams();

 //add basic info
  const [basicInfo,setBasicInfo]= useState({
    id:"",
    firstName:"",
    middleName:"",
    lastName:"",
    religion:"" ,
    subCaste:"",
    otherCaste:"",
    emailId:"",
    countryCode:"",
    mobileNumber:"",
    password:"",
    nationality:"",
    referralUsed:null
    })

    const [selectedVal, setSelectedVal] = useState({
      label:"",
      id:""
  });

    const [confirmPassErr,setconfirmPassErr]= useState(false)

    let history = useHistory();

    const [joinNowRes,setjoinNowRes]= useState()
    const [amouttotal, setamouttotal] = useState();

    //for free and premium cards
    const [borderbox, setBorderbox]=useState(false);
const [borderboxfree, setBorderboxfree]=useState(false);

    const [Paymentreq, setPaymentreq]= useState({
      nameId:{joinNowRes},
      amount:"",
      productType:'Community',
      currency:'INR',
      code:"",

      });

    //setPaymentreq(joinNowRes);

//payment req
const paymentRequest =()=>{
  debugger
if(borderboxfree==true){
          
  history.push("/CompleteProfile")

}
else{

  const response = axios.post(configData.ApiUrl+'/user/payment-request', Paymentreq)
    response.then((result) => {
      console.log("Paymentres response",result.data.data);
      debugger
      if (result.data.data != null) {
          toast.success(" Successfully!");

         // localStorage.setItem('id',result.data.data.id);
          //setjoinNowRes(result.data.data)
          debugger;
          localStorage.setItem('name',result.data.data.name);
         // history.push("/CompleteProfile")

  
        }
          toast.success("payments added Successfully!");
   })
       response.catch((err) => {
         alert("Getting error in featching data")
       });
       console.log("response", response?.data)
}
  
    

        //history.push("/Login")

}


const addBasicInfo = ()=>{
  debugger

  
    const response =  axios.post(configData.ApiUrl+'/user/addBasicUser', basicInfo)
    .then((result) => {
      console.log("joinnow response",result.data.data);
      debugger
      if (result.data.data != null) {
          toast.success("Basic Detail Added Successfully!");

          localStorage.setItem('JoinNowData',JSON.stringify(result.data.data));
          setjoinNowRes(result.data.data)
          debugger;
          localStorage.setItem('name',result.data.data.name);
          // history.push("/CompleteProfile")

          setActiveStep(activeStep + 1);
          newStepValue =newStepValue+1;


  
        }
          //toast.success("Last Name  has been added Successfully!");
   })
       response.catch((err) => {
         alert("Getting error in featching data")
       });
       console.log("response", response?.data)

      
}

//resendotp
const resendOtp =async()=>{
  debugger;

  const result = await axios.get(configData.ApiUrl+'/user/resendUserOTP?mobileNo='+
  basicInfo.mobileNumber+ '&emailId='+basicInfo.emailId+'&countryCode='+basicInfo.countryCode);
console.log("sendotp",result.data.data)
  if(result.data.code==200){
    toast.success("Otp Sent!");

  }
  else{
    toast.warn("Otp Sent fail")

  }


}

   const isStepOptional = (step) => {
     return step === 4 || step === 5;
   };

  // const isStepSkipped = (step) => {
  //   return skippedSteps.includes(step);
  // };

  const basicSaveInfo = async()=>{
    debugger
    newStepValue=0
      const result =  await axios.get(configData.ApiUrl+'/user/sendUserOTP?mobileNo='+
        basicInfo.mobileNumber+ '&emailId='+basicInfo.emailId+'&countryCode='+basicInfo.countryCode);
      console.log("sendotp",result.data.data)
        if(result.data.code==200){
          toast.success("Otp Sent Successfully!");

          setActiveStep(activeStep +1);
          newStepValue=1;
        }
       else if(result.data.code==400){
        toast.warn("Mobile no Already Exist!");
        newStepValue=0;

       }
       else if(result.data.code==409){
        toast.warn("Email Already Exist!");
        newStepValue=0;


       }
        else{
          //setActiveStep(activeStep ===0);
          newStepValue=0;
      
        
        
      }
  }

  const handleNext = async() => {
    if  (newStepValue === 0) {
      
      debugger
        

      //   const result = await axios.get(configData.ApiUrl+'/user/sendUserOTP?mobileNo='+
      //   basicInfo.mobileNumber+ '&emailId='+basicInfo.emailId+'&countryCode='+basicInfo.countryCode);
      // console.log("sendotp",result.data.data)
      //   if(result.data.code==200){
      //     toast.success("Otp Sent Successfully!");

      //     //setActiveStep(activeStep ===1);
      //     newStepValue=1;
      //   }
      //  else if(result.data.code==400){
      //   toast.warn("Mobile no Already Exist!");
      //   newStepValue=0;

      //  }
      //  else if(result.data.code==409){
      //   toast.warn("Email Already Exist!");
      //   newStepValue=0;


      //  }
      //   else{
      //     //setActiveStep(activeStep ===0);
      //     newStepValue=0;
      
        
        
      // }

  }
  if (newStepValue === 1) {
    debugger
    if(otpNew !=''&& otpNew !=null){
      handleVerifyOptp();

    }
  }

  if (newStepValue === 2) {
    debugger
    paymentRequest();
  }
  // if(newStepValue!=0){
  //   setActiveStep(activeStep + 1);
  //   newStepValue =newStepValue+1;}
    // setSkippedSteps(skippedSteps.filter((skipItem) => skipItem !== activeStep));
  };

  const handleBack = () => {
    //
    debugger;
    newStepValue =newStepValue-1;
    //getStepContent(newStepValue);
    setActiveStep(activeStep - 1);
  };

  // const handleSkip = () => {
  //   if (!isStepSkipped(activeStep)) {
  //     setSkippedSteps([...skippedSteps, activeStep]);
  //   }
  //   setActiveStep(activeStep + 1);
  // };

  // const handleBackData = () => {
  //   setActiveStep(activeStep +2);
  // };
  if(authlocalstoragedata != null && authlocalstoragedata?.directory==false && activeStep === 0)
  {
    debugger;
    newStepValue=2;

  }
  //for new session for userlogin
  // const redirecToLandingdata =  () => {
 
  //   if(authlocalstoragedata?.kycStatus ==null &&  authlocalstoragedata?.kycStatus ==''){
  //     history.push("/");

  //   }
  // }
     
  //    useEffect(() => {
  //     redirecToLandingdata();
  //  });

  return (
    <div className=" container-fluid hidescrolll  login-main-div"style={{overflowY:"auto",height:"710px",backgroundColor:""}}>
<div className="container-fluid  userlandtop-div">
            <div className="row">
          <div className="col-lg-1 col-sm-12 col-md-3"><img src={logo} className='' id='profpicdisablesave' style={{paddingTop:"14px"}}/></div>
          <div className="col-lg-9 col-sm-12 col-md-6"></div>
          <div className="col-lg-2 col-sm-12 col-md-3">
          </div>

          </div>

            </div>
          <div className="margintop  row">
            <div className="col-lg-1"></div>
            <div className="textleft col-lg-1 col-sm-12 col-md-2">
            {/* <img src={logo}  style={{width:"104px", height:"77px"}}/> */}
            </div>
            {/* <div className="col-1"></div> */}
            <div className="col-lg-9 col-sm-12 col-md-10">
<div className="textcenter"><span className="matchestxetxt textcenter"style={{fontSize:'14px',color:"#222227"}}>Register & get connected with Naidu Community globally...</span>
</div>
<div className="margintop3">
<div className="row marglrftrightminusrow">
          <div className="col-lg-11 col-sm-12 col-md-12 form-container">
<Stepper alternativeLabel activeStep={newStepValue}>
        {steps.map((step, index) => {
          const labelProps = {};
          const stepProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography
                variant="caption"
                align="center"
                style={{ display: "block" }}
              >
                optional
              </Typography>
            );
          }
          // if (isStepSkipped(index)) {
          //   stepProps.completed = false;
          // }
          return (
            <Step {...stepProps} key={index}>
              <StepLabel {...labelProps}>{step}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {newStepValue === steps.length ? (
        <Typography variant="h3" align="center">
             {/* {joinpoupopen(true)}         */}
             </Typography>
      ) : (
        <>
        
          <div className="">{getStepContent(newStepValue)}</div> 
         
         {/* { authlocalstoragedata?.directory==false? (
          <div className="">{getStepContent(newStepValue)}</div>

         ):(
          <div className="">{getStepContent(newStepValue)}</div> 

         )


          

          
} */}

          
          {/* <div className="margintop1 row ">
            <div className="col-3 marginleftmins4"></div>
            <div className="col-3 margintop textright">
            <button
               className={activeStep === 0?"displynoneprev":"prevbtn sourcesansfont"}
              
              onClick={handleBack}
            >
              Previous
            </button>
            </div>
          <div className="col-2 margintop ">
          <button
            className={activeStep === 0?"nextbtn sourcesansfont":activeStep === 2?"nextbtn2 sourcesansfont ":"nextbtn1 sourcesansfont"}
              variant="contained"
              color="primary"
               onClick={handleNext}
              type="submit"
            >
              {activeStep === steps.length - 1 ? "Submit And Proceed to Pay" : "Next"}
            </button>
            
            
          </div>
          <div className="margintop1  row"></div>

          <div className={activeStep === 1?"displynoneprev":activeStep === 2?"displynoneprev":"margintop textcenter row"}>
            <div className="col-4"></div>
            <div className="margintop col-4">
            <span className="alreadyspn marginleftmins1">Already a registered member ?</span>
            <span className="signin-stepper marginleft5" onClick={redirectoLogin} style={{cursor:"pointer"}}>Sign In</span>
            </div>
           
            </div>

            <div className={activeStep === 2?"displynoneprev":activeStep === 0?"displynoneprev":" textcenter row"}>
            <div className="col-3"></div>
            <div className="margintop col-6">
            <span className="alreadyspn1 ">Mobile verified badge is shown on your profile once you verify your mobile </span>
            </div>
           
            </div>

            <div className={activeStep === 1?"displynoneprev":activeStep === 0?"displynoneprev":" textcenter row "}>
            <div className="col-3"></div>
            <div className="margintop col-6">
            <span className="alreadyspn1 ">You will be redirect to Payment Gateway once you click on Submit button </span>
            </div>
           
            </div>
            
          </div> */}
         
          
          <br/>
        
        </>
      )}
      </div>
      </div>
</div>

</div>
          </div>
      
          

    </div>
  );
  
};

export default JoinNow;