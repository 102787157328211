import React from "react";
import {  Link } from 'react-router-dom'
import './Login.css';
import logo from '../images/naidudismall.png';
import backing from '../images/logback.png';
import { useState ,useEffect } from "react";
import {AiFillEye} from 'react-icons/ai'
 import { useHistory } from "react-router-dom";
 import axios from 'axios';
 import {configData} from "../Config"




const Login =()=>{
    let history = useHistory();
    let isvalid= true;
    const initialValues = { email: "",  password: "" };
   const [formValues, setFormValues] = useState(initialValues);
   const [formErrors, setFormErrors] = useState({});
   const [isSubmit, setIsSubmit] = useState(false);

    
    const [eyeshown, setEyeShown] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);

   const togglePasswordVisiblity = () => {
     setPasswordShown(passwordShown ? false : true);
   };

   const[email, setEmail]=useState("");
   const[password,setPassword]=useState("");
   const[userValidEroor,setuserValidError]=useState(false);


   const handleEmail =(e)=>{
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
     setEmail(e.target.value)
  }
  const handlePassword =(e)=>{
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    setPassword(e.target.value)
    // if(password != null){
    //   setEyeShown(true);
    // }

  }
  const handleSubmit = (e) => {
    e.preventDefault();
    debugger
    
    setFormErrors(validate(formValues));
    console.log(formErrors);
    console.log(email,password);
    if(isvalid ){
    axios.post(configData.ApiUrl+"/userAuthentication",{
      emailId: email,
      password: password
    })
    .then(result =>{
      console.log("result",result.data.data);
      debugger;
      if(result.data.data !=null){
        localStorage.setItem('authdata',JSON.stringify(result.data.data));
        if(result.data.data.directory== false){
          history.push("/JoinNow/null");
  
        }
        else if(result.data.data.birthDate== null){
          history.push("/CompleteProfile");
  
        }
        else if(result.data.data.currentCountry== null){
          history.push("/CompleteProfile");
  
        }
        
        else if(result.data.data.kycStatus== null){
          history.push("/CompleteProfile");
  
        }
        
        else{
          history.push("/UserLanding");

          setuserValidError(false)
        }
        
      }
      
      else{
        //toast.warn("Invalid user");
        setuserValidError(true)
      }
    })
    .catch(error =>{
      console.log(error)
    })
    
  }
    setIsSubmit(true);

  };

  useEffect(() => {
    console.log(formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(formValues);
    }
  }, [formErrors]);
  

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    
   

    let regex1 = /\D/;
    let isCharecter = regex1.test(values.email);
    if (!isCharecter ) {
      if(values.email.length ==10){
        isvalid=true

      }
      else{
        errors.email = "Mobile no must be 10 characters";
        isvalid=false

      }

    } else {
      if (!values.email) {
      
        errors.email = "Email is required!";
        isvalid=false;
      } else if (!regex.test(values.email)) {
        errors.email = "This is not a valid email format!";
        isvalid=false;
  
      }
      if (!values.password) {
        errors.password = "Password is required !";
        isvalid=false;
  
      } else if (values.password.length < 4) {
        errors.password = "Password must be more than 4 characters";
        isvalid=false;
  
      } else if (values.password.length > 20) {
        errors.password = "Password cannot exceed more than 20 characters";
        isvalid=false;
  
      }
     // setFormErrors(validate(values));
    }
    // if (values.email =="admin@gmail.com" && values.password == "12345") {
    //     isvalid = true;
    //   } 
    //   else{
    //     errors.password = "Username and Password  is not valid!";
    //     isvalid = false;

    //   }
    
    
    return errors;
  };

  const redirectTOJoin = () => {
history.push('/JoinNow/'+ null);
  };

    return(
        <div className="container-fluid containerfluidpadding0 login-main-div" style={{backgroundColor:"#F8F8F8"}}>
            
            <div className="container-fluid  userlandtop-div">
            <div className="row">
          <div className="col-lg-1 col-sm-12 col-md-3"><img src={logo} className='' id='profpicdisablesave' style={{paddingTop:"14px"}}/></div>
          <div className="col-lg-9 col-sm-12 col-md-6"></div>
          <div className="col-lg-2 col-sm-12 col-md-3">
            <button className="secondry-btnn margintop" style={{marginRight:"10px",float:"right"}}>Sign Up</button>
          </div>

          </div>

            </div>
            
            
            
            
            <div className="row margintop "></div>

            <div className="row margintop ">
                <div className="col-4"></div>
                <div className="col-4 radius-div ">
                    <div className="row">
                      <div className="col-lg-4 col-sm-12 col-md-3"></div>
                      <div className="col-lg-4 col-sm-12 col-md-3" style={{marginLeft:"-10px"}}><img src={logo} className='' id='profpicdisablesave' style={{paddingTop:"14px"}}/>
                      </div>
                      <div className="col-lg-4 col-sm-12 col-md-3"></div>

                    </div>
                 <div className=" row margintop1 textcenter"><span className="fontroboto40012px"style={{fontWeight:"500",fontSize:"14px"}}>Sign In and Socialize</span></div>
                 <div className="margintop3"></div>
                 <div className="row margintop">
                    <div className="col-2"></div>
                    <div className="col-8">
                        <div className="">
                        <input type="text" className=" form-control" id="email"
                         name="email"
                         onChange={handleEmail}
                        placeholder=" Email or Mobile Number"
                        style={{fontSize:"12px",height:"38px"}}
                     />
                        </div>
                        <p className="redp">{formErrors.email}</p>

                    </div>

                 </div>
                 <div className="row margintop3">
                    <div className="col-2"></div>
                    <div className="col-8 form-group">
                    <div className="">
                        <input type={passwordShown ? "email" : "password"} className=" form-control" id="password"
                     name="password"
                        placeholder="Password"
                        onChange={handlePassword}
                        style={{fontSize:"12px",height:"38px"}}
                     />
                           { (password !== "") && <AiFillEye  className='eyeicon' size='1.2em' onClick={togglePasswordVisiblity}/> }

                   
                     </div>
                     <p className="redp" style={{color:"red",fontSize:"13px"}}>{formErrors.password}</p>

                    </div>

                 </div>
                 <div className="row margintpmins1">
                    <div className="col-2"></div>
                 <div className=" col-5">
                <Link  to="/ForgotPassword"><span className="fontroboto40012px"style={{fontWeight:"500",fontSize:"14px"}}>Forgot Password?</span></Link>
                </div>
                 </div>
                 <div className="row margintop3">
                    <div className="col-3"></div>
                    <div className="col-4 margintop1">
                        <button className="signinbutton fontroboto40012px"style={{fontWeight:"500",fontSize:"14px",color:"#ffffff"}}  onClick={handleSubmit} >Sign In</button>
                    </div>
                    <lable className={userValidEroor ? "show lablered":"hide"} style={{color:"red",fontSize:"13px",textAlign:"center",marginTop:"5px"}}>Invalid User</lable>

                 </div>
                 <div className="row margintop textcenter">
                    <div className="col-1"></div>
                    <div className="col-9">
                    <span className="fontroboto40012px"style={{fontWeight:"500",fontSize:"14px",marginLeft:"38px"}}>New to Naidu Community?</span>
                    <div className=""><span className="fontroboto40012px" style={{fontWeight:"500",fontSize:"14px",marginLeft:"27px",color:"#FF8427"}} onClick={redirectTOJoin}>Sign Up Now</span></div>

                    </div>
                 </div>
                 <div className="row margintop3"></div>


                    
                    </div>
            </div>
            
            
           


        </div>
    );
}
export default Login