import './AddNewsPopup.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import React, { useState, useEffect,useRef } from "react";
import AddBlogPopup from './AddBlogPopup';
import AddKycPopup from './AddKycPopup';
import SuccessPop from '../JoinNow/SuccessPop';
import moment, { duration } from 'moment'
import Select from "react-select";
import csc from "country-state-city";
import validator from "validator";
import axios from "axios";
import { toast } from "react-toastify";
import {BsPlusLg} from 'react-icons/bs'
import {MdOutlineDeleteOutline} from 'react-icons/md'
import AddJobPop from './AddJobPop';
import {configData} from "../../Config"
import { MdOutlineEventAvailable } from "react-icons/md";


 
const AddNewsPopup = props => {
  

  const [serviceList, setServiceList] = useState([{ service: "" }]);
  const filearr=[];
    const handleServiceChange = (e, index) => {
      const { name, value } = e.target;
      const list = [...serviceList];
      list[index] = e.target.files[0];
      setServiceList(list);
  debugger;

  const file = e.target.files[0];
  const allowedTypes = ['image/jpeg', 'image/png', 'image/svg+xml'];
  const maxSize = 20 * 1024 * 1024; // 20 MB in bytes

  // Validate file type
  if (file && !allowedTypes.includes(file.type)) {
    toast.error('Only JPEG, PNG, and SVG files are allowed.');
    return; // Exit the function if file type is invalid
  }
  // Validate file size
  if (file && file.size > maxSize) {
    toast.error('File size must not exceed 20 MB.');
    return; // Exit the function if file size is too large
  }

  
  if(serviceList.length==1){
    setAttachment(preState => ({
         ...preState,
         "profilePhoto" : e.target.files[0]
       }))
  }
  if(serviceList.length==2){
    setAttachment(preState => ({
         ...preState,
         "photo1" : e.target.files[0]
       }))
  }
  if(serviceList.length==3){
    setAttachment(preState => ({
         ...preState,
         "photo2" : e.target.files[0]
       }))
  }if(serviceList.length==4){
    setAttachment(preState => ({
         ...preState,
         "photo3" : e.target.files[0]
       }))
  }if(serviceList.length==5){
    setAttachment(preState => ({
         ...preState,
         "photo4" : e.target.files[0]
       }))
  }
      // setAppointment(preState => ({
      //   ...preState,
      //   "attachFiles" : e.target.files[0]
      // }))
      filearr.push(e.target.files[0]);
    };
  
    const handleServiceRemove = (index) => {
      const list = [...serviceList];
      list.splice(index, 1);
      setServiceList(list);
    };
  
    const handleServiceAdd = () => {
      setServiceList([...serviceList, { service: "" }]);
    };

    const [startDate, setStartDate] = useState(new Date());

    const handleChangedate= (e) => {
      debugger
     setStartDate(e)
     setEventData(preState => ({
      ...preState,
      "eventDate": (moment(e).format("DD MMM yyyy")),

    }))
    }

    const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
    console.log("authdata11",authlocalstoragedata)
    const [uerAuthId, setuerAuthId] = useState(authlocalstoragedata?.userAuthenticationId);


    const [eventdata, setEventData] = useState({
      hostId:uerAuthId,
      hostName:authlocalstoragedata?.name2,
      eventType:"",
      eventDate:"",
      eventTime:"",
      address:"",
      countryId:"",
      country:"",
      stateId:"",
      state:"",
      cityId:"",
      city:""
  
      });

      const handlechange =(event)=>{
        const {name,value,type,checked}=event.currentTarget;
        debugger
        setEventData(preState =>({
          ...preState,
          [name]:type ==='checkbox'?checked: value
        }))
        if(!CountrystateDiv){
          setEventData((preState) => ({
            ...preState,
            country: authlocalstoragedata?.currentCountry,
            state: authlocalstoragedata?.currentState,
            city: authlocalstoragedata?.currentCity
          }));
        }

        // if(event.currentTarget.files != null)
        // {
        //   setEventData(event.target.files[0]);
        // }
      }

      const [error, setError] = useState(false);

      const submitFormData = (e) => {
        debugger
        e.preventDefault();
    
        // checking if value of first name and last name is empty show error else take to step 2
         if (validator.isEmpty(eventdata.eventType)) {
             setError(true);
         }
         else if (validator.isEmpty(eventdata.eventDate)) {
             setError(true);
         }
         else if (validator.isEmpty(eventdata.eventTime)) {
           setError(true);
         }
         else if (validator.isEmpty(eventdata.address)) {
           setError(true);
    
         }
         else if(CountrystateDiv==true) {
              if (validator.isEmpty(eventdata.country)) {
               setError(true);
             }
           }
             else if(CountrystateDiv==true) { 
             if (validator.isEmpty(eventdata.state)) {
               setError(true);
             }
           }
           else if(CountrystateDiv==true) {
                if (validator.isEmpty(eventdata.city)) {
                 setError(true);
                }
               }
       
       
        
     else {
      
      addEventDetails();      
      
       }
    };

    const [errorForImg, seterrorForImg] = useState(false);

    const addEventDetails =()=>{
debugger
      // setEventData(preState => ({
      //   ...preState,
      //   attachFiles : serviceList
      // }))
      if (!CountrystateDiv) {
        setEventData((preState) => ({
          ...preState,
          country: authlocalstoragedata?.currentCountry,
          state: authlocalstoragedata?.currentState,
          city: authlocalstoragedata?.currentCity
        }));
      }

      // Check if at least one image is present
  const hasAttachment = 
  attachment.profilePhoto || 
  attachment.photo1 || 
  attachment.photo2 || 
  attachment.photo3 || 
  attachment.photo4;

  if (!hasAttachment) {
   // toast.error("Please upload at least one image.");
    seterrorForImg(true);
    return; // Stop further execution if validation fails
  }
  else{
    const formData = new FormData();

    formData.append('event',JSON.stringify(eventdata));
    
    formData.append('profilePhoto', attachment.profilePhoto);
    formData.append('photo1', attachment.photo1);
    formData.append('photo2', attachment.photo2);
     formData.append('photo3', attachment.photo3);
     formData.append('photo4', attachment.photo4);

     const response = axios.post(configData.ApiUrl+'/CommunityPost/createEvent',formData,{
headers: {
  'content-type': 'multipart/form-data'
}
}
)
    response.then(() => {
      toast.success("Event Added Successfully!");
      props.handleClose(togglePopup)

    })
    response.catch((err) => {
      alert("Getting error in featching data")

    });
       console.log("response", response?.data)
        console.log("event",eventdata)
  }
      
     }


     const [attachment, setAttachment] = useState({
      profilePhoto: "",
      photo1: "",
      photo2: "",
      photo3: "",
      photo4: "",
      
     })


     
      const countries = csc.getAllCountries();
      ////console.log(csc.getAllCountries())
    
      const updatedCountries = countries.map((country) => ({
        label: country.name,
        value: country.id,
        ...country
      }));
      const updatedStates = (countryId) => csc.getStatesOfCountry(countryId)
      .map((state) => ({ label: state.name, value: state.name, ...state }));

  const updatedCities = (stateId) =>
    csc
      .getCitiesOfState(stateId)
      .map((city) => ({ label: city.name, value: city.name, ...city }));

    const [isOpenBlog, setIsOpenBlog] = useState(false);
  const togglePopupBlog = () => {
    setIsOpenBlog(!isOpenBlog);
  }
  const [isOpenkyc, setIsOpenkyc] = useState(false);
  const togglePopupKyc = () => {
    setIsOpenkyc(!isOpenkyc);
  }
  
  const [isOpen, setIsOpen] = useState(false);
    const togglePopup = () => {
      setIsOpen(!isOpen);
    }

  const [selected, setSelected] = useState('event');
  const handleChange = event => {
    setSelected(event.target.value);
  };

  
  const [isOpentopjobPop, setisOpentopjobPop] = useState(false);
  const togglePopuptopJobPop = () => {
    setisOpentopjobPop(!isOpentopjobPop);
  }
  //for close popup when click on outside
// const popupEventdivRef = useRef(null);
// const handleClickOutsideEventdiv = (event) => {
//   if (popupEventdivRef.current && !popupEventdivRef.current.contains(event.target)) {
//     props.handleClose();
//   }
// };
// useEffect(() => {
//   document.addEventListener('mousedown', handleClickOutsideEventdiv);
//   return () => {
//     document.removeEventListener('mousedown', handleClickOutsideEventdiv);
//   };
// }, []);


//for country city state
const [countryData, setcountryData] = useState([]);

    useEffect(() => {
      loadCountryData();
    }, []);
  
    const loadCountryData = async () => {
      debugger
      const result = await axios.get(configData.ApiUrl+"/user/listOfCountries");
      setcountryData(result.data.data);
      console.log("country",result.data.data)
    };

    const handleCountryChange = (event) => {
      const [id, country] = event.target.value.split("-");
      setEventData((prevState) => ({
        ...prevState,
        countryId: id,
        country: country,
      }));
      loadStateData(id);
    };
    //for state city state
const [stateData, setstateData] = useState([]);

useEffect(() => {
  loadStateData();
}, []);

const loadStateData = async (countryId) => {
  debugger
  const result = await axios.get(`${configData.ApiUrl}/user/listOfStates?countryId=${countryId}`);
  setstateData(result.data.data);
  console.log("state",result.data.data)
};

const handleStateChange = (event) => {
  debugger
  const [id, state] = event.target.value.split("-");
  setEventData((prevState) => ({
    ...prevState,
    stateId: id,
    state: state,
  }));
  loadCityData(id);

};
//for state city state
const [cityData, setcityData] = useState([]);

useEffect(() => {
  loadCityData();
}, []);

const loadCityData = async (stateId) => {
  debugger
  const result = await axios.get(`${configData.ApiUrl}/user/listOfCities?stateId=${stateId}`);
  setcityData(result.data.data);
  console.log("city",result.data.data);
};

const handleCityChange = (event) => {
  const [id, city] = event.target.value.split("-");
  setEventData((prevState) => ({
    ...prevState,
    cityId: id,
    city: city,
  }));
};

 //for countrydiv
   const [CountrystateDiv, setCountrystateDiv] = useState(false);

   const togglePopupCountry = () => {
    setCountrystateDiv(!CountrystateDiv);
   }
 

  return (
    <div className="popup-boxnews">
      <div className="boxnews" >
                  <div className="topnewsrow"style={{paddingTop:"11px",paddingBottom:"7px",backgroundColor:"#FF84270D",borderColor:"#FF84270D"}} >
                  <div className="row " id="sharediv"> 

                    <span className='post-host-Name ' style={{fontSize:"15px",textAlign:"left"}}><MdOutlineEventAvailable size="1.3em"/> Share an Event</span>
                    </div>
                  </div>
                  <form onSubmit={submitFormData}>

                  <div className='row'>
                    <div className='col-12'>
                    
                    <div className="row margintop3">
                    <div className="col-5 textleft whitenowrap"><span className="post-host-Name "style={{color:"#626262"}}>Host Name</span></div>
                    <div className=" textleft "><span className="post-host-Name "style={{color:"#212529"}}>{authlocalstoragedata?.name2}</span></div>
                </div>
                <div className="row margintop2">
                    <div className="col-5 textleft margintop1"><span className="post-host-Name whitenowrap " style={{color:"#626262",fontSize:"13px"}}>Event Type</span>
                    <div className=" textleft">
                    <select   id="inputState" name='eventType' 
            className="form-control " onChange={handlechange} style={{fontSize:"13px"}}>
            <option selected value=""> Select Event Type</option>
            <option value="House Warming Ceremony">House Warming Ceremony</option>
            <option value="Birthday">Birthday</option>
            <option value="Marrige Anniversary">Marrige Anniversary</option>
            <option value="Death Anniversary"> Death Anniversary</option>
            <option value="Others">Others</option>
       </select>
                    </div>
                </div>
                 <div className='row '>
               <div className="col-7">
                                    {error && eventdata.eventType == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Type  is is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>     
  </div>            
                <div className="row margintop2">
                    <div className="col-6 textleft margintop1"><span className="post-host-Name "style={{color:"#626262"}}>Date</span>
                    <div className=" textleft">
                    <DatePicker
          className="adreservdate width299px "
          name='eventDate'
            selected={startDate}
             onChange={date => handleChangedate(date)}

            selectsStart // tells this DatePicker that it is part of a range*
            minDate={moment().toDate()}

            startDate={startDate}
            dateFormat="dd  MMM yyyy"
          />     
           <div className='row '>
               <div className="col-12">
                                    {error && eventdata.eventDate == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Date  is is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>                          
           </div>
                    </div>
                    <div className="col-5 textleft margintop1">
                    <span className=" post-host-Name "style={{color:"#626262"}}>Time</span>
                    <input type="time" name='eventTime' class="  form-control" onChange={handlechange} style={{fontSize:"13px"}}/>
                    <div className='row '>
                  
               <div className="col-12">
                                    {error && eventdata.eventTime == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Time  is is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>        
                    </div>

                   
                </div>
                
                
                 
                <div className="row margintop2">
                    <div className="col-5 textleft margintop1"><span className="post-host-Name "style={{color:"#626262"}}>Venue</span>
                    <div className=" textleft form-group">
                    <textarea class="form-control " name='address' id="exampleFormControlTextarea1" rows="2" placeholder="Enter Address"
                     onChange={handlechange} style={{fontSize:"13px"}}/>
                        </div>
                </div>
                 <div className='row '>
               <div className="col-7">
                                    {error && eventdata.address == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Address  is is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                </div>                   
  </div>        
                {/* <div className="row ">
                    <div className="col-5 textleft "><span className="post-host-Name "style={{color:"#626262"}}>Country</span>
                    <div className=" textleft form-group">
                   
    <select   id="countryy"  
                className=" form-control "
                name='country'
                onChange={handleCountryChange}>
                <option selected value="">Select</option>
                {
                  
                  countryData.map((result) =>(
                  <option  key={result.id}  value={`${result.id}-${result.country}`}>{result.country}</option>
                  ) )

                }
                </select>   
     </div>
                
                 <div className='row '>
               <div className="col-7">
                                    {error && eventdata.country == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Country  is is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>   
  </div>
  </div>      */}
                
                {/* <div className="row ">
                    <div className="col-5 textleft "><span className="post-host-Name "style={{color:"#626262"}}>State</span>
                    <div className=" textleft form-group">
                    
      <select   id="state"  
                className=" form-control "
                name='state'
                onChange={handleStateChange}>
                <option selected value="">Select</option>
                {
                  
                  stateData.map((result) =>(
                  <option  key={result.id}  value={`${result.id}-${result.state}`}>{result.state}</option>
                  ) )

                }
                </select> 
             </div>
                </div>
                 <div className='row '>
               <div className="col-7">
                                    {error && eventdata.state == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            State  is is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>   
  </div>      */}
                
                {/* <div className="row ">
                    <div className="col-5 textleft "><span className="post-host-Name "style={{color:"#626262"}}>City</span>
                    <div className=" textleft form-group">
                    
      <select   id="city"  
                className=" form-control "
                name='city'
                onChange={handleCityChange}>
                <option selected value="">Select</option>
                {
                  
                  cityData.map((result) =>(
                  <option  key={result.id}  value={`${result.id}-${result.city}`}>{result.city}</option>
                  ) )

                }
                </select>
       </div>
                </div>
                 <div className='row '>
               <div className="col-7">
                                    {error && eventdata.city == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            City  is is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>    
  </div>     */}
  <div className="row  textleft">
                  <div className="col-8">
                  <div className="  "><span className="post-host-Name " style={{color:"#797979",fontSize:"12px"}}>This event is being host in </span> <span className="event-sub-detail">{authlocalstoragedata?.currentCountry}, {authlocalstoragedata?.currentState}, {authlocalstoragedata?.currentCity}</span></div>
                  
                  <div className=" margintop1">
                  <div className="  mt-1"><span className="post-host-Name " style={{color:"#797979",fontSize:"12px"}}>If you want to host somewhere else please <span className="bluetextforlink" style={{ fontSize: "10px", marginLeft: "0px" }} onClick={togglePopupCountry}>Click here</span></span></div>
                  </div>
                </div> 
                </div> 

      <div className="col-5 textleft margintop2"><span className="post-host-Name "style={{color:"#626262"}}>Choose File</span>
      <div className=''><span className='fontroboto40012px' style={{color:"#626262",fontSize:"11px",fontStyle:"italic"}}>A maximum of 5 files can be added to this event. (Your file should be in jpg., jpeg.,  png., svg. with less than 20 MB size)</span></div>
      </div>
            
  {serviceList.map((singleService, index) => (
      <div className='row margintop1' key={index}  style={{whiteSpace:'nowrap'}}>

      <div  className='margintop2 col-lg-5 col-sm-9 col-md-9'>
      <input type="file" 
      accept="image/jpeg, image/png, image/svg+xml"
      class="form-control  textboxback" name='attachFiles'
     id="attachFiles" placeholder="Upload" 
     value={singleService?.service}
                onChange={(e) => handleServiceChange(e, index)}
     />
      </div>
      <div className='margintop3 col-lg-1 col-sm-1 col-md-1'>
      {serviceList.length - 1 === index && serviceList.length < 5 && (
        <BsPlusLg onClick={handleServiceAdd} size='1.2em'/>
                
              )}
      </div>
      <div className='margintop3 col-lg-1 col-sm-1 col-md-1'>
              {serviceList.length !== 1 && (
                <MdOutlineDeleteOutline size='1.7em' onClick={() => handleServiceRemove(index)}/>
                
              )}
      </div>
      </div>

        ))}
        <div className={errorForImg?'row':"hide"}><span style={{ color: "red",fontSize:"12px" }}>
        Please upload at least one image.
                                        </span></div>
                             
                    <div className='row margintop'>
                    <div className='col-2'>
                        <button className='secondry-btnn '  onClick={props.handleClose} style={{height:"40px",borderRadius:"20px"}}>Cancel</button>
                    </div>
                    <div className='col-2'>
                    <button className='primary-btnn ' type='submit' style={{height:"40px",fontWeight:"500",marginLeft:"17px"}}>Create Event</button>
                  </div>
                 

                  </div>
                    </div>
                    
                    
                     
                  </div>
                  
                  </form>

                  <div className={CountrystateDiv ? "" : "hide"}>
                <div className="popup-boxcheckdetail">
                  <div className="boxcheckdetail" style={{marginLeft:"510px"}}>
                    <div className="topnewsrow">
                      <div className="row">
                        <div className="col-4"></div>
                        <div className="col-8">
                          <span className="blood-top-text">Location Details</span>
                        </div>
                      </div>


                    </div>
                   
                    <div className="row margintop">
                  <div className="col-lg-3 col-sm-12 col-md-12 textright mt-1"><span className="event-sub-detail">Country</span></div>
                  <div className="col-lg-6 col-sm-12 col-md-12 textleft">
                    {/* <Select
                      // required
                      id="country"
                      name="country"
                      label="country"
                      className="countrydrp "
                      rules={{ required: true }}
                      options={updatedCountries}
                      // onChange={(value) => {
                      //   setValues({ country: value, state: null, city: null }, false);
                      // }}
                      // defaultValue={updatedCountries.filter((value) => value.name == 'India')}
                      onChange={(event) =>
                        setcoffeeData({ ...coffeeData, country: event.name, countryId: event.id })
                      }

                      style={{ fontSize: '13px' }}
                    /> */}

                <select   id="countryy"  
                className=" form-control "
                name='country'
                value={eventdata?.country}
                onChange={handleCountryChange}>
                <option selected value="">Select</option>
                {
                  
                  countryData.map((result) =>(
                  <option  key={result.id}  value={`${result.id}-${result.country}`}>{result.country}</option>
                  ) )

                }
                </select> 
                  </div>
                </div>
                <div className='row '>
                  <div className='col-4'></div>
                  <div className=" col-4 ">
                    {error && CountrystateDiv==true && eventdata.country == "" ? (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        Country is required.
                      </span>
                    ) : (
                      ""
                    )}
                  </div>


                </div>
                <div className="row margintop">
                  <div className="col-lg-3 col-sm-12 col-md-12 textright mt-1"><span className="event-sub-detail">State</span></div>
                  <div className="col-lg-6 col-sm-12 col-md-12 textleft">
                    {/* <Select
                      name="state"
                      id="state"
                      className="countrydrp"

                      options={updatedStates(coffeeData.countryId ? coffeeData.countryId : null)}

                      onChange={(event) =>
                        setcoffeeData({ ...coffeeData, state: event.name, stateId: event.id })
                      }
                      style={{ fontSize: '13px' }}

                    /> */}
                     <select   id="state"  
                className=" form-control "
                name='state'
                value={eventdata?.state}
                onChange={handleStateChange}>
                <option selected value="">Select</option>
                {
                  
                  stateData.map((result) =>(
                  <option  key={result.id}  value={`${result.id}-${result.state}`}>{result.state}</option>
                  ) )

                }
                </select> 
                  </div>
                </div>
                <div className='row '>
                  <div className='col-4'></div>
                  <div className=" col-4 ">
                    {error && CountrystateDiv==true && eventdata.state == "" ? (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        State is required.
                      </span>
                    ) : (
                      ""
                    )}
                  </div>


                </div>
                <div className="row margintop">
                  <div className="col-lg-3 col-sm-12 col-md-12 textright mt-1"><span className="event-sub-detail">City</span></div>
                  <div className="col-lg-6 col-sm-12 col-md-12 textleft">
                    {/* <Select
                      id="city"
                      className="countrydrp"
                      name="city"
                      options={updatedCities(coffeeData.stateId ? coffeeData.stateId : null)}

                      onChange={(event) =>
                        setcoffeeData({ ...coffeeData, city: event.name, cityId: event.id })
                      }
                      style={{ fontSize: '13px' }}
                    /> */}

                <select   id="city"  
                className=" form-control "
                name='city'
                value={eventdata?.city}
                onChange={handleCityChange}>
                <option selected value="">Select</option>
                {
                  
                  cityData.map((result) =>(
                  <option  key={result.id}  value={`${result.id}-${result.city}`}>{result.city}</option>
                  ) )

                }
                </select>
                  </div>
                </div>
                <div className='row '>
                  <div className='col-4'></div>
                  <div className=" col-4 ">
                    {error && CountrystateDiv==true && eventdata.city == "" ? (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        City is required.
                      </span>
                    ) : (
                      ""
                    )}
                  </div>


                </div>


                    <div className='popupbtn-div margintop'>

                      <div className='row'>
                        <div className='col-3'></div>
                        <div className='col-3'>
                          <button className='cancelbtn' style={{ width: '130px', height: "42px" }} onClick={() => setCountrystateDiv(false)}>Close</button>
                        </div>
                        <div className='col-3'>
                          <button className='attendbtn' style={{ width: '130px', height: "42px", marginLeft: "px" }}onClick={() => setCountrystateDiv(false)}>Save</button>
                        </div>

                      </div>
                    </div>

                  </div>
                </div>
              </div>  
                  
      </div>
      
    </div>
  );
};
 
export default AddNewsPopup;