import './AddNewsPopup.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import React, { useState, useEffect,useRef } from "react";
import AddKycPopup from "./AddKycPopup";
import AddNewsPopup from "./AddNewsPopup";
import moment, { duration } from 'moment'
import validator from "validator";
import axios from "axios";
import { useHistory } from "react-router-dom";

import { toast } from "react-toastify";
import AddBlogPopup from './AddBlogPopup';
import {configData} from "../../Config"
import { RiGhost2Fill } from 'react-icons/ri';
import { MdOutlineBusinessCenter } from "react-icons/md";


 
const AddJobPop = props => {

  //for session
  let history = useHistory();

  // const [session ,setSession] = useState(false);

  // const gesessiondata = async () => {
  //    debugger;
  //    axios.get('/checkSession')
      
  //    .then(result =>{
  //      if(result.data.code == 501){
  //        setSession(true)  ;
  //         }
  //     else{
  //          history.push("/Login");

  //     }
  //      debugger;
  //      console.log(result.data.code);
  //    }).catch(error =>{
  //      console.log(error);
  //    })
  //  };

  //        console.log("checksession on campaign",session);

  // useEffect(() => {
  //     gesessiondata();
  //   }, [session]);
  

    const [startDate, setStartDate] = useState(new Date());

    const handleChangedate= (e) => {
      debugger
     setStartDate(e)
     setJob(preState => ({
      ...preState,
      "scheduledDate": (moment(e).format("DD MMM yyyy")),

    }))
    }

    const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
console.log("authdata11",authlocalstoragedata)

    const [job, setJob] = useState({
      postedById:authlocalstoragedata?.userAuthenticationId,
      postedByName:authlocalstoragedata?.name2,
      jobOpeningFor:"",
      others:"",
      experience:"",
      availableIn:"",
      skillSets:"",
      email:authlocalstoragedata?.emailId,
      mobileNumber:authlocalstoragedata?.mobileNumber,
      
      })


      const [attachment, setAttachment] = useState([])

      const [error, setError] = useState(false);

      const submitFormData = (e) => {
        debugger
        e.preventDefault();
    
        // checking if value of first name and last name is empty show error else take to step 2
         if (validator.isEmpty(job.skillSets)) {
             setError(true);
         }
         else if (job.skillSets.length > 300) {
          setError(true);

        }
         else if (validator.isEmpty(job.availableIn)) {
             setError(true);
         }
         
         else if (validator.isEmpty(job.experience)) {
           setError(true);
         }
         
         else if (validator.isEmpty(job.jobOpeningFor)) {
           setError(true);
    
         }
         
       
       
     else {
      
      addBlogDetails();      
      
       }
    };
   
    const addBlogDetails =()=>{
      
      const formData = new FormData();

      if(job.authorName!=undefined){
        setJob(preState =>({
          ...preState,
          "postedByName":authlocalstoragedata?.name2
        }))
      }

      if(job.authorName!=undefined){
        setJob(preState =>({
          ...preState,
          "postedById":authlocalstoragedata?.userAuthenticationId
        }))
      }
debugger;
      formData.append('job',JSON.stringify(job));
      formData.append('resume', attachment);

      const response = axios.post(configData.ApiUrl+'/CommunityPost/createJobPost', formData)
      response.then(() => {
            toast.success("Job Post Added Successfully!");
            props.handleClose(togglePopuptopJobPop)

          })
         response.catch((err) => {
           alert("Getting error in featching data")
         });
         console.log("response", response?.data)
          console.log("job",job)
     }

    const [selected, setSelected] = useState('job');

const [isOpen, setIsOpen] = useState(false);
  const togglePopup = () => {
    setIsOpen(!isOpen);
  }

  const [isOpenBlog, setIsOpenBlog] = useState(false);
  const togglePopupBlog = () => {
    setIsOpenBlog(!isOpenBlog);
  }
  const [isOpenkyc, setIsOpenkyc] = useState(false);
  const togglePopupKyc = () => {
    setIsOpenkyc(!isOpenkyc);
  }
  const [isOpentopjobPop, setisOpentopjobPop] = useState(false);
  const togglePopuptopJobPop = () => {
    setisOpentopjobPop(!isOpentopjobPop);
  }
  
  const maxchar = 300;
  const [charLeft, setCharLeft] = useState(maxchar);

  const handleChange = event => {
    setSelected(event.target.value);
    const {name,value,type,checked}=event.currentTarget;
        debugger
        
        setJob(preState =>({
          ...preState,
          [name]:type ==='checkbox'?checked: value
        }))

        if(event.currentTarget.files != null)
        {
            setJob(event.target.files[0]);
        }

        if(job.authorName!=undefined){
          setJob(preState =>({
            ...preState,
            "postedByName":authlocalstoragedata?.name2
          }))
        }
  
        if(job.authorName!=undefined){
          setJob(preState =>({
            ...preState,
            "postedById":authlocalstoragedata?.userAuthenticationId
          }))
        }

        //for dynamic placeholder
        debugger
        let input = event.target.value;
  //  const maxchar = 300;
  //  if(input==""|| input==null){
  //   const charleft = maxchar - 0
  //   setcharlenght(charleft);

  //  }
  //  else{
  //   const charleft = maxchar - input.length
  //   setcharlenght(charleft);

  //  }
  const remainingChars = maxchar - (input.length || 0); // Calculate remaining characters
    setCharLeft(remainingChars > 0 ? remainingChars : 0); // Ensure it doesn't go below 0
  };
  const [selectedFilenameadharFront,setselectedFilenameadharFront]=useState(null)
const [fileSizeErr,setFileSizeErr]=useState(false)
  
const banerImageChange = event=>{

     if(event.currentTarget.files != null)
        {
          setAttachment(event.target.files[0]);
        }
        setselectedFilenameadharFront(event.currentTarget.files[0])
        if (event.currentTarget.files[0]?.size > 3 * 1000 * 1024) {
          console.log("File with maximum size of 3MB is allowed");
          //return false;
          setFileSizeErr(true)
        }
  }


  //for opening fields depends on radio cheked
  const [showhide, setShowhide]= useState("");


  const handleshow= e=>{
    const getshow= e.target.value;
    setShowhide(getshow);
  }
//for viewing   mbile
  const [showMobileShow, setshowMobileShow]= useState(false);


  const handleMobileshow=()=>{
    setshowMobileShow(true);
  }
  //for viewing email 
  const [showEmailShow, setshowEmailShow]= useState(false);


  const handleEmailshow=()=>{
    setshowEmailShow(true);
  }


  //for close popup when click on outside
const popupBlogdivRef = useRef(null);
const handleClickOutsideBlogtdiv = (event) => {
  if (popupBlogdivRef.current && !popupBlogdivRef.current.contains(event.target)) {
    props.handleClose();
  }
};
useEffect(() => {
  document.addEventListener('mousedown', handleClickOutsideBlogtdiv);
  return () => {
    document.removeEventListener('mousedown', handleClickOutsideBlogtdiv);
  };
}, []);
  
return (
    <div className="popup-boxnews">
              <div className="boxnews" style={{height:"96vh", marginTop: "10px"}}>
                                <div className="topnewsrow"style={{paddingTop:"11px",paddingBottom:"7px",backgroundColor:"#FF84270D",borderColor:"#FF84270D"}} >
                                <div className="row " id="sharediv"> 
              
                                  <span className='post-host-Name ' style={{fontSize:"15px",textAlign:"left"}}><MdOutlineBusinessCenter size="1.3em"/> Share an Looking For Job Post</span>
                                  </div>
                                </div>
                  <form onSubmit={submitFormData}>

                  <div className='row margintop1'>
                  <div className='col-6'>
                    <div className="row margintop1">
                    <div className="col-11 textleft "><span className="post-host-Name "style={{color:"#626262",fontSize:"13px"}}>Posted by</span>
                    <div className=" textleft "><span className="post-host-Name "style={{color:"#212529",fontSize:"13px"}}>{authlocalstoragedata?.name2}</span></div>
                    </div>
                </div>
                <div className="row margintop1">
                    <div className="col-11 textleft "><span className="post-host-Name "style={{color:"#626262",fontSize:"13px"}}>Hometown</span>
                    <div className=" textleft "><span className="post-host-Name "style={{color:"#212529",fontSize:"13px"}}>{authlocalstoragedata?.homeCity} {authlocalstoragedata?.state}</span></div>
                    </div>
                </div>
                <div className="row margintop1">
                    <div className="col-11 textleft"><span className="post-host-Name "style={{color:"#626262",fontSize:"13px"}}>Current Location</span>
                    <div className=" textleft "><span className="post-host-Name "style={{color:"#212529",fontSize:"13px"}}>{authlocalstoragedata?.currentCity} {authlocalstoragedata?.state}</span></div>
                    </div>
                </div>
              
                
                
                
                <div className="row margintop2">
                    <div className="col-11 textleft" style={{whiteSpace:"wrap"}}><span className="post-host-Name "style={{color:"#626262",fontSize:"13px"}}>Looking for a job opening for</span>
                    <div className=" textleft form-group">
                    <select   id="inputState"  
                className=" form-control "
                name='jobOpeningFor'
                onChange={handleChange} >
                <option selected value="">Select Category</option>
                <option  value="Analytics Manager">Analytics Manager</option>
                <option  value="Financial Analyst">Financial Analyst</option>
                <option  value="Data Analyst">Data Analyst</option>
                <option  value="Business Analyst">Business Analyst</option>
                <option  value="Charted Accountant">Charted Accountant</option>
                <option  value="Account Executive">Account Executive </option>
                <option  value="Software Developer">Software Developer</option>
                <option  value="Testing Engineer">Testing Engineer</option>
                <option  value="Networking Engineer">Networking Engineer</option>
                <option  value="Java Developer">Java Developer</option>
                <option  value="Frontend  Developer">Frontend  Developer</option>
                <option  value="UI Designer">UI Designer</option>
                <option  value="Graphic Designer">Graphic Designer</option>
                <option  value="Solution Architect">Solution Architect</option>
                <option  value="Data Security Analyst">Data Security Analyst</option>
                <option  value="Machin Learning Engineer">Machin Learning Engineer</option>
                <option  value="Electrical Engineer">Electrical Engineer</option>
                <option  value="Mechanical Engineer">Mechanical Engineer</option>
                <option  value="Technician">Technician</option>
                <option  value="Logistics manager/supply chain manage">Logistics manager/supply chain manager</option>
                <option  value="Marketing manager">Marketing manager</option>
                <option  value="SEO manager">SEO manager</option>
                <option  value="Content writer">Content writer</option>
                <option  value="Social media marketer">Social media marketer</option>
                <option  value="Product manager">Product manager</option>
                <option  value="Sales manager">Sales manager</option>
                <option  value="business developer">business developer</option>
                <option  value="Customer service executive">Customer service executive</option>
                <option  value="Teacher">Teacher</option>
                <option  value="Librarian">Librarian</option>
                <option  value="Counsellor">Counsellor</option>
                <option  value="HR manager">HR manager</option>
                <option  value="legal officer">legal officer</option>
                <option  value="Garment designer/textile designer">Garment designer/textile designer</option>
                <option  value="Jewellery designer">Jewellery designer</option>
                <option  value="Architect/interior designer">Architect/interior designer</option>
                <option  value="Travel agent">Travel agent</option>
                <option  value="Branch head/branch manager">Branch head/branch manager</option>
                <option  value="Healthcare professional/Doctor">Healthcare professional/Doctor</option>
                <option  value="Operations manager">Operations manager</option>
                <option  value="Insurance advisor">Insurance advisor</option>Food runner
                <option  value="Food runner">Food runner</option>
                <option  value=" Dishwasher"> Dishwasher</option>
                <option  value="Grill cook">Grill cook</option>
                <option  value="Prep cook">Prep cook</option>
                <option  value="Pantry cook"> Pantry cook</option>         
                <option  value="Baker"> Baker</option>
                <option  value="Food and beverage manager">Food and beverage manager</option>
                <option  value="Executive chef">Executive chef</option>
                <option  value="Homemaker"> Homemaker</option>
                <option  value="Others"> Others</option>

                <div className={job.jobOpeningFor=="Others"?'margintop':"hide"}>
              {
    job.jobOpeningFor=="Others" && (
      <input type="text" className=" form-control" id="fst"
         name="others"
         placeholder="Looking for job opening for"
         onChange={handleChange}
         value={job.others}
         style={{fontSize:'12px',textTransform:"capitalize"}}    />
    )
    }
            </div>

                </select>
                   </div>
                   </div>
                </div>
                 <div className='row textleft'>
               <div className="col-8">
                                    {error && job.jobOpeningFor == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Looking for job Opening is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    {error && jobOpeningFor.others=="" && job.jobOpeningFor=='Others' ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Other  is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    
                                </div>
                                
                                
  </div> 
  <div className="row margintop1">
                    <div className="col-11  textleft"><span className=" post-host-Name "style={{color:"#626262",fontSize:"13px"}}>Total Experience</span>
                    <div className=" textleft form-group">
                    <select   id="inputState"  
                className=" form-control "
                name='experience'
                onChange={handleChange} >
                <option selected value=""> Please Select</option>
                <option  value="0-1 Years">0-1 Years</option>
                <option  value="1-2 Years">1-2 Years</option>
                <option  value="2-3 Years">2-3 Years</option>
                <option  value="3-4 Years">3-4 Years</option>
                <option  value="4-5 Years">4-5 Years</option>
                <option  value="5-6 Years">5-6 Years</option>
                <option  value="6-7 Years">6-7 Years</option>
                <option  value="7-8 Years">7-8 Years</option>
                <option  value="8-9 Years">8-9 Years</option>
                <option  value="9-10 Years">9-10 Years</option>
                <option  value="More than Years">More than Years</option>

                </select>
                   </div>
                </div>
                </div>
                 <div className='row textleft'>
               <div className="col-8">
                                    {error && job.experience == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Experience is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    
                                </div>
                                
                                
  </div> 
                
  <div className="row margintop1">
                    <div className="col-11  textleft"><span className=" post-host-Name "style={{color:"#626262",fontSize:"13px"}}>Available for job in</span>
                    <div className=" textleft form-group">
                    <select   id="inputState"  
                className=" form-control "
                name='availableIn'
                onChange={handleChange} >
                <option selected value="">Select Category</option>
                <option  value="Immediately Available">Immediately Available</option>
                <option  value="In a week">In a week</option> 
                <option  value="In 30 Day">In 30 Day</option>
                <option  value="In 60 Day">In 60 Day</option>

                </select>
                   </div>
                </div>
                </div>
                 <div className='row textleft'>
               <div className="col-8">
                                    {error && job.availableIn == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Available for job in is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    
                                </div>
                                
                                
  </div>           
  </div>
  <div className='col-6'>
        
        <div className="row margintop1">
                          <div className="col-11 textleft"><span className="post-host-Name "style={{color:"#626262",fontSize:"13px"}}>Resume (Optional)</span>
                          <div className=' '>
                                  <label for="selectFile" name="fileupload" className="bluetextforlink" style={{ backgroudColor: "white" }}   >
                Upload Resume
                                 </label>
                           <input id="selectFile" type="file"  className="fileupload hide" 
                             name="bannerImage"  accept='.doc,.docx,application/pdf'  onChange={banerImageChange}
                              />
                                    </div>
                                    </div>
                                    <div className='col-11'>
                                    <span className='event-sub-detail textShowondotBuss'style={{width:"230px",marginTop:"5px"}}>{selectedFilenameadharFront?.name}</span> 
                                    <div className="col-8">
                                          
                                          <span className={fileSizeErr?"":"hide"} style={{ color: "red",fontSize:"12px" }}>
                                          File with maximum size of 3MB is allowed                                        </span>
                                      
                                      
                                  </div>
                                    </div>
                                    
                      </div>
                      
                      
                      <div className="row ">
                          <div className="col-11 textleft " style={{marginTop:"-10px"}}><span className="post-host-Name "style={{color:"#626262",fontSize:"13px"}}>Email Id</span>
                          <div className=" textleft ">
                              {/* {showMobileShow==true ?
                              ( */}
                              <span className="post-host-Name "style={{color:"#212529",fontSize:"13px"}}>{authlocalstoragedata?.emailId}</span>
                              {/* ):
                              (<span className="bluetextforlink" style={{fontSize:"12px"}} onClick={handleMobileshow}>View</span>)} */}
                              </div>
                              </div>
                      </div>
                      <div className="row margintop1">
                          <div className="col-11 textleft "><span className="post-host-Name "style={{color:"#626262",fontSize:"13px"}}>Mobile No.</span>
                          <div className=" textleft ">
                          {/* {showEmailShow==true ? */}
                          {/* ( */}
                            <span className="post-host-Name "style={{color:"#212529",fontSize:"13px"}}>{authlocalstoragedata?.mobileNumber}</span>
                            {/* ):
                              (<span className="bluetextforlink" style={{fontSize:"12px"}} onClick={handleEmailshow}>View</span>
                              )}                        */}
                               </div>
                               </div>
                      </div>
                      </div>
  <div className="row margintop1">
                    <div className="col-11 textleft "><span className="post-host-Name "style={{color:"#626262",fontSize:"13px"}}>Write in brief about your skill sets(Optional)</span>
                    <div className=" textleft form-group">
                    <textarea class="form-control" name='skillSets' maxLength={maxchar} disabled={charLeft === 0} onChange={handleChange} id="exampleFormControlTextarea1" rows="4"  placeholder={`${charLeft} characters left`} style={{fontSize:"13px"}}/>
                    <div style={{float:"right"}}><span className="post-host-Name " style={{color:"#797979",fontSize:"12px"}}>{`${charLeft} characters left`}</span></div>
                        </div>
                        </div>
                </div>
                
                 <div className='row textleft'>
               <div className="col-9">
                                    {error && job.skillSets == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            SkillSets is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    {error && job.skillSets?.length >300  ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Skillsets cannot exceed more than 300 characters..
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div> 
               

                  </div>
                <div className='row  '>
                    <div className='col-2'>
                        <button className='cancelbtn'  onClick={props.handleClose}style={{height:"40px",width:"140px",borderColor:"#FF8427",color:"#FF8427",borderRadius:"20px"}}>Cancel</button>
                    </div>
                    <div className='col-4'>

                    
                     <button className='createeventbtn' type='submit' style={{height:"40px",width:"215px",backgroundColor:"#FF8427",borderColor:"#FF8427",color:"#ffffff",borderRadius:"20px",fontWeight:"500",marginLeft:"17px"}}>Post Availbility for Job</button> 
                
                     
                                  </div>


                 
           </div>  
</form>
         
      </div>
    </div>
  );
};
 
export default AddJobPop;