import React, { useState, useEffect, useRef } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { columns, data, heading, Rows } from "./Data1";
import { Link } from "react-router-dom";
import { AiOutlineFilePdf } from 'react-icons/ai'
import { Breadcrumb, Button, Card, Col, Form, FormGroup, Dropdown, ButtonGroup, InputGroup, Row, Carousel, Pagination } from 'react-bootstrap';
import imgupload from '../../images/imageupload.svg';
import coffeemeetimg from '../../images/Group 617.png';

import { AiOutlineCaretDown } from "react-icons/ai";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import csc from "country-state-city";
import Select from "react-select";
import { FaFilter } from "react-icons/fa";
import profile from '../../images/sagarsir.svg';
import axios from "axios";
import { useHistory } from "react-router-dom";

import validator from "validator";
import Multiselect from 'multiselect-react-dropdown';
import { event } from "jquery";
import { toast } from "react-toastify";
import moment, { duration } from 'moment'
//import ReactPaginate from "react-paginate";
import {configData} from "../../Config"
import ReactTooltip from "react-tooltip";
import '../ViewProfile/ReferCommMemberPopup.css'
import Avatar from '@mui/material/Avatar';
import { RiCupFill, RiSendPlaneFill, RiTimeLine } from 'react-icons/ri';




const CoffeeMeets = (props) => {

  //for session
  // let history = useHistory();

  // const [session ,setSession] = useState(false);

  // const gesessiondata = async () => {
  //    debugger;
  //    axios.get('/checkSession')
      
  //    .then(result =>{
  //      if(result.data.code == 501){
  //        setSession(true)  ;
  //         }
  //     else{
  //          history.push("/Login");

  //     }
  //      debugger;
  //      console.log(result.data.code);
  //    }).catch(error =>{
  //      console.log(error);
  //    })
  //  };

  //        console.log("checksession on campaign",session);

  // useEffect(() => {
  //     gesessiondata();
  //   }, [session]);
  


  const [isOpendiv, setIsOpendiv] = useState(true);
  const [iscofeediv1, setIscofeediv1] = useState(false);
  const [iscofeediv2, setIscofeediv2] = useState(false);
  const [isOpenMyCoffeediv, setIsOpenMyCoffeediv] = useState(false);



  const onNext = (isback) => {

    if (isback) {
      setIsOpendiv(true);
      setIscofeediv1(false);
      setIscofeediv2(false)
      setIsOpenMyCoffeediv(false);
      setError(false);

    }
    else {
      setIsOpendiv(false);
      setIscofeediv1(true)
      setIscofeediv2(false)
      setIsOpenMyCoffeediv(false)


    }


  }

const onbackMeetUp=()=>{
  setIsOpendiv(true);
  setIscofeediv1(false);
  setIscofeediv2(false)
  setIsOpenMyCoffeediv(false)
  setError(false);
}
const onbackMyMeetUp=()=>{
  setIsOpendiv(false);
  setIscofeediv1(false);
  setIscofeediv2(false)
  setIsOpenMyCoffeediv(true)
  setError(false);
}


  const onNextOther = (isback1) => {

    debugger;
    if (validator.isEmpty(coffeeData.date)) {
      setError(true);
    }
    else if(CountrystateDiv==true) {
   if (validator.isEmpty(coffeeData.country)) {
      setError(true);
   }
    }
    else if(CountrystateDiv==true) {
     if(validator.isEmpty(coffeeData.state)) {
      setError(true);
     }
    }
    else if (validator.isEmpty(coffeeData.venue)) {
      setError(true);

    }
    else if (selectedSlot === "" || amPm === "") {
      setError(true);

    }
    



    else {
      if (isback1) {
        setIsOpendiv(true);
        setIscofeediv1(false);
        setIscofeediv2(false)
        setIsOpenMyCoffeediv(false)
        setError(false);

      }
      else {
        setIsOpendiv(false);
        setIscofeediv1(false)
        setIscofeediv2(true)
        setIsOpenMyCoffeediv(false)

      }
      //addCoffeeMeet();
    }



  }
  //for my coffee meetups
  const onNextMyCoffee = (isbackmy) => {

    if (isbackmy) {
      setIsOpendiv(false);
      setIscofeediv1(false);
      setIscofeediv2(false)
      setIsOpenMyCoffeediv(true)
    }
    else {
      setIsOpendiv(true);
      setIscofeediv1(false)
      setIscofeediv2(false)
      setIsOpenMyCoffeediv(false)

    }


  }

  const [startDate, setStartDate] = useState(new Date());

  const handleChangedate = (e) => {
    debugger
    // const { name, value, type, checked } = e.currentTarget;
    // if(type=="Text")
    // {
    //   setcoffeeData(preState => ({
    //     ...preState,
    //     [name]: type === 'checkbox' ? checked : value
    // }));
    // }

    // else{
    setStartDate(e);
    setcoffeeData(preState => ({
      ...preState,
      "date": (moment(e).format("DD MMM yyyy"))
    }));
    // }


  }

  const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
  console.log("authdata11", authlocalstoragedata)

  const [uerAuthId, setuerAuthId] = useState(authlocalstoragedata?.userAuthenticationId);
  const [userhostname, setuserhostname] = useState(authlocalstoragedata?.name2);
  const [authdirectory, setauthDirectory] = useState(authlocalstoragedata?.directory);


 ///timeslot
//  const [selectedPeriod, setSelectedPeriod] = useState(''); // Store "morning" or "evening"
//   const [selectedSlot, setSelectedSlot] = useState(''); 
//  const morningSlots = ['8am', '9am', '10am'];
//  const eveningSlots = ['5pm', '6pm', '7pm'];

//  const handleSlotSelection = (slot) => {
//   setSelectedSlot(slot);
// };

const [selectedSlot, setSelectedSlot] = useState('');
const [selectedMinute, setSelectedMinute] = useState('');
  const [activePeriod, setActivePeriod] = useState(''); // 'morning' or 'evening'
  const [amPm, setAmPm] = useState(''); // Track AM/PM button selection
  const [isManual, setIsManual] = useState(false); // Track if manual time is selected
  // Time slots for morning and evening
  const morningSlots = ['08', '09', '10'];
  const eveningSlots = ['05', '06', '07'];

  // Function to handle the time slot selection
  const handleSlotSelection = (slot) => {
    setSelectedSlot(slot);
    setSelectedMinute("00");
    setIsManual(false); // Reset manual flag since a predefined slot is selected
    if (morningSlots.includes(slot)) {
      setAmPm('AM'); // Set AM for morning slots
    } else if (eveningSlots.includes(slot)) {
      setAmPm('PM'); // Set PM for evening slots
    }
  };

  // Function to handle manual selection
  const handleManualSelection = (slot) => {
    setSelectedSlot(slot);
    setAmPm(''); // Reset AM/PM selection since manual time was chosen
    setIsManual(true); // Mark it as manual
    setActivePeriod(''); // Reset Morning/Evening button selection when manual time is chosen

  };
  // Handle manual minute selection
const handleManualMinuteSelection = (minute) => {
  setSelectedMinute(minute);
};
 // Function to handle AM/PM button click
 const handleAmPmSelection = (period) => {
  setAmPm(period);
};

////////for 24format

// Utility function to convert selected time to 24-hour format
const convertTo24HourFormat = (hour, minute, period) => {
  let hourIn24 = parseInt(hour, 10);
  if (period === 'PM' && hourIn24 !== 12) {
    hourIn24 += 12; // Convert PM hours to 24-hour format
  } else if (period === 'AM' && hourIn24 === 12) {
    hourIn24 = 0; // Handle midnight
  }
  // Format hour and minute to ensure two digits
  const formattedHour = hourIn24.toString().padStart(2, '0');
  const formattedMinute = minute.padStart(2, '0');
  return `${formattedHour}:${formattedMinute}`;
};

  const [coffeeData, setcoffeeData] = useState({
    hostId: authlocalstoragedata?.userAuthenticationId,
    hostName:authlocalstoragedata?.name2,
    date: "",
    countryCode: "",
    country: "",
    stateCode: "",
    state: "",
    cityCode: "",
    city: "",
    venue: "",
    //time: "",
    time:"",
    guests: [],
    googleMapsLink:""


  });

  const countries = csc.getAllCountries();
  //console.log(csc.getAllCountries())

  const updatedCountries = countries.map((country) => ({
    label: country.name,
    value: country.id,
    ...country
  }));

  const updatedStates = (countryId) => csc.getStatesOfCountry(countryId)
    .map((state) => ({ label: state.name, value: state.name, ...state }));

  const updatedCities = (stateId) =>
    csc
      .getCitiesOfState(stateId)
      .map((city) => ({ label: city.name, value: city.name, ...city }));


      //for country city state
const [countryData, setcountryData] = useState([]);

useEffect(() => {
  loadCountryData();
}, []);

const loadCountryData = async () => {
  debugger
  const result = await axios.get(configData.ApiUrl+"/user/listOfCountries");
  setcountryData(result.data.data);
  console.log("country",result.data.data)
};

const handleCountryChange = (event) => {
  const [id, country] = event.target.value.split("-");
  setcoffeeData((prevState) => ({
    ...prevState,
    countryId: id,
    country: country,
  }));
  loadStateData(id);
};
//for state city state
const [stateData, setstateData] = useState([]);

useEffect(() => {
loadStateData();
}, []);

const loadStateData = async (countryId) => {
debugger
const result = await axios.get(`${configData.ApiUrl}/user/listOfStates?countryId=${countryId}`);
setstateData(result.data.data);
console.log("state",result.data.data)
};

const handleStateChange = (event) => {
debugger
const [id, state] = event.target.value.split("-");
setcoffeeData((prevState) => ({
...prevState,
stateId: id,
state: state,
}));
loadCityData(id);

};
//for state city state
const [cityData, setcityData] = useState([]);

useEffect(() => {
loadCityData();
}, []);

const loadCityData = async (stateId) => {
debugger
const result = await axios.get(`${configData.ApiUrl}/user/listOfCities?stateId=${stateId}`);
setcityData(result.data.data);
console.log("city",result.data.data);
};

const handleCityChange = (event) => {
const [id, city] = event.target.value.split("-");
setcoffeeData((prevState) => ({
...prevState,
cityId: id,
city: city,
}));
};



  useEffect(() => {
    console.log(coffeeData);
  }, [coffeeData]);
  //   const dattatata="";
  //   const filterCity =(stateId)=>

  //      csc.getCitiesOfState(stateId)
  //      .map((city) => ({ label: city.name, value: city.name, ...city }));

  //  const onchangeFiltercity =(e)=>{
  //   const {name,value,type,checked}=e.currentTarget;

  //   setcoffeeFilterData(preState => ({
  //     ...preState,
  //     name: value
  //   }))
  //  }

  const handleChange = (e) => {
    debugger;
    const { name, value, type, checked } = e.currentTarget;


    setcoffeeData(preState => ({
      ...preState,
      [name]: type === 'checkbox' ? checked : value
    }));
    if(!CountrystateDiv){
      setcoffeeData((preState) => ({
        ...preState,
        country: authlocalstoragedata?.currentCountry,
        state: authlocalstoragedata?.currentState,
        city: authlocalstoragedata?.currentCity
      }));
    }
    // if(CountrystateDiv == false){
    //     setcoffeeData(preState =>({
    //       ...preState,
    //       "state":authlocalstoragedata?.currentState
    //     }))}
    // if(CountrystateDiv == false){
    //   setcoffeeData(preState =>({
    //         ...preState,
    //         "city":authlocalstoragedata?.currentCity
    //     }))}
  }


  const [cityDiv, setcityDiv] = useState(false);
  const [professionDiv, setprofessionDiv] = useState(false);
  const [connectDiv, setconnectDiv] = useState(false);


  const handleChangeforkycoffline = (e) => {
    debugger;
    const { name, value, type, checked } = e.currentTarget;
    setcoffeeFilterData(preState => ({
      ...preState,
      name: e.target.value
    }))

    if (e.target.value == "City") {
      //setisfilterdata( e.target.value )
      setcityDiv(true);
      setprofessionDiv(false);
      setconnectDiv(false)
    }
    else if (e.target.value == "profession") {
      setcityDiv(false);
      setprofessionDiv(true);
      setconnectDiv(false)
    }
    else if (e.target.value == "all") {
      setcityDiv(false);
      setprofessionDiv(false);
      setconnectDiv(true);
      loadCityFilerUser(e.target.value );

    }
    else if (e.target.value == "connects only") {

      setcityDiv(false);
      setprofessionDiv(false);
      setconnectDiv(true);
      loadCityFilerUser(e.target.value );

    }
    else{
      loadCityFilerUser();

    }

    


  }
  const [showinvitbtn, setshowinvitbtn] = useState(false);
  var gst = [];
  const [arrayData, setArrayData] = useState([])

  const showbtn = (e) => {
    debugger;
    const { name, value, type, checked } = e.currentTarget;

     if (checked) {
       if (value != "") {
         var dd = cityfilterUser.filter(obj =>
           obj.id == value)[0];

         setArrayData(arrayData => [...arrayData, dd]);

       }
     }
     else {
       if (value != "") {
         setArrayData(arrayData => (
           arrayData.filter((valuedata) => valuedata.id != value)
         ));
       }
     }
    
    setshowinvitbtn(true);
    console.log("coffeeData", coffeeData)
  }

  const removebtn = (e) => {
    debugger;

     let val = e.currentTarget.getAttribute('value');
     
       if (val != "") {
         setArrayData(arrayData => (
           arrayData.filter((valuedata) => valuedata.id != val)
         ));
       }
     
    
    setshowinvitbtn(true);
    console.log("coffeeData", coffeeData)
  }


  const [coffeeFilterData, setcoffeeFilterData] = useState({
    hostId: "",
    city: "",
    profession: "",
    filterStatus: ""
  });

  const [error, setError] = useState(false);

  const submitFormData = (e) => {
    debugger
    e.preventDefault();

    // checking if value of first name and last name is empty show error else take to step 2
    if (validator.isEmpty(coffeeData.date)) {
      setError(true);
    }
    else if(CountrystateDiv==true) {
     if (validator.isEmpty(coffeeData.country)) {
      setError(true);
    }
  }
    else if(CountrystateDiv==true) { 
    if (validator.isEmpty(coffeeData.state)) {
      setError(true);
    }
  }
    else if (validator.isEmpty(coffeeData.venue)) {
      setError(true);

    }
    else if (selectedSlot === "" || amPm === "") {
      setError(true);

    }
    else if(CountrystateDiv==true) {
     if (validator.isEmpty(coffeeData.city)) {
      setError(true);
     }
    }

  };

 

  const [errorGuestcount, seterrorGuestcount] = useState(false);

  const addCoffeeMeet = () => {
    debugger;
    // coffeeData.guests = arrayData
    if (selectedSlot === "" || amPm === ""||selectedMinute==="") {
      setError(true); // Set error if time is not selected
      return; // Stop execution if time is required
    } else {
      setError(false); // Reset error if time is valid
    }
    // Combine selectedSlot with amPm to form the time string
  const formattedTime = selectedSlot ? `${selectedSlot}:${selectedMinute} ${amPm}` : '';
const selectedTime = convertTo24HourFormat(selectedSlot, selectedMinute, amPm);

  setcoffeeData((prevData) => ({
    ...prevData,
    time: selectedTime, // Add the converted time here
  }));
  // Update coffeeData with the formatted time
  const coffeeDataToSend = {
    ...coffeeData,
    // time: formattedTime,
    time: selectedTime
  };
  coffeeDataToSend.guests = arrayData;
  if (!CountrystateDiv) {
    setcoffeeData((preState) => ({
      ...preState,
      country: authlocalstoragedata?.currentCountry,
      state: authlocalstoragedata?.currentState,
      city: authlocalstoragedata?.currentCity
    }));
  }
    
  if(arrayData.length<=3){
      seterrorGuestcount(true);
    }
    else{
      console.log("coffeemeetdata = ",coffeeData)
    const response = axios.post(configData.ApiUrl+'/coffeeMeet/addCoffeeMeet ', coffeeDataToSend)
    response.then(() => {
      toast.success("Coffee Meet Added Successfully!");
      onbackMyMeetUp();
      seterrorGuestcount(false);


    })
    response.catch((err) => {
      alert("Getting error in featching data")
    });
    }
    
    
  }



  var selectedCity = "";
  var selectedProfession = "";

  const [abc, setabc] = useState("");
  const [abcc, setabcc] = useState("");


  const handleChangeMultiSelect = (e) => {
    debugger;
    setabc(e.join())
    console.log("sss", abc);
    let abcd=[];
    // if(e != null && e !=undefined )
    // {
    //   debugger;
    //   for(var i=0;i<e.length;i++)
    //   {
    //     abcd.push(e[i].name);
    //   }
    // }
    loadCityFilerUser1(e)

  }
  const handleProfession = (e) => {
    debugger;

    selectedProfession = e.toString();
    console.log("sss", abcc)
    loadCityFilerUser1(e)

  }

  //for filteredlist of users on city
  
  

  const [cityfilterUser, setcityfilterUser] = useState([]);

  useEffect(() => {
    //loadCityFilerUser();
  }, []);

  const loadCityFilerUser = async ( allconnect) => {
    debugger;
    
    
     if (allconnect== 'connects only') {
      debugger
      const result = await axios.get(configData.ApiUrl+'/coffeeMeet/getFilteredListsOfUsers?filterStatus=' + allconnect + '&hostId=' + uerAuthId);
      setcityfilterUser(result.data.data);
      console.log("connects only", result.data.data);
      const rootElementcityfilteruser = document.getElementById('coffemituserlistprofid');

    if(rootElementcityfilteruser){
      rootElementcityfilteruser.addEventListener('contextmenu', handleContextMenuimgdisable);
    }
    }
    else  {
      debugger
      const result = await axios.get(configData.ApiUrl+'/coffeeMeet/getFilteredListsOfUsers?filterStatus=' + allconnect + '&hostId=' + uerAuthId);
      setcityfilterUser(result.data.data);
      console.log("all ", result.data.data);
      const rootElementcityfilteruser = document.getElementById('coffemituserlistprofid');

    if(rootElementcityfilteruser){
      rootElementcityfilteruser.addEventListener('contextmenu', handleContextMenuimgdisable);
    }
    }
   

  };

  const loadCityFilerUser1 = async (abc) => {
    debugger;
    
    if ((coffeeFilterData.name == 'City') && (abc?.length > 0)) {
      // if(abc.length==0)
      // {
      //   const result = await axios.get(`/coffeeMeet/getFilteredListsOfUsers?hostId=${uerAuthId}`);
      // setcityfilterUser(result.data.data);
      // console.log("bydefault ", result.data.data)
      // }
      //else{
      const result = await axios.get(configData.ApiUrl+"/coffeeMeet/getFilteredListsOfUsers?filterStatus=" + coffeeFilterData.name + "&city=" + abc.toString() + "&hostId=" + uerAuthId);
      setcityfilterUser(result.data.data);
      console.log("cityfilteruser", result.data.data);
      const rootElementcityfilteruser = document.getElementById('coffemituserlistprofid');

    if(rootElementcityfilteruser){
      rootElementcityfilteruser.addEventListener('contextmenu', handleContextMenuimgdisable);
    }
      //}

    }
    else if((coffeeFilterData.name == 'profession') && (abc?.length > 0))  {
      debugger
      const result = await axios.get(configData.ApiUrl+'/coffeeMeet/getFilteredListsOfUsers?filterStatus=' + coffeeFilterData.name + '&profession=' + abc.join().toString() + '&hostId=' + uerAuthId);
      setcityfilterUser(result.data.data);
      console.log("profession", result.data.data);
      const rootElementcityfilteruser = document.getElementById('coffemituserlistprofid');

    if(rootElementcityfilteruser){
      rootElementcityfilteruser.addEventListener('contextmenu', handleContextMenuimgdisable);
    }
      
    }
    else{
      {
        const result = await axios.get(`${configData.ApiUrl}/coffeeMeet/getFilteredListsOfUsers?hostId=${uerAuthId}`);
      setcityfilterUser(result.data.data);
      console.log("bydefault ", result.data.data);
      const rootElementcityfilteruser = document.getElementById('coffemituserlistprofid');

    if(rootElementcityfilteruser){
      rootElementcityfilteruser.addEventListener('contextmenu', handleContextMenuimgdisable);
    }
      }
    }
    

  };

  const loadCityFilerUser2 = async ( ) => {
    debugger;
    
    const result = await axios.get(`${configData.ApiUrl}/coffeeMeet/getFilteredListsOfUsers?hostId=${uerAuthId}`);
      setcityfilterUser(result.data.data);
      console.log("bydefault ", result.data.data);
      
      const rootElementcityfilteruser = document.getElementById('coffemituserlistprofid');

    if(rootElementcityfilteruser){
      rootElementcityfilteruser.addEventListener('contextmenu', handleContextMenuimgdisable);
    }
    

  };
  //for citylist
  const [cityList, setcityList] = useState([]);

  useEffect(() => {
    loadCityList();
    loadCityFilerUser2();
  }, []);

  const loadCityList = async () => {

    debugger;
    const result = await axios.get(configData.ApiUrl+'/coffeeMeet/listOfCities');
     let citydata = result.data.data.filter(obj =>
      obj != null);
    setcityList(citydata);
    console.log("citylist", result.data.data)
  };
  //profession
  const [naturOfBusiness, setnaturOfBusiness] = useState([]);

  useEffect(() => {
    loadNaturOfBusiness();
  }, []);

  const loadNaturOfBusiness = async () => {
    const result = await axios.get(configData.ApiUrl+"/user/natureOfBuisness");
    setnaturOfBusiness(result.data.data);
    console.log("busnessgrp", result.data.data);

  };

  //for googlemaplink
  const [googleMaplinkDiv, setgoogleMaplinkDiv] = useState(false);

  const togglePopupMessage = () => {
    setgoogleMaplinkDiv(!googleMaplinkDiv);
  }

   //for countrydiv
   const [CountrystateDiv, setCountrystateDiv] = useState(false);

   const togglePopupCountry = () => {
    setCountrystateDiv(!CountrystateDiv);
   }
 
  function handleContextMenuimgdisable(e) {
    debugger
    e.preventDefault(); // prevents the default right-click menu from appearing
  }

  //my coffeemeet list
  const [mycoffeemeetfilter, setmycoffeemeetfilter] = useState([]);

  useEffect(() => {
    loadMyCoffeeMeet();
  }, []);

  const loadMyCoffeeMeet = async () => {

    const result = await axios.get(`${configData.ApiUrl}/coffeeMeet/getListOfMyCoffeeMeets?hostId=${uerAuthId}`);
    props.setmycoffeemeet(result.data.data.reverse());
    setmycoffeemeetfilter(result.data.data.reverse())
    console.log("mycoffeemeet", result.data.data);
    const rootElement4mycoffee = document.getElementById('mycoffeeprofid');

    if(rootElement4mycoffee){
      rootElement4mycoffee.addEventListener('contextmenu', handleContextMenuimgdisable);
    }
  };
  ////recieved invitaion coffeemeet list
  const [otherscoffeemeetfilter, setotherscoffeemeetfilter] = useState([]);

  useEffect(() => {
    loadOthersCoffeeMeet();
  }, []);

  const loadOthersCoffeeMeet = async () => {

    const result = await axios.get(`${configData.ApiUrl}/coffeeMeet/getListOfInvitedCoffeeMeets?hostId=${uerAuthId}`);
    props.setotherscoffeemeet(result.data.data);
    setotherscoffeemeetfilter(result.data.data);
    console.log("othercoffeemeet", result.data.data);
    const rootElement4othercoffee = document.getElementById('othercoffeeprofid');

    if(rootElement4othercoffee){
      rootElement4othercoffee.addEventListener('contextmenu', handleContextMenuimgdisable);
    }
  };

  const avtarProf = props?.otherscoffeemeet[0]?.name?.substr(0, 1) 

  console.log("avtarProfcoffee",avtarProf)
  //////cancel  coffeemeet 
  const cancelMyCofeeMeet = async (id) => {

    debugger;
    const response = await axios.post(`${configData.ApiUrl}/coffeeMeet/cancelCoffeeMeet?coffeeMeetId=${id} `)
    if (response.status == 200 && response.data.code) {
      toast.success("Canceled Successfully!")
      loadMyCoffeeMeet();
      setisdeletepopup(false)
    }
    else {
      alert("Something Went Wrong..!")
    }
  }
  //////attend unattend  coffeemeet 
  const attendStatusCofeeMeet = async (id) => {
    debugger
    //e.preventDefault();
    setcoffeeFilterData(preState => ({
      ...preState,
      "hostId":uerAuthId,
      "hostName":userhostname
    }))


    debugger;
    const response = await axios.post(
      
      configData.ApiUrl+'/coffeeMeet/editGuestStatus?guestId=' + uerAuthId + '&coffeeMeetId=' + id)
    if (response.status == 200 && response.data.code) {
      toast.success("Update Status  Successfully!")
      loadOthersCoffeeMeet();

    }
    else {
      alert("Something Went Wrong..!")
    }
  }
  const attendStatusCofeeMeet1= async (id) => {
    debugger
    //e.preventDefault();
    setcoffeeFilterData(preState => ({
      ...preState,
      "hostId":uerAuthId,
      "hostName":userhostname
    }))

    const attendstatus="Attend"

    debugger;
    const response = await axios.post(
      configData.ApiUrl+'/coffeeMeet/respondInvitation?guestId=' + uerAuthId + '&coffeeMeetId=' + id+'&status='+attendstatus)
    if (response.status == 200 && response.data.code) {
      toast.success("Updated Attend Status Successfully!")
      loadOthersCoffeeMeet();
      //setHideRespond(true);
      setShowButtonsforAttend(false);

    }
    else {
      alert("Something Went Wrong..!")
    }
  }
  const attendStatusCofeeMeet2= async (id) => {
    debugger
    //e.preventDefault();
    setcoffeeFilterData(preState => ({
      ...preState,
      "hostId":uerAuthId,
      "hostName":userhostname
    }))

    const rejectstatus="Reject"

    debugger;
    const response = await axios.post(
      configData.ApiUrl+'/coffeeMeet/respondInvitation?guestId=' + uerAuthId + '&coffeeMeetId=' + id+'&status='+rejectstatus)
    if (response.status == 200 && response.data.code) {
      toast.success("Updated Reject Status Successfully!")
      loadOthersCoffeeMeet();
      //setHideRespond(true);
      setshowButtonsforReject(false);
    }
    else {
      alert("Something Went Wrong..!")
    }
  }

//for hide show buttons
const [showButtons, setShowButtons] = useState(false);
  const [hideRespond, setHideRespond] = useState(false);
  const handleRespondClickMouseOver = () => {
    setShowButtons(true);
  };
  // const handleMouseOver = () => {
  //   setShowButtons(true);
  // };

  const handleRespondClickMouseLeave = () => {
    setShowButtons(false);
  };


  //for attend status active
  const [showButtonsforAttend, setShowButtonsforAttend] = useState(false);
  const handleAttendClick = () => {
    setShowButtonsforAttend(true);
         // setShowButtonsforAttend(prevState => !prevState);

  };
  const handleAttendClickLeave = () => {
    setShowButtonsforAttend(false);
          //setShowButtonsforAttend(prevState => !prevState);

  };
    //for reject status active
    const [showButtonsforReject, setshowButtonsforReject] = useState(false);
    const handleRejectClick = () => {
      setshowButtonsforReject(true);
     // setshowButtonsforReject(prevState => !prevState);
  
    };
    const handleRejectClickLeave = () => {
      setshowButtonsforReject(false);
      //setshowButtonsforReject(prevState => !prevState);
  
    };

  //for search functionality
    const [searchVal, setSearchVal] = useState("");
    function handleSearchChange(e) {
      debugger
        if (e.currentTarget.value === "") 
        {  loadCityFilerUser2();
          loadCityFilerUser1();
          loadCityFilerUser();
          
           return; }
        const filterBySearch = pageData.filter((item) => {
          if ( item != null && item.name != null){
            if ( item?.name?.toLowerCase().includes(e.currentTarget.value)) { return item; }
          }
          if ( item != null && item.profession != null){
            if (item?.profession?.toLowerCase()
                .includes(e.currentTarget.value)) { return item; }}
        })
        setcityfilterUser(filterBySearch);
    }

    //for coffee meet search for all coffeee meet
    function handleSearchChangeCoffee(e) {
      debugger
        if (e.currentTarget.value === "") 
        {  loadOthersCoffeeMeet();
          loadMyCoffeeMeet();
           return; }
        const filterBySearch = otherscoffeemeetfilter.filter((item) => {
          if ( item != null && item.date != null){
            if ( item?.date?.toLowerCase().includes(e.currentTarget.value)) { return item; }
          }
          if ( item != null && item.time != null){
            if (item?.time?.toLowerCase()
                .includes(e.currentTarget.value)) { return item; }}
          
            if ( item != null && item.city != null){
            if (item?.city?.toLowerCase()
                .includes(e.currentTarget.value)) { return item; }}
            
            if ( item != null && item.venue != null){
             if (item?.venue?.toLowerCase()
                  .includes(e.currentTarget.value)) { return item; }}
            
                  if ( item != null && item.hostName != null){
             if (item?.hostName?.toLowerCase()
                .includes(e.currentTarget.value)) { return item; }}      
        
              })
              const filterBySearchmy = mycoffeemeetfilter.filter((item) => {
                if ( item != null && item.date != null){
                  if ( item?.date?.toLowerCase().includes(e.currentTarget.value)) { return item; }
                }
                if ( item != null && item.time != null){
                  if (item?.time?.toLowerCase()
                      .includes(e.currentTarget.value)) { return item; }}
                
                  if ( item != null && item.city != null){
                  if (item?.city?.toLowerCase()
                      .includes(e.currentTarget.value)) { return item; }}
                  
                  if ( item != null && item.venue != null){
                   if (item?.venue?.toLowerCase()
                        .includes(e.currentTarget.value)) { return item; }}
                  
                        if ( item != null && item.hostName != null){
                   if (item?.hostName?.toLowerCase()
                      .includes(e.currentTarget.value)) { return item; }}      
              
                    })
              
              

                    setotherscoffeemeetfilter(filterBySearch);
                    setmycoffeemeetfilter(filterBySearchmy)
    }
    function handleSearchChangeMyCoffee(e) {
      debugger
        if (e.currentTarget.value === "") 
        {  
          loadMyCoffeeMeet();
           return; }
       
              const filterBySearchmy = mycoffeemeetfilter.filter((item) => {
                if ( item != null && item.date != null){
                  if ( item?.date?.toLowerCase().includes(e.currentTarget.value)) { return item; }
                }
                if ( item != null && item.time != null){
                  if (item?.time?.toLowerCase()
                      .includes(e.currentTarget.value)) { return item; }}
                
                  if ( item != null && item.city != null){
                  if (item?.city?.toLowerCase()
                      .includes(e.currentTarget.value)) { return item; }}
                  
                  if ( item != null && item.venue != null){
                   if (item?.venue?.toLowerCase()
                        .includes(e.currentTarget.value)) { return item; }}
                  
                        if ( item != null && item.hostName != null){
                   if (item?.hostName?.toLowerCase()
                      .includes(e.currentTarget.value)) { return item; }}      
              
                    })
              
                    setmycoffeemeetfilter(filterBySearchmy)
    }

//for pagination
const [data, setData] = useState([]);

    const [pageData, setPageData] = useState([]);
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);

    const getdata = async () => {
        const response = await axios.get("https://dummyjson.com/products");
        setData(response.data.products)
    }

    // handle next
    const handleNext = () => {
        if (page === pageCount) return page;
        setPage(page + 1)
    }

    // handle previous
    const handlePrevios = () => {
        if (page === 1) return page;
        setPage(page - 1)
    }

    // useEffect(() => {
    //   loadCityFilerUser2();
    //   loadCityFilerUser1();
    //       loadCityFilerUser();
    // }, [page])

    useEffect(() => {
        const pagedatacount = Math.ceil(cityfilterUser.length / 4);
        setPageCount(pagedatacount);

        if (page) {
            const LIMIT = 4;
            const skip = LIMIT * page // 5 *2 = 10
            const dataskip = cityfilterUser.slice(page === 1 ? 0 : skip - LIMIT, skip);
            setPageData(dataskip)
        }
    }, [cityfilterUser])

    //for confirmdelete
    const [isdeletepopup,setisdeletepopup] = useState(false);

    const deleteUser = () => {

      setisdeletepopup(true)

    }
    const confirmDelete1 = async (id) => {
      debugger;
    const response = await axios.delete(`/UserManagement/deleteUser?userId=${id}`).then
    debugger;
    toast.success("Coffee Meet deleted succssefully");
    
    }
    //for randm color
const colors = ["#ff8427", "#3498db", "#2ecc71", "#e74c3c", "#9b59b6", "#f1c40f", "#e67e22"];
const getRandomColor = () => {
 return colors[Math.floor(Math.random() * colors.length)];
};

//for filter
 


  const [filter, setfilter] = useState({
    filterstatus: "all"
  });
  const handleChangeFilter = (status) => {
    setfilter({ filterstatus: status });

    if (status === "upcoming") {
      setotherscoffeemeetfilter(props.otherscoffeemeet.filter((valuedata) => valuedata.meetStatus =="upcoming"))
      
      // setupcomingDiv(true);
      // setarchiveDiv(false);
      // setallDiv(false);
    } else if (status === "passed") {
      setotherscoffeemeetfilter(props.otherscoffeemeet.filter((valuedata) => valuedata.meetStatus =="passed"))
      // setarchiveDiv(true);
      // setupcomingDiv(false);
      // setallDiv(false);
    } else {
      setotherscoffeemeetfilter(props.otherscoffeemeet)
      // setallDiv(true);
      // setupcomingDiv(false);
      // setarchiveDiv(false);
    }
  };

  //for my coffeemeet
  const [filterMyCoffee, setfilterMyCoffee] = useState({
    filterstatus: "all"
  });
  const handleChangeFiltermycoffee = (status) => {
    setfilterMyCoffee({ filterstatus: status });

    if (status === "upcoming") {
      setmycoffeemeetfilter(props.mycoffeemeet.filter((valuedata) => valuedata.meetStatus =="upcoming"))
      
      // setupcomingDiv(true);
      // setarchiveDiv(false);
      // setallDiv(false);
    } else if (status === "passed") {
      setmycoffeemeetfilter(props.mycoffeemeet.filter((valuedata) => valuedata.meetStatus =="passed"))
      // setarchiveDiv(true);
      // setupcomingDiv(false);
      // setallDiv(false);
    } else {
      setmycoffeemeetfilter(props.mycoffeemeet)
      // setallDiv(true);
      // setupcomingDiv(false);
      // setarchiveDiv(false);
    }
  };

  return (
    <div className="hidescrolll" style={{overflowY: "auto", height: "575px" ,paddingBottom:"1px"}}>
<div className="row" style={{ }}>
          <div className={isOpendiv ?'row':"hide"} style={{height:"48px"}} >
            <div className="col-4"><span className="coffee-heading">Coffee Meetups</span></div>
            <div className="col-3"></div>
            <div className="col-5">
            <input type="text" class="form-control" onChange={handleSearchChangeCoffee}   placeholder="Search" style={{fontSize:"12px"}}/>

            </div>

                    </div>
                    <div className={isOpenMyCoffeediv ?'row':"hide"} style={{height:"48px"}}>
                    <div className="col-4"><span className="coffee-heading">Coffee Meetups</span></div>
            <div className="col-3"></div>
            <div className="col-5">

                    <input type="text" class="form-control" onChange={handleSearchChangeMyCoffee} placeholder="Search" style={{fontSize:"12px"}} />

                    </div>
                    </div>
            
            

              <div className={isOpendiv ? "" : "hide"}>
             
                <div className="example" style={{ backgroundColor: "#fff" }}>
                  <div className='row' style={{backgroundColor:"#D5A576",paddingBottom:"13px",paddingTop:"11px",borderRadius:"17px"}}>
                    <div className='col-lg-4 col-sm-12 col-md-12  textleft' >
                      <span className='post-host-Name coffeemeetbtnn textleft cpointer' onClick={() => onNextMyCoffee(false)}>All Coffee Meet Ups</span>
                      </div>
                      <div className='col-lg-4 col-sm-12 col-md-12' >
                      <span className='post-host-Name textcenter cpointer' style={{ marginLeft: "20px" }} onClick={() => onNextMyCoffee(true)}> My Coffee Meet Ups</span>
                      </div>
                      <div className='col-lg-4 col-sm-12 col-md-12' >
                      {
                      authdirectory==false?( 
                        <span >                
                      <span className='disableHostCoffee post-host-Name textcenter' style={{ marginLeft: "10px" }} data-tip data-for="registerTip1">Host a new Coffee Meet Up</span>
                      <ReactTooltip id="registerTip1" place="right" backgroundColor='#B5924C' class='tooltip width' effect="solid">
                 Go for Premium
                 </ReactTooltip>
                 </span>
                      ):
                      (
                        <span className="post-host-Name textcenter" style={{ marginLeft: "30px" }} onClick={() => onNext(false)}>Host a new Coffee Meet Up</span>
                        )
                    }
                    {/* <div className="col-lg-2"></div> */}

                   </div>

                  </div>


          <div className="row mt-3">
          <div className='col-lg-2 col-sm-12 col-md-12'>
            <button 
            style={{width:"80px"}}
            className={filter.filterstatus === "all" ? "filtercoffeebtnact" : "filtercoffeebtn"}
             onClick={() => handleChangeFilter("all")}>All</button>
           
          </div>
          <div className='col-lg-2 col-sm-12 col-md-12'>
            
            <button 
            className={filter.filterstatus === "passed" ? "filtercoffeebtnact" : "filtercoffeebtn"}
             onClick={() => handleChangeFilter("passed")}>Archive</button>
            
          </div>
          <div className='col-lg-2 col-sm-12 col-md-12'>
            
            <button 
            className={filter.filterstatus === "upcoming" ? "filtercoffeebtnact" : "filtercoffeebtn"}
             onClick={() => handleChangeFilter("upcoming")}>Upcoming</button>
          </div>
            </div>
                  {
                   otherscoffeemeetfilter.map((getmenu, index) => (
                    <div className="row">
                    <div className={getmenu.meetStatus == "upcoming" ? "radius-div-news margintop row" : "radius-div-news margintop row bacolorgrya"}style={{paddingBottom:"2px"}}>
                       <div className="row mt-2">
  
  <div className="col-1">

  {getmenu.bannerImagePath == "" || getmenu.bannerImagePath == null ?
                        (<Avatar className="" style={{ width: "54px",marginTop:"3px", height: "52px",backgroundColor: getRandomColor(),color:"#ffffff" }}>{getmenu.hostName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.bannerImagePath} id='profpicdisablesave1' className='prof-img-right topprofsmimg eventlogoimg1' />)
                      }
    </div>
    <div className="col-6" style={{marginTop:"-2px"}}><h6 className="card-title mb-1" style={{whiteSpace:'nowrap'}}>


<span className='coffeemeet-host-name'  key={getmenu.id}>{getmenu.hostName} - Host</span>

             </h6>
             <div style={{marginTop:"4px"}}>
             
             <span className="coffeemeet-host-name" style={{fontSize:"12px",color:"#797979"}}>
             Creaeted On - {getmenu.createdDate} @{getmenu.createdTime}
</span>
<span className="marginleft20px coffeemeet-host-name" style={{fontSize:"12px",color:"#797979"}}>
City - {getmenu.city}
</span>
             </div>
             </div>
             <div className="col-2"></div>
             
             <div className={getmenu.meetStatus=="passed"?"hide":"col-2"} style={{float:"right"}}>
                           {!hideRespond && (
                            <div className={getmenu.attendStatus !== 'Reject'&& getmenu.attendStatus !== 'Attend'?"":"hide"}><button className="attendbtn"  style={{ marginTop: '6px', position: "absolute",width: "160px" ,cursor:"pointer"}} 
                             onMouseOver={handleRespondClickMouseOver} ><span style={{marginLeft:"29px"}}>Respond</span> <AiOutlineCaretDown size="1.2em" style={{marginLeft:"25px"}}/></button></div>
                           )}
                           {/* {showButtons  &&  ( */}
                              <div className="">
                                {showButtons && getmenu.attendStatus !== 'Reject'&& getmenu.attendStatus !== 'Attend' ?(
                                  <span>
<div><button className="attendbtn"  style={{ marginTop: '49px', position: "",width: "160px",cursor:"pointer",backgroundColor:"#4CAF50" }} onClick={() => attendStatusCofeeMeet1(getmenu.id)} >Attend</button></div>
<div><button className="attendbtn" style={{ marginTop: '2px', position: "",width: "160px",backgroundColor: "#E75480",cursor:"pointer" }} onClick={() => attendStatusCofeeMeet2(getmenu.id)}  onMouseLeave={handleRespondClickMouseLeave}>Reject</button></div>
                                  </span>):
                                  (
                                    <div className="">
                                    
                         <span className={getmenu.attendStatus === 'Reject'?"":"hide"}>
                              <div><button className="attendbtn" style={{ marginTop: '2px', position: "",width: "160px",backgroundColor: "#E75480",cursor:"pointer" }} onMouseOver={handleRejectClick}> <span style={{marginLeft:"29px"}}>Reject</span> <AiOutlineCaretDown size="1.2em" style={{marginLeft:"25px"}}/></button></div>
                              {showButtonsforReject && (<div><button className="attendbtn"  style={{ marginTop: '2px', position: "",width: "160px",cursor:"pointer",backgroundColor:"#4CAF50" }} onClick={()=>attendStatusCofeeMeet1(getmenu.id)} onMouseLeave={handleRejectClickLeave}>Attend</button></div>)} 
                              
                            </span>
                                    
                              <span className={getmenu.attendStatus === 'Attend'?"":"hide"}>
                                        <div><button className="attendbtn"  style={{ marginTop: '2px', position: "",width: "160px",cursor:"pointer",backgroundColor:"#4CAF50" }} onMouseOver={handleAttendClick}><span style={{marginLeft:"9px"}}>You are Attending</span> <AiOutlineCaretDown size="1.1em" style={{marginLeft:"5px"}}/></button></div>
                                        {showButtonsforAttend &&(<div className=""><button className="attendbtn" style={{ marginTop: '2px', position: "",width: "160px",backgroundColor: "#E75480",cursor:"pointer" }} onClick={() => attendStatusCofeeMeet2(getmenu.id)} onMouseLeave={handleAttendClickLeave}>Reject</button></div>)}
                                </span>
                                    
                          </div>                                  )
                                }
                      </div>
                           {/* )} */}
                           
                           
                            

                          </div>
                          {/* <div className={getmenu.meetStatus=="passed"|| getmenu.cancelStatus != "Cancelled"?"col-2 ":"hide col-2"} style={{float:"right"}}>
                           {!hideRespond && (
                            <div className={getmenu.attendStatus !== 'Reject'&& getmenu.attendStatus !== 'Attend'?"":"hide"}><button className="attendbtn"   style={{ marginTop: '6px', position: "absolute",width: "160px" ,cursor:"pointer",background:'lightgray',borderColor:'lightgray'}} 
                              ><span style={{marginLeft:"29px"}}>Respond</span> <AiOutlineCaretDown size="1.2em" style={{marginLeft:"25px"}}/></button></div>
                           )}
                              <div className="">
                                {showButtons && getmenu.attendStatus !== 'Reject'&& getmenu.attendStatus !== 'Attend' ?(
                                  <span>
<div><button className="attendbtn" disabled style={{ marginTop: '49px', position: "",width: "160px",cursor:"pointer",background:'lightgray',borderColor:'lightgray' }}  >Attend</button></div>
<div><button className="attendbtn" disabled style={{ marginTop: '2px', position: "",width: "160px",background:'lightgray',borderColor:'lightgray',cursor:"pointer" }}  >Reject</button></div>
                                  </span>):
                                  (
                                    <div className="">
                                    
                         <span className={getmenu.attendStatus === 'Reject'?"":"hide"}>
                              <div><button disabled className="attendbtn" style={{ marginTop: '2px', position: "",width: "160px",background:'lightgray',borderColor:'lightgray',cursor:"pointer" }} > <span style={{marginLeft:"29px"}}>Reject</span> <AiOutlineCaretDown size="1.2em" style={{marginLeft:"25px"}}/></button></div>
                              {showButtonsforReject && (<div><button className="attendbtn" disabled style={{ marginTop: '2px', position: "",width: "160px",cursor:"pointer",background:'lightgray',borderColor:'lightgray' }}  >Attend</button></div>)} 
                              
                            </span>
                                    
                              <span className={getmenu.attendStatus === 'Attend'?"":"hide"}>
                                        <div><button disabled className="attendbtn"  style={{ marginTop: '2px', position: "",width: "160px",cursor:"pointer",background:'lightgray',borderColor:'lightgray' }}><span style={{marginLeft:"9px"}}>You are Attending</span> <AiOutlineCaretDown size="1.1em" style={{marginLeft:"5px"}}/></button></div>
                                        {showButtonsforAttend &&(<div className=""><button disabled className="attendbtn" style={{ marginTop: '2px', position: "",width: "160px",background:'lightgray',borderColor:'lightgray',cursor:"pointer" }}  >Reject</button></div>)}
                                </span>
                                    
                          </div>                                  )
                                }
                      </div>
                           
                           
                            

                          </div> */}


</div>
<div className="row margintop3 ">
                          <div className="col-11 host-coffee-datetime-div" style={{marginLeft:"13px"}}>
                            <RiCupFill size="1.7em" color="#964B00"/>
                            <span className="coffeemeet-host-name" style={{fontSize:"12px",color:"#222227",fontWeight:"400",marginLeft:"5px"}}>Hosting Coffee Meet Up on <span style={{fontSize:"12px",color:"#222227",fontWeight:"600",marginLeft:"1px"}}>{getmenu.date}</span> at <span style={{fontSize:"12px",color:"#222227",fontWeight:"600",marginLeft:"1px"}}>{getmenu.venue} ,</span> starting at <span style={{fontSize:"12px",color:"#222227",fontWeight:"600",marginLeft:"1px"}}>{getmenu.time}</span> </span>
                            {/* <span className="coffeemeet-host-name" style={{fontSize:"12px",color:"#797979"}}>{getmenu.date} </span> <span className="event-sub-detail"> At {getmenu.time} </span><span className="event-sub-detail" style={{ marginLeft: '12px',textTransform:"capitalize" }}>{getmenu.city}</span> */}
                          </div>
                          
                        </div>   
                       
                       
                        

                        
                       
                        <div className="row margintop3">
                        <div className="col-6">
                        {
                        getmenu.cancelStatus != "Cancelled"?
                          (<div className="">
                            <span className="coffeemeet-host-name" style={{color:"#797979",fontSize:"11px",fontWeight:"500"}}>{getmenu.attendeesCount}/{getmenu.memberCount} Members are attending this Coffee Meet Up</span>
                          </div>)
                          :(<div><span className="coffeemeet-host-name" style={{ margin: "5px", fontSize: '13px', fontStyle: "italic", color: "#E75480" }}> This meet up has been cancelled</span></div>)
                        }
                        </div>
                        <div className="col-1"></div>
                        <div className="col-4 textright" style={{float:"right"}}>
                            <a href={`/${getmenu.googleMapsLink}`} target='_blank'><span className="coffeemeet-host-name cpointer" style={{color:"#626262",fontSize:"12px",fontWeight:"400"}}>Check on Google Maps</span></a>
                        </div>
                        </div>
                      
                        <div className="row margintop">
                        <div className="col-1 maxwd12">
                          {getmenu.bannerImagePath == "" || getmenu.bannerImagePath == null ?
                        (<Avatar className="borderstatshost" style={{ width: "70px",marginTop:"3px", height: "70px",backgroundColor: getRandomColor(),color:"#ffffff" }}>{getmenu.hostName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.bannerImagePath} id='profpicdisablesave1' className='prof-img-right topprofsmimg eventlogoimg1 borderstatshost' />)
                      }
                      <div className="tectcenter margintop1" style={{ height: '30px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}><span className="coffeemeet-host-name" style={{color:"#797979",fontSize:"11px",fontWeight:"400",textAlign:"center",marginTop:"3px"}}>{getmenu.hostName}</span></div>
                      <div className=""style={{ textAlign: 'center', minHeight: '20px' }}><span className="coffeemeet-host-name"style={{color:"#964B00",fontSize:"11px",fontWeight:"500"}}>Host</span></div>

                          </div>
                        {
                            getmenu.guests?.map((gname, index) => (
                              // <div className="col-2">
                              //   <img src={gname.profilePicture} id="mycoffeeprofid" className=' ' style={{ width: "100px", height: "100px", borderRadius: "0%", borderColor: "gray" }} />
                              //   <div><span className="event-sub-detail1" style={{ fontSize: '10px', marginLeft: '2px',textTransform:"capitalize" }}>{gname.name}</span></div>
                              // </div>
                              <div className="col-1 maxwd12">
                                                             
                                         <span className={gname.status == 'Reject'?"":"hide"}><img src={gname.profilePicture} id="othercoffeeprofid" className='borderstatusspnrej' style={{ width: "75px", height: "73px", borderRadius: "50%",marginBottom: '5px' }} /></span>
                                         <span className={gname.status == 'Attend'?"":"hide"}><img src={gname.profilePicture} id="othercoffeeprofid" className='borderstatusspnatt' style={{ width: "75px", height: "73px", borderRadius: "50%",marginBottom: '5px'  }} /></span>
                                         <span className={gname.status == 'Pending'?"":"hide"}><img src={gname.profilePicture} id="othercoffeeprofid" className='borderstatusspnpen' style={{ width: "75px", height: "73px", borderRadius: "50%",marginBottom: '5px'  }} /></span>



                                {/* <span className="borderstatusspn"><img src={gname.profilePicture} id="othercoffeeprofid" className=' ' style={{ width: "100px", height: "100px", borderRadius: "50%" }} /></span> */}
                                {/* <div className="textcenter"><span className="event-sub-detail1" style={{ fontSize: '10px', marginLeft: '0px' }}>{gname.name}</span></div> */}
                                <div className="textcenter" style={{ height: '30px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>

    <span className="coffeemeet-host-name" style={{ fontSize: '12px', maxWidth: '100px', textAlign: 'center', whiteSpace: 'normal', lineHeight: '1.2', overflow: 'hidden',fontWeight:'400',color:'#797979' }}>{gname.name}</span>
    </div>
    <div className="textcenter" style={{ textAlign: 'center', minHeight: '20px' }}>
    <span className={gname.status === 'Reject' ? "coffeemeet-host-name" : "hide"} style={{ fontSize: '12px', color: "#E75480", whiteSpace: 'nowrap',marginLeft:"-1px" }}>{gname.status}</span>
    <span className={gname.status === 'Attend' ? "coffeemeet-host-name" : "hide"} style={{ fontSize: '12px', color: "#4CAF50", whiteSpace: 'nowrap',marginLeft:"-1px" }}>{gname.status}</span>
    <span className={gname.status === 'Pending' ? "coffeemeet-host-name" : "hide"} style={{ fontSize: '12px', color: "#FFC107", whiteSpace: 'nowrap',marginLeft:"-1px" }}>{gname.status}</span>
  </div>
                                  {/* <span className={gname.status == 'Reject'?"event-sub-detail":"hide"} style={{ fontSize: '10px', marginLeft: '0px',color:"#E75480" }}>{gname.status}</span>
                                  <span className={gname.status == 'Attend'?"event-sub-detail":"hide"} style={{ fontSize: '10px', marginLeft: '0px',color:"#4CAF50" }}>{gname.status}</span>
                                  <span className={gname.status == 'Pending'?"event-sub-detail":"hide"} style={{ fontSize: '10px', marginLeft: '0px',color:"#FFC107" }}>{gname.status}</span> */}

                              </div>
                            ))
                          }
                        </div>
                        
                        <div className="row margintop3"></div>
                      </div>
                      </div>
                    ))
                  }

{
 otherscoffeemeetfilter  && otherscoffeemeetfilter.length== 0 &&(
<div className='row margintop'>
  <div className='col-3'></div>
  <div className='col-5 margintop'> <span className='event-sub-detail'>Coffee Meets Are not Available</span>
</div>

</div>
 )
}
                  {/* <div className="cardiv-coffee margintop border1px">
          <div className="row margintop3">
            <div className="col-9">
                <span className="event-sub-detail">01 Feb 2023 </span><span className="event-sub-detail" style={{marginLeft:'12px'}}>Pune</span>
            </div>
            <div className="col-2">

            <div class="dropdown">
  <button class="btn  dropdown-toggle attendbtn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{width:"160px"}}>
  You are Attending
  </button>
  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
    <a class="dropdown-item" href="#" style={{fontSize:'14px',fontFamily:"Source Sans Pro"}}>Withdraw Attendance</a>
  </div>
</div>

          </div>
          </div>
          
          <div className="row margintop2">
            <div className="">
                <span className="event-sub-detail">Venue:- </span>
                <span className="event-sub-detail1" style={{margin:"5px",fontSize:'13px'}}>Good Luck Cafe, FC Road, Pune 01</span>
                <span className="bluetextforlink" style={{margin:"7px",fontSize:'10px'}}>Check on Google Maps</span>
            </div>
          </div>
          <div className="row margintop3">
      <div className="">
      <span className="event-sub-detail1" style={{margin:"5px",fontSize:'13px'}}>05 Members are attending this Coffee Meet Up</span>
    </div>
     </div>
     <div className="row margintop">
     
        <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"0%"}}/>
            <div><span className="event-sub-detail1" style={{fontSize:'10px',marginLeft:'27px'}}>name</span></div>
        </div>
               
        
        

     </div>
          <div className="row margintop3"></div>
          </div> */}
                  <div className="row margintop1" style={{ float: "right" }}>
                    <span className={props.otherscoffeemeet.length== 0?"hide":"showmorefor-coffee"}>Show More</span>
                  </div>
                </div>

              </div>

              <div className={iscofeediv1 ? "" : "hide"}>
              <div className='row' style={{backgroundColor:"#D5A576",paddingBottom:"13px",paddingTop:"11px",borderRadius:"17px"}}>
                  <div className='col-lg-4 col-sm-12 col-md-12 textcenter' >
                      <span className='post-host-Name textcenter cpointer' onClick={() => onNextMyCoffee(false)} >Coffee Meet Ups</span>
                      </div>
                      <div className='col-lg-3 col-sm-12 col-md-12' >
                      <span className='post-host-Name  textcenter cpointer' style={{ marginLeft: "0px" }} onClick={() => onNextMyCoffee(true)}> My Coffee Meet Ups</span>
</div>
                      {/* <span className="bluetextforlink" style={{ marginLeft: "20px" }} onClick={() => onNext(false)}>Host a new Coffee Meet Up</span> */}
                     <div className='col-lg-5 col-sm-12 col-md-12' >
                      {
                      authdirectory==false?( 
                        <span >                
                      <span className='disableHostCoffee post-host-Name coffeemeetbtnn textcenter ' style={{ marginLeft: "20px" }} data-tip data-for="registerTip1">Host a new Coffee Meet Up</span>
                      <ReactTooltip id="registerTip1" place="right" backgroundColor='#B5924C' class='tooltip width' effect="solid">
                 Go for Premium
                 </ReactTooltip>
                 </span>
                      ):
                      (
                        <span className=" textcenter coffeemeetbtnn post-host-Name" style={{ marginLeft: "10px" }} onClick={() => onNext(false)}>Host a new Coffee Meet Up</span>
                        )
                    }

</div>

                  </div>
                <div className='row'>
                  <div className='col-lg-3 col-sm-12 col-md-12 margintop1 textright'>
                    {
                      authdirectory==false?( 
                        <div>                
                      <span className='disableHostCoffee ' data-tip data-for="registerTip1">Host Coffee Meet Up</span>
                      <ReactTooltip id="registerTip1" place="right" backgroundColor='#B5924C' class='tooltip width' effect="solid">
                 Go for Premium
                 </ReactTooltip>
                 </div>
                      ):
                      (<span className='event-sub-detail '>Host Coffee Meet Up</span>
                      )
                    }

                  </div>
                  <div className='col-lg-8 col-sm-12 col-md-12 margintop1'>
                    <span className='event-sub-detail1 ' style={{ marginLeft: "0px", fontSize: "10px" }}> You need at least 4 members to host a coffee meet up. Coffee meet ups will be displayed on the Community Page</span>

                  </div>
                </div>
                <form onSubmit={submitFormData}>

                <div className="row margintop">
                  <div className="col-lg-3 col-sm-12 col-md-12 textright mt-1"><span className="event-sub-detail ">Date</span></div>
                  <div className="col-lg-7 col-sm-12 col-md-12 textleft">
                    <DatePicker
                      className="adreservdate11 "
                      name='date'
                      selected={startDate}
                      onChange={date => handleChangedate(date)}

                      selectsStart // tells this DatePicker that it is part of a range*

                      startDate={startDate}
                      dateFormat="dd  MMM yyyy"
                      minDate={moment().toDate()}
                     style={{width:"399px"}}
                    />
                  </div>
                </div>
                <div className='row '>
                  <div className='col-4'></div>
                  <div className=" col-4 ">
                    {error && coffeeData.date == "" ? (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        Date is required.
                      </span>
                    ) : (
                      ""
                    )}
                  </div>


                </div>

                <div className="row margintop">
                  <div className="col-lg-3 col-sm-12 col-md-12 textright mt-1"><span className="event-sub-detail ">Time</span></div>
                  <div className="col-lg-9 col-sm-12 col-md-12 textleft">
                    {/* <input type="time" name='time' onChange={handleChange} className=' form-control' style={{width:"382px"}}/> */}

                    {/* <div>
        <button
          type="button"
          onClick={() => setSelectedPeriod('morning')}
          style={{
            backgroundColor: selectedPeriod === 'morning' ? 'lightblue' : 'white',
            margin: '5px',
            padding: '10px 20px',
          }}
        >
          Morning
        </button>

        <button
          type="button"
          onClick={() => setSelectedPeriod('evening')}
          style={{
            backgroundColor: selectedPeriod === 'evening' ? 'lightblue' : 'white',
            margin: '5px',
            padding: '10px 20px',
          }}
        >
          Evening
        </button>
      </div>

      
      {selectedPeriod === 'morning' && (
        <div>
          <h3>Morning Slots:</h3>
          {morningSlots.map((slot, index) => (
            <button
              key={index}
              type="button"
              onClick={() => handleSlotSelection(slot)}
              style={{
                backgroundColor: selectedSlot === slot ? 'lightgreen' : 'white',
                margin: '5px',
                padding: '10px 20px',
              }}
            >
              {slot}
            </button>
          ))}
        </div>
      )}

      {selectedPeriod === 'evening' && (
        <div>
          <h3>Evening Slots:</h3>
          {eveningSlots.map((slot, index) => (
            <button
              key={index}
              type="button"
              onClick={() => handleSlotSelection(slot)}
              style={{
                backgroundColor: selectedSlot === slot ? 'lightgreen' : 'white',
                margin: '5px',
                padding: '10px 20px',
              }}
            >
              {slot}
            </button>
          ))}
        </div>
      )}
{selectedSlot && (
        <div style={{ marginTop: '20px' }}>
          <h4>Selected Slot: {selectedSlot}</h4>
        </div>
      )} */}
       <div className="" style={{display:"inline-flex"}}>
      <div className="">
      <select
       className=" form-control dropdowntime "
          value={selectedSlot}
          onChange={(e) => handleManualSelection(e.target.value)}
          style={{ margin: '0px', paddingLeft: '12px',paddingRight: '12px', width: '',paddingTop:'4px',paddingBottom:"4px" }}
        >
          {/* Predefined options */}
          <option value="">hh</option>
            {/* {(activePeriod === 'morning' ? morningSlots : eveningSlots).map((slot) => (
            <option key={slot} value={slot}>
              {slot}
            </option>
          ))}   */}
          {/* Manual option */}
          <option value="01">01</option>
          <option value="02">02</option>
          <option value="03">03</option>
          <option value="04">04</option>
          <option value="05">05</option>
          <option value="06">06</option>
          <option value="07">07</option>
          <option value="08">08</option>
          <option value="09">09</option>
          <option value="10">10</option>
          <option value="11">11</option>
          <option value="12">12</option>
          

        </select>
      </div>
      <div className="">
      <select
        className="form-control dropdowntime"
        value={selectedMinute}
        onChange={(e) => handleManualMinuteSelection(e.target.value)}
        style={{ margin: '0px', paddingLeft: '5px',paddingRight: '5px', width: '',paddingTop:'4px',paddingBottom:"4px",marginLeft:'5px' }}
      >
        <option value="">mm</option>
        <option value="00">00</option>
        <option value="15">15</option>
        <option value="30">30</option>
        <option value="45">45</option>
      </select>
      </div>
      <div className="" style={{marginLeft:"10px"}}>
      <button
            type="button"
            onClick={() => handleAmPmSelection('AM')}
            className={amPm === 'AM' ? ' actAmpmbtn' : "Ampmbtn"}
            style={{
              backgroundColor: amPm === 'AM' ? ' mornevningbtn' : '',
              margin: '1px',
              marginTop:"1px",
              padding: '7px',
            }}
            disabled={!isManual && activePeriod === 'evening'} // Disable AM when evening slot is selected unless manual
          >
            AM
          </button>
          <button
            type="button"
            className={amPm === 'PM' ? ' actAmpmbtn' : "Ampmbtn"}
            onClick={() => handleAmPmSelection('PM')}
            style={{
              backgroundColor: amPm === 'PM' ? ' mornevningbtn' : '',
              margin: '1px',
              marginTop:"1px",
              padding: '7px',
            }}
            disabled={!isManual && activePeriod === 'morning'} // Disable PM when morning slot is selected unless manual
          >
            PM
          </button>
      </div>
     </div>
      
      {/* Morning and Evening buttons */}
      <div className="margintop2">
        <button
          type="button"
          onClick={() => setActivePeriod('morning')}
          className={activePeriod === 'morning'? ' actmornevningbtn' : "mornevningbtn"}
          style={{ margin: '5px', backgroundColor: activePeriod === 'morning' ? '' : '' }}
        >
          Morning
        </button>
        <button
          type="button"
          onClick={() => setActivePeriod('evening')}
          className={activePeriod === 'evening'? ' actmornevningbtn' : "mornevningbtn"}
          style={{ margin: '5px', backgroundColor: activePeriod === 'evening' ? '' : '' }}
        >
          Evening
        </button>
      </div>

      {/* Time slot buttons depending on the selected period */}
      <div className="margintop1">
        {activePeriod === 'morning' &&
          morningSlots.map((slot, index) => (
            <button
              key={index}
              type="button"
              className={selectedSlot === slot? ' actslotclass' : "slotclass"}
              onClick={() => handleSlotSelection(slot)}
              style={{
                backgroundColor: selectedSlot === slot ? '' : '',
                margin: '5px',
                paddingTop: '5px',
                paddingBottom:"4px"
              }}
            >
              {slot +":00 AM"}
            </button>
          ))}

        {activePeriod === 'evening' &&
          eveningSlots.map((slot, index) => (
            <button
              key={index}
              type="button"
              className={selectedSlot === slot? ' actslotclass' : "slotclass"}
              onClick={() => handleSlotSelection(slot)}
              style={{
                backgroundColor: selectedSlot === slot ? '' : '',
                margin: '5px',
                paddingTop: '5px',
                paddingBottom:"4px"
              }}
            >
              {slot +":00 PM"}
            </button>
          ))}
      </div>

      {/* Input field for the selected time slot (you can type manually as well) */}
    
      
        
        {/* <div>
          <input
            type="text"
            value={selectedSlot}
            onChange={(e) => setSelectedSlot(e.target.value)}
            placeholder="Select or enter a time slot"
            style={{ margin: '10px', padding: '5px', width: '150px' }}
          />
        </div> */}

                
                  </div>
                </div>
                <div className='row '>
                  <div className='col-4'></div>
                  <div className=" col-4 ">
                  {error && (selectedSlot === "" || amPm === "") ? (
      <span style={{ color: "red", fontSize: "12px" }}>
        Time is required.
      </span>
    ) : (
      ""
    )}
                  </div>


                </div>

                <div className="row margintop3">
                  <div className="col-2"></div>
                  <div className="col-8">
                  <div className="  mt-1"><span className="event-sub-detail1 ">This coffee meetup is being hosted in</span> <span className="event-sub-detail">{authlocalstoragedata?.currentCountry}, {authlocalstoragedata?.currentState}, {authlocalstoragedata?.currentCity}</span></div>
                  
                  <div className=" margintop1">
                  <div className="  mt-1"><span className="event-sub-detail1 ">If you want to host somewhere else please <span className="bluetextforlink" style={{ fontSize: "10px", marginLeft: "0px" }} onClick={togglePopupCountry}>Click here</span></span></div>
                  </div>
                </div> 
                </div> 
               
                
               
               
               
                <div className="row margintop">
                  <div className="col-lg-3 col-sm-12 col-md-12 textright mt-1"><span className="event-sub-detail">Venue</span></div>
                  <div className="col-lg-5 col-sm-12 col-md-12 textleft" style={{ whiteSpace: "nowrap" }}>
                    <input type="text" className=" form-control" id="fst"
                      name="venue"
                      placeholder="Enter Venue"
                      style={{ fontSize: '13px' }}
                      onChange={handleChange}
                    />
                  </div>
                  {/* <div className="col-lg-4 col-sm-12 col-md-12">
                    {
                   
                    coffeeData.googleMapsLink==""?
                    ( <span className="bluetextforlink" style={{ fontSize: "10px", marginLeft: "-20px" }} onClick={togglePopupMessage}>Enter Google Map Link</span>)
                    :(<div>
                      <a href= {`/${coffeeData.googleMapsLink}`} target='_blank'><span className="bluetextforlink" style={{ fontSize: "10px", marginLeft: "-20px" }} >
                      Check Coffee Meet Venue on Google Map
                       </span></a>

                      
                     <span className="bluetextforlink" style={{ fontSize: "10px",marginLeft:"15px" }} onClick={togglePopupMessage}>Edit</span> </div>)
                    }

                  </div> */}

                </div>
                <div className="row margintop">
                  <div className="col-lg-3 col-sm-12 col-md-12 textright mt-1"><span className="event-sub-detail">Google Maps Link</span>
                  <div style={{ marginTop: "-3px" }}><span className="lbl popinsfont" style={{ fontSize: '12px' }} >(Optional)</span></div>
                  </div>

                  <div className="col-lg-5 col-sm-12 col-md-12 textleft" style={{ whiteSpace: "nowrap" }}>
                  <input type="text" name='googleMapsLink' placeholder="Enter Google Map Link" onChange={handleChange} className='form-control' style={{ fontSize: "12px" }} />

                  </div>
                  {/* <div className="col-lg-4 col-sm-12 col-md-12">
                    {
                   
                    coffeeData.googleMapsLink==""?
                    ( <span className="bluetextforlink" style={{ fontSize: "10px", marginLeft: "-20px" }} onClick={togglePopupMessage}>Enter Google Map Link</span>)
                    :(<div>
                      <a href= {`/${coffeeData.googleMapsLink}`} target='_blank'><span className="bluetextforlink" style={{ fontSize: "10px", marginLeft: "-20px" }} >
                      Check Coffee Meet Venue on Google Map
                       </span></a>

                      
                     <span className="bluetextforlink" style={{ fontSize: "10px",marginLeft:"15px" }} onClick={togglePopupMessage}>Edit</span> </div>)
                    }

                  </div> */}

                </div>

                
                
                </form>
                <div className="row margintop">
                  <div className="col-3"></div>

                  <div className="col-3">
                    <button className="cancelbtn" onClick={()=> onbackMeetUp()}>
                      Cancel
                    </button>
                  </div>


                  <div className="col-3">
                    <button className="createeventbtn" onClick={() => onNextOther(false)}    >
                      Next
                    </button>
                  </div>

                </div>
              </div>

              <div className={iscofeediv2 ? "" : "hide"}>
                
              <div className='row' style={{backgroundColor:"#D5A576",paddingBottom:"13px",paddingTop:"11px",borderRadius:"17px"}}>
                  <div className='col-lg-4 col-sm-12 col-md-12 textcenter' >
                      <span className='post-host-Name textcenter cpointer' onClick={() => onNextMyCoffee(false)} >Coffee Meet Ups</span>
                      </div>
                      <div className='col-lg-3 col-sm-12 col-md-12' >
                      <span className='post-host-Name  textcenter cpointer' style={{ marginLeft: "0px" }} onClick={() => onNextMyCoffee(true)}> My Coffee Meet Ups</span>
</div>
                      {/* <span className="bluetextforlink" style={{ marginLeft: "20px" }} onClick={() => onNext(false)}>Host a new Coffee Meet Up</span> */}
                     <div className='col-lg-5 col-sm-12 col-md-12' >
                      {
                      authdirectory==false?( 
                        <span >                
                      <span className='disableHostCoffee post-host-Name coffeemeetbtnn textcenter ' style={{ marginLeft: "20px" }} data-tip data-for="registerTip1">Host a new Coffee Meet Up</span>
                      <ReactTooltip id="registerTip1" place="right" backgroundColor='#B5924C' class='tooltip width' effect="solid">
                 Go for Premium
                 </ReactTooltip>
                 </span>
                      ):
                      (
                        <span className=" textcenter coffeemeetbtnn post-host-Name" style={{ marginLeft: "10px" }} onClick={() => onNext(false)}>Host a new Coffee Meet Up</span>
                        )
                    }

</div>

                  </div>
<div className="row margintop3">
  <div className="col-6 boxshadowsmall backgroundimg"style={{borderRadius:"5px"}}>
  <div className=" margintop3 marginleftrightminus">
                          <div className=" host-coffee-datetime-div" style={{marginLeft:"2px",marginRight:"-8px",marginTop:"-14px",paddingLeft:"7px",paddingRight:"5px",borderBottomLeftRadius:"0px",borderBottomRightRadius:"0px"}}>
                            <RiCupFill size="1.7em" color="#964B00"/>
                            <span className="coffeemeet-host-name" style={{fontSize:"14px",color:"#222227",fontWeight:"400",marginLeft:"5px"}}>Hosting Coffee Meet Up on <span style={{fontSize:"14px",color:"#222227",fontWeight:"600",marginLeft:"1px"}}>{coffeeData.date}</span> at <span style={{fontSize:"14px",color:"#222227",fontWeight:"600",marginLeft:"1px"}}>{coffeeData.venue} ,</span> Starting at <span style={{fontSize:"14px",color:"#222227",fontWeight:"600",marginLeft:"1px"}}>{coffeeData.time}</span> </span>
                            {/* <span className="coffeemeet-host-name" style={{fontSize:"12px",color:"#797979"}}>{getmenu.date} </span> <span className="event-sub-detail"> At {getmenu.time} </span><span className="event-sub-detail" style={{ marginLeft: '12px',textTransform:"capitalize" }}>{getmenu.city}</span> */}
                          </div>
                          
                        </div>
                        <div className=" marginleftrightminus margintop3" style={{marginLeft:"10px",paddingLeft:"10px",paddingRightt:"10px"}}>
                          <span className="coffeemeet-host-name" style={{fontSize:"14px",color:"#626262",fontWeight:"400",fontStyle:"italic"}}>  You need at least 4 members and a maximum of 10 members to host a coffee meet up. Coffee meet ups will be displayed on the Community Page</span>
                        </div>
                        <div className="line-with-text">
      <span className="coffeemeet-host-name"style={{color:"#964B00",fontSize:"11px",fontWeight:"500"}}>Host</span><span style={{borderBottomWidth:"1px",borderBottomStyle:"solid",borderBottomColor:"#C4C4C4"}}></span>
      <hr className="horizontal-line" />
</div>

<div className="row">
  <div className="col-2">
  {
  props.loadProfile==""?(
<Avatar className="avtarimg" style={{width:559,height:53,borderRadius:"50%",border:"1px",borderStyle:"solid",borderColor:"rgb(237, 237, 237)"}}>{props.avtarProf}</Avatar> 

  ):(
    <img src={props.loadProfile} id="othercoffeeprofid" className='' style={{ width: "55px", height: "53px", borderRadius: "50%",marginBottom: '5px' }} />

  )
}
    </div>
  <div className="col-6"><span className="coffeemeet-host-name" style={{fontSize:"16px",color:"#222227",fontWeight:"600",marginLeft:"5px"}}>{userhostname}</span> 
  <div className=""><span className="coffeemeet-host-name" style={{fontSize:"12px",color:"#797979",fontWeight:"500",marginLeft:"5px"}}>{authlocalstoragedata.currentCity},{authlocalstoragedata.currentState},{authlocalstoragedata.currentCountry} Maharashtra, India</span></div>
   </div>

</div>


{arrayData.length > 0 ? (
    arrayData.map((member) => (
      <div key={member.id} className="member-item">
        <div className="line-with-text">
      <span className="coffeemeet-host-name"style={{color:"#964B00",fontSize:"11px",fontWeight:"500"}}>Guest</span><span style={{borderBottomWidth:"1px",borderBottomStyle:"solid",borderBottomColor:"#C4C4C4"}}></span>
      <hr className="horizontal-line" />
</div>
<div className="row">
  <div className="col-2"><img src={imgupload} id="othercoffeeprofid" className='' style={{ width: "55px", height: "53px", borderRadius: "50%",marginBottom: '5px' }} /></div>
  <div className="col-6"><span className="coffeemeet-host-name" style={{fontSize:"16px",color:"#222227",fontWeight:"600",marginLeft:"5px"}}>{member.name}</span> 
  <div className=""><span className="coffeemeet-host-name" style={{fontSize:"12px",color:"#797979",fontWeight:"500",marginLeft:"5px"}}>{member.currentCity}, {member.currentState}, {member.currentCountry}</span></div>
   </div>
   <div className="col-2" ><span className="coffeemeet-host-name" value={member.id} style={{fontSize:"12px",color:"#F44336",fontWeight:"400",fontStyle:"italic",cursor:"pointer"}} onClick={removebtn}>Remove</span></div>

</div>
        
      </div>
    ))
  ) : (
    <div className="row margintop1 textcenter"><span className="coffeemeet-host-name" style={{fontSize:"14px",color:"#797979",fontWeight:"500",marginLeft:"px"}}>No members selected.</span></div>
  )}

  </div>
  
  
  <div className="col-6">
  <div className='row margintop3'>
                  <div class="form-group col-5">
                    <select class="form-control" id="sel1" onChange={handleChangeforkycoffline} name='filterStatus'>
                      <option value=''>Please Select</option>
                      <option value='all'>All</option>
                      <option value='City'>City</option>
                      <option value='profession'>Profession</option>
                      <option value='connects only'>Connects Only</option>
                    </select>
                  </div>
                  <div className="col-1">
                    <FaFilter size='1.2em' style={{marginTop:"5px"}}/>
                  </div>
                  <div className="col-6">

                    {
                      cityDiv == true && (
                        <div className='  '>
                          <Multiselect
                            isObject={false}
                            onRemove={(event) => {
                              handleChangeMultiSelect(event)
                              console.log(event)
                            }}
                            onSelect={(event) => {
                              handleChangeMultiSelect(event)
                              console.log(event)
                            }}
                            //options={[{name: 'Option 1', id: 1},{name: 'Option 2', id: 2}]}
                            options={cityList}
                            displayValue="name"
                            name="city"
                          //onChange={handleChangeMultiSelect(event)}

                          />

                          {/* <input type="text" className=" form-control" id="fst"
         name="city"
         placeholder="Enter City"
         style={{fontSize:'13px'}}
         onChange={handleChangeforkycoffline}
    />  */}
                          {/* <Select
                                    id="city"
                                    className="countrydrp"
                                    name="city"
                                    options={filterCity(formData.stateId ? formData.stateId : null)}
                                    
                                    onChange={onchangeFiltercity}                                    
                                    style={{fontSize:'13px'}}
                                />  */}
                        </div>
                      )

                    }

                    {
                      professionDiv == true && (
                        <div className='  '>

                          {/* <input type="text" className=" form-control" id="fst"
         name="profession"
         placeholder="Enter Profession"
         style={{fontSize:'13px'}}
         onChange={handleChangeforkycoffline}
    /> */}
                          <Multiselect
                            isObject={false}
                            onRemove={(event) => {
                              console.log(event);
                              handleProfession(event);
                            }}
                            onSelect={(event) => {
                              console.log(event);
                              handleProfession(event);
                            }}
                            options={naturOfBusiness}
                            displayValue="name"
                            name="city"
                            onChange={handleProfession}
                            style={{fontSize:"12px"}}
                          />
                        </div>
                      )

                    }

                    {/* {
              connectDiv==true && (
<div className='  '>
            
<input type="text" className=" form-control" id="fst"
         name="firstname"
         placeholder="Connects Only"
         style={{fontSize:'13px'}}
    />
            </div>            
             )

            } */}
                  </div>

                </div>

                <div className='row margintop3'>
                  <div className='col-4  textright' >
                    <span className='event-sub-detail '>Search Members </span>
                  </div>
                  <div className='col-8  '>
                    <div class="form-group has-search ">
                      <span class="fa fa-search form-control-feedback"></span>
                      <input type="text" class="form-control" placeholder="Search Community members" onChange={handleSearchChange} style={{ fontSize: '12px', marginLeft: "-0px" }} />
                    </div>
                  </div>
                </div>
                <div className=""style={{backgroundColor:"#F8F8F8",paddingLeft:"16px",paddingRight:"16px",paddingTop:"10px",paddingBottom:"15px",borderRadius:"5px"}}>

                {
                    cityfilterUser.length > 0 ?
                    cityfilterUser.map((data1, index) => {
                      const isChecked = arrayData.some((item) => item.id == data1.id);

                      return(
                        <>
                       <div className="row margintop2 boxshadowsmall"style={{backgroundColor:"#ffffff",paddingTop:'9px',paddingBottom:'7px',borderRadius:"5px"}} >
  <div className="col-2"><img src={data1.profilePicture} id="othercoffeeprofid" className='' style={{ width: "55px", height: "53px", borderRadius: "50%",marginBottom: '5px' }} /></div>
  <div className="col-7"><span className="coffeemeet-host-name" style={{fontSize:"16px",color:"#222227",fontWeight:"600",marginLeft:"5px"}}>{data1.name}</span> 
  <div className=""><span className="coffeemeet-host-name" style={{fontSize:"12px",color:"#797979",fontWeight:"500",marginLeft:"5px"}}>{data1.currentCity}, {data1.currentState}, {data1.currentCountry}</span></div>
   </div>
   <div className="col-1"></div>
   <div className="col-2">
    <label class="form-check-label " style={{marginTop:"0px"}}>
    <input type="checkbox" class=" form-check-input chek" id={data1.id} value={data1.id} onClick={showbtn} style={{marginTop:"-2px",backgroundColor:"#ffffff",borderRadius:"0px",borderWidth:"2px"}}
      checked={isChecked}

    />
    <span className="showconnectmemrs"></span>
  </label>
  </div>

</div>
{/*                        
                        <div className="row margintop1">
<div className="col-2  textright" >
  <label class="form-check-label " style={{marginTop:"0px"}}>
    <input type="checkbox" class=" form-check-input chek" id={data1.id} value={data1.id} onClick={showbtn} style={{marginTop:"-2px"}}/>
    <span className="showconnectmemrs"></span>
  </label>

  <img src={data1.profilePicture} id="coffemituserlistprofid" className='' style={{ width: "42px", height: "42px", marginTop: "18px", marginLeft: "20px" }} />

</div>
<div className="col-3" style={{ marginTop: "27px" }}>
  <span className="event-sub-detail">{data1.name}</span>
</div>
<div className="col-1 " style={{ marginTop: "27px" }}>
  <span className="event-sub-detail1">{data1.id}</span>

</div>
<div className="col-3 " style={{ marginTop: "27px" }}>
  <span className="event-sub-detail1">{data1.profession}</span>

</div>
<div className="col-2 " style={{ marginTop: "27px" }}>
  <span className="event-sub-detail1">{data1.currentCity}, {data1.currentCountry}</span>

</div>
</div> */}
                        </>
                      )
                      
                  
                      }):<div></div>
                      }
                      </div>
  </div>

</div>

              
              
              
                

                
                
                 {/* {
                                pageData.length > 0 ?
                                    pageData.map((element, index) => {
                                        return (
                                            <>
                                                <div>
                                                    <p>{element.id}</p>
                                                    <p>{element.price}</p>
                                                    <p>{element.title}</p>
                                                    <p><img src={element.thumbnail} style={{ width: 60, height: 60 }} alt="" /></p>
                                                </div>
                                            </>
                                        )
                                    }) : <div className='d-flex justify-content-center mt-4'>
                                    </div>
                          } */}
               
               {/* <div className=""style={{float:"right"}}>
       <Pagination>

<Pagination.Prev onClick={()=>handlePrevios()} disabled={page === 1} />
{
    Array(pageCount).fill(null).map((ele, index) => {
        return (
            <>
                <Pagination.Item active={page === index + 1 ? true : false} onClick={() => setPage(index + 1)}>{index + 1}</Pagination.Item>
            </>
        )
    })
}
<Pagination.Next onClick={()=>handleNext()} disabled={page === pageCount} />
</Pagination>
               </div>  */}
                <br />
                <div className="row margintop">
                  <div className="col-3"></div>

                  <div className="col-3">
                    <button className="cancelbtn" onClick={()=>onbackMeetUp()}>
                      Cancel
                    </button>
                  </div>


                  <div className={showinvitbtn ? "col-3" : "hide"}>
                    <button className="createeventbtn" onClick={()=>addCoffeeMeet()}    >
                      Send Invite
                    </button>
                    <label className={errorGuestcount?"event-sub-detail1":"hide"} style={{color:"red"}}>Coffee meetups required at least 4 members</label>
                  </div>

                </div>

              </div>
              
              <div className={isOpenMyCoffeediv ? "" : "hide"}>
                <div className="" style={{ backgroundColor: "#fff" }}>
                  <div className='row' style={{backgroundColor:"#D5A576",paddingBottom:"13px",paddingTop:"11px",borderRadius:"17px"}}>
                  <div className='col-lg-4 col-sm-12 col-md-12 textcenter' >
                      <span className='post-host-Name textcenter cpointer' onClick={() => onNextMyCoffee(false)} >Coffee Meet Ups</span>
                      </div>
                      <div className='col-lg-4 col-sm-12 col-md-12' >
                      <span className='post-host-Name coffeemeetbtnn textcenter cpointer' style={{ marginLeft: "0px" }} onClick={() => onNextMyCoffee(true)}> My Coffee Meet Ups</span>
</div>
                      {/* <span className="bluetextforlink" style={{ marginLeft: "20px" }} onClick={() => onNext(false)}>Host a new Coffee Meet Up</span> */}
                     <div className='col-lg-4 col-sm-12 col-md-12' >
                      {
                      authdirectory==false?( 
                        <span >                
                      <span className='disableHostCoffee post-host-Name textcenter ' style={{ marginLeft: "20px" }} data-tip data-for="registerTip1">Host a new Coffee Meet Up</span>
                      <ReactTooltip id="registerTip1" place="right" backgroundColor='#B5924C' class='tooltip width' effect="solid">
                 Go for Premium
                 </ReactTooltip>
                 </span>
                      ):
                      (
                        <span className="post-host-Name textcenter post-host-Name" style={{ marginLeft: "10px" }} onClick={() => onNext(false)}>Host a new Coffee Meet Up</span>
                        )
                    }

</div>

                  </div>


                  <div className="row mt-3">
          <div className='col-lg-2 col-sm-12 col-md-12'>
            <button 
            style={{width:"80px"}}
            className={filterMyCoffee.filterstatus === "all" ? "filtercoffeebtnact" : "filtercoffeebtn"}
             onClick={() => handleChangeFiltermycoffee("all")}>All</button>
           
          </div>
          <div className='col-lg-2 col-sm-12 col-md-12'>
            
            <button 
            className={filterMyCoffee.filterstatus === "passed" ? "filtercoffeebtnact" : "filtercoffeebtn"}
             onClick={() => handleChangeFiltermycoffee("passed")}>Archive</button>
            
          </div>
          <div className='col-lg-2 col-sm-12 col-md-12'>
            
            <button 
            className={filterMyCoffee.filterstatus === "upcoming" ? "filtercoffeebtnact" : "filtercoffeebtn"}
             onClick={() => handleChangeFiltermycoffee("upcoming")}>Upcoming</button>
          </div>
            </div>
                  {
                    mycoffeemeetfilter.map((getmenu, index) => (
                      <div className="row">
                      <div className={getmenu.meetStatus == "upcoming" ? "radius-div-news margintop row" : "radius-div-news margintop row bacolorgrya"}style={{paddingBottom:"2px"}}>
                         <div className="row mt-2">
  
  <div className="col-1">

  {getmenu.bannerImagePath == "" || getmenu.bannerImagePath == null ?
                        (<Avatar className="" style={{ width: "54px",marginTop:"3px", height: "52px",backgroundColor: getRandomColor(),color:"#ffffff" }}>{getmenu.hostName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.bannerImagePath} id='profpicdisablesave1' className='prof-img-right topprofsmimg eventlogoimg1' />)
                      }
    </div>
<div className="col-6" style={{marginTop:"-2px"}}><h6 className="card-title mb-1" style={{whiteSpace:'nowrap'}}>


<span className='coffeemeet-host-name'  key={getmenu.id}>{getmenu.hostName} - Host</span>

             </h6>
             <div style={{marginTop:"4px"}}>
             
             <span className="coffeemeet-host-name" style={{fontSize:"12px",color:"#797979"}}>
             Creaeted On - {getmenu.createdDate} @{getmenu.createdTime}
</span>
<span className="marginleft20px coffeemeet-host-name" style={{fontSize:"12px",color:"#797979"}}>
City - {getmenu.city}
</span>
             </div>
             </div>
             <div className="col-2"></div>
    <div className={getmenu.cancelStatus == "Cancelled" ? "hide" : "col-2"} style={{float:'right'}}>
                            <button className={getmenu.meetStatus == "upcoming" ? "attendbtn" : "hide"} style={{ marginTop: '6px', position: "absolute", backgroundColor: "#E75480" }} 
                            // onClick={() => cancelMyCofeeMeet(getmenu.id)}
                            onClick={deleteUser}
                            >Cancel</button>
                          </div>



</div>
                        <div className="row margintop3 ">
                          <div className="col-11 host-coffee-datetime-div" style={{marginLeft:"13px"}}>
                            <RiCupFill size="1.7em" color="#964B00"/>
                            <span className="coffeemeet-host-name" style={{fontSize:"12px",color:"#222227",fontWeight:"400",marginLeft:"5px"}}>Hosting Coffee Meet Up on <span style={{fontSize:"12px",color:"#222227",fontWeight:"600",marginLeft:"1px"}}>{getmenu.date}</span> at <span style={{fontSize:"12px",color:"#222227",fontWeight:"600",marginLeft:"1px"}}>{getmenu.venue} ,</span> starting at <span style={{fontSize:"12px",color:"#222227",fontWeight:"600",marginLeft:"1px"}}>{getmenu.time}</span> </span>
                            {/* <span className="coffeemeet-host-name" style={{fontSize:"12px",color:"#797979"}}>{getmenu.date} </span> <span className="event-sub-detail"> At {getmenu.time} </span><span className="event-sub-detail" style={{ marginLeft: '12px',textTransform:"capitalize" }}>{getmenu.city}</span> */}
                          </div>
                          
                        </div>
                       {/* <div className={isdeletepopup?"row":"hide"}> */}
                       <div className={isdeletepopup?"popup-boxrefmember2":"hide"}>
      <div className="boxrefmember">
        <div className="Deletpopdiv1">
            <div className="row ">
                <div className="col-4"></div>
                <div className="col-7">
                <span className=" blood-top-text " style={{color:"#FFFFFF"}}>Warning</span>
            {/* <span className="refermem-secondtop-text marginleft6"style={{color:"#555555"}}>(Referral ID - {authlocalstoragedata?.referralCode})</span> */}
                 {/* <MdFileCopy color='#fff' className="marginleft2"style={{color:"#555555"}}/>
                 <FaShareAltSquare color='#fff' className="marginleft5"style={{color:"#555555"}}/> */}
                 </div>
                 <div className='col-1'>
                 {/* <span className="lastpop-top-text" onClick={props.handleClose} style={{cursor:"pointer"}}><AiOutlineClose size="1.3em"/></span> */}

                 </div>
            </div>
           
        
        </div>
        <div className="warntxtdiv">
                    <span className="warntxt"> Do you Really want to delete these  </span>
                    <div><span className="warntxt">Coffee Meet ? </span></div>
                </div>
                <div className="warnbtn row">
                  <div className="col-2"></div>
                  <div className="col-3"> <button class="cnceldeletpopbtn"  onClick={() => setisdeletepopup(false)}> Cancel </button></div>
                  <div className="col-3 ml-3"> 
                  <button className="addeletepopbtn" onClick={() => cancelMyCofeeMeet(getmenu.id)}>Delete</button>
                  </div>

                </div>
                <br/>
            </div>
            </div>           
                        {/* <div className="popup-boxrefmember">
            <div className="boxrefmember">
                <div className="Deletpopdiv ">
                    <span className="warnspn">Warning!</span>
                </div>
                <div className="warntxtdiv">
                    <span className="warntxt"> Do you Really want to delete these  </span>
                    <div><span className="warntxt">Coffee Meet ? </span></div>
                </div>
                <div className="warnbtn">
                    <button class=" cnceldeletpopbtn"  > Cancel </button>
                    <button className="addeletepopbtn" onClick={() => cancelMyCofeeMeet(getmenu.id)}>Delete</button>
                </div>
            </div>
        </div> */}
        {/* </div> */}

                        <div className="row margintop3">
                          <div className="col-6">
                            <span className="coffeemeet-host-name" style={{color:"#797979",fontSize:"11px"}}>{
                            getmenu.cancelStatus != "Cancelled" ? (
                              <div className="">

                                <span className="coffeemeet-host-name" style={{color:"#797979",fontSize:"11px",fontWeight:"500"}}>{getmenu.attendeesCount}/{getmenu.memberCount} Members are attending this Coffee Meet Up</span>

                              </div>

                            ) : (
                              <div>
                                <span className="coffeemeet-host-name" style={{ margin: "5px", fontSize: '11px', fontStyle: "italic", color: "#E75480" }}>You cancelled this meet up</span>

                              </div>
                            )


                          } </span>
                            </div>
                            <div className="col-1"></div>
<div className="col-4 textright" style={{float:"right"}}>
                            <a href={`/${getmenu.googleMapsLink}`} target='_blank'><span className="coffeemeet-host-name cpointer" style={{color:"#626262",fontSize:"12px",fontWeight:"400"}}>Check on Google Maps</span></a>
                        </div>
                        </div>
                        
                       
                        <div className="row margintop3">

                          <div className="col-1 maxwd12">
                          {getmenu.bannerImagePath == "" || getmenu.bannerImagePath == null ?
                        (<Avatar className="borderstatshost" style={{ width: "75px",marginTop:"3px", height: "75px",backgroundColor: getRandomColor(),color:"#ffffff" }}>{getmenu.hostName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.bannerImagePath} id='profpicdisablesave1' className='prof-img-right topprofsmimg eventlogoimg1 borderstatshost' />)
                      }
                      <div className="tectcenter" style={{ height: '30px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}><span className="coffeemeet-host-name" style={{color:"#797979",fontSize:"11px",fontWeight:"400"}}>{getmenu.hostName}</span></div>
                      <div className=""style={{ textAlign: 'center', minHeight: '20px' }}><span className="coffeemeet-host-name"style={{color:"#964B00",fontSize:"11px",fontWeight:"500"}}>Host</span></div>

                          </div>
                          {
                            getmenu.guests?.map((gname, index) => (
                              // <div className="col-2">
                              //   <img src={gname.profilePicture} id="mycoffeeprofid" className=' ' style={{ width: "100px", height: "100px", borderRadius: "0%", borderColor: "gray" }} />
                              //   <div><span className="event-sub-detail1" style={{ fontSize: '10px', marginLeft: '2px',textTransform:"capitalize" }}>{gname.name}</span></div>
                              // </div>
                              <div className="col-1 maxwd12">
                                                             
                                         <span className={gname.status == 'Reject'?"":"hide"}><img src={gname.profilePicture} id="othercoffeeprofid" className='borderstatusspnrej' style={{ width: "75px", height: "73px", borderRadius: "50%",marginBottom: '5px' }} /></span>
                                         <span className={gname.status == 'Attend'?"":"hide"}><img src={gname.profilePicture} id="othercoffeeprofid" className='borderstatusspnatt' style={{ width: "75px", height: "73px", borderRadius: "50%",marginBottom: '5px'  }} /></span>
                                         <span className={gname.status == 'Pending'?"":"hide"}><img src={gname.profilePicture} id="othercoffeeprofid" className='borderstatusspnpen' style={{ width: "75px", height: "73px", borderRadius: "50%",marginBottom: '5px'  }} /></span>



                                {/* <span className="borderstatusspn"><img src={gname.profilePicture} id="othercoffeeprofid" className=' ' style={{ width: "100px", height: "100px", borderRadius: "50%" }} /></span> */}
                                {/* <div className="textcenter"><span className="event-sub-detail1" style={{ fontSize: '10px', marginLeft: '0px' }}>{gname.name}</span></div> */}
                                <div className="textcenter" style={{ height: '30px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>

    <span className="coffeemeet-host-name" style={{ fontSize: '12px', maxWidth: '100px', textAlign: 'center', whiteSpace: 'normal', lineHeight: '1.2', overflow: 'hidden',fontWeight:'400',color:'#797979' }}>{gname.name}</span>
    </div>
    <div className="textcenter" style={{ textAlign: 'center', minHeight: '20px' }}>
    <span className={gname.status === 'Reject' ? "coffeemeet-host-name" : "hide"} style={{ fontSize: '12px', color: "#E75480", whiteSpace: 'nowrap',marginLeft:"-1px" }}>{gname.status}</span>
    <span className={gname.status === 'Attend' ? "coffeemeet-host-name" : "hide"} style={{ fontSize: '12px', color: "#4CAF50", whiteSpace: 'nowrap',marginLeft:"-1px" }}>{gname.status}</span>
    <span className={gname.status === 'Pending' ? "coffeemeet-host-name" : "hide"} style={{ fontSize: '12px', color: "#FFC107", whiteSpace: 'nowrap',marginLeft:"-1px" }}>{gname.status}</span>
  </div>
                                  {/* <span className={gname.status == 'Reject'?"event-sub-detail":"hide"} style={{ fontSize: '10px', marginLeft: '0px',color:"#E75480" }}>{gname.status}</span>
                                  <span className={gname.status == 'Attend'?"event-sub-detail":"hide"} style={{ fontSize: '10px', marginLeft: '0px',color:"#4CAF50" }}>{gname.status}</span>
                                  <span className={gname.status == 'Pending'?"event-sub-detail":"hide"} style={{ fontSize: '10px', marginLeft: '0px',color:"#FFC107" }}>{gname.status}</span> */}

                              </div>
                            ))
                          }
                          {/* <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
               <div><span className="event-sub-detail1" style={{fontSize:'10px' ,marginLeft:'27px'}}>First Name</span></div>

        </div>
        <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
        <div><span className="event-sub-detail1" style={{fontSize:'10px' ,marginLeft:'27px'}}>First Name</span></div>

        </div>
        <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
        <div><span className="event-sub-detail1" style={{fontSize:'10px' ,marginLeft:'27px'}}>First Name</span></div>

        </div>
        <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
        <div><span className="event-sub-detail1" style={{fontSize:'10px' ,marginLeft:'27px'}}>First Name</span></div>

        </div> */}


                        </div>
                        <div className="row margintop3"></div>
                      </div>
                      </div>
                    ))
                  }
                  
 {
  mycoffeemeetfilter  && mycoffeemeetfilter.length== 0 &&(
 <div className='row margintop'>
   <div className='col-3'></div>
   <div className='col-5 margintop'> <span className='event-sub-detail'>Coffee Meets Are not Available</span>
 </div>
 
 </div>
  )
 }
                  {/* <div className="cardiv-coffee margintop border1px">
          <div className="row margintop3">
            <div className="col-9">
                <span className="event-sub-detail">01 Feb 2023 </span><span className="event-sub-detail" style={{marginLeft:'12px'}}>Pune</span>
            </div>
            <div className="col-2">
            <button className="attendbtn" style={{marginTop:'6px',position:"absolute",backgroundColor:"#E75480"}}>Cancel</button>
          </div>
          </div>
          
          <div className="row margintop2">
            <div className="">  
                <span className="event-sub-detail">Venue:- </span>
                <span className="event-sub-detail1" style={{margin:"5px",fontSize:'13px'}}>Good Luck Cafe, FC Road, Pune 01</span>
                <span className="bluetextforlink" style={{margin:"7px",fontSize:'10px'}}>Check on Google Maps</span>
            </div>
          </div>
          <div className="row margintop3">
      <div className="">
      <span className="event-sub-detail1" style={{margin:"5px",fontSize:'13px'}}>01/10 Members are attending this Coffee Meet Up</span>
    </div>
     </div>
     <div className="row margintop">
        <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
            <div><span className="event-sub-detail1" style={{fontSize:'10px',marginLeft:'27px'}}>First Name</span></div>
        </div>
        <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
               <div><span className="event-sub-detail1" style={{fontSize:'10px' ,marginLeft:'27px'}}>First Name</span></div>

        </div>
        <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
        <div><span className="event-sub-detail1" style={{fontSize:'10px' ,marginLeft:'27px'}}>First Name</span></div>

        </div>
        <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
        <div><span className="event-sub-detail1" style={{fontSize:'10px' ,marginLeft:'27px'}}>First Name</span></div>

        </div>
        <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
        <div><span className="event-sub-detail1" style={{fontSize:'10px' ,marginLeft:'27px'}}>First Name</span></div>

        </div>
        

     </div>
          <div className="row margintop3"></div>
          </div> */}
                  {/* <div className="cardiv-coffee margintop border1px" style={{backgroundColor:"lightgray"}}>
          <div className="row margintop3">
            <div className="col-9">
                <span className="event-sub-detail">01 Feb 2023 </span><span className="event-sub-detail" style={{marginLeft:'12px'}}>Pune</span>
            </div>
            <div className="col-2">
          </div>
          </div>
          
          <div className="row margintop2">
            <div className="">  
                <span className="event-sub-detail">Venue:- </span>
                <span className="event-sub-detail1" style={{margin:"5px",fontSize:'13px'}}>Good Luck Cafe, FC Road, Pune 01</span>
                <span className="bluetextforlink" style={{margin:"7px",fontSize:'10px'}}>Check on Google Maps</span>
            </div>
          </div>
          <div className="row margintop3">
      <div className="">
      <span className="event-sub-detail1" style={{margin:"5px",fontSize:'13px'}}>01/10 Members are attending this Coffee Meet Up</span>
    </div>
     </div>
     <div className="row margintop">
        <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
            <div><span className="event-sub-detail1" style={{fontSize:'10px',marginLeft:'27px'}}>First Name</span></div>
        </div>
        <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
               <div><span className="event-sub-detail1" style={{fontSize:'10px' ,marginLeft:'27px'}}>First Name</span></div>

        </div>
        <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
        <div><span className="event-sub-detail1" style={{fontSize:'10px' ,marginLeft:'27px'}}>First Name</span></div>

        </div>
        <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
        <div><span className="event-sub-detail1" style={{fontSize:'10px' ,marginLeft:'27px'}}>First Name</span></div>

        </div>
        <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
        <div><span className="event-sub-detail1" style={{fontSize:'10px' ,marginLeft:'27px'}}>First Name</span></div>

        </div>
        

     </div>
          <div className="row margintop3"></div>
          </div> */}
                  {/* <div className="cardiv-coffee margintop border1px">
          <div className="row margintop3">
            <div className="col-9">
                <span className="event-sub-detail">01 Feb 2023 </span><span className="event-sub-detail" style={{marginLeft:'12px'}}>Pune</span>
            </div>
            <div className="col-2">
          </div>
          </div>
          
          <div className="row margintop2">
            <div className="">  
                <span className="event-sub-detail">Venue:- </span>
                <span className="event-sub-detail1" style={{margin:"5px",fontSize:'13px'}}>Good Luck Cafe, FC Road, Pune 01</span>
                <span className="bluetextforlink" style={{margin:"7px",fontSize:'10px'}}>Check on Google Maps</span>
            </div>
          </div>
          <div className="row margintop3">
      <div className="">
      <span className="event-sub-detail1" style={{margin:"5px",fontSize:'11px',fontStyle:"italic",color:"#E75480"}}>You cancelled this meet up

</span>
    </div>
     </div>
     <div className="row margintop">
        <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
            <div><span className="event-sub-detail1" style={{fontSize:'10px',marginLeft:'27px'}}>First Name</span></div>
        </div>
        <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
               <div><span className="event-sub-detail1" style={{fontSize:'10px' ,marginLeft:'27px'}}>First Name</span></div>

        </div>
        <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
        <div><span className="event-sub-detail1" style={{fontSize:'10px' ,marginLeft:'27px'}}>First Name</span></div>

        </div>
        <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
        <div><span className="event-sub-detail1" style={{fontSize:'10px' ,marginLeft:'27px'}}>First Name</span></div>

        </div>
        <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
        <div><span className="event-sub-detail1" style={{fontSize:'10px' ,marginLeft:'27px'}}>First Name</span></div>

        </div>
        

     </div>
          <div className="row margintop3"></div>
          </div> */}
                  <div className="row margintop1" style={{ float: "right" }}>
                    <span className={props.mycoffeemeet.length== 0 ?"hide":"showmorefor-coffee"}>Show More</span>
                  </div>
                </div>

              </div>
              {/* <div className={googleMaplinkDiv ? "" : "hide"}>
                <div className="popup-boxcheckdetail">
                  <div className="boxcheckdetail" style={{marginLeft:"510px"}}>
                    <div className="topnewsrow">
                      <div className="row">
                        <div className="col-4"></div>
                        <div className="col-8">
                          <span className="blood-top-text">Google Maps Link</span>
                        </div>
                      </div>


                    </div>
                    <div className="row margintop">
                      <div className="col-12">
                        <input type="text" name='googleMapsLink' placeholder="Enter Google Map Link" onChange={handleChange} className='form-control' style={{ fontSize: "12px" }} />
                      </div>
                    </div>



                    <div className='popupbtn-div margintop'>

                      <div className='row'>
                        <div className='col-3'></div>
                        <div className='col-3'>
                          <button className='cancelbtn' style={{ width: '130px', height: "42px" }} onClick={() => setgoogleMaplinkDiv(false)}>Close</button>
                        </div>
                        <div className='col-3'>
                          <button className='attendbtn' style={{ width: '130px', height: "42px", marginLeft: "px" }}onClick={() => setgoogleMaplinkDiv(false)}>Save</button>
                        </div>

                      </div>
                    </div>

                  </div>
                </div>
              </div> */}
              <div className={CountrystateDiv ? "" : "hide"}>
                <div className="popup-boxcheckdetail">
                  <div className="boxcheckdetail" style={{marginLeft:"510px"}}>
                    <div className="topnewsrow">
                      <div className="row">
                        <div className="col-4"></div>
                        <div className="col-8">
                          <span className="blood-top-text">Location Details</span>
                        </div>
                      </div>


                    </div>
                   
                    <div className="row margintop">
                  <div className="col-lg-3 col-sm-12 col-md-12 textright mt-1"><span className="event-sub-detail">Country</span></div>
                  <div className="col-lg-6 col-sm-12 col-md-12 textleft">
                    {/* <Select
                      // required
                      id="country"
                      name="country"
                      label="country"
                      className="countrydrp "
                      rules={{ required: true }}
                      options={updatedCountries}
                      // onChange={(value) => {
                      //   setValues({ country: value, state: null, city: null }, false);
                      // }}
                      // defaultValue={updatedCountries.filter((value) => value.name == 'India')}
                      onChange={(event) =>
                        setcoffeeData({ ...coffeeData, country: event.name, countryId: event.id })
                      }

                      style={{ fontSize: '13px' }}
                    /> */}

                <select   id="countryy"  
                className=" form-control "
                name='country'
                value={coffeeData?.country}
                onChange={handleCountryChange}>
                <option selected value="">Select</option>
                {
                  
                  countryData.map((result) =>(
                  <option  key={result.id}  value={`${result.id}-${result.country}`}>{result.country}</option>
                  ) )

                }
                </select> 
                  </div>
                </div>
                <div className='row '>
                  <div className='col-4'></div>
                  <div className=" col-4 ">
                    {error && CountrystateDiv==true && coffeeData.country == "" ? (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        Country is required.
                      </span>
                    ) : (
                      ""
                    )}
                  </div>


                </div>
                <div className="row margintop">
                  <div className="col-lg-3 col-sm-12 col-md-12 textright mt-1"><span className="event-sub-detail">State</span></div>
                  <div className="col-lg-6 col-sm-12 col-md-12 textleft">
                    {/* <Select
                      name="state"
                      id="state"
                      className="countrydrp"

                      options={updatedStates(coffeeData.countryId ? coffeeData.countryId : null)}

                      onChange={(event) =>
                        setcoffeeData({ ...coffeeData, state: event.name, stateId: event.id })
                      }
                      style={{ fontSize: '13px' }}

                    /> */}
                     <select   id="state"  
                className=" form-control "
                name='state'
                value={coffeeData?.state}
                onChange={handleStateChange}>
                <option selected value="">Select</option>
                {
                  
                  stateData.map((result) =>(
                  <option  key={result.id}  value={`${result.id}-${result.state}`}>{result.state}</option>
                  ) )

                }
                </select> 
                  </div>
                </div>
                <div className='row '>
                  <div className='col-4'></div>
                  <div className=" col-4 ">
                    {error && CountrystateDiv==true && coffeeData.state == "" ? (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        State is required.
                      </span>
                    ) : (
                      ""
                    )}
                  </div>


                </div>
                <div className="row margintop">
                  <div className="col-lg-3 col-sm-12 col-md-12 textright mt-1"><span className="event-sub-detail">City</span></div>
                  <div className="col-lg-6 col-sm-12 col-md-12 textleft">
                    {/* <Select
                      id="city"
                      className="countrydrp"
                      name="city"
                      options={updatedCities(coffeeData.stateId ? coffeeData.stateId : null)}

                      onChange={(event) =>
                        setcoffeeData({ ...coffeeData, city: event.name, cityId: event.id })
                      }
                      style={{ fontSize: '13px' }}
                    /> */}

                <select   id="city"  
                className=" form-control "
                name='city'
                value={coffeeData?.city}
                onChange={handleCityChange}>
                <option selected value="">Select</option>
                {
                  
                  cityData.map((result) =>(
                  <option  key={result.id}  value={`${result.id}-${result.city}`}>{result.city}</option>
                  ) )

                }
                </select>
                  </div>
                </div>
                <div className='row '>
                  <div className='col-4'></div>
                  <div className=" col-4 ">
                    {error && CountrystateDiv==true && coffeeData.city == "" ? (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        City is required.
                      </span>
                    ) : (
                      ""
                    )}
                  </div>


                </div>


                    <div className='popupbtn-div margintop'>

                      <div className='row'>
                        <div className='col-3'></div>
                        <div className='col-3'>
                          <button className='cancelbtn' style={{ width: '130px', height: "42px" }} onClick={() => setCountrystateDiv(false)}>Close</button>
                        </div>
                        <div className='col-3'>
                          <button className='attendbtn' style={{ width: '130px', height: "42px", marginLeft: "px" }}onClick={() => setCountrystateDiv(false)}>Save</button>
                        </div>

                      </div>
                    </div>

                  </div>
                </div>
              </div>

             
            {/* </form> */}

      </div>
    </div>

  );
}

export default CoffeeMeets;