import './MembersInMyLocationSeAllPopup.css'
import conprof from '../../images/coneect.svg';
import {FiMaximize2} from 'react-icons/fi'
import {AiOutlineCloseSquare} from 'react-icons/ai'
import React, { useState, useEffect ,useRef} from "react";
import {over} from 'stompjs';
import SockJS from 'sockjs-client';
import axios from "axios";
import { toast } from "react-toastify";
import { BiBorderRadius } from 'react-icons/bi';
import {configData} from "../../Config"
import ReactTooltip from "react-tooltip";
import { IoPinSharp } from "react-icons/io5";
import { FaRegComments, FaUserClock, FaFilter,FaThumbsDown, FaInfoCircle,FaSearch, FaRegHandshake, FaEdit, FaBookmark } from 'react-icons/fa';
import { Badge, Button, Tab, Nav, Card, Col, Form, FormGroup, Row } from 'react-bootstrap';
import { MdAddBox, MdWarning,MdOutlineClose } from 'react-icons/md';
import { BsThreeDots } from 'react-icons/bs';
import MessagePopup from './MessagePopup';
import imgupload from '../../images/imageupload.svg'
import Avatar from '@mui/material/Avatar';
import Multiselect from 'multiselect-react-dropdown';



const MembersInMyLocationSeAllPopup = (props)=> {

    
 const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
 console.log("authdata11", authlocalstoragedata)

  
    const [uerAuthId, setuerAuthId] = useState(authlocalstoragedata?.userAuthenticationId);
    const [uerCurrentCity, setuerCurrentCity] = useState(authlocalstoragedata?.currentCity);
  
    
    const [activeButton, setActiveButton] = useState('All');
    const [showselect, setshowselect] = useState(false);


  // const handleButtonClick = (buttonName) => {
  //   setActiveButton(buttonName);
  // };
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
debugger;
    switch (buttonName) {
      case 'My Contacts':
        loadMembers("My Contacts"); // connectedStatus = 1 for My Contacts
        break;
       case 'Not in Contact':
         loadMembers("Not in Contacts"); // connectedStatus = 0 for Not in Contact
         break;
         case 'Profession':
         //loadMembers1("Profession"); // connectedStatus = 0 for Not in Contact
         setshowselect(true);
         break;
         
      default:
        loadMembers("All"); // connectedStatus = null for All
    }
  };
    
  //for randm color
 const colors = ["#ff8427", "#3498db", "#2ecc71", "#e74c3c", "#9b59b6", "#f1c40f", "#e67e22"];
 const getRandomColor = () => {
  return colors[Math.floor(Math.random() * colors.length)];
};
const [bgColor, setBgColor] = useState(""); // Initialize state for background color

  useEffect(() => {
    // Set random color only once during the initial render
    setBgColor(getRandomColor());
  }, []);
    
    const [members, setMembers] = useState([]);

  useEffect(() => {
    loadMembers(0);
  }, []);


  const loadMembers = async (filterStatus) => {

      const result = await axios.get(configData.ApiUrl+'/user/getUsersNearMe?id=' + uerAuthId + '&filterStatus=' + filterStatus + '&currentCity=' + uerCurrentCity);

      setMembers(result.data.data);
      console.log("getmembersinlocation", result.data.data)

    
  

    };
    const loadMembers1 = async (abc) => {

      const result = await axios.get(configData.ApiUrl+'/user/getUsersNearMe?id=' + uerAuthId + '&filterStatus=' + Profession +'&profession=' + abc.toString()+ '&currentCity=' + uerCurrentCity);

      setMembers(result.data.data);
      console.log("getmembersinlocation", result.data.data)

    
  

    };

    //for multiselect
    var selectedProfession = "";
  const [abcc, setabcc] = useState("");
    


const handleProfession = (e) => {
      debugger;
  
      selectedProfession = e.toString();
      console.log("sss", abcc)
      loadMembers1(e)
  
    }
    //profession
  const [naturOfBusiness, setnaturOfBusiness] = useState([]);

  useEffect(() => {
    loadNaturOfBusiness();
  }, []);

  const loadNaturOfBusiness = async () => {
    const result = await axios.get(configData.ApiUrl+"/user/natureOfBuisness");
    setnaturOfBusiness(result.data.data);
    console.log("busnessgrp", result.data.data);

  };

 return (
   <div className="popup-boxMembersInPopup">
       <div className="boxMembersInPopup">
       <div className="borderbottom-radius margintop3">
      
       <div className="textleft radiussub-div" style={{marginLeft:"0px",marginTop:"-14px",marginRight:"0px"}}><IoPinSharp size="1.3em"  style={{marginLeft:"25px"}}/>    <span className="radius-heding" style={{marginLeft:"5px"}}> Members In My Location</span><span style={{float:"right",marginRight:"10px"}}><MdOutlineClose size="1.3em"  style={{cursor:'pointer',marginLeft:"10px"}} onClick={props.handleClose}/></span></div>
<div className=''style={{paddingLeft:"21px",height:"530px",overflowY:"scroll"}}>
<div className="row margintop3">
      <div className="col-lg-9 col-sm-12 col-md-12 displyinline">
        <button
          className={`near-members-filter-btn-long fontroboto40012px ${activeButton === 'All' ? 'active ' : ''}`}
          onClick={() => handleButtonClick('All')}
          style={{width:"53px"}}
        >
          All
        </button>
        <button
          className={`near-members-filter-btn-long fontroboto40012px ${activeButton === 'My Contacts' ? 'active ' : ''}`}
          style={{ marginLeft: '10px',width:"109px" }}
          onClick={() => handleButtonClick('My Contacts')}
          
        >
          My Contacts
        </button>
        <button
          className={`near-members-filter-btn-long fontroboto40012px ${activeButton === 'Not in Contact' ? 'active ' : ''}`}
          style={{ marginLeft: '10px',width:"109px" }}
          onClick={() => handleButtonClick('Not in Contact')}
        >
          Not in Contact
        </button>
        {showselect?(
          <Multiselect
          isObject={false}
          onRemove={(event) => {
            console.log(event);
            handleProfession(event);
          }}
          onSelect={(event) => {
            console.log(event);
            handleProfession(event);
          }}
          options={naturOfBusiness}
          displayValue="name"
          name="Profession"
          onChange={handleProfession}
          style={{fontSize:"12px",marginLeft:"19px"}}
          className='professionmultiselectt'
        />
        ):(
        <button
          className={`near-members-filter-btn-long fontroboto40012px ${activeButton === 'Profession' ? 'active ' : ''}`}
          style={{ marginLeft: '10px',width:"109px" }}
          onClick={() => handleButtonClick('Profession')}
        >
          Profession
        </button>
        )
      }
      </div>
        <div className='col-3'>
        <div className="form-group has-search" style={{ marginLeft: '3px', backgroundColor: '#214B0D1A',float:"right" }}>
          <span className="fa fa-search form-control-feedback"></span>
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            style={{
              fontSize: '12px',
              backgroundColor: '#214B0D1A',
              width: '',
              height: '30px',
            }}
          />
        </div>
      </div>
    </div>
    {Array.from({ length: Math.ceil(members.length / 5) }, (_, i) =>
    members.slice(i * 5, i * 5 + 5) // Chunk the members array into groups of 5
  ).map((group, rowIndex) => (
  <div className='row textcenter minusleftmarg8 margintop3' key={rowIndex}>
  {group.map((image, index) => (
  <div
  key={index}
  
   className='col-lg-2 col-sm-12 col-md-12 member-cards textcenter'>
    {image.profilePhotoPath == "" || image.profilePhotoPath == null ?
                        (<Avatar className="" style={{ width: "66px", height: "64px",backgroundColor: bgColor,color:"#ffffff",textTransform:"capitalize" ,marginLeft:"38px"}}>{image.name?.substring(0, 2)}</Avatar>)
                        : (<img src={image.profilePhotoPath} style={{width:"66px",height:"66px",borderRadius:"50%"}}/>)
                      }
<div className='margintop1'>
  <span className='radius-heding'style={{fontSize:"14px"}}>{image.name}</span>
</div>
<div className=''style={{marginTop:"-5px"}}>
  <span style={{fontSize:"11px",color:"#626262"}}>{image.profession}</span>
</div>
{/* <div className=''style={{marginTop:"5px"}}><span className='fontroboto40012px' style={{color:"#222227",fontWeight:"500"}}>240m</span></div> */}
<button className='view-prof-btun'>View Profile</button>
</div>
    ))
}

  </div>
))}
 
  
  </div>
</div>
</div>

     </div>
 );
};

export default MembersInMyLocationSeAllPopup;